*, ::before, ::after{
  border-color:currentColor
}

.visible{
  visibility:visible
}

.invisible{
  visibility:hidden
}

.static{
  position:static
}

.fixed{
  position:fixed
}

.absolute{
  position:absolute
}

.relative{
  position:relative
}

.sticky{
  position:sticky
}

.inset-0{
  top:0;
  right:0;
  bottom:0;
  left:0
}

.inset-1{
  top:4px;
  right:4px;
  bottom:4px;
  left:4px
}

.inset-2{
  top:8px;
  right:8px;
  bottom:8px;
  left:8px
}

.inset-3{
  top:12px;
  right:12px;
  bottom:12px;
  left:12px
}

.inset-4{
  top:16px;
  right:16px;
  bottom:16px;
  left:16px
}

.inset-5{
  top:20px;
  right:20px;
  bottom:20px;
  left:20px
}

.inset-6{
  top:24px;
  right:24px;
  bottom:24px;
  left:24px
}

.inset-8{
  top:32px;
  right:32px;
  bottom:32px;
  left:32px
}

.inset-10{
  top:40px;
  right:40px;
  bottom:40px;
  left:40px
}

.inset-12{
  top:48px;
  right:48px;
  bottom:48px;
  left:48px
}

.inset-16{
  top:64px;
  right:64px;
  bottom:64px;
  left:64px
}

.inset-18{
  top:72px;
  right:72px;
  bottom:72px;
  left:72px
}

.inset-24{
  top:96px;
  right:96px;
  bottom:96px;
  left:96px
}

.inset-auto{
  top:auto;
  right:auto;
  bottom:auto;
  left:auto
}

.inset-5dot5{
  top:22px;
  right:22px;
  bottom:22px;
  left:22px
}

.inset-full{
  top:100%;
  right:100%;
  bottom:100%;
  left:100%
}

.-inset-1{
  top:-4px;
  right:-4px;
  bottom:-4px;
  left:-4px
}

.-inset-2{
  top:-8px;
  right:-8px;
  bottom:-8px;
  left:-8px
}

.-inset-3{
  top:-12px;
  right:-12px;
  bottom:-12px;
  left:-12px
}

.-inset-4{
  top:-16px;
  right:-16px;
  bottom:-16px;
  left:-16px
}

.-inset-5{
  top:-20px;
  right:-20px;
  bottom:-20px;
  left:-20px
}

.-inset-6{
  top:-24px;
  right:-24px;
  bottom:-24px;
  left:-24px
}

.-inset-8{
  top:-32px;
  right:-32px;
  bottom:-32px;
  left:-32px
}

.-inset-10{
  top:-40px;
  right:-40px;
  bottom:-40px;
  left:-40px
}

.-inset-12{
  top:-48px;
  right:-48px;
  bottom:-48px;
  left:-48px
}

.-inset-16{
  top:-64px;
  right:-64px;
  bottom:-64px;
  left:-64px
}

.-inset-18{
  top:-72px;
  right:-72px;
  bottom:-72px;
  left:-72px
}

.-inset-24{
  top:-96px;
  right:-96px;
  bottom:-96px;
  left:-96px
}

.-inset-5dot5{
  top:-22px;
  right:-22px;
  bottom:-22px;
  left:-22px
}

.-inset-full{
  top:-100%;
  right:-100%;
  bottom:-100%;
  left:-100%
}

.inset-1\/2{
  top:50%;
  right:50%;
  bottom:50%;
  left:50%
}

.inset-1\/3{
  top:33.33333%;
  right:33.33333%;
  bottom:33.33333%;
  left:33.33333%
}

.inset-2\/3{
  top:66.666667%;
  right:66.666667%;
  bottom:66.666667%;
  left:66.666667%
}

.inset-1\/4{
  top:25%;
  right:25%;
  bottom:25%;
  left:25%
}

.inset-2\/4{
  top:50%;
  right:50%;
  bottom:50%;
  left:50%
}

.inset-3\/4{
  top:75%;
  right:75%;
  bottom:75%;
  left:75%
}

.-inset-1\/2{
  top:-50%;
  right:-50%;
  bottom:-50%;
  left:-50%
}

.-inset-1\/3{
  top:-33.333333%;
  right:-33.333333%;
  bottom:-33.333333%;
  left:-33.333333%
}

.-inset-2\/3{
  top:-66.666667%;
  right:-66.666667%;
  bottom:-66.666667%;
  left:-66.666667%
}

.-inset-1\/4{
  top:-25%;
  right:-25%;
  bottom:-25%;
  left:-25%
}

.-inset-2\/4{
  top:-50%;
  right:-50%;
  bottom:-50%;
  left:-50%
}

.-inset-3\/4{
  top:-75%;
  right:-75%;
  bottom:-75%;
  left:-75%
}

.inset-x-0{
  left:0;
  right:0
}

.inset-x-1{
  left:4px;
  right:4px
}

.inset-x-2{
  left:8px;
  right:8px
}

.inset-x-3{
  left:12px;
  right:12px
}

.inset-x-4{
  left:16px;
  right:16px
}

.inset-x-5{
  left:20px;
  right:20px
}

.inset-x-6{
  left:24px;
  right:24px
}

.inset-x-8{
  left:32px;
  right:32px
}

.inset-x-10{
  left:40px;
  right:40px
}

.inset-x-12{
  left:48px;
  right:48px
}

.inset-x-16{
  left:64px;
  right:64px
}

.inset-x-18{
  left:72px;
  right:72px
}

.inset-x-24{
  left:96px;
  right:96px
}

.inset-x-auto{
  left:auto;
  right:auto
}

.inset-x-5dot5{
  left:22px;
  right:22px
}

.inset-x-full{
  left:100%;
  right:100%
}

.-inset-x-1{
  left:-4px;
  right:-4px
}

.-inset-x-2{
  left:-8px;
  right:-8px
}

.-inset-x-3{
  left:-12px;
  right:-12px
}

.-inset-x-4{
  left:-16px;
  right:-16px
}

.-inset-x-5{
  left:-20px;
  right:-20px
}

.-inset-x-6{
  left:-24px;
  right:-24px
}

.-inset-x-8{
  left:-32px;
  right:-32px
}

.-inset-x-10{
  left:-40px;
  right:-40px
}

.-inset-x-12{
  left:-48px;
  right:-48px
}

.-inset-x-16{
  left:-64px;
  right:-64px
}

.-inset-x-18{
  left:-72px;
  right:-72px
}

.-inset-x-24{
  left:-96px;
  right:-96px
}

.-inset-x-5dot5{
  left:-22px;
  right:-22px
}

.-inset-x-full{
  left:-100%;
  right:-100%
}

.inset-x-1\/2{
  left:50%;
  right:50%
}

.inset-x-1\/3{
  left:33.33333%;
  right:33.33333%
}

.inset-x-2\/3{
  left:66.666667%;
  right:66.666667%
}

.inset-x-1\/4{
  left:25%;
  right:25%
}

.inset-x-2\/4{
  left:50%;
  right:50%
}

.inset-x-3\/4{
  left:75%;
  right:75%
}

.-inset-x-1\/2{
  left:-50%;
  right:-50%
}

.-inset-x-1\/3{
  left:-33.333333%;
  right:-33.333333%
}

.-inset-x-2\/3{
  left:-66.666667%;
  right:-66.666667%
}

.-inset-x-1\/4{
  left:-25%;
  right:-25%
}

.-inset-x-2\/4{
  left:-50%;
  right:-50%
}

.-inset-x-3\/4{
  left:-75%;
  right:-75%
}

.inset-y-0{
  top:0;
  bottom:0
}

.inset-y-1{
  top:4px;
  bottom:4px
}

.inset-y-2{
  top:8px;
  bottom:8px
}

.inset-y-3{
  top:12px;
  bottom:12px
}

.inset-y-4{
  top:16px;
  bottom:16px
}

.inset-y-5{
  top:20px;
  bottom:20px
}

.inset-y-6{
  top:24px;
  bottom:24px
}

.inset-y-8{
  top:32px;
  bottom:32px
}

.inset-y-10{
  top:40px;
  bottom:40px
}

.inset-y-12{
  top:48px;
  bottom:48px
}

.inset-y-16{
  top:64px;
  bottom:64px
}

.inset-y-18{
  top:72px;
  bottom:72px
}

.inset-y-24{
  top:96px;
  bottom:96px
}

.inset-y-auto{
  top:auto;
  bottom:auto
}

.inset-y-5dot5{
  top:22px;
  bottom:22px
}

.inset-y-full{
  top:100%;
  bottom:100%
}

.-inset-y-1{
  top:-4px;
  bottom:-4px
}

.-inset-y-2{
  top:-8px;
  bottom:-8px
}

.-inset-y-3{
  top:-12px;
  bottom:-12px
}

.-inset-y-4{
  top:-16px;
  bottom:-16px
}

.-inset-y-5{
  top:-20px;
  bottom:-20px
}

.-inset-y-6{
  top:-24px;
  bottom:-24px
}

.-inset-y-8{
  top:-32px;
  bottom:-32px
}

.-inset-y-10{
  top:-40px;
  bottom:-40px
}

.-inset-y-12{
  top:-48px;
  bottom:-48px
}

.-inset-y-16{
  top:-64px;
  bottom:-64px
}

.-inset-y-18{
  top:-72px;
  bottom:-72px
}

.-inset-y-24{
  top:-96px;
  bottom:-96px
}

.-inset-y-5dot5{
  top:-22px;
  bottom:-22px
}

.-inset-y-full{
  top:-100%;
  bottom:-100%
}

.inset-y-1\/2{
  top:50%;
  bottom:50%
}

.inset-y-1\/3{
  top:33.33333%;
  bottom:33.33333%
}

.inset-y-2\/3{
  top:66.666667%;
  bottom:66.666667%
}

.inset-y-1\/4{
  top:25%;
  bottom:25%
}

.inset-y-2\/4{
  top:50%;
  bottom:50%
}

.inset-y-3\/4{
  top:75%;
  bottom:75%
}

.-inset-y-1\/2{
  top:-50%;
  bottom:-50%
}

.-inset-y-1\/3{
  top:-33.333333%;
  bottom:-33.333333%
}

.-inset-y-2\/3{
  top:-66.666667%;
  bottom:-66.666667%
}

.-inset-y-1\/4{
  top:-25%;
  bottom:-25%
}

.-inset-y-2\/4{
  top:-50%;
  bottom:-50%
}

.-inset-y-3\/4{
  top:-75%;
  bottom:-75%
}

.top-0{
  top:0
}

.top-1{
  top:4px
}

.top-2{
  top:8px
}

.top-3{
  top:12px
}

.top-4{
  top:16px
}

.top-5{
  top:20px
}

.top-6{
  top:24px
}

.top-8{
  top:32px
}

.top-10{
  top:40px
}

.top-12{
  top:48px
}

.top-16{
  top:64px
}

.top-18{
  top:72px
}

.top-24{
  top:96px
}

.top-auto{
  top:auto
}

.top-5dot5{
  top:22px
}

.top-full{
  top:100%
}

.-top-1{
  top:-4px
}

.-top-2{
  top:-8px
}

.-top-3{
  top:-12px
}

.-top-4{
  top:-16px
}

.-top-5{
  top:-20px
}

.-top-6{
  top:-24px
}

.-top-8{
  top:-32px
}

.-top-10{
  top:-40px
}

.-top-12{
  top:-48px
}

.-top-16{
  top:-64px
}

.-top-18{
  top:-72px
}

.-top-24{
  top:-96px
}

.-top-5dot5{
  top:-22px
}

.-top-full{
  top:-100%
}

.top-1\/2{
  top:50%
}

.top-1\/3{
  top:33.33333%
}

.top-2\/3{
  top:66.666667%
}

.top-1\/4{
  top:25%
}

.top-2\/4{
  top:50%
}

.top-3\/4{
  top:75%
}

.-top-1\/2{
  top:-50%
}

.-top-1\/3{
  top:-33.333333%
}

.-top-2\/3{
  top:-66.666667%
}

.-top-1\/4{
  top:-25%
}

.-top-2\/4{
  top:-50%
}

.-top-3\/4{
  top:-75%
}

.right-0{
  right:0
}

.right-1{
  right:4px
}

.right-2{
  right:8px
}

.right-3{
  right:12px
}

.right-4{
  right:16px
}

.right-5{
  right:20px
}

.right-6{
  right:24px
}

.right-8{
  right:32px
}

.right-10{
  right:40px
}

.right-12{
  right:48px
}

.right-16{
  right:64px
}

.right-18{
  right:72px
}

.right-24{
  right:96px
}

.right-auto{
  right:auto
}

.right-5dot5{
  right:22px
}

.right-full{
  right:100%
}

.-right-1{
  right:-4px
}

.-right-2{
  right:-8px
}

.-right-3{
  right:-12px
}

.-right-4{
  right:-16px
}

.-right-5{
  right:-20px
}

.-right-6{
  right:-24px
}

.-right-8{
  right:-32px
}

.-right-10{
  right:-40px
}

.-right-12{
  right:-48px
}

.-right-16{
  right:-64px
}

.-right-18{
  right:-72px
}

.-right-24{
  right:-96px
}

.-right-5dot5{
  right:-22px
}

.-right-full{
  right:-100%
}

.right-1\/2{
  right:50%
}

.right-1\/3{
  right:33.33333%
}

.right-2\/3{
  right:66.666667%
}

.right-1\/4{
  right:25%
}

.right-2\/4{
  right:50%
}

.right-3\/4{
  right:75%
}

.-right-1\/2{
  right:-50%
}

.-right-1\/3{
  right:-33.333333%
}

.-right-2\/3{
  right:-66.666667%
}

.-right-1\/4{
  right:-25%
}

.-right-2\/4{
  right:-50%
}

.-right-3\/4{
  right:-75%
}

.bottom-0{
  bottom:0
}

.bottom-1{
  bottom:4px
}

.bottom-2{
  bottom:8px
}

.bottom-3{
  bottom:12px
}

.bottom-4{
  bottom:16px
}

.bottom-5{
  bottom:20px
}

.bottom-6{
  bottom:24px
}

.bottom-8{
  bottom:32px
}

.bottom-10{
  bottom:40px
}

.bottom-12{
  bottom:48px
}

.bottom-16{
  bottom:64px
}

.bottom-18{
  bottom:72px
}

.bottom-24{
  bottom:96px
}

.bottom-auto{
  bottom:auto
}

.bottom-5dot5{
  bottom:22px
}

.bottom-full{
  bottom:100%
}

.-bottom-1{
  bottom:-4px
}

.-bottom-2{
  bottom:-8px
}

.-bottom-3{
  bottom:-12px
}

.-bottom-4{
  bottom:-16px
}

.-bottom-5{
  bottom:-20px
}

.-bottom-6{
  bottom:-24px
}

.-bottom-8{
  bottom:-32px
}

.-bottom-10{
  bottom:-40px
}

.-bottom-12{
  bottom:-48px
}

.-bottom-16{
  bottom:-64px
}

.-bottom-18{
  bottom:-72px
}

.-bottom-24{
  bottom:-96px
}

.-bottom-5dot5{
  bottom:-22px
}

.-bottom-full{
  bottom:-100%
}

.bottom-1\/2{
  bottom:50%
}

.bottom-1\/3{
  bottom:33.33333%
}

.bottom-2\/3{
  bottom:66.666667%
}

.bottom-1\/4{
  bottom:25%
}

.bottom-2\/4{
  bottom:50%
}

.bottom-3\/4{
  bottom:75%
}

.-bottom-1\/2{
  bottom:-50%
}

.-bottom-1\/3{
  bottom:-33.333333%
}

.-bottom-2\/3{
  bottom:-66.666667%
}

.-bottom-1\/4{
  bottom:-25%
}

.-bottom-2\/4{
  bottom:-50%
}

.-bottom-3\/4{
  bottom:-75%
}

.left-0{
  left:0
}

.left-1{
  left:4px
}

.left-2{
  left:8px
}

.left-3{
  left:12px
}

.left-4{
  left:16px
}

.left-5{
  left:20px
}

.left-6{
  left:24px
}

.left-8{
  left:32px
}

.left-10{
  left:40px
}

.left-12{
  left:48px
}

.left-16{
  left:64px
}

.left-18{
  left:72px
}

.left-24{
  left:96px
}

.left-auto{
  left:auto
}

.left-5dot5{
  left:22px
}

.left-full{
  left:100%
}

.-left-1{
  left:-4px
}

.-left-2{
  left:-8px
}

.-left-3{
  left:-12px
}

.-left-4{
  left:-16px
}

.-left-5{
  left:-20px
}

.-left-6{
  left:-24px
}

.-left-8{
  left:-32px
}

.-left-10{
  left:-40px
}

.-left-12{
  left:-48px
}

.-left-16{
  left:-64px
}

.-left-18{
  left:-72px
}

.-left-24{
  left:-96px
}

.-left-5dot5{
  left:-22px
}

.-left-full{
  left:-100%
}

.left-1\/2{
  left:50%
}

.left-1\/3{
  left:33.33333%
}

.left-2\/3{
  left:66.666667%
}

.left-1\/4{
  left:25%
}

.left-2\/4{
  left:50%
}

.left-3\/4{
  left:75%
}

.-left-1\/2{
  left:-50%
}

.-left-1\/3{
  left:-33.333333%
}

.-left-2\/3{
  left:-66.666667%
}

.-left-1\/4{
  left:-25%
}

.-left-2\/4{
  left:-50%
}

.-left-3\/4{
  left:-75%
}

.isolate{
  isolation:isolate
}

.isolation-auto{
  isolation:auto
}

.z-0{
  z-index:0
}

.z-10{
  z-index:10
}

.z-20{
  z-index:20
}

.z-30{
  z-index:30
}

.z-40{
  z-index:40
}

.z-50{
  z-index:50
}

.z-auto{
  z-index:auto
}

.focus-within\:z-0:focus-within{
  z-index:0
}

.focus-within\:z-10:focus-within{
  z-index:10
}

.focus-within\:z-20:focus-within{
  z-index:20
}

.focus-within\:z-30:focus-within{
  z-index:30
}

.focus-within\:z-40:focus-within{
  z-index:40
}

.focus-within\:z-50:focus-within{
  z-index:50
}

.focus-within\:z-auto:focus-within{
  z-index:auto
}

.focus\:z-0:focus{
  z-index:0
}

.focus\:z-10:focus{
  z-index:10
}

.focus\:z-20:focus{
  z-index:20
}

.focus\:z-30:focus{
  z-index:30
}

.focus\:z-40:focus{
  z-index:40
}

.focus\:z-50:focus{
  z-index:50
}

.focus\:z-auto:focus{
  z-index:auto
}

.col-auto{
  grid-column:auto
}

.col-span-1{
  grid-column:span 1 / span 1
}

.col-span-2{
  grid-column:span 2 / span 2
}

.col-span-3{
  grid-column:span 3 / span 3
}

.col-span-4{
  grid-column:span 4 / span 4
}

.col-span-5{
  grid-column:span 5 / span 5
}

.col-span-6{
  grid-column:span 6 / span 6
}

.col-span-7{
  grid-column:span 7 / span 7
}

.col-span-8{
  grid-column:span 8 / span 8
}

.col-span-9{
  grid-column:span 9 / span 9
}

.col-span-10{
  grid-column:span 10 / span 10
}

.col-span-11{
  grid-column:span 11 / span 11
}

.col-span-12{
  grid-column:span 12 / span 12
}

.col-span-full{
  grid-column:1 / -1
}

.col-start-1{
  grid-column-start:1
}

.col-start-2{
  grid-column-start:2
}

.col-start-3{
  grid-column-start:3
}

.col-start-4{
  grid-column-start:4
}

.col-start-5{
  grid-column-start:5
}

.col-start-6{
  grid-column-start:6
}

.col-start-7{
  grid-column-start:7
}

.col-start-8{
  grid-column-start:8
}

.col-start-9{
  grid-column-start:9
}

.col-start-10{
  grid-column-start:10
}

.col-start-11{
  grid-column-start:11
}

.col-start-12{
  grid-column-start:12
}

.col-start-13{
  grid-column-start:13
}

.col-start-auto{
  grid-column-start:auto
}

.col-end-1{
  grid-column-end:1
}

.col-end-2{
  grid-column-end:2
}

.col-end-3{
  grid-column-end:3
}

.col-end-4{
  grid-column-end:4
}

.col-end-5{
  grid-column-end:5
}

.col-end-6{
  grid-column-end:6
}

.col-end-7{
  grid-column-end:7
}

.col-end-8{
  grid-column-end:8
}

.col-end-9{
  grid-column-end:9
}

.col-end-10{
  grid-column-end:10
}

.col-end-11{
  grid-column-end:11
}

.col-end-12{
  grid-column-end:12
}

.col-end-13{
  grid-column-end:13
}

.col-end-auto{
  grid-column-end:auto
}

.row-auto{
  grid-row:auto
}

.row-span-1{
  grid-row:span 1 / span 1
}

.row-span-2{
  grid-row:span 2 / span 2
}

.row-span-3{
  grid-row:span 3 / span 3
}

.row-span-4{
  grid-row:span 4 / span 4
}

.row-span-5{
  grid-row:span 5 / span 5
}

.row-span-6{
  grid-row:span 6 / span 6
}

.row-span-full{
  grid-row:1 / -1
}

.row-start-1{
  grid-row-start:1
}

.row-start-2{
  grid-row-start:2
}

.row-start-3{
  grid-row-start:3
}

.row-start-4{
  grid-row-start:4
}

.row-start-5{
  grid-row-start:5
}

.row-start-6{
  grid-row-start:6
}

.row-start-7{
  grid-row-start:7
}

.row-start-auto{
  grid-row-start:auto
}

.row-end-1{
  grid-row-end:1
}

.row-end-2{
  grid-row-end:2
}

.row-end-3{
  grid-row-end:3
}

.row-end-4{
  grid-row-end:4
}

.row-end-5{
  grid-row-end:5
}

.row-end-6{
  grid-row-end:6
}

.row-end-7{
  grid-row-end:7
}

.row-end-auto{
  grid-row-end:auto
}

.m-0{
  margin:0
}

.m-1{
  margin:4px
}

.m-2{
  margin:8px
}

.m-3{
  margin:12px
}

.m-4{
  margin:16px
}

.m-5{
  margin:20px
}

.m-6{
  margin:24px
}

.m-8{
  margin:32px
}

.m-10{
  margin:40px
}

.m-12{
  margin:48px
}

.m-16{
  margin:64px
}

.m-18{
  margin:72px
}

.m-24{
  margin:96px
}

.m-auto{
  margin:auto
}

.m-5dot5{
  margin:22px
}

.m-full{
  margin:100%
}

.-m-1{
  margin:-4px
}

.-m-2{
  margin:-8px
}

.-m-3{
  margin:-12px
}

.-m-4{
  margin:-16px
}

.-m-5{
  margin:-20px
}

.-m-6{
  margin:-24px
}

.-m-8{
  margin:-32px
}

.-m-10{
  margin:-40px
}

.-m-12{
  margin:-48px
}

.-m-16{
  margin:-64px
}

.-m-18{
  margin:-72px
}

.-m-24{
  margin:-96px
}

.-m-5dot5{
  margin:-22px
}

.-m-full{
  margin:-100%
}

.first\:m-0:first-child{
  margin:0
}

.first\:m-1:first-child{
  margin:4px
}

.first\:m-2:first-child{
  margin:8px
}

.first\:m-3:first-child{
  margin:12px
}

.first\:m-4:first-child{
  margin:16px
}

.first\:m-5:first-child{
  margin:20px
}

.first\:m-6:first-child{
  margin:24px
}

.first\:m-8:first-child{
  margin:32px
}

.first\:m-10:first-child{
  margin:40px
}

.first\:m-12:first-child{
  margin:48px
}

.first\:m-16:first-child{
  margin:64px
}

.first\:m-18:first-child{
  margin:72px
}

.first\:m-24:first-child{
  margin:96px
}

.first\:m-auto:first-child{
  margin:auto
}

.first\:m-5dot5:first-child{
  margin:22px
}

.first\:m-full:first-child{
  margin:100%
}

.first\:-m-1:first-child{
  margin:-4px
}

.first\:-m-2:first-child{
  margin:-8px
}

.first\:-m-3:first-child{
  margin:-12px
}

.first\:-m-4:first-child{
  margin:-16px
}

.first\:-m-5:first-child{
  margin:-20px
}

.first\:-m-6:first-child{
  margin:-24px
}

.first\:-m-8:first-child{
  margin:-32px
}

.first\:-m-10:first-child{
  margin:-40px
}

.first\:-m-12:first-child{
  margin:-48px
}

.first\:-m-16:first-child{
  margin:-64px
}

.first\:-m-18:first-child{
  margin:-72px
}

.first\:-m-24:first-child{
  margin:-96px
}

.first\:-m-5dot5:first-child{
  margin:-22px
}

.first\:-m-full:first-child{
  margin:-100%
}

.last\:m-0:last-child{
  margin:0
}

.last\:m-1:last-child{
  margin:4px
}

.last\:m-2:last-child{
  margin:8px
}

.last\:m-3:last-child{
  margin:12px
}

.last\:m-4:last-child{
  margin:16px
}

.last\:m-5:last-child{
  margin:20px
}

.last\:m-6:last-child{
  margin:24px
}

.last\:m-8:last-child{
  margin:32px
}

.last\:m-10:last-child{
  margin:40px
}

.last\:m-12:last-child{
  margin:48px
}

.last\:m-16:last-child{
  margin:64px
}

.last\:m-18:last-child{
  margin:72px
}

.last\:m-24:last-child{
  margin:96px
}

.last\:m-auto:last-child{
  margin:auto
}

.last\:m-5dot5:last-child{
  margin:22px
}

.last\:m-full:last-child{
  margin:100%
}

.last\:-m-1:last-child{
  margin:-4px
}

.last\:-m-2:last-child{
  margin:-8px
}

.last\:-m-3:last-child{
  margin:-12px
}

.last\:-m-4:last-child{
  margin:-16px
}

.last\:-m-5:last-child{
  margin:-20px
}

.last\:-m-6:last-child{
  margin:-24px
}

.last\:-m-8:last-child{
  margin:-32px
}

.last\:-m-10:last-child{
  margin:-40px
}

.last\:-m-12:last-child{
  margin:-48px
}

.last\:-m-16:last-child{
  margin:-64px
}

.last\:-m-18:last-child{
  margin:-72px
}

.last\:-m-24:last-child{
  margin:-96px
}

.last\:-m-5dot5:last-child{
  margin:-22px
}

.last\:-m-full:last-child{
  margin:-100%
}

.mx-0{
  margin-left:0;
  margin-right:0
}

.mx-1{
  margin-left:4px;
  margin-right:4px
}

.mx-2{
  margin-left:8px;
  margin-right:8px
}

.mx-3{
  margin-left:12px;
  margin-right:12px
}

.mx-4{
  margin-left:16px;
  margin-right:16px
}

.mx-5{
  margin-left:20px;
  margin-right:20px
}

.mx-6{
  margin-left:24px;
  margin-right:24px
}

.mx-8{
  margin-left:32px;
  margin-right:32px
}

.mx-10{
  margin-left:40px;
  margin-right:40px
}

.mx-12{
  margin-left:48px;
  margin-right:48px
}

.mx-16{
  margin-left:64px;
  margin-right:64px
}

.mx-18{
  margin-left:72px;
  margin-right:72px
}

.mx-24{
  margin-left:96px;
  margin-right:96px
}

.mx-auto{
  margin-left:auto;
  margin-right:auto
}

.mx-5dot5{
  margin-left:22px;
  margin-right:22px
}

.mx-full{
  margin-left:100%;
  margin-right:100%
}

.-mx-1{
  margin-left:-4px;
  margin-right:-4px
}

.-mx-2{
  margin-left:-8px;
  margin-right:-8px
}

.-mx-3{
  margin-left:-12px;
  margin-right:-12px
}

.-mx-4{
  margin-left:-16px;
  margin-right:-16px
}

.-mx-5{
  margin-left:-20px;
  margin-right:-20px
}

.-mx-6{
  margin-left:-24px;
  margin-right:-24px
}

.-mx-8{
  margin-left:-32px;
  margin-right:-32px
}

.-mx-10{
  margin-left:-40px;
  margin-right:-40px
}

.-mx-12{
  margin-left:-48px;
  margin-right:-48px
}

.-mx-16{
  margin-left:-64px;
  margin-right:-64px
}

.-mx-18{
  margin-left:-72px;
  margin-right:-72px
}

.-mx-24{
  margin-left:-96px;
  margin-right:-96px
}

.-mx-5dot5{
  margin-left:-22px;
  margin-right:-22px
}

.-mx-full{
  margin-left:-100%;
  margin-right:-100%
}

.my-0{
  margin-top:0;
  margin-bottom:0
}

.my-1{
  margin-top:4px;
  margin-bottom:4px
}

.my-2{
  margin-top:8px;
  margin-bottom:8px
}

.my-3{
  margin-top:12px;
  margin-bottom:12px
}

.my-4{
  margin-top:16px;
  margin-bottom:16px
}

.my-5{
  margin-top:20px;
  margin-bottom:20px
}

.my-6{
  margin-top:24px;
  margin-bottom:24px
}

.my-8{
  margin-top:32px;
  margin-bottom:32px
}

.my-10{
  margin-top:40px;
  margin-bottom:40px
}

.my-12{
  margin-top:48px;
  margin-bottom:48px
}

.my-16{
  margin-top:64px;
  margin-bottom:64px
}

.my-18{
  margin-top:72px;
  margin-bottom:72px
}

.my-24{
  margin-top:96px;
  margin-bottom:96px
}

.my-auto{
  margin-top:auto;
  margin-bottom:auto
}

.my-5dot5{
  margin-top:22px;
  margin-bottom:22px
}

.my-full{
  margin-top:100%;
  margin-bottom:100%
}

.-my-1{
  margin-top:-4px;
  margin-bottom:-4px
}

.-my-2{
  margin-top:-8px;
  margin-bottom:-8px
}

.-my-3{
  margin-top:-12px;
  margin-bottom:-12px
}

.-my-4{
  margin-top:-16px;
  margin-bottom:-16px
}

.-my-5{
  margin-top:-20px;
  margin-bottom:-20px
}

.-my-6{
  margin-top:-24px;
  margin-bottom:-24px
}

.-my-8{
  margin-top:-32px;
  margin-bottom:-32px
}

.-my-10{
  margin-top:-40px;
  margin-bottom:-40px
}

.-my-12{
  margin-top:-48px;
  margin-bottom:-48px
}

.-my-16{
  margin-top:-64px;
  margin-bottom:-64px
}

.-my-18{
  margin-top:-72px;
  margin-bottom:-72px
}

.-my-24{
  margin-top:-96px;
  margin-bottom:-96px
}

.-my-5dot5{
  margin-top:-22px;
  margin-bottom:-22px
}

.-my-full{
  margin-top:-100%;
  margin-bottom:-100%
}

.first\:mx-0:first-child{
  margin-left:0;
  margin-right:0
}

.first\:mx-1:first-child{
  margin-left:4px;
  margin-right:4px
}

.first\:mx-2:first-child{
  margin-left:8px;
  margin-right:8px
}

.first\:mx-3:first-child{
  margin-left:12px;
  margin-right:12px
}

.first\:mx-4:first-child{
  margin-left:16px;
  margin-right:16px
}

.first\:mx-5:first-child{
  margin-left:20px;
  margin-right:20px
}

.first\:mx-6:first-child{
  margin-left:24px;
  margin-right:24px
}

.first\:mx-8:first-child{
  margin-left:32px;
  margin-right:32px
}

.first\:mx-10:first-child{
  margin-left:40px;
  margin-right:40px
}

.first\:mx-12:first-child{
  margin-left:48px;
  margin-right:48px
}

.first\:mx-16:first-child{
  margin-left:64px;
  margin-right:64px
}

.first\:mx-18:first-child{
  margin-left:72px;
  margin-right:72px
}

.first\:mx-24:first-child{
  margin-left:96px;
  margin-right:96px
}

.first\:mx-auto:first-child{
  margin-left:auto;
  margin-right:auto
}

.first\:mx-5dot5:first-child{
  margin-left:22px;
  margin-right:22px
}

.first\:mx-full:first-child{
  margin-left:100%;
  margin-right:100%
}

.first\:-mx-1:first-child{
  margin-left:-4px;
  margin-right:-4px
}

.first\:-mx-2:first-child{
  margin-left:-8px;
  margin-right:-8px
}

.first\:-mx-3:first-child{
  margin-left:-12px;
  margin-right:-12px
}

.first\:-mx-4:first-child{
  margin-left:-16px;
  margin-right:-16px
}

.first\:-mx-5:first-child{
  margin-left:-20px;
  margin-right:-20px
}

.first\:-mx-6:first-child{
  margin-left:-24px;
  margin-right:-24px
}

.first\:-mx-8:first-child{
  margin-left:-32px;
  margin-right:-32px
}

.first\:-mx-10:first-child{
  margin-left:-40px;
  margin-right:-40px
}

.first\:-mx-12:first-child{
  margin-left:-48px;
  margin-right:-48px
}

.first\:-mx-16:first-child{
  margin-left:-64px;
  margin-right:-64px
}

.first\:-mx-18:first-child{
  margin-left:-72px;
  margin-right:-72px
}

.first\:-mx-24:first-child{
  margin-left:-96px;
  margin-right:-96px
}

.first\:-mx-5dot5:first-child{
  margin-left:-22px;
  margin-right:-22px
}

.first\:-mx-full:first-child{
  margin-left:-100%;
  margin-right:-100%
}

.first\:my-0:first-child{
  margin-top:0;
  margin-bottom:0
}

.first\:my-1:first-child{
  margin-top:4px;
  margin-bottom:4px
}

.first\:my-2:first-child{
  margin-top:8px;
  margin-bottom:8px
}

.first\:my-3:first-child{
  margin-top:12px;
  margin-bottom:12px
}

.first\:my-4:first-child{
  margin-top:16px;
  margin-bottom:16px
}

.first\:my-5:first-child{
  margin-top:20px;
  margin-bottom:20px
}

.first\:my-6:first-child{
  margin-top:24px;
  margin-bottom:24px
}

.first\:my-8:first-child{
  margin-top:32px;
  margin-bottom:32px
}

.first\:my-10:first-child{
  margin-top:40px;
  margin-bottom:40px
}

.first\:my-12:first-child{
  margin-top:48px;
  margin-bottom:48px
}

.first\:my-16:first-child{
  margin-top:64px;
  margin-bottom:64px
}

.first\:my-18:first-child{
  margin-top:72px;
  margin-bottom:72px
}

.first\:my-24:first-child{
  margin-top:96px;
  margin-bottom:96px
}

.first\:my-auto:first-child{
  margin-top:auto;
  margin-bottom:auto
}

.first\:my-5dot5:first-child{
  margin-top:22px;
  margin-bottom:22px
}

.first\:my-full:first-child{
  margin-top:100%;
  margin-bottom:100%
}

.first\:-my-1:first-child{
  margin-top:-4px;
  margin-bottom:-4px
}

.first\:-my-2:first-child{
  margin-top:-8px;
  margin-bottom:-8px
}

.first\:-my-3:first-child{
  margin-top:-12px;
  margin-bottom:-12px
}

.first\:-my-4:first-child{
  margin-top:-16px;
  margin-bottom:-16px
}

.first\:-my-5:first-child{
  margin-top:-20px;
  margin-bottom:-20px
}

.first\:-my-6:first-child{
  margin-top:-24px;
  margin-bottom:-24px
}

.first\:-my-8:first-child{
  margin-top:-32px;
  margin-bottom:-32px
}

.first\:-my-10:first-child{
  margin-top:-40px;
  margin-bottom:-40px
}

.first\:-my-12:first-child{
  margin-top:-48px;
  margin-bottom:-48px
}

.first\:-my-16:first-child{
  margin-top:-64px;
  margin-bottom:-64px
}

.first\:-my-18:first-child{
  margin-top:-72px;
  margin-bottom:-72px
}

.first\:-my-24:first-child{
  margin-top:-96px;
  margin-bottom:-96px
}

.first\:-my-5dot5:first-child{
  margin-top:-22px;
  margin-bottom:-22px
}

.first\:-my-full:first-child{
  margin-top:-100%;
  margin-bottom:-100%
}

.last\:mx-0:last-child{
  margin-left:0;
  margin-right:0
}

.last\:mx-1:last-child{
  margin-left:4px;
  margin-right:4px
}

.last\:mx-2:last-child{
  margin-left:8px;
  margin-right:8px
}

.last\:mx-3:last-child{
  margin-left:12px;
  margin-right:12px
}

.last\:mx-4:last-child{
  margin-left:16px;
  margin-right:16px
}

.last\:mx-5:last-child{
  margin-left:20px;
  margin-right:20px
}

.last\:mx-6:last-child{
  margin-left:24px;
  margin-right:24px
}

.last\:mx-8:last-child{
  margin-left:32px;
  margin-right:32px
}

.last\:mx-10:last-child{
  margin-left:40px;
  margin-right:40px
}

.last\:mx-12:last-child{
  margin-left:48px;
  margin-right:48px
}

.last\:mx-16:last-child{
  margin-left:64px;
  margin-right:64px
}

.last\:mx-18:last-child{
  margin-left:72px;
  margin-right:72px
}

.last\:mx-24:last-child{
  margin-left:96px;
  margin-right:96px
}

.last\:mx-auto:last-child{
  margin-left:auto;
  margin-right:auto
}

.last\:mx-5dot5:last-child{
  margin-left:22px;
  margin-right:22px
}

.last\:mx-full:last-child{
  margin-left:100%;
  margin-right:100%
}

.last\:-mx-1:last-child{
  margin-left:-4px;
  margin-right:-4px
}

.last\:-mx-2:last-child{
  margin-left:-8px;
  margin-right:-8px
}

.last\:-mx-3:last-child{
  margin-left:-12px;
  margin-right:-12px
}

.last\:-mx-4:last-child{
  margin-left:-16px;
  margin-right:-16px
}

.last\:-mx-5:last-child{
  margin-left:-20px;
  margin-right:-20px
}

.last\:-mx-6:last-child{
  margin-left:-24px;
  margin-right:-24px
}

.last\:-mx-8:last-child{
  margin-left:-32px;
  margin-right:-32px
}

.last\:-mx-10:last-child{
  margin-left:-40px;
  margin-right:-40px
}

.last\:-mx-12:last-child{
  margin-left:-48px;
  margin-right:-48px
}

.last\:-mx-16:last-child{
  margin-left:-64px;
  margin-right:-64px
}

.last\:-mx-18:last-child{
  margin-left:-72px;
  margin-right:-72px
}

.last\:-mx-24:last-child{
  margin-left:-96px;
  margin-right:-96px
}

.last\:-mx-5dot5:last-child{
  margin-left:-22px;
  margin-right:-22px
}

.last\:-mx-full:last-child{
  margin-left:-100%;
  margin-right:-100%
}

.last\:my-0:last-child{
  margin-top:0;
  margin-bottom:0
}

.last\:my-1:last-child{
  margin-top:4px;
  margin-bottom:4px
}

.last\:my-2:last-child{
  margin-top:8px;
  margin-bottom:8px
}

.last\:my-3:last-child{
  margin-top:12px;
  margin-bottom:12px
}

.last\:my-4:last-child{
  margin-top:16px;
  margin-bottom:16px
}

.last\:my-5:last-child{
  margin-top:20px;
  margin-bottom:20px
}

.last\:my-6:last-child{
  margin-top:24px;
  margin-bottom:24px
}

.last\:my-8:last-child{
  margin-top:32px;
  margin-bottom:32px
}

.last\:my-10:last-child{
  margin-top:40px;
  margin-bottom:40px
}

.last\:my-12:last-child{
  margin-top:48px;
  margin-bottom:48px
}

.last\:my-16:last-child{
  margin-top:64px;
  margin-bottom:64px
}

.last\:my-18:last-child{
  margin-top:72px;
  margin-bottom:72px
}

.last\:my-24:last-child{
  margin-top:96px;
  margin-bottom:96px
}

.last\:my-auto:last-child{
  margin-top:auto;
  margin-bottom:auto
}

.last\:my-5dot5:last-child{
  margin-top:22px;
  margin-bottom:22px
}

.last\:my-full:last-child{
  margin-top:100%;
  margin-bottom:100%
}

.last\:-my-1:last-child{
  margin-top:-4px;
  margin-bottom:-4px
}

.last\:-my-2:last-child{
  margin-top:-8px;
  margin-bottom:-8px
}

.last\:-my-3:last-child{
  margin-top:-12px;
  margin-bottom:-12px
}

.last\:-my-4:last-child{
  margin-top:-16px;
  margin-bottom:-16px
}

.last\:-my-5:last-child{
  margin-top:-20px;
  margin-bottom:-20px
}

.last\:-my-6:last-child{
  margin-top:-24px;
  margin-bottom:-24px
}

.last\:-my-8:last-child{
  margin-top:-32px;
  margin-bottom:-32px
}

.last\:-my-10:last-child{
  margin-top:-40px;
  margin-bottom:-40px
}

.last\:-my-12:last-child{
  margin-top:-48px;
  margin-bottom:-48px
}

.last\:-my-16:last-child{
  margin-top:-64px;
  margin-bottom:-64px
}

.last\:-my-18:last-child{
  margin-top:-72px;
  margin-bottom:-72px
}

.last\:-my-24:last-child{
  margin-top:-96px;
  margin-bottom:-96px
}

.last\:-my-5dot5:last-child{
  margin-top:-22px;
  margin-bottom:-22px
}

.last\:-my-full:last-child{
  margin-top:-100%;
  margin-bottom:-100%
}

.mt-0{
  margin-top:0
}

.mt-1{
  margin-top:4px
}

.mt-2{
  margin-top:8px
}

.mt-3{
  margin-top:12px
}

.mt-4{
  margin-top:16px
}

.mt-5{
  margin-top:20px
}

.mt-6{
  margin-top:24px
}

.mt-8{
  margin-top:32px
}

.mt-10{
  margin-top:40px
}

.mt-12{
  margin-top:48px
}

.mt-16{
  margin-top:64px
}

.mt-18{
  margin-top:72px
}

.mt-24{
  margin-top:96px
}

.mt-auto{
  margin-top:auto
}

.mt-5dot5{
  margin-top:22px
}

.mt-full{
  margin-top:100%
}

.-mt-1{
  margin-top:-4px
}

.-mt-2{
  margin-top:-8px
}

.-mt-3{
  margin-top:-12px
}

.-mt-4{
  margin-top:-16px
}

.-mt-5{
  margin-top:-20px
}

.-mt-6{
  margin-top:-24px
}

.-mt-8{
  margin-top:-32px
}

.-mt-10{
  margin-top:-40px
}

.-mt-12{
  margin-top:-48px
}

.-mt-16{
  margin-top:-64px
}

.-mt-18{
  margin-top:-72px
}

.-mt-24{
  margin-top:-96px
}

.-mt-5dot5{
  margin-top:-22px
}

.-mt-full{
  margin-top:-100%
}

.mr-0{
  margin-right:0
}

.mr-1{
  margin-right:4px
}

.mr-2{
  margin-right:8px
}

.mr-3{
  margin-right:12px
}

.mr-4{
  margin-right:16px
}

.mr-5{
  margin-right:20px
}

.mr-6{
  margin-right:24px
}

.mr-8{
  margin-right:32px
}

.mr-10{
  margin-right:40px
}

.mr-12{
  margin-right:48px
}

.mr-16{
  margin-right:64px
}

.mr-18{
  margin-right:72px
}

.mr-24{
  margin-right:96px
}

.mr-auto{
  margin-right:auto
}

.mr-5dot5{
  margin-right:22px
}

.mr-full{
  margin-right:100%
}

.-mr-1{
  margin-right:-4px
}

.-mr-2{
  margin-right:-8px
}

.-mr-3{
  margin-right:-12px
}

.-mr-4{
  margin-right:-16px
}

.-mr-5{
  margin-right:-20px
}

.-mr-6{
  margin-right:-24px
}

.-mr-8{
  margin-right:-32px
}

.-mr-10{
  margin-right:-40px
}

.-mr-12{
  margin-right:-48px
}

.-mr-16{
  margin-right:-64px
}

.-mr-18{
  margin-right:-72px
}

.-mr-24{
  margin-right:-96px
}

.-mr-5dot5{
  margin-right:-22px
}

.-mr-full{
  margin-right:-100%
}

.mb-0{
  margin-bottom:0
}

.mb-1{
  margin-bottom:4px
}

.mb-2{
  margin-bottom:8px
}

.mb-3{
  margin-bottom:12px
}

.mb-4{
  margin-bottom:16px
}

.mb-5{
  margin-bottom:20px
}

.mb-6{
  margin-bottom:24px
}

.mb-8{
  margin-bottom:32px
}

.mb-10{
  margin-bottom:40px
}

.mb-12{
  margin-bottom:48px
}

.mb-16{
  margin-bottom:64px
}

.mb-18{
  margin-bottom:72px
}

.mb-24{
  margin-bottom:96px
}

.mb-auto{
  margin-bottom:auto
}

.mb-5dot5{
  margin-bottom:22px
}

.mb-full{
  margin-bottom:100%
}

.-mb-1{
  margin-bottom:-4px
}

.-mb-2{
  margin-bottom:-8px
}

.-mb-3{
  margin-bottom:-12px
}

.-mb-4{
  margin-bottom:-16px
}

.-mb-5{
  margin-bottom:-20px
}

.-mb-6{
  margin-bottom:-24px
}

.-mb-8{
  margin-bottom:-32px
}

.-mb-10{
  margin-bottom:-40px
}

.-mb-12{
  margin-bottom:-48px
}

.-mb-16{
  margin-bottom:-64px
}

.-mb-18{
  margin-bottom:-72px
}

.-mb-24{
  margin-bottom:-96px
}

.-mb-5dot5{
  margin-bottom:-22px
}

.-mb-full{
  margin-bottom:-100%
}

.ml-0{
  margin-left:0
}

.ml-1{
  margin-left:4px
}

.ml-2{
  margin-left:8px
}

.ml-3{
  margin-left:12px
}

.ml-4{
  margin-left:16px
}

.ml-5{
  margin-left:20px
}

.ml-6{
  margin-left:24px
}

.ml-8{
  margin-left:32px
}

.ml-10{
  margin-left:40px
}

.ml-12{
  margin-left:48px
}

.ml-16{
  margin-left:64px
}

.ml-18{
  margin-left:72px
}

.ml-24{
  margin-left:96px
}

.ml-auto{
  margin-left:auto
}

.ml-5dot5{
  margin-left:22px
}

.ml-full{
  margin-left:100%
}

.-ml-1{
  margin-left:-4px
}

.-ml-2{
  margin-left:-8px
}

.-ml-3{
  margin-left:-12px
}

.-ml-4{
  margin-left:-16px
}

.-ml-5{
  margin-left:-20px
}

.-ml-6{
  margin-left:-24px
}

.-ml-8{
  margin-left:-32px
}

.-ml-10{
  margin-left:-40px
}

.-ml-12{
  margin-left:-48px
}

.-ml-16{
  margin-left:-64px
}

.-ml-18{
  margin-left:-72px
}

.-ml-24{
  margin-left:-96px
}

.-ml-5dot5{
  margin-left:-22px
}

.-ml-full{
  margin-left:-100%
}

.first\:mt-0:first-child{
  margin-top:0
}

.first\:mt-1:first-child{
  margin-top:4px
}

.first\:mt-2:first-child{
  margin-top:8px
}

.first\:mt-3:first-child{
  margin-top:12px
}

.first\:mt-4:first-child{
  margin-top:16px
}

.first\:mt-5:first-child{
  margin-top:20px
}

.first\:mt-6:first-child{
  margin-top:24px
}

.first\:mt-8:first-child{
  margin-top:32px
}

.first\:mt-10:first-child{
  margin-top:40px
}

.first\:mt-12:first-child{
  margin-top:48px
}

.first\:mt-16:first-child{
  margin-top:64px
}

.first\:mt-18:first-child{
  margin-top:72px
}

.first\:mt-24:first-child{
  margin-top:96px
}

.first\:mt-auto:first-child{
  margin-top:auto
}

.first\:mt-5dot5:first-child{
  margin-top:22px
}

.first\:mt-full:first-child{
  margin-top:100%
}

.first\:-mt-1:first-child{
  margin-top:-4px
}

.first\:-mt-2:first-child{
  margin-top:-8px
}

.first\:-mt-3:first-child{
  margin-top:-12px
}

.first\:-mt-4:first-child{
  margin-top:-16px
}

.first\:-mt-5:first-child{
  margin-top:-20px
}

.first\:-mt-6:first-child{
  margin-top:-24px
}

.first\:-mt-8:first-child{
  margin-top:-32px
}

.first\:-mt-10:first-child{
  margin-top:-40px
}

.first\:-mt-12:first-child{
  margin-top:-48px
}

.first\:-mt-16:first-child{
  margin-top:-64px
}

.first\:-mt-18:first-child{
  margin-top:-72px
}

.first\:-mt-24:first-child{
  margin-top:-96px
}

.first\:-mt-5dot5:first-child{
  margin-top:-22px
}

.first\:-mt-full:first-child{
  margin-top:-100%
}

.first\:mr-0:first-child{
  margin-right:0
}

.first\:mr-1:first-child{
  margin-right:4px
}

.first\:mr-2:first-child{
  margin-right:8px
}

.first\:mr-3:first-child{
  margin-right:12px
}

.first\:mr-4:first-child{
  margin-right:16px
}

.first\:mr-5:first-child{
  margin-right:20px
}

.first\:mr-6:first-child{
  margin-right:24px
}

.first\:mr-8:first-child{
  margin-right:32px
}

.first\:mr-10:first-child{
  margin-right:40px
}

.first\:mr-12:first-child{
  margin-right:48px
}

.first\:mr-16:first-child{
  margin-right:64px
}

.first\:mr-18:first-child{
  margin-right:72px
}

.first\:mr-24:first-child{
  margin-right:96px
}

.first\:mr-auto:first-child{
  margin-right:auto
}

.first\:mr-5dot5:first-child{
  margin-right:22px
}

.first\:mr-full:first-child{
  margin-right:100%
}

.first\:-mr-1:first-child{
  margin-right:-4px
}

.first\:-mr-2:first-child{
  margin-right:-8px
}

.first\:-mr-3:first-child{
  margin-right:-12px
}

.first\:-mr-4:first-child{
  margin-right:-16px
}

.first\:-mr-5:first-child{
  margin-right:-20px
}

.first\:-mr-6:first-child{
  margin-right:-24px
}

.first\:-mr-8:first-child{
  margin-right:-32px
}

.first\:-mr-10:first-child{
  margin-right:-40px
}

.first\:-mr-12:first-child{
  margin-right:-48px
}

.first\:-mr-16:first-child{
  margin-right:-64px
}

.first\:-mr-18:first-child{
  margin-right:-72px
}

.first\:-mr-24:first-child{
  margin-right:-96px
}

.first\:-mr-5dot5:first-child{
  margin-right:-22px
}

.first\:-mr-full:first-child{
  margin-right:-100%
}

.first\:mb-0:first-child{
  margin-bottom:0
}

.first\:mb-1:first-child{
  margin-bottom:4px
}

.first\:mb-2:first-child{
  margin-bottom:8px
}

.first\:mb-3:first-child{
  margin-bottom:12px
}

.first\:mb-4:first-child{
  margin-bottom:16px
}

.first\:mb-5:first-child{
  margin-bottom:20px
}

.first\:mb-6:first-child{
  margin-bottom:24px
}

.first\:mb-8:first-child{
  margin-bottom:32px
}

.first\:mb-10:first-child{
  margin-bottom:40px
}

.first\:mb-12:first-child{
  margin-bottom:48px
}

.first\:mb-16:first-child{
  margin-bottom:64px
}

.first\:mb-18:first-child{
  margin-bottom:72px
}

.first\:mb-24:first-child{
  margin-bottom:96px
}

.first\:mb-auto:first-child{
  margin-bottom:auto
}

.first\:mb-5dot5:first-child{
  margin-bottom:22px
}

.first\:mb-full:first-child{
  margin-bottom:100%
}

.first\:-mb-1:first-child{
  margin-bottom:-4px
}

.first\:-mb-2:first-child{
  margin-bottom:-8px
}

.first\:-mb-3:first-child{
  margin-bottom:-12px
}

.first\:-mb-4:first-child{
  margin-bottom:-16px
}

.first\:-mb-5:first-child{
  margin-bottom:-20px
}

.first\:-mb-6:first-child{
  margin-bottom:-24px
}

.first\:-mb-8:first-child{
  margin-bottom:-32px
}

.first\:-mb-10:first-child{
  margin-bottom:-40px
}

.first\:-mb-12:first-child{
  margin-bottom:-48px
}

.first\:-mb-16:first-child{
  margin-bottom:-64px
}

.first\:-mb-18:first-child{
  margin-bottom:-72px
}

.first\:-mb-24:first-child{
  margin-bottom:-96px
}

.first\:-mb-5dot5:first-child{
  margin-bottom:-22px
}

.first\:-mb-full:first-child{
  margin-bottom:-100%
}

.first\:ml-0:first-child{
  margin-left:0
}

.first\:ml-1:first-child{
  margin-left:4px
}

.first\:ml-2:first-child{
  margin-left:8px
}

.first\:ml-3:first-child{
  margin-left:12px
}

.first\:ml-4:first-child{
  margin-left:16px
}

.first\:ml-5:first-child{
  margin-left:20px
}

.first\:ml-6:first-child{
  margin-left:24px
}

.first\:ml-8:first-child{
  margin-left:32px
}

.first\:ml-10:first-child{
  margin-left:40px
}

.first\:ml-12:first-child{
  margin-left:48px
}

.first\:ml-16:first-child{
  margin-left:64px
}

.first\:ml-18:first-child{
  margin-left:72px
}

.first\:ml-24:first-child{
  margin-left:96px
}

.first\:ml-auto:first-child{
  margin-left:auto
}

.first\:ml-5dot5:first-child{
  margin-left:22px
}

.first\:ml-full:first-child{
  margin-left:100%
}

.first\:-ml-1:first-child{
  margin-left:-4px
}

.first\:-ml-2:first-child{
  margin-left:-8px
}

.first\:-ml-3:first-child{
  margin-left:-12px
}

.first\:-ml-4:first-child{
  margin-left:-16px
}

.first\:-ml-5:first-child{
  margin-left:-20px
}

.first\:-ml-6:first-child{
  margin-left:-24px
}

.first\:-ml-8:first-child{
  margin-left:-32px
}

.first\:-ml-10:first-child{
  margin-left:-40px
}

.first\:-ml-12:first-child{
  margin-left:-48px
}

.first\:-ml-16:first-child{
  margin-left:-64px
}

.first\:-ml-18:first-child{
  margin-left:-72px
}

.first\:-ml-24:first-child{
  margin-left:-96px
}

.first\:-ml-5dot5:first-child{
  margin-left:-22px
}

.first\:-ml-full:first-child{
  margin-left:-100%
}

.last\:mt-0:last-child{
  margin-top:0
}

.last\:mt-1:last-child{
  margin-top:4px
}

.last\:mt-2:last-child{
  margin-top:8px
}

.last\:mt-3:last-child{
  margin-top:12px
}

.last\:mt-4:last-child{
  margin-top:16px
}

.last\:mt-5:last-child{
  margin-top:20px
}

.last\:mt-6:last-child{
  margin-top:24px
}

.last\:mt-8:last-child{
  margin-top:32px
}

.last\:mt-10:last-child{
  margin-top:40px
}

.last\:mt-12:last-child{
  margin-top:48px
}

.last\:mt-16:last-child{
  margin-top:64px
}

.last\:mt-18:last-child{
  margin-top:72px
}

.last\:mt-24:last-child{
  margin-top:96px
}

.last\:mt-auto:last-child{
  margin-top:auto
}

.last\:mt-5dot5:last-child{
  margin-top:22px
}

.last\:mt-full:last-child{
  margin-top:100%
}

.last\:-mt-1:last-child{
  margin-top:-4px
}

.last\:-mt-2:last-child{
  margin-top:-8px
}

.last\:-mt-3:last-child{
  margin-top:-12px
}

.last\:-mt-4:last-child{
  margin-top:-16px
}

.last\:-mt-5:last-child{
  margin-top:-20px
}

.last\:-mt-6:last-child{
  margin-top:-24px
}

.last\:-mt-8:last-child{
  margin-top:-32px
}

.last\:-mt-10:last-child{
  margin-top:-40px
}

.last\:-mt-12:last-child{
  margin-top:-48px
}

.last\:-mt-16:last-child{
  margin-top:-64px
}

.last\:-mt-18:last-child{
  margin-top:-72px
}

.last\:-mt-24:last-child{
  margin-top:-96px
}

.last\:-mt-5dot5:last-child{
  margin-top:-22px
}

.last\:-mt-full:last-child{
  margin-top:-100%
}

.last\:mr-0:last-child{
  margin-right:0
}

.last\:mr-1:last-child{
  margin-right:4px
}

.last\:mr-2:last-child{
  margin-right:8px
}

.last\:mr-3:last-child{
  margin-right:12px
}

.last\:mr-4:last-child{
  margin-right:16px
}

.last\:mr-5:last-child{
  margin-right:20px
}

.last\:mr-6:last-child{
  margin-right:24px
}

.last\:mr-8:last-child{
  margin-right:32px
}

.last\:mr-10:last-child{
  margin-right:40px
}

.last\:mr-12:last-child{
  margin-right:48px
}

.last\:mr-16:last-child{
  margin-right:64px
}

.last\:mr-18:last-child{
  margin-right:72px
}

.last\:mr-24:last-child{
  margin-right:96px
}

.last\:mr-auto:last-child{
  margin-right:auto
}

.last\:mr-5dot5:last-child{
  margin-right:22px
}

.last\:mr-full:last-child{
  margin-right:100%
}

.last\:-mr-1:last-child{
  margin-right:-4px
}

.last\:-mr-2:last-child{
  margin-right:-8px
}

.last\:-mr-3:last-child{
  margin-right:-12px
}

.last\:-mr-4:last-child{
  margin-right:-16px
}

.last\:-mr-5:last-child{
  margin-right:-20px
}

.last\:-mr-6:last-child{
  margin-right:-24px
}

.last\:-mr-8:last-child{
  margin-right:-32px
}

.last\:-mr-10:last-child{
  margin-right:-40px
}

.last\:-mr-12:last-child{
  margin-right:-48px
}

.last\:-mr-16:last-child{
  margin-right:-64px
}

.last\:-mr-18:last-child{
  margin-right:-72px
}

.last\:-mr-24:last-child{
  margin-right:-96px
}

.last\:-mr-5dot5:last-child{
  margin-right:-22px
}

.last\:-mr-full:last-child{
  margin-right:-100%
}

.last\:mb-0:last-child{
  margin-bottom:0
}

.last\:mb-1:last-child{
  margin-bottom:4px
}

.last\:mb-2:last-child{
  margin-bottom:8px
}

.last\:mb-3:last-child{
  margin-bottom:12px
}

.last\:mb-4:last-child{
  margin-bottom:16px
}

.last\:mb-5:last-child{
  margin-bottom:20px
}

.last\:mb-6:last-child{
  margin-bottom:24px
}

.last\:mb-8:last-child{
  margin-bottom:32px
}

.last\:mb-10:last-child{
  margin-bottom:40px
}

.last\:mb-12:last-child{
  margin-bottom:48px
}

.last\:mb-16:last-child{
  margin-bottom:64px
}

.last\:mb-18:last-child{
  margin-bottom:72px
}

.last\:mb-24:last-child{
  margin-bottom:96px
}

.last\:mb-auto:last-child{
  margin-bottom:auto
}

.last\:mb-5dot5:last-child{
  margin-bottom:22px
}

.last\:mb-full:last-child{
  margin-bottom:100%
}

.last\:-mb-1:last-child{
  margin-bottom:-4px
}

.last\:-mb-2:last-child{
  margin-bottom:-8px
}

.last\:-mb-3:last-child{
  margin-bottom:-12px
}

.last\:-mb-4:last-child{
  margin-bottom:-16px
}

.last\:-mb-5:last-child{
  margin-bottom:-20px
}

.last\:-mb-6:last-child{
  margin-bottom:-24px
}

.last\:-mb-8:last-child{
  margin-bottom:-32px
}

.last\:-mb-10:last-child{
  margin-bottom:-40px
}

.last\:-mb-12:last-child{
  margin-bottom:-48px
}

.last\:-mb-16:last-child{
  margin-bottom:-64px
}

.last\:-mb-18:last-child{
  margin-bottom:-72px
}

.last\:-mb-24:last-child{
  margin-bottom:-96px
}

.last\:-mb-5dot5:last-child{
  margin-bottom:-22px
}

.last\:-mb-full:last-child{
  margin-bottom:-100%
}

.last\:ml-0:last-child{
  margin-left:0
}

.last\:ml-1:last-child{
  margin-left:4px
}

.last\:ml-2:last-child{
  margin-left:8px
}

.last\:ml-3:last-child{
  margin-left:12px
}

.last\:ml-4:last-child{
  margin-left:16px
}

.last\:ml-5:last-child{
  margin-left:20px
}

.last\:ml-6:last-child{
  margin-left:24px
}

.last\:ml-8:last-child{
  margin-left:32px
}

.last\:ml-10:last-child{
  margin-left:40px
}

.last\:ml-12:last-child{
  margin-left:48px
}

.last\:ml-16:last-child{
  margin-left:64px
}

.last\:ml-18:last-child{
  margin-left:72px
}

.last\:ml-24:last-child{
  margin-left:96px
}

.last\:ml-auto:last-child{
  margin-left:auto
}

.last\:ml-5dot5:last-child{
  margin-left:22px
}

.last\:ml-full:last-child{
  margin-left:100%
}

.last\:-ml-1:last-child{
  margin-left:-4px
}

.last\:-ml-2:last-child{
  margin-left:-8px
}

.last\:-ml-3:last-child{
  margin-left:-12px
}

.last\:-ml-4:last-child{
  margin-left:-16px
}

.last\:-ml-5:last-child{
  margin-left:-20px
}

.last\:-ml-6:last-child{
  margin-left:-24px
}

.last\:-ml-8:last-child{
  margin-left:-32px
}

.last\:-ml-10:last-child{
  margin-left:-40px
}

.last\:-ml-12:last-child{
  margin-left:-48px
}

.last\:-ml-16:last-child{
  margin-left:-64px
}

.last\:-ml-18:last-child{
  margin-left:-72px
}

.last\:-ml-24:last-child{
  margin-left:-96px
}

.last\:-ml-5dot5:last-child{
  margin-left:-22px
}

.last\:-ml-full:last-child{
  margin-left:-100%
}

.block{
  display:block
}

.inline-block{
  display:inline-block
}

.inline{
  display:inline
}

.flex{
  display:flex
}

.inline-flex{
  display:inline-flex
}

.table{
  display:table
}

.inline-table{
  display:inline-table
}

.table-caption{
  display:table-caption
}

.table-cell{
  display:table-cell
}

.table-column{
  display:table-column
}

.table-column-group{
  display:table-column-group
}

.table-footer-group{
  display:table-footer-group
}

.table-header-group{
  display:table-header-group
}

.table-row-group{
  display:table-row-group
}

.table-row{
  display:table-row
}

.flow-root{
  display:flow-root
}

.grid{
  display:grid
}

.inline-grid{
  display:inline-grid
}

.contents{
  display:contents
}

.list-item{
  display:list-item
}

.hidden{
  display:none
}

.h-0{
  height:0
}

.h-1{
  height:4px
}

.h-2{
  height:8px
}

.h-3{
  height:12px
}

.h-4{
  height:16px
}

.h-5{
  height:20px
}

.h-6{
  height:24px
}

.h-8{
  height:32px
}

.h-10{
  height:40px
}

.h-12{
  height:48px
}

.h-15{
  height:60px
}

.h-16{
  height:64px
}

.h-18{
  height:72px
}

.h-24{
  height:96px
}

.h-auto{
  height:auto
}

.h-5dot5{
  height:22px
}

.h-full{
  height:100%
}

.h-1\/2{
  height:50%
}

.h-1\/3{
  height:33.333333%
}

.h-2\/3{
  height:66.666667%
}

.h-1\/4{
  height:25%
}

.h-2\/4{
  height:50%
}

.h-3\/4{
  height:75%
}

.h-1\/5{
  height:20%
}

.h-2\/5{
  height:40%
}

.h-3\/5{
  height:60%
}

.h-4\/5{
  height:80%
}

.h-1\/6{
  height:16.666667%
}

.h-2\/6{
  height:33.333333%
}

.h-3\/6{
  height:50%
}

.h-4\/6{
  height:66.666667%
}

.h-5\/6{
  height:83.333333%
}

.h-screen{
  height:100vh
}

.max-h-0{
  max-height:0
}

.max-h-1{
  max-height:4px
}

.max-h-2{
  max-height:8px
}

.max-h-3{
  max-height:12px
}

.max-h-4{
  max-height:16px
}

.max-h-5{
  max-height:20px
}

.max-h-6{
  max-height:24px
}

.max-h-8{
  max-height:32px
}

.max-h-10{
  max-height:40px
}

.max-h-12{
  max-height:48px
}

.max-h-16{
  max-height:64px
}

.max-h-18{
  max-height:72px
}

.max-h-24{
  max-height:96px
}

.max-h-5dot5{
  max-height:22px
}

.max-h-full{
  max-height:100%
}

.max-h-screen{
  max-height:100vh
}

.min-h-0{
  min-height:0px
}

.min-h-6{
  min-height:24px
}

.min-h-10{
  min-height:40px
}

.min-h-15{
  min-height:60px
}

.min-h-100{
  min-height:400px
}

.min-h-full{
  min-height:100%
}

.min-h-screen{
  min-height:100vh
}

.min-h-100vh{
  min-height:100vh
}

.w-0{
  width:0
}

.w-1{
  width:4px
}

.w-2{
  width:8px
}

.w-3{
  width:12px
}

.w-4{
  width:16px
}

.w-5{
  width:20px
}

.w-6{
  width:24px
}

.w-8{
  width:32px
}

.w-10{
  width:40px
}

.w-12{
  width:48px
}

.w-16{
  width:64px
}

.w-18{
  width:72px
}

.w-24{
  width:96px
}

.w-25{
  width:100px
}

.w-26{
  width:104px
}

.w-30{
  width:120px
}

.w-80{
  width:320px
}

.w-125{
  width:500px
}

.w-160{
  width:640px
}

.w-180{
  width:720px
}

.w-200{
  width:800px
}

.w-240{
  width:960px
}

.w-320{
  width:1280px
}

.w-auto{
  width:auto
}

.w-5dot5{
  width:22px
}

.w-full{
  width:100%
}

.w-1\/2{
  width:50%
}

.w-1\/3{
  width:33.333333%
}

.w-2\/3{
  width:66.666667%
}

.w-1\/4{
  width:25%
}

.w-2\/4{
  width:50%
}

.w-3\/4{
  width:75%
}

.w-1\/5{
  width:20%
}

.w-2\/5{
  width:40%
}

.w-3\/5{
  width:60%
}

.w-4\/5{
  width:80%
}

.w-1\/6{
  width:16.666667%
}

.w-2\/6{
  width:33.333333%
}

.w-3\/6{
  width:50%
}

.w-4\/6{
  width:66.666667%
}

.w-5\/6{
  width:83.333333%
}

.w-1\/12{
  width:8.333333%
}

.w-2\/12{
  width:16.666667%
}

.w-3\/12{
  width:25%
}

.w-4\/12{
  width:33.333333%
}

.w-5\/12{
  width:41.666667%
}

.w-6\/12{
  width:50%
}

.w-7\/12{
  width:58.333333%
}

.w-8\/12{
  width:66.666667%
}

.w-9\/12{
  width:75%
}

.w-10\/12{
  width:83.333333%
}

.w-11\/12{
  width:91.666667%
}

.w-screen{
  width:100vw
}

.w-min{
  width:min-content
}

.w-max{
  width:max-content
}

.min-w-0{
  min-width:0px
}

.min-w-full{
  min-width:100%
}

.min-w-min{
  min-width:min-content
}

.min-w-max{
  min-width:max-content
}

.max-w-0{
  max-width:0rem
}

.max-w-none{
  max-width:none
}

.max-w-xs{
  max-width:20rem
}

.max-w-sm{
  max-width:24rem
}

.max-w-md{
  max-width:28rem
}

.max-w-lg{
  max-width:32rem
}

.max-w-xl{
  max-width:36rem
}

.max-w-2xl{
  max-width:42rem
}

.max-w-3xl{
  max-width:48rem
}

.max-w-4xl{
  max-width:56rem
}

.max-w-5xl{
  max-width:64rem
}

.max-w-6xl{
  max-width:72rem
}

.max-w-7xl{
  max-width:80rem
}

.max-w-full{
  max-width:100%
}

.max-w-min{
  max-width:min-content
}

.max-w-max{
  max-width:max-content
}

.max-w-prose{
  max-width:65ch
}

.max-w-grid-12{
  max-width:1176px
}

.max-w-grid-10{
  max-width:984px
}

.max-w-grid-8{
  max-width:768px
}

.max-w-1\/4{
  max-width:25%
}

.max-w-1\/2{
  max-width:50%
}

.max-w-3\/4{
  max-width:75%
}

.max-w-1\/5{
  max-width:20%
}

.max-w-2\/5{
  max-width:40%
}

.max-w-3\/5{
  max-width:60%
}

.max-w-4\/5{
  max-width:80%
}

.max-w-100vw{
  max-width:100vw
}

.flex-1{
  flex:1 1 0%
}

.flex-2{
  flex:2 1 0%
}

.flex-3{
  flex:3 1 0%
}

.flex-auto{
  flex:1 1 auto
}

.flex-initial{
  flex:0 1 auto
}

.flex-none{
  flex:none
}

.flex-shrink-0{
  flex-shrink:0
}

.flex-shrink{
  flex-shrink:1
}

.flex-grow-0{
  flex-grow:0
}

.flex-grow{
  flex-grow:1
}

.border-collapse{
  border-collapse:collapse
}

.border-separate{
  border-collapse:separate
}

.transform{
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform-gpu{
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  transform:translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform-none{
  transform:none
}

.translate-x-0{
  --tw-translate-x:0
}

.translate-x-1{
  --tw-translate-x:4px
}

.translate-x-2{
  --tw-translate-x:8px
}

.translate-x-3{
  --tw-translate-x:12px
}

.translate-x-4{
  --tw-translate-x:16px
}

.translate-x-5{
  --tw-translate-x:20px
}

.translate-x-6{
  --tw-translate-x:24px
}

.translate-x-8{
  --tw-translate-x:32px
}

.translate-x-10{
  --tw-translate-x:40px
}

.translate-x-12{
  --tw-translate-x:48px
}

.translate-x-16{
  --tw-translate-x:64px
}

.translate-x-18{
  --tw-translate-x:72px
}

.translate-x-24{
  --tw-translate-x:96px
}

.translate-x-5dot5{
  --tw-translate-x:22px
}

.translate-x-full{
  --tw-translate-x:100%
}

.-translate-x-1{
  --tw-translate-x:-4px
}

.-translate-x-2{
  --tw-translate-x:-8px
}

.-translate-x-3{
  --tw-translate-x:-12px
}

.-translate-x-4{
  --tw-translate-x:-16px
}

.-translate-x-5{
  --tw-translate-x:-20px
}

.-translate-x-6{
  --tw-translate-x:-24px
}

.-translate-x-8{
  --tw-translate-x:-32px
}

.-translate-x-10{
  --tw-translate-x:-40px
}

.-translate-x-12{
  --tw-translate-x:-48px
}

.-translate-x-16{
  --tw-translate-x:-64px
}

.-translate-x-18{
  --tw-translate-x:-72px
}

.-translate-x-24{
  --tw-translate-x:-96px
}

.-translate-x-5dot5{
  --tw-translate-x:-22px
}

.-translate-x-full{
  --tw-translate-x:-100%
}

.translate-x-1\/2{
  --tw-translate-x:50%
}

.translate-x-1\/3{
  --tw-translate-x:33.333333%
}

.translate-x-2\/3{
  --tw-translate-x:66.666667%
}

.translate-x-1\/4{
  --tw-translate-x:25%
}

.translate-x-2\/4{
  --tw-translate-x:50%
}

.translate-x-3\/4{
  --tw-translate-x:75%
}

.-translate-x-1\/2{
  --tw-translate-x:-50%
}

.-translate-x-1\/3{
  --tw-translate-x:-33.333333%
}

.-translate-x-2\/3{
  --tw-translate-x:-66.666667%
}

.-translate-x-1\/4{
  --tw-translate-x:-25%
}

.-translate-x-2\/4{
  --tw-translate-x:-50%
}

.-translate-x-3\/4{
  --tw-translate-x:-75%
}

.translate-y-0{
  --tw-translate-y:0
}

.translate-y-1{
  --tw-translate-y:4px
}

.translate-y-2{
  --tw-translate-y:8px
}

.translate-y-3{
  --tw-translate-y:12px
}

.translate-y-4{
  --tw-translate-y:16px
}

.translate-y-5{
  --tw-translate-y:20px
}

.translate-y-6{
  --tw-translate-y:24px
}

.translate-y-8{
  --tw-translate-y:32px
}

.translate-y-10{
  --tw-translate-y:40px
}

.translate-y-12{
  --tw-translate-y:48px
}

.translate-y-16{
  --tw-translate-y:64px
}

.translate-y-18{
  --tw-translate-y:72px
}

.translate-y-24{
  --tw-translate-y:96px
}

.translate-y-5dot5{
  --tw-translate-y:22px
}

.translate-y-full{
  --tw-translate-y:100%
}

.-translate-y-1{
  --tw-translate-y:-4px
}

.-translate-y-2{
  --tw-translate-y:-8px
}

.-translate-y-3{
  --tw-translate-y:-12px
}

.-translate-y-4{
  --tw-translate-y:-16px
}

.-translate-y-5{
  --tw-translate-y:-20px
}

.-translate-y-6{
  --tw-translate-y:-24px
}

.-translate-y-8{
  --tw-translate-y:-32px
}

.-translate-y-10{
  --tw-translate-y:-40px
}

.-translate-y-12{
  --tw-translate-y:-48px
}

.-translate-y-16{
  --tw-translate-y:-64px
}

.-translate-y-18{
  --tw-translate-y:-72px
}

.-translate-y-24{
  --tw-translate-y:-96px
}

.-translate-y-5dot5{
  --tw-translate-y:-22px
}

.-translate-y-full{
  --tw-translate-y:-100%
}

.translate-y-1\/2{
  --tw-translate-y:50%
}

.translate-y-1\/3{
  --tw-translate-y:33.333333%
}

.translate-y-2\/3{
  --tw-translate-y:66.666667%
}

.translate-y-1\/4{
  --tw-translate-y:25%
}

.translate-y-2\/4{
  --tw-translate-y:50%
}

.translate-y-3\/4{
  --tw-translate-y:75%
}

.-translate-y-1\/2{
  --tw-translate-y:-50%
}

.-translate-y-1\/3{
  --tw-translate-y:-33.333333%
}

.-translate-y-2\/3{
  --tw-translate-y:-66.666667%
}

.-translate-y-1\/4{
  --tw-translate-y:-25%
}

.-translate-y-2\/4{
  --tw-translate-y:-50%
}

.-translate-y-3\/4{
  --tw-translate-y:-75%
}

.hover\:translate-x-0:hover{
  --tw-translate-x:0
}

.hover\:translate-x-1:hover{
  --tw-translate-x:4px
}

.hover\:translate-x-2:hover{
  --tw-translate-x:8px
}

.hover\:translate-x-3:hover{
  --tw-translate-x:12px
}

.hover\:translate-x-4:hover{
  --tw-translate-x:16px
}

.hover\:translate-x-5:hover{
  --tw-translate-x:20px
}

.hover\:translate-x-6:hover{
  --tw-translate-x:24px
}

.hover\:translate-x-8:hover{
  --tw-translate-x:32px
}

.hover\:translate-x-10:hover{
  --tw-translate-x:40px
}

.hover\:translate-x-12:hover{
  --tw-translate-x:48px
}

.hover\:translate-x-16:hover{
  --tw-translate-x:64px
}

.hover\:translate-x-18:hover{
  --tw-translate-x:72px
}

.hover\:translate-x-24:hover{
  --tw-translate-x:96px
}

.hover\:translate-x-5dot5:hover{
  --tw-translate-x:22px
}

.hover\:translate-x-full:hover{
  --tw-translate-x:100%
}

.hover\:-translate-x-1:hover{
  --tw-translate-x:-4px
}

.hover\:-translate-x-2:hover{
  --tw-translate-x:-8px
}

.hover\:-translate-x-3:hover{
  --tw-translate-x:-12px
}

.hover\:-translate-x-4:hover{
  --tw-translate-x:-16px
}

.hover\:-translate-x-5:hover{
  --tw-translate-x:-20px
}

.hover\:-translate-x-6:hover{
  --tw-translate-x:-24px
}

.hover\:-translate-x-8:hover{
  --tw-translate-x:-32px
}

.hover\:-translate-x-10:hover{
  --tw-translate-x:-40px
}

.hover\:-translate-x-12:hover{
  --tw-translate-x:-48px
}

.hover\:-translate-x-16:hover{
  --tw-translate-x:-64px
}

.hover\:-translate-x-18:hover{
  --tw-translate-x:-72px
}

.hover\:-translate-x-24:hover{
  --tw-translate-x:-96px
}

.hover\:-translate-x-5dot5:hover{
  --tw-translate-x:-22px
}

.hover\:-translate-x-full:hover{
  --tw-translate-x:-100%
}

.hover\:translate-x-1\/2:hover{
  --tw-translate-x:50%
}

.hover\:translate-x-1\/3:hover{
  --tw-translate-x:33.333333%
}

.hover\:translate-x-2\/3:hover{
  --tw-translate-x:66.666667%
}

.hover\:translate-x-1\/4:hover{
  --tw-translate-x:25%
}

.hover\:translate-x-2\/4:hover{
  --tw-translate-x:50%
}

.hover\:translate-x-3\/4:hover{
  --tw-translate-x:75%
}

.hover\:-translate-x-1\/2:hover{
  --tw-translate-x:-50%
}

.hover\:-translate-x-1\/3:hover{
  --tw-translate-x:-33.333333%
}

.hover\:-translate-x-2\/3:hover{
  --tw-translate-x:-66.666667%
}

.hover\:-translate-x-1\/4:hover{
  --tw-translate-x:-25%
}

.hover\:-translate-x-2\/4:hover{
  --tw-translate-x:-50%
}

.hover\:-translate-x-3\/4:hover{
  --tw-translate-x:-75%
}

.hover\:translate-y-0:hover{
  --tw-translate-y:0
}

.hover\:translate-y-1:hover{
  --tw-translate-y:4px
}

.hover\:translate-y-2:hover{
  --tw-translate-y:8px
}

.hover\:translate-y-3:hover{
  --tw-translate-y:12px
}

.hover\:translate-y-4:hover{
  --tw-translate-y:16px
}

.hover\:translate-y-5:hover{
  --tw-translate-y:20px
}

.hover\:translate-y-6:hover{
  --tw-translate-y:24px
}

.hover\:translate-y-8:hover{
  --tw-translate-y:32px
}

.hover\:translate-y-10:hover{
  --tw-translate-y:40px
}

.hover\:translate-y-12:hover{
  --tw-translate-y:48px
}

.hover\:translate-y-16:hover{
  --tw-translate-y:64px
}

.hover\:translate-y-18:hover{
  --tw-translate-y:72px
}

.hover\:translate-y-24:hover{
  --tw-translate-y:96px
}

.hover\:translate-y-5dot5:hover{
  --tw-translate-y:22px
}

.hover\:translate-y-full:hover{
  --tw-translate-y:100%
}

.hover\:-translate-y-1:hover{
  --tw-translate-y:-4px
}

.hover\:-translate-y-2:hover{
  --tw-translate-y:-8px
}

.hover\:-translate-y-3:hover{
  --tw-translate-y:-12px
}

.hover\:-translate-y-4:hover{
  --tw-translate-y:-16px
}

.hover\:-translate-y-5:hover{
  --tw-translate-y:-20px
}

.hover\:-translate-y-6:hover{
  --tw-translate-y:-24px
}

.hover\:-translate-y-8:hover{
  --tw-translate-y:-32px
}

.hover\:-translate-y-10:hover{
  --tw-translate-y:-40px
}

.hover\:-translate-y-12:hover{
  --tw-translate-y:-48px
}

.hover\:-translate-y-16:hover{
  --tw-translate-y:-64px
}

.hover\:-translate-y-18:hover{
  --tw-translate-y:-72px
}

.hover\:-translate-y-24:hover{
  --tw-translate-y:-96px
}

.hover\:-translate-y-5dot5:hover{
  --tw-translate-y:-22px
}

.hover\:-translate-y-full:hover{
  --tw-translate-y:-100%
}

.hover\:translate-y-1\/2:hover{
  --tw-translate-y:50%
}

.hover\:translate-y-1\/3:hover{
  --tw-translate-y:33.333333%
}

.hover\:translate-y-2\/3:hover{
  --tw-translate-y:66.666667%
}

.hover\:translate-y-1\/4:hover{
  --tw-translate-y:25%
}

.hover\:translate-y-2\/4:hover{
  --tw-translate-y:50%
}

.hover\:translate-y-3\/4:hover{
  --tw-translate-y:75%
}

.hover\:-translate-y-1\/2:hover{
  --tw-translate-y:-50%
}

.hover\:-translate-y-1\/3:hover{
  --tw-translate-y:-33.333333%
}

.hover\:-translate-y-2\/3:hover{
  --tw-translate-y:-66.666667%
}

.hover\:-translate-y-1\/4:hover{
  --tw-translate-y:-25%
}

.hover\:-translate-y-2\/4:hover{
  --tw-translate-y:-50%
}

.hover\:-translate-y-3\/4:hover{
  --tw-translate-y:-75%
}

.focus\:translate-x-0:focus{
  --tw-translate-x:0
}

.focus\:translate-x-1:focus{
  --tw-translate-x:4px
}

.focus\:translate-x-2:focus{
  --tw-translate-x:8px
}

.focus\:translate-x-3:focus{
  --tw-translate-x:12px
}

.focus\:translate-x-4:focus{
  --tw-translate-x:16px
}

.focus\:translate-x-5:focus{
  --tw-translate-x:20px
}

.focus\:translate-x-6:focus{
  --tw-translate-x:24px
}

.focus\:translate-x-8:focus{
  --tw-translate-x:32px
}

.focus\:translate-x-10:focus{
  --tw-translate-x:40px
}

.focus\:translate-x-12:focus{
  --tw-translate-x:48px
}

.focus\:translate-x-16:focus{
  --tw-translate-x:64px
}

.focus\:translate-x-18:focus{
  --tw-translate-x:72px
}

.focus\:translate-x-24:focus{
  --tw-translate-x:96px
}

.focus\:translate-x-5dot5:focus{
  --tw-translate-x:22px
}

.focus\:translate-x-full:focus{
  --tw-translate-x:100%
}

.focus\:-translate-x-1:focus{
  --tw-translate-x:-4px
}

.focus\:-translate-x-2:focus{
  --tw-translate-x:-8px
}

.focus\:-translate-x-3:focus{
  --tw-translate-x:-12px
}

.focus\:-translate-x-4:focus{
  --tw-translate-x:-16px
}

.focus\:-translate-x-5:focus{
  --tw-translate-x:-20px
}

.focus\:-translate-x-6:focus{
  --tw-translate-x:-24px
}

.focus\:-translate-x-8:focus{
  --tw-translate-x:-32px
}

.focus\:-translate-x-10:focus{
  --tw-translate-x:-40px
}

.focus\:-translate-x-12:focus{
  --tw-translate-x:-48px
}

.focus\:-translate-x-16:focus{
  --tw-translate-x:-64px
}

.focus\:-translate-x-18:focus{
  --tw-translate-x:-72px
}

.focus\:-translate-x-24:focus{
  --tw-translate-x:-96px
}

.focus\:-translate-x-5dot5:focus{
  --tw-translate-x:-22px
}

.focus\:-translate-x-full:focus{
  --tw-translate-x:-100%
}

.focus\:translate-x-1\/2:focus{
  --tw-translate-x:50%
}

.focus\:translate-x-1\/3:focus{
  --tw-translate-x:33.333333%
}

.focus\:translate-x-2\/3:focus{
  --tw-translate-x:66.666667%
}

.focus\:translate-x-1\/4:focus{
  --tw-translate-x:25%
}

.focus\:translate-x-2\/4:focus{
  --tw-translate-x:50%
}

.focus\:translate-x-3\/4:focus{
  --tw-translate-x:75%
}

.focus\:-translate-x-1\/2:focus{
  --tw-translate-x:-50%
}

.focus\:-translate-x-1\/3:focus{
  --tw-translate-x:-33.333333%
}

.focus\:-translate-x-2\/3:focus{
  --tw-translate-x:-66.666667%
}

.focus\:-translate-x-1\/4:focus{
  --tw-translate-x:-25%
}

.focus\:-translate-x-2\/4:focus{
  --tw-translate-x:-50%
}

.focus\:-translate-x-3\/4:focus{
  --tw-translate-x:-75%
}

.focus\:translate-y-0:focus{
  --tw-translate-y:0
}

.focus\:translate-y-1:focus{
  --tw-translate-y:4px
}

.focus\:translate-y-2:focus{
  --tw-translate-y:8px
}

.focus\:translate-y-3:focus{
  --tw-translate-y:12px
}

.focus\:translate-y-4:focus{
  --tw-translate-y:16px
}

.focus\:translate-y-5:focus{
  --tw-translate-y:20px
}

.focus\:translate-y-6:focus{
  --tw-translate-y:24px
}

.focus\:translate-y-8:focus{
  --tw-translate-y:32px
}

.focus\:translate-y-10:focus{
  --tw-translate-y:40px
}

.focus\:translate-y-12:focus{
  --tw-translate-y:48px
}

.focus\:translate-y-16:focus{
  --tw-translate-y:64px
}

.focus\:translate-y-18:focus{
  --tw-translate-y:72px
}

.focus\:translate-y-24:focus{
  --tw-translate-y:96px
}

.focus\:translate-y-5dot5:focus{
  --tw-translate-y:22px
}

.focus\:translate-y-full:focus{
  --tw-translate-y:100%
}

.focus\:-translate-y-1:focus{
  --tw-translate-y:-4px
}

.focus\:-translate-y-2:focus{
  --tw-translate-y:-8px
}

.focus\:-translate-y-3:focus{
  --tw-translate-y:-12px
}

.focus\:-translate-y-4:focus{
  --tw-translate-y:-16px
}

.focus\:-translate-y-5:focus{
  --tw-translate-y:-20px
}

.focus\:-translate-y-6:focus{
  --tw-translate-y:-24px
}

.focus\:-translate-y-8:focus{
  --tw-translate-y:-32px
}

.focus\:-translate-y-10:focus{
  --tw-translate-y:-40px
}

.focus\:-translate-y-12:focus{
  --tw-translate-y:-48px
}

.focus\:-translate-y-16:focus{
  --tw-translate-y:-64px
}

.focus\:-translate-y-18:focus{
  --tw-translate-y:-72px
}

.focus\:-translate-y-24:focus{
  --tw-translate-y:-96px
}

.focus\:-translate-y-5dot5:focus{
  --tw-translate-y:-22px
}

.focus\:-translate-y-full:focus{
  --tw-translate-y:-100%
}

.focus\:translate-y-1\/2:focus{
  --tw-translate-y:50%
}

.focus\:translate-y-1\/3:focus{
  --tw-translate-y:33.333333%
}

.focus\:translate-y-2\/3:focus{
  --tw-translate-y:66.666667%
}

.focus\:translate-y-1\/4:focus{
  --tw-translate-y:25%
}

.focus\:translate-y-2\/4:focus{
  --tw-translate-y:50%
}

.focus\:translate-y-3\/4:focus{
  --tw-translate-y:75%
}

.focus\:-translate-y-1\/2:focus{
  --tw-translate-y:-50%
}

.focus\:-translate-y-1\/3:focus{
  --tw-translate-y:-33.333333%
}

.focus\:-translate-y-2\/3:focus{
  --tw-translate-y:-66.666667%
}

.focus\:-translate-y-1\/4:focus{
  --tw-translate-y:-25%
}

.focus\:-translate-y-2\/4:focus{
  --tw-translate-y:-50%
}

.focus\:-translate-y-3\/4:focus{
  --tw-translate-y:-75%
}

.rotate-0{
  --tw-rotate:0deg
}

.rotate-1{
  --tw-rotate:1deg
}

.rotate-2{
  --tw-rotate:2deg
}

.rotate-3{
  --tw-rotate:3deg
}

.rotate-6{
  --tw-rotate:6deg
}

.rotate-12{
  --tw-rotate:12deg
}

.rotate-45{
  --tw-rotate:45deg
}

.rotate-90{
  --tw-rotate:90deg
}

.rotate-180{
  --tw-rotate:180deg
}

.-rotate-180{
  --tw-rotate:-180deg
}

.-rotate-90{
  --tw-rotate:-90deg
}

.-rotate-45{
  --tw-rotate:-45deg
}

.-rotate-12{
  --tw-rotate:-12deg
}

.-rotate-6{
  --tw-rotate:-6deg
}

.-rotate-3{
  --tw-rotate:-3deg
}

.-rotate-2{
  --tw-rotate:-2deg
}

.-rotate-1{
  --tw-rotate:-1deg
}

.hover\:rotate-0:hover{
  --tw-rotate:0deg
}

.hover\:rotate-1:hover{
  --tw-rotate:1deg
}

.hover\:rotate-2:hover{
  --tw-rotate:2deg
}

.hover\:rotate-3:hover{
  --tw-rotate:3deg
}

.hover\:rotate-6:hover{
  --tw-rotate:6deg
}

.hover\:rotate-12:hover{
  --tw-rotate:12deg
}

.hover\:rotate-45:hover{
  --tw-rotate:45deg
}

.hover\:rotate-90:hover{
  --tw-rotate:90deg
}

.hover\:rotate-180:hover{
  --tw-rotate:180deg
}

.hover\:-rotate-180:hover{
  --tw-rotate:-180deg
}

.hover\:-rotate-90:hover{
  --tw-rotate:-90deg
}

.hover\:-rotate-45:hover{
  --tw-rotate:-45deg
}

.hover\:-rotate-12:hover{
  --tw-rotate:-12deg
}

.hover\:-rotate-6:hover{
  --tw-rotate:-6deg
}

.hover\:-rotate-3:hover{
  --tw-rotate:-3deg
}

.hover\:-rotate-2:hover{
  --tw-rotate:-2deg
}

.hover\:-rotate-1:hover{
  --tw-rotate:-1deg
}

.focus\:rotate-0:focus{
  --tw-rotate:0deg
}

.focus\:rotate-1:focus{
  --tw-rotate:1deg
}

.focus\:rotate-2:focus{
  --tw-rotate:2deg
}

.focus\:rotate-3:focus{
  --tw-rotate:3deg
}

.focus\:rotate-6:focus{
  --tw-rotate:6deg
}

.focus\:rotate-12:focus{
  --tw-rotate:12deg
}

.focus\:rotate-45:focus{
  --tw-rotate:45deg
}

.focus\:rotate-90:focus{
  --tw-rotate:90deg
}

.focus\:rotate-180:focus{
  --tw-rotate:180deg
}

.focus\:-rotate-180:focus{
  --tw-rotate:-180deg
}

.focus\:-rotate-90:focus{
  --tw-rotate:-90deg
}

.focus\:-rotate-45:focus{
  --tw-rotate:-45deg
}

.focus\:-rotate-12:focus{
  --tw-rotate:-12deg
}

.focus\:-rotate-6:focus{
  --tw-rotate:-6deg
}

.focus\:-rotate-3:focus{
  --tw-rotate:-3deg
}

.focus\:-rotate-2:focus{
  --tw-rotate:-2deg
}

.focus\:-rotate-1:focus{
  --tw-rotate:-1deg
}

@keyframes spin{
  to{
    transform:rotate(360deg)
  }
}

@keyframes ping{
  75%, 100%{
    transform:scale(2);
    opacity:0
  }
}

@keyframes pulse{
  50%{
    opacity:.5
  }
}

@keyframes bounce{
  0%, 100%{
    transform:translateY(-25%);
    animation-timing-function:cubic-bezier(0.8,0,1,1)
  }

  50%{
    transform:none;
    animation-timing-function:cubic-bezier(0,0,0.2,1)
  }
}

@keyframes spin-delay{
  0%{
    transform:rotate(0deg)
  }

  50%{
    transform:rotate(360deg)
  }

  100%{
    transform:rotate(360deg)
  }
}

.animate-none{
  animation:none
}

.animate-spin{
  animation:spin 1s linear infinite
}

.animate-ping{
  animation:ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
}

.animate-pulse{
  animation:pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

.animate-bounce{
  animation:bounce 1s infinite
}

.animate-spin-delay{
  animation:spin-delay 2.4s ease infinite
}

.cursor-auto{
  cursor:auto
}

.cursor-default{
  cursor:default
}

.cursor-pointer{
  cursor:pointer
}

.cursor-wait{
  cursor:wait
}

.cursor-text{
  cursor:text
}

.cursor-move{
  cursor:move
}

.cursor-help{
  cursor:help
}

.cursor-not-allowed{
  cursor:not-allowed
}

.select-none{
  -webkit-user-select:none;
          user-select:none
}

.select-text{
  -webkit-user-select:text;
          user-select:text
}

.select-all{
  -webkit-user-select:all;
          user-select:all
}

.select-auto{
  -webkit-user-select:auto;
          user-select:auto
}

.auto-cols-auto{
  grid-auto-columns:auto
}

.auto-cols-min{
  grid-auto-columns:min-content
}

.auto-cols-max{
  grid-auto-columns:max-content
}

.auto-cols-fr{
  grid-auto-columns:minmax(0, 1fr)
}

.grid-flow-row{
  grid-auto-flow:row
}

.grid-flow-col{
  grid-auto-flow:column
}

.grid-flow-row-dense{
  grid-auto-flow:row dense
}

.grid-flow-col-dense{
  grid-auto-flow:column dense
}

.auto-rows-auto{
  grid-auto-rows:auto
}

.auto-rows-min{
  grid-auto-rows:min-content
}

.auto-rows-max{
  grid-auto-rows:max-content
}

.auto-rows-fr{
  grid-auto-rows:minmax(0, 1fr)
}

.grid-cols-1{
  grid-template-columns:repeat(1, minmax(0, 1fr))
}

.grid-cols-2{
  grid-template-columns:repeat(2, minmax(0, 1fr))
}

.grid-cols-3{
  grid-template-columns:repeat(3, minmax(0, 1fr))
}

.grid-cols-4{
  grid-template-columns:repeat(4, minmax(0, 1fr))
}

.grid-cols-5{
  grid-template-columns:repeat(5, minmax(0, 1fr))
}

.grid-cols-6{
  grid-template-columns:repeat(6, minmax(0, 1fr))
}

.grid-cols-7{
  grid-template-columns:repeat(7, minmax(0, 1fr))
}

.grid-cols-8{
  grid-template-columns:repeat(8, minmax(0, 1fr))
}

.grid-cols-9{
  grid-template-columns:repeat(9, minmax(0, 1fr))
}

.grid-cols-10{
  grid-template-columns:repeat(10, minmax(0, 1fr))
}

.grid-cols-11{
  grid-template-columns:repeat(11, minmax(0, 1fr))
}

.grid-cols-12{
  grid-template-columns:repeat(12, minmax(0, 1fr))
}

.grid-cols-none{
  grid-template-columns:none
}

.grid-rows-1{
  grid-template-rows:repeat(1, minmax(0, 1fr))
}

.grid-rows-2{
  grid-template-rows:repeat(2, minmax(0, 1fr))
}

.grid-rows-3{
  grid-template-rows:repeat(3, minmax(0, 1fr))
}

.grid-rows-4{
  grid-template-rows:repeat(4, minmax(0, 1fr))
}

.grid-rows-5{
  grid-template-rows:repeat(5, minmax(0, 1fr))
}

.grid-rows-6{
  grid-template-rows:repeat(6, minmax(0, 1fr))
}

.grid-rows-none{
  grid-template-rows:none
}

.flex-row{
  flex-direction:row
}

.flex-row-reverse{
  flex-direction:row-reverse
}

.flex-col{
  flex-direction:column
}

.flex-col-reverse{
  flex-direction:column-reverse
}

.flex-wrap{
  flex-wrap:wrap
}

.flex-wrap-reverse{
  flex-wrap:wrap-reverse
}

.flex-nowrap{
  flex-wrap:nowrap
}

.place-content-center{
  place-content:center
}

.place-content-start{
  place-content:start
}

.place-content-end{
  place-content:end
}

.place-content-between{
  place-content:space-between
}

.place-content-around{
  place-content:space-around
}

.place-content-evenly{
  place-content:space-evenly
}

.place-content-stretch{
  place-content:stretch
}

.place-items-start{
  place-items:start
}

.place-items-end{
  place-items:end
}

.place-items-center{
  place-items:center
}

.place-items-stretch{
  place-items:stretch
}

.content-center{
  align-content:center
}

.content-start{
  align-content:flex-start
}

.content-end{
  align-content:flex-end
}

.content-between{
  align-content:space-between
}

.content-around{
  align-content:space-around
}

.content-evenly{
  align-content:space-evenly
}

.items-start{
  align-items:flex-start
}

.items-end{
  align-items:flex-end
}

.items-center{
  align-items:center
}

.items-baseline{
  align-items:baseline
}

.items-stretch{
  align-items:stretch
}

.justify-start{
  justify-content:flex-start
}

.justify-end{
  justify-content:flex-end
}

.justify-center{
  justify-content:center
}

.justify-between{
  justify-content:space-between
}

.justify-around{
  justify-content:space-around
}

.justify-evenly{
  justify-content:space-evenly
}

.justify-items-start{
  justify-items:start
}

.justify-items-end{
  justify-items:end
}

.justify-items-center{
  justify-items:center
}

.justify-items-stretch{
  justify-items:stretch
}

.space-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(0px * var(--tw-space-x-reverse));
  margin-left:calc(0px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(4px * var(--tw-space-x-reverse));
  margin-left:calc(4px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(8px * var(--tw-space-x-reverse));
  margin-left:calc(8px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(12px * var(--tw-space-x-reverse));
  margin-left:calc(12px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(16px * var(--tw-space-x-reverse));
  margin-left:calc(16px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(20px * var(--tw-space-x-reverse));
  margin-left:calc(20px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(24px * var(--tw-space-x-reverse));
  margin-left:calc(24px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(32px * var(--tw-space-x-reverse));
  margin-left:calc(32px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(40px * var(--tw-space-x-reverse));
  margin-left:calc(40px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(48px * var(--tw-space-x-reverse));
  margin-left:calc(48px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(64px * var(--tw-space-x-reverse));
  margin-left:calc(64px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-18 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(72px * var(--tw-space-x-reverse));
  margin-left:calc(72px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(96px * var(--tw-space-x-reverse));
  margin-left:calc(96px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(22px * var(--tw-space-x-reverse));
  margin-left:calc(22px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-full > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(100% * var(--tw-space-x-reverse));
  margin-left:calc(100% * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-4px * var(--tw-space-x-reverse));
  margin-left:calc(-4px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-8px * var(--tw-space-x-reverse));
  margin-left:calc(-8px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-12px * var(--tw-space-x-reverse));
  margin-left:calc(-12px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-16px * var(--tw-space-x-reverse));
  margin-left:calc(-16px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-20px * var(--tw-space-x-reverse));
  margin-left:calc(-20px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-24px * var(--tw-space-x-reverse));
  margin-left:calc(-24px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-32px * var(--tw-space-x-reverse));
  margin-left:calc(-32px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-40px * var(--tw-space-x-reverse));
  margin-left:calc(-40px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-48px * var(--tw-space-x-reverse));
  margin-left:calc(-48px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-64px * var(--tw-space-x-reverse));
  margin-left:calc(-64px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-18 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-72px * var(--tw-space-x-reverse));
  margin-left:calc(-72px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-96px * var(--tw-space-x-reverse));
  margin-left:calc(-96px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-22px * var(--tw-space-x-reverse));
  margin-left:calc(-22px * calc(1 - var(--tw-space-x-reverse)))
}

.-space-x-full > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(-100% * var(--tw-space-x-reverse));
  margin-left:calc(-100% * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(0px * var(--tw-space-y-reverse))
}

.space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(4px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(4px * var(--tw-space-y-reverse))
}

.space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(8px * var(--tw-space-y-reverse))
}

.space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(12px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(12px * var(--tw-space-y-reverse))
}

.space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(16px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(16px * var(--tw-space-y-reverse))
}

.space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(20px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(20px * var(--tw-space-y-reverse))
}

.space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(24px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(24px * var(--tw-space-y-reverse))
}

.space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(32px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(32px * var(--tw-space-y-reverse))
}

.space-y-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(40px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(40px * var(--tw-space-y-reverse))
}

.space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(48px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(48px * var(--tw-space-y-reverse))
}

.space-y-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(64px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(64px * var(--tw-space-y-reverse))
}

.space-y-18 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(72px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(72px * var(--tw-space-y-reverse))
}

.space-y-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(96px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(96px * var(--tw-space-y-reverse))
}

.space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(22px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(22px * var(--tw-space-y-reverse))
}

.space-y-full > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(100% * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(100% * var(--tw-space-y-reverse))
}

.-space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-4px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-4px * var(--tw-space-y-reverse))
}

.-space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-8px * var(--tw-space-y-reverse))
}

.-space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-12px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-12px * var(--tw-space-y-reverse))
}

.-space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-16px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-16px * var(--tw-space-y-reverse))
}

.-space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-20px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-20px * var(--tw-space-y-reverse))
}

.-space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-24px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-24px * var(--tw-space-y-reverse))
}

.-space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-32px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-32px * var(--tw-space-y-reverse))
}

.-space-y-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-40px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-40px * var(--tw-space-y-reverse))
}

.-space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-48px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-48px * var(--tw-space-y-reverse))
}

.-space-y-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-64px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-64px * var(--tw-space-y-reverse))
}

.-space-y-18 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-72px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-72px * var(--tw-space-y-reverse))
}

.-space-y-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-96px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-96px * var(--tw-space-y-reverse))
}

.-space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-22px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-22px * var(--tw-space-y-reverse))
}

.-space-y-full > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(-100% * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-100% * var(--tw-space-y-reverse))
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:1
}

.space-x-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:1
}

.divide-solid > :not([hidden]) ~ :not([hidden]){
  border-style:solid
}

.divide-dashed > :not([hidden]) ~ :not([hidden]){
  border-style:dashed
}

.divide-dotted > :not([hidden]) ~ :not([hidden]){
  border-style:dotted
}

.divide-double > :not([hidden]) ~ :not([hidden]){
  border-style:double
}

.divide-none > :not([hidden]) ~ :not([hidden]){
  border-style:none
}

.place-self-auto{
  place-self:auto
}

.place-self-start{
  place-self:start
}

.place-self-end{
  place-self:end
}

.place-self-center{
  place-self:center
}

.place-self-stretch{
  place-self:stretch
}

.self-auto{
  align-self:auto
}

.self-start{
  align-self:flex-start
}

.self-end{
  align-self:flex-end
}

.self-center{
  align-self:center
}

.self-stretch{
  align-self:stretch
}

.self-baseline{
  align-self:baseline
}

.justify-self-auto{
  justify-self:auto
}

.justify-self-start{
  justify-self:start
}

.justify-self-end{
  justify-self:end
}

.justify-self-center{
  justify-self:center
}

.justify-self-stretch{
  justify-self:stretch
}

.overflow-auto{
  overflow:auto
}

.overflow-hidden{
  overflow:hidden
}

.overflow-visible{
  overflow:visible
}

.overflow-scroll{
  overflow:scroll
}

.overflow-x-auto{
  overflow-x:auto
}

.overflow-y-auto{
  overflow-y:auto
}

.overflow-x-hidden{
  overflow-x:hidden
}

.overflow-y-hidden{
  overflow-y:hidden
}

.overflow-x-visible{
  overflow-x:visible
}

.overflow-y-visible{
  overflow-y:visible
}

.overflow-x-scroll{
  overflow-x:scroll
}

.overflow-y-scroll{
  overflow-y:scroll
}

.overscroll-auto{
  overscroll-behavior:auto
}

.overscroll-contain{
  overscroll-behavior:contain
}

.overscroll-none{
  overscroll-behavior:none
}

.overscroll-y-auto{
  overscroll-behavior-y:auto
}

.overscroll-y-contain{
  overscroll-behavior-y:contain
}

.overscroll-y-none{
  overscroll-behavior-y:none
}

.overscroll-x-auto{
  overscroll-behavior-x:auto
}

.overscroll-x-contain{
  overscroll-behavior-x:contain
}

.overscroll-x-none{
  overscroll-behavior-x:none
}

.truncate{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

.overflow-ellipsis{
  text-overflow:ellipsis
}

.overflow-clip{
  text-overflow:clip
}

.whitespace-normal{
  white-space:normal
}

.whitespace-nowrap{
  white-space:nowrap
}

.whitespace-pre{
  white-space:pre
}

.whitespace-pre-line{
  white-space:pre-line
}

.whitespace-pre-wrap{
  white-space:pre-wrap
}

.break-normal{
  overflow-wrap:normal;
  word-break:normal
}

.break-words{
  overflow-wrap:break-word
}

.break-all{
  word-break:break-all
}

.rounded-1{
  border-radius:4px
}

.rounded-2{
  border-radius:8px
}

.rounded-3{
  border-radius:12px
}

.rounded-4{
  border-radius:16px
}

.rounded-none{
  border-radius:0px
}

.rounded-sm{
  border-radius:0.125rem
}

.rounded{
  border-radius:0.25rem
}

.rounded-md{
  border-radius:0.375rem
}

.rounded-lg{
  border-radius:0.5rem
}

.rounded-xl{
  border-radius:0.75rem
}

.rounded-2xl{
  border-radius:1rem
}

.rounded-3xl{
  border-radius:1.5rem
}

.rounded-full{
  border-radius:9999px
}

.rounded-default{
  border-radius:4px
}

.rounded-t-1{
  border-top-left-radius:4px;
  border-top-right-radius:4px
}

.rounded-t-2{
  border-top-left-radius:8px;
  border-top-right-radius:8px
}

.rounded-t-3{
  border-top-left-radius:12px;
  border-top-right-radius:12px
}

.rounded-t-4{
  border-top-left-radius:16px;
  border-top-right-radius:16px
}

.rounded-t-none{
  border-top-left-radius:0px;
  border-top-right-radius:0px
}

.rounded-t-sm{
  border-top-left-radius:0.125rem;
  border-top-right-radius:0.125rem
}

.rounded-t{
  border-top-left-radius:0.25rem;
  border-top-right-radius:0.25rem
}

.rounded-t-md{
  border-top-left-radius:0.375rem;
  border-top-right-radius:0.375rem
}

.rounded-t-lg{
  border-top-left-radius:0.5rem;
  border-top-right-radius:0.5rem
}

.rounded-t-xl{
  border-top-left-radius:0.75rem;
  border-top-right-radius:0.75rem
}

.rounded-t-2xl{
  border-top-left-radius:1rem;
  border-top-right-radius:1rem
}

.rounded-t-3xl{
  border-top-left-radius:1.5rem;
  border-top-right-radius:1.5rem
}

.rounded-t-full{
  border-top-left-radius:9999px;
  border-top-right-radius:9999px
}

.rounded-t-default{
  border-top-left-radius:4px;
  border-top-right-radius:4px
}

.rounded-r-1{
  border-top-right-radius:4px;
  border-bottom-right-radius:4px
}

.rounded-r-2{
  border-top-right-radius:8px;
  border-bottom-right-radius:8px
}

.rounded-r-3{
  border-top-right-radius:12px;
  border-bottom-right-radius:12px
}

.rounded-r-4{
  border-top-right-radius:16px;
  border-bottom-right-radius:16px
}

.rounded-r-none{
  border-top-right-radius:0px;
  border-bottom-right-radius:0px
}

.rounded-r-sm{
  border-top-right-radius:0.125rem;
  border-bottom-right-radius:0.125rem
}

.rounded-r{
  border-top-right-radius:0.25rem;
  border-bottom-right-radius:0.25rem
}

.rounded-r-md{
  border-top-right-radius:0.375rem;
  border-bottom-right-radius:0.375rem
}

.rounded-r-lg{
  border-top-right-radius:0.5rem;
  border-bottom-right-radius:0.5rem
}

.rounded-r-xl{
  border-top-right-radius:0.75rem;
  border-bottom-right-radius:0.75rem
}

.rounded-r-2xl{
  border-top-right-radius:1rem;
  border-bottom-right-radius:1rem
}

.rounded-r-3xl{
  border-top-right-radius:1.5rem;
  border-bottom-right-radius:1.5rem
}

.rounded-r-full{
  border-top-right-radius:9999px;
  border-bottom-right-radius:9999px
}

.rounded-r-default{
  border-top-right-radius:4px;
  border-bottom-right-radius:4px
}

.rounded-b-1{
  border-bottom-right-radius:4px;
  border-bottom-left-radius:4px
}

.rounded-b-2{
  border-bottom-right-radius:8px;
  border-bottom-left-radius:8px
}

.rounded-b-3{
  border-bottom-right-radius:12px;
  border-bottom-left-radius:12px
}

.rounded-b-4{
  border-bottom-right-radius:16px;
  border-bottom-left-radius:16px
}

.rounded-b-none{
  border-bottom-right-radius:0px;
  border-bottom-left-radius:0px
}

.rounded-b-sm{
  border-bottom-right-radius:0.125rem;
  border-bottom-left-radius:0.125rem
}

.rounded-b{
  border-bottom-right-radius:0.25rem;
  border-bottom-left-radius:0.25rem
}

.rounded-b-md{
  border-bottom-right-radius:0.375rem;
  border-bottom-left-radius:0.375rem
}

.rounded-b-lg{
  border-bottom-right-radius:0.5rem;
  border-bottom-left-radius:0.5rem
}

.rounded-b-xl{
  border-bottom-right-radius:0.75rem;
  border-bottom-left-radius:0.75rem
}

.rounded-b-2xl{
  border-bottom-right-radius:1rem;
  border-bottom-left-radius:1rem
}

.rounded-b-3xl{
  border-bottom-right-radius:1.5rem;
  border-bottom-left-radius:1.5rem
}

.rounded-b-full{
  border-bottom-right-radius:9999px;
  border-bottom-left-radius:9999px
}

.rounded-b-default{
  border-bottom-right-radius:4px;
  border-bottom-left-radius:4px
}

.rounded-l-1{
  border-top-left-radius:4px;
  border-bottom-left-radius:4px
}

.rounded-l-2{
  border-top-left-radius:8px;
  border-bottom-left-radius:8px
}

.rounded-l-3{
  border-top-left-radius:12px;
  border-bottom-left-radius:12px
}

.rounded-l-4{
  border-top-left-radius:16px;
  border-bottom-left-radius:16px
}

.rounded-l-none{
  border-top-left-radius:0px;
  border-bottom-left-radius:0px
}

.rounded-l-sm{
  border-top-left-radius:0.125rem;
  border-bottom-left-radius:0.125rem
}

.rounded-l{
  border-top-left-radius:0.25rem;
  border-bottom-left-radius:0.25rem
}

.rounded-l-md{
  border-top-left-radius:0.375rem;
  border-bottom-left-radius:0.375rem
}

.rounded-l-lg{
  border-top-left-radius:0.5rem;
  border-bottom-left-radius:0.5rem
}

.rounded-l-xl{
  border-top-left-radius:0.75rem;
  border-bottom-left-radius:0.75rem
}

.rounded-l-2xl{
  border-top-left-radius:1rem;
  border-bottom-left-radius:1rem
}

.rounded-l-3xl{
  border-top-left-radius:1.5rem;
  border-bottom-left-radius:1.5rem
}

.rounded-l-full{
  border-top-left-radius:9999px;
  border-bottom-left-radius:9999px
}

.rounded-l-default{
  border-top-left-radius:4px;
  border-bottom-left-radius:4px
}

.rounded-tl-1{
  border-top-left-radius:4px
}

.rounded-tl-2{
  border-top-left-radius:8px
}

.rounded-tl-3{
  border-top-left-radius:12px
}

.rounded-tl-4{
  border-top-left-radius:16px
}

.rounded-tl-none{
  border-top-left-radius:0px
}

.rounded-tl-sm{
  border-top-left-radius:0.125rem
}

.rounded-tl{
  border-top-left-radius:0.25rem
}

.rounded-tl-md{
  border-top-left-radius:0.375rem
}

.rounded-tl-lg{
  border-top-left-radius:0.5rem
}

.rounded-tl-xl{
  border-top-left-radius:0.75rem
}

.rounded-tl-2xl{
  border-top-left-radius:1rem
}

.rounded-tl-3xl{
  border-top-left-radius:1.5rem
}

.rounded-tl-full{
  border-top-left-radius:9999px
}

.rounded-tl-default{
  border-top-left-radius:4px
}

.rounded-tr-1{
  border-top-right-radius:4px
}

.rounded-tr-2{
  border-top-right-radius:8px
}

.rounded-tr-3{
  border-top-right-radius:12px
}

.rounded-tr-4{
  border-top-right-radius:16px
}

.rounded-tr-none{
  border-top-right-radius:0px
}

.rounded-tr-sm{
  border-top-right-radius:0.125rem
}

.rounded-tr{
  border-top-right-radius:0.25rem
}

.rounded-tr-md{
  border-top-right-radius:0.375rem
}

.rounded-tr-lg{
  border-top-right-radius:0.5rem
}

.rounded-tr-xl{
  border-top-right-radius:0.75rem
}

.rounded-tr-2xl{
  border-top-right-radius:1rem
}

.rounded-tr-3xl{
  border-top-right-radius:1.5rem
}

.rounded-tr-full{
  border-top-right-radius:9999px
}

.rounded-tr-default{
  border-top-right-radius:4px
}

.rounded-br-1{
  border-bottom-right-radius:4px
}

.rounded-br-2{
  border-bottom-right-radius:8px
}

.rounded-br-3{
  border-bottom-right-radius:12px
}

.rounded-br-4{
  border-bottom-right-radius:16px
}

.rounded-br-none{
  border-bottom-right-radius:0px
}

.rounded-br-sm{
  border-bottom-right-radius:0.125rem
}

.rounded-br{
  border-bottom-right-radius:0.25rem
}

.rounded-br-md{
  border-bottom-right-radius:0.375rem
}

.rounded-br-lg{
  border-bottom-right-radius:0.5rem
}

.rounded-br-xl{
  border-bottom-right-radius:0.75rem
}

.rounded-br-2xl{
  border-bottom-right-radius:1rem
}

.rounded-br-3xl{
  border-bottom-right-radius:1.5rem
}

.rounded-br-full{
  border-bottom-right-radius:9999px
}

.rounded-br-default{
  border-bottom-right-radius:4px
}

.rounded-bl-1{
  border-bottom-left-radius:4px
}

.rounded-bl-2{
  border-bottom-left-radius:8px
}

.rounded-bl-3{
  border-bottom-left-radius:12px
}

.rounded-bl-4{
  border-bottom-left-radius:16px
}

.rounded-bl-none{
  border-bottom-left-radius:0px
}

.rounded-bl-sm{
  border-bottom-left-radius:0.125rem
}

.rounded-bl{
  border-bottom-left-radius:0.25rem
}

.rounded-bl-md{
  border-bottom-left-radius:0.375rem
}

.rounded-bl-lg{
  border-bottom-left-radius:0.5rem
}

.rounded-bl-xl{
  border-bottom-left-radius:0.75rem
}

.rounded-bl-2xl{
  border-bottom-left-radius:1rem
}

.rounded-bl-3xl{
  border-bottom-left-radius:1.5rem
}

.rounded-bl-full{
  border-bottom-left-radius:9999px
}

.rounded-bl-default{
  border-bottom-left-radius:4px
}

.border-0{
  border-width:0px
}

.border-2{
  border-width:2px
}

.border-4{
  border-width:4px
}

.border-8{
  border-width:8px
}

.border{
  border-width:1px
}

.border-t-0{
  border-top-width:0px
}

.border-t-2{
  border-top-width:2px
}

.border-t-4{
  border-top-width:4px
}

.border-t-8{
  border-top-width:8px
}

.border-t{
  border-top-width:1px
}

.border-r-0{
  border-right-width:0px
}

.border-r-2{
  border-right-width:2px
}

.border-r-4{
  border-right-width:4px
}

.border-r-8{
  border-right-width:8px
}

.border-r{
  border-right-width:1px
}

.border-b-0{
  border-bottom-width:0px
}

.border-b-2{
  border-bottom-width:2px
}

.border-b-4{
  border-bottom-width:4px
}

.border-b-8{
  border-bottom-width:8px
}

.border-b{
  border-bottom-width:1px
}

.border-l-0{
  border-left-width:0px
}

.border-l-2{
  border-left-width:2px
}

.border-l-4{
  border-left-width:4px
}

.border-l-8{
  border-left-width:8px
}

.border-l{
  border-left-width:1px
}

.border-solid{
  border-style:solid
}

.border-dashed{
  border-style:dashed
}

.border-dotted{
  border-style:dotted
}

.border-double{
  border-style:double
}

.border-none{
  border-style:none
}

.border-red1{
  --tw-border-opacity:1;
  border-color:rgba(204, 53, 44, var(--tw-border-opacity))
}

.border-red2{
  --tw-border-opacity:1;
  border-color:rgba(255, 85, 75, var(--tw-border-opacity))
}

.border-red3{
  --tw-border-opacity:1;
  border-color:rgba(255, 123, 115, var(--tw-border-opacity))
}

.border-red4{
  --tw-border-opacity:1;
  border-color:rgba(255, 241, 241, var(--tw-border-opacity))
}

.border-red5{
  --tw-border-opacity:1;
  border-color:rgba(255, 246, 246, var(--tw-border-opacity))
}

.border-cyan1{
  --tw-border-opacity:1;
  border-color:rgba(0, 152, 151, var(--tw-border-opacity))
}

.border-cyan2{
  --tw-border-opacity:1;
  border-color:rgba(0, 191, 189, var(--tw-border-opacity))
}

.border-cyan3{
  --tw-border-opacity:1;
  border-color:rgba(76, 209, 208, var(--tw-border-opacity))
}

.border-cyan4{
  --tw-border-opacity:1;
  border-color:rgba(178, 236, 235, var(--tw-border-opacity))
}

.border-cyan5{
  --tw-border-opacity:1;
  border-color:rgba(237, 250, 250, var(--tw-border-opacity))
}

.border-bg1{
  --tw-border-opacity:1;
  border-color:rgba(250, 250, 252, var(--tw-border-opacity))
}

.border-bg2{
  --tw-border-opacity:1;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity))
}

.border-bg3{
  --tw-border-opacity:1;
  border-color:rgba(247, 247, 250, var(--tw-border-opacity))
}

.border-gray1{
  --tw-border-opacity:1;
  border-color:rgba(51, 51, 51, var(--tw-border-opacity))
}

.border-gray2{
  --tw-border-opacity:1;
  border-color:rgba(77, 77, 77, var(--tw-border-opacity))
}

.border-gray3{
  --tw-border-opacity:1;
  border-color:rgba(140, 140, 140, var(--tw-border-opacity))
}

.border-gray4{
  --tw-border-opacity:1;
  border-color:rgba(191, 191, 191, var(--tw-border-opacity))
}

.border-gray5{
  --tw-border-opacity:1;
  border-color:rgba(217, 217, 217, var(--tw-border-opacity))
}

.border-gray6{
  --tw-border-opacity:1;
  border-color:rgba(233, 233, 235, var(--tw-border-opacity))
}

.border-gray7{
  --tw-border-opacity:1;
  border-color:rgba(81, 81, 100, var(--tw-border-opacity))
}

.border-white{
  --tw-border-opacity:1;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity))
}

.border-warning{
  --tw-border-opacity:1;
  border-color:rgba(255, 195, 25, var(--tw-border-opacity))
}

.border-error{
  --tw-border-opacity:1;
  border-color:rgba(234, 105, 111, var(--tw-border-opacity))
}

.border-info{
  --tw-border-opacity:1;
  border-color:rgba(0, 113, 185, var(--tw-border-opacity))
}

.border-success{
  --tw-border-opacity:1;
  border-color:rgba(152, 212, 95, var(--tw-border-opacity))
}

.border-special4{
  --tw-border-opacity:1;
  border-color:rgba(89, 119, 154, var(--tw-border-opacity))
}

.border-dark{
  --tw-border-opacity:1;
  border-color:rgba(0, 0, 0, var(--tw-border-opacity))
}

.border-transparent{
  border-color:transparent
}

.border-secondary1{
  --tw-border-opacity:1;
  border-color:rgba(49, 49, 64, var(--tw-border-opacity))
}

.border-secondary2{
  --tw-border-opacity:1;
  border-color:rgba(81, 81, 100, var(--tw-border-opacity))
}

.border-secondary3{
  --tw-border-opacity:1;
  border-color:rgba(156, 156, 172, var(--tw-border-opacity))
}

.border-secondary4{
  --tw-border-opacity:1;
  border-color:rgba(229, 232, 237, var(--tw-border-opacity))
}

.border-secondary5{
  --tw-border-opacity:1;
  border-color:rgba(245, 246, 249, var(--tw-border-opacity))
}

.border-green1{
  --tw-border-opacity:1;
  border-color:rgba(13, 226, 152, var(--tw-border-opacity))
}

.hover\:border-red1:hover{
  --tw-border-opacity:1;
  border-color:rgba(204, 53, 44, var(--tw-border-opacity))
}

.hover\:border-red2:hover{
  --tw-border-opacity:1;
  border-color:rgba(255, 85, 75, var(--tw-border-opacity))
}

.hover\:border-red3:hover{
  --tw-border-opacity:1;
  border-color:rgba(255, 123, 115, var(--tw-border-opacity))
}

.hover\:border-red4:hover{
  --tw-border-opacity:1;
  border-color:rgba(255, 241, 241, var(--tw-border-opacity))
}

.hover\:border-red5:hover{
  --tw-border-opacity:1;
  border-color:rgba(255, 246, 246, var(--tw-border-opacity))
}

.hover\:border-cyan1:hover{
  --tw-border-opacity:1;
  border-color:rgba(0, 152, 151, var(--tw-border-opacity))
}

.hover\:border-cyan2:hover{
  --tw-border-opacity:1;
  border-color:rgba(0, 191, 189, var(--tw-border-opacity))
}

.hover\:border-cyan3:hover{
  --tw-border-opacity:1;
  border-color:rgba(76, 209, 208, var(--tw-border-opacity))
}

.hover\:border-cyan4:hover{
  --tw-border-opacity:1;
  border-color:rgba(178, 236, 235, var(--tw-border-opacity))
}

.hover\:border-cyan5:hover{
  --tw-border-opacity:1;
  border-color:rgba(237, 250, 250, var(--tw-border-opacity))
}

.hover\:border-bg1:hover{
  --tw-border-opacity:1;
  border-color:rgba(250, 250, 252, var(--tw-border-opacity))
}

.hover\:border-bg2:hover{
  --tw-border-opacity:1;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity))
}

.hover\:border-bg3:hover{
  --tw-border-opacity:1;
  border-color:rgba(247, 247, 250, var(--tw-border-opacity))
}

.hover\:border-gray1:hover{
  --tw-border-opacity:1;
  border-color:rgba(51, 51, 51, var(--tw-border-opacity))
}

.hover\:border-gray2:hover{
  --tw-border-opacity:1;
  border-color:rgba(77, 77, 77, var(--tw-border-opacity))
}

.hover\:border-gray3:hover{
  --tw-border-opacity:1;
  border-color:rgba(140, 140, 140, var(--tw-border-opacity))
}

.hover\:border-gray4:hover{
  --tw-border-opacity:1;
  border-color:rgba(191, 191, 191, var(--tw-border-opacity))
}

.hover\:border-gray5:hover{
  --tw-border-opacity:1;
  border-color:rgba(217, 217, 217, var(--tw-border-opacity))
}

.hover\:border-gray6:hover{
  --tw-border-opacity:1;
  border-color:rgba(233, 233, 235, var(--tw-border-opacity))
}

.hover\:border-gray7:hover{
  --tw-border-opacity:1;
  border-color:rgba(81, 81, 100, var(--tw-border-opacity))
}

.hover\:border-white:hover{
  --tw-border-opacity:1;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity))
}

.hover\:border-warning:hover{
  --tw-border-opacity:1;
  border-color:rgba(255, 195, 25, var(--tw-border-opacity))
}

.hover\:border-error:hover{
  --tw-border-opacity:1;
  border-color:rgba(234, 105, 111, var(--tw-border-opacity))
}

.hover\:border-info:hover{
  --tw-border-opacity:1;
  border-color:rgba(0, 113, 185, var(--tw-border-opacity))
}

.hover\:border-success:hover{
  --tw-border-opacity:1;
  border-color:rgba(152, 212, 95, var(--tw-border-opacity))
}

.hover\:border-special4:hover{
  --tw-border-opacity:1;
  border-color:rgba(89, 119, 154, var(--tw-border-opacity))
}

.hover\:border-dark:hover{
  --tw-border-opacity:1;
  border-color:rgba(0, 0, 0, var(--tw-border-opacity))
}

.hover\:border-transparent:hover{
  border-color:transparent
}

.hover\:border-secondary1:hover{
  --tw-border-opacity:1;
  border-color:rgba(49, 49, 64, var(--tw-border-opacity))
}

.hover\:border-secondary2:hover{
  --tw-border-opacity:1;
  border-color:rgba(81, 81, 100, var(--tw-border-opacity))
}

.hover\:border-secondary3:hover{
  --tw-border-opacity:1;
  border-color:rgba(156, 156, 172, var(--tw-border-opacity))
}

.hover\:border-secondary4:hover{
  --tw-border-opacity:1;
  border-color:rgba(229, 232, 237, var(--tw-border-opacity))
}

.hover\:border-secondary5:hover{
  --tw-border-opacity:1;
  border-color:rgba(245, 246, 249, var(--tw-border-opacity))
}

.hover\:border-green1:hover{
  --tw-border-opacity:1;
  border-color:rgba(13, 226, 152, var(--tw-border-opacity))
}

.focus\:border-red1:focus{
  --tw-border-opacity:1;
  border-color:rgba(204, 53, 44, var(--tw-border-opacity))
}

.focus\:border-red2:focus{
  --tw-border-opacity:1;
  border-color:rgba(255, 85, 75, var(--tw-border-opacity))
}

.focus\:border-red3:focus{
  --tw-border-opacity:1;
  border-color:rgba(255, 123, 115, var(--tw-border-opacity))
}

.focus\:border-red4:focus{
  --tw-border-opacity:1;
  border-color:rgba(255, 241, 241, var(--tw-border-opacity))
}

.focus\:border-red5:focus{
  --tw-border-opacity:1;
  border-color:rgba(255, 246, 246, var(--tw-border-opacity))
}

.focus\:border-cyan1:focus{
  --tw-border-opacity:1;
  border-color:rgba(0, 152, 151, var(--tw-border-opacity))
}

.focus\:border-cyan2:focus{
  --tw-border-opacity:1;
  border-color:rgba(0, 191, 189, var(--tw-border-opacity))
}

.focus\:border-cyan3:focus{
  --tw-border-opacity:1;
  border-color:rgba(76, 209, 208, var(--tw-border-opacity))
}

.focus\:border-cyan4:focus{
  --tw-border-opacity:1;
  border-color:rgba(178, 236, 235, var(--tw-border-opacity))
}

.focus\:border-cyan5:focus{
  --tw-border-opacity:1;
  border-color:rgba(237, 250, 250, var(--tw-border-opacity))
}

.focus\:border-bg1:focus{
  --tw-border-opacity:1;
  border-color:rgba(250, 250, 252, var(--tw-border-opacity))
}

.focus\:border-bg2:focus{
  --tw-border-opacity:1;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity))
}

.focus\:border-bg3:focus{
  --tw-border-opacity:1;
  border-color:rgba(247, 247, 250, var(--tw-border-opacity))
}

.focus\:border-gray1:focus{
  --tw-border-opacity:1;
  border-color:rgba(51, 51, 51, var(--tw-border-opacity))
}

.focus\:border-gray2:focus{
  --tw-border-opacity:1;
  border-color:rgba(77, 77, 77, var(--tw-border-opacity))
}

.focus\:border-gray3:focus{
  --tw-border-opacity:1;
  border-color:rgba(140, 140, 140, var(--tw-border-opacity))
}

.focus\:border-gray4:focus{
  --tw-border-opacity:1;
  border-color:rgba(191, 191, 191, var(--tw-border-opacity))
}

.focus\:border-gray5:focus{
  --tw-border-opacity:1;
  border-color:rgba(217, 217, 217, var(--tw-border-opacity))
}

.focus\:border-gray6:focus{
  --tw-border-opacity:1;
  border-color:rgba(233, 233, 235, var(--tw-border-opacity))
}

.focus\:border-gray7:focus{
  --tw-border-opacity:1;
  border-color:rgba(81, 81, 100, var(--tw-border-opacity))
}

.focus\:border-white:focus{
  --tw-border-opacity:1;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity))
}

.focus\:border-warning:focus{
  --tw-border-opacity:1;
  border-color:rgba(255, 195, 25, var(--tw-border-opacity))
}

.focus\:border-error:focus{
  --tw-border-opacity:1;
  border-color:rgba(234, 105, 111, var(--tw-border-opacity))
}

.focus\:border-info:focus{
  --tw-border-opacity:1;
  border-color:rgba(0, 113, 185, var(--tw-border-opacity))
}

.focus\:border-success:focus{
  --tw-border-opacity:1;
  border-color:rgba(152, 212, 95, var(--tw-border-opacity))
}

.focus\:border-special4:focus{
  --tw-border-opacity:1;
  border-color:rgba(89, 119, 154, var(--tw-border-opacity))
}

.focus\:border-dark:focus{
  --tw-border-opacity:1;
  border-color:rgba(0, 0, 0, var(--tw-border-opacity))
}

.focus\:border-transparent:focus{
  border-color:transparent
}

.focus\:border-secondary1:focus{
  --tw-border-opacity:1;
  border-color:rgba(49, 49, 64, var(--tw-border-opacity))
}

.focus\:border-secondary2:focus{
  --tw-border-opacity:1;
  border-color:rgba(81, 81, 100, var(--tw-border-opacity))
}

.focus\:border-secondary3:focus{
  --tw-border-opacity:1;
  border-color:rgba(156, 156, 172, var(--tw-border-opacity))
}

.focus\:border-secondary4:focus{
  --tw-border-opacity:1;
  border-color:rgba(229, 232, 237, var(--tw-border-opacity))
}

.focus\:border-secondary5:focus{
  --tw-border-opacity:1;
  border-color:rgba(245, 246, 249, var(--tw-border-opacity))
}

.focus\:border-green1:focus{
  --tw-border-opacity:1;
  border-color:rgba(13, 226, 152, var(--tw-border-opacity))
}

.active\:border-red1:active{
  --tw-border-opacity:1;
  border-color:rgba(204, 53, 44, var(--tw-border-opacity))
}

.active\:border-red2:active{
  --tw-border-opacity:1;
  border-color:rgba(255, 85, 75, var(--tw-border-opacity))
}

.active\:border-red3:active{
  --tw-border-opacity:1;
  border-color:rgba(255, 123, 115, var(--tw-border-opacity))
}

.active\:border-red4:active{
  --tw-border-opacity:1;
  border-color:rgba(255, 241, 241, var(--tw-border-opacity))
}

.active\:border-red5:active{
  --tw-border-opacity:1;
  border-color:rgba(255, 246, 246, var(--tw-border-opacity))
}

.active\:border-cyan1:active{
  --tw-border-opacity:1;
  border-color:rgba(0, 152, 151, var(--tw-border-opacity))
}

.active\:border-cyan2:active{
  --tw-border-opacity:1;
  border-color:rgba(0, 191, 189, var(--tw-border-opacity))
}

.active\:border-cyan3:active{
  --tw-border-opacity:1;
  border-color:rgba(76, 209, 208, var(--tw-border-opacity))
}

.active\:border-cyan4:active{
  --tw-border-opacity:1;
  border-color:rgba(178, 236, 235, var(--tw-border-opacity))
}

.active\:border-cyan5:active{
  --tw-border-opacity:1;
  border-color:rgba(237, 250, 250, var(--tw-border-opacity))
}

.active\:border-bg1:active{
  --tw-border-opacity:1;
  border-color:rgba(250, 250, 252, var(--tw-border-opacity))
}

.active\:border-bg2:active{
  --tw-border-opacity:1;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity))
}

.active\:border-bg3:active{
  --tw-border-opacity:1;
  border-color:rgba(247, 247, 250, var(--tw-border-opacity))
}

.active\:border-gray1:active{
  --tw-border-opacity:1;
  border-color:rgba(51, 51, 51, var(--tw-border-opacity))
}

.active\:border-gray2:active{
  --tw-border-opacity:1;
  border-color:rgba(77, 77, 77, var(--tw-border-opacity))
}

.active\:border-gray3:active{
  --tw-border-opacity:1;
  border-color:rgba(140, 140, 140, var(--tw-border-opacity))
}

.active\:border-gray4:active{
  --tw-border-opacity:1;
  border-color:rgba(191, 191, 191, var(--tw-border-opacity))
}

.active\:border-gray5:active{
  --tw-border-opacity:1;
  border-color:rgba(217, 217, 217, var(--tw-border-opacity))
}

.active\:border-gray6:active{
  --tw-border-opacity:1;
  border-color:rgba(233, 233, 235, var(--tw-border-opacity))
}

.active\:border-gray7:active{
  --tw-border-opacity:1;
  border-color:rgba(81, 81, 100, var(--tw-border-opacity))
}

.active\:border-white:active{
  --tw-border-opacity:1;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity))
}

.active\:border-warning:active{
  --tw-border-opacity:1;
  border-color:rgba(255, 195, 25, var(--tw-border-opacity))
}

.active\:border-error:active{
  --tw-border-opacity:1;
  border-color:rgba(234, 105, 111, var(--tw-border-opacity))
}

.active\:border-info:active{
  --tw-border-opacity:1;
  border-color:rgba(0, 113, 185, var(--tw-border-opacity))
}

.active\:border-success:active{
  --tw-border-opacity:1;
  border-color:rgba(152, 212, 95, var(--tw-border-opacity))
}

.active\:border-special4:active{
  --tw-border-opacity:1;
  border-color:rgba(89, 119, 154, var(--tw-border-opacity))
}

.active\:border-dark:active{
  --tw-border-opacity:1;
  border-color:rgba(0, 0, 0, var(--tw-border-opacity))
}

.active\:border-transparent:active{
  border-color:transparent
}

.active\:border-secondary1:active{
  --tw-border-opacity:1;
  border-color:rgba(49, 49, 64, var(--tw-border-opacity))
}

.active\:border-secondary2:active{
  --tw-border-opacity:1;
  border-color:rgba(81, 81, 100, var(--tw-border-opacity))
}

.active\:border-secondary3:active{
  --tw-border-opacity:1;
  border-color:rgba(156, 156, 172, var(--tw-border-opacity))
}

.active\:border-secondary4:active{
  --tw-border-opacity:1;
  border-color:rgba(229, 232, 237, var(--tw-border-opacity))
}

.active\:border-secondary5:active{
  --tw-border-opacity:1;
  border-color:rgba(245, 246, 249, var(--tw-border-opacity))
}

.active\:border-green1:active{
  --tw-border-opacity:1;
  border-color:rgba(13, 226, 152, var(--tw-border-opacity))
}

.border-opacity-0{
  --tw-border-opacity:0
}

.border-opacity-5{
  --tw-border-opacity:0.05
}

.border-opacity-10{
  --tw-border-opacity:0.1
}

.border-opacity-20{
  --tw-border-opacity:0.2
}

.border-opacity-25{
  --tw-border-opacity:0.25
}

.border-opacity-30{
  --tw-border-opacity:0.3
}

.border-opacity-40{
  --tw-border-opacity:0.4
}

.border-opacity-50{
  --tw-border-opacity:0.5
}

.border-opacity-60{
  --tw-border-opacity:0.6
}

.border-opacity-70{
  --tw-border-opacity:0.7
}

.border-opacity-75{
  --tw-border-opacity:0.75
}

.border-opacity-80{
  --tw-border-opacity:0.8
}

.border-opacity-90{
  --tw-border-opacity:0.9
}

.border-opacity-95{
  --tw-border-opacity:0.95
}

.border-opacity-100{
  --tw-border-opacity:1
}

.group:hover .group-hover\:border-opacity-0{
  --tw-border-opacity:0
}

.group:hover .group-hover\:border-opacity-5{
  --tw-border-opacity:0.05
}

.group:hover .group-hover\:border-opacity-10{
  --tw-border-opacity:0.1
}

.group:hover .group-hover\:border-opacity-20{
  --tw-border-opacity:0.2
}

.group:hover .group-hover\:border-opacity-25{
  --tw-border-opacity:0.25
}

.group:hover .group-hover\:border-opacity-30{
  --tw-border-opacity:0.3
}

.group:hover .group-hover\:border-opacity-40{
  --tw-border-opacity:0.4
}

.group:hover .group-hover\:border-opacity-50{
  --tw-border-opacity:0.5
}

.group:hover .group-hover\:border-opacity-60{
  --tw-border-opacity:0.6
}

.group:hover .group-hover\:border-opacity-70{
  --tw-border-opacity:0.7
}

.group:hover .group-hover\:border-opacity-75{
  --tw-border-opacity:0.75
}

.group:hover .group-hover\:border-opacity-80{
  --tw-border-opacity:0.8
}

.group:hover .group-hover\:border-opacity-90{
  --tw-border-opacity:0.9
}

.group:hover .group-hover\:border-opacity-95{
  --tw-border-opacity:0.95
}

.group:hover .group-hover\:border-opacity-100{
  --tw-border-opacity:1
}

.focus-within\:border-opacity-0:focus-within{
  --tw-border-opacity:0
}

.focus-within\:border-opacity-5:focus-within{
  --tw-border-opacity:0.05
}

.focus-within\:border-opacity-10:focus-within{
  --tw-border-opacity:0.1
}

.focus-within\:border-opacity-20:focus-within{
  --tw-border-opacity:0.2
}

.focus-within\:border-opacity-25:focus-within{
  --tw-border-opacity:0.25
}

.focus-within\:border-opacity-30:focus-within{
  --tw-border-opacity:0.3
}

.focus-within\:border-opacity-40:focus-within{
  --tw-border-opacity:0.4
}

.focus-within\:border-opacity-50:focus-within{
  --tw-border-opacity:0.5
}

.focus-within\:border-opacity-60:focus-within{
  --tw-border-opacity:0.6
}

.focus-within\:border-opacity-70:focus-within{
  --tw-border-opacity:0.7
}

.focus-within\:border-opacity-75:focus-within{
  --tw-border-opacity:0.75
}

.focus-within\:border-opacity-80:focus-within{
  --tw-border-opacity:0.8
}

.focus-within\:border-opacity-90:focus-within{
  --tw-border-opacity:0.9
}

.focus-within\:border-opacity-95:focus-within{
  --tw-border-opacity:0.95
}

.focus-within\:border-opacity-100:focus-within{
  --tw-border-opacity:1
}

.hover\:border-opacity-0:hover{
  --tw-border-opacity:0
}

.hover\:border-opacity-5:hover{
  --tw-border-opacity:0.05
}

.hover\:border-opacity-10:hover{
  --tw-border-opacity:0.1
}

.hover\:border-opacity-20:hover{
  --tw-border-opacity:0.2
}

.hover\:border-opacity-25:hover{
  --tw-border-opacity:0.25
}

.hover\:border-opacity-30:hover{
  --tw-border-opacity:0.3
}

.hover\:border-opacity-40:hover{
  --tw-border-opacity:0.4
}

.hover\:border-opacity-50:hover{
  --tw-border-opacity:0.5
}

.hover\:border-opacity-60:hover{
  --tw-border-opacity:0.6
}

.hover\:border-opacity-70:hover{
  --tw-border-opacity:0.7
}

.hover\:border-opacity-75:hover{
  --tw-border-opacity:0.75
}

.hover\:border-opacity-80:hover{
  --tw-border-opacity:0.8
}

.hover\:border-opacity-90:hover{
  --tw-border-opacity:0.9
}

.hover\:border-opacity-95:hover{
  --tw-border-opacity:0.95
}

.hover\:border-opacity-100:hover{
  --tw-border-opacity:1
}

.focus\:border-opacity-0:focus{
  --tw-border-opacity:0
}

.focus\:border-opacity-5:focus{
  --tw-border-opacity:0.05
}

.focus\:border-opacity-10:focus{
  --tw-border-opacity:0.1
}

.focus\:border-opacity-20:focus{
  --tw-border-opacity:0.2
}

.focus\:border-opacity-25:focus{
  --tw-border-opacity:0.25
}

.focus\:border-opacity-30:focus{
  --tw-border-opacity:0.3
}

.focus\:border-opacity-40:focus{
  --tw-border-opacity:0.4
}

.focus\:border-opacity-50:focus{
  --tw-border-opacity:0.5
}

.focus\:border-opacity-60:focus{
  --tw-border-opacity:0.6
}

.focus\:border-opacity-70:focus{
  --tw-border-opacity:0.7
}

.focus\:border-opacity-75:focus{
  --tw-border-opacity:0.75
}

.focus\:border-opacity-80:focus{
  --tw-border-opacity:0.8
}

.focus\:border-opacity-90:focus{
  --tw-border-opacity:0.9
}

.focus\:border-opacity-95:focus{
  --tw-border-opacity:0.95
}

.focus\:border-opacity-100:focus{
  --tw-border-opacity:1
}

.bg-red1{
  --tw-bg-opacity:1;
  background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
}

.bg-red2{
  --tw-bg-opacity:1;
  background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
}

.bg-red3{
  --tw-bg-opacity:1;
  background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
}

.bg-red4{
  --tw-bg-opacity:1;
  background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
}

.bg-red5{
  --tw-bg-opacity:1;
  background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
}

.bg-cyan1{
  --tw-bg-opacity:1;
  background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
}

.bg-cyan2{
  --tw-bg-opacity:1;
  background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
}

.bg-cyan3{
  --tw-bg-opacity:1;
  background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
}

.bg-cyan4{
  --tw-bg-opacity:1;
  background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
}

.bg-cyan5{
  --tw-bg-opacity:1;
  background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
}

.bg-bg1{
  --tw-bg-opacity:1;
  background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
}

.bg-bg2{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.bg-bg3{
  --tw-bg-opacity:1;
  background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
}

.bg-gray1{
  --tw-bg-opacity:1;
  background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
}

.bg-gray2{
  --tw-bg-opacity:1;
  background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
}

.bg-gray3{
  --tw-bg-opacity:1;
  background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
}

.bg-gray4{
  --tw-bg-opacity:1;
  background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
}

.bg-gray5{
  --tw-bg-opacity:1;
  background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
}

.bg-gray6{
  --tw-bg-opacity:1;
  background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
}

.bg-gray7{
  --tw-bg-opacity:1;
  background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
}

.bg-white{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.bg-warning{
  --tw-bg-opacity:1;
  background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
}

.bg-error{
  --tw-bg-opacity:1;
  background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
}

.bg-info{
  --tw-bg-opacity:1;
  background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
}

.bg-success{
  --tw-bg-opacity:1;
  background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
}

.bg-special4{
  --tw-bg-opacity:1;
  background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
}

.bg-dark{
  --tw-bg-opacity:1;
  background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
}

.bg-transparent{
  background-color:transparent
}

.bg-secondary1{
  --tw-bg-opacity:1;
  background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
}

.bg-secondary2{
  --tw-bg-opacity:1;
  background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
}

.bg-secondary3{
  --tw-bg-opacity:1;
  background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
}

.bg-secondary4{
  --tw-bg-opacity:1;
  background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
}

.bg-secondary5{
  --tw-bg-opacity:1;
  background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
}

.bg-green1{
  --tw-bg-opacity:1;
  background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
}

.hover\:bg-red1:hover{
  --tw-bg-opacity:1;
  background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
}

.hover\:bg-red2:hover{
  --tw-bg-opacity:1;
  background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
}

.hover\:bg-red3:hover{
  --tw-bg-opacity:1;
  background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
}

.hover\:bg-red4:hover{
  --tw-bg-opacity:1;
  background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
}

.hover\:bg-red5:hover{
  --tw-bg-opacity:1;
  background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
}

.hover\:bg-cyan1:hover{
  --tw-bg-opacity:1;
  background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
}

.hover\:bg-cyan2:hover{
  --tw-bg-opacity:1;
  background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
}

.hover\:bg-cyan3:hover{
  --tw-bg-opacity:1;
  background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
}

.hover\:bg-cyan4:hover{
  --tw-bg-opacity:1;
  background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
}

.hover\:bg-cyan5:hover{
  --tw-bg-opacity:1;
  background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
}

.hover\:bg-bg1:hover{
  --tw-bg-opacity:1;
  background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
}

.hover\:bg-bg2:hover{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.hover\:bg-bg3:hover{
  --tw-bg-opacity:1;
  background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
}

.hover\:bg-gray1:hover{
  --tw-bg-opacity:1;
  background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
}

.hover\:bg-gray2:hover{
  --tw-bg-opacity:1;
  background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
}

.hover\:bg-gray3:hover{
  --tw-bg-opacity:1;
  background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
}

.hover\:bg-gray4:hover{
  --tw-bg-opacity:1;
  background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
}

.hover\:bg-gray5:hover{
  --tw-bg-opacity:1;
  background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
}

.hover\:bg-gray6:hover{
  --tw-bg-opacity:1;
  background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
}

.hover\:bg-gray7:hover{
  --tw-bg-opacity:1;
  background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
}

.hover\:bg-white:hover{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.hover\:bg-warning:hover{
  --tw-bg-opacity:1;
  background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
}

.hover\:bg-error:hover{
  --tw-bg-opacity:1;
  background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
}

.hover\:bg-info:hover{
  --tw-bg-opacity:1;
  background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
}

.hover\:bg-success:hover{
  --tw-bg-opacity:1;
  background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
}

.hover\:bg-special4:hover{
  --tw-bg-opacity:1;
  background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
}

.hover\:bg-dark:hover{
  --tw-bg-opacity:1;
  background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
}

.hover\:bg-transparent:hover{
  background-color:transparent
}

.hover\:bg-secondary1:hover{
  --tw-bg-opacity:1;
  background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
}

.hover\:bg-secondary2:hover{
  --tw-bg-opacity:1;
  background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
}

.hover\:bg-secondary3:hover{
  --tw-bg-opacity:1;
  background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
}

.hover\:bg-secondary4:hover{
  --tw-bg-opacity:1;
  background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
}

.hover\:bg-secondary5:hover{
  --tw-bg-opacity:1;
  background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
}

.hover\:bg-green1:hover{
  --tw-bg-opacity:1;
  background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
}

.focus\:bg-red1:focus{
  --tw-bg-opacity:1;
  background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
}

.focus\:bg-red2:focus{
  --tw-bg-opacity:1;
  background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
}

.focus\:bg-red3:focus{
  --tw-bg-opacity:1;
  background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
}

.focus\:bg-red4:focus{
  --tw-bg-opacity:1;
  background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
}

.focus\:bg-red5:focus{
  --tw-bg-opacity:1;
  background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
}

.focus\:bg-cyan1:focus{
  --tw-bg-opacity:1;
  background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
}

.focus\:bg-cyan2:focus{
  --tw-bg-opacity:1;
  background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
}

.focus\:bg-cyan3:focus{
  --tw-bg-opacity:1;
  background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
}

.focus\:bg-cyan4:focus{
  --tw-bg-opacity:1;
  background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
}

.focus\:bg-cyan5:focus{
  --tw-bg-opacity:1;
  background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
}

.focus\:bg-bg1:focus{
  --tw-bg-opacity:1;
  background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
}

.focus\:bg-bg2:focus{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.focus\:bg-bg3:focus{
  --tw-bg-opacity:1;
  background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
}

.focus\:bg-gray1:focus{
  --tw-bg-opacity:1;
  background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
}

.focus\:bg-gray2:focus{
  --tw-bg-opacity:1;
  background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
}

.focus\:bg-gray3:focus{
  --tw-bg-opacity:1;
  background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
}

.focus\:bg-gray4:focus{
  --tw-bg-opacity:1;
  background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
}

.focus\:bg-gray5:focus{
  --tw-bg-opacity:1;
  background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
}

.focus\:bg-gray6:focus{
  --tw-bg-opacity:1;
  background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
}

.focus\:bg-gray7:focus{
  --tw-bg-opacity:1;
  background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
}

.focus\:bg-white:focus{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.focus\:bg-warning:focus{
  --tw-bg-opacity:1;
  background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
}

.focus\:bg-error:focus{
  --tw-bg-opacity:1;
  background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
}

.focus\:bg-info:focus{
  --tw-bg-opacity:1;
  background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
}

.focus\:bg-success:focus{
  --tw-bg-opacity:1;
  background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
}

.focus\:bg-special4:focus{
  --tw-bg-opacity:1;
  background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
}

.focus\:bg-dark:focus{
  --tw-bg-opacity:1;
  background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
}

.focus\:bg-transparent:focus{
  background-color:transparent
}

.focus\:bg-secondary1:focus{
  --tw-bg-opacity:1;
  background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
}

.focus\:bg-secondary2:focus{
  --tw-bg-opacity:1;
  background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
}

.focus\:bg-secondary3:focus{
  --tw-bg-opacity:1;
  background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
}

.focus\:bg-secondary4:focus{
  --tw-bg-opacity:1;
  background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
}

.focus\:bg-secondary5:focus{
  --tw-bg-opacity:1;
  background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
}

.focus\:bg-green1:focus{
  --tw-bg-opacity:1;
  background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
}

.active\:bg-red1:active{
  --tw-bg-opacity:1;
  background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
}

.active\:bg-red2:active{
  --tw-bg-opacity:1;
  background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
}

.active\:bg-red3:active{
  --tw-bg-opacity:1;
  background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
}

.active\:bg-red4:active{
  --tw-bg-opacity:1;
  background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
}

.active\:bg-red5:active{
  --tw-bg-opacity:1;
  background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
}

.active\:bg-cyan1:active{
  --tw-bg-opacity:1;
  background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
}

.active\:bg-cyan2:active{
  --tw-bg-opacity:1;
  background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
}

.active\:bg-cyan3:active{
  --tw-bg-opacity:1;
  background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
}

.active\:bg-cyan4:active{
  --tw-bg-opacity:1;
  background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
}

.active\:bg-cyan5:active{
  --tw-bg-opacity:1;
  background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
}

.active\:bg-bg1:active{
  --tw-bg-opacity:1;
  background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
}

.active\:bg-bg2:active{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.active\:bg-bg3:active{
  --tw-bg-opacity:1;
  background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
}

.active\:bg-gray1:active{
  --tw-bg-opacity:1;
  background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
}

.active\:bg-gray2:active{
  --tw-bg-opacity:1;
  background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
}

.active\:bg-gray3:active{
  --tw-bg-opacity:1;
  background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
}

.active\:bg-gray4:active{
  --tw-bg-opacity:1;
  background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
}

.active\:bg-gray5:active{
  --tw-bg-opacity:1;
  background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
}

.active\:bg-gray6:active{
  --tw-bg-opacity:1;
  background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
}

.active\:bg-gray7:active{
  --tw-bg-opacity:1;
  background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
}

.active\:bg-white:active{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.active\:bg-warning:active{
  --tw-bg-opacity:1;
  background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
}

.active\:bg-error:active{
  --tw-bg-opacity:1;
  background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
}

.active\:bg-info:active{
  --tw-bg-opacity:1;
  background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
}

.active\:bg-success:active{
  --tw-bg-opacity:1;
  background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
}

.active\:bg-special4:active{
  --tw-bg-opacity:1;
  background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
}

.active\:bg-dark:active{
  --tw-bg-opacity:1;
  background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
}

.active\:bg-transparent:active{
  background-color:transparent
}

.active\:bg-secondary1:active{
  --tw-bg-opacity:1;
  background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
}

.active\:bg-secondary2:active{
  --tw-bg-opacity:1;
  background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
}

.active\:bg-secondary3:active{
  --tw-bg-opacity:1;
  background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
}

.active\:bg-secondary4:active{
  --tw-bg-opacity:1;
  background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
}

.active\:bg-secondary5:active{
  --tw-bg-opacity:1;
  background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
}

.active\:bg-green1:active{
  --tw-bg-opacity:1;
  background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red1{
  --tw-bg-opacity:1;
  background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red2{
  --tw-bg-opacity:1;
  background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red3{
  --tw-bg-opacity:1;
  background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red4{
  --tw-bg-opacity:1;
  background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-red5{
  --tw-bg-opacity:1;
  background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan1{
  --tw-bg-opacity:1;
  background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan2{
  --tw-bg-opacity:1;
  background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan3{
  --tw-bg-opacity:1;
  background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan4{
  --tw-bg-opacity:1;
  background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-cyan5{
  --tw-bg-opacity:1;
  background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-bg1{
  --tw-bg-opacity:1;
  background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-bg2{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-bg3{
  --tw-bg-opacity:1;
  background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray1{
  --tw-bg-opacity:1;
  background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray2{
  --tw-bg-opacity:1;
  background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray3{
  --tw-bg-opacity:1;
  background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray4{
  --tw-bg-opacity:1;
  background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray5{
  --tw-bg-opacity:1;
  background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray6{
  --tw-bg-opacity:1;
  background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gray7{
  --tw-bg-opacity:1;
  background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-white{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-warning{
  --tw-bg-opacity:1;
  background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-error{
  --tw-bg-opacity:1;
  background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-info{
  --tw-bg-opacity:1;
  background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-success{
  --tw-bg-opacity:1;
  background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-special4{
  --tw-bg-opacity:1;
  background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-dark{
  --tw-bg-opacity:1;
  background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-transparent{
  background-color:transparent
}

.group:hover .group-hover\:bg-secondary1{
  --tw-bg-opacity:1;
  background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-secondary2{
  --tw-bg-opacity:1;
  background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-secondary3{
  --tw-bg-opacity:1;
  background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-secondary4{
  --tw-bg-opacity:1;
  background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-secondary5{
  --tw-bg-opacity:1;
  background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-green1{
  --tw-bg-opacity:1;
  background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
}

.bg-opacity-0{
  --tw-bg-opacity:0
}

.bg-opacity-5{
  --tw-bg-opacity:0.05
}

.bg-opacity-10{
  --tw-bg-opacity:0.1
}

.bg-opacity-20{
  --tw-bg-opacity:0.2
}

.bg-opacity-25{
  --tw-bg-opacity:0.25
}

.bg-opacity-30{
  --tw-bg-opacity:0.3
}

.bg-opacity-40{
  --tw-bg-opacity:0.4
}

.bg-opacity-50{
  --tw-bg-opacity:0.5
}

.bg-opacity-60{
  --tw-bg-opacity:0.6
}

.bg-opacity-70{
  --tw-bg-opacity:0.7
}

.bg-opacity-75{
  --tw-bg-opacity:0.75
}

.bg-opacity-80{
  --tw-bg-opacity:0.8
}

.bg-opacity-90{
  --tw-bg-opacity:0.9
}

.bg-opacity-95{
  --tw-bg-opacity:0.95
}

.bg-opacity-100{
  --tw-bg-opacity:1
}

.group:hover .group-hover\:bg-opacity-0{
  --tw-bg-opacity:0
}

.group:hover .group-hover\:bg-opacity-5{
  --tw-bg-opacity:0.05
}

.group:hover .group-hover\:bg-opacity-10{
  --tw-bg-opacity:0.1
}

.group:hover .group-hover\:bg-opacity-20{
  --tw-bg-opacity:0.2
}

.group:hover .group-hover\:bg-opacity-25{
  --tw-bg-opacity:0.25
}

.group:hover .group-hover\:bg-opacity-30{
  --tw-bg-opacity:0.3
}

.group:hover .group-hover\:bg-opacity-40{
  --tw-bg-opacity:0.4
}

.group:hover .group-hover\:bg-opacity-50{
  --tw-bg-opacity:0.5
}

.group:hover .group-hover\:bg-opacity-60{
  --tw-bg-opacity:0.6
}

.group:hover .group-hover\:bg-opacity-70{
  --tw-bg-opacity:0.7
}

.group:hover .group-hover\:bg-opacity-75{
  --tw-bg-opacity:0.75
}

.group:hover .group-hover\:bg-opacity-80{
  --tw-bg-opacity:0.8
}

.group:hover .group-hover\:bg-opacity-90{
  --tw-bg-opacity:0.9
}

.group:hover .group-hover\:bg-opacity-95{
  --tw-bg-opacity:0.95
}

.group:hover .group-hover\:bg-opacity-100{
  --tw-bg-opacity:1
}

.focus-within\:bg-opacity-0:focus-within{
  --tw-bg-opacity:0
}

.focus-within\:bg-opacity-5:focus-within{
  --tw-bg-opacity:0.05
}

.focus-within\:bg-opacity-10:focus-within{
  --tw-bg-opacity:0.1
}

.focus-within\:bg-opacity-20:focus-within{
  --tw-bg-opacity:0.2
}

.focus-within\:bg-opacity-25:focus-within{
  --tw-bg-opacity:0.25
}

.focus-within\:bg-opacity-30:focus-within{
  --tw-bg-opacity:0.3
}

.focus-within\:bg-opacity-40:focus-within{
  --tw-bg-opacity:0.4
}

.focus-within\:bg-opacity-50:focus-within{
  --tw-bg-opacity:0.5
}

.focus-within\:bg-opacity-60:focus-within{
  --tw-bg-opacity:0.6
}

.focus-within\:bg-opacity-70:focus-within{
  --tw-bg-opacity:0.7
}

.focus-within\:bg-opacity-75:focus-within{
  --tw-bg-opacity:0.75
}

.focus-within\:bg-opacity-80:focus-within{
  --tw-bg-opacity:0.8
}

.focus-within\:bg-opacity-90:focus-within{
  --tw-bg-opacity:0.9
}

.focus-within\:bg-opacity-95:focus-within{
  --tw-bg-opacity:0.95
}

.focus-within\:bg-opacity-100:focus-within{
  --tw-bg-opacity:1
}

.hover\:bg-opacity-0:hover{
  --tw-bg-opacity:0
}

.hover\:bg-opacity-5:hover{
  --tw-bg-opacity:0.05
}

.hover\:bg-opacity-10:hover{
  --tw-bg-opacity:0.1
}

.hover\:bg-opacity-20:hover{
  --tw-bg-opacity:0.2
}

.hover\:bg-opacity-25:hover{
  --tw-bg-opacity:0.25
}

.hover\:bg-opacity-30:hover{
  --tw-bg-opacity:0.3
}

.hover\:bg-opacity-40:hover{
  --tw-bg-opacity:0.4
}

.hover\:bg-opacity-50:hover{
  --tw-bg-opacity:0.5
}

.hover\:bg-opacity-60:hover{
  --tw-bg-opacity:0.6
}

.hover\:bg-opacity-70:hover{
  --tw-bg-opacity:0.7
}

.hover\:bg-opacity-75:hover{
  --tw-bg-opacity:0.75
}

.hover\:bg-opacity-80:hover{
  --tw-bg-opacity:0.8
}

.hover\:bg-opacity-90:hover{
  --tw-bg-opacity:0.9
}

.hover\:bg-opacity-95:hover{
  --tw-bg-opacity:0.95
}

.hover\:bg-opacity-100:hover{
  --tw-bg-opacity:1
}

.focus\:bg-opacity-0:focus{
  --tw-bg-opacity:0
}

.focus\:bg-opacity-5:focus{
  --tw-bg-opacity:0.05
}

.focus\:bg-opacity-10:focus{
  --tw-bg-opacity:0.1
}

.focus\:bg-opacity-20:focus{
  --tw-bg-opacity:0.2
}

.focus\:bg-opacity-25:focus{
  --tw-bg-opacity:0.25
}

.focus\:bg-opacity-30:focus{
  --tw-bg-opacity:0.3
}

.focus\:bg-opacity-40:focus{
  --tw-bg-opacity:0.4
}

.focus\:bg-opacity-50:focus{
  --tw-bg-opacity:0.5
}

.focus\:bg-opacity-60:focus{
  --tw-bg-opacity:0.6
}

.focus\:bg-opacity-70:focus{
  --tw-bg-opacity:0.7
}

.focus\:bg-opacity-75:focus{
  --tw-bg-opacity:0.75
}

.focus\:bg-opacity-80:focus{
  --tw-bg-opacity:0.8
}

.focus\:bg-opacity-90:focus{
  --tw-bg-opacity:0.9
}

.focus\:bg-opacity-95:focus{
  --tw-bg-opacity:0.95
}

.focus\:bg-opacity-100:focus{
  --tw-bg-opacity:1
}

.bg-none{
  background-image:none
}

.bg-gradient-to-t{
  background-image:linear-gradient(to top, var(--tw-gradient-stops))
}

.bg-gradient-to-tr{
  background-image:linear-gradient(to top right, var(--tw-gradient-stops))
}

.bg-gradient-to-r{
  background-image:linear-gradient(to right, var(--tw-gradient-stops))
}

.bg-gradient-to-br{
  background-image:linear-gradient(to bottom right, var(--tw-gradient-stops))
}

.bg-gradient-to-b{
  background-image:linear-gradient(to bottom, var(--tw-gradient-stops))
}

.bg-gradient-to-bl{
  background-image:linear-gradient(to bottom left, var(--tw-gradient-stops))
}

.bg-gradient-to-l{
  background-image:linear-gradient(to left, var(--tw-gradient-stops))
}

.bg-gradient-to-tl{
  background-image:linear-gradient(to top left, var(--tw-gradient-stops))
}

.from-red1{
  --tw-gradient-from:#CC352C;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
}

.from-red2{
  --tw-gradient-from:#FF554B;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
}

.from-red3{
  --tw-gradient-from:#FF7B73;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
}

.from-red4{
  --tw-gradient-from:#FFF1F1;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
}

.from-red5{
  --tw-gradient-from:#FFF6F6;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
}

.from-cyan1{
  --tw-gradient-from:#009897;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
}

.from-cyan2{
  --tw-gradient-from:#00BFBD;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
}

.from-cyan3{
  --tw-gradient-from:#4CD1D0;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
}

.from-cyan4{
  --tw-gradient-from:#B2ECEB;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
}

.from-cyan5{
  --tw-gradient-from:#EDFAFA;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
}

.from-bg1{
  --tw-gradient-from:#FAFAFC;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
}

.from-bg2{
  --tw-gradient-from:#ffffff;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.from-bg3{
  --tw-gradient-from:#F7F7FA;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
}

.from-gray1{
  --tw-gradient-from:#333333;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
}

.from-gray2{
  --tw-gradient-from:#4D4D4D;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
}

.from-gray3{
  --tw-gradient-from:#8C8C8C;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
}

.from-gray4{
  --tw-gradient-from:#BFBFBF;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
}

.from-gray5{
  --tw-gradient-from:#D9D9D9;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
}

.from-gray6{
  --tw-gradient-from:#E9E9EB;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
}

.from-gray7{
  --tw-gradient-from:#515164;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.from-white{
  --tw-gradient-from:#ffffff;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.from-warning{
  --tw-gradient-from:#FFC319;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
}

.from-error{
  --tw-gradient-from:#EA696F;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
}

.from-info{
  --tw-gradient-from:#0071B9;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
}

.from-success{
  --tw-gradient-from:#98D45F;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
}

.from-special4{
  --tw-gradient-from:#59779A;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
}

.from-dark{
  --tw-gradient-from:#000000;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.from-transparent{
  --tw-gradient-from:transparent;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.from-secondary1{
  --tw-gradient-from:#313140;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
}

.from-secondary2{
  --tw-gradient-from:#515164;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.from-secondary3{
  --tw-gradient-from:#9C9CAC;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
}

.from-secondary4{
  --tw-gradient-from:#E5E8ED;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
}

.from-secondary5{
  --tw-gradient-from:#F5F6F9;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
}

.from-green1{
  --tw-gradient-from:#0DE298;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
}

.hover\:from-red1:hover{
  --tw-gradient-from:#CC352C;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
}

.hover\:from-red2:hover{
  --tw-gradient-from:#FF554B;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
}

.hover\:from-red3:hover{
  --tw-gradient-from:#FF7B73;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
}

.hover\:from-red4:hover{
  --tw-gradient-from:#FFF1F1;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
}

.hover\:from-red5:hover{
  --tw-gradient-from:#FFF6F6;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
}

.hover\:from-cyan1:hover{
  --tw-gradient-from:#009897;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
}

.hover\:from-cyan2:hover{
  --tw-gradient-from:#00BFBD;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
}

.hover\:from-cyan3:hover{
  --tw-gradient-from:#4CD1D0;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
}

.hover\:from-cyan4:hover{
  --tw-gradient-from:#B2ECEB;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
}

.hover\:from-cyan5:hover{
  --tw-gradient-from:#EDFAFA;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
}

.hover\:from-bg1:hover{
  --tw-gradient-from:#FAFAFC;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
}

.hover\:from-bg2:hover{
  --tw-gradient-from:#ffffff;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.hover\:from-bg3:hover{
  --tw-gradient-from:#F7F7FA;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
}

.hover\:from-gray1:hover{
  --tw-gradient-from:#333333;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
}

.hover\:from-gray2:hover{
  --tw-gradient-from:#4D4D4D;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
}

.hover\:from-gray3:hover{
  --tw-gradient-from:#8C8C8C;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
}

.hover\:from-gray4:hover{
  --tw-gradient-from:#BFBFBF;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
}

.hover\:from-gray5:hover{
  --tw-gradient-from:#D9D9D9;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
}

.hover\:from-gray6:hover{
  --tw-gradient-from:#E9E9EB;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
}

.hover\:from-gray7:hover{
  --tw-gradient-from:#515164;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.hover\:from-white:hover{
  --tw-gradient-from:#ffffff;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.hover\:from-warning:hover{
  --tw-gradient-from:#FFC319;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
}

.hover\:from-error:hover{
  --tw-gradient-from:#EA696F;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
}

.hover\:from-info:hover{
  --tw-gradient-from:#0071B9;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
}

.hover\:from-success:hover{
  --tw-gradient-from:#98D45F;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
}

.hover\:from-special4:hover{
  --tw-gradient-from:#59779A;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
}

.hover\:from-dark:hover{
  --tw-gradient-from:#000000;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.hover\:from-transparent:hover{
  --tw-gradient-from:transparent;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.hover\:from-secondary1:hover{
  --tw-gradient-from:#313140;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
}

.hover\:from-secondary2:hover{
  --tw-gradient-from:#515164;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.hover\:from-secondary3:hover{
  --tw-gradient-from:#9C9CAC;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
}

.hover\:from-secondary4:hover{
  --tw-gradient-from:#E5E8ED;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
}

.hover\:from-secondary5:hover{
  --tw-gradient-from:#F5F6F9;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
}

.hover\:from-green1:hover{
  --tw-gradient-from:#0DE298;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
}

.focus\:from-red1:focus{
  --tw-gradient-from:#CC352C;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
}

.focus\:from-red2:focus{
  --tw-gradient-from:#FF554B;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
}

.focus\:from-red3:focus{
  --tw-gradient-from:#FF7B73;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
}

.focus\:from-red4:focus{
  --tw-gradient-from:#FFF1F1;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
}

.focus\:from-red5:focus{
  --tw-gradient-from:#FFF6F6;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
}

.focus\:from-cyan1:focus{
  --tw-gradient-from:#009897;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
}

.focus\:from-cyan2:focus{
  --tw-gradient-from:#00BFBD;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
}

.focus\:from-cyan3:focus{
  --tw-gradient-from:#4CD1D0;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
}

.focus\:from-cyan4:focus{
  --tw-gradient-from:#B2ECEB;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
}

.focus\:from-cyan5:focus{
  --tw-gradient-from:#EDFAFA;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
}

.focus\:from-bg1:focus{
  --tw-gradient-from:#FAFAFC;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
}

.focus\:from-bg2:focus{
  --tw-gradient-from:#ffffff;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.focus\:from-bg3:focus{
  --tw-gradient-from:#F7F7FA;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
}

.focus\:from-gray1:focus{
  --tw-gradient-from:#333333;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
}

.focus\:from-gray2:focus{
  --tw-gradient-from:#4D4D4D;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
}

.focus\:from-gray3:focus{
  --tw-gradient-from:#8C8C8C;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
}

.focus\:from-gray4:focus{
  --tw-gradient-from:#BFBFBF;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
}

.focus\:from-gray5:focus{
  --tw-gradient-from:#D9D9D9;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
}

.focus\:from-gray6:focus{
  --tw-gradient-from:#E9E9EB;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
}

.focus\:from-gray7:focus{
  --tw-gradient-from:#515164;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.focus\:from-white:focus{
  --tw-gradient-from:#ffffff;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.focus\:from-warning:focus{
  --tw-gradient-from:#FFC319;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
}

.focus\:from-error:focus{
  --tw-gradient-from:#EA696F;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
}

.focus\:from-info:focus{
  --tw-gradient-from:#0071B9;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
}

.focus\:from-success:focus{
  --tw-gradient-from:#98D45F;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
}

.focus\:from-special4:focus{
  --tw-gradient-from:#59779A;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
}

.focus\:from-dark:focus{
  --tw-gradient-from:#000000;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.focus\:from-transparent:focus{
  --tw-gradient-from:transparent;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.focus\:from-secondary1:focus{
  --tw-gradient-from:#313140;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
}

.focus\:from-secondary2:focus{
  --tw-gradient-from:#515164;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.focus\:from-secondary3:focus{
  --tw-gradient-from:#9C9CAC;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
}

.focus\:from-secondary4:focus{
  --tw-gradient-from:#E5E8ED;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
}

.focus\:from-secondary5:focus{
  --tw-gradient-from:#F5F6F9;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
}

.focus\:from-green1:focus{
  --tw-gradient-from:#0DE298;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
}

.via-red1{
  --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
}

.via-red2{
  --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
}

.via-red3{
  --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
}

.via-red4{
  --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
}

.via-red5{
  --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
}

.via-cyan1{
  --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
}

.via-cyan2{
  --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
}

.via-cyan3{
  --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
}

.via-cyan4{
  --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
}

.via-cyan5{
  --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
}

.via-bg1{
  --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
}

.via-bg2{
  --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.via-bg3{
  --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
}

.via-gray1{
  --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
}

.via-gray2{
  --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
}

.via-gray3{
  --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
}

.via-gray4{
  --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
}

.via-gray5{
  --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
}

.via-gray6{
  --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
}

.via-gray7{
  --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.via-white{
  --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.via-warning{
  --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
}

.via-error{
  --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
}

.via-info{
  --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
}

.via-success{
  --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
}

.via-special4{
  --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
}

.via-dark{
  --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.via-transparent{
  --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.via-secondary1{
  --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
}

.via-secondary2{
  --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.via-secondary3{
  --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
}

.via-secondary4{
  --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
}

.via-secondary5{
  --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
}

.via-green1{
  --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
}

.hover\:via-red1:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
}

.hover\:via-red2:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
}

.hover\:via-red3:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
}

.hover\:via-red4:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
}

.hover\:via-red5:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
}

.hover\:via-cyan1:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
}

.hover\:via-cyan2:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
}

.hover\:via-cyan3:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
}

.hover\:via-cyan4:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
}

.hover\:via-cyan5:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
}

.hover\:via-bg1:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
}

.hover\:via-bg2:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.hover\:via-bg3:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
}

.hover\:via-gray1:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
}

.hover\:via-gray2:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
}

.hover\:via-gray3:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
}

.hover\:via-gray4:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
}

.hover\:via-gray5:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
}

.hover\:via-gray6:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
}

.hover\:via-gray7:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.hover\:via-white:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.hover\:via-warning:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
}

.hover\:via-error:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
}

.hover\:via-info:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
}

.hover\:via-success:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
}

.hover\:via-special4:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
}

.hover\:via-dark:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.hover\:via-transparent:hover{
  --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.hover\:via-secondary1:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
}

.hover\:via-secondary2:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.hover\:via-secondary3:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
}

.hover\:via-secondary4:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
}

.hover\:via-secondary5:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
}

.hover\:via-green1:hover{
  --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
}

.focus\:via-red1:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
}

.focus\:via-red2:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
}

.focus\:via-red3:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
}

.focus\:via-red4:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
}

.focus\:via-red5:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
}

.focus\:via-cyan1:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
}

.focus\:via-cyan2:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
}

.focus\:via-cyan3:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
}

.focus\:via-cyan4:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
}

.focus\:via-cyan5:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
}

.focus\:via-bg1:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
}

.focus\:via-bg2:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.focus\:via-bg3:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
}

.focus\:via-gray1:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
}

.focus\:via-gray2:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
}

.focus\:via-gray3:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
}

.focus\:via-gray4:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
}

.focus\:via-gray5:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
}

.focus\:via-gray6:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
}

.focus\:via-gray7:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.focus\:via-white:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

.focus\:via-warning:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
}

.focus\:via-error:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
}

.focus\:via-info:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
}

.focus\:via-success:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
}

.focus\:via-special4:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
}

.focus\:via-dark:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.focus\:via-transparent:focus{
  --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

.focus\:via-secondary1:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
}

.focus\:via-secondary2:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
}

.focus\:via-secondary3:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
}

.focus\:via-secondary4:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
}

.focus\:via-secondary5:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
}

.focus\:via-green1:focus{
  --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
}

.to-red1{
  --tw-gradient-to:#CC352C
}

.to-red2{
  --tw-gradient-to:#FF554B
}

.to-red3{
  --tw-gradient-to:#FF7B73
}

.to-red4{
  --tw-gradient-to:#FFF1F1
}

.to-red5{
  --tw-gradient-to:#FFF6F6
}

.to-cyan1{
  --tw-gradient-to:#009897
}

.to-cyan2{
  --tw-gradient-to:#00BFBD
}

.to-cyan3{
  --tw-gradient-to:#4CD1D0
}

.to-cyan4{
  --tw-gradient-to:#B2ECEB
}

.to-cyan5{
  --tw-gradient-to:#EDFAFA
}

.to-bg1{
  --tw-gradient-to:#FAFAFC
}

.to-bg2{
  --tw-gradient-to:#ffffff
}

.to-bg3{
  --tw-gradient-to:#F7F7FA
}

.to-gray1{
  --tw-gradient-to:#333333
}

.to-gray2{
  --tw-gradient-to:#4D4D4D
}

.to-gray3{
  --tw-gradient-to:#8C8C8C
}

.to-gray4{
  --tw-gradient-to:#BFBFBF
}

.to-gray5{
  --tw-gradient-to:#D9D9D9
}

.to-gray6{
  --tw-gradient-to:#E9E9EB
}

.to-gray7{
  --tw-gradient-to:#515164
}

.to-white{
  --tw-gradient-to:#ffffff
}

.to-warning{
  --tw-gradient-to:#FFC319
}

.to-error{
  --tw-gradient-to:#EA696F
}

.to-info{
  --tw-gradient-to:#0071B9
}

.to-success{
  --tw-gradient-to:#98D45F
}

.to-special4{
  --tw-gradient-to:#59779A
}

.to-dark{
  --tw-gradient-to:#000000
}

.to-transparent{
  --tw-gradient-to:transparent
}

.to-secondary1{
  --tw-gradient-to:#313140
}

.to-secondary2{
  --tw-gradient-to:#515164
}

.to-secondary3{
  --tw-gradient-to:#9C9CAC
}

.to-secondary4{
  --tw-gradient-to:#E5E8ED
}

.to-secondary5{
  --tw-gradient-to:#F5F6F9
}

.to-green1{
  --tw-gradient-to:#0DE298
}

.hover\:to-red1:hover{
  --tw-gradient-to:#CC352C
}

.hover\:to-red2:hover{
  --tw-gradient-to:#FF554B
}

.hover\:to-red3:hover{
  --tw-gradient-to:#FF7B73
}

.hover\:to-red4:hover{
  --tw-gradient-to:#FFF1F1
}

.hover\:to-red5:hover{
  --tw-gradient-to:#FFF6F6
}

.hover\:to-cyan1:hover{
  --tw-gradient-to:#009897
}

.hover\:to-cyan2:hover{
  --tw-gradient-to:#00BFBD
}

.hover\:to-cyan3:hover{
  --tw-gradient-to:#4CD1D0
}

.hover\:to-cyan4:hover{
  --tw-gradient-to:#B2ECEB
}

.hover\:to-cyan5:hover{
  --tw-gradient-to:#EDFAFA
}

.hover\:to-bg1:hover{
  --tw-gradient-to:#FAFAFC
}

.hover\:to-bg2:hover{
  --tw-gradient-to:#ffffff
}

.hover\:to-bg3:hover{
  --tw-gradient-to:#F7F7FA
}

.hover\:to-gray1:hover{
  --tw-gradient-to:#333333
}

.hover\:to-gray2:hover{
  --tw-gradient-to:#4D4D4D
}

.hover\:to-gray3:hover{
  --tw-gradient-to:#8C8C8C
}

.hover\:to-gray4:hover{
  --tw-gradient-to:#BFBFBF
}

.hover\:to-gray5:hover{
  --tw-gradient-to:#D9D9D9
}

.hover\:to-gray6:hover{
  --tw-gradient-to:#E9E9EB
}

.hover\:to-gray7:hover{
  --tw-gradient-to:#515164
}

.hover\:to-white:hover{
  --tw-gradient-to:#ffffff
}

.hover\:to-warning:hover{
  --tw-gradient-to:#FFC319
}

.hover\:to-error:hover{
  --tw-gradient-to:#EA696F
}

.hover\:to-info:hover{
  --tw-gradient-to:#0071B9
}

.hover\:to-success:hover{
  --tw-gradient-to:#98D45F
}

.hover\:to-special4:hover{
  --tw-gradient-to:#59779A
}

.hover\:to-dark:hover{
  --tw-gradient-to:#000000
}

.hover\:to-transparent:hover{
  --tw-gradient-to:transparent
}

.hover\:to-secondary1:hover{
  --tw-gradient-to:#313140
}

.hover\:to-secondary2:hover{
  --tw-gradient-to:#515164
}

.hover\:to-secondary3:hover{
  --tw-gradient-to:#9C9CAC
}

.hover\:to-secondary4:hover{
  --tw-gradient-to:#E5E8ED
}

.hover\:to-secondary5:hover{
  --tw-gradient-to:#F5F6F9
}

.hover\:to-green1:hover{
  --tw-gradient-to:#0DE298
}

.focus\:to-red1:focus{
  --tw-gradient-to:#CC352C
}

.focus\:to-red2:focus{
  --tw-gradient-to:#FF554B
}

.focus\:to-red3:focus{
  --tw-gradient-to:#FF7B73
}

.focus\:to-red4:focus{
  --tw-gradient-to:#FFF1F1
}

.focus\:to-red5:focus{
  --tw-gradient-to:#FFF6F6
}

.focus\:to-cyan1:focus{
  --tw-gradient-to:#009897
}

.focus\:to-cyan2:focus{
  --tw-gradient-to:#00BFBD
}

.focus\:to-cyan3:focus{
  --tw-gradient-to:#4CD1D0
}

.focus\:to-cyan4:focus{
  --tw-gradient-to:#B2ECEB
}

.focus\:to-cyan5:focus{
  --tw-gradient-to:#EDFAFA
}

.focus\:to-bg1:focus{
  --tw-gradient-to:#FAFAFC
}

.focus\:to-bg2:focus{
  --tw-gradient-to:#ffffff
}

.focus\:to-bg3:focus{
  --tw-gradient-to:#F7F7FA
}

.focus\:to-gray1:focus{
  --tw-gradient-to:#333333
}

.focus\:to-gray2:focus{
  --tw-gradient-to:#4D4D4D
}

.focus\:to-gray3:focus{
  --tw-gradient-to:#8C8C8C
}

.focus\:to-gray4:focus{
  --tw-gradient-to:#BFBFBF
}

.focus\:to-gray5:focus{
  --tw-gradient-to:#D9D9D9
}

.focus\:to-gray6:focus{
  --tw-gradient-to:#E9E9EB
}

.focus\:to-gray7:focus{
  --tw-gradient-to:#515164
}

.focus\:to-white:focus{
  --tw-gradient-to:#ffffff
}

.focus\:to-warning:focus{
  --tw-gradient-to:#FFC319
}

.focus\:to-error:focus{
  --tw-gradient-to:#EA696F
}

.focus\:to-info:focus{
  --tw-gradient-to:#0071B9
}

.focus\:to-success:focus{
  --tw-gradient-to:#98D45F
}

.focus\:to-special4:focus{
  --tw-gradient-to:#59779A
}

.focus\:to-dark:focus{
  --tw-gradient-to:#000000
}

.focus\:to-transparent:focus{
  --tw-gradient-to:transparent
}

.focus\:to-secondary1:focus{
  --tw-gradient-to:#313140
}

.focus\:to-secondary2:focus{
  --tw-gradient-to:#515164
}

.focus\:to-secondary3:focus{
  --tw-gradient-to:#9C9CAC
}

.focus\:to-secondary4:focus{
  --tw-gradient-to:#E5E8ED
}

.focus\:to-secondary5:focus{
  --tw-gradient-to:#F5F6F9
}

.focus\:to-green1:focus{
  --tw-gradient-to:#0DE298
}

.decoration-slice{
  -webkit-box-decoration-break:slice;
          box-decoration-break:slice
}

.decoration-clone{
  -webkit-box-decoration-break:clone;
          box-decoration-break:clone
}

.bg-auto{
  background-size:auto
}

.bg-cover{
  background-size:cover
}

.bg-contain{
  background-size:contain
}

.bg-clip-border{
  background-clip:border-box
}

.bg-clip-padding{
  background-clip:padding-box
}

.bg-clip-content{
  background-clip:content-box
}

.bg-clip-text{
  -webkit-background-clip:text;
          background-clip:text
}

.bg-bottom{
  background-position:bottom
}

.bg-center{
  background-position:center
}

.bg-left{
  background-position:left
}

.bg-left-bottom{
  background-position:left bottom
}

.bg-left-top{
  background-position:left top
}

.bg-right{
  background-position:right
}

.bg-right-bottom{
  background-position:right bottom
}

.bg-right-top{
  background-position:right top
}

.bg-top{
  background-position:top
}

.bg-repeat{
  background-repeat:repeat
}

.bg-no-repeat{
  background-repeat:no-repeat
}

.bg-repeat-x{
  background-repeat:repeat-x
}

.bg-repeat-y{
  background-repeat:repeat-y
}

.bg-repeat-round{
  background-repeat:round
}

.bg-repeat-space{
  background-repeat:space
}

.bg-origin-border{
  background-origin:border-box
}

.bg-origin-padding{
  background-origin:padding-box
}

.bg-origin-content{
  background-origin:content-box
}

.fill-current{
  fill:currentColor
}

.object-contain{
  object-fit:contain
}

.object-cover{
  object-fit:cover
}

.object-fill{
  object-fit:fill
}

.object-none{
  object-fit:none
}

.object-scale-down{
  object-fit:scale-down
}

.p-0{
  padding:0
}

.p-1{
  padding:4px
}

.p-2{
  padding:8px
}

.p-3{
  padding:12px
}

.p-4{
  padding:16px
}

.p-5{
  padding:20px
}

.p-6{
  padding:24px
}

.p-8{
  padding:32px
}

.p-10{
  padding:40px
}

.p-12{
  padding:48px
}

.p-16{
  padding:64px
}

.p-18{
  padding:72px
}

.p-24{
  padding:96px
}

.p-5dot5{
  padding:22px
}

.p-full{
  padding:100%
}

.first\:p-0:first-child{
  padding:0
}

.first\:p-1:first-child{
  padding:4px
}

.first\:p-2:first-child{
  padding:8px
}

.first\:p-3:first-child{
  padding:12px
}

.first\:p-4:first-child{
  padding:16px
}

.first\:p-5:first-child{
  padding:20px
}

.first\:p-6:first-child{
  padding:24px
}

.first\:p-8:first-child{
  padding:32px
}

.first\:p-10:first-child{
  padding:40px
}

.first\:p-12:first-child{
  padding:48px
}

.first\:p-16:first-child{
  padding:64px
}

.first\:p-18:first-child{
  padding:72px
}

.first\:p-24:first-child{
  padding:96px
}

.first\:p-5dot5:first-child{
  padding:22px
}

.first\:p-full:first-child{
  padding:100%
}

.last\:p-0:last-child{
  padding:0
}

.last\:p-1:last-child{
  padding:4px
}

.last\:p-2:last-child{
  padding:8px
}

.last\:p-3:last-child{
  padding:12px
}

.last\:p-4:last-child{
  padding:16px
}

.last\:p-5:last-child{
  padding:20px
}

.last\:p-6:last-child{
  padding:24px
}

.last\:p-8:last-child{
  padding:32px
}

.last\:p-10:last-child{
  padding:40px
}

.last\:p-12:last-child{
  padding:48px
}

.last\:p-16:last-child{
  padding:64px
}

.last\:p-18:last-child{
  padding:72px
}

.last\:p-24:last-child{
  padding:96px
}

.last\:p-5dot5:last-child{
  padding:22px
}

.last\:p-full:last-child{
  padding:100%
}

.px-0{
  padding-left:0;
  padding-right:0
}

.px-1{
  padding-left:4px;
  padding-right:4px
}

.px-2{
  padding-left:8px;
  padding-right:8px
}

.px-3{
  padding-left:12px;
  padding-right:12px
}

.px-4{
  padding-left:16px;
  padding-right:16px
}

.px-5{
  padding-left:20px;
  padding-right:20px
}

.px-6{
  padding-left:24px;
  padding-right:24px
}

.px-8{
  padding-left:32px;
  padding-right:32px
}

.px-10{
  padding-left:40px;
  padding-right:40px
}

.px-12{
  padding-left:48px;
  padding-right:48px
}

.px-16{
  padding-left:64px;
  padding-right:64px
}

.px-18{
  padding-left:72px;
  padding-right:72px
}

.px-24{
  padding-left:96px;
  padding-right:96px
}

.px-5dot5{
  padding-left:22px;
  padding-right:22px
}

.px-full{
  padding-left:100%;
  padding-right:100%
}

.py-0{
  padding-top:0;
  padding-bottom:0
}

.py-1{
  padding-top:4px;
  padding-bottom:4px
}

.py-2{
  padding-top:8px;
  padding-bottom:8px
}

.py-3{
  padding-top:12px;
  padding-bottom:12px
}

.py-4{
  padding-top:16px;
  padding-bottom:16px
}

.py-5{
  padding-top:20px;
  padding-bottom:20px
}

.py-6{
  padding-top:24px;
  padding-bottom:24px
}

.py-8{
  padding-top:32px;
  padding-bottom:32px
}

.py-10{
  padding-top:40px;
  padding-bottom:40px
}

.py-12{
  padding-top:48px;
  padding-bottom:48px
}

.py-16{
  padding-top:64px;
  padding-bottom:64px
}

.py-18{
  padding-top:72px;
  padding-bottom:72px
}

.py-24{
  padding-top:96px;
  padding-bottom:96px
}

.py-5dot5{
  padding-top:22px;
  padding-bottom:22px
}

.py-full{
  padding-top:100%;
  padding-bottom:100%
}

.first\:px-0:first-child{
  padding-left:0;
  padding-right:0
}

.first\:px-1:first-child{
  padding-left:4px;
  padding-right:4px
}

.first\:px-2:first-child{
  padding-left:8px;
  padding-right:8px
}

.first\:px-3:first-child{
  padding-left:12px;
  padding-right:12px
}

.first\:px-4:first-child{
  padding-left:16px;
  padding-right:16px
}

.first\:px-5:first-child{
  padding-left:20px;
  padding-right:20px
}

.first\:px-6:first-child{
  padding-left:24px;
  padding-right:24px
}

.first\:px-8:first-child{
  padding-left:32px;
  padding-right:32px
}

.first\:px-10:first-child{
  padding-left:40px;
  padding-right:40px
}

.first\:px-12:first-child{
  padding-left:48px;
  padding-right:48px
}

.first\:px-16:first-child{
  padding-left:64px;
  padding-right:64px
}

.first\:px-18:first-child{
  padding-left:72px;
  padding-right:72px
}

.first\:px-24:first-child{
  padding-left:96px;
  padding-right:96px
}

.first\:px-5dot5:first-child{
  padding-left:22px;
  padding-right:22px
}

.first\:px-full:first-child{
  padding-left:100%;
  padding-right:100%
}

.first\:py-0:first-child{
  padding-top:0;
  padding-bottom:0
}

.first\:py-1:first-child{
  padding-top:4px;
  padding-bottom:4px
}

.first\:py-2:first-child{
  padding-top:8px;
  padding-bottom:8px
}

.first\:py-3:first-child{
  padding-top:12px;
  padding-bottom:12px
}

.first\:py-4:first-child{
  padding-top:16px;
  padding-bottom:16px
}

.first\:py-5:first-child{
  padding-top:20px;
  padding-bottom:20px
}

.first\:py-6:first-child{
  padding-top:24px;
  padding-bottom:24px
}

.first\:py-8:first-child{
  padding-top:32px;
  padding-bottom:32px
}

.first\:py-10:first-child{
  padding-top:40px;
  padding-bottom:40px
}

.first\:py-12:first-child{
  padding-top:48px;
  padding-bottom:48px
}

.first\:py-16:first-child{
  padding-top:64px;
  padding-bottom:64px
}

.first\:py-18:first-child{
  padding-top:72px;
  padding-bottom:72px
}

.first\:py-24:first-child{
  padding-top:96px;
  padding-bottom:96px
}

.first\:py-5dot5:first-child{
  padding-top:22px;
  padding-bottom:22px
}

.first\:py-full:first-child{
  padding-top:100%;
  padding-bottom:100%
}

.last\:px-0:last-child{
  padding-left:0;
  padding-right:0
}

.last\:px-1:last-child{
  padding-left:4px;
  padding-right:4px
}

.last\:px-2:last-child{
  padding-left:8px;
  padding-right:8px
}

.last\:px-3:last-child{
  padding-left:12px;
  padding-right:12px
}

.last\:px-4:last-child{
  padding-left:16px;
  padding-right:16px
}

.last\:px-5:last-child{
  padding-left:20px;
  padding-right:20px
}

.last\:px-6:last-child{
  padding-left:24px;
  padding-right:24px
}

.last\:px-8:last-child{
  padding-left:32px;
  padding-right:32px
}

.last\:px-10:last-child{
  padding-left:40px;
  padding-right:40px
}

.last\:px-12:last-child{
  padding-left:48px;
  padding-right:48px
}

.last\:px-16:last-child{
  padding-left:64px;
  padding-right:64px
}

.last\:px-18:last-child{
  padding-left:72px;
  padding-right:72px
}

.last\:px-24:last-child{
  padding-left:96px;
  padding-right:96px
}

.last\:px-5dot5:last-child{
  padding-left:22px;
  padding-right:22px
}

.last\:px-full:last-child{
  padding-left:100%;
  padding-right:100%
}

.last\:py-0:last-child{
  padding-top:0;
  padding-bottom:0
}

.last\:py-1:last-child{
  padding-top:4px;
  padding-bottom:4px
}

.last\:py-2:last-child{
  padding-top:8px;
  padding-bottom:8px
}

.last\:py-3:last-child{
  padding-top:12px;
  padding-bottom:12px
}

.last\:py-4:last-child{
  padding-top:16px;
  padding-bottom:16px
}

.last\:py-5:last-child{
  padding-top:20px;
  padding-bottom:20px
}

.last\:py-6:last-child{
  padding-top:24px;
  padding-bottom:24px
}

.last\:py-8:last-child{
  padding-top:32px;
  padding-bottom:32px
}

.last\:py-10:last-child{
  padding-top:40px;
  padding-bottom:40px
}

.last\:py-12:last-child{
  padding-top:48px;
  padding-bottom:48px
}

.last\:py-16:last-child{
  padding-top:64px;
  padding-bottom:64px
}

.last\:py-18:last-child{
  padding-top:72px;
  padding-bottom:72px
}

.last\:py-24:last-child{
  padding-top:96px;
  padding-bottom:96px
}

.last\:py-5dot5:last-child{
  padding-top:22px;
  padding-bottom:22px
}

.last\:py-full:last-child{
  padding-top:100%;
  padding-bottom:100%
}

.pt-0{
  padding-top:0
}

.pt-1{
  padding-top:4px
}

.pt-2{
  padding-top:8px
}

.pt-3{
  padding-top:12px
}

.pt-4{
  padding-top:16px
}

.pt-5{
  padding-top:20px
}

.pt-6{
  padding-top:24px
}

.pt-8{
  padding-top:32px
}

.pt-10{
  padding-top:40px
}

.pt-12{
  padding-top:48px
}

.pt-16{
  padding-top:64px
}

.pt-18{
  padding-top:72px
}

.pt-24{
  padding-top:96px
}

.pt-5dot5{
  padding-top:22px
}

.pt-full{
  padding-top:100%
}

.pr-0{
  padding-right:0
}

.pr-1{
  padding-right:4px
}

.pr-2{
  padding-right:8px
}

.pr-3{
  padding-right:12px
}

.pr-4{
  padding-right:16px
}

.pr-5{
  padding-right:20px
}

.pr-6{
  padding-right:24px
}

.pr-8{
  padding-right:32px
}

.pr-10{
  padding-right:40px
}

.pr-12{
  padding-right:48px
}

.pr-16{
  padding-right:64px
}

.pr-18{
  padding-right:72px
}

.pr-24{
  padding-right:96px
}

.pr-5dot5{
  padding-right:22px
}

.pr-full{
  padding-right:100%
}

.pb-0{
  padding-bottom:0
}

.pb-1{
  padding-bottom:4px
}

.pb-2{
  padding-bottom:8px
}

.pb-3{
  padding-bottom:12px
}

.pb-4{
  padding-bottom:16px
}

.pb-5{
  padding-bottom:20px
}

.pb-6{
  padding-bottom:24px
}

.pb-8{
  padding-bottom:32px
}

.pb-10{
  padding-bottom:40px
}

.pb-12{
  padding-bottom:48px
}

.pb-16{
  padding-bottom:64px
}

.pb-18{
  padding-bottom:72px
}

.pb-24{
  padding-bottom:96px
}

.pb-5dot5{
  padding-bottom:22px
}

.pb-full{
  padding-bottom:100%
}

.pl-0{
  padding-left:0
}

.pl-1{
  padding-left:4px
}

.pl-2{
  padding-left:8px
}

.pl-3{
  padding-left:12px
}

.pl-4{
  padding-left:16px
}

.pl-5{
  padding-left:20px
}

.pl-6{
  padding-left:24px
}

.pl-8{
  padding-left:32px
}

.pl-10{
  padding-left:40px
}

.pl-12{
  padding-left:48px
}

.pl-16{
  padding-left:64px
}

.pl-18{
  padding-left:72px
}

.pl-24{
  padding-left:96px
}

.pl-5dot5{
  padding-left:22px
}

.pl-full{
  padding-left:100%
}

.first\:pt-0:first-child{
  padding-top:0
}

.first\:pt-1:first-child{
  padding-top:4px
}

.first\:pt-2:first-child{
  padding-top:8px
}

.first\:pt-3:first-child{
  padding-top:12px
}

.first\:pt-4:first-child{
  padding-top:16px
}

.first\:pt-5:first-child{
  padding-top:20px
}

.first\:pt-6:first-child{
  padding-top:24px
}

.first\:pt-8:first-child{
  padding-top:32px
}

.first\:pt-10:first-child{
  padding-top:40px
}

.first\:pt-12:first-child{
  padding-top:48px
}

.first\:pt-16:first-child{
  padding-top:64px
}

.first\:pt-18:first-child{
  padding-top:72px
}

.first\:pt-24:first-child{
  padding-top:96px
}

.first\:pt-5dot5:first-child{
  padding-top:22px
}

.first\:pt-full:first-child{
  padding-top:100%
}

.first\:pr-0:first-child{
  padding-right:0
}

.first\:pr-1:first-child{
  padding-right:4px
}

.first\:pr-2:first-child{
  padding-right:8px
}

.first\:pr-3:first-child{
  padding-right:12px
}

.first\:pr-4:first-child{
  padding-right:16px
}

.first\:pr-5:first-child{
  padding-right:20px
}

.first\:pr-6:first-child{
  padding-right:24px
}

.first\:pr-8:first-child{
  padding-right:32px
}

.first\:pr-10:first-child{
  padding-right:40px
}

.first\:pr-12:first-child{
  padding-right:48px
}

.first\:pr-16:first-child{
  padding-right:64px
}

.first\:pr-18:first-child{
  padding-right:72px
}

.first\:pr-24:first-child{
  padding-right:96px
}

.first\:pr-5dot5:first-child{
  padding-right:22px
}

.first\:pr-full:first-child{
  padding-right:100%
}

.first\:pb-0:first-child{
  padding-bottom:0
}

.first\:pb-1:first-child{
  padding-bottom:4px
}

.first\:pb-2:first-child{
  padding-bottom:8px
}

.first\:pb-3:first-child{
  padding-bottom:12px
}

.first\:pb-4:first-child{
  padding-bottom:16px
}

.first\:pb-5:first-child{
  padding-bottom:20px
}

.first\:pb-6:first-child{
  padding-bottom:24px
}

.first\:pb-8:first-child{
  padding-bottom:32px
}

.first\:pb-10:first-child{
  padding-bottom:40px
}

.first\:pb-12:first-child{
  padding-bottom:48px
}

.first\:pb-16:first-child{
  padding-bottom:64px
}

.first\:pb-18:first-child{
  padding-bottom:72px
}

.first\:pb-24:first-child{
  padding-bottom:96px
}

.first\:pb-5dot5:first-child{
  padding-bottom:22px
}

.first\:pb-full:first-child{
  padding-bottom:100%
}

.first\:pl-0:first-child{
  padding-left:0
}

.first\:pl-1:first-child{
  padding-left:4px
}

.first\:pl-2:first-child{
  padding-left:8px
}

.first\:pl-3:first-child{
  padding-left:12px
}

.first\:pl-4:first-child{
  padding-left:16px
}

.first\:pl-5:first-child{
  padding-left:20px
}

.first\:pl-6:first-child{
  padding-left:24px
}

.first\:pl-8:first-child{
  padding-left:32px
}

.first\:pl-10:first-child{
  padding-left:40px
}

.first\:pl-12:first-child{
  padding-left:48px
}

.first\:pl-16:first-child{
  padding-left:64px
}

.first\:pl-18:first-child{
  padding-left:72px
}

.first\:pl-24:first-child{
  padding-left:96px
}

.first\:pl-5dot5:first-child{
  padding-left:22px
}

.first\:pl-full:first-child{
  padding-left:100%
}

.last\:pt-0:last-child{
  padding-top:0
}

.last\:pt-1:last-child{
  padding-top:4px
}

.last\:pt-2:last-child{
  padding-top:8px
}

.last\:pt-3:last-child{
  padding-top:12px
}

.last\:pt-4:last-child{
  padding-top:16px
}

.last\:pt-5:last-child{
  padding-top:20px
}

.last\:pt-6:last-child{
  padding-top:24px
}

.last\:pt-8:last-child{
  padding-top:32px
}

.last\:pt-10:last-child{
  padding-top:40px
}

.last\:pt-12:last-child{
  padding-top:48px
}

.last\:pt-16:last-child{
  padding-top:64px
}

.last\:pt-18:last-child{
  padding-top:72px
}

.last\:pt-24:last-child{
  padding-top:96px
}

.last\:pt-5dot5:last-child{
  padding-top:22px
}

.last\:pt-full:last-child{
  padding-top:100%
}

.last\:pr-0:last-child{
  padding-right:0
}

.last\:pr-1:last-child{
  padding-right:4px
}

.last\:pr-2:last-child{
  padding-right:8px
}

.last\:pr-3:last-child{
  padding-right:12px
}

.last\:pr-4:last-child{
  padding-right:16px
}

.last\:pr-5:last-child{
  padding-right:20px
}

.last\:pr-6:last-child{
  padding-right:24px
}

.last\:pr-8:last-child{
  padding-right:32px
}

.last\:pr-10:last-child{
  padding-right:40px
}

.last\:pr-12:last-child{
  padding-right:48px
}

.last\:pr-16:last-child{
  padding-right:64px
}

.last\:pr-18:last-child{
  padding-right:72px
}

.last\:pr-24:last-child{
  padding-right:96px
}

.last\:pr-5dot5:last-child{
  padding-right:22px
}

.last\:pr-full:last-child{
  padding-right:100%
}

.last\:pb-0:last-child{
  padding-bottom:0
}

.last\:pb-1:last-child{
  padding-bottom:4px
}

.last\:pb-2:last-child{
  padding-bottom:8px
}

.last\:pb-3:last-child{
  padding-bottom:12px
}

.last\:pb-4:last-child{
  padding-bottom:16px
}

.last\:pb-5:last-child{
  padding-bottom:20px
}

.last\:pb-6:last-child{
  padding-bottom:24px
}

.last\:pb-8:last-child{
  padding-bottom:32px
}

.last\:pb-10:last-child{
  padding-bottom:40px
}

.last\:pb-12:last-child{
  padding-bottom:48px
}

.last\:pb-16:last-child{
  padding-bottom:64px
}

.last\:pb-18:last-child{
  padding-bottom:72px
}

.last\:pb-24:last-child{
  padding-bottom:96px
}

.last\:pb-5dot5:last-child{
  padding-bottom:22px
}

.last\:pb-full:last-child{
  padding-bottom:100%
}

.last\:pl-0:last-child{
  padding-left:0
}

.last\:pl-1:last-child{
  padding-left:4px
}

.last\:pl-2:last-child{
  padding-left:8px
}

.last\:pl-3:last-child{
  padding-left:12px
}

.last\:pl-4:last-child{
  padding-left:16px
}

.last\:pl-5:last-child{
  padding-left:20px
}

.last\:pl-6:last-child{
  padding-left:24px
}

.last\:pl-8:last-child{
  padding-left:32px
}

.last\:pl-10:last-child{
  padding-left:40px
}

.last\:pl-12:last-child{
  padding-left:48px
}

.last\:pl-16:last-child{
  padding-left:64px
}

.last\:pl-18:last-child{
  padding-left:72px
}

.last\:pl-24:last-child{
  padding-left:96px
}

.last\:pl-5dot5:last-child{
  padding-left:22px
}

.last\:pl-full:last-child{
  padding-left:100%
}

.text-left{
  text-align:left
}

.text-center{
  text-align:center
}

.text-right{
  text-align:right
}

.text-justify{
  text-align:justify
}

.align-baseline{
  vertical-align:baseline
}

.align-top{
  vertical-align:top
}

.align-middle{
  vertical-align:middle
}

.align-bottom{
  vertical-align:bottom
}

.align-text-top{
  vertical-align:text-top
}

.align-text-bottom{
  vertical-align:text-bottom
}

.text-xs{
  font-size:10px
}

.text-s{
  font-size:12px
}

.text-sm{
  font-size:14px
}

.text-tiny{
  font-size:12px
}

.text-base{
  font-size:16px
}

.text-lg{
  font-size:20px
}

.text-xl{
  font-size:24px
}

.text-2xl{
  font-size:32px
}

.text-3xl{
  font-size:40px
}

.text-4xl{
  font-size:48px
}

.text-5xl{
  font-size:64px
}

.font-thin{
  font-weight:100
}

.font-extralight{
  font-weight:200
}

.font-light{
  font-weight:300
}

.font-normal{
  font-weight:400
}

.font-medium{
  font-weight:500
}

.font-semibold{
  font-weight:600
}

.font-bold{
  font-weight:700
}

.font-extrabold{
  font-weight:800
}

.font-black{
  font-weight:900
}

.uppercase{
  text-transform:uppercase
}

.lowercase{
  text-transform:lowercase
}

.capitalize{
  text-transform:capitalize
}

.normal-case{
  text-transform:none
}

.italic{
  font-style:italic
}

.not-italic{
  font-style:normal
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions{
  --tw-ordinal:var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero:var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure:var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing:var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-fraction:var(--tw-empty,/*!*/ /*!*/);
  font-variant-numeric:var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.normal-nums{
  font-variant-numeric:normal
}

.ordinal{
  --tw-ordinal:ordinal
}

.slashed-zero{
  --tw-slashed-zero:slashed-zero
}

.lining-nums{
  --tw-numeric-figure:lining-nums
}

.oldstyle-nums{
  --tw-numeric-figure:oldstyle-nums
}

.proportional-nums{
  --tw-numeric-spacing:proportional-nums
}

.tabular-nums{
  --tw-numeric-spacing:tabular-nums
}

.diagonal-fractions{
  --tw-numeric-fraction:diagonal-fractions
}

.stacked-fractions{
  --tw-numeric-fraction:stacked-fractions
}

.leading-3{
  line-height:.75rem
}

.leading-4{
  line-height:1rem
}

.leading-5{
  line-height:1.25rem
}

.leading-6{
  line-height:1.5rem
}

.leading-7{
  line-height:1.75rem
}

.leading-8{
  line-height:2rem
}

.leading-9{
  line-height:2.25rem
}

.leading-10{
  line-height:2.5rem
}

.leading-none{
  line-height:1
}

.leading-tight{
  line-height:1.25
}

.leading-snug{
  line-height:1.375
}

.leading-normal{
  line-height:1.5
}

.leading-relaxed{
  line-height:1.625
}

.leading-loose{
  line-height:2
}

.text-red1{
  color:#CC352C
}

.text-red2{
  color:#FF554B
}

.text-red3{
  color:#FF7B73
}

.text-red4{
  color:#FFF1F1
}

.text-red5{
  color:#FFF6F6
}

.text-cyan1{
  color:#009897
}

.text-cyan2{
  color:#00BFBD
}

.text-cyan3{
  color:#4CD1D0
}

.text-cyan4{
  color:#B2ECEB
}

.text-cyan5{
  color:#EDFAFA
}

.text-bg1{
  color:#FAFAFC
}

.text-bg2{
  color:#ffffff
}

.text-bg3{
  color:#F7F7FA
}

.text-gray1{
  color:#333333
}

.text-gray2{
  color:#4D4D4D
}

.text-gray3{
  color:#8C8C8C
}

.text-gray4{
  color:#BFBFBF
}

.text-gray5{
  color:#D9D9D9
}

.text-gray6{
  color:#E9E9EB
}

.text-gray7{
  color:#515164
}

.text-white{
  color:#ffffff
}

.text-warning{
  color:#FFC319
}

.text-error{
  color:#EA696F
}

.text-info{
  color:#0071B9
}

.text-success{
  color:#98D45F
}

.text-special4{
  color:#59779A
}

.text-dark{
  color:#000000
}

.text-transparent{
  color:transparent
}

.text-secondary1{
  color:#313140
}

.text-secondary2{
  color:#515164
}

.text-secondary3{
  color:#9C9CAC
}

.text-secondary4{
  color:#E5E8ED
}

.text-secondary5{
  color:#F5F6F9
}

.text-green1{
  color:#0DE298
}

.group:hover .group-hover\:text-red1{
  color:#CC352C
}

.group:hover .group-hover\:text-red2{
  color:#FF554B
}

.group:hover .group-hover\:text-red3{
  color:#FF7B73
}

.group:hover .group-hover\:text-red4{
  color:#FFF1F1
}

.group:hover .group-hover\:text-red5{
  color:#FFF6F6
}

.group:hover .group-hover\:text-cyan1{
  color:#009897
}

.group:hover .group-hover\:text-cyan2{
  color:#00BFBD
}

.group:hover .group-hover\:text-cyan3{
  color:#4CD1D0
}

.group:hover .group-hover\:text-cyan4{
  color:#B2ECEB
}

.group:hover .group-hover\:text-cyan5{
  color:#EDFAFA
}

.group:hover .group-hover\:text-bg1{
  color:#FAFAFC
}

.group:hover .group-hover\:text-bg2{
  color:#ffffff
}

.group:hover .group-hover\:text-bg3{
  color:#F7F7FA
}

.group:hover .group-hover\:text-gray1{
  color:#333333
}

.group:hover .group-hover\:text-gray2{
  color:#4D4D4D
}

.group:hover .group-hover\:text-gray3{
  color:#8C8C8C
}

.group:hover .group-hover\:text-gray4{
  color:#BFBFBF
}

.group:hover .group-hover\:text-gray5{
  color:#D9D9D9
}

.group:hover .group-hover\:text-gray6{
  color:#E9E9EB
}

.group:hover .group-hover\:text-gray7{
  color:#515164
}

.group:hover .group-hover\:text-white{
  color:#ffffff
}

.group:hover .group-hover\:text-warning{
  color:#FFC319
}

.group:hover .group-hover\:text-error{
  color:#EA696F
}

.group:hover .group-hover\:text-info{
  color:#0071B9
}

.group:hover .group-hover\:text-success{
  color:#98D45F
}

.group:hover .group-hover\:text-special4{
  color:#59779A
}

.group:hover .group-hover\:text-dark{
  color:#000000
}

.group:hover .group-hover\:text-transparent{
  color:transparent
}

.group:hover .group-hover\:text-secondary1{
  color:#313140
}

.group:hover .group-hover\:text-secondary2{
  color:#515164
}

.group:hover .group-hover\:text-secondary3{
  color:#9C9CAC
}

.group:hover .group-hover\:text-secondary4{
  color:#E5E8ED
}

.group:hover .group-hover\:text-secondary5{
  color:#F5F6F9
}

.group:hover .group-hover\:text-green1{
  color:#0DE298
}

.focus-within\:text-red1:focus-within{
  color:#CC352C
}

.focus-within\:text-red2:focus-within{
  color:#FF554B
}

.focus-within\:text-red3:focus-within{
  color:#FF7B73
}

.focus-within\:text-red4:focus-within{
  color:#FFF1F1
}

.focus-within\:text-red5:focus-within{
  color:#FFF6F6
}

.focus-within\:text-cyan1:focus-within{
  color:#009897
}

.focus-within\:text-cyan2:focus-within{
  color:#00BFBD
}

.focus-within\:text-cyan3:focus-within{
  color:#4CD1D0
}

.focus-within\:text-cyan4:focus-within{
  color:#B2ECEB
}

.focus-within\:text-cyan5:focus-within{
  color:#EDFAFA
}

.focus-within\:text-bg1:focus-within{
  color:#FAFAFC
}

.focus-within\:text-bg2:focus-within{
  color:#ffffff
}

.focus-within\:text-bg3:focus-within{
  color:#F7F7FA
}

.focus-within\:text-gray1:focus-within{
  color:#333333
}

.focus-within\:text-gray2:focus-within{
  color:#4D4D4D
}

.focus-within\:text-gray3:focus-within{
  color:#8C8C8C
}

.focus-within\:text-gray4:focus-within{
  color:#BFBFBF
}

.focus-within\:text-gray5:focus-within{
  color:#D9D9D9
}

.focus-within\:text-gray6:focus-within{
  color:#E9E9EB
}

.focus-within\:text-gray7:focus-within{
  color:#515164
}

.focus-within\:text-white:focus-within{
  color:#ffffff
}

.focus-within\:text-warning:focus-within{
  color:#FFC319
}

.focus-within\:text-error:focus-within{
  color:#EA696F
}

.focus-within\:text-info:focus-within{
  color:#0071B9
}

.focus-within\:text-success:focus-within{
  color:#98D45F
}

.focus-within\:text-special4:focus-within{
  color:#59779A
}

.focus-within\:text-dark:focus-within{
  color:#000000
}

.focus-within\:text-transparent:focus-within{
  color:transparent
}

.focus-within\:text-secondary1:focus-within{
  color:#313140
}

.focus-within\:text-secondary2:focus-within{
  color:#515164
}

.focus-within\:text-secondary3:focus-within{
  color:#9C9CAC
}

.focus-within\:text-secondary4:focus-within{
  color:#E5E8ED
}

.focus-within\:text-secondary5:focus-within{
  color:#F5F6F9
}

.focus-within\:text-green1:focus-within{
  color:#0DE298
}

.hover\:text-red1:hover{
  color:#CC352C
}

.hover\:text-red2:hover{
  color:#FF554B
}

.hover\:text-red3:hover{
  color:#FF7B73
}

.hover\:text-red4:hover{
  color:#FFF1F1
}

.hover\:text-red5:hover{
  color:#FFF6F6
}

.hover\:text-cyan1:hover{
  color:#009897
}

.hover\:text-cyan2:hover{
  color:#00BFBD
}

.hover\:text-cyan3:hover{
  color:#4CD1D0
}

.hover\:text-cyan4:hover{
  color:#B2ECEB
}

.hover\:text-cyan5:hover{
  color:#EDFAFA
}

.hover\:text-bg1:hover{
  color:#FAFAFC
}

.hover\:text-bg2:hover{
  color:#ffffff
}

.hover\:text-bg3:hover{
  color:#F7F7FA
}

.hover\:text-gray1:hover{
  color:#333333
}

.hover\:text-gray2:hover{
  color:#4D4D4D
}

.hover\:text-gray3:hover{
  color:#8C8C8C
}

.hover\:text-gray4:hover{
  color:#BFBFBF
}

.hover\:text-gray5:hover{
  color:#D9D9D9
}

.hover\:text-gray6:hover{
  color:#E9E9EB
}

.hover\:text-gray7:hover{
  color:#515164
}

.hover\:text-white:hover{
  color:#ffffff
}

.hover\:text-warning:hover{
  color:#FFC319
}

.hover\:text-error:hover{
  color:#EA696F
}

.hover\:text-info:hover{
  color:#0071B9
}

.hover\:text-success:hover{
  color:#98D45F
}

.hover\:text-special4:hover{
  color:#59779A
}

.hover\:text-dark:hover{
  color:#000000
}

.hover\:text-transparent:hover{
  color:transparent
}

.hover\:text-secondary1:hover{
  color:#313140
}

.hover\:text-secondary2:hover{
  color:#515164
}

.hover\:text-secondary3:hover{
  color:#9C9CAC
}

.hover\:text-secondary4:hover{
  color:#E5E8ED
}

.hover\:text-secondary5:hover{
  color:#F5F6F9
}

.hover\:text-green1:hover{
  color:#0DE298
}

.focus\:text-red1:focus{
  color:#CC352C
}

.focus\:text-red2:focus{
  color:#FF554B
}

.focus\:text-red3:focus{
  color:#FF7B73
}

.focus\:text-red4:focus{
  color:#FFF1F1
}

.focus\:text-red5:focus{
  color:#FFF6F6
}

.focus\:text-cyan1:focus{
  color:#009897
}

.focus\:text-cyan2:focus{
  color:#00BFBD
}

.focus\:text-cyan3:focus{
  color:#4CD1D0
}

.focus\:text-cyan4:focus{
  color:#B2ECEB
}

.focus\:text-cyan5:focus{
  color:#EDFAFA
}

.focus\:text-bg1:focus{
  color:#FAFAFC
}

.focus\:text-bg2:focus{
  color:#ffffff
}

.focus\:text-bg3:focus{
  color:#F7F7FA
}

.focus\:text-gray1:focus{
  color:#333333
}

.focus\:text-gray2:focus{
  color:#4D4D4D
}

.focus\:text-gray3:focus{
  color:#8C8C8C
}

.focus\:text-gray4:focus{
  color:#BFBFBF
}

.focus\:text-gray5:focus{
  color:#D9D9D9
}

.focus\:text-gray6:focus{
  color:#E9E9EB
}

.focus\:text-gray7:focus{
  color:#515164
}

.focus\:text-white:focus{
  color:#ffffff
}

.focus\:text-warning:focus{
  color:#FFC319
}

.focus\:text-error:focus{
  color:#EA696F
}

.focus\:text-info:focus{
  color:#0071B9
}

.focus\:text-success:focus{
  color:#98D45F
}

.focus\:text-special4:focus{
  color:#59779A
}

.focus\:text-dark:focus{
  color:#000000
}

.focus\:text-transparent:focus{
  color:transparent
}

.focus\:text-secondary1:focus{
  color:#313140
}

.focus\:text-secondary2:focus{
  color:#515164
}

.focus\:text-secondary3:focus{
  color:#9C9CAC
}

.focus\:text-secondary4:focus{
  color:#E5E8ED
}

.focus\:text-secondary5:focus{
  color:#F5F6F9
}

.focus\:text-green1:focus{
  color:#0DE298
}

.underline{
  text-decoration:underline
}

.line-through{
  text-decoration:line-through
}

.no-underline{
  text-decoration:none
}

.group:hover .group-hover\:underline{
  text-decoration:underline
}

.group:hover .group-hover\:line-through{
  text-decoration:line-through
}

.group:hover .group-hover\:no-underline{
  text-decoration:none
}

.focus-within\:underline:focus-within{
  text-decoration:underline
}

.focus-within\:line-through:focus-within{
  text-decoration:line-through
}

.focus-within\:no-underline:focus-within{
  text-decoration:none
}

.hover\:underline:hover{
  text-decoration:underline
}

.hover\:line-through:hover{
  text-decoration:line-through
}

.hover\:no-underline:hover{
  text-decoration:none
}

.focus\:underline:focus{
  text-decoration:underline
}

.focus\:line-through:focus{
  text-decoration:line-through
}

.focus\:no-underline:focus{
  text-decoration:none
}

.placeholder-red1::placeholder{
  color:#CC352C
}

.placeholder-red2::placeholder{
  color:#FF554B
}

.placeholder-red3::placeholder{
  color:#FF7B73
}

.placeholder-red4::placeholder{
  color:#FFF1F1
}

.placeholder-red5::placeholder{
  color:#FFF6F6
}

.placeholder-cyan1::placeholder{
  color:#009897
}

.placeholder-cyan2::placeholder{
  color:#00BFBD
}

.placeholder-cyan3::placeholder{
  color:#4CD1D0
}

.placeholder-cyan4::placeholder{
  color:#B2ECEB
}

.placeholder-cyan5::placeholder{
  color:#EDFAFA
}

.placeholder-bg1::placeholder{
  color:#FAFAFC
}

.placeholder-bg2::placeholder{
  color:#ffffff
}

.placeholder-bg3::placeholder{
  color:#F7F7FA
}

.placeholder-gray1::placeholder{
  color:#333333
}

.placeholder-gray2::placeholder{
  color:#4D4D4D
}

.placeholder-gray3::placeholder{
  color:#8C8C8C
}

.placeholder-gray4::placeholder{
  color:#BFBFBF
}

.placeholder-gray5::placeholder{
  color:#D9D9D9
}

.placeholder-gray6::placeholder{
  color:#E9E9EB
}

.placeholder-gray7::placeholder{
  color:#515164
}

.placeholder-white::placeholder{
  color:#ffffff
}

.placeholder-warning::placeholder{
  color:#FFC319
}

.placeholder-error::placeholder{
  color:#EA696F
}

.placeholder-info::placeholder{
  color:#0071B9
}

.placeholder-success::placeholder{
  color:#98D45F
}

.placeholder-special4::placeholder{
  color:#59779A
}

.placeholder-dark::placeholder{
  color:#000000
}

.placeholder-transparent::placeholder{
  color:transparent
}

.placeholder-secondary1::placeholder{
  color:#313140
}

.placeholder-secondary2::placeholder{
  color:#515164
}

.placeholder-secondary3::placeholder{
  color:#9C9CAC
}

.placeholder-secondary4::placeholder{
  color:#E5E8ED
}

.placeholder-secondary5::placeholder{
  color:#F5F6F9
}

.placeholder-green1::placeholder{
  color:#0DE298
}

.focus\:placeholder-red1:focus::placeholder{
  color:#CC352C
}

.focus\:placeholder-red2:focus::placeholder{
  color:#FF554B
}

.focus\:placeholder-red3:focus::placeholder{
  color:#FF7B73
}

.focus\:placeholder-red4:focus::placeholder{
  color:#FFF1F1
}

.focus\:placeholder-red5:focus::placeholder{
  color:#FFF6F6
}

.focus\:placeholder-cyan1:focus::placeholder{
  color:#009897
}

.focus\:placeholder-cyan2:focus::placeholder{
  color:#00BFBD
}

.focus\:placeholder-cyan3:focus::placeholder{
  color:#4CD1D0
}

.focus\:placeholder-cyan4:focus::placeholder{
  color:#B2ECEB
}

.focus\:placeholder-cyan5:focus::placeholder{
  color:#EDFAFA
}

.focus\:placeholder-bg1:focus::placeholder{
  color:#FAFAFC
}

.focus\:placeholder-bg2:focus::placeholder{
  color:#ffffff
}

.focus\:placeholder-bg3:focus::placeholder{
  color:#F7F7FA
}

.focus\:placeholder-gray1:focus::placeholder{
  color:#333333
}

.focus\:placeholder-gray2:focus::placeholder{
  color:#4D4D4D
}

.focus\:placeholder-gray3:focus::placeholder{
  color:#8C8C8C
}

.focus\:placeholder-gray4:focus::placeholder{
  color:#BFBFBF
}

.focus\:placeholder-gray5:focus::placeholder{
  color:#D9D9D9
}

.focus\:placeholder-gray6:focus::placeholder{
  color:#E9E9EB
}

.focus\:placeholder-gray7:focus::placeholder{
  color:#515164
}

.focus\:placeholder-white:focus::placeholder{
  color:#ffffff
}

.focus\:placeholder-warning:focus::placeholder{
  color:#FFC319
}

.focus\:placeholder-error:focus::placeholder{
  color:#EA696F
}

.focus\:placeholder-info:focus::placeholder{
  color:#0071B9
}

.focus\:placeholder-success:focus::placeholder{
  color:#98D45F
}

.focus\:placeholder-special4:focus::placeholder{
  color:#59779A
}

.focus\:placeholder-dark:focus::placeholder{
  color:#000000
}

.focus\:placeholder-transparent:focus::placeholder{
  color:transparent
}

.focus\:placeholder-secondary1:focus::placeholder{
  color:#313140
}

.focus\:placeholder-secondary2:focus::placeholder{
  color:#515164
}

.focus\:placeholder-secondary3:focus::placeholder{
  color:#9C9CAC
}

.focus\:placeholder-secondary4:focus::placeholder{
  color:#E5E8ED
}

.focus\:placeholder-secondary5:focus::placeholder{
  color:#F5F6F9
}

.focus\:placeholder-green1:focus::placeholder{
  color:#0DE298
}

.caret-red1{
  caret-color:#CC352C
}

.caret-red2{
  caret-color:#FF554B
}

.caret-red3{
  caret-color:#FF7B73
}

.caret-red4{
  caret-color:#FFF1F1
}

.caret-red5{
  caret-color:#FFF6F6
}

.caret-cyan1{
  caret-color:#009897
}

.caret-cyan2{
  caret-color:#00BFBD
}

.caret-cyan3{
  caret-color:#4CD1D0
}

.caret-cyan4{
  caret-color:#B2ECEB
}

.caret-cyan5{
  caret-color:#EDFAFA
}

.caret-bg1{
  caret-color:#FAFAFC
}

.caret-bg2{
  caret-color:#ffffff
}

.caret-bg3{
  caret-color:#F7F7FA
}

.caret-gray1{
  caret-color:#333333
}

.caret-gray2{
  caret-color:#4D4D4D
}

.caret-gray3{
  caret-color:#8C8C8C
}

.caret-gray4{
  caret-color:#BFBFBF
}

.caret-gray5{
  caret-color:#D9D9D9
}

.caret-gray6{
  caret-color:#E9E9EB
}

.caret-gray7{
  caret-color:#515164
}

.caret-white{
  caret-color:#ffffff
}

.caret-warning{
  caret-color:#FFC319
}

.caret-error{
  caret-color:#EA696F
}

.caret-info{
  caret-color:#0071B9
}

.caret-success{
  caret-color:#98D45F
}

.caret-special4{
  caret-color:#59779A
}

.caret-dark{
  caret-color:#000000
}

.caret-transparent{
  caret-color:transparent
}

.caret-secondary1{
  caret-color:#313140
}

.caret-secondary2{
  caret-color:#515164
}

.caret-secondary3{
  caret-color:#9C9CAC
}

.caret-secondary4{
  caret-color:#E5E8ED
}

.caret-secondary5{
  caret-color:#F5F6F9
}

.caret-green1{
  caret-color:#0DE298
}

.opacity-0{
  opacity:0
}

.opacity-5{
  opacity:0.05
}

.opacity-10{
  opacity:0.1
}

.opacity-20{
  opacity:0.2
}

.opacity-25{
  opacity:0.25
}

.opacity-30{
  opacity:0.3
}

.opacity-40{
  opacity:0.4
}

.opacity-50{
  opacity:0.5
}

.opacity-60{
  opacity:0.6
}

.opacity-70{
  opacity:0.7
}

.opacity-75{
  opacity:0.75
}

.opacity-80{
  opacity:0.8
}

.opacity-90{
  opacity:0.9
}

.opacity-95{
  opacity:0.95
}

.opacity-100{
  opacity:1
}

.group:hover .group-hover\:opacity-0{
  opacity:0
}

.group:hover .group-hover\:opacity-5{
  opacity:0.05
}

.group:hover .group-hover\:opacity-10{
  opacity:0.1
}

.group:hover .group-hover\:opacity-20{
  opacity:0.2
}

.group:hover .group-hover\:opacity-25{
  opacity:0.25
}

.group:hover .group-hover\:opacity-30{
  opacity:0.3
}

.group:hover .group-hover\:opacity-40{
  opacity:0.4
}

.group:hover .group-hover\:opacity-50{
  opacity:0.5
}

.group:hover .group-hover\:opacity-60{
  opacity:0.6
}

.group:hover .group-hover\:opacity-70{
  opacity:0.7
}

.group:hover .group-hover\:opacity-75{
  opacity:0.75
}

.group:hover .group-hover\:opacity-80{
  opacity:0.8
}

.group:hover .group-hover\:opacity-90{
  opacity:0.9
}

.group:hover .group-hover\:opacity-95{
  opacity:0.95
}

.group:hover .group-hover\:opacity-100{
  opacity:1
}

.focus-within\:opacity-0:focus-within{
  opacity:0
}

.focus-within\:opacity-5:focus-within{
  opacity:0.05
}

.focus-within\:opacity-10:focus-within{
  opacity:0.1
}

.focus-within\:opacity-20:focus-within{
  opacity:0.2
}

.focus-within\:opacity-25:focus-within{
  opacity:0.25
}

.focus-within\:opacity-30:focus-within{
  opacity:0.3
}

.focus-within\:opacity-40:focus-within{
  opacity:0.4
}

.focus-within\:opacity-50:focus-within{
  opacity:0.5
}

.focus-within\:opacity-60:focus-within{
  opacity:0.6
}

.focus-within\:opacity-70:focus-within{
  opacity:0.7
}

.focus-within\:opacity-75:focus-within{
  opacity:0.75
}

.focus-within\:opacity-80:focus-within{
  opacity:0.8
}

.focus-within\:opacity-90:focus-within{
  opacity:0.9
}

.focus-within\:opacity-95:focus-within{
  opacity:0.95
}

.focus-within\:opacity-100:focus-within{
  opacity:1
}

.hover\:opacity-0:hover{
  opacity:0
}

.hover\:opacity-5:hover{
  opacity:0.05
}

.hover\:opacity-10:hover{
  opacity:0.1
}

.hover\:opacity-20:hover{
  opacity:0.2
}

.hover\:opacity-25:hover{
  opacity:0.25
}

.hover\:opacity-30:hover{
  opacity:0.3
}

.hover\:opacity-40:hover{
  opacity:0.4
}

.hover\:opacity-50:hover{
  opacity:0.5
}

.hover\:opacity-60:hover{
  opacity:0.6
}

.hover\:opacity-70:hover{
  opacity:0.7
}

.hover\:opacity-75:hover{
  opacity:0.75
}

.hover\:opacity-80:hover{
  opacity:0.8
}

.hover\:opacity-90:hover{
  opacity:0.9
}

.hover\:opacity-95:hover{
  opacity:0.95
}

.hover\:opacity-100:hover{
  opacity:1
}

.focus\:opacity-0:focus{
  opacity:0
}

.focus\:opacity-5:focus{
  opacity:0.05
}

.focus\:opacity-10:focus{
  opacity:0.1
}

.focus\:opacity-20:focus{
  opacity:0.2
}

.focus\:opacity-25:focus{
  opacity:0.25
}

.focus\:opacity-30:focus{
  opacity:0.3
}

.focus\:opacity-40:focus{
  opacity:0.4
}

.focus\:opacity-50:focus{
  opacity:0.5
}

.focus\:opacity-60:focus{
  opacity:0.6
}

.focus\:opacity-70:focus{
  opacity:0.7
}

.focus\:opacity-75:focus{
  opacity:0.75
}

.focus\:opacity-80:focus{
  opacity:0.8
}

.focus\:opacity-90:focus{
  opacity:0.9
}

.focus\:opacity-95:focus{
  opacity:0.95
}

.focus\:opacity-100:focus{
  opacity:1
}

.bg-blend-normal{
  background-blend-mode:normal
}

.bg-blend-multiply{
  background-blend-mode:multiply
}

.bg-blend-screen{
  background-blend-mode:screen
}

.bg-blend-overlay{
  background-blend-mode:overlay
}

.bg-blend-darken{
  background-blend-mode:darken
}

.bg-blend-lighten{
  background-blend-mode:lighten
}

.bg-blend-color-dodge{
  background-blend-mode:color-dodge
}

.bg-blend-color-burn{
  background-blend-mode:color-burn
}

.bg-blend-hard-light{
  background-blend-mode:hard-light
}

.bg-blend-soft-light{
  background-blend-mode:soft-light
}

.bg-blend-difference{
  background-blend-mode:difference
}

.bg-blend-exclusion{
  background-blend-mode:exclusion
}

.bg-blend-hue{
  background-blend-mode:hue
}

.bg-blend-saturation{
  background-blend-mode:saturation
}

.bg-blend-color{
  background-blend-mode:color
}

.bg-blend-luminosity{
  background-blend-mode:luminosity
}

.mix-blend-normal{
  mix-blend-mode:normal
}

.mix-blend-multiply{
  mix-blend-mode:multiply
}

.mix-blend-screen{
  mix-blend-mode:screen
}

.mix-blend-overlay{
  mix-blend-mode:overlay
}

.mix-blend-darken{
  mix-blend-mode:darken
}

.mix-blend-lighten{
  mix-blend-mode:lighten
}

.mix-blend-color-dodge{
  mix-blend-mode:color-dodge
}

.mix-blend-color-burn{
  mix-blend-mode:color-burn
}

.mix-blend-hard-light{
  mix-blend-mode:hard-light
}

.mix-blend-soft-light{
  mix-blend-mode:soft-light
}

.mix-blend-difference{
  mix-blend-mode:difference
}

.mix-blend-exclusion{
  mix-blend-mode:exclusion
}

.mix-blend-hue{
  mix-blend-mode:hue
}

.mix-blend-saturation{
  mix-blend-mode:saturation
}

.mix-blend-color{
  mix-blend-mode:color
}

.mix-blend-luminosity{
  mix-blend-mode:luminosity
}

*, ::before, ::after{
  --tw-shadow:0 0 #0000
}

.shadow-panel{
  --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-hover{
  --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-popup{
  --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-panel{
  --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-hover{
  --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-popup{
  --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-panel:focus-within{
  --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-hover:focus-within{
  --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus-within\:shadow-popup:focus-within{
  --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-panel:hover{
  --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-hover:hover{
  --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-popup:hover{
  --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-panel:focus{
  --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-hover:focus{
  --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-popup:focus{
  --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none{
  outline:2px solid transparent;
  outline-offset:2px
}

.outline-white{
  outline:2px dotted white;
  outline-offset:2px
}

.outline-black{
  outline:2px dotted black;
  outline-offset:2px
}

.focus-within\:outline-none:focus-within{
  outline:2px solid transparent;
  outline-offset:2px
}

.focus-within\:outline-white:focus-within{
  outline:2px dotted white;
  outline-offset:2px
}

.focus-within\:outline-black:focus-within{
  outline:2px dotted black;
  outline-offset:2px
}

.focus\:outline-none:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

.focus\:outline-white:focus{
  outline:2px dotted white;
  outline-offset:2px
}

.focus\:outline-black:focus{
  outline:2px dotted black;
  outline-offset:2px
}

*, ::before, ::after{
  --tw-ring-inset:var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow:0 0 #0000;
  --tw-ring-shadow:0 0 #0000
}

.ring-0{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-1{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-2{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-4{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-8{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring-0:focus-within{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring-1:focus-within{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring-2:focus-within{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring-4:focus-within{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring-8:focus-within{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring:focus-within{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-0:focus{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-1:focus{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-2:focus{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-4:focus{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-8:focus{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring:focus{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-inset{
  --tw-ring-inset:inset
}

.focus-within\:ring-inset:focus-within{
  --tw-ring-inset:inset
}

.focus\:ring-inset:focus{
  --tw-ring-inset:inset
}

.ring-red1{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
}

.ring-red2{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
}

.ring-red3{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
}

.ring-red4{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
}

.ring-red5{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
}

.ring-cyan1{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
}

.ring-cyan2{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
}

.ring-cyan3{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
}

.ring-cyan4{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
}

.ring-cyan5{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
}

.ring-bg1{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
}

.ring-bg2{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
}

.ring-bg3{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
}

.ring-gray1{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
}

.ring-gray2{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
}

.ring-gray3{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
}

.ring-gray4{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
}

.ring-gray5{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
}

.ring-gray6{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
}

.ring-gray7{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
}

.ring-white{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
}

.ring-warning{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
}

.ring-error{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
}

.ring-info{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
}

.ring-success{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
}

.ring-special4{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
}

.ring-dark{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
}

.ring-transparent{
  --tw-ring-color:transparent
}

.ring-secondary1{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
}

.ring-secondary2{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
}

.ring-secondary3{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
}

.ring-secondary4{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
}

.ring-secondary5{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
}

.ring-green1{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
}

.focus-within\:ring-red1:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
}

.focus-within\:ring-red2:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
}

.focus-within\:ring-red3:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
}

.focus-within\:ring-red4:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
}

.focus-within\:ring-red5:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan1:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan2:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan3:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan4:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
}

.focus-within\:ring-cyan5:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
}

.focus-within\:ring-bg1:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
}

.focus-within\:ring-bg2:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-bg3:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
}

.focus-within\:ring-gray1:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
}

.focus-within\:ring-gray2:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
}

.focus-within\:ring-gray3:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
}

.focus-within\:ring-gray4:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
}

.focus-within\:ring-gray5:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
}

.focus-within\:ring-gray6:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
}

.focus-within\:ring-gray7:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
}

.focus-within\:ring-white:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
}

.focus-within\:ring-warning:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
}

.focus-within\:ring-error:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
}

.focus-within\:ring-info:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
}

.focus-within\:ring-success:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
}

.focus-within\:ring-special4:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
}

.focus-within\:ring-dark:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
}

.focus-within\:ring-transparent:focus-within{
  --tw-ring-color:transparent
}

.focus-within\:ring-secondary1:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
}

.focus-within\:ring-secondary2:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
}

.focus-within\:ring-secondary3:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
}

.focus-within\:ring-secondary4:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
}

.focus-within\:ring-secondary5:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
}

.focus-within\:ring-green1:focus-within{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
}

.focus\:ring-red1:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
}

.focus\:ring-red2:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
}

.focus\:ring-red3:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
}

.focus\:ring-red4:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
}

.focus\:ring-red5:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
}

.focus\:ring-cyan1:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
}

.focus\:ring-cyan2:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
}

.focus\:ring-cyan3:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
}

.focus\:ring-cyan4:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
}

.focus\:ring-cyan5:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
}

.focus\:ring-bg1:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
}

.focus\:ring-bg2:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
}

.focus\:ring-bg3:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
}

.focus\:ring-gray1:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
}

.focus\:ring-gray2:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
}

.focus\:ring-gray3:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
}

.focus\:ring-gray4:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
}

.focus\:ring-gray5:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
}

.focus\:ring-gray6:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
}

.focus\:ring-gray7:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
}

.focus\:ring-white:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
}

.focus\:ring-warning:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
}

.focus\:ring-error:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
}

.focus\:ring-info:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
}

.focus\:ring-success:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
}

.focus\:ring-special4:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
}

.focus\:ring-dark:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
}

.focus\:ring-transparent:focus{
  --tw-ring-color:transparent
}

.focus\:ring-secondary1:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
}

.focus\:ring-secondary2:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
}

.focus\:ring-secondary3:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
}

.focus\:ring-secondary4:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
}

.focus\:ring-secondary5:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
}

.focus\:ring-green1:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
}

.ring-opacity-0{
  --tw-ring-opacity:0
}

.ring-opacity-5{
  --tw-ring-opacity:0.05
}

.ring-opacity-10{
  --tw-ring-opacity:0.1
}

.ring-opacity-20{
  --tw-ring-opacity:0.2
}

.ring-opacity-25{
  --tw-ring-opacity:0.25
}

.ring-opacity-30{
  --tw-ring-opacity:0.3
}

.ring-opacity-40{
  --tw-ring-opacity:0.4
}

.ring-opacity-50{
  --tw-ring-opacity:0.5
}

.ring-opacity-60{
  --tw-ring-opacity:0.6
}

.ring-opacity-70{
  --tw-ring-opacity:0.7
}

.ring-opacity-75{
  --tw-ring-opacity:0.75
}

.ring-opacity-80{
  --tw-ring-opacity:0.8
}

.ring-opacity-90{
  --tw-ring-opacity:0.9
}

.ring-opacity-95{
  --tw-ring-opacity:0.95
}

.ring-opacity-100{
  --tw-ring-opacity:1
}

.focus-within\:ring-opacity-0:focus-within{
  --tw-ring-opacity:0
}

.focus-within\:ring-opacity-5:focus-within{
  --tw-ring-opacity:0.05
}

.focus-within\:ring-opacity-10:focus-within{
  --tw-ring-opacity:0.1
}

.focus-within\:ring-opacity-20:focus-within{
  --tw-ring-opacity:0.2
}

.focus-within\:ring-opacity-25:focus-within{
  --tw-ring-opacity:0.25
}

.focus-within\:ring-opacity-30:focus-within{
  --tw-ring-opacity:0.3
}

.focus-within\:ring-opacity-40:focus-within{
  --tw-ring-opacity:0.4
}

.focus-within\:ring-opacity-50:focus-within{
  --tw-ring-opacity:0.5
}

.focus-within\:ring-opacity-60:focus-within{
  --tw-ring-opacity:0.6
}

.focus-within\:ring-opacity-70:focus-within{
  --tw-ring-opacity:0.7
}

.focus-within\:ring-opacity-75:focus-within{
  --tw-ring-opacity:0.75
}

.focus-within\:ring-opacity-80:focus-within{
  --tw-ring-opacity:0.8
}

.focus-within\:ring-opacity-90:focus-within{
  --tw-ring-opacity:0.9
}

.focus-within\:ring-opacity-95:focus-within{
  --tw-ring-opacity:0.95
}

.focus-within\:ring-opacity-100:focus-within{
  --tw-ring-opacity:1
}

.focus\:ring-opacity-0:focus{
  --tw-ring-opacity:0
}

.focus\:ring-opacity-5:focus{
  --tw-ring-opacity:0.05
}

.focus\:ring-opacity-10:focus{
  --tw-ring-opacity:0.1
}

.focus\:ring-opacity-20:focus{
  --tw-ring-opacity:0.2
}

.focus\:ring-opacity-25:focus{
  --tw-ring-opacity:0.25
}

.focus\:ring-opacity-30:focus{
  --tw-ring-opacity:0.3
}

.focus\:ring-opacity-40:focus{
  --tw-ring-opacity:0.4
}

.focus\:ring-opacity-50:focus{
  --tw-ring-opacity:0.5
}

.focus\:ring-opacity-60:focus{
  --tw-ring-opacity:0.6
}

.focus\:ring-opacity-70:focus{
  --tw-ring-opacity:0.7
}

.focus\:ring-opacity-75:focus{
  --tw-ring-opacity:0.75
}

.focus\:ring-opacity-80:focus{
  --tw-ring-opacity:0.8
}

.focus\:ring-opacity-90:focus{
  --tw-ring-opacity:0.9
}

.focus\:ring-opacity-95:focus{
  --tw-ring-opacity:0.95
}

.focus\:ring-opacity-100:focus{
  --tw-ring-opacity:1
}

.ring-offset-0{
  --tw-ring-offset-width:0px
}

.ring-offset-1{
  --tw-ring-offset-width:1px
}

.ring-offset-2{
  --tw-ring-offset-width:2px
}

.ring-offset-4{
  --tw-ring-offset-width:4px
}

.ring-offset-8{
  --tw-ring-offset-width:8px
}

.focus-within\:ring-offset-0:focus-within{
  --tw-ring-offset-width:0px
}

.focus-within\:ring-offset-1:focus-within{
  --tw-ring-offset-width:1px
}

.focus-within\:ring-offset-2:focus-within{
  --tw-ring-offset-width:2px
}

.focus-within\:ring-offset-4:focus-within{
  --tw-ring-offset-width:4px
}

.focus-within\:ring-offset-8:focus-within{
  --tw-ring-offset-width:8px
}

.focus\:ring-offset-0:focus{
  --tw-ring-offset-width:0px
}

.focus\:ring-offset-1:focus{
  --tw-ring-offset-width:1px
}

.focus\:ring-offset-2:focus{
  --tw-ring-offset-width:2px
}

.focus\:ring-offset-4:focus{
  --tw-ring-offset-width:4px
}

.focus\:ring-offset-8:focus{
  --tw-ring-offset-width:8px
}

.ring-offset-red1{
  --tw-ring-offset-color:#CC352C
}

.ring-offset-red2{
  --tw-ring-offset-color:#FF554B
}

.ring-offset-red3{
  --tw-ring-offset-color:#FF7B73
}

.ring-offset-red4{
  --tw-ring-offset-color:#FFF1F1
}

.ring-offset-red5{
  --tw-ring-offset-color:#FFF6F6
}

.ring-offset-cyan1{
  --tw-ring-offset-color:#009897
}

.ring-offset-cyan2{
  --tw-ring-offset-color:#00BFBD
}

.ring-offset-cyan3{
  --tw-ring-offset-color:#4CD1D0
}

.ring-offset-cyan4{
  --tw-ring-offset-color:#B2ECEB
}

.ring-offset-cyan5{
  --tw-ring-offset-color:#EDFAFA
}

.ring-offset-bg1{
  --tw-ring-offset-color:#FAFAFC
}

.ring-offset-bg2{
  --tw-ring-offset-color:#ffffff
}

.ring-offset-bg3{
  --tw-ring-offset-color:#F7F7FA
}

.ring-offset-gray1{
  --tw-ring-offset-color:#333333
}

.ring-offset-gray2{
  --tw-ring-offset-color:#4D4D4D
}

.ring-offset-gray3{
  --tw-ring-offset-color:#8C8C8C
}

.ring-offset-gray4{
  --tw-ring-offset-color:#BFBFBF
}

.ring-offset-gray5{
  --tw-ring-offset-color:#D9D9D9
}

.ring-offset-gray6{
  --tw-ring-offset-color:#E9E9EB
}

.ring-offset-gray7{
  --tw-ring-offset-color:#515164
}

.ring-offset-white{
  --tw-ring-offset-color:#ffffff
}

.ring-offset-warning{
  --tw-ring-offset-color:#FFC319
}

.ring-offset-error{
  --tw-ring-offset-color:#EA696F
}

.ring-offset-info{
  --tw-ring-offset-color:#0071B9
}

.ring-offset-success{
  --tw-ring-offset-color:#98D45F
}

.ring-offset-special4{
  --tw-ring-offset-color:#59779A
}

.ring-offset-dark{
  --tw-ring-offset-color:#000000
}

.ring-offset-transparent{
  --tw-ring-offset-color:transparent
}

.ring-offset-secondary1{
  --tw-ring-offset-color:#313140
}

.ring-offset-secondary2{
  --tw-ring-offset-color:#515164
}

.ring-offset-secondary3{
  --tw-ring-offset-color:#9C9CAC
}

.ring-offset-secondary4{
  --tw-ring-offset-color:#E5E8ED
}

.ring-offset-secondary5{
  --tw-ring-offset-color:#F5F6F9
}

.ring-offset-green1{
  --tw-ring-offset-color:#0DE298
}

.focus-within\:ring-offset-red1:focus-within{
  --tw-ring-offset-color:#CC352C
}

.focus-within\:ring-offset-red2:focus-within{
  --tw-ring-offset-color:#FF554B
}

.focus-within\:ring-offset-red3:focus-within{
  --tw-ring-offset-color:#FF7B73
}

.focus-within\:ring-offset-red4:focus-within{
  --tw-ring-offset-color:#FFF1F1
}

.focus-within\:ring-offset-red5:focus-within{
  --tw-ring-offset-color:#FFF6F6
}

.focus-within\:ring-offset-cyan1:focus-within{
  --tw-ring-offset-color:#009897
}

.focus-within\:ring-offset-cyan2:focus-within{
  --tw-ring-offset-color:#00BFBD
}

.focus-within\:ring-offset-cyan3:focus-within{
  --tw-ring-offset-color:#4CD1D0
}

.focus-within\:ring-offset-cyan4:focus-within{
  --tw-ring-offset-color:#B2ECEB
}

.focus-within\:ring-offset-cyan5:focus-within{
  --tw-ring-offset-color:#EDFAFA
}

.focus-within\:ring-offset-bg1:focus-within{
  --tw-ring-offset-color:#FAFAFC
}

.focus-within\:ring-offset-bg2:focus-within{
  --tw-ring-offset-color:#ffffff
}

.focus-within\:ring-offset-bg3:focus-within{
  --tw-ring-offset-color:#F7F7FA
}

.focus-within\:ring-offset-gray1:focus-within{
  --tw-ring-offset-color:#333333
}

.focus-within\:ring-offset-gray2:focus-within{
  --tw-ring-offset-color:#4D4D4D
}

.focus-within\:ring-offset-gray3:focus-within{
  --tw-ring-offset-color:#8C8C8C
}

.focus-within\:ring-offset-gray4:focus-within{
  --tw-ring-offset-color:#BFBFBF
}

.focus-within\:ring-offset-gray5:focus-within{
  --tw-ring-offset-color:#D9D9D9
}

.focus-within\:ring-offset-gray6:focus-within{
  --tw-ring-offset-color:#E9E9EB
}

.focus-within\:ring-offset-gray7:focus-within{
  --tw-ring-offset-color:#515164
}

.focus-within\:ring-offset-white:focus-within{
  --tw-ring-offset-color:#ffffff
}

.focus-within\:ring-offset-warning:focus-within{
  --tw-ring-offset-color:#FFC319
}

.focus-within\:ring-offset-error:focus-within{
  --tw-ring-offset-color:#EA696F
}

.focus-within\:ring-offset-info:focus-within{
  --tw-ring-offset-color:#0071B9
}

.focus-within\:ring-offset-success:focus-within{
  --tw-ring-offset-color:#98D45F
}

.focus-within\:ring-offset-special4:focus-within{
  --tw-ring-offset-color:#59779A
}

.focus-within\:ring-offset-dark:focus-within{
  --tw-ring-offset-color:#000000
}

.focus-within\:ring-offset-transparent:focus-within{
  --tw-ring-offset-color:transparent
}

.focus-within\:ring-offset-secondary1:focus-within{
  --tw-ring-offset-color:#313140
}

.focus-within\:ring-offset-secondary2:focus-within{
  --tw-ring-offset-color:#515164
}

.focus-within\:ring-offset-secondary3:focus-within{
  --tw-ring-offset-color:#9C9CAC
}

.focus-within\:ring-offset-secondary4:focus-within{
  --tw-ring-offset-color:#E5E8ED
}

.focus-within\:ring-offset-secondary5:focus-within{
  --tw-ring-offset-color:#F5F6F9
}

.focus-within\:ring-offset-green1:focus-within{
  --tw-ring-offset-color:#0DE298
}

.focus\:ring-offset-red1:focus{
  --tw-ring-offset-color:#CC352C
}

.focus\:ring-offset-red2:focus{
  --tw-ring-offset-color:#FF554B
}

.focus\:ring-offset-red3:focus{
  --tw-ring-offset-color:#FF7B73
}

.focus\:ring-offset-red4:focus{
  --tw-ring-offset-color:#FFF1F1
}

.focus\:ring-offset-red5:focus{
  --tw-ring-offset-color:#FFF6F6
}

.focus\:ring-offset-cyan1:focus{
  --tw-ring-offset-color:#009897
}

.focus\:ring-offset-cyan2:focus{
  --tw-ring-offset-color:#00BFBD
}

.focus\:ring-offset-cyan3:focus{
  --tw-ring-offset-color:#4CD1D0
}

.focus\:ring-offset-cyan4:focus{
  --tw-ring-offset-color:#B2ECEB
}

.focus\:ring-offset-cyan5:focus{
  --tw-ring-offset-color:#EDFAFA
}

.focus\:ring-offset-bg1:focus{
  --tw-ring-offset-color:#FAFAFC
}

.focus\:ring-offset-bg2:focus{
  --tw-ring-offset-color:#ffffff
}

.focus\:ring-offset-bg3:focus{
  --tw-ring-offset-color:#F7F7FA
}

.focus\:ring-offset-gray1:focus{
  --tw-ring-offset-color:#333333
}

.focus\:ring-offset-gray2:focus{
  --tw-ring-offset-color:#4D4D4D
}

.focus\:ring-offset-gray3:focus{
  --tw-ring-offset-color:#8C8C8C
}

.focus\:ring-offset-gray4:focus{
  --tw-ring-offset-color:#BFBFBF
}

.focus\:ring-offset-gray5:focus{
  --tw-ring-offset-color:#D9D9D9
}

.focus\:ring-offset-gray6:focus{
  --tw-ring-offset-color:#E9E9EB
}

.focus\:ring-offset-gray7:focus{
  --tw-ring-offset-color:#515164
}

.focus\:ring-offset-white:focus{
  --tw-ring-offset-color:#ffffff
}

.focus\:ring-offset-warning:focus{
  --tw-ring-offset-color:#FFC319
}

.focus\:ring-offset-error:focus{
  --tw-ring-offset-color:#EA696F
}

.focus\:ring-offset-info:focus{
  --tw-ring-offset-color:#0071B9
}

.focus\:ring-offset-success:focus{
  --tw-ring-offset-color:#98D45F
}

.focus\:ring-offset-special4:focus{
  --tw-ring-offset-color:#59779A
}

.focus\:ring-offset-dark:focus{
  --tw-ring-offset-color:#000000
}

.focus\:ring-offset-transparent:focus{
  --tw-ring-offset-color:transparent
}

.focus\:ring-offset-secondary1:focus{
  --tw-ring-offset-color:#313140
}

.focus\:ring-offset-secondary2:focus{
  --tw-ring-offset-color:#515164
}

.focus\:ring-offset-secondary3:focus{
  --tw-ring-offset-color:#9C9CAC
}

.focus\:ring-offset-secondary4:focus{
  --tw-ring-offset-color:#E5E8ED
}

.focus\:ring-offset-secondary5:focus{
  --tw-ring-offset-color:#F5F6F9
}

.focus\:ring-offset-green1:focus{
  --tw-ring-offset-color:#0DE298
}

.filter{
  --tw-blur:var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness:var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast:var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale:var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
  --tw-invert:var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate:var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia:var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow:var(--tw-empty,/*!*/ /*!*/);
  filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter-none{
  filter:none
}

.blur-0{
  --tw-blur:blur(0)
}

.blur-none{
  --tw-blur:blur(0)
}

.blur-sm{
  --tw-blur:blur(4px)
}

.blur{
  --tw-blur:blur(8px)
}

.blur-md{
  --tw-blur:blur(12px)
}

.blur-lg{
  --tw-blur:blur(16px)
}

.blur-xl{
  --tw-blur:blur(24px)
}

.blur-2xl{
  --tw-blur:blur(40px)
}

.blur-3xl{
  --tw-blur:blur(64px)
}

.brightness-0{
  --tw-brightness:brightness(0)
}

.brightness-50{
  --tw-brightness:brightness(.5)
}

.brightness-75{
  --tw-brightness:brightness(.75)
}

.brightness-90{
  --tw-brightness:brightness(.9)
}

.brightness-95{
  --tw-brightness:brightness(.95)
}

.brightness-100{
  --tw-brightness:brightness(1)
}

.brightness-105{
  --tw-brightness:brightness(1.05)
}

.brightness-110{
  --tw-brightness:brightness(1.1)
}

.brightness-125{
  --tw-brightness:brightness(1.25)
}

.brightness-150{
  --tw-brightness:brightness(1.5)
}

.brightness-200{
  --tw-brightness:brightness(2)
}

.contrast-0{
  --tw-contrast:contrast(0)
}

.contrast-50{
  --tw-contrast:contrast(.5)
}

.contrast-75{
  --tw-contrast:contrast(.75)
}

.contrast-100{
  --tw-contrast:contrast(1)
}

.contrast-125{
  --tw-contrast:contrast(1.25)
}

.contrast-150{
  --tw-contrast:contrast(1.5)
}

.contrast-200{
  --tw-contrast:contrast(2)
}

.drop-shadow-sm{
  --tw-drop-shadow:drop-shadow(0 1px 1px rgba(0,0,0,0.05))
}

.drop-shadow{
  --tw-drop-shadow:drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
}

.drop-shadow-md{
  --tw-drop-shadow:drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
}

.drop-shadow-lg{
  --tw-drop-shadow:drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
}

.drop-shadow-xl{
  --tw-drop-shadow:drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
}

.drop-shadow-2xl{
  --tw-drop-shadow:drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
}

.drop-shadow-none{
  --tw-drop-shadow:drop-shadow(0 0 #0000)
}

.grayscale-0{
  --tw-grayscale:grayscale(0)
}

.grayscale{
  --tw-grayscale:grayscale(100%)
}

.hue-rotate-0{
  --tw-hue-rotate:hue-rotate(0deg)
}

.hue-rotate-15{
  --tw-hue-rotate:hue-rotate(15deg)
}

.hue-rotate-30{
  --tw-hue-rotate:hue-rotate(30deg)
}

.hue-rotate-60{
  --tw-hue-rotate:hue-rotate(60deg)
}

.hue-rotate-90{
  --tw-hue-rotate:hue-rotate(90deg)
}

.hue-rotate-180{
  --tw-hue-rotate:hue-rotate(180deg)
}

.-hue-rotate-180{
  --tw-hue-rotate:hue-rotate(-180deg)
}

.-hue-rotate-90{
  --tw-hue-rotate:hue-rotate(-90deg)
}

.-hue-rotate-60{
  --tw-hue-rotate:hue-rotate(-60deg)
}

.-hue-rotate-30{
  --tw-hue-rotate:hue-rotate(-30deg)
}

.-hue-rotate-15{
  --tw-hue-rotate:hue-rotate(-15deg)
}

.invert-0{
  --tw-invert:invert(0)
}

.invert{
  --tw-invert:invert(100%)
}

.saturate-0{
  --tw-saturate:saturate(0)
}

.saturate-50{
  --tw-saturate:saturate(.5)
}

.saturate-100{
  --tw-saturate:saturate(1)
}

.saturate-150{
  --tw-saturate:saturate(1.5)
}

.saturate-200{
  --tw-saturate:saturate(2)
}

.sepia-0{
  --tw-sepia:sepia(0)
}

.sepia{
  --tw-sepia:sepia(100%)
}

.backdrop-filter{
  --tw-backdrop-blur:var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-brightness:var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-contrast:var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-grayscale:var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-invert:var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-opacity:var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-saturate:var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-sepia:var(--tw-empty,/*!*/ /*!*/);
  -webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-filter-none{
  -webkit-backdrop-filter:none;
          backdrop-filter:none
}

.backdrop-blur-0{
  --tw-backdrop-blur:blur(0)
}

.backdrop-blur-none{
  --tw-backdrop-blur:blur(0)
}

.backdrop-blur-sm{
  --tw-backdrop-blur:blur(4px)
}

.backdrop-blur{
  --tw-backdrop-blur:blur(8px)
}

.backdrop-blur-md{
  --tw-backdrop-blur:blur(12px)
}

.backdrop-blur-lg{
  --tw-backdrop-blur:blur(16px)
}

.backdrop-blur-xl{
  --tw-backdrop-blur:blur(24px)
}

.backdrop-blur-2xl{
  --tw-backdrop-blur:blur(40px)
}

.backdrop-blur-3xl{
  --tw-backdrop-blur:blur(64px)
}

.backdrop-brightness-0{
  --tw-backdrop-brightness:brightness(0)
}

.backdrop-brightness-50{
  --tw-backdrop-brightness:brightness(.5)
}

.backdrop-brightness-75{
  --tw-backdrop-brightness:brightness(.75)
}

.backdrop-brightness-90{
  --tw-backdrop-brightness:brightness(.9)
}

.backdrop-brightness-95{
  --tw-backdrop-brightness:brightness(.95)
}

.backdrop-brightness-100{
  --tw-backdrop-brightness:brightness(1)
}

.backdrop-brightness-105{
  --tw-backdrop-brightness:brightness(1.05)
}

.backdrop-brightness-110{
  --tw-backdrop-brightness:brightness(1.1)
}

.backdrop-brightness-125{
  --tw-backdrop-brightness:brightness(1.25)
}

.backdrop-brightness-150{
  --tw-backdrop-brightness:brightness(1.5)
}

.backdrop-brightness-200{
  --tw-backdrop-brightness:brightness(2)
}

.backdrop-contrast-0{
  --tw-backdrop-contrast:contrast(0)
}

.backdrop-contrast-50{
  --tw-backdrop-contrast:contrast(.5)
}

.backdrop-contrast-75{
  --tw-backdrop-contrast:contrast(.75)
}

.backdrop-contrast-100{
  --tw-backdrop-contrast:contrast(1)
}

.backdrop-contrast-125{
  --tw-backdrop-contrast:contrast(1.25)
}

.backdrop-contrast-150{
  --tw-backdrop-contrast:contrast(1.5)
}

.backdrop-contrast-200{
  --tw-backdrop-contrast:contrast(2)
}

.backdrop-grayscale-0{
  --tw-backdrop-grayscale:grayscale(0)
}

.backdrop-grayscale{
  --tw-backdrop-grayscale:grayscale(100%)
}

.backdrop-hue-rotate-0{
  --tw-backdrop-hue-rotate:hue-rotate(0deg)
}

.backdrop-hue-rotate-15{
  --tw-backdrop-hue-rotate:hue-rotate(15deg)
}

.backdrop-hue-rotate-30{
  --tw-backdrop-hue-rotate:hue-rotate(30deg)
}

.backdrop-hue-rotate-60{
  --tw-backdrop-hue-rotate:hue-rotate(60deg)
}

.backdrop-hue-rotate-90{
  --tw-backdrop-hue-rotate:hue-rotate(90deg)
}

.backdrop-hue-rotate-180{
  --tw-backdrop-hue-rotate:hue-rotate(180deg)
}

.-backdrop-hue-rotate-180{
  --tw-backdrop-hue-rotate:hue-rotate(-180deg)
}

.-backdrop-hue-rotate-90{
  --tw-backdrop-hue-rotate:hue-rotate(-90deg)
}

.-backdrop-hue-rotate-60{
  --tw-backdrop-hue-rotate:hue-rotate(-60deg)
}

.-backdrop-hue-rotate-30{
  --tw-backdrop-hue-rotate:hue-rotate(-30deg)
}

.-backdrop-hue-rotate-15{
  --tw-backdrop-hue-rotate:hue-rotate(-15deg)
}

.backdrop-invert-0{
  --tw-backdrop-invert:invert(0)
}

.backdrop-invert{
  --tw-backdrop-invert:invert(100%)
}

.backdrop-opacity-0{
  --tw-backdrop-opacity:opacity(0)
}

.backdrop-opacity-5{
  --tw-backdrop-opacity:opacity(0.05)
}

.backdrop-opacity-10{
  --tw-backdrop-opacity:opacity(0.1)
}

.backdrop-opacity-20{
  --tw-backdrop-opacity:opacity(0.2)
}

.backdrop-opacity-25{
  --tw-backdrop-opacity:opacity(0.25)
}

.backdrop-opacity-30{
  --tw-backdrop-opacity:opacity(0.3)
}

.backdrop-opacity-40{
  --tw-backdrop-opacity:opacity(0.4)
}

.backdrop-opacity-50{
  --tw-backdrop-opacity:opacity(0.5)
}

.backdrop-opacity-60{
  --tw-backdrop-opacity:opacity(0.6)
}

.backdrop-opacity-70{
  --tw-backdrop-opacity:opacity(0.7)
}

.backdrop-opacity-75{
  --tw-backdrop-opacity:opacity(0.75)
}

.backdrop-opacity-80{
  --tw-backdrop-opacity:opacity(0.8)
}

.backdrop-opacity-90{
  --tw-backdrop-opacity:opacity(0.9)
}

.backdrop-opacity-95{
  --tw-backdrop-opacity:opacity(0.95)
}

.backdrop-opacity-100{
  --tw-backdrop-opacity:opacity(1)
}

.backdrop-saturate-0{
  --tw-backdrop-saturate:saturate(0)
}

.backdrop-saturate-50{
  --tw-backdrop-saturate:saturate(.5)
}

.backdrop-saturate-100{
  --tw-backdrop-saturate:saturate(1)
}

.backdrop-saturate-150{
  --tw-backdrop-saturate:saturate(1.5)
}

.backdrop-saturate-200{
  --tw-backdrop-saturate:saturate(2)
}

.backdrop-sepia-0{
  --tw-backdrop-sepia:sepia(0)
}

.backdrop-sepia{
  --tw-backdrop-sepia:sepia(100%)
}

.transition-none{
  transition-property:none
}

.transition-all{
  transition-property:all;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms
}

.transition{
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms
}

.transition-colors{
  transition-property:background-color, border-color, color, fill, stroke;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms
}

.transition-opacity{
  transition-property:opacity;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms
}

.transition-shadow{
  transition-property:box-shadow;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms
}

.transition-transform{
  transition-property:transform;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms
}

.duration-75{
  transition-duration:75ms
}

.duration-100{
  transition-duration:100ms
}

.duration-150{
  transition-duration:150ms
}

.duration-200{
  transition-duration:200ms
}

.duration-300{
  transition-duration:300ms
}

.duration-500{
  transition-duration:500ms
}

.duration-700{
  transition-duration:700ms
}

.duration-1000{
  transition-duration:1000ms
}

.ease-linear{
  transition-timing-function:linear
}

.ease-in{
  transition-timing-function:cubic-bezier(0.4, 0, 1, 1)
}

.ease-out{
  transition-timing-function:cubic-bezier(0, 0, 0.2, 1)
}

.ease-in-out{
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1)
}

.content-none{
  content:none
}

.h1{
  color:#333333;
  font-size:32px;
  font-weight:600;
  line-height:40px
}

@media (min-width: 768px){
  .h1{
    font-size:48px;
    line-height:60px
  }
}

.h2{
  color:#333333;
  font-weight:500;
  font-size:30px;
  line-height:38px
}

@media (min-width: 768px){
  .h2{
    font-size:40px;
    line-height:48px
  }
}

.h3{
  color:#333333;
  font-weight:500;
  font-size:28px;
  line-height:36px
}

@media (min-width: 768px){
  .h3{
    font-size:32px;
    line-height:40px
  }
}

.h4{
  color:#333333;
  font-size:24px;
  font-weight:500;
  line-height:32px
}

.h5{
  color:#333333;
  font-size:20px;
  font-weight:500;
  line-height:28px
}

h6, .h6{
  color:#333333
}

h6, .h6{
  font-size:16px
}

h6, .h6{
  font-weight:500
}

h6,.h6{
  line-height:24px
}

.regular-body{
  font-size:16px;
  font-weight:400;
  line-height:24px
}

.small-secondary{
  font-size:14px;
  font-weight:400;
  line-height:22px
}

.tiny-caption{
  font-size:12px;
  font-weight:400;
  line-height:18px
}

.text-button{
  font-weight:500;
  font-size:14px;
  letter-spacing:0.75px
}

.text-overline{
  margin:4px;
  color:#8C8C8C;
  font-size:12px;
  font-weight:300;
  letter-spacing:0.75px
}

.big-numbers{
  font-size:40px;
  font-weight:300
}

.speech-italic{
  color:#4D4D4D;
  font-style:italic;
  font-weight:400;
  line-height:22px;
  letter-spacing:-0.01px
}

.text-link{
  margin-left:4px;
  margin-right:4px;
  font-weight:400;
  text-decoration:underline;
  color:#59779A;
  line-height:24px
}

.text-link:hover{
  color:#333333
}

.small-dot{
  border-radius:9999px;
  width:8px;
  height:8px
}

.border-b-gray{
  border-width:0px;
  border-style:solid;
  border-bottom-width:1px;
  --tw-border-opacity:1;
  border-color:rgba(233, 233, 235, var(--tw-border-opacity))
}

.truncate-2{
  overflow:hidden;
  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical
}

.truncate-3{
  overflow:hidden;
  display:-webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient:vertical
}

.truncate-4{
  overflow:hidden;
  display:-webkit-box;
  -webkit-line-clamp:4;
  -webkit-box-orient:vertical
}

.grecaptcha-badge{
  visibility:hidden
}

.absolute-center{
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%)
}

html{
  font-size:14px;
  font-family:"Noto Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"
}

body{
  margin:0;
  padding:0;
  font-family:"Noto Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  -webkit-text-size-adjust:100%;
  line-height:1.5715;
  font-weight:300
}

body>iframe{
  display:none !important
}

ul{
  list-style:none;
  padding:0;
  margin:0
}

svg{
  display:block
}

a:any-link{
  text-decoration:none
}

.primary-color{
  color:#ff4338
}

.absolute-center{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%)
}

@media (min-width: 480px){
  .sm\:visible{
    visibility:visible
  }

  .sm\:invisible{
    visibility:hidden
  }

  .sm\:static{
    position:static
  }

  .sm\:fixed{
    position:fixed
  }

  .sm\:absolute{
    position:absolute
  }

  .sm\:relative{
    position:relative
  }

  .sm\:sticky{
    position:sticky
  }

  .sm\:inset-0{
    top:0;
    right:0;
    bottom:0;
    left:0
  }

  .sm\:inset-1{
    top:4px;
    right:4px;
    bottom:4px;
    left:4px
  }

  .sm\:inset-2{
    top:8px;
    right:8px;
    bottom:8px;
    left:8px
  }

  .sm\:inset-3{
    top:12px;
    right:12px;
    bottom:12px;
    left:12px
  }

  .sm\:inset-4{
    top:16px;
    right:16px;
    bottom:16px;
    left:16px
  }

  .sm\:inset-5{
    top:20px;
    right:20px;
    bottom:20px;
    left:20px
  }

  .sm\:inset-6{
    top:24px;
    right:24px;
    bottom:24px;
    left:24px
  }

  .sm\:inset-8{
    top:32px;
    right:32px;
    bottom:32px;
    left:32px
  }

  .sm\:inset-10{
    top:40px;
    right:40px;
    bottom:40px;
    left:40px
  }

  .sm\:inset-12{
    top:48px;
    right:48px;
    bottom:48px;
    left:48px
  }

  .sm\:inset-16{
    top:64px;
    right:64px;
    bottom:64px;
    left:64px
  }

  .sm\:inset-18{
    top:72px;
    right:72px;
    bottom:72px;
    left:72px
  }

  .sm\:inset-24{
    top:96px;
    right:96px;
    bottom:96px;
    left:96px
  }

  .sm\:inset-auto{
    top:auto;
    right:auto;
    bottom:auto;
    left:auto
  }

  .sm\:inset-5dot5{
    top:22px;
    right:22px;
    bottom:22px;
    left:22px
  }

  .sm\:inset-full{
    top:100%;
    right:100%;
    bottom:100%;
    left:100%
  }

  .sm\:-inset-1{
    top:-4px;
    right:-4px;
    bottom:-4px;
    left:-4px
  }

  .sm\:-inset-2{
    top:-8px;
    right:-8px;
    bottom:-8px;
    left:-8px
  }

  .sm\:-inset-3{
    top:-12px;
    right:-12px;
    bottom:-12px;
    left:-12px
  }

  .sm\:-inset-4{
    top:-16px;
    right:-16px;
    bottom:-16px;
    left:-16px
  }

  .sm\:-inset-5{
    top:-20px;
    right:-20px;
    bottom:-20px;
    left:-20px
  }

  .sm\:-inset-6{
    top:-24px;
    right:-24px;
    bottom:-24px;
    left:-24px
  }

  .sm\:-inset-8{
    top:-32px;
    right:-32px;
    bottom:-32px;
    left:-32px
  }

  .sm\:-inset-10{
    top:-40px;
    right:-40px;
    bottom:-40px;
    left:-40px
  }

  .sm\:-inset-12{
    top:-48px;
    right:-48px;
    bottom:-48px;
    left:-48px
  }

  .sm\:-inset-16{
    top:-64px;
    right:-64px;
    bottom:-64px;
    left:-64px
  }

  .sm\:-inset-18{
    top:-72px;
    right:-72px;
    bottom:-72px;
    left:-72px
  }

  .sm\:-inset-24{
    top:-96px;
    right:-96px;
    bottom:-96px;
    left:-96px
  }

  .sm\:-inset-5dot5{
    top:-22px;
    right:-22px;
    bottom:-22px;
    left:-22px
  }

  .sm\:-inset-full{
    top:-100%;
    right:-100%;
    bottom:-100%;
    left:-100%
  }

  .sm\:inset-1\/2{
    top:50%;
    right:50%;
    bottom:50%;
    left:50%
  }

  .sm\:inset-1\/3{
    top:33.33333%;
    right:33.33333%;
    bottom:33.33333%;
    left:33.33333%
  }

  .sm\:inset-2\/3{
    top:66.666667%;
    right:66.666667%;
    bottom:66.666667%;
    left:66.666667%
  }

  .sm\:inset-1\/4{
    top:25%;
    right:25%;
    bottom:25%;
    left:25%
  }

  .sm\:inset-2\/4{
    top:50%;
    right:50%;
    bottom:50%;
    left:50%
  }

  .sm\:inset-3\/4{
    top:75%;
    right:75%;
    bottom:75%;
    left:75%
  }

  .sm\:-inset-1\/2{
    top:-50%;
    right:-50%;
    bottom:-50%;
    left:-50%
  }

  .sm\:-inset-1\/3{
    top:-33.333333%;
    right:-33.333333%;
    bottom:-33.333333%;
    left:-33.333333%
  }

  .sm\:-inset-2\/3{
    top:-66.666667%;
    right:-66.666667%;
    bottom:-66.666667%;
    left:-66.666667%
  }

  .sm\:-inset-1\/4{
    top:-25%;
    right:-25%;
    bottom:-25%;
    left:-25%
  }

  .sm\:-inset-2\/4{
    top:-50%;
    right:-50%;
    bottom:-50%;
    left:-50%
  }

  .sm\:-inset-3\/4{
    top:-75%;
    right:-75%;
    bottom:-75%;
    left:-75%
  }

  .sm\:inset-x-0{
    left:0;
    right:0
  }

  .sm\:inset-x-1{
    left:4px;
    right:4px
  }

  .sm\:inset-x-2{
    left:8px;
    right:8px
  }

  .sm\:inset-x-3{
    left:12px;
    right:12px
  }

  .sm\:inset-x-4{
    left:16px;
    right:16px
  }

  .sm\:inset-x-5{
    left:20px;
    right:20px
  }

  .sm\:inset-x-6{
    left:24px;
    right:24px
  }

  .sm\:inset-x-8{
    left:32px;
    right:32px
  }

  .sm\:inset-x-10{
    left:40px;
    right:40px
  }

  .sm\:inset-x-12{
    left:48px;
    right:48px
  }

  .sm\:inset-x-16{
    left:64px;
    right:64px
  }

  .sm\:inset-x-18{
    left:72px;
    right:72px
  }

  .sm\:inset-x-24{
    left:96px;
    right:96px
  }

  .sm\:inset-x-auto{
    left:auto;
    right:auto
  }

  .sm\:inset-x-5dot5{
    left:22px;
    right:22px
  }

  .sm\:inset-x-full{
    left:100%;
    right:100%
  }

  .sm\:-inset-x-1{
    left:-4px;
    right:-4px
  }

  .sm\:-inset-x-2{
    left:-8px;
    right:-8px
  }

  .sm\:-inset-x-3{
    left:-12px;
    right:-12px
  }

  .sm\:-inset-x-4{
    left:-16px;
    right:-16px
  }

  .sm\:-inset-x-5{
    left:-20px;
    right:-20px
  }

  .sm\:-inset-x-6{
    left:-24px;
    right:-24px
  }

  .sm\:-inset-x-8{
    left:-32px;
    right:-32px
  }

  .sm\:-inset-x-10{
    left:-40px;
    right:-40px
  }

  .sm\:-inset-x-12{
    left:-48px;
    right:-48px
  }

  .sm\:-inset-x-16{
    left:-64px;
    right:-64px
  }

  .sm\:-inset-x-18{
    left:-72px;
    right:-72px
  }

  .sm\:-inset-x-24{
    left:-96px;
    right:-96px
  }

  .sm\:-inset-x-5dot5{
    left:-22px;
    right:-22px
  }

  .sm\:-inset-x-full{
    left:-100%;
    right:-100%
  }

  .sm\:inset-x-1\/2{
    left:50%;
    right:50%
  }

  .sm\:inset-x-1\/3{
    left:33.33333%;
    right:33.33333%
  }

  .sm\:inset-x-2\/3{
    left:66.666667%;
    right:66.666667%
  }

  .sm\:inset-x-1\/4{
    left:25%;
    right:25%
  }

  .sm\:inset-x-2\/4{
    left:50%;
    right:50%
  }

  .sm\:inset-x-3\/4{
    left:75%;
    right:75%
  }

  .sm\:-inset-x-1\/2{
    left:-50%;
    right:-50%
  }

  .sm\:-inset-x-1\/3{
    left:-33.333333%;
    right:-33.333333%
  }

  .sm\:-inset-x-2\/3{
    left:-66.666667%;
    right:-66.666667%
  }

  .sm\:-inset-x-1\/4{
    left:-25%;
    right:-25%
  }

  .sm\:-inset-x-2\/4{
    left:-50%;
    right:-50%
  }

  .sm\:-inset-x-3\/4{
    left:-75%;
    right:-75%
  }

  .sm\:inset-y-0{
    top:0;
    bottom:0
  }

  .sm\:inset-y-1{
    top:4px;
    bottom:4px
  }

  .sm\:inset-y-2{
    top:8px;
    bottom:8px
  }

  .sm\:inset-y-3{
    top:12px;
    bottom:12px
  }

  .sm\:inset-y-4{
    top:16px;
    bottom:16px
  }

  .sm\:inset-y-5{
    top:20px;
    bottom:20px
  }

  .sm\:inset-y-6{
    top:24px;
    bottom:24px
  }

  .sm\:inset-y-8{
    top:32px;
    bottom:32px
  }

  .sm\:inset-y-10{
    top:40px;
    bottom:40px
  }

  .sm\:inset-y-12{
    top:48px;
    bottom:48px
  }

  .sm\:inset-y-16{
    top:64px;
    bottom:64px
  }

  .sm\:inset-y-18{
    top:72px;
    bottom:72px
  }

  .sm\:inset-y-24{
    top:96px;
    bottom:96px
  }

  .sm\:inset-y-auto{
    top:auto;
    bottom:auto
  }

  .sm\:inset-y-5dot5{
    top:22px;
    bottom:22px
  }

  .sm\:inset-y-full{
    top:100%;
    bottom:100%
  }

  .sm\:-inset-y-1{
    top:-4px;
    bottom:-4px
  }

  .sm\:-inset-y-2{
    top:-8px;
    bottom:-8px
  }

  .sm\:-inset-y-3{
    top:-12px;
    bottom:-12px
  }

  .sm\:-inset-y-4{
    top:-16px;
    bottom:-16px
  }

  .sm\:-inset-y-5{
    top:-20px;
    bottom:-20px
  }

  .sm\:-inset-y-6{
    top:-24px;
    bottom:-24px
  }

  .sm\:-inset-y-8{
    top:-32px;
    bottom:-32px
  }

  .sm\:-inset-y-10{
    top:-40px;
    bottom:-40px
  }

  .sm\:-inset-y-12{
    top:-48px;
    bottom:-48px
  }

  .sm\:-inset-y-16{
    top:-64px;
    bottom:-64px
  }

  .sm\:-inset-y-18{
    top:-72px;
    bottom:-72px
  }

  .sm\:-inset-y-24{
    top:-96px;
    bottom:-96px
  }

  .sm\:-inset-y-5dot5{
    top:-22px;
    bottom:-22px
  }

  .sm\:-inset-y-full{
    top:-100%;
    bottom:-100%
  }

  .sm\:inset-y-1\/2{
    top:50%;
    bottom:50%
  }

  .sm\:inset-y-1\/3{
    top:33.33333%;
    bottom:33.33333%
  }

  .sm\:inset-y-2\/3{
    top:66.666667%;
    bottom:66.666667%
  }

  .sm\:inset-y-1\/4{
    top:25%;
    bottom:25%
  }

  .sm\:inset-y-2\/4{
    top:50%;
    bottom:50%
  }

  .sm\:inset-y-3\/4{
    top:75%;
    bottom:75%
  }

  .sm\:-inset-y-1\/2{
    top:-50%;
    bottom:-50%
  }

  .sm\:-inset-y-1\/3{
    top:-33.333333%;
    bottom:-33.333333%
  }

  .sm\:-inset-y-2\/3{
    top:-66.666667%;
    bottom:-66.666667%
  }

  .sm\:-inset-y-1\/4{
    top:-25%;
    bottom:-25%
  }

  .sm\:-inset-y-2\/4{
    top:-50%;
    bottom:-50%
  }

  .sm\:-inset-y-3\/4{
    top:-75%;
    bottom:-75%
  }

  .sm\:top-0{
    top:0
  }

  .sm\:top-1{
    top:4px
  }

  .sm\:top-2{
    top:8px
  }

  .sm\:top-3{
    top:12px
  }

  .sm\:top-4{
    top:16px
  }

  .sm\:top-5{
    top:20px
  }

  .sm\:top-6{
    top:24px
  }

  .sm\:top-8{
    top:32px
  }

  .sm\:top-10{
    top:40px
  }

  .sm\:top-12{
    top:48px
  }

  .sm\:top-16{
    top:64px
  }

  .sm\:top-18{
    top:72px
  }

  .sm\:top-24{
    top:96px
  }

  .sm\:top-auto{
    top:auto
  }

  .sm\:top-5dot5{
    top:22px
  }

  .sm\:top-full{
    top:100%
  }

  .sm\:-top-1{
    top:-4px
  }

  .sm\:-top-2{
    top:-8px
  }

  .sm\:-top-3{
    top:-12px
  }

  .sm\:-top-4{
    top:-16px
  }

  .sm\:-top-5{
    top:-20px
  }

  .sm\:-top-6{
    top:-24px
  }

  .sm\:-top-8{
    top:-32px
  }

  .sm\:-top-10{
    top:-40px
  }

  .sm\:-top-12{
    top:-48px
  }

  .sm\:-top-16{
    top:-64px
  }

  .sm\:-top-18{
    top:-72px
  }

  .sm\:-top-24{
    top:-96px
  }

  .sm\:-top-5dot5{
    top:-22px
  }

  .sm\:-top-full{
    top:-100%
  }

  .sm\:top-1\/2{
    top:50%
  }

  .sm\:top-1\/3{
    top:33.33333%
  }

  .sm\:top-2\/3{
    top:66.666667%
  }

  .sm\:top-1\/4{
    top:25%
  }

  .sm\:top-2\/4{
    top:50%
  }

  .sm\:top-3\/4{
    top:75%
  }

  .sm\:-top-1\/2{
    top:-50%
  }

  .sm\:-top-1\/3{
    top:-33.333333%
  }

  .sm\:-top-2\/3{
    top:-66.666667%
  }

  .sm\:-top-1\/4{
    top:-25%
  }

  .sm\:-top-2\/4{
    top:-50%
  }

  .sm\:-top-3\/4{
    top:-75%
  }

  .sm\:right-0{
    right:0
  }

  .sm\:right-1{
    right:4px
  }

  .sm\:right-2{
    right:8px
  }

  .sm\:right-3{
    right:12px
  }

  .sm\:right-4{
    right:16px
  }

  .sm\:right-5{
    right:20px
  }

  .sm\:right-6{
    right:24px
  }

  .sm\:right-8{
    right:32px
  }

  .sm\:right-10{
    right:40px
  }

  .sm\:right-12{
    right:48px
  }

  .sm\:right-16{
    right:64px
  }

  .sm\:right-18{
    right:72px
  }

  .sm\:right-24{
    right:96px
  }

  .sm\:right-auto{
    right:auto
  }

  .sm\:right-5dot5{
    right:22px
  }

  .sm\:right-full{
    right:100%
  }

  .sm\:-right-1{
    right:-4px
  }

  .sm\:-right-2{
    right:-8px
  }

  .sm\:-right-3{
    right:-12px
  }

  .sm\:-right-4{
    right:-16px
  }

  .sm\:-right-5{
    right:-20px
  }

  .sm\:-right-6{
    right:-24px
  }

  .sm\:-right-8{
    right:-32px
  }

  .sm\:-right-10{
    right:-40px
  }

  .sm\:-right-12{
    right:-48px
  }

  .sm\:-right-16{
    right:-64px
  }

  .sm\:-right-18{
    right:-72px
  }

  .sm\:-right-24{
    right:-96px
  }

  .sm\:-right-5dot5{
    right:-22px
  }

  .sm\:-right-full{
    right:-100%
  }

  .sm\:right-1\/2{
    right:50%
  }

  .sm\:right-1\/3{
    right:33.33333%
  }

  .sm\:right-2\/3{
    right:66.666667%
  }

  .sm\:right-1\/4{
    right:25%
  }

  .sm\:right-2\/4{
    right:50%
  }

  .sm\:right-3\/4{
    right:75%
  }

  .sm\:-right-1\/2{
    right:-50%
  }

  .sm\:-right-1\/3{
    right:-33.333333%
  }

  .sm\:-right-2\/3{
    right:-66.666667%
  }

  .sm\:-right-1\/4{
    right:-25%
  }

  .sm\:-right-2\/4{
    right:-50%
  }

  .sm\:-right-3\/4{
    right:-75%
  }

  .sm\:bottom-0{
    bottom:0
  }

  .sm\:bottom-1{
    bottom:4px
  }

  .sm\:bottom-2{
    bottom:8px
  }

  .sm\:bottom-3{
    bottom:12px
  }

  .sm\:bottom-4{
    bottom:16px
  }

  .sm\:bottom-5{
    bottom:20px
  }

  .sm\:bottom-6{
    bottom:24px
  }

  .sm\:bottom-8{
    bottom:32px
  }

  .sm\:bottom-10{
    bottom:40px
  }

  .sm\:bottom-12{
    bottom:48px
  }

  .sm\:bottom-16{
    bottom:64px
  }

  .sm\:bottom-18{
    bottom:72px
  }

  .sm\:bottom-24{
    bottom:96px
  }

  .sm\:bottom-auto{
    bottom:auto
  }

  .sm\:bottom-5dot5{
    bottom:22px
  }

  .sm\:bottom-full{
    bottom:100%
  }

  .sm\:-bottom-1{
    bottom:-4px
  }

  .sm\:-bottom-2{
    bottom:-8px
  }

  .sm\:-bottom-3{
    bottom:-12px
  }

  .sm\:-bottom-4{
    bottom:-16px
  }

  .sm\:-bottom-5{
    bottom:-20px
  }

  .sm\:-bottom-6{
    bottom:-24px
  }

  .sm\:-bottom-8{
    bottom:-32px
  }

  .sm\:-bottom-10{
    bottom:-40px
  }

  .sm\:-bottom-12{
    bottom:-48px
  }

  .sm\:-bottom-16{
    bottom:-64px
  }

  .sm\:-bottom-18{
    bottom:-72px
  }

  .sm\:-bottom-24{
    bottom:-96px
  }

  .sm\:-bottom-5dot5{
    bottom:-22px
  }

  .sm\:-bottom-full{
    bottom:-100%
  }

  .sm\:bottom-1\/2{
    bottom:50%
  }

  .sm\:bottom-1\/3{
    bottom:33.33333%
  }

  .sm\:bottom-2\/3{
    bottom:66.666667%
  }

  .sm\:bottom-1\/4{
    bottom:25%
  }

  .sm\:bottom-2\/4{
    bottom:50%
  }

  .sm\:bottom-3\/4{
    bottom:75%
  }

  .sm\:-bottom-1\/2{
    bottom:-50%
  }

  .sm\:-bottom-1\/3{
    bottom:-33.333333%
  }

  .sm\:-bottom-2\/3{
    bottom:-66.666667%
  }

  .sm\:-bottom-1\/4{
    bottom:-25%
  }

  .sm\:-bottom-2\/4{
    bottom:-50%
  }

  .sm\:-bottom-3\/4{
    bottom:-75%
  }

  .sm\:left-0{
    left:0
  }

  .sm\:left-1{
    left:4px
  }

  .sm\:left-2{
    left:8px
  }

  .sm\:left-3{
    left:12px
  }

  .sm\:left-4{
    left:16px
  }

  .sm\:left-5{
    left:20px
  }

  .sm\:left-6{
    left:24px
  }

  .sm\:left-8{
    left:32px
  }

  .sm\:left-10{
    left:40px
  }

  .sm\:left-12{
    left:48px
  }

  .sm\:left-16{
    left:64px
  }

  .sm\:left-18{
    left:72px
  }

  .sm\:left-24{
    left:96px
  }

  .sm\:left-auto{
    left:auto
  }

  .sm\:left-5dot5{
    left:22px
  }

  .sm\:left-full{
    left:100%
  }

  .sm\:-left-1{
    left:-4px
  }

  .sm\:-left-2{
    left:-8px
  }

  .sm\:-left-3{
    left:-12px
  }

  .sm\:-left-4{
    left:-16px
  }

  .sm\:-left-5{
    left:-20px
  }

  .sm\:-left-6{
    left:-24px
  }

  .sm\:-left-8{
    left:-32px
  }

  .sm\:-left-10{
    left:-40px
  }

  .sm\:-left-12{
    left:-48px
  }

  .sm\:-left-16{
    left:-64px
  }

  .sm\:-left-18{
    left:-72px
  }

  .sm\:-left-24{
    left:-96px
  }

  .sm\:-left-5dot5{
    left:-22px
  }

  .sm\:-left-full{
    left:-100%
  }

  .sm\:left-1\/2{
    left:50%
  }

  .sm\:left-1\/3{
    left:33.33333%
  }

  .sm\:left-2\/3{
    left:66.666667%
  }

  .sm\:left-1\/4{
    left:25%
  }

  .sm\:left-2\/4{
    left:50%
  }

  .sm\:left-3\/4{
    left:75%
  }

  .sm\:-left-1\/2{
    left:-50%
  }

  .sm\:-left-1\/3{
    left:-33.333333%
  }

  .sm\:-left-2\/3{
    left:-66.666667%
  }

  .sm\:-left-1\/4{
    left:-25%
  }

  .sm\:-left-2\/4{
    left:-50%
  }

  .sm\:-left-3\/4{
    left:-75%
  }

  .sm\:isolate{
    isolation:isolate
  }

  .sm\:isolation-auto{
    isolation:auto
  }

  .sm\:z-0{
    z-index:0
  }

  .sm\:z-10{
    z-index:10
  }

  .sm\:z-20{
    z-index:20
  }

  .sm\:z-30{
    z-index:30
  }

  .sm\:z-40{
    z-index:40
  }

  .sm\:z-50{
    z-index:50
  }

  .sm\:z-auto{
    z-index:auto
  }

  .sm\:focus-within\:z-0:focus-within{
    z-index:0
  }

  .sm\:focus-within\:z-10:focus-within{
    z-index:10
  }

  .sm\:focus-within\:z-20:focus-within{
    z-index:20
  }

  .sm\:focus-within\:z-30:focus-within{
    z-index:30
  }

  .sm\:focus-within\:z-40:focus-within{
    z-index:40
  }

  .sm\:focus-within\:z-50:focus-within{
    z-index:50
  }

  .sm\:focus-within\:z-auto:focus-within{
    z-index:auto
  }

  .sm\:focus\:z-0:focus{
    z-index:0
  }

  .sm\:focus\:z-10:focus{
    z-index:10
  }

  .sm\:focus\:z-20:focus{
    z-index:20
  }

  .sm\:focus\:z-30:focus{
    z-index:30
  }

  .sm\:focus\:z-40:focus{
    z-index:40
  }

  .sm\:focus\:z-50:focus{
    z-index:50
  }

  .sm\:focus\:z-auto:focus{
    z-index:auto
  }

  .sm\:col-auto{
    grid-column:auto
  }

  .sm\:col-span-1{
    grid-column:span 1 / span 1
  }

  .sm\:col-span-2{
    grid-column:span 2 / span 2
  }

  .sm\:col-span-3{
    grid-column:span 3 / span 3
  }

  .sm\:col-span-4{
    grid-column:span 4 / span 4
  }

  .sm\:col-span-5{
    grid-column:span 5 / span 5
  }

  .sm\:col-span-6{
    grid-column:span 6 / span 6
  }

  .sm\:col-span-7{
    grid-column:span 7 / span 7
  }

  .sm\:col-span-8{
    grid-column:span 8 / span 8
  }

  .sm\:col-span-9{
    grid-column:span 9 / span 9
  }

  .sm\:col-span-10{
    grid-column:span 10 / span 10
  }

  .sm\:col-span-11{
    grid-column:span 11 / span 11
  }

  .sm\:col-span-12{
    grid-column:span 12 / span 12
  }

  .sm\:col-span-full{
    grid-column:1 / -1
  }

  .sm\:col-start-1{
    grid-column-start:1
  }

  .sm\:col-start-2{
    grid-column-start:2
  }

  .sm\:col-start-3{
    grid-column-start:3
  }

  .sm\:col-start-4{
    grid-column-start:4
  }

  .sm\:col-start-5{
    grid-column-start:5
  }

  .sm\:col-start-6{
    grid-column-start:6
  }

  .sm\:col-start-7{
    grid-column-start:7
  }

  .sm\:col-start-8{
    grid-column-start:8
  }

  .sm\:col-start-9{
    grid-column-start:9
  }

  .sm\:col-start-10{
    grid-column-start:10
  }

  .sm\:col-start-11{
    grid-column-start:11
  }

  .sm\:col-start-12{
    grid-column-start:12
  }

  .sm\:col-start-13{
    grid-column-start:13
  }

  .sm\:col-start-auto{
    grid-column-start:auto
  }

  .sm\:col-end-1{
    grid-column-end:1
  }

  .sm\:col-end-2{
    grid-column-end:2
  }

  .sm\:col-end-3{
    grid-column-end:3
  }

  .sm\:col-end-4{
    grid-column-end:4
  }

  .sm\:col-end-5{
    grid-column-end:5
  }

  .sm\:col-end-6{
    grid-column-end:6
  }

  .sm\:col-end-7{
    grid-column-end:7
  }

  .sm\:col-end-8{
    grid-column-end:8
  }

  .sm\:col-end-9{
    grid-column-end:9
  }

  .sm\:col-end-10{
    grid-column-end:10
  }

  .sm\:col-end-11{
    grid-column-end:11
  }

  .sm\:col-end-12{
    grid-column-end:12
  }

  .sm\:col-end-13{
    grid-column-end:13
  }

  .sm\:col-end-auto{
    grid-column-end:auto
  }

  .sm\:row-auto{
    grid-row:auto
  }

  .sm\:row-span-1{
    grid-row:span 1 / span 1
  }

  .sm\:row-span-2{
    grid-row:span 2 / span 2
  }

  .sm\:row-span-3{
    grid-row:span 3 / span 3
  }

  .sm\:row-span-4{
    grid-row:span 4 / span 4
  }

  .sm\:row-span-5{
    grid-row:span 5 / span 5
  }

  .sm\:row-span-6{
    grid-row:span 6 / span 6
  }

  .sm\:row-span-full{
    grid-row:1 / -1
  }

  .sm\:row-start-1{
    grid-row-start:1
  }

  .sm\:row-start-2{
    grid-row-start:2
  }

  .sm\:row-start-3{
    grid-row-start:3
  }

  .sm\:row-start-4{
    grid-row-start:4
  }

  .sm\:row-start-5{
    grid-row-start:5
  }

  .sm\:row-start-6{
    grid-row-start:6
  }

  .sm\:row-start-7{
    grid-row-start:7
  }

  .sm\:row-start-auto{
    grid-row-start:auto
  }

  .sm\:row-end-1{
    grid-row-end:1
  }

  .sm\:row-end-2{
    grid-row-end:2
  }

  .sm\:row-end-3{
    grid-row-end:3
  }

  .sm\:row-end-4{
    grid-row-end:4
  }

  .sm\:row-end-5{
    grid-row-end:5
  }

  .sm\:row-end-6{
    grid-row-end:6
  }

  .sm\:row-end-7{
    grid-row-end:7
  }

  .sm\:row-end-auto{
    grid-row-end:auto
  }

  .sm\:m-0{
    margin:0
  }

  .sm\:m-1{
    margin:4px
  }

  .sm\:m-2{
    margin:8px
  }

  .sm\:m-3{
    margin:12px
  }

  .sm\:m-4{
    margin:16px
  }

  .sm\:m-5{
    margin:20px
  }

  .sm\:m-6{
    margin:24px
  }

  .sm\:m-8{
    margin:32px
  }

  .sm\:m-10{
    margin:40px
  }

  .sm\:m-12{
    margin:48px
  }

  .sm\:m-16{
    margin:64px
  }

  .sm\:m-18{
    margin:72px
  }

  .sm\:m-24{
    margin:96px
  }

  .sm\:m-auto{
    margin:auto
  }

  .sm\:m-5dot5{
    margin:22px
  }

  .sm\:m-full{
    margin:100%
  }

  .sm\:-m-1{
    margin:-4px
  }

  .sm\:-m-2{
    margin:-8px
  }

  .sm\:-m-3{
    margin:-12px
  }

  .sm\:-m-4{
    margin:-16px
  }

  .sm\:-m-5{
    margin:-20px
  }

  .sm\:-m-6{
    margin:-24px
  }

  .sm\:-m-8{
    margin:-32px
  }

  .sm\:-m-10{
    margin:-40px
  }

  .sm\:-m-12{
    margin:-48px
  }

  .sm\:-m-16{
    margin:-64px
  }

  .sm\:-m-18{
    margin:-72px
  }

  .sm\:-m-24{
    margin:-96px
  }

  .sm\:-m-5dot5{
    margin:-22px
  }

  .sm\:-m-full{
    margin:-100%
  }

  .sm\:first\:m-0:first-child{
    margin:0
  }

  .sm\:first\:m-1:first-child{
    margin:4px
  }

  .sm\:first\:m-2:first-child{
    margin:8px
  }

  .sm\:first\:m-3:first-child{
    margin:12px
  }

  .sm\:first\:m-4:first-child{
    margin:16px
  }

  .sm\:first\:m-5:first-child{
    margin:20px
  }

  .sm\:first\:m-6:first-child{
    margin:24px
  }

  .sm\:first\:m-8:first-child{
    margin:32px
  }

  .sm\:first\:m-10:first-child{
    margin:40px
  }

  .sm\:first\:m-12:first-child{
    margin:48px
  }

  .sm\:first\:m-16:first-child{
    margin:64px
  }

  .sm\:first\:m-18:first-child{
    margin:72px
  }

  .sm\:first\:m-24:first-child{
    margin:96px
  }

  .sm\:first\:m-auto:first-child{
    margin:auto
  }

  .sm\:first\:m-5dot5:first-child{
    margin:22px
  }

  .sm\:first\:m-full:first-child{
    margin:100%
  }

  .sm\:first\:-m-1:first-child{
    margin:-4px
  }

  .sm\:first\:-m-2:first-child{
    margin:-8px
  }

  .sm\:first\:-m-3:first-child{
    margin:-12px
  }

  .sm\:first\:-m-4:first-child{
    margin:-16px
  }

  .sm\:first\:-m-5:first-child{
    margin:-20px
  }

  .sm\:first\:-m-6:first-child{
    margin:-24px
  }

  .sm\:first\:-m-8:first-child{
    margin:-32px
  }

  .sm\:first\:-m-10:first-child{
    margin:-40px
  }

  .sm\:first\:-m-12:first-child{
    margin:-48px
  }

  .sm\:first\:-m-16:first-child{
    margin:-64px
  }

  .sm\:first\:-m-18:first-child{
    margin:-72px
  }

  .sm\:first\:-m-24:first-child{
    margin:-96px
  }

  .sm\:first\:-m-5dot5:first-child{
    margin:-22px
  }

  .sm\:first\:-m-full:first-child{
    margin:-100%
  }

  .sm\:last\:m-0:last-child{
    margin:0
  }

  .sm\:last\:m-1:last-child{
    margin:4px
  }

  .sm\:last\:m-2:last-child{
    margin:8px
  }

  .sm\:last\:m-3:last-child{
    margin:12px
  }

  .sm\:last\:m-4:last-child{
    margin:16px
  }

  .sm\:last\:m-5:last-child{
    margin:20px
  }

  .sm\:last\:m-6:last-child{
    margin:24px
  }

  .sm\:last\:m-8:last-child{
    margin:32px
  }

  .sm\:last\:m-10:last-child{
    margin:40px
  }

  .sm\:last\:m-12:last-child{
    margin:48px
  }

  .sm\:last\:m-16:last-child{
    margin:64px
  }

  .sm\:last\:m-18:last-child{
    margin:72px
  }

  .sm\:last\:m-24:last-child{
    margin:96px
  }

  .sm\:last\:m-auto:last-child{
    margin:auto
  }

  .sm\:last\:m-5dot5:last-child{
    margin:22px
  }

  .sm\:last\:m-full:last-child{
    margin:100%
  }

  .sm\:last\:-m-1:last-child{
    margin:-4px
  }

  .sm\:last\:-m-2:last-child{
    margin:-8px
  }

  .sm\:last\:-m-3:last-child{
    margin:-12px
  }

  .sm\:last\:-m-4:last-child{
    margin:-16px
  }

  .sm\:last\:-m-5:last-child{
    margin:-20px
  }

  .sm\:last\:-m-6:last-child{
    margin:-24px
  }

  .sm\:last\:-m-8:last-child{
    margin:-32px
  }

  .sm\:last\:-m-10:last-child{
    margin:-40px
  }

  .sm\:last\:-m-12:last-child{
    margin:-48px
  }

  .sm\:last\:-m-16:last-child{
    margin:-64px
  }

  .sm\:last\:-m-18:last-child{
    margin:-72px
  }

  .sm\:last\:-m-24:last-child{
    margin:-96px
  }

  .sm\:last\:-m-5dot5:last-child{
    margin:-22px
  }

  .sm\:last\:-m-full:last-child{
    margin:-100%
  }

  .sm\:mx-0{
    margin-left:0;
    margin-right:0
  }

  .sm\:mx-1{
    margin-left:4px;
    margin-right:4px
  }

  .sm\:mx-2{
    margin-left:8px;
    margin-right:8px
  }

  .sm\:mx-3{
    margin-left:12px;
    margin-right:12px
  }

  .sm\:mx-4{
    margin-left:16px;
    margin-right:16px
  }

  .sm\:mx-5{
    margin-left:20px;
    margin-right:20px
  }

  .sm\:mx-6{
    margin-left:24px;
    margin-right:24px
  }

  .sm\:mx-8{
    margin-left:32px;
    margin-right:32px
  }

  .sm\:mx-10{
    margin-left:40px;
    margin-right:40px
  }

  .sm\:mx-12{
    margin-left:48px;
    margin-right:48px
  }

  .sm\:mx-16{
    margin-left:64px;
    margin-right:64px
  }

  .sm\:mx-18{
    margin-left:72px;
    margin-right:72px
  }

  .sm\:mx-24{
    margin-left:96px;
    margin-right:96px
  }

  .sm\:mx-auto{
    margin-left:auto;
    margin-right:auto
  }

  .sm\:mx-5dot5{
    margin-left:22px;
    margin-right:22px
  }

  .sm\:mx-full{
    margin-left:100%;
    margin-right:100%
  }

  .sm\:-mx-1{
    margin-left:-4px;
    margin-right:-4px
  }

  .sm\:-mx-2{
    margin-left:-8px;
    margin-right:-8px
  }

  .sm\:-mx-3{
    margin-left:-12px;
    margin-right:-12px
  }

  .sm\:-mx-4{
    margin-left:-16px;
    margin-right:-16px
  }

  .sm\:-mx-5{
    margin-left:-20px;
    margin-right:-20px
  }

  .sm\:-mx-6{
    margin-left:-24px;
    margin-right:-24px
  }

  .sm\:-mx-8{
    margin-left:-32px;
    margin-right:-32px
  }

  .sm\:-mx-10{
    margin-left:-40px;
    margin-right:-40px
  }

  .sm\:-mx-12{
    margin-left:-48px;
    margin-right:-48px
  }

  .sm\:-mx-16{
    margin-left:-64px;
    margin-right:-64px
  }

  .sm\:-mx-18{
    margin-left:-72px;
    margin-right:-72px
  }

  .sm\:-mx-24{
    margin-left:-96px;
    margin-right:-96px
  }

  .sm\:-mx-5dot5{
    margin-left:-22px;
    margin-right:-22px
  }

  .sm\:-mx-full{
    margin-left:-100%;
    margin-right:-100%
  }

  .sm\:my-0{
    margin-top:0;
    margin-bottom:0
  }

  .sm\:my-1{
    margin-top:4px;
    margin-bottom:4px
  }

  .sm\:my-2{
    margin-top:8px;
    margin-bottom:8px
  }

  .sm\:my-3{
    margin-top:12px;
    margin-bottom:12px
  }

  .sm\:my-4{
    margin-top:16px;
    margin-bottom:16px
  }

  .sm\:my-5{
    margin-top:20px;
    margin-bottom:20px
  }

  .sm\:my-6{
    margin-top:24px;
    margin-bottom:24px
  }

  .sm\:my-8{
    margin-top:32px;
    margin-bottom:32px
  }

  .sm\:my-10{
    margin-top:40px;
    margin-bottom:40px
  }

  .sm\:my-12{
    margin-top:48px;
    margin-bottom:48px
  }

  .sm\:my-16{
    margin-top:64px;
    margin-bottom:64px
  }

  .sm\:my-18{
    margin-top:72px;
    margin-bottom:72px
  }

  .sm\:my-24{
    margin-top:96px;
    margin-bottom:96px
  }

  .sm\:my-auto{
    margin-top:auto;
    margin-bottom:auto
  }

  .sm\:my-5dot5{
    margin-top:22px;
    margin-bottom:22px
  }

  .sm\:my-full{
    margin-top:100%;
    margin-bottom:100%
  }

  .sm\:-my-1{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .sm\:-my-2{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .sm\:-my-3{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .sm\:-my-4{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .sm\:-my-5{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .sm\:-my-6{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .sm\:-my-8{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .sm\:-my-10{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .sm\:-my-12{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .sm\:-my-16{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .sm\:-my-18{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .sm\:-my-24{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .sm\:-my-5dot5{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .sm\:-my-full{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .sm\:first\:mx-0:first-child{
    margin-left:0;
    margin-right:0
  }

  .sm\:first\:mx-1:first-child{
    margin-left:4px;
    margin-right:4px
  }

  .sm\:first\:mx-2:first-child{
    margin-left:8px;
    margin-right:8px
  }

  .sm\:first\:mx-3:first-child{
    margin-left:12px;
    margin-right:12px
  }

  .sm\:first\:mx-4:first-child{
    margin-left:16px;
    margin-right:16px
  }

  .sm\:first\:mx-5:first-child{
    margin-left:20px;
    margin-right:20px
  }

  .sm\:first\:mx-6:first-child{
    margin-left:24px;
    margin-right:24px
  }

  .sm\:first\:mx-8:first-child{
    margin-left:32px;
    margin-right:32px
  }

  .sm\:first\:mx-10:first-child{
    margin-left:40px;
    margin-right:40px
  }

  .sm\:first\:mx-12:first-child{
    margin-left:48px;
    margin-right:48px
  }

  .sm\:first\:mx-16:first-child{
    margin-left:64px;
    margin-right:64px
  }

  .sm\:first\:mx-18:first-child{
    margin-left:72px;
    margin-right:72px
  }

  .sm\:first\:mx-24:first-child{
    margin-left:96px;
    margin-right:96px
  }

  .sm\:first\:mx-auto:first-child{
    margin-left:auto;
    margin-right:auto
  }

  .sm\:first\:mx-5dot5:first-child{
    margin-left:22px;
    margin-right:22px
  }

  .sm\:first\:mx-full:first-child{
    margin-left:100%;
    margin-right:100%
  }

  .sm\:first\:-mx-1:first-child{
    margin-left:-4px;
    margin-right:-4px
  }

  .sm\:first\:-mx-2:first-child{
    margin-left:-8px;
    margin-right:-8px
  }

  .sm\:first\:-mx-3:first-child{
    margin-left:-12px;
    margin-right:-12px
  }

  .sm\:first\:-mx-4:first-child{
    margin-left:-16px;
    margin-right:-16px
  }

  .sm\:first\:-mx-5:first-child{
    margin-left:-20px;
    margin-right:-20px
  }

  .sm\:first\:-mx-6:first-child{
    margin-left:-24px;
    margin-right:-24px
  }

  .sm\:first\:-mx-8:first-child{
    margin-left:-32px;
    margin-right:-32px
  }

  .sm\:first\:-mx-10:first-child{
    margin-left:-40px;
    margin-right:-40px
  }

  .sm\:first\:-mx-12:first-child{
    margin-left:-48px;
    margin-right:-48px
  }

  .sm\:first\:-mx-16:first-child{
    margin-left:-64px;
    margin-right:-64px
  }

  .sm\:first\:-mx-18:first-child{
    margin-left:-72px;
    margin-right:-72px
  }

  .sm\:first\:-mx-24:first-child{
    margin-left:-96px;
    margin-right:-96px
  }

  .sm\:first\:-mx-5dot5:first-child{
    margin-left:-22px;
    margin-right:-22px
  }

  .sm\:first\:-mx-full:first-child{
    margin-left:-100%;
    margin-right:-100%
  }

  .sm\:first\:my-0:first-child{
    margin-top:0;
    margin-bottom:0
  }

  .sm\:first\:my-1:first-child{
    margin-top:4px;
    margin-bottom:4px
  }

  .sm\:first\:my-2:first-child{
    margin-top:8px;
    margin-bottom:8px
  }

  .sm\:first\:my-3:first-child{
    margin-top:12px;
    margin-bottom:12px
  }

  .sm\:first\:my-4:first-child{
    margin-top:16px;
    margin-bottom:16px
  }

  .sm\:first\:my-5:first-child{
    margin-top:20px;
    margin-bottom:20px
  }

  .sm\:first\:my-6:first-child{
    margin-top:24px;
    margin-bottom:24px
  }

  .sm\:first\:my-8:first-child{
    margin-top:32px;
    margin-bottom:32px
  }

  .sm\:first\:my-10:first-child{
    margin-top:40px;
    margin-bottom:40px
  }

  .sm\:first\:my-12:first-child{
    margin-top:48px;
    margin-bottom:48px
  }

  .sm\:first\:my-16:first-child{
    margin-top:64px;
    margin-bottom:64px
  }

  .sm\:first\:my-18:first-child{
    margin-top:72px;
    margin-bottom:72px
  }

  .sm\:first\:my-24:first-child{
    margin-top:96px;
    margin-bottom:96px
  }

  .sm\:first\:my-auto:first-child{
    margin-top:auto;
    margin-bottom:auto
  }

  .sm\:first\:my-5dot5:first-child{
    margin-top:22px;
    margin-bottom:22px
  }

  .sm\:first\:my-full:first-child{
    margin-top:100%;
    margin-bottom:100%
  }

  .sm\:first\:-my-1:first-child{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .sm\:first\:-my-2:first-child{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .sm\:first\:-my-3:first-child{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .sm\:first\:-my-4:first-child{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .sm\:first\:-my-5:first-child{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .sm\:first\:-my-6:first-child{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .sm\:first\:-my-8:first-child{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .sm\:first\:-my-10:first-child{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .sm\:first\:-my-12:first-child{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .sm\:first\:-my-16:first-child{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .sm\:first\:-my-18:first-child{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .sm\:first\:-my-24:first-child{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .sm\:first\:-my-5dot5:first-child{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .sm\:first\:-my-full:first-child{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .sm\:last\:mx-0:last-child{
    margin-left:0;
    margin-right:0
  }

  .sm\:last\:mx-1:last-child{
    margin-left:4px;
    margin-right:4px
  }

  .sm\:last\:mx-2:last-child{
    margin-left:8px;
    margin-right:8px
  }

  .sm\:last\:mx-3:last-child{
    margin-left:12px;
    margin-right:12px
  }

  .sm\:last\:mx-4:last-child{
    margin-left:16px;
    margin-right:16px
  }

  .sm\:last\:mx-5:last-child{
    margin-left:20px;
    margin-right:20px
  }

  .sm\:last\:mx-6:last-child{
    margin-left:24px;
    margin-right:24px
  }

  .sm\:last\:mx-8:last-child{
    margin-left:32px;
    margin-right:32px
  }

  .sm\:last\:mx-10:last-child{
    margin-left:40px;
    margin-right:40px
  }

  .sm\:last\:mx-12:last-child{
    margin-left:48px;
    margin-right:48px
  }

  .sm\:last\:mx-16:last-child{
    margin-left:64px;
    margin-right:64px
  }

  .sm\:last\:mx-18:last-child{
    margin-left:72px;
    margin-right:72px
  }

  .sm\:last\:mx-24:last-child{
    margin-left:96px;
    margin-right:96px
  }

  .sm\:last\:mx-auto:last-child{
    margin-left:auto;
    margin-right:auto
  }

  .sm\:last\:mx-5dot5:last-child{
    margin-left:22px;
    margin-right:22px
  }

  .sm\:last\:mx-full:last-child{
    margin-left:100%;
    margin-right:100%
  }

  .sm\:last\:-mx-1:last-child{
    margin-left:-4px;
    margin-right:-4px
  }

  .sm\:last\:-mx-2:last-child{
    margin-left:-8px;
    margin-right:-8px
  }

  .sm\:last\:-mx-3:last-child{
    margin-left:-12px;
    margin-right:-12px
  }

  .sm\:last\:-mx-4:last-child{
    margin-left:-16px;
    margin-right:-16px
  }

  .sm\:last\:-mx-5:last-child{
    margin-left:-20px;
    margin-right:-20px
  }

  .sm\:last\:-mx-6:last-child{
    margin-left:-24px;
    margin-right:-24px
  }

  .sm\:last\:-mx-8:last-child{
    margin-left:-32px;
    margin-right:-32px
  }

  .sm\:last\:-mx-10:last-child{
    margin-left:-40px;
    margin-right:-40px
  }

  .sm\:last\:-mx-12:last-child{
    margin-left:-48px;
    margin-right:-48px
  }

  .sm\:last\:-mx-16:last-child{
    margin-left:-64px;
    margin-right:-64px
  }

  .sm\:last\:-mx-18:last-child{
    margin-left:-72px;
    margin-right:-72px
  }

  .sm\:last\:-mx-24:last-child{
    margin-left:-96px;
    margin-right:-96px
  }

  .sm\:last\:-mx-5dot5:last-child{
    margin-left:-22px;
    margin-right:-22px
  }

  .sm\:last\:-mx-full:last-child{
    margin-left:-100%;
    margin-right:-100%
  }

  .sm\:last\:my-0:last-child{
    margin-top:0;
    margin-bottom:0
  }

  .sm\:last\:my-1:last-child{
    margin-top:4px;
    margin-bottom:4px
  }

  .sm\:last\:my-2:last-child{
    margin-top:8px;
    margin-bottom:8px
  }

  .sm\:last\:my-3:last-child{
    margin-top:12px;
    margin-bottom:12px
  }

  .sm\:last\:my-4:last-child{
    margin-top:16px;
    margin-bottom:16px
  }

  .sm\:last\:my-5:last-child{
    margin-top:20px;
    margin-bottom:20px
  }

  .sm\:last\:my-6:last-child{
    margin-top:24px;
    margin-bottom:24px
  }

  .sm\:last\:my-8:last-child{
    margin-top:32px;
    margin-bottom:32px
  }

  .sm\:last\:my-10:last-child{
    margin-top:40px;
    margin-bottom:40px
  }

  .sm\:last\:my-12:last-child{
    margin-top:48px;
    margin-bottom:48px
  }

  .sm\:last\:my-16:last-child{
    margin-top:64px;
    margin-bottom:64px
  }

  .sm\:last\:my-18:last-child{
    margin-top:72px;
    margin-bottom:72px
  }

  .sm\:last\:my-24:last-child{
    margin-top:96px;
    margin-bottom:96px
  }

  .sm\:last\:my-auto:last-child{
    margin-top:auto;
    margin-bottom:auto
  }

  .sm\:last\:my-5dot5:last-child{
    margin-top:22px;
    margin-bottom:22px
  }

  .sm\:last\:my-full:last-child{
    margin-top:100%;
    margin-bottom:100%
  }

  .sm\:last\:-my-1:last-child{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .sm\:last\:-my-2:last-child{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .sm\:last\:-my-3:last-child{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .sm\:last\:-my-4:last-child{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .sm\:last\:-my-5:last-child{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .sm\:last\:-my-6:last-child{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .sm\:last\:-my-8:last-child{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .sm\:last\:-my-10:last-child{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .sm\:last\:-my-12:last-child{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .sm\:last\:-my-16:last-child{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .sm\:last\:-my-18:last-child{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .sm\:last\:-my-24:last-child{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .sm\:last\:-my-5dot5:last-child{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .sm\:last\:-my-full:last-child{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .sm\:mt-0{
    margin-top:0
  }

  .sm\:mt-1{
    margin-top:4px
  }

  .sm\:mt-2{
    margin-top:8px
  }

  .sm\:mt-3{
    margin-top:12px
  }

  .sm\:mt-4{
    margin-top:16px
  }

  .sm\:mt-5{
    margin-top:20px
  }

  .sm\:mt-6{
    margin-top:24px
  }

  .sm\:mt-8{
    margin-top:32px
  }

  .sm\:mt-10{
    margin-top:40px
  }

  .sm\:mt-12{
    margin-top:48px
  }

  .sm\:mt-16{
    margin-top:64px
  }

  .sm\:mt-18{
    margin-top:72px
  }

  .sm\:mt-24{
    margin-top:96px
  }

  .sm\:mt-auto{
    margin-top:auto
  }

  .sm\:mt-5dot5{
    margin-top:22px
  }

  .sm\:mt-full{
    margin-top:100%
  }

  .sm\:-mt-1{
    margin-top:-4px
  }

  .sm\:-mt-2{
    margin-top:-8px
  }

  .sm\:-mt-3{
    margin-top:-12px
  }

  .sm\:-mt-4{
    margin-top:-16px
  }

  .sm\:-mt-5{
    margin-top:-20px
  }

  .sm\:-mt-6{
    margin-top:-24px
  }

  .sm\:-mt-8{
    margin-top:-32px
  }

  .sm\:-mt-10{
    margin-top:-40px
  }

  .sm\:-mt-12{
    margin-top:-48px
  }

  .sm\:-mt-16{
    margin-top:-64px
  }

  .sm\:-mt-18{
    margin-top:-72px
  }

  .sm\:-mt-24{
    margin-top:-96px
  }

  .sm\:-mt-5dot5{
    margin-top:-22px
  }

  .sm\:-mt-full{
    margin-top:-100%
  }

  .sm\:mr-0{
    margin-right:0
  }

  .sm\:mr-1{
    margin-right:4px
  }

  .sm\:mr-2{
    margin-right:8px
  }

  .sm\:mr-3{
    margin-right:12px
  }

  .sm\:mr-4{
    margin-right:16px
  }

  .sm\:mr-5{
    margin-right:20px
  }

  .sm\:mr-6{
    margin-right:24px
  }

  .sm\:mr-8{
    margin-right:32px
  }

  .sm\:mr-10{
    margin-right:40px
  }

  .sm\:mr-12{
    margin-right:48px
  }

  .sm\:mr-16{
    margin-right:64px
  }

  .sm\:mr-18{
    margin-right:72px
  }

  .sm\:mr-24{
    margin-right:96px
  }

  .sm\:mr-auto{
    margin-right:auto
  }

  .sm\:mr-5dot5{
    margin-right:22px
  }

  .sm\:mr-full{
    margin-right:100%
  }

  .sm\:-mr-1{
    margin-right:-4px
  }

  .sm\:-mr-2{
    margin-right:-8px
  }

  .sm\:-mr-3{
    margin-right:-12px
  }

  .sm\:-mr-4{
    margin-right:-16px
  }

  .sm\:-mr-5{
    margin-right:-20px
  }

  .sm\:-mr-6{
    margin-right:-24px
  }

  .sm\:-mr-8{
    margin-right:-32px
  }

  .sm\:-mr-10{
    margin-right:-40px
  }

  .sm\:-mr-12{
    margin-right:-48px
  }

  .sm\:-mr-16{
    margin-right:-64px
  }

  .sm\:-mr-18{
    margin-right:-72px
  }

  .sm\:-mr-24{
    margin-right:-96px
  }

  .sm\:-mr-5dot5{
    margin-right:-22px
  }

  .sm\:-mr-full{
    margin-right:-100%
  }

  .sm\:mb-0{
    margin-bottom:0
  }

  .sm\:mb-1{
    margin-bottom:4px
  }

  .sm\:mb-2{
    margin-bottom:8px
  }

  .sm\:mb-3{
    margin-bottom:12px
  }

  .sm\:mb-4{
    margin-bottom:16px
  }

  .sm\:mb-5{
    margin-bottom:20px
  }

  .sm\:mb-6{
    margin-bottom:24px
  }

  .sm\:mb-8{
    margin-bottom:32px
  }

  .sm\:mb-10{
    margin-bottom:40px
  }

  .sm\:mb-12{
    margin-bottom:48px
  }

  .sm\:mb-16{
    margin-bottom:64px
  }

  .sm\:mb-18{
    margin-bottom:72px
  }

  .sm\:mb-24{
    margin-bottom:96px
  }

  .sm\:mb-auto{
    margin-bottom:auto
  }

  .sm\:mb-5dot5{
    margin-bottom:22px
  }

  .sm\:mb-full{
    margin-bottom:100%
  }

  .sm\:-mb-1{
    margin-bottom:-4px
  }

  .sm\:-mb-2{
    margin-bottom:-8px
  }

  .sm\:-mb-3{
    margin-bottom:-12px
  }

  .sm\:-mb-4{
    margin-bottom:-16px
  }

  .sm\:-mb-5{
    margin-bottom:-20px
  }

  .sm\:-mb-6{
    margin-bottom:-24px
  }

  .sm\:-mb-8{
    margin-bottom:-32px
  }

  .sm\:-mb-10{
    margin-bottom:-40px
  }

  .sm\:-mb-12{
    margin-bottom:-48px
  }

  .sm\:-mb-16{
    margin-bottom:-64px
  }

  .sm\:-mb-18{
    margin-bottom:-72px
  }

  .sm\:-mb-24{
    margin-bottom:-96px
  }

  .sm\:-mb-5dot5{
    margin-bottom:-22px
  }

  .sm\:-mb-full{
    margin-bottom:-100%
  }

  .sm\:ml-0{
    margin-left:0
  }

  .sm\:ml-1{
    margin-left:4px
  }

  .sm\:ml-2{
    margin-left:8px
  }

  .sm\:ml-3{
    margin-left:12px
  }

  .sm\:ml-4{
    margin-left:16px
  }

  .sm\:ml-5{
    margin-left:20px
  }

  .sm\:ml-6{
    margin-left:24px
  }

  .sm\:ml-8{
    margin-left:32px
  }

  .sm\:ml-10{
    margin-left:40px
  }

  .sm\:ml-12{
    margin-left:48px
  }

  .sm\:ml-16{
    margin-left:64px
  }

  .sm\:ml-18{
    margin-left:72px
  }

  .sm\:ml-24{
    margin-left:96px
  }

  .sm\:ml-auto{
    margin-left:auto
  }

  .sm\:ml-5dot5{
    margin-left:22px
  }

  .sm\:ml-full{
    margin-left:100%
  }

  .sm\:-ml-1{
    margin-left:-4px
  }

  .sm\:-ml-2{
    margin-left:-8px
  }

  .sm\:-ml-3{
    margin-left:-12px
  }

  .sm\:-ml-4{
    margin-left:-16px
  }

  .sm\:-ml-5{
    margin-left:-20px
  }

  .sm\:-ml-6{
    margin-left:-24px
  }

  .sm\:-ml-8{
    margin-left:-32px
  }

  .sm\:-ml-10{
    margin-left:-40px
  }

  .sm\:-ml-12{
    margin-left:-48px
  }

  .sm\:-ml-16{
    margin-left:-64px
  }

  .sm\:-ml-18{
    margin-left:-72px
  }

  .sm\:-ml-24{
    margin-left:-96px
  }

  .sm\:-ml-5dot5{
    margin-left:-22px
  }

  .sm\:-ml-full{
    margin-left:-100%
  }

  .sm\:first\:mt-0:first-child{
    margin-top:0
  }

  .sm\:first\:mt-1:first-child{
    margin-top:4px
  }

  .sm\:first\:mt-2:first-child{
    margin-top:8px
  }

  .sm\:first\:mt-3:first-child{
    margin-top:12px
  }

  .sm\:first\:mt-4:first-child{
    margin-top:16px
  }

  .sm\:first\:mt-5:first-child{
    margin-top:20px
  }

  .sm\:first\:mt-6:first-child{
    margin-top:24px
  }

  .sm\:first\:mt-8:first-child{
    margin-top:32px
  }

  .sm\:first\:mt-10:first-child{
    margin-top:40px
  }

  .sm\:first\:mt-12:first-child{
    margin-top:48px
  }

  .sm\:first\:mt-16:first-child{
    margin-top:64px
  }

  .sm\:first\:mt-18:first-child{
    margin-top:72px
  }

  .sm\:first\:mt-24:first-child{
    margin-top:96px
  }

  .sm\:first\:mt-auto:first-child{
    margin-top:auto
  }

  .sm\:first\:mt-5dot5:first-child{
    margin-top:22px
  }

  .sm\:first\:mt-full:first-child{
    margin-top:100%
  }

  .sm\:first\:-mt-1:first-child{
    margin-top:-4px
  }

  .sm\:first\:-mt-2:first-child{
    margin-top:-8px
  }

  .sm\:first\:-mt-3:first-child{
    margin-top:-12px
  }

  .sm\:first\:-mt-4:first-child{
    margin-top:-16px
  }

  .sm\:first\:-mt-5:first-child{
    margin-top:-20px
  }

  .sm\:first\:-mt-6:first-child{
    margin-top:-24px
  }

  .sm\:first\:-mt-8:first-child{
    margin-top:-32px
  }

  .sm\:first\:-mt-10:first-child{
    margin-top:-40px
  }

  .sm\:first\:-mt-12:first-child{
    margin-top:-48px
  }

  .sm\:first\:-mt-16:first-child{
    margin-top:-64px
  }

  .sm\:first\:-mt-18:first-child{
    margin-top:-72px
  }

  .sm\:first\:-mt-24:first-child{
    margin-top:-96px
  }

  .sm\:first\:-mt-5dot5:first-child{
    margin-top:-22px
  }

  .sm\:first\:-mt-full:first-child{
    margin-top:-100%
  }

  .sm\:first\:mr-0:first-child{
    margin-right:0
  }

  .sm\:first\:mr-1:first-child{
    margin-right:4px
  }

  .sm\:first\:mr-2:first-child{
    margin-right:8px
  }

  .sm\:first\:mr-3:first-child{
    margin-right:12px
  }

  .sm\:first\:mr-4:first-child{
    margin-right:16px
  }

  .sm\:first\:mr-5:first-child{
    margin-right:20px
  }

  .sm\:first\:mr-6:first-child{
    margin-right:24px
  }

  .sm\:first\:mr-8:first-child{
    margin-right:32px
  }

  .sm\:first\:mr-10:first-child{
    margin-right:40px
  }

  .sm\:first\:mr-12:first-child{
    margin-right:48px
  }

  .sm\:first\:mr-16:first-child{
    margin-right:64px
  }

  .sm\:first\:mr-18:first-child{
    margin-right:72px
  }

  .sm\:first\:mr-24:first-child{
    margin-right:96px
  }

  .sm\:first\:mr-auto:first-child{
    margin-right:auto
  }

  .sm\:first\:mr-5dot5:first-child{
    margin-right:22px
  }

  .sm\:first\:mr-full:first-child{
    margin-right:100%
  }

  .sm\:first\:-mr-1:first-child{
    margin-right:-4px
  }

  .sm\:first\:-mr-2:first-child{
    margin-right:-8px
  }

  .sm\:first\:-mr-3:first-child{
    margin-right:-12px
  }

  .sm\:first\:-mr-4:first-child{
    margin-right:-16px
  }

  .sm\:first\:-mr-5:first-child{
    margin-right:-20px
  }

  .sm\:first\:-mr-6:first-child{
    margin-right:-24px
  }

  .sm\:first\:-mr-8:first-child{
    margin-right:-32px
  }

  .sm\:first\:-mr-10:first-child{
    margin-right:-40px
  }

  .sm\:first\:-mr-12:first-child{
    margin-right:-48px
  }

  .sm\:first\:-mr-16:first-child{
    margin-right:-64px
  }

  .sm\:first\:-mr-18:first-child{
    margin-right:-72px
  }

  .sm\:first\:-mr-24:first-child{
    margin-right:-96px
  }

  .sm\:first\:-mr-5dot5:first-child{
    margin-right:-22px
  }

  .sm\:first\:-mr-full:first-child{
    margin-right:-100%
  }

  .sm\:first\:mb-0:first-child{
    margin-bottom:0
  }

  .sm\:first\:mb-1:first-child{
    margin-bottom:4px
  }

  .sm\:first\:mb-2:first-child{
    margin-bottom:8px
  }

  .sm\:first\:mb-3:first-child{
    margin-bottom:12px
  }

  .sm\:first\:mb-4:first-child{
    margin-bottom:16px
  }

  .sm\:first\:mb-5:first-child{
    margin-bottom:20px
  }

  .sm\:first\:mb-6:first-child{
    margin-bottom:24px
  }

  .sm\:first\:mb-8:first-child{
    margin-bottom:32px
  }

  .sm\:first\:mb-10:first-child{
    margin-bottom:40px
  }

  .sm\:first\:mb-12:first-child{
    margin-bottom:48px
  }

  .sm\:first\:mb-16:first-child{
    margin-bottom:64px
  }

  .sm\:first\:mb-18:first-child{
    margin-bottom:72px
  }

  .sm\:first\:mb-24:first-child{
    margin-bottom:96px
  }

  .sm\:first\:mb-auto:first-child{
    margin-bottom:auto
  }

  .sm\:first\:mb-5dot5:first-child{
    margin-bottom:22px
  }

  .sm\:first\:mb-full:first-child{
    margin-bottom:100%
  }

  .sm\:first\:-mb-1:first-child{
    margin-bottom:-4px
  }

  .sm\:first\:-mb-2:first-child{
    margin-bottom:-8px
  }

  .sm\:first\:-mb-3:first-child{
    margin-bottom:-12px
  }

  .sm\:first\:-mb-4:first-child{
    margin-bottom:-16px
  }

  .sm\:first\:-mb-5:first-child{
    margin-bottom:-20px
  }

  .sm\:first\:-mb-6:first-child{
    margin-bottom:-24px
  }

  .sm\:first\:-mb-8:first-child{
    margin-bottom:-32px
  }

  .sm\:first\:-mb-10:first-child{
    margin-bottom:-40px
  }

  .sm\:first\:-mb-12:first-child{
    margin-bottom:-48px
  }

  .sm\:first\:-mb-16:first-child{
    margin-bottom:-64px
  }

  .sm\:first\:-mb-18:first-child{
    margin-bottom:-72px
  }

  .sm\:first\:-mb-24:first-child{
    margin-bottom:-96px
  }

  .sm\:first\:-mb-5dot5:first-child{
    margin-bottom:-22px
  }

  .sm\:first\:-mb-full:first-child{
    margin-bottom:-100%
  }

  .sm\:first\:ml-0:first-child{
    margin-left:0
  }

  .sm\:first\:ml-1:first-child{
    margin-left:4px
  }

  .sm\:first\:ml-2:first-child{
    margin-left:8px
  }

  .sm\:first\:ml-3:first-child{
    margin-left:12px
  }

  .sm\:first\:ml-4:first-child{
    margin-left:16px
  }

  .sm\:first\:ml-5:first-child{
    margin-left:20px
  }

  .sm\:first\:ml-6:first-child{
    margin-left:24px
  }

  .sm\:first\:ml-8:first-child{
    margin-left:32px
  }

  .sm\:first\:ml-10:first-child{
    margin-left:40px
  }

  .sm\:first\:ml-12:first-child{
    margin-left:48px
  }

  .sm\:first\:ml-16:first-child{
    margin-left:64px
  }

  .sm\:first\:ml-18:first-child{
    margin-left:72px
  }

  .sm\:first\:ml-24:first-child{
    margin-left:96px
  }

  .sm\:first\:ml-auto:first-child{
    margin-left:auto
  }

  .sm\:first\:ml-5dot5:first-child{
    margin-left:22px
  }

  .sm\:first\:ml-full:first-child{
    margin-left:100%
  }

  .sm\:first\:-ml-1:first-child{
    margin-left:-4px
  }

  .sm\:first\:-ml-2:first-child{
    margin-left:-8px
  }

  .sm\:first\:-ml-3:first-child{
    margin-left:-12px
  }

  .sm\:first\:-ml-4:first-child{
    margin-left:-16px
  }

  .sm\:first\:-ml-5:first-child{
    margin-left:-20px
  }

  .sm\:first\:-ml-6:first-child{
    margin-left:-24px
  }

  .sm\:first\:-ml-8:first-child{
    margin-left:-32px
  }

  .sm\:first\:-ml-10:first-child{
    margin-left:-40px
  }

  .sm\:first\:-ml-12:first-child{
    margin-left:-48px
  }

  .sm\:first\:-ml-16:first-child{
    margin-left:-64px
  }

  .sm\:first\:-ml-18:first-child{
    margin-left:-72px
  }

  .sm\:first\:-ml-24:first-child{
    margin-left:-96px
  }

  .sm\:first\:-ml-5dot5:first-child{
    margin-left:-22px
  }

  .sm\:first\:-ml-full:first-child{
    margin-left:-100%
  }

  .sm\:last\:mt-0:last-child{
    margin-top:0
  }

  .sm\:last\:mt-1:last-child{
    margin-top:4px
  }

  .sm\:last\:mt-2:last-child{
    margin-top:8px
  }

  .sm\:last\:mt-3:last-child{
    margin-top:12px
  }

  .sm\:last\:mt-4:last-child{
    margin-top:16px
  }

  .sm\:last\:mt-5:last-child{
    margin-top:20px
  }

  .sm\:last\:mt-6:last-child{
    margin-top:24px
  }

  .sm\:last\:mt-8:last-child{
    margin-top:32px
  }

  .sm\:last\:mt-10:last-child{
    margin-top:40px
  }

  .sm\:last\:mt-12:last-child{
    margin-top:48px
  }

  .sm\:last\:mt-16:last-child{
    margin-top:64px
  }

  .sm\:last\:mt-18:last-child{
    margin-top:72px
  }

  .sm\:last\:mt-24:last-child{
    margin-top:96px
  }

  .sm\:last\:mt-auto:last-child{
    margin-top:auto
  }

  .sm\:last\:mt-5dot5:last-child{
    margin-top:22px
  }

  .sm\:last\:mt-full:last-child{
    margin-top:100%
  }

  .sm\:last\:-mt-1:last-child{
    margin-top:-4px
  }

  .sm\:last\:-mt-2:last-child{
    margin-top:-8px
  }

  .sm\:last\:-mt-3:last-child{
    margin-top:-12px
  }

  .sm\:last\:-mt-4:last-child{
    margin-top:-16px
  }

  .sm\:last\:-mt-5:last-child{
    margin-top:-20px
  }

  .sm\:last\:-mt-6:last-child{
    margin-top:-24px
  }

  .sm\:last\:-mt-8:last-child{
    margin-top:-32px
  }

  .sm\:last\:-mt-10:last-child{
    margin-top:-40px
  }

  .sm\:last\:-mt-12:last-child{
    margin-top:-48px
  }

  .sm\:last\:-mt-16:last-child{
    margin-top:-64px
  }

  .sm\:last\:-mt-18:last-child{
    margin-top:-72px
  }

  .sm\:last\:-mt-24:last-child{
    margin-top:-96px
  }

  .sm\:last\:-mt-5dot5:last-child{
    margin-top:-22px
  }

  .sm\:last\:-mt-full:last-child{
    margin-top:-100%
  }

  .sm\:last\:mr-0:last-child{
    margin-right:0
  }

  .sm\:last\:mr-1:last-child{
    margin-right:4px
  }

  .sm\:last\:mr-2:last-child{
    margin-right:8px
  }

  .sm\:last\:mr-3:last-child{
    margin-right:12px
  }

  .sm\:last\:mr-4:last-child{
    margin-right:16px
  }

  .sm\:last\:mr-5:last-child{
    margin-right:20px
  }

  .sm\:last\:mr-6:last-child{
    margin-right:24px
  }

  .sm\:last\:mr-8:last-child{
    margin-right:32px
  }

  .sm\:last\:mr-10:last-child{
    margin-right:40px
  }

  .sm\:last\:mr-12:last-child{
    margin-right:48px
  }

  .sm\:last\:mr-16:last-child{
    margin-right:64px
  }

  .sm\:last\:mr-18:last-child{
    margin-right:72px
  }

  .sm\:last\:mr-24:last-child{
    margin-right:96px
  }

  .sm\:last\:mr-auto:last-child{
    margin-right:auto
  }

  .sm\:last\:mr-5dot5:last-child{
    margin-right:22px
  }

  .sm\:last\:mr-full:last-child{
    margin-right:100%
  }

  .sm\:last\:-mr-1:last-child{
    margin-right:-4px
  }

  .sm\:last\:-mr-2:last-child{
    margin-right:-8px
  }

  .sm\:last\:-mr-3:last-child{
    margin-right:-12px
  }

  .sm\:last\:-mr-4:last-child{
    margin-right:-16px
  }

  .sm\:last\:-mr-5:last-child{
    margin-right:-20px
  }

  .sm\:last\:-mr-6:last-child{
    margin-right:-24px
  }

  .sm\:last\:-mr-8:last-child{
    margin-right:-32px
  }

  .sm\:last\:-mr-10:last-child{
    margin-right:-40px
  }

  .sm\:last\:-mr-12:last-child{
    margin-right:-48px
  }

  .sm\:last\:-mr-16:last-child{
    margin-right:-64px
  }

  .sm\:last\:-mr-18:last-child{
    margin-right:-72px
  }

  .sm\:last\:-mr-24:last-child{
    margin-right:-96px
  }

  .sm\:last\:-mr-5dot5:last-child{
    margin-right:-22px
  }

  .sm\:last\:-mr-full:last-child{
    margin-right:-100%
  }

  .sm\:last\:mb-0:last-child{
    margin-bottom:0
  }

  .sm\:last\:mb-1:last-child{
    margin-bottom:4px
  }

  .sm\:last\:mb-2:last-child{
    margin-bottom:8px
  }

  .sm\:last\:mb-3:last-child{
    margin-bottom:12px
  }

  .sm\:last\:mb-4:last-child{
    margin-bottom:16px
  }

  .sm\:last\:mb-5:last-child{
    margin-bottom:20px
  }

  .sm\:last\:mb-6:last-child{
    margin-bottom:24px
  }

  .sm\:last\:mb-8:last-child{
    margin-bottom:32px
  }

  .sm\:last\:mb-10:last-child{
    margin-bottom:40px
  }

  .sm\:last\:mb-12:last-child{
    margin-bottom:48px
  }

  .sm\:last\:mb-16:last-child{
    margin-bottom:64px
  }

  .sm\:last\:mb-18:last-child{
    margin-bottom:72px
  }

  .sm\:last\:mb-24:last-child{
    margin-bottom:96px
  }

  .sm\:last\:mb-auto:last-child{
    margin-bottom:auto
  }

  .sm\:last\:mb-5dot5:last-child{
    margin-bottom:22px
  }

  .sm\:last\:mb-full:last-child{
    margin-bottom:100%
  }

  .sm\:last\:-mb-1:last-child{
    margin-bottom:-4px
  }

  .sm\:last\:-mb-2:last-child{
    margin-bottom:-8px
  }

  .sm\:last\:-mb-3:last-child{
    margin-bottom:-12px
  }

  .sm\:last\:-mb-4:last-child{
    margin-bottom:-16px
  }

  .sm\:last\:-mb-5:last-child{
    margin-bottom:-20px
  }

  .sm\:last\:-mb-6:last-child{
    margin-bottom:-24px
  }

  .sm\:last\:-mb-8:last-child{
    margin-bottom:-32px
  }

  .sm\:last\:-mb-10:last-child{
    margin-bottom:-40px
  }

  .sm\:last\:-mb-12:last-child{
    margin-bottom:-48px
  }

  .sm\:last\:-mb-16:last-child{
    margin-bottom:-64px
  }

  .sm\:last\:-mb-18:last-child{
    margin-bottom:-72px
  }

  .sm\:last\:-mb-24:last-child{
    margin-bottom:-96px
  }

  .sm\:last\:-mb-5dot5:last-child{
    margin-bottom:-22px
  }

  .sm\:last\:-mb-full:last-child{
    margin-bottom:-100%
  }

  .sm\:last\:ml-0:last-child{
    margin-left:0
  }

  .sm\:last\:ml-1:last-child{
    margin-left:4px
  }

  .sm\:last\:ml-2:last-child{
    margin-left:8px
  }

  .sm\:last\:ml-3:last-child{
    margin-left:12px
  }

  .sm\:last\:ml-4:last-child{
    margin-left:16px
  }

  .sm\:last\:ml-5:last-child{
    margin-left:20px
  }

  .sm\:last\:ml-6:last-child{
    margin-left:24px
  }

  .sm\:last\:ml-8:last-child{
    margin-left:32px
  }

  .sm\:last\:ml-10:last-child{
    margin-left:40px
  }

  .sm\:last\:ml-12:last-child{
    margin-left:48px
  }

  .sm\:last\:ml-16:last-child{
    margin-left:64px
  }

  .sm\:last\:ml-18:last-child{
    margin-left:72px
  }

  .sm\:last\:ml-24:last-child{
    margin-left:96px
  }

  .sm\:last\:ml-auto:last-child{
    margin-left:auto
  }

  .sm\:last\:ml-5dot5:last-child{
    margin-left:22px
  }

  .sm\:last\:ml-full:last-child{
    margin-left:100%
  }

  .sm\:last\:-ml-1:last-child{
    margin-left:-4px
  }

  .sm\:last\:-ml-2:last-child{
    margin-left:-8px
  }

  .sm\:last\:-ml-3:last-child{
    margin-left:-12px
  }

  .sm\:last\:-ml-4:last-child{
    margin-left:-16px
  }

  .sm\:last\:-ml-5:last-child{
    margin-left:-20px
  }

  .sm\:last\:-ml-6:last-child{
    margin-left:-24px
  }

  .sm\:last\:-ml-8:last-child{
    margin-left:-32px
  }

  .sm\:last\:-ml-10:last-child{
    margin-left:-40px
  }

  .sm\:last\:-ml-12:last-child{
    margin-left:-48px
  }

  .sm\:last\:-ml-16:last-child{
    margin-left:-64px
  }

  .sm\:last\:-ml-18:last-child{
    margin-left:-72px
  }

  .sm\:last\:-ml-24:last-child{
    margin-left:-96px
  }

  .sm\:last\:-ml-5dot5:last-child{
    margin-left:-22px
  }

  .sm\:last\:-ml-full:last-child{
    margin-left:-100%
  }

  .sm\:block{
    display:block
  }

  .sm\:inline-block{
    display:inline-block
  }

  .sm\:inline{
    display:inline
  }

  .sm\:flex{
    display:flex
  }

  .sm\:inline-flex{
    display:inline-flex
  }

  .sm\:table{
    display:table
  }

  .sm\:inline-table{
    display:inline-table
  }

  .sm\:table-caption{
    display:table-caption
  }

  .sm\:table-cell{
    display:table-cell
  }

  .sm\:table-column{
    display:table-column
  }

  .sm\:table-column-group{
    display:table-column-group
  }

  .sm\:table-footer-group{
    display:table-footer-group
  }

  .sm\:table-header-group{
    display:table-header-group
  }

  .sm\:table-row-group{
    display:table-row-group
  }

  .sm\:table-row{
    display:table-row
  }

  .sm\:flow-root{
    display:flow-root
  }

  .sm\:grid{
    display:grid
  }

  .sm\:inline-grid{
    display:inline-grid
  }

  .sm\:contents{
    display:contents
  }

  .sm\:list-item{
    display:list-item
  }

  .sm\:hidden{
    display:none
  }

  .sm\:h-0{
    height:0
  }

  .sm\:h-1{
    height:4px
  }

  .sm\:h-2{
    height:8px
  }

  .sm\:h-3{
    height:12px
  }

  .sm\:h-4{
    height:16px
  }

  .sm\:h-5{
    height:20px
  }

  .sm\:h-6{
    height:24px
  }

  .sm\:h-8{
    height:32px
  }

  .sm\:h-10{
    height:40px
  }

  .sm\:h-12{
    height:48px
  }

  .sm\:h-15{
    height:60px
  }

  .sm\:h-16{
    height:64px
  }

  .sm\:h-18{
    height:72px
  }

  .sm\:h-24{
    height:96px
  }

  .sm\:h-auto{
    height:auto
  }

  .sm\:h-5dot5{
    height:22px
  }

  .sm\:h-full{
    height:100%
  }

  .sm\:h-1\/2{
    height:50%
  }

  .sm\:h-1\/3{
    height:33.333333%
  }

  .sm\:h-2\/3{
    height:66.666667%
  }

  .sm\:h-1\/4{
    height:25%
  }

  .sm\:h-2\/4{
    height:50%
  }

  .sm\:h-3\/4{
    height:75%
  }

  .sm\:h-1\/5{
    height:20%
  }

  .sm\:h-2\/5{
    height:40%
  }

  .sm\:h-3\/5{
    height:60%
  }

  .sm\:h-4\/5{
    height:80%
  }

  .sm\:h-1\/6{
    height:16.666667%
  }

  .sm\:h-2\/6{
    height:33.333333%
  }

  .sm\:h-3\/6{
    height:50%
  }

  .sm\:h-4\/6{
    height:66.666667%
  }

  .sm\:h-5\/6{
    height:83.333333%
  }

  .sm\:h-screen{
    height:100vh
  }

  .sm\:max-h-0{
    max-height:0
  }

  .sm\:max-h-1{
    max-height:4px
  }

  .sm\:max-h-2{
    max-height:8px
  }

  .sm\:max-h-3{
    max-height:12px
  }

  .sm\:max-h-4{
    max-height:16px
  }

  .sm\:max-h-5{
    max-height:20px
  }

  .sm\:max-h-6{
    max-height:24px
  }

  .sm\:max-h-8{
    max-height:32px
  }

  .sm\:max-h-10{
    max-height:40px
  }

  .sm\:max-h-12{
    max-height:48px
  }

  .sm\:max-h-16{
    max-height:64px
  }

  .sm\:max-h-18{
    max-height:72px
  }

  .sm\:max-h-24{
    max-height:96px
  }

  .sm\:max-h-5dot5{
    max-height:22px
  }

  .sm\:max-h-full{
    max-height:100%
  }

  .sm\:max-h-screen{
    max-height:100vh
  }

  .sm\:min-h-0{
    min-height:0px
  }

  .sm\:min-h-6{
    min-height:24px
  }

  .sm\:min-h-10{
    min-height:40px
  }

  .sm\:min-h-15{
    min-height:60px
  }

  .sm\:min-h-100{
    min-height:400px
  }

  .sm\:min-h-full{
    min-height:100%
  }

  .sm\:min-h-screen{
    min-height:100vh
  }

  .sm\:min-h-100vh{
    min-height:100vh
  }

  .sm\:w-0{
    width:0
  }

  .sm\:w-1{
    width:4px
  }

  .sm\:w-2{
    width:8px
  }

  .sm\:w-3{
    width:12px
  }

  .sm\:w-4{
    width:16px
  }

  .sm\:w-5{
    width:20px
  }

  .sm\:w-6{
    width:24px
  }

  .sm\:w-8{
    width:32px
  }

  .sm\:w-10{
    width:40px
  }

  .sm\:w-12{
    width:48px
  }

  .sm\:w-16{
    width:64px
  }

  .sm\:w-18{
    width:72px
  }

  .sm\:w-24{
    width:96px
  }

  .sm\:w-25{
    width:100px
  }

  .sm\:w-26{
    width:104px
  }

  .sm\:w-30{
    width:120px
  }

  .sm\:w-80{
    width:320px
  }

  .sm\:w-125{
    width:500px
  }

  .sm\:w-160{
    width:640px
  }

  .sm\:w-180{
    width:720px
  }

  .sm\:w-200{
    width:800px
  }

  .sm\:w-240{
    width:960px
  }

  .sm\:w-320{
    width:1280px
  }

  .sm\:w-auto{
    width:auto
  }

  .sm\:w-5dot5{
    width:22px
  }

  .sm\:w-full{
    width:100%
  }

  .sm\:w-1\/2{
    width:50%
  }

  .sm\:w-1\/3{
    width:33.333333%
  }

  .sm\:w-2\/3{
    width:66.666667%
  }

  .sm\:w-1\/4{
    width:25%
  }

  .sm\:w-2\/4{
    width:50%
  }

  .sm\:w-3\/4{
    width:75%
  }

  .sm\:w-1\/5{
    width:20%
  }

  .sm\:w-2\/5{
    width:40%
  }

  .sm\:w-3\/5{
    width:60%
  }

  .sm\:w-4\/5{
    width:80%
  }

  .sm\:w-1\/6{
    width:16.666667%
  }

  .sm\:w-2\/6{
    width:33.333333%
  }

  .sm\:w-3\/6{
    width:50%
  }

  .sm\:w-4\/6{
    width:66.666667%
  }

  .sm\:w-5\/6{
    width:83.333333%
  }

  .sm\:w-1\/12{
    width:8.333333%
  }

  .sm\:w-2\/12{
    width:16.666667%
  }

  .sm\:w-3\/12{
    width:25%
  }

  .sm\:w-4\/12{
    width:33.333333%
  }

  .sm\:w-5\/12{
    width:41.666667%
  }

  .sm\:w-6\/12{
    width:50%
  }

  .sm\:w-7\/12{
    width:58.333333%
  }

  .sm\:w-8\/12{
    width:66.666667%
  }

  .sm\:w-9\/12{
    width:75%
  }

  .sm\:w-10\/12{
    width:83.333333%
  }

  .sm\:w-11\/12{
    width:91.666667%
  }

  .sm\:w-screen{
    width:100vw
  }

  .sm\:w-min{
    width:min-content
  }

  .sm\:w-max{
    width:max-content
  }

  .sm\:min-w-0{
    min-width:0px
  }

  .sm\:min-w-full{
    min-width:100%
  }

  .sm\:min-w-min{
    min-width:min-content
  }

  .sm\:min-w-max{
    min-width:max-content
  }

  .sm\:max-w-0{
    max-width:0rem
  }

  .sm\:max-w-none{
    max-width:none
  }

  .sm\:max-w-xs{
    max-width:20rem
  }

  .sm\:max-w-sm{
    max-width:24rem
  }

  .sm\:max-w-md{
    max-width:28rem
  }

  .sm\:max-w-lg{
    max-width:32rem
  }

  .sm\:max-w-xl{
    max-width:36rem
  }

  .sm\:max-w-2xl{
    max-width:42rem
  }

  .sm\:max-w-3xl{
    max-width:48rem
  }

  .sm\:max-w-4xl{
    max-width:56rem
  }

  .sm\:max-w-5xl{
    max-width:64rem
  }

  .sm\:max-w-6xl{
    max-width:72rem
  }

  .sm\:max-w-7xl{
    max-width:80rem
  }

  .sm\:max-w-full{
    max-width:100%
  }

  .sm\:max-w-min{
    max-width:min-content
  }

  .sm\:max-w-max{
    max-width:max-content
  }

  .sm\:max-w-prose{
    max-width:65ch
  }

  .sm\:max-w-grid-12{
    max-width:1176px
  }

  .sm\:max-w-grid-10{
    max-width:984px
  }

  .sm\:max-w-grid-8{
    max-width:768px
  }

  .sm\:max-w-1\/4{
    max-width:25%
  }

  .sm\:max-w-1\/2{
    max-width:50%
  }

  .sm\:max-w-3\/4{
    max-width:75%
  }

  .sm\:max-w-1\/5{
    max-width:20%
  }

  .sm\:max-w-2\/5{
    max-width:40%
  }

  .sm\:max-w-3\/5{
    max-width:60%
  }

  .sm\:max-w-4\/5{
    max-width:80%
  }

  .sm\:max-w-100vw{
    max-width:100vw
  }

  .sm\:flex-1{
    flex:1 1 0%
  }

  .sm\:flex-2{
    flex:2 1 0%
  }

  .sm\:flex-3{
    flex:3 1 0%
  }

  .sm\:flex-auto{
    flex:1 1 auto
  }

  .sm\:flex-initial{
    flex:0 1 auto
  }

  .sm\:flex-none{
    flex:none
  }

  .sm\:flex-shrink-0{
    flex-shrink:0
  }

  .sm\:flex-shrink{
    flex-shrink:1
  }

  .sm\:flex-grow-0{
    flex-grow:0
  }

  .sm\:flex-grow{
    flex-grow:1
  }

  .sm\:border-collapse{
    border-collapse:collapse
  }

  .sm\:border-separate{
    border-collapse:separate
  }

  .sm\:transform{
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .sm\:transform-gpu{
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    transform:translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .sm\:transform-none{
    transform:none
  }

  .sm\:translate-x-0{
    --tw-translate-x:0
  }

  .sm\:translate-x-1{
    --tw-translate-x:4px
  }

  .sm\:translate-x-2{
    --tw-translate-x:8px
  }

  .sm\:translate-x-3{
    --tw-translate-x:12px
  }

  .sm\:translate-x-4{
    --tw-translate-x:16px
  }

  .sm\:translate-x-5{
    --tw-translate-x:20px
  }

  .sm\:translate-x-6{
    --tw-translate-x:24px
  }

  .sm\:translate-x-8{
    --tw-translate-x:32px
  }

  .sm\:translate-x-10{
    --tw-translate-x:40px
  }

  .sm\:translate-x-12{
    --tw-translate-x:48px
  }

  .sm\:translate-x-16{
    --tw-translate-x:64px
  }

  .sm\:translate-x-18{
    --tw-translate-x:72px
  }

  .sm\:translate-x-24{
    --tw-translate-x:96px
  }

  .sm\:translate-x-5dot5{
    --tw-translate-x:22px
  }

  .sm\:translate-x-full{
    --tw-translate-x:100%
  }

  .sm\:-translate-x-1{
    --tw-translate-x:-4px
  }

  .sm\:-translate-x-2{
    --tw-translate-x:-8px
  }

  .sm\:-translate-x-3{
    --tw-translate-x:-12px
  }

  .sm\:-translate-x-4{
    --tw-translate-x:-16px
  }

  .sm\:-translate-x-5{
    --tw-translate-x:-20px
  }

  .sm\:-translate-x-6{
    --tw-translate-x:-24px
  }

  .sm\:-translate-x-8{
    --tw-translate-x:-32px
  }

  .sm\:-translate-x-10{
    --tw-translate-x:-40px
  }

  .sm\:-translate-x-12{
    --tw-translate-x:-48px
  }

  .sm\:-translate-x-16{
    --tw-translate-x:-64px
  }

  .sm\:-translate-x-18{
    --tw-translate-x:-72px
  }

  .sm\:-translate-x-24{
    --tw-translate-x:-96px
  }

  .sm\:-translate-x-5dot5{
    --tw-translate-x:-22px
  }

  .sm\:-translate-x-full{
    --tw-translate-x:-100%
  }

  .sm\:translate-x-1\/2{
    --tw-translate-x:50%
  }

  .sm\:translate-x-1\/3{
    --tw-translate-x:33.333333%
  }

  .sm\:translate-x-2\/3{
    --tw-translate-x:66.666667%
  }

  .sm\:translate-x-1\/4{
    --tw-translate-x:25%
  }

  .sm\:translate-x-2\/4{
    --tw-translate-x:50%
  }

  .sm\:translate-x-3\/4{
    --tw-translate-x:75%
  }

  .sm\:-translate-x-1\/2{
    --tw-translate-x:-50%
  }

  .sm\:-translate-x-1\/3{
    --tw-translate-x:-33.333333%
  }

  .sm\:-translate-x-2\/3{
    --tw-translate-x:-66.666667%
  }

  .sm\:-translate-x-1\/4{
    --tw-translate-x:-25%
  }

  .sm\:-translate-x-2\/4{
    --tw-translate-x:-50%
  }

  .sm\:-translate-x-3\/4{
    --tw-translate-x:-75%
  }

  .sm\:translate-y-0{
    --tw-translate-y:0
  }

  .sm\:translate-y-1{
    --tw-translate-y:4px
  }

  .sm\:translate-y-2{
    --tw-translate-y:8px
  }

  .sm\:translate-y-3{
    --tw-translate-y:12px
  }

  .sm\:translate-y-4{
    --tw-translate-y:16px
  }

  .sm\:translate-y-5{
    --tw-translate-y:20px
  }

  .sm\:translate-y-6{
    --tw-translate-y:24px
  }

  .sm\:translate-y-8{
    --tw-translate-y:32px
  }

  .sm\:translate-y-10{
    --tw-translate-y:40px
  }

  .sm\:translate-y-12{
    --tw-translate-y:48px
  }

  .sm\:translate-y-16{
    --tw-translate-y:64px
  }

  .sm\:translate-y-18{
    --tw-translate-y:72px
  }

  .sm\:translate-y-24{
    --tw-translate-y:96px
  }

  .sm\:translate-y-5dot5{
    --tw-translate-y:22px
  }

  .sm\:translate-y-full{
    --tw-translate-y:100%
  }

  .sm\:-translate-y-1{
    --tw-translate-y:-4px
  }

  .sm\:-translate-y-2{
    --tw-translate-y:-8px
  }

  .sm\:-translate-y-3{
    --tw-translate-y:-12px
  }

  .sm\:-translate-y-4{
    --tw-translate-y:-16px
  }

  .sm\:-translate-y-5{
    --tw-translate-y:-20px
  }

  .sm\:-translate-y-6{
    --tw-translate-y:-24px
  }

  .sm\:-translate-y-8{
    --tw-translate-y:-32px
  }

  .sm\:-translate-y-10{
    --tw-translate-y:-40px
  }

  .sm\:-translate-y-12{
    --tw-translate-y:-48px
  }

  .sm\:-translate-y-16{
    --tw-translate-y:-64px
  }

  .sm\:-translate-y-18{
    --tw-translate-y:-72px
  }

  .sm\:-translate-y-24{
    --tw-translate-y:-96px
  }

  .sm\:-translate-y-5dot5{
    --tw-translate-y:-22px
  }

  .sm\:-translate-y-full{
    --tw-translate-y:-100%
  }

  .sm\:translate-y-1\/2{
    --tw-translate-y:50%
  }

  .sm\:translate-y-1\/3{
    --tw-translate-y:33.333333%
  }

  .sm\:translate-y-2\/3{
    --tw-translate-y:66.666667%
  }

  .sm\:translate-y-1\/4{
    --tw-translate-y:25%
  }

  .sm\:translate-y-2\/4{
    --tw-translate-y:50%
  }

  .sm\:translate-y-3\/4{
    --tw-translate-y:75%
  }

  .sm\:-translate-y-1\/2{
    --tw-translate-y:-50%
  }

  .sm\:-translate-y-1\/3{
    --tw-translate-y:-33.333333%
  }

  .sm\:-translate-y-2\/3{
    --tw-translate-y:-66.666667%
  }

  .sm\:-translate-y-1\/4{
    --tw-translate-y:-25%
  }

  .sm\:-translate-y-2\/4{
    --tw-translate-y:-50%
  }

  .sm\:-translate-y-3\/4{
    --tw-translate-y:-75%
  }

  .sm\:hover\:translate-x-0:hover{
    --tw-translate-x:0
  }

  .sm\:hover\:translate-x-1:hover{
    --tw-translate-x:4px
  }

  .sm\:hover\:translate-x-2:hover{
    --tw-translate-x:8px
  }

  .sm\:hover\:translate-x-3:hover{
    --tw-translate-x:12px
  }

  .sm\:hover\:translate-x-4:hover{
    --tw-translate-x:16px
  }

  .sm\:hover\:translate-x-5:hover{
    --tw-translate-x:20px
  }

  .sm\:hover\:translate-x-6:hover{
    --tw-translate-x:24px
  }

  .sm\:hover\:translate-x-8:hover{
    --tw-translate-x:32px
  }

  .sm\:hover\:translate-x-10:hover{
    --tw-translate-x:40px
  }

  .sm\:hover\:translate-x-12:hover{
    --tw-translate-x:48px
  }

  .sm\:hover\:translate-x-16:hover{
    --tw-translate-x:64px
  }

  .sm\:hover\:translate-x-18:hover{
    --tw-translate-x:72px
  }

  .sm\:hover\:translate-x-24:hover{
    --tw-translate-x:96px
  }

  .sm\:hover\:translate-x-5dot5:hover{
    --tw-translate-x:22px
  }

  .sm\:hover\:translate-x-full:hover{
    --tw-translate-x:100%
  }

  .sm\:hover\:-translate-x-1:hover{
    --tw-translate-x:-4px
  }

  .sm\:hover\:-translate-x-2:hover{
    --tw-translate-x:-8px
  }

  .sm\:hover\:-translate-x-3:hover{
    --tw-translate-x:-12px
  }

  .sm\:hover\:-translate-x-4:hover{
    --tw-translate-x:-16px
  }

  .sm\:hover\:-translate-x-5:hover{
    --tw-translate-x:-20px
  }

  .sm\:hover\:-translate-x-6:hover{
    --tw-translate-x:-24px
  }

  .sm\:hover\:-translate-x-8:hover{
    --tw-translate-x:-32px
  }

  .sm\:hover\:-translate-x-10:hover{
    --tw-translate-x:-40px
  }

  .sm\:hover\:-translate-x-12:hover{
    --tw-translate-x:-48px
  }

  .sm\:hover\:-translate-x-16:hover{
    --tw-translate-x:-64px
  }

  .sm\:hover\:-translate-x-18:hover{
    --tw-translate-x:-72px
  }

  .sm\:hover\:-translate-x-24:hover{
    --tw-translate-x:-96px
  }

  .sm\:hover\:-translate-x-5dot5:hover{
    --tw-translate-x:-22px
  }

  .sm\:hover\:-translate-x-full:hover{
    --tw-translate-x:-100%
  }

  .sm\:hover\:translate-x-1\/2:hover{
    --tw-translate-x:50%
  }

  .sm\:hover\:translate-x-1\/3:hover{
    --tw-translate-x:33.333333%
  }

  .sm\:hover\:translate-x-2\/3:hover{
    --tw-translate-x:66.666667%
  }

  .sm\:hover\:translate-x-1\/4:hover{
    --tw-translate-x:25%
  }

  .sm\:hover\:translate-x-2\/4:hover{
    --tw-translate-x:50%
  }

  .sm\:hover\:translate-x-3\/4:hover{
    --tw-translate-x:75%
  }

  .sm\:hover\:-translate-x-1\/2:hover{
    --tw-translate-x:-50%
  }

  .sm\:hover\:-translate-x-1\/3:hover{
    --tw-translate-x:-33.333333%
  }

  .sm\:hover\:-translate-x-2\/3:hover{
    --tw-translate-x:-66.666667%
  }

  .sm\:hover\:-translate-x-1\/4:hover{
    --tw-translate-x:-25%
  }

  .sm\:hover\:-translate-x-2\/4:hover{
    --tw-translate-x:-50%
  }

  .sm\:hover\:-translate-x-3\/4:hover{
    --tw-translate-x:-75%
  }

  .sm\:hover\:translate-y-0:hover{
    --tw-translate-y:0
  }

  .sm\:hover\:translate-y-1:hover{
    --tw-translate-y:4px
  }

  .sm\:hover\:translate-y-2:hover{
    --tw-translate-y:8px
  }

  .sm\:hover\:translate-y-3:hover{
    --tw-translate-y:12px
  }

  .sm\:hover\:translate-y-4:hover{
    --tw-translate-y:16px
  }

  .sm\:hover\:translate-y-5:hover{
    --tw-translate-y:20px
  }

  .sm\:hover\:translate-y-6:hover{
    --tw-translate-y:24px
  }

  .sm\:hover\:translate-y-8:hover{
    --tw-translate-y:32px
  }

  .sm\:hover\:translate-y-10:hover{
    --tw-translate-y:40px
  }

  .sm\:hover\:translate-y-12:hover{
    --tw-translate-y:48px
  }

  .sm\:hover\:translate-y-16:hover{
    --tw-translate-y:64px
  }

  .sm\:hover\:translate-y-18:hover{
    --tw-translate-y:72px
  }

  .sm\:hover\:translate-y-24:hover{
    --tw-translate-y:96px
  }

  .sm\:hover\:translate-y-5dot5:hover{
    --tw-translate-y:22px
  }

  .sm\:hover\:translate-y-full:hover{
    --tw-translate-y:100%
  }

  .sm\:hover\:-translate-y-1:hover{
    --tw-translate-y:-4px
  }

  .sm\:hover\:-translate-y-2:hover{
    --tw-translate-y:-8px
  }

  .sm\:hover\:-translate-y-3:hover{
    --tw-translate-y:-12px
  }

  .sm\:hover\:-translate-y-4:hover{
    --tw-translate-y:-16px
  }

  .sm\:hover\:-translate-y-5:hover{
    --tw-translate-y:-20px
  }

  .sm\:hover\:-translate-y-6:hover{
    --tw-translate-y:-24px
  }

  .sm\:hover\:-translate-y-8:hover{
    --tw-translate-y:-32px
  }

  .sm\:hover\:-translate-y-10:hover{
    --tw-translate-y:-40px
  }

  .sm\:hover\:-translate-y-12:hover{
    --tw-translate-y:-48px
  }

  .sm\:hover\:-translate-y-16:hover{
    --tw-translate-y:-64px
  }

  .sm\:hover\:-translate-y-18:hover{
    --tw-translate-y:-72px
  }

  .sm\:hover\:-translate-y-24:hover{
    --tw-translate-y:-96px
  }

  .sm\:hover\:-translate-y-5dot5:hover{
    --tw-translate-y:-22px
  }

  .sm\:hover\:-translate-y-full:hover{
    --tw-translate-y:-100%
  }

  .sm\:hover\:translate-y-1\/2:hover{
    --tw-translate-y:50%
  }

  .sm\:hover\:translate-y-1\/3:hover{
    --tw-translate-y:33.333333%
  }

  .sm\:hover\:translate-y-2\/3:hover{
    --tw-translate-y:66.666667%
  }

  .sm\:hover\:translate-y-1\/4:hover{
    --tw-translate-y:25%
  }

  .sm\:hover\:translate-y-2\/4:hover{
    --tw-translate-y:50%
  }

  .sm\:hover\:translate-y-3\/4:hover{
    --tw-translate-y:75%
  }

  .sm\:hover\:-translate-y-1\/2:hover{
    --tw-translate-y:-50%
  }

  .sm\:hover\:-translate-y-1\/3:hover{
    --tw-translate-y:-33.333333%
  }

  .sm\:hover\:-translate-y-2\/3:hover{
    --tw-translate-y:-66.666667%
  }

  .sm\:hover\:-translate-y-1\/4:hover{
    --tw-translate-y:-25%
  }

  .sm\:hover\:-translate-y-2\/4:hover{
    --tw-translate-y:-50%
  }

  .sm\:hover\:-translate-y-3\/4:hover{
    --tw-translate-y:-75%
  }

  .sm\:focus\:translate-x-0:focus{
    --tw-translate-x:0
  }

  .sm\:focus\:translate-x-1:focus{
    --tw-translate-x:4px
  }

  .sm\:focus\:translate-x-2:focus{
    --tw-translate-x:8px
  }

  .sm\:focus\:translate-x-3:focus{
    --tw-translate-x:12px
  }

  .sm\:focus\:translate-x-4:focus{
    --tw-translate-x:16px
  }

  .sm\:focus\:translate-x-5:focus{
    --tw-translate-x:20px
  }

  .sm\:focus\:translate-x-6:focus{
    --tw-translate-x:24px
  }

  .sm\:focus\:translate-x-8:focus{
    --tw-translate-x:32px
  }

  .sm\:focus\:translate-x-10:focus{
    --tw-translate-x:40px
  }

  .sm\:focus\:translate-x-12:focus{
    --tw-translate-x:48px
  }

  .sm\:focus\:translate-x-16:focus{
    --tw-translate-x:64px
  }

  .sm\:focus\:translate-x-18:focus{
    --tw-translate-x:72px
  }

  .sm\:focus\:translate-x-24:focus{
    --tw-translate-x:96px
  }

  .sm\:focus\:translate-x-5dot5:focus{
    --tw-translate-x:22px
  }

  .sm\:focus\:translate-x-full:focus{
    --tw-translate-x:100%
  }

  .sm\:focus\:-translate-x-1:focus{
    --tw-translate-x:-4px
  }

  .sm\:focus\:-translate-x-2:focus{
    --tw-translate-x:-8px
  }

  .sm\:focus\:-translate-x-3:focus{
    --tw-translate-x:-12px
  }

  .sm\:focus\:-translate-x-4:focus{
    --tw-translate-x:-16px
  }

  .sm\:focus\:-translate-x-5:focus{
    --tw-translate-x:-20px
  }

  .sm\:focus\:-translate-x-6:focus{
    --tw-translate-x:-24px
  }

  .sm\:focus\:-translate-x-8:focus{
    --tw-translate-x:-32px
  }

  .sm\:focus\:-translate-x-10:focus{
    --tw-translate-x:-40px
  }

  .sm\:focus\:-translate-x-12:focus{
    --tw-translate-x:-48px
  }

  .sm\:focus\:-translate-x-16:focus{
    --tw-translate-x:-64px
  }

  .sm\:focus\:-translate-x-18:focus{
    --tw-translate-x:-72px
  }

  .sm\:focus\:-translate-x-24:focus{
    --tw-translate-x:-96px
  }

  .sm\:focus\:-translate-x-5dot5:focus{
    --tw-translate-x:-22px
  }

  .sm\:focus\:-translate-x-full:focus{
    --tw-translate-x:-100%
  }

  .sm\:focus\:translate-x-1\/2:focus{
    --tw-translate-x:50%
  }

  .sm\:focus\:translate-x-1\/3:focus{
    --tw-translate-x:33.333333%
  }

  .sm\:focus\:translate-x-2\/3:focus{
    --tw-translate-x:66.666667%
  }

  .sm\:focus\:translate-x-1\/4:focus{
    --tw-translate-x:25%
  }

  .sm\:focus\:translate-x-2\/4:focus{
    --tw-translate-x:50%
  }

  .sm\:focus\:translate-x-3\/4:focus{
    --tw-translate-x:75%
  }

  .sm\:focus\:-translate-x-1\/2:focus{
    --tw-translate-x:-50%
  }

  .sm\:focus\:-translate-x-1\/3:focus{
    --tw-translate-x:-33.333333%
  }

  .sm\:focus\:-translate-x-2\/3:focus{
    --tw-translate-x:-66.666667%
  }

  .sm\:focus\:-translate-x-1\/4:focus{
    --tw-translate-x:-25%
  }

  .sm\:focus\:-translate-x-2\/4:focus{
    --tw-translate-x:-50%
  }

  .sm\:focus\:-translate-x-3\/4:focus{
    --tw-translate-x:-75%
  }

  .sm\:focus\:translate-y-0:focus{
    --tw-translate-y:0
  }

  .sm\:focus\:translate-y-1:focus{
    --tw-translate-y:4px
  }

  .sm\:focus\:translate-y-2:focus{
    --tw-translate-y:8px
  }

  .sm\:focus\:translate-y-3:focus{
    --tw-translate-y:12px
  }

  .sm\:focus\:translate-y-4:focus{
    --tw-translate-y:16px
  }

  .sm\:focus\:translate-y-5:focus{
    --tw-translate-y:20px
  }

  .sm\:focus\:translate-y-6:focus{
    --tw-translate-y:24px
  }

  .sm\:focus\:translate-y-8:focus{
    --tw-translate-y:32px
  }

  .sm\:focus\:translate-y-10:focus{
    --tw-translate-y:40px
  }

  .sm\:focus\:translate-y-12:focus{
    --tw-translate-y:48px
  }

  .sm\:focus\:translate-y-16:focus{
    --tw-translate-y:64px
  }

  .sm\:focus\:translate-y-18:focus{
    --tw-translate-y:72px
  }

  .sm\:focus\:translate-y-24:focus{
    --tw-translate-y:96px
  }

  .sm\:focus\:translate-y-5dot5:focus{
    --tw-translate-y:22px
  }

  .sm\:focus\:translate-y-full:focus{
    --tw-translate-y:100%
  }

  .sm\:focus\:-translate-y-1:focus{
    --tw-translate-y:-4px
  }

  .sm\:focus\:-translate-y-2:focus{
    --tw-translate-y:-8px
  }

  .sm\:focus\:-translate-y-3:focus{
    --tw-translate-y:-12px
  }

  .sm\:focus\:-translate-y-4:focus{
    --tw-translate-y:-16px
  }

  .sm\:focus\:-translate-y-5:focus{
    --tw-translate-y:-20px
  }

  .sm\:focus\:-translate-y-6:focus{
    --tw-translate-y:-24px
  }

  .sm\:focus\:-translate-y-8:focus{
    --tw-translate-y:-32px
  }

  .sm\:focus\:-translate-y-10:focus{
    --tw-translate-y:-40px
  }

  .sm\:focus\:-translate-y-12:focus{
    --tw-translate-y:-48px
  }

  .sm\:focus\:-translate-y-16:focus{
    --tw-translate-y:-64px
  }

  .sm\:focus\:-translate-y-18:focus{
    --tw-translate-y:-72px
  }

  .sm\:focus\:-translate-y-24:focus{
    --tw-translate-y:-96px
  }

  .sm\:focus\:-translate-y-5dot5:focus{
    --tw-translate-y:-22px
  }

  .sm\:focus\:-translate-y-full:focus{
    --tw-translate-y:-100%
  }

  .sm\:focus\:translate-y-1\/2:focus{
    --tw-translate-y:50%
  }

  .sm\:focus\:translate-y-1\/3:focus{
    --tw-translate-y:33.333333%
  }

  .sm\:focus\:translate-y-2\/3:focus{
    --tw-translate-y:66.666667%
  }

  .sm\:focus\:translate-y-1\/4:focus{
    --tw-translate-y:25%
  }

  .sm\:focus\:translate-y-2\/4:focus{
    --tw-translate-y:50%
  }

  .sm\:focus\:translate-y-3\/4:focus{
    --tw-translate-y:75%
  }

  .sm\:focus\:-translate-y-1\/2:focus{
    --tw-translate-y:-50%
  }

  .sm\:focus\:-translate-y-1\/3:focus{
    --tw-translate-y:-33.333333%
  }

  .sm\:focus\:-translate-y-2\/3:focus{
    --tw-translate-y:-66.666667%
  }

  .sm\:focus\:-translate-y-1\/4:focus{
    --tw-translate-y:-25%
  }

  .sm\:focus\:-translate-y-2\/4:focus{
    --tw-translate-y:-50%
  }

  .sm\:focus\:-translate-y-3\/4:focus{
    --tw-translate-y:-75%
  }

  .sm\:rotate-0{
    --tw-rotate:0deg
  }

  .sm\:rotate-1{
    --tw-rotate:1deg
  }

  .sm\:rotate-2{
    --tw-rotate:2deg
  }

  .sm\:rotate-3{
    --tw-rotate:3deg
  }

  .sm\:rotate-6{
    --tw-rotate:6deg
  }

  .sm\:rotate-12{
    --tw-rotate:12deg
  }

  .sm\:rotate-45{
    --tw-rotate:45deg
  }

  .sm\:rotate-90{
    --tw-rotate:90deg
  }

  .sm\:rotate-180{
    --tw-rotate:180deg
  }

  .sm\:-rotate-180{
    --tw-rotate:-180deg
  }

  .sm\:-rotate-90{
    --tw-rotate:-90deg
  }

  .sm\:-rotate-45{
    --tw-rotate:-45deg
  }

  .sm\:-rotate-12{
    --tw-rotate:-12deg
  }

  .sm\:-rotate-6{
    --tw-rotate:-6deg
  }

  .sm\:-rotate-3{
    --tw-rotate:-3deg
  }

  .sm\:-rotate-2{
    --tw-rotate:-2deg
  }

  .sm\:-rotate-1{
    --tw-rotate:-1deg
  }

  .sm\:hover\:rotate-0:hover{
    --tw-rotate:0deg
  }

  .sm\:hover\:rotate-1:hover{
    --tw-rotate:1deg
  }

  .sm\:hover\:rotate-2:hover{
    --tw-rotate:2deg
  }

  .sm\:hover\:rotate-3:hover{
    --tw-rotate:3deg
  }

  .sm\:hover\:rotate-6:hover{
    --tw-rotate:6deg
  }

  .sm\:hover\:rotate-12:hover{
    --tw-rotate:12deg
  }

  .sm\:hover\:rotate-45:hover{
    --tw-rotate:45deg
  }

  .sm\:hover\:rotate-90:hover{
    --tw-rotate:90deg
  }

  .sm\:hover\:rotate-180:hover{
    --tw-rotate:180deg
  }

  .sm\:hover\:-rotate-180:hover{
    --tw-rotate:-180deg
  }

  .sm\:hover\:-rotate-90:hover{
    --tw-rotate:-90deg
  }

  .sm\:hover\:-rotate-45:hover{
    --tw-rotate:-45deg
  }

  .sm\:hover\:-rotate-12:hover{
    --tw-rotate:-12deg
  }

  .sm\:hover\:-rotate-6:hover{
    --tw-rotate:-6deg
  }

  .sm\:hover\:-rotate-3:hover{
    --tw-rotate:-3deg
  }

  .sm\:hover\:-rotate-2:hover{
    --tw-rotate:-2deg
  }

  .sm\:hover\:-rotate-1:hover{
    --tw-rotate:-1deg
  }

  .sm\:focus\:rotate-0:focus{
    --tw-rotate:0deg
  }

  .sm\:focus\:rotate-1:focus{
    --tw-rotate:1deg
  }

  .sm\:focus\:rotate-2:focus{
    --tw-rotate:2deg
  }

  .sm\:focus\:rotate-3:focus{
    --tw-rotate:3deg
  }

  .sm\:focus\:rotate-6:focus{
    --tw-rotate:6deg
  }

  .sm\:focus\:rotate-12:focus{
    --tw-rotate:12deg
  }

  .sm\:focus\:rotate-45:focus{
    --tw-rotate:45deg
  }

  .sm\:focus\:rotate-90:focus{
    --tw-rotate:90deg
  }

  .sm\:focus\:rotate-180:focus{
    --tw-rotate:180deg
  }

  .sm\:focus\:-rotate-180:focus{
    --tw-rotate:-180deg
  }

  .sm\:focus\:-rotate-90:focus{
    --tw-rotate:-90deg
  }

  .sm\:focus\:-rotate-45:focus{
    --tw-rotate:-45deg
  }

  .sm\:focus\:-rotate-12:focus{
    --tw-rotate:-12deg
  }

  .sm\:focus\:-rotate-6:focus{
    --tw-rotate:-6deg
  }

  .sm\:focus\:-rotate-3:focus{
    --tw-rotate:-3deg
  }

  .sm\:focus\:-rotate-2:focus{
    --tw-rotate:-2deg
  }

  .sm\:focus\:-rotate-1:focus{
    --tw-rotate:-1deg
  }

  .sm\:animate-none{
    animation:none
  }

  .sm\:animate-spin{
    animation:spin 1s linear infinite
  }

  .sm\:animate-ping{
    animation:ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .sm\:animate-pulse{
    animation:pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .sm\:animate-bounce{
    animation:bounce 1s infinite
  }

  .sm\:animate-spin-delay{
    animation:spin-delay 2.4s ease infinite
  }

  .sm\:cursor-auto{
    cursor:auto
  }

  .sm\:cursor-default{
    cursor:default
  }

  .sm\:cursor-pointer{
    cursor:pointer
  }

  .sm\:cursor-wait{
    cursor:wait
  }

  .sm\:cursor-text{
    cursor:text
  }

  .sm\:cursor-move{
    cursor:move
  }

  .sm\:cursor-help{
    cursor:help
  }

  .sm\:cursor-not-allowed{
    cursor:not-allowed
  }

  .sm\:select-none{
    -webkit-user-select:none;
            user-select:none
  }

  .sm\:select-text{
    -webkit-user-select:text;
            user-select:text
  }

  .sm\:select-all{
    -webkit-user-select:all;
            user-select:all
  }

  .sm\:select-auto{
    -webkit-user-select:auto;
            user-select:auto
  }

  .sm\:auto-cols-auto{
    grid-auto-columns:auto
  }

  .sm\:auto-cols-min{
    grid-auto-columns:min-content
  }

  .sm\:auto-cols-max{
    grid-auto-columns:max-content
  }

  .sm\:auto-cols-fr{
    grid-auto-columns:minmax(0, 1fr)
  }

  .sm\:grid-flow-row{
    grid-auto-flow:row
  }

  .sm\:grid-flow-col{
    grid-auto-flow:column
  }

  .sm\:grid-flow-row-dense{
    grid-auto-flow:row dense
  }

  .sm\:grid-flow-col-dense{
    grid-auto-flow:column dense
  }

  .sm\:auto-rows-auto{
    grid-auto-rows:auto
  }

  .sm\:auto-rows-min{
    grid-auto-rows:min-content
  }

  .sm\:auto-rows-max{
    grid-auto-rows:max-content
  }

  .sm\:auto-rows-fr{
    grid-auto-rows:minmax(0, 1fr)
  }

  .sm\:grid-cols-1{
    grid-template-columns:repeat(1, minmax(0, 1fr))
  }

  .sm\:grid-cols-2{
    grid-template-columns:repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-cols-3{
    grid-template-columns:repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-cols-4{
    grid-template-columns:repeat(4, minmax(0, 1fr))
  }

  .sm\:grid-cols-5{
    grid-template-columns:repeat(5, minmax(0, 1fr))
  }

  .sm\:grid-cols-6{
    grid-template-columns:repeat(6, minmax(0, 1fr))
  }

  .sm\:grid-cols-7{
    grid-template-columns:repeat(7, minmax(0, 1fr))
  }

  .sm\:grid-cols-8{
    grid-template-columns:repeat(8, minmax(0, 1fr))
  }

  .sm\:grid-cols-9{
    grid-template-columns:repeat(9, minmax(0, 1fr))
  }

  .sm\:grid-cols-10{
    grid-template-columns:repeat(10, minmax(0, 1fr))
  }

  .sm\:grid-cols-11{
    grid-template-columns:repeat(11, minmax(0, 1fr))
  }

  .sm\:grid-cols-12{
    grid-template-columns:repeat(12, minmax(0, 1fr))
  }

  .sm\:grid-cols-none{
    grid-template-columns:none
  }

  .sm\:grid-rows-1{
    grid-template-rows:repeat(1, minmax(0, 1fr))
  }

  .sm\:grid-rows-2{
    grid-template-rows:repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-rows-3{
    grid-template-rows:repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-rows-4{
    grid-template-rows:repeat(4, minmax(0, 1fr))
  }

  .sm\:grid-rows-5{
    grid-template-rows:repeat(5, minmax(0, 1fr))
  }

  .sm\:grid-rows-6{
    grid-template-rows:repeat(6, minmax(0, 1fr))
  }

  .sm\:grid-rows-none{
    grid-template-rows:none
  }

  .sm\:flex-row{
    flex-direction:row
  }

  .sm\:flex-row-reverse{
    flex-direction:row-reverse
  }

  .sm\:flex-col{
    flex-direction:column
  }

  .sm\:flex-col-reverse{
    flex-direction:column-reverse
  }

  .sm\:flex-wrap{
    flex-wrap:wrap
  }

  .sm\:flex-wrap-reverse{
    flex-wrap:wrap-reverse
  }

  .sm\:flex-nowrap{
    flex-wrap:nowrap
  }

  .sm\:place-content-center{
    place-content:center
  }

  .sm\:place-content-start{
    place-content:start
  }

  .sm\:place-content-end{
    place-content:end
  }

  .sm\:place-content-between{
    place-content:space-between
  }

  .sm\:place-content-around{
    place-content:space-around
  }

  .sm\:place-content-evenly{
    place-content:space-evenly
  }

  .sm\:place-content-stretch{
    place-content:stretch
  }

  .sm\:place-items-start{
    place-items:start
  }

  .sm\:place-items-end{
    place-items:end
  }

  .sm\:place-items-center{
    place-items:center
  }

  .sm\:place-items-stretch{
    place-items:stretch
  }

  .sm\:content-center{
    align-content:center
  }

  .sm\:content-start{
    align-content:flex-start
  }

  .sm\:content-end{
    align-content:flex-end
  }

  .sm\:content-between{
    align-content:space-between
  }

  .sm\:content-around{
    align-content:space-around
  }

  .sm\:content-evenly{
    align-content:space-evenly
  }

  .sm\:items-start{
    align-items:flex-start
  }

  .sm\:items-end{
    align-items:flex-end
  }

  .sm\:items-center{
    align-items:center
  }

  .sm\:items-baseline{
    align-items:baseline
  }

  .sm\:items-stretch{
    align-items:stretch
  }

  .sm\:justify-start{
    justify-content:flex-start
  }

  .sm\:justify-end{
    justify-content:flex-end
  }

  .sm\:justify-center{
    justify-content:center
  }

  .sm\:justify-between{
    justify-content:space-between
  }

  .sm\:justify-around{
    justify-content:space-around
  }

  .sm\:justify-evenly{
    justify-content:space-evenly
  }

  .sm\:justify-items-start{
    justify-items:start
  }

  .sm\:justify-items-end{
    justify-items:end
  }

  .sm\:justify-items-center{
    justify-items:center
  }

  .sm\:justify-items-stretch{
    justify-items:stretch
  }

  .sm\:space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(0px * var(--tw-space-x-reverse));
    margin-left:calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(4px * var(--tw-space-x-reverse));
    margin-left:calc(4px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(8px * var(--tw-space-x-reverse));
    margin-left:calc(8px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(12px * var(--tw-space-x-reverse));
    margin-left:calc(12px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(16px * var(--tw-space-x-reverse));
    margin-left:calc(16px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(20px * var(--tw-space-x-reverse));
    margin-left:calc(20px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(24px * var(--tw-space-x-reverse));
    margin-left:calc(24px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(32px * var(--tw-space-x-reverse));
    margin-left:calc(32px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(40px * var(--tw-space-x-reverse));
    margin-left:calc(40px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(48px * var(--tw-space-x-reverse));
    margin-left:calc(48px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(64px * var(--tw-space-x-reverse));
    margin-left:calc(64px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(72px * var(--tw-space-x-reverse));
    margin-left:calc(72px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(96px * var(--tw-space-x-reverse));
    margin-left:calc(96px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(22px * var(--tw-space-x-reverse));
    margin-left:calc(22px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-x-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(100% * var(--tw-space-x-reverse));
    margin-left:calc(100% * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-4px * var(--tw-space-x-reverse));
    margin-left:calc(-4px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-8px * var(--tw-space-x-reverse));
    margin-left:calc(-8px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-12px * var(--tw-space-x-reverse));
    margin-left:calc(-12px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-16px * var(--tw-space-x-reverse));
    margin-left:calc(-16px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-20px * var(--tw-space-x-reverse));
    margin-left:calc(-20px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-24px * var(--tw-space-x-reverse));
    margin-left:calc(-24px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-32px * var(--tw-space-x-reverse));
    margin-left:calc(-32px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-40px * var(--tw-space-x-reverse));
    margin-left:calc(-40px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-48px * var(--tw-space-x-reverse));
    margin-left:calc(-48px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-64px * var(--tw-space-x-reverse));
    margin-left:calc(-64px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-72px * var(--tw-space-x-reverse));
    margin-left:calc(-72px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-96px * var(--tw-space-x-reverse));
    margin-left:calc(-96px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-22px * var(--tw-space-x-reverse));
    margin-left:calc(-22px * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:-space-x-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-100% * var(--tw-space-x-reverse));
    margin-left:calc(-100% * calc(1 - var(--tw-space-x-reverse)))
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(4px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(4px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(8px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(12px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(16px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(20px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(24px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(32px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(40px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(48px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(48px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(64px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(64px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(72px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(72px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(96px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(96px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(22px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(22px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(100% * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(100% * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-4px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-4px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-8px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-12px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-16px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-20px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-24px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-32px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-40px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-48px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-48px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-64px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-64px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-72px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-72px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-96px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-96px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-22px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-22px * var(--tw-space-y-reverse))
  }

  .sm\:-space-y-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-100% * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-100% * var(--tw-space-y-reverse))
  }

  .sm\:space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:1
  }

  .sm\:space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:1
  }

  .sm\:divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style:solid
  }

  .sm\:divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style:dashed
  }

  .sm\:divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style:dotted
  }

  .sm\:divide-double > :not([hidden]) ~ :not([hidden]){
    border-style:double
  }

  .sm\:divide-none > :not([hidden]) ~ :not([hidden]){
    border-style:none
  }

  .sm\:place-self-auto{
    place-self:auto
  }

  .sm\:place-self-start{
    place-self:start
  }

  .sm\:place-self-end{
    place-self:end
  }

  .sm\:place-self-center{
    place-self:center
  }

  .sm\:place-self-stretch{
    place-self:stretch
  }

  .sm\:self-auto{
    align-self:auto
  }

  .sm\:self-start{
    align-self:flex-start
  }

  .sm\:self-end{
    align-self:flex-end
  }

  .sm\:self-center{
    align-self:center
  }

  .sm\:self-stretch{
    align-self:stretch
  }

  .sm\:self-baseline{
    align-self:baseline
  }

  .sm\:justify-self-auto{
    justify-self:auto
  }

  .sm\:justify-self-start{
    justify-self:start
  }

  .sm\:justify-self-end{
    justify-self:end
  }

  .sm\:justify-self-center{
    justify-self:center
  }

  .sm\:justify-self-stretch{
    justify-self:stretch
  }

  .sm\:overflow-auto{
    overflow:auto
  }

  .sm\:overflow-hidden{
    overflow:hidden
  }

  .sm\:overflow-visible{
    overflow:visible
  }

  .sm\:overflow-scroll{
    overflow:scroll
  }

  .sm\:overflow-x-auto{
    overflow-x:auto
  }

  .sm\:overflow-y-auto{
    overflow-y:auto
  }

  .sm\:overflow-x-hidden{
    overflow-x:hidden
  }

  .sm\:overflow-y-hidden{
    overflow-y:hidden
  }

  .sm\:overflow-x-visible{
    overflow-x:visible
  }

  .sm\:overflow-y-visible{
    overflow-y:visible
  }

  .sm\:overflow-x-scroll{
    overflow-x:scroll
  }

  .sm\:overflow-y-scroll{
    overflow-y:scroll
  }

  .sm\:overscroll-auto{
    overscroll-behavior:auto
  }

  .sm\:overscroll-contain{
    overscroll-behavior:contain
  }

  .sm\:overscroll-none{
    overscroll-behavior:none
  }

  .sm\:overscroll-y-auto{
    overscroll-behavior-y:auto
  }

  .sm\:overscroll-y-contain{
    overscroll-behavior-y:contain
  }

  .sm\:overscroll-y-none{
    overscroll-behavior-y:none
  }

  .sm\:overscroll-x-auto{
    overscroll-behavior-x:auto
  }

  .sm\:overscroll-x-contain{
    overscroll-behavior-x:contain
  }

  .sm\:overscroll-x-none{
    overscroll-behavior-x:none
  }

  .sm\:truncate{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
  }

  .sm\:overflow-ellipsis{
    text-overflow:ellipsis
  }

  .sm\:overflow-clip{
    text-overflow:clip
  }

  .sm\:whitespace-normal{
    white-space:normal
  }

  .sm\:whitespace-nowrap{
    white-space:nowrap
  }

  .sm\:whitespace-pre{
    white-space:pre
  }

  .sm\:whitespace-pre-line{
    white-space:pre-line
  }

  .sm\:whitespace-pre-wrap{
    white-space:pre-wrap
  }

  .sm\:break-normal{
    overflow-wrap:normal;
    word-break:normal
  }

  .sm\:break-words{
    overflow-wrap:break-word
  }

  .sm\:break-all{
    word-break:break-all
  }

  .sm\:rounded-1{
    border-radius:4px
  }

  .sm\:rounded-2{
    border-radius:8px
  }

  .sm\:rounded-3{
    border-radius:12px
  }

  .sm\:rounded-4{
    border-radius:16px
  }

  .sm\:rounded-none{
    border-radius:0px
  }

  .sm\:rounded-sm{
    border-radius:0.125rem
  }

  .sm\:rounded{
    border-radius:0.25rem
  }

  .sm\:rounded-md{
    border-radius:0.375rem
  }

  .sm\:rounded-lg{
    border-radius:0.5rem
  }

  .sm\:rounded-xl{
    border-radius:0.75rem
  }

  .sm\:rounded-2xl{
    border-radius:1rem
  }

  .sm\:rounded-3xl{
    border-radius:1.5rem
  }

  .sm\:rounded-full{
    border-radius:9999px
  }

  .sm\:rounded-default{
    border-radius:4px
  }

  .sm\:rounded-t-1{
    border-top-left-radius:4px;
    border-top-right-radius:4px
  }

  .sm\:rounded-t-2{
    border-top-left-radius:8px;
    border-top-right-radius:8px
  }

  .sm\:rounded-t-3{
    border-top-left-radius:12px;
    border-top-right-radius:12px
  }

  .sm\:rounded-t-4{
    border-top-left-radius:16px;
    border-top-right-radius:16px
  }

  .sm\:rounded-t-none{
    border-top-left-radius:0px;
    border-top-right-radius:0px
  }

  .sm\:rounded-t-sm{
    border-top-left-radius:0.125rem;
    border-top-right-radius:0.125rem
  }

  .sm\:rounded-t{
    border-top-left-radius:0.25rem;
    border-top-right-radius:0.25rem
  }

  .sm\:rounded-t-md{
    border-top-left-radius:0.375rem;
    border-top-right-radius:0.375rem
  }

  .sm\:rounded-t-lg{
    border-top-left-radius:0.5rem;
    border-top-right-radius:0.5rem
  }

  .sm\:rounded-t-xl{
    border-top-left-radius:0.75rem;
    border-top-right-radius:0.75rem
  }

  .sm\:rounded-t-2xl{
    border-top-left-radius:1rem;
    border-top-right-radius:1rem
  }

  .sm\:rounded-t-3xl{
    border-top-left-radius:1.5rem;
    border-top-right-radius:1.5rem
  }

  .sm\:rounded-t-full{
    border-top-left-radius:9999px;
    border-top-right-radius:9999px
  }

  .sm\:rounded-t-default{
    border-top-left-radius:4px;
    border-top-right-radius:4px
  }

  .sm\:rounded-r-1{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
  }

  .sm\:rounded-r-2{
    border-top-right-radius:8px;
    border-bottom-right-radius:8px
  }

  .sm\:rounded-r-3{
    border-top-right-radius:12px;
    border-bottom-right-radius:12px
  }

  .sm\:rounded-r-4{
    border-top-right-radius:16px;
    border-bottom-right-radius:16px
  }

  .sm\:rounded-r-none{
    border-top-right-radius:0px;
    border-bottom-right-radius:0px
  }

  .sm\:rounded-r-sm{
    border-top-right-radius:0.125rem;
    border-bottom-right-radius:0.125rem
  }

  .sm\:rounded-r{
    border-top-right-radius:0.25rem;
    border-bottom-right-radius:0.25rem
  }

  .sm\:rounded-r-md{
    border-top-right-radius:0.375rem;
    border-bottom-right-radius:0.375rem
  }

  .sm\:rounded-r-lg{
    border-top-right-radius:0.5rem;
    border-bottom-right-radius:0.5rem
  }

  .sm\:rounded-r-xl{
    border-top-right-radius:0.75rem;
    border-bottom-right-radius:0.75rem
  }

  .sm\:rounded-r-2xl{
    border-top-right-radius:1rem;
    border-bottom-right-radius:1rem
  }

  .sm\:rounded-r-3xl{
    border-top-right-radius:1.5rem;
    border-bottom-right-radius:1.5rem
  }

  .sm\:rounded-r-full{
    border-top-right-radius:9999px;
    border-bottom-right-radius:9999px
  }

  .sm\:rounded-r-default{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
  }

  .sm\:rounded-b-1{
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px
  }

  .sm\:rounded-b-2{
    border-bottom-right-radius:8px;
    border-bottom-left-radius:8px
  }

  .sm\:rounded-b-3{
    border-bottom-right-radius:12px;
    border-bottom-left-radius:12px
  }

  .sm\:rounded-b-4{
    border-bottom-right-radius:16px;
    border-bottom-left-radius:16px
  }

  .sm\:rounded-b-none{
    border-bottom-right-radius:0px;
    border-bottom-left-radius:0px
  }

  .sm\:rounded-b-sm{
    border-bottom-right-radius:0.125rem;
    border-bottom-left-radius:0.125rem
  }

  .sm\:rounded-b{
    border-bottom-right-radius:0.25rem;
    border-bottom-left-radius:0.25rem
  }

  .sm\:rounded-b-md{
    border-bottom-right-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }

  .sm\:rounded-b-lg{
    border-bottom-right-radius:0.5rem;
    border-bottom-left-radius:0.5rem
  }

  .sm\:rounded-b-xl{
    border-bottom-right-radius:0.75rem;
    border-bottom-left-radius:0.75rem
  }

  .sm\:rounded-b-2xl{
    border-bottom-right-radius:1rem;
    border-bottom-left-radius:1rem
  }

  .sm\:rounded-b-3xl{
    border-bottom-right-radius:1.5rem;
    border-bottom-left-radius:1.5rem
  }

  .sm\:rounded-b-full{
    border-bottom-right-radius:9999px;
    border-bottom-left-radius:9999px
  }

  .sm\:rounded-b-default{
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px
  }

  .sm\:rounded-l-1{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
  }

  .sm\:rounded-l-2{
    border-top-left-radius:8px;
    border-bottom-left-radius:8px
  }

  .sm\:rounded-l-3{
    border-top-left-radius:12px;
    border-bottom-left-radius:12px
  }

  .sm\:rounded-l-4{
    border-top-left-radius:16px;
    border-bottom-left-radius:16px
  }

  .sm\:rounded-l-none{
    border-top-left-radius:0px;
    border-bottom-left-radius:0px
  }

  .sm\:rounded-l-sm{
    border-top-left-radius:0.125rem;
    border-bottom-left-radius:0.125rem
  }

  .sm\:rounded-l{
    border-top-left-radius:0.25rem;
    border-bottom-left-radius:0.25rem
  }

  .sm\:rounded-l-md{
    border-top-left-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }

  .sm\:rounded-l-lg{
    border-top-left-radius:0.5rem;
    border-bottom-left-radius:0.5rem
  }

  .sm\:rounded-l-xl{
    border-top-left-radius:0.75rem;
    border-bottom-left-radius:0.75rem
  }

  .sm\:rounded-l-2xl{
    border-top-left-radius:1rem;
    border-bottom-left-radius:1rem
  }

  .sm\:rounded-l-3xl{
    border-top-left-radius:1.5rem;
    border-bottom-left-radius:1.5rem
  }

  .sm\:rounded-l-full{
    border-top-left-radius:9999px;
    border-bottom-left-radius:9999px
  }

  .sm\:rounded-l-default{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
  }

  .sm\:rounded-tl-1{
    border-top-left-radius:4px
  }

  .sm\:rounded-tl-2{
    border-top-left-radius:8px
  }

  .sm\:rounded-tl-3{
    border-top-left-radius:12px
  }

  .sm\:rounded-tl-4{
    border-top-left-radius:16px
  }

  .sm\:rounded-tl-none{
    border-top-left-radius:0px
  }

  .sm\:rounded-tl-sm{
    border-top-left-radius:0.125rem
  }

  .sm\:rounded-tl{
    border-top-left-radius:0.25rem
  }

  .sm\:rounded-tl-md{
    border-top-left-radius:0.375rem
  }

  .sm\:rounded-tl-lg{
    border-top-left-radius:0.5rem
  }

  .sm\:rounded-tl-xl{
    border-top-left-radius:0.75rem
  }

  .sm\:rounded-tl-2xl{
    border-top-left-radius:1rem
  }

  .sm\:rounded-tl-3xl{
    border-top-left-radius:1.5rem
  }

  .sm\:rounded-tl-full{
    border-top-left-radius:9999px
  }

  .sm\:rounded-tl-default{
    border-top-left-radius:4px
  }

  .sm\:rounded-tr-1{
    border-top-right-radius:4px
  }

  .sm\:rounded-tr-2{
    border-top-right-radius:8px
  }

  .sm\:rounded-tr-3{
    border-top-right-radius:12px
  }

  .sm\:rounded-tr-4{
    border-top-right-radius:16px
  }

  .sm\:rounded-tr-none{
    border-top-right-radius:0px
  }

  .sm\:rounded-tr-sm{
    border-top-right-radius:0.125rem
  }

  .sm\:rounded-tr{
    border-top-right-radius:0.25rem
  }

  .sm\:rounded-tr-md{
    border-top-right-radius:0.375rem
  }

  .sm\:rounded-tr-lg{
    border-top-right-radius:0.5rem
  }

  .sm\:rounded-tr-xl{
    border-top-right-radius:0.75rem
  }

  .sm\:rounded-tr-2xl{
    border-top-right-radius:1rem
  }

  .sm\:rounded-tr-3xl{
    border-top-right-radius:1.5rem
  }

  .sm\:rounded-tr-full{
    border-top-right-radius:9999px
  }

  .sm\:rounded-tr-default{
    border-top-right-radius:4px
  }

  .sm\:rounded-br-1{
    border-bottom-right-radius:4px
  }

  .sm\:rounded-br-2{
    border-bottom-right-radius:8px
  }

  .sm\:rounded-br-3{
    border-bottom-right-radius:12px
  }

  .sm\:rounded-br-4{
    border-bottom-right-radius:16px
  }

  .sm\:rounded-br-none{
    border-bottom-right-radius:0px
  }

  .sm\:rounded-br-sm{
    border-bottom-right-radius:0.125rem
  }

  .sm\:rounded-br{
    border-bottom-right-radius:0.25rem
  }

  .sm\:rounded-br-md{
    border-bottom-right-radius:0.375rem
  }

  .sm\:rounded-br-lg{
    border-bottom-right-radius:0.5rem
  }

  .sm\:rounded-br-xl{
    border-bottom-right-radius:0.75rem
  }

  .sm\:rounded-br-2xl{
    border-bottom-right-radius:1rem
  }

  .sm\:rounded-br-3xl{
    border-bottom-right-radius:1.5rem
  }

  .sm\:rounded-br-full{
    border-bottom-right-radius:9999px
  }

  .sm\:rounded-br-default{
    border-bottom-right-radius:4px
  }

  .sm\:rounded-bl-1{
    border-bottom-left-radius:4px
  }

  .sm\:rounded-bl-2{
    border-bottom-left-radius:8px
  }

  .sm\:rounded-bl-3{
    border-bottom-left-radius:12px
  }

  .sm\:rounded-bl-4{
    border-bottom-left-radius:16px
  }

  .sm\:rounded-bl-none{
    border-bottom-left-radius:0px
  }

  .sm\:rounded-bl-sm{
    border-bottom-left-radius:0.125rem
  }

  .sm\:rounded-bl{
    border-bottom-left-radius:0.25rem
  }

  .sm\:rounded-bl-md{
    border-bottom-left-radius:0.375rem
  }

  .sm\:rounded-bl-lg{
    border-bottom-left-radius:0.5rem
  }

  .sm\:rounded-bl-xl{
    border-bottom-left-radius:0.75rem
  }

  .sm\:rounded-bl-2xl{
    border-bottom-left-radius:1rem
  }

  .sm\:rounded-bl-3xl{
    border-bottom-left-radius:1.5rem
  }

  .sm\:rounded-bl-full{
    border-bottom-left-radius:9999px
  }

  .sm\:rounded-bl-default{
    border-bottom-left-radius:4px
  }

  .sm\:border-0{
    border-width:0px
  }

  .sm\:border-2{
    border-width:2px
  }

  .sm\:border-4{
    border-width:4px
  }

  .sm\:border-8{
    border-width:8px
  }

  .sm\:border{
    border-width:1px
  }

  .sm\:border-t-0{
    border-top-width:0px
  }

  .sm\:border-t-2{
    border-top-width:2px
  }

  .sm\:border-t-4{
    border-top-width:4px
  }

  .sm\:border-t-8{
    border-top-width:8px
  }

  .sm\:border-t{
    border-top-width:1px
  }

  .sm\:border-r-0{
    border-right-width:0px
  }

  .sm\:border-r-2{
    border-right-width:2px
  }

  .sm\:border-r-4{
    border-right-width:4px
  }

  .sm\:border-r-8{
    border-right-width:8px
  }

  .sm\:border-r{
    border-right-width:1px
  }

  .sm\:border-b-0{
    border-bottom-width:0px
  }

  .sm\:border-b-2{
    border-bottom-width:2px
  }

  .sm\:border-b-4{
    border-bottom-width:4px
  }

  .sm\:border-b-8{
    border-bottom-width:8px
  }

  .sm\:border-b{
    border-bottom-width:1px
  }

  .sm\:border-l-0{
    border-left-width:0px
  }

  .sm\:border-l-2{
    border-left-width:2px
  }

  .sm\:border-l-4{
    border-left-width:4px
  }

  .sm\:border-l-8{
    border-left-width:8px
  }

  .sm\:border-l{
    border-left-width:1px
  }

  .sm\:border-solid{
    border-style:solid
  }

  .sm\:border-dashed{
    border-style:dashed
  }

  .sm\:border-dotted{
    border-style:dotted
  }

  .sm\:border-double{
    border-style:double
  }

  .sm\:border-none{
    border-style:none
  }

  .sm\:border-red1{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .sm\:border-red2{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .sm\:border-red3{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .sm\:border-red4{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .sm\:border-red5{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .sm\:border-cyan1{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .sm\:border-cyan2{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .sm\:border-cyan3{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .sm\:border-cyan4{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .sm\:border-cyan5{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .sm\:border-bg1{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .sm\:border-bg2{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:border-bg3{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .sm\:border-gray1{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .sm\:border-gray2{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .sm\:border-gray3{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .sm\:border-gray4{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .sm\:border-gray5{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .sm\:border-gray6{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .sm\:border-gray7{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .sm\:border-white{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:border-warning{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .sm\:border-error{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .sm\:border-info{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .sm\:border-success{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .sm\:border-special4{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .sm\:border-dark{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .sm\:border-transparent{
    border-color:transparent
  }

  .sm\:border-secondary1{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .sm\:border-secondary2{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .sm\:border-secondary3{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .sm\:border-secondary4{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .sm\:border-secondary5{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .sm\:border-green1{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red1:hover{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red2:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red3:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red4:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .sm\:hover\:border-red5:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan1:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan2:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan3:hover{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan4:hover{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .sm\:hover\:border-cyan5:hover{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .sm\:hover\:border-bg1:hover{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .sm\:hover\:border-bg2:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-bg3:hover{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray1:hover{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray2:hover{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray3:hover{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray4:hover{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray5:hover{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray6:hover{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .sm\:hover\:border-gray7:hover{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .sm\:hover\:border-white:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:hover\:border-warning:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .sm\:hover\:border-error:hover{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .sm\:hover\:border-info:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .sm\:hover\:border-success:hover{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .sm\:hover\:border-special4:hover{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .sm\:hover\:border-dark:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .sm\:hover\:border-transparent:hover{
    border-color:transparent
  }

  .sm\:hover\:border-secondary1:hover{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .sm\:hover\:border-secondary2:hover{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .sm\:hover\:border-secondary3:hover{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .sm\:hover\:border-secondary4:hover{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .sm\:hover\:border-secondary5:hover{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .sm\:hover\:border-green1:hover{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red1:focus{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red2:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red3:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red4:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .sm\:focus\:border-red5:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan1:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan2:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan3:focus{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan4:focus{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .sm\:focus\:border-cyan5:focus{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .sm\:focus\:border-bg1:focus{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .sm\:focus\:border-bg2:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-bg3:focus{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray1:focus{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray2:focus{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray3:focus{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray4:focus{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray5:focus{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray6:focus{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .sm\:focus\:border-gray7:focus{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .sm\:focus\:border-white:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:focus\:border-warning:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .sm\:focus\:border-error:focus{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .sm\:focus\:border-info:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .sm\:focus\:border-success:focus{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .sm\:focus\:border-special4:focus{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .sm\:focus\:border-dark:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .sm\:focus\:border-transparent:focus{
    border-color:transparent
  }

  .sm\:focus\:border-secondary1:focus{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .sm\:focus\:border-secondary2:focus{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .sm\:focus\:border-secondary3:focus{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .sm\:focus\:border-secondary4:focus{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .sm\:focus\:border-secondary5:focus{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .sm\:focus\:border-green1:focus{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .sm\:active\:border-red1:active{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .sm\:active\:border-red2:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .sm\:active\:border-red3:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .sm\:active\:border-red4:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .sm\:active\:border-red5:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .sm\:active\:border-cyan1:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .sm\:active\:border-cyan2:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .sm\:active\:border-cyan3:active{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .sm\:active\:border-cyan4:active{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .sm\:active\:border-cyan5:active{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .sm\:active\:border-bg1:active{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .sm\:active\:border-bg2:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:active\:border-bg3:active{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .sm\:active\:border-gray1:active{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .sm\:active\:border-gray2:active{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .sm\:active\:border-gray3:active{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .sm\:active\:border-gray4:active{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .sm\:active\:border-gray5:active{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .sm\:active\:border-gray6:active{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .sm\:active\:border-gray7:active{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .sm\:active\:border-white:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .sm\:active\:border-warning:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .sm\:active\:border-error:active{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .sm\:active\:border-info:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .sm\:active\:border-success:active{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .sm\:active\:border-special4:active{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .sm\:active\:border-dark:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .sm\:active\:border-transparent:active{
    border-color:transparent
  }

  .sm\:active\:border-secondary1:active{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .sm\:active\:border-secondary2:active{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .sm\:active\:border-secondary3:active{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .sm\:active\:border-secondary4:active{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .sm\:active\:border-secondary5:active{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .sm\:active\:border-green1:active{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .sm\:border-opacity-0{
    --tw-border-opacity:0
  }

  .sm\:border-opacity-5{
    --tw-border-opacity:0.05
  }

  .sm\:border-opacity-10{
    --tw-border-opacity:0.1
  }

  .sm\:border-opacity-20{
    --tw-border-opacity:0.2
  }

  .sm\:border-opacity-25{
    --tw-border-opacity:0.25
  }

  .sm\:border-opacity-30{
    --tw-border-opacity:0.3
  }

  .sm\:border-opacity-40{
    --tw-border-opacity:0.4
  }

  .sm\:border-opacity-50{
    --tw-border-opacity:0.5
  }

  .sm\:border-opacity-60{
    --tw-border-opacity:0.6
  }

  .sm\:border-opacity-70{
    --tw-border-opacity:0.7
  }

  .sm\:border-opacity-75{
    --tw-border-opacity:0.75
  }

  .sm\:border-opacity-80{
    --tw-border-opacity:0.8
  }

  .sm\:border-opacity-90{
    --tw-border-opacity:0.9
  }

  .sm\:border-opacity-95{
    --tw-border-opacity:0.95
  }

  .sm\:border-opacity-100{
    --tw-border-opacity:1
  }

  .group:hover .sm\:group-hover\:border-opacity-0{
    --tw-border-opacity:0
  }

  .group:hover .sm\:group-hover\:border-opacity-5{
    --tw-border-opacity:0.05
  }

  .group:hover .sm\:group-hover\:border-opacity-10{
    --tw-border-opacity:0.1
  }

  .group:hover .sm\:group-hover\:border-opacity-20{
    --tw-border-opacity:0.2
  }

  .group:hover .sm\:group-hover\:border-opacity-25{
    --tw-border-opacity:0.25
  }

  .group:hover .sm\:group-hover\:border-opacity-30{
    --tw-border-opacity:0.3
  }

  .group:hover .sm\:group-hover\:border-opacity-40{
    --tw-border-opacity:0.4
  }

  .group:hover .sm\:group-hover\:border-opacity-50{
    --tw-border-opacity:0.5
  }

  .group:hover .sm\:group-hover\:border-opacity-60{
    --tw-border-opacity:0.6
  }

  .group:hover .sm\:group-hover\:border-opacity-70{
    --tw-border-opacity:0.7
  }

  .group:hover .sm\:group-hover\:border-opacity-75{
    --tw-border-opacity:0.75
  }

  .group:hover .sm\:group-hover\:border-opacity-80{
    --tw-border-opacity:0.8
  }

  .group:hover .sm\:group-hover\:border-opacity-90{
    --tw-border-opacity:0.9
  }

  .group:hover .sm\:group-hover\:border-opacity-95{
    --tw-border-opacity:0.95
  }

  .group:hover .sm\:group-hover\:border-opacity-100{
    --tw-border-opacity:1
  }

  .sm\:focus-within\:border-opacity-0:focus-within{
    --tw-border-opacity:0
  }

  .sm\:focus-within\:border-opacity-5:focus-within{
    --tw-border-opacity:0.05
  }

  .sm\:focus-within\:border-opacity-10:focus-within{
    --tw-border-opacity:0.1
  }

  .sm\:focus-within\:border-opacity-20:focus-within{
    --tw-border-opacity:0.2
  }

  .sm\:focus-within\:border-opacity-25:focus-within{
    --tw-border-opacity:0.25
  }

  .sm\:focus-within\:border-opacity-30:focus-within{
    --tw-border-opacity:0.3
  }

  .sm\:focus-within\:border-opacity-40:focus-within{
    --tw-border-opacity:0.4
  }

  .sm\:focus-within\:border-opacity-50:focus-within{
    --tw-border-opacity:0.5
  }

  .sm\:focus-within\:border-opacity-60:focus-within{
    --tw-border-opacity:0.6
  }

  .sm\:focus-within\:border-opacity-70:focus-within{
    --tw-border-opacity:0.7
  }

  .sm\:focus-within\:border-opacity-75:focus-within{
    --tw-border-opacity:0.75
  }

  .sm\:focus-within\:border-opacity-80:focus-within{
    --tw-border-opacity:0.8
  }

  .sm\:focus-within\:border-opacity-90:focus-within{
    --tw-border-opacity:0.9
  }

  .sm\:focus-within\:border-opacity-95:focus-within{
    --tw-border-opacity:0.95
  }

  .sm\:focus-within\:border-opacity-100:focus-within{
    --tw-border-opacity:1
  }

  .sm\:hover\:border-opacity-0:hover{
    --tw-border-opacity:0
  }

  .sm\:hover\:border-opacity-5:hover{
    --tw-border-opacity:0.05
  }

  .sm\:hover\:border-opacity-10:hover{
    --tw-border-opacity:0.1
  }

  .sm\:hover\:border-opacity-20:hover{
    --tw-border-opacity:0.2
  }

  .sm\:hover\:border-opacity-25:hover{
    --tw-border-opacity:0.25
  }

  .sm\:hover\:border-opacity-30:hover{
    --tw-border-opacity:0.3
  }

  .sm\:hover\:border-opacity-40:hover{
    --tw-border-opacity:0.4
  }

  .sm\:hover\:border-opacity-50:hover{
    --tw-border-opacity:0.5
  }

  .sm\:hover\:border-opacity-60:hover{
    --tw-border-opacity:0.6
  }

  .sm\:hover\:border-opacity-70:hover{
    --tw-border-opacity:0.7
  }

  .sm\:hover\:border-opacity-75:hover{
    --tw-border-opacity:0.75
  }

  .sm\:hover\:border-opacity-80:hover{
    --tw-border-opacity:0.8
  }

  .sm\:hover\:border-opacity-90:hover{
    --tw-border-opacity:0.9
  }

  .sm\:hover\:border-opacity-95:hover{
    --tw-border-opacity:0.95
  }

  .sm\:hover\:border-opacity-100:hover{
    --tw-border-opacity:1
  }

  .sm\:focus\:border-opacity-0:focus{
    --tw-border-opacity:0
  }

  .sm\:focus\:border-opacity-5:focus{
    --tw-border-opacity:0.05
  }

  .sm\:focus\:border-opacity-10:focus{
    --tw-border-opacity:0.1
  }

  .sm\:focus\:border-opacity-20:focus{
    --tw-border-opacity:0.2
  }

  .sm\:focus\:border-opacity-25:focus{
    --tw-border-opacity:0.25
  }

  .sm\:focus\:border-opacity-30:focus{
    --tw-border-opacity:0.3
  }

  .sm\:focus\:border-opacity-40:focus{
    --tw-border-opacity:0.4
  }

  .sm\:focus\:border-opacity-50:focus{
    --tw-border-opacity:0.5
  }

  .sm\:focus\:border-opacity-60:focus{
    --tw-border-opacity:0.6
  }

  .sm\:focus\:border-opacity-70:focus{
    --tw-border-opacity:0.7
  }

  .sm\:focus\:border-opacity-75:focus{
    --tw-border-opacity:0.75
  }

  .sm\:focus\:border-opacity-80:focus{
    --tw-border-opacity:0.8
  }

  .sm\:focus\:border-opacity-90:focus{
    --tw-border-opacity:0.9
  }

  .sm\:focus\:border-opacity-95:focus{
    --tw-border-opacity:0.95
  }

  .sm\:focus\:border-opacity-100:focus{
    --tw-border-opacity:1
  }

  .sm\:bg-red1{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .sm\:bg-red2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .sm\:bg-red3{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .sm\:bg-red4{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .sm\:bg-red5{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan1{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan2{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan3{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan4{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .sm\:bg-cyan5{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:bg-bg1{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .sm\:bg-bg2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-bg3{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .sm\:bg-gray1{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .sm\:bg-gray2{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .sm\:bg-gray3{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .sm\:bg-gray4{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .sm\:bg-gray5{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .sm\:bg-gray6{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .sm\:bg-gray7{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .sm\:bg-white{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:bg-warning{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .sm\:bg-error{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .sm\:bg-info{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .sm\:bg-success{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .sm\:bg-special4{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .sm\:bg-dark{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .sm\:bg-transparent{
    background-color:transparent
  }

  .sm\:bg-secondary1{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .sm\:bg-secondary2{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .sm\:bg-secondary3{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .sm\:bg-secondary4{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .sm\:bg-secondary5{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .sm\:bg-green1{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-red5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-cyan5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-bg1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-bg2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-bg3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray6:hover{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-gray7:hover{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-white:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-warning:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-error:hover{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-info:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-success:hover{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-special4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-dark:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-transparent:hover{
    background-color:transparent
  }

  .sm\:hover\:bg-secondary1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-secondary2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-secondary3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-secondary4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-secondary5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .sm\:hover\:bg-green1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-red5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-cyan5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-bg1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-bg2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-bg3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray6:focus{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-gray7:focus{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-white:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-warning:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-error:focus{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-info:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-success:focus{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-special4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-dark:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-transparent:focus{
    background-color:transparent
  }

  .sm\:focus\:bg-secondary1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-secondary2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-secondary3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-secondary4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-secondary5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .sm\:focus\:bg-green1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-red1:active{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-red2:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-red3:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-red4:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-red5:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-cyan1:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-cyan2:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-cyan3:active{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-cyan4:active{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-cyan5:active{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-bg1:active{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-bg2:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-bg3:active{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-gray1:active{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-gray2:active{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-gray3:active{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-gray4:active{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-gray5:active{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-gray6:active{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-gray7:active{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-white:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-warning:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-error:active{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-info:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-success:active{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-special4:active{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-dark:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-transparent:active{
    background-color:transparent
  }

  .sm\:active\:bg-secondary1:active{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-secondary2:active{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-secondary3:active{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-secondary4:active{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-secondary5:active{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .sm\:active\:bg-green1:active{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red1{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red3{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red4{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-red5{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan1{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan2{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan3{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan4{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-cyan5{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-bg1{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-bg2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-bg3{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray1{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray2{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray3{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray4{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray5{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray6{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-gray7{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-white{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-warning{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-error{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-info{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-success{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-special4{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-dark{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-transparent{
    background-color:transparent
  }

  .group:hover .sm\:group-hover\:bg-secondary1{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-secondary2{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-secondary3{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-secondary4{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-secondary5{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .group:hover .sm\:group-hover\:bg-green1{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .sm\:bg-opacity-0{
    --tw-bg-opacity:0
  }

  .sm\:bg-opacity-5{
    --tw-bg-opacity:0.05
  }

  .sm\:bg-opacity-10{
    --tw-bg-opacity:0.1
  }

  .sm\:bg-opacity-20{
    --tw-bg-opacity:0.2
  }

  .sm\:bg-opacity-25{
    --tw-bg-opacity:0.25
  }

  .sm\:bg-opacity-30{
    --tw-bg-opacity:0.3
  }

  .sm\:bg-opacity-40{
    --tw-bg-opacity:0.4
  }

  .sm\:bg-opacity-50{
    --tw-bg-opacity:0.5
  }

  .sm\:bg-opacity-60{
    --tw-bg-opacity:0.6
  }

  .sm\:bg-opacity-70{
    --tw-bg-opacity:0.7
  }

  .sm\:bg-opacity-75{
    --tw-bg-opacity:0.75
  }

  .sm\:bg-opacity-80{
    --tw-bg-opacity:0.8
  }

  .sm\:bg-opacity-90{
    --tw-bg-opacity:0.9
  }

  .sm\:bg-opacity-95{
    --tw-bg-opacity:0.95
  }

  .sm\:bg-opacity-100{
    --tw-bg-opacity:1
  }

  .group:hover .sm\:group-hover\:bg-opacity-0{
    --tw-bg-opacity:0
  }

  .group:hover .sm\:group-hover\:bg-opacity-5{
    --tw-bg-opacity:0.05
  }

  .group:hover .sm\:group-hover\:bg-opacity-10{
    --tw-bg-opacity:0.1
  }

  .group:hover .sm\:group-hover\:bg-opacity-20{
    --tw-bg-opacity:0.2
  }

  .group:hover .sm\:group-hover\:bg-opacity-25{
    --tw-bg-opacity:0.25
  }

  .group:hover .sm\:group-hover\:bg-opacity-30{
    --tw-bg-opacity:0.3
  }

  .group:hover .sm\:group-hover\:bg-opacity-40{
    --tw-bg-opacity:0.4
  }

  .group:hover .sm\:group-hover\:bg-opacity-50{
    --tw-bg-opacity:0.5
  }

  .group:hover .sm\:group-hover\:bg-opacity-60{
    --tw-bg-opacity:0.6
  }

  .group:hover .sm\:group-hover\:bg-opacity-70{
    --tw-bg-opacity:0.7
  }

  .group:hover .sm\:group-hover\:bg-opacity-75{
    --tw-bg-opacity:0.75
  }

  .group:hover .sm\:group-hover\:bg-opacity-80{
    --tw-bg-opacity:0.8
  }

  .group:hover .sm\:group-hover\:bg-opacity-90{
    --tw-bg-opacity:0.9
  }

  .group:hover .sm\:group-hover\:bg-opacity-95{
    --tw-bg-opacity:0.95
  }

  .group:hover .sm\:group-hover\:bg-opacity-100{
    --tw-bg-opacity:1
  }

  .sm\:focus-within\:bg-opacity-0:focus-within{
    --tw-bg-opacity:0
  }

  .sm\:focus-within\:bg-opacity-5:focus-within{
    --tw-bg-opacity:0.05
  }

  .sm\:focus-within\:bg-opacity-10:focus-within{
    --tw-bg-opacity:0.1
  }

  .sm\:focus-within\:bg-opacity-20:focus-within{
    --tw-bg-opacity:0.2
  }

  .sm\:focus-within\:bg-opacity-25:focus-within{
    --tw-bg-opacity:0.25
  }

  .sm\:focus-within\:bg-opacity-30:focus-within{
    --tw-bg-opacity:0.3
  }

  .sm\:focus-within\:bg-opacity-40:focus-within{
    --tw-bg-opacity:0.4
  }

  .sm\:focus-within\:bg-opacity-50:focus-within{
    --tw-bg-opacity:0.5
  }

  .sm\:focus-within\:bg-opacity-60:focus-within{
    --tw-bg-opacity:0.6
  }

  .sm\:focus-within\:bg-opacity-70:focus-within{
    --tw-bg-opacity:0.7
  }

  .sm\:focus-within\:bg-opacity-75:focus-within{
    --tw-bg-opacity:0.75
  }

  .sm\:focus-within\:bg-opacity-80:focus-within{
    --tw-bg-opacity:0.8
  }

  .sm\:focus-within\:bg-opacity-90:focus-within{
    --tw-bg-opacity:0.9
  }

  .sm\:focus-within\:bg-opacity-95:focus-within{
    --tw-bg-opacity:0.95
  }

  .sm\:focus-within\:bg-opacity-100:focus-within{
    --tw-bg-opacity:1
  }

  .sm\:hover\:bg-opacity-0:hover{
    --tw-bg-opacity:0
  }

  .sm\:hover\:bg-opacity-5:hover{
    --tw-bg-opacity:0.05
  }

  .sm\:hover\:bg-opacity-10:hover{
    --tw-bg-opacity:0.1
  }

  .sm\:hover\:bg-opacity-20:hover{
    --tw-bg-opacity:0.2
  }

  .sm\:hover\:bg-opacity-25:hover{
    --tw-bg-opacity:0.25
  }

  .sm\:hover\:bg-opacity-30:hover{
    --tw-bg-opacity:0.3
  }

  .sm\:hover\:bg-opacity-40:hover{
    --tw-bg-opacity:0.4
  }

  .sm\:hover\:bg-opacity-50:hover{
    --tw-bg-opacity:0.5
  }

  .sm\:hover\:bg-opacity-60:hover{
    --tw-bg-opacity:0.6
  }

  .sm\:hover\:bg-opacity-70:hover{
    --tw-bg-opacity:0.7
  }

  .sm\:hover\:bg-opacity-75:hover{
    --tw-bg-opacity:0.75
  }

  .sm\:hover\:bg-opacity-80:hover{
    --tw-bg-opacity:0.8
  }

  .sm\:hover\:bg-opacity-90:hover{
    --tw-bg-opacity:0.9
  }

  .sm\:hover\:bg-opacity-95:hover{
    --tw-bg-opacity:0.95
  }

  .sm\:hover\:bg-opacity-100:hover{
    --tw-bg-opacity:1
  }

  .sm\:focus\:bg-opacity-0:focus{
    --tw-bg-opacity:0
  }

  .sm\:focus\:bg-opacity-5:focus{
    --tw-bg-opacity:0.05
  }

  .sm\:focus\:bg-opacity-10:focus{
    --tw-bg-opacity:0.1
  }

  .sm\:focus\:bg-opacity-20:focus{
    --tw-bg-opacity:0.2
  }

  .sm\:focus\:bg-opacity-25:focus{
    --tw-bg-opacity:0.25
  }

  .sm\:focus\:bg-opacity-30:focus{
    --tw-bg-opacity:0.3
  }

  .sm\:focus\:bg-opacity-40:focus{
    --tw-bg-opacity:0.4
  }

  .sm\:focus\:bg-opacity-50:focus{
    --tw-bg-opacity:0.5
  }

  .sm\:focus\:bg-opacity-60:focus{
    --tw-bg-opacity:0.6
  }

  .sm\:focus\:bg-opacity-70:focus{
    --tw-bg-opacity:0.7
  }

  .sm\:focus\:bg-opacity-75:focus{
    --tw-bg-opacity:0.75
  }

  .sm\:focus\:bg-opacity-80:focus{
    --tw-bg-opacity:0.8
  }

  .sm\:focus\:bg-opacity-90:focus{
    --tw-bg-opacity:0.9
  }

  .sm\:focus\:bg-opacity-95:focus{
    --tw-bg-opacity:0.95
  }

  .sm\:focus\:bg-opacity-100:focus{
    --tw-bg-opacity:1
  }

  .sm\:bg-none{
    background-image:none
  }

  .sm\:bg-gradient-to-t{
    background-image:linear-gradient(to top, var(--tw-gradient-stops))
  }

  .sm\:bg-gradient-to-tr{
    background-image:linear-gradient(to top right, var(--tw-gradient-stops))
  }

  .sm\:bg-gradient-to-r{
    background-image:linear-gradient(to right, var(--tw-gradient-stops))
  }

  .sm\:bg-gradient-to-br{
    background-image:linear-gradient(to bottom right, var(--tw-gradient-stops))
  }

  .sm\:bg-gradient-to-b{
    background-image:linear-gradient(to bottom, var(--tw-gradient-stops))
  }

  .sm\:bg-gradient-to-bl{
    background-image:linear-gradient(to bottom left, var(--tw-gradient-stops))
  }

  .sm\:bg-gradient-to-l{
    background-image:linear-gradient(to left, var(--tw-gradient-stops))
  }

  .sm\:bg-gradient-to-tl{
    background-image:linear-gradient(to top left, var(--tw-gradient-stops))
  }

  .sm\:from-red1{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .sm\:from-red2{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .sm\:from-red3{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .sm\:from-red4{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .sm\:from-red5{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .sm\:from-cyan1{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .sm\:from-cyan2{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .sm\:from-cyan3{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .sm\:from-cyan4{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .sm\:from-cyan5{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .sm\:from-bg1{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .sm\:from-bg2{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:from-bg3{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .sm\:from-gray1{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .sm\:from-gray2{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .sm\:from-gray3{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .sm\:from-gray4{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .sm\:from-gray5{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .sm\:from-gray6{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .sm\:from-gray7{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:from-white{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:from-warning{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .sm\:from-error{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .sm\:from-info{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .sm\:from-success{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .sm\:from-special4{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .sm\:from-dark{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:from-transparent{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:from-secondary1{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .sm\:from-secondary2{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:from-secondary3{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .sm\:from-secondary4{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .sm\:from-secondary5{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .sm\:from-green1{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .sm\:hover\:from-red1:hover{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .sm\:hover\:from-red2:hover{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .sm\:hover\:from-red3:hover{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .sm\:hover\:from-red4:hover{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .sm\:hover\:from-red5:hover{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .sm\:hover\:from-cyan1:hover{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .sm\:hover\:from-cyan2:hover{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .sm\:hover\:from-cyan3:hover{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .sm\:hover\:from-cyan4:hover{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .sm\:hover\:from-cyan5:hover{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .sm\:hover\:from-bg1:hover{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .sm\:hover\:from-bg2:hover{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-bg3:hover{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .sm\:hover\:from-gray1:hover{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .sm\:hover\:from-gray2:hover{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .sm\:hover\:from-gray3:hover{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .sm\:hover\:from-gray4:hover{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .sm\:hover\:from-gray5:hover{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .sm\:hover\:from-gray6:hover{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .sm\:hover\:from-gray7:hover{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:hover\:from-white:hover{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-warning:hover{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .sm\:hover\:from-error:hover{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .sm\:hover\:from-info:hover{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .sm\:hover\:from-success:hover{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .sm\:hover\:from-special4:hover{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .sm\:hover\:from-dark:hover{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:from-transparent:hover{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:from-secondary1:hover{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .sm\:hover\:from-secondary2:hover{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:hover\:from-secondary3:hover{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .sm\:hover\:from-secondary4:hover{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .sm\:hover\:from-secondary5:hover{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .sm\:hover\:from-green1:hover{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .sm\:focus\:from-red1:focus{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .sm\:focus\:from-red2:focus{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .sm\:focus\:from-red3:focus{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .sm\:focus\:from-red4:focus{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .sm\:focus\:from-red5:focus{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .sm\:focus\:from-cyan1:focus{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .sm\:focus\:from-cyan2:focus{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .sm\:focus\:from-cyan3:focus{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .sm\:focus\:from-cyan4:focus{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .sm\:focus\:from-cyan5:focus{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .sm\:focus\:from-bg1:focus{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .sm\:focus\:from-bg2:focus{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-bg3:focus{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .sm\:focus\:from-gray1:focus{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .sm\:focus\:from-gray2:focus{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .sm\:focus\:from-gray3:focus{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .sm\:focus\:from-gray4:focus{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .sm\:focus\:from-gray5:focus{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .sm\:focus\:from-gray6:focus{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .sm\:focus\:from-gray7:focus{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:focus\:from-white:focus{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-warning:focus{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .sm\:focus\:from-error:focus{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .sm\:focus\:from-info:focus{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .sm\:focus\:from-success:focus{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .sm\:focus\:from-special4:focus{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .sm\:focus\:from-dark:focus{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:from-transparent:focus{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:from-secondary1:focus{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .sm\:focus\:from-secondary2:focus{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:focus\:from-secondary3:focus{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .sm\:focus\:from-secondary4:focus{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .sm\:focus\:from-secondary5:focus{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .sm\:focus\:from-green1:focus{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .sm\:via-red1{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .sm\:via-red2{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .sm\:via-red3{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .sm\:via-red4{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .sm\:via-red5{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .sm\:via-cyan1{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .sm\:via-cyan2{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .sm\:via-cyan3{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .sm\:via-cyan4{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .sm\:via-cyan5{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .sm\:via-bg1{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .sm\:via-bg2{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:via-bg3{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .sm\:via-gray1{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .sm\:via-gray2{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .sm\:via-gray3{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .sm\:via-gray4{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .sm\:via-gray5{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .sm\:via-gray6{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .sm\:via-gray7{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:via-white{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:via-warning{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .sm\:via-error{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .sm\:via-info{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .sm\:via-success{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .sm\:via-special4{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .sm\:via-dark{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:via-transparent{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:via-secondary1{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .sm\:via-secondary2{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:via-secondary3{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .sm\:via-secondary4{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .sm\:via-secondary5{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .sm\:via-green1{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .sm\:hover\:via-red1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .sm\:hover\:via-red2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .sm\:hover\:via-red3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .sm\:hover\:via-red4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .sm\:hover\:via-red5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .sm\:hover\:via-cyan1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .sm\:hover\:via-cyan2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .sm\:hover\:via-cyan3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .sm\:hover\:via-cyan4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .sm\:hover\:via-cyan5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .sm\:hover\:via-bg1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .sm\:hover\:via-bg2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-bg3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .sm\:hover\:via-gray1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .sm\:hover\:via-gray2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .sm\:hover\:via-gray3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .sm\:hover\:via-gray4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .sm\:hover\:via-gray5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .sm\:hover\:via-gray6:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .sm\:hover\:via-gray7:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:hover\:via-white:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-warning:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .sm\:hover\:via-error:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .sm\:hover\:via-info:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .sm\:hover\:via-success:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .sm\:hover\:via-special4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .sm\:hover\:via-dark:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:via-transparent:hover{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:hover\:via-secondary1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .sm\:hover\:via-secondary2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:hover\:via-secondary3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .sm\:hover\:via-secondary4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .sm\:hover\:via-secondary5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .sm\:hover\:via-green1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .sm\:focus\:via-red1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .sm\:focus\:via-red2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .sm\:focus\:via-red3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .sm\:focus\:via-red4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .sm\:focus\:via-red5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .sm\:focus\:via-cyan1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .sm\:focus\:via-cyan2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .sm\:focus\:via-cyan3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .sm\:focus\:via-cyan4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .sm\:focus\:via-cyan5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .sm\:focus\:via-bg1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .sm\:focus\:via-bg2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-bg3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .sm\:focus\:via-gray1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .sm\:focus\:via-gray2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .sm\:focus\:via-gray3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .sm\:focus\:via-gray4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .sm\:focus\:via-gray5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .sm\:focus\:via-gray6:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .sm\:focus\:via-gray7:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:focus\:via-white:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-warning:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .sm\:focus\:via-error:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .sm\:focus\:via-info:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .sm\:focus\:via-success:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .sm\:focus\:via-special4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .sm\:focus\:via-dark:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:via-transparent:focus{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .sm\:focus\:via-secondary1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .sm\:focus\:via-secondary2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .sm\:focus\:via-secondary3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .sm\:focus\:via-secondary4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .sm\:focus\:via-secondary5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .sm\:focus\:via-green1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .sm\:to-red1{
    --tw-gradient-to:#CC352C
  }

  .sm\:to-red2{
    --tw-gradient-to:#FF554B
  }

  .sm\:to-red3{
    --tw-gradient-to:#FF7B73
  }

  .sm\:to-red4{
    --tw-gradient-to:#FFF1F1
  }

  .sm\:to-red5{
    --tw-gradient-to:#FFF6F6
  }

  .sm\:to-cyan1{
    --tw-gradient-to:#009897
  }

  .sm\:to-cyan2{
    --tw-gradient-to:#00BFBD
  }

  .sm\:to-cyan3{
    --tw-gradient-to:#4CD1D0
  }

  .sm\:to-cyan4{
    --tw-gradient-to:#B2ECEB
  }

  .sm\:to-cyan5{
    --tw-gradient-to:#EDFAFA
  }

  .sm\:to-bg1{
    --tw-gradient-to:#FAFAFC
  }

  .sm\:to-bg2{
    --tw-gradient-to:#ffffff
  }

  .sm\:to-bg3{
    --tw-gradient-to:#F7F7FA
  }

  .sm\:to-gray1{
    --tw-gradient-to:#333333
  }

  .sm\:to-gray2{
    --tw-gradient-to:#4D4D4D
  }

  .sm\:to-gray3{
    --tw-gradient-to:#8C8C8C
  }

  .sm\:to-gray4{
    --tw-gradient-to:#BFBFBF
  }

  .sm\:to-gray5{
    --tw-gradient-to:#D9D9D9
  }

  .sm\:to-gray6{
    --tw-gradient-to:#E9E9EB
  }

  .sm\:to-gray7{
    --tw-gradient-to:#515164
  }

  .sm\:to-white{
    --tw-gradient-to:#ffffff
  }

  .sm\:to-warning{
    --tw-gradient-to:#FFC319
  }

  .sm\:to-error{
    --tw-gradient-to:#EA696F
  }

  .sm\:to-info{
    --tw-gradient-to:#0071B9
  }

  .sm\:to-success{
    --tw-gradient-to:#98D45F
  }

  .sm\:to-special4{
    --tw-gradient-to:#59779A
  }

  .sm\:to-dark{
    --tw-gradient-to:#000000
  }

  .sm\:to-transparent{
    --tw-gradient-to:transparent
  }

  .sm\:to-secondary1{
    --tw-gradient-to:#313140
  }

  .sm\:to-secondary2{
    --tw-gradient-to:#515164
  }

  .sm\:to-secondary3{
    --tw-gradient-to:#9C9CAC
  }

  .sm\:to-secondary4{
    --tw-gradient-to:#E5E8ED
  }

  .sm\:to-secondary5{
    --tw-gradient-to:#F5F6F9
  }

  .sm\:to-green1{
    --tw-gradient-to:#0DE298
  }

  .sm\:hover\:to-red1:hover{
    --tw-gradient-to:#CC352C
  }

  .sm\:hover\:to-red2:hover{
    --tw-gradient-to:#FF554B
  }

  .sm\:hover\:to-red3:hover{
    --tw-gradient-to:#FF7B73
  }

  .sm\:hover\:to-red4:hover{
    --tw-gradient-to:#FFF1F1
  }

  .sm\:hover\:to-red5:hover{
    --tw-gradient-to:#FFF6F6
  }

  .sm\:hover\:to-cyan1:hover{
    --tw-gradient-to:#009897
  }

  .sm\:hover\:to-cyan2:hover{
    --tw-gradient-to:#00BFBD
  }

  .sm\:hover\:to-cyan3:hover{
    --tw-gradient-to:#4CD1D0
  }

  .sm\:hover\:to-cyan4:hover{
    --tw-gradient-to:#B2ECEB
  }

  .sm\:hover\:to-cyan5:hover{
    --tw-gradient-to:#EDFAFA
  }

  .sm\:hover\:to-bg1:hover{
    --tw-gradient-to:#FAFAFC
  }

  .sm\:hover\:to-bg2:hover{
    --tw-gradient-to:#ffffff
  }

  .sm\:hover\:to-bg3:hover{
    --tw-gradient-to:#F7F7FA
  }

  .sm\:hover\:to-gray1:hover{
    --tw-gradient-to:#333333
  }

  .sm\:hover\:to-gray2:hover{
    --tw-gradient-to:#4D4D4D
  }

  .sm\:hover\:to-gray3:hover{
    --tw-gradient-to:#8C8C8C
  }

  .sm\:hover\:to-gray4:hover{
    --tw-gradient-to:#BFBFBF
  }

  .sm\:hover\:to-gray5:hover{
    --tw-gradient-to:#D9D9D9
  }

  .sm\:hover\:to-gray6:hover{
    --tw-gradient-to:#E9E9EB
  }

  .sm\:hover\:to-gray7:hover{
    --tw-gradient-to:#515164
  }

  .sm\:hover\:to-white:hover{
    --tw-gradient-to:#ffffff
  }

  .sm\:hover\:to-warning:hover{
    --tw-gradient-to:#FFC319
  }

  .sm\:hover\:to-error:hover{
    --tw-gradient-to:#EA696F
  }

  .sm\:hover\:to-info:hover{
    --tw-gradient-to:#0071B9
  }

  .sm\:hover\:to-success:hover{
    --tw-gradient-to:#98D45F
  }

  .sm\:hover\:to-special4:hover{
    --tw-gradient-to:#59779A
  }

  .sm\:hover\:to-dark:hover{
    --tw-gradient-to:#000000
  }

  .sm\:hover\:to-transparent:hover{
    --tw-gradient-to:transparent
  }

  .sm\:hover\:to-secondary1:hover{
    --tw-gradient-to:#313140
  }

  .sm\:hover\:to-secondary2:hover{
    --tw-gradient-to:#515164
  }

  .sm\:hover\:to-secondary3:hover{
    --tw-gradient-to:#9C9CAC
  }

  .sm\:hover\:to-secondary4:hover{
    --tw-gradient-to:#E5E8ED
  }

  .sm\:hover\:to-secondary5:hover{
    --tw-gradient-to:#F5F6F9
  }

  .sm\:hover\:to-green1:hover{
    --tw-gradient-to:#0DE298
  }

  .sm\:focus\:to-red1:focus{
    --tw-gradient-to:#CC352C
  }

  .sm\:focus\:to-red2:focus{
    --tw-gradient-to:#FF554B
  }

  .sm\:focus\:to-red3:focus{
    --tw-gradient-to:#FF7B73
  }

  .sm\:focus\:to-red4:focus{
    --tw-gradient-to:#FFF1F1
  }

  .sm\:focus\:to-red5:focus{
    --tw-gradient-to:#FFF6F6
  }

  .sm\:focus\:to-cyan1:focus{
    --tw-gradient-to:#009897
  }

  .sm\:focus\:to-cyan2:focus{
    --tw-gradient-to:#00BFBD
  }

  .sm\:focus\:to-cyan3:focus{
    --tw-gradient-to:#4CD1D0
  }

  .sm\:focus\:to-cyan4:focus{
    --tw-gradient-to:#B2ECEB
  }

  .sm\:focus\:to-cyan5:focus{
    --tw-gradient-to:#EDFAFA
  }

  .sm\:focus\:to-bg1:focus{
    --tw-gradient-to:#FAFAFC
  }

  .sm\:focus\:to-bg2:focus{
    --tw-gradient-to:#ffffff
  }

  .sm\:focus\:to-bg3:focus{
    --tw-gradient-to:#F7F7FA
  }

  .sm\:focus\:to-gray1:focus{
    --tw-gradient-to:#333333
  }

  .sm\:focus\:to-gray2:focus{
    --tw-gradient-to:#4D4D4D
  }

  .sm\:focus\:to-gray3:focus{
    --tw-gradient-to:#8C8C8C
  }

  .sm\:focus\:to-gray4:focus{
    --tw-gradient-to:#BFBFBF
  }

  .sm\:focus\:to-gray5:focus{
    --tw-gradient-to:#D9D9D9
  }

  .sm\:focus\:to-gray6:focus{
    --tw-gradient-to:#E9E9EB
  }

  .sm\:focus\:to-gray7:focus{
    --tw-gradient-to:#515164
  }

  .sm\:focus\:to-white:focus{
    --tw-gradient-to:#ffffff
  }

  .sm\:focus\:to-warning:focus{
    --tw-gradient-to:#FFC319
  }

  .sm\:focus\:to-error:focus{
    --tw-gradient-to:#EA696F
  }

  .sm\:focus\:to-info:focus{
    --tw-gradient-to:#0071B9
  }

  .sm\:focus\:to-success:focus{
    --tw-gradient-to:#98D45F
  }

  .sm\:focus\:to-special4:focus{
    --tw-gradient-to:#59779A
  }

  .sm\:focus\:to-dark:focus{
    --tw-gradient-to:#000000
  }

  .sm\:focus\:to-transparent:focus{
    --tw-gradient-to:transparent
  }

  .sm\:focus\:to-secondary1:focus{
    --tw-gradient-to:#313140
  }

  .sm\:focus\:to-secondary2:focus{
    --tw-gradient-to:#515164
  }

  .sm\:focus\:to-secondary3:focus{
    --tw-gradient-to:#9C9CAC
  }

  .sm\:focus\:to-secondary4:focus{
    --tw-gradient-to:#E5E8ED
  }

  .sm\:focus\:to-secondary5:focus{
    --tw-gradient-to:#F5F6F9
  }

  .sm\:focus\:to-green1:focus{
    --tw-gradient-to:#0DE298
  }

  .sm\:decoration-slice{
    -webkit-box-decoration-break:slice;
            box-decoration-break:slice
  }

  .sm\:decoration-clone{
    -webkit-box-decoration-break:clone;
            box-decoration-break:clone
  }

  .sm\:bg-auto{
    background-size:auto
  }

  .sm\:bg-cover{
    background-size:cover
  }

  .sm\:bg-contain{
    background-size:contain
  }

  .sm\:bg-clip-border{
    background-clip:border-box
  }

  .sm\:bg-clip-padding{
    background-clip:padding-box
  }

  .sm\:bg-clip-content{
    background-clip:content-box
  }

  .sm\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .sm\:bg-bottom{
    background-position:bottom
  }

  .sm\:bg-center{
    background-position:center
  }

  .sm\:bg-left{
    background-position:left
  }

  .sm\:bg-left-bottom{
    background-position:left bottom
  }

  .sm\:bg-left-top{
    background-position:left top
  }

  .sm\:bg-right{
    background-position:right
  }

  .sm\:bg-right-bottom{
    background-position:right bottom
  }

  .sm\:bg-right-top{
    background-position:right top
  }

  .sm\:bg-top{
    background-position:top
  }

  .sm\:bg-repeat{
    background-repeat:repeat
  }

  .sm\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .sm\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .sm\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .sm\:bg-repeat-round{
    background-repeat:round
  }

  .sm\:bg-repeat-space{
    background-repeat:space
  }

  .sm\:bg-origin-border{
    background-origin:border-box
  }

  .sm\:bg-origin-padding{
    background-origin:padding-box
  }

  .sm\:bg-origin-content{
    background-origin:content-box
  }

  .sm\:fill-current{
    fill:currentColor
  }

  .sm\:object-contain{
    object-fit:contain
  }

  .sm\:object-cover{
    object-fit:cover
  }

  .sm\:object-fill{
    object-fit:fill
  }

  .sm\:object-none{
    object-fit:none
  }

  .sm\:object-scale-down{
    object-fit:scale-down
  }

  .sm\:p-0{
    padding:0
  }

  .sm\:p-1{
    padding:4px
  }

  .sm\:p-2{
    padding:8px
  }

  .sm\:p-3{
    padding:12px
  }

  .sm\:p-4{
    padding:16px
  }

  .sm\:p-5{
    padding:20px
  }

  .sm\:p-6{
    padding:24px
  }

  .sm\:p-8{
    padding:32px
  }

  .sm\:p-10{
    padding:40px
  }

  .sm\:p-12{
    padding:48px
  }

  .sm\:p-16{
    padding:64px
  }

  .sm\:p-18{
    padding:72px
  }

  .sm\:p-24{
    padding:96px
  }

  .sm\:p-5dot5{
    padding:22px
  }

  .sm\:p-full{
    padding:100%
  }

  .sm\:first\:p-0:first-child{
    padding:0
  }

  .sm\:first\:p-1:first-child{
    padding:4px
  }

  .sm\:first\:p-2:first-child{
    padding:8px
  }

  .sm\:first\:p-3:first-child{
    padding:12px
  }

  .sm\:first\:p-4:first-child{
    padding:16px
  }

  .sm\:first\:p-5:first-child{
    padding:20px
  }

  .sm\:first\:p-6:first-child{
    padding:24px
  }

  .sm\:first\:p-8:first-child{
    padding:32px
  }

  .sm\:first\:p-10:first-child{
    padding:40px
  }

  .sm\:first\:p-12:first-child{
    padding:48px
  }

  .sm\:first\:p-16:first-child{
    padding:64px
  }

  .sm\:first\:p-18:first-child{
    padding:72px
  }

  .sm\:first\:p-24:first-child{
    padding:96px
  }

  .sm\:first\:p-5dot5:first-child{
    padding:22px
  }

  .sm\:first\:p-full:first-child{
    padding:100%
  }

  .sm\:last\:p-0:last-child{
    padding:0
  }

  .sm\:last\:p-1:last-child{
    padding:4px
  }

  .sm\:last\:p-2:last-child{
    padding:8px
  }

  .sm\:last\:p-3:last-child{
    padding:12px
  }

  .sm\:last\:p-4:last-child{
    padding:16px
  }

  .sm\:last\:p-5:last-child{
    padding:20px
  }

  .sm\:last\:p-6:last-child{
    padding:24px
  }

  .sm\:last\:p-8:last-child{
    padding:32px
  }

  .sm\:last\:p-10:last-child{
    padding:40px
  }

  .sm\:last\:p-12:last-child{
    padding:48px
  }

  .sm\:last\:p-16:last-child{
    padding:64px
  }

  .sm\:last\:p-18:last-child{
    padding:72px
  }

  .sm\:last\:p-24:last-child{
    padding:96px
  }

  .sm\:last\:p-5dot5:last-child{
    padding:22px
  }

  .sm\:last\:p-full:last-child{
    padding:100%
  }

  .sm\:px-0{
    padding-left:0;
    padding-right:0
  }

  .sm\:px-1{
    padding-left:4px;
    padding-right:4px
  }

  .sm\:px-2{
    padding-left:8px;
    padding-right:8px
  }

  .sm\:px-3{
    padding-left:12px;
    padding-right:12px
  }

  .sm\:px-4{
    padding-left:16px;
    padding-right:16px
  }

  .sm\:px-5{
    padding-left:20px;
    padding-right:20px
  }

  .sm\:px-6{
    padding-left:24px;
    padding-right:24px
  }

  .sm\:px-8{
    padding-left:32px;
    padding-right:32px
  }

  .sm\:px-10{
    padding-left:40px;
    padding-right:40px
  }

  .sm\:px-12{
    padding-left:48px;
    padding-right:48px
  }

  .sm\:px-16{
    padding-left:64px;
    padding-right:64px
  }

  .sm\:px-18{
    padding-left:72px;
    padding-right:72px
  }

  .sm\:px-24{
    padding-left:96px;
    padding-right:96px
  }

  .sm\:px-5dot5{
    padding-left:22px;
    padding-right:22px
  }

  .sm\:px-full{
    padding-left:100%;
    padding-right:100%
  }

  .sm\:py-0{
    padding-top:0;
    padding-bottom:0
  }

  .sm\:py-1{
    padding-top:4px;
    padding-bottom:4px
  }

  .sm\:py-2{
    padding-top:8px;
    padding-bottom:8px
  }

  .sm\:py-3{
    padding-top:12px;
    padding-bottom:12px
  }

  .sm\:py-4{
    padding-top:16px;
    padding-bottom:16px
  }

  .sm\:py-5{
    padding-top:20px;
    padding-bottom:20px
  }

  .sm\:py-6{
    padding-top:24px;
    padding-bottom:24px
  }

  .sm\:py-8{
    padding-top:32px;
    padding-bottom:32px
  }

  .sm\:py-10{
    padding-top:40px;
    padding-bottom:40px
  }

  .sm\:py-12{
    padding-top:48px;
    padding-bottom:48px
  }

  .sm\:py-16{
    padding-top:64px;
    padding-bottom:64px
  }

  .sm\:py-18{
    padding-top:72px;
    padding-bottom:72px
  }

  .sm\:py-24{
    padding-top:96px;
    padding-bottom:96px
  }

  .sm\:py-5dot5{
    padding-top:22px;
    padding-bottom:22px
  }

  .sm\:py-full{
    padding-top:100%;
    padding-bottom:100%
  }

  .sm\:first\:px-0:first-child{
    padding-left:0;
    padding-right:0
  }

  .sm\:first\:px-1:first-child{
    padding-left:4px;
    padding-right:4px
  }

  .sm\:first\:px-2:first-child{
    padding-left:8px;
    padding-right:8px
  }

  .sm\:first\:px-3:first-child{
    padding-left:12px;
    padding-right:12px
  }

  .sm\:first\:px-4:first-child{
    padding-left:16px;
    padding-right:16px
  }

  .sm\:first\:px-5:first-child{
    padding-left:20px;
    padding-right:20px
  }

  .sm\:first\:px-6:first-child{
    padding-left:24px;
    padding-right:24px
  }

  .sm\:first\:px-8:first-child{
    padding-left:32px;
    padding-right:32px
  }

  .sm\:first\:px-10:first-child{
    padding-left:40px;
    padding-right:40px
  }

  .sm\:first\:px-12:first-child{
    padding-left:48px;
    padding-right:48px
  }

  .sm\:first\:px-16:first-child{
    padding-left:64px;
    padding-right:64px
  }

  .sm\:first\:px-18:first-child{
    padding-left:72px;
    padding-right:72px
  }

  .sm\:first\:px-24:first-child{
    padding-left:96px;
    padding-right:96px
  }

  .sm\:first\:px-5dot5:first-child{
    padding-left:22px;
    padding-right:22px
  }

  .sm\:first\:px-full:first-child{
    padding-left:100%;
    padding-right:100%
  }

  .sm\:first\:py-0:first-child{
    padding-top:0;
    padding-bottom:0
  }

  .sm\:first\:py-1:first-child{
    padding-top:4px;
    padding-bottom:4px
  }

  .sm\:first\:py-2:first-child{
    padding-top:8px;
    padding-bottom:8px
  }

  .sm\:first\:py-3:first-child{
    padding-top:12px;
    padding-bottom:12px
  }

  .sm\:first\:py-4:first-child{
    padding-top:16px;
    padding-bottom:16px
  }

  .sm\:first\:py-5:first-child{
    padding-top:20px;
    padding-bottom:20px
  }

  .sm\:first\:py-6:first-child{
    padding-top:24px;
    padding-bottom:24px
  }

  .sm\:first\:py-8:first-child{
    padding-top:32px;
    padding-bottom:32px
  }

  .sm\:first\:py-10:first-child{
    padding-top:40px;
    padding-bottom:40px
  }

  .sm\:first\:py-12:first-child{
    padding-top:48px;
    padding-bottom:48px
  }

  .sm\:first\:py-16:first-child{
    padding-top:64px;
    padding-bottom:64px
  }

  .sm\:first\:py-18:first-child{
    padding-top:72px;
    padding-bottom:72px
  }

  .sm\:first\:py-24:first-child{
    padding-top:96px;
    padding-bottom:96px
  }

  .sm\:first\:py-5dot5:first-child{
    padding-top:22px;
    padding-bottom:22px
  }

  .sm\:first\:py-full:first-child{
    padding-top:100%;
    padding-bottom:100%
  }

  .sm\:last\:px-0:last-child{
    padding-left:0;
    padding-right:0
  }

  .sm\:last\:px-1:last-child{
    padding-left:4px;
    padding-right:4px
  }

  .sm\:last\:px-2:last-child{
    padding-left:8px;
    padding-right:8px
  }

  .sm\:last\:px-3:last-child{
    padding-left:12px;
    padding-right:12px
  }

  .sm\:last\:px-4:last-child{
    padding-left:16px;
    padding-right:16px
  }

  .sm\:last\:px-5:last-child{
    padding-left:20px;
    padding-right:20px
  }

  .sm\:last\:px-6:last-child{
    padding-left:24px;
    padding-right:24px
  }

  .sm\:last\:px-8:last-child{
    padding-left:32px;
    padding-right:32px
  }

  .sm\:last\:px-10:last-child{
    padding-left:40px;
    padding-right:40px
  }

  .sm\:last\:px-12:last-child{
    padding-left:48px;
    padding-right:48px
  }

  .sm\:last\:px-16:last-child{
    padding-left:64px;
    padding-right:64px
  }

  .sm\:last\:px-18:last-child{
    padding-left:72px;
    padding-right:72px
  }

  .sm\:last\:px-24:last-child{
    padding-left:96px;
    padding-right:96px
  }

  .sm\:last\:px-5dot5:last-child{
    padding-left:22px;
    padding-right:22px
  }

  .sm\:last\:px-full:last-child{
    padding-left:100%;
    padding-right:100%
  }

  .sm\:last\:py-0:last-child{
    padding-top:0;
    padding-bottom:0
  }

  .sm\:last\:py-1:last-child{
    padding-top:4px;
    padding-bottom:4px
  }

  .sm\:last\:py-2:last-child{
    padding-top:8px;
    padding-bottom:8px
  }

  .sm\:last\:py-3:last-child{
    padding-top:12px;
    padding-bottom:12px
  }

  .sm\:last\:py-4:last-child{
    padding-top:16px;
    padding-bottom:16px
  }

  .sm\:last\:py-5:last-child{
    padding-top:20px;
    padding-bottom:20px
  }

  .sm\:last\:py-6:last-child{
    padding-top:24px;
    padding-bottom:24px
  }

  .sm\:last\:py-8:last-child{
    padding-top:32px;
    padding-bottom:32px
  }

  .sm\:last\:py-10:last-child{
    padding-top:40px;
    padding-bottom:40px
  }

  .sm\:last\:py-12:last-child{
    padding-top:48px;
    padding-bottom:48px
  }

  .sm\:last\:py-16:last-child{
    padding-top:64px;
    padding-bottom:64px
  }

  .sm\:last\:py-18:last-child{
    padding-top:72px;
    padding-bottom:72px
  }

  .sm\:last\:py-24:last-child{
    padding-top:96px;
    padding-bottom:96px
  }

  .sm\:last\:py-5dot5:last-child{
    padding-top:22px;
    padding-bottom:22px
  }

  .sm\:last\:py-full:last-child{
    padding-top:100%;
    padding-bottom:100%
  }

  .sm\:pt-0{
    padding-top:0
  }

  .sm\:pt-1{
    padding-top:4px
  }

  .sm\:pt-2{
    padding-top:8px
  }

  .sm\:pt-3{
    padding-top:12px
  }

  .sm\:pt-4{
    padding-top:16px
  }

  .sm\:pt-5{
    padding-top:20px
  }

  .sm\:pt-6{
    padding-top:24px
  }

  .sm\:pt-8{
    padding-top:32px
  }

  .sm\:pt-10{
    padding-top:40px
  }

  .sm\:pt-12{
    padding-top:48px
  }

  .sm\:pt-16{
    padding-top:64px
  }

  .sm\:pt-18{
    padding-top:72px
  }

  .sm\:pt-24{
    padding-top:96px
  }

  .sm\:pt-5dot5{
    padding-top:22px
  }

  .sm\:pt-full{
    padding-top:100%
  }

  .sm\:pr-0{
    padding-right:0
  }

  .sm\:pr-1{
    padding-right:4px
  }

  .sm\:pr-2{
    padding-right:8px
  }

  .sm\:pr-3{
    padding-right:12px
  }

  .sm\:pr-4{
    padding-right:16px
  }

  .sm\:pr-5{
    padding-right:20px
  }

  .sm\:pr-6{
    padding-right:24px
  }

  .sm\:pr-8{
    padding-right:32px
  }

  .sm\:pr-10{
    padding-right:40px
  }

  .sm\:pr-12{
    padding-right:48px
  }

  .sm\:pr-16{
    padding-right:64px
  }

  .sm\:pr-18{
    padding-right:72px
  }

  .sm\:pr-24{
    padding-right:96px
  }

  .sm\:pr-5dot5{
    padding-right:22px
  }

  .sm\:pr-full{
    padding-right:100%
  }

  .sm\:pb-0{
    padding-bottom:0
  }

  .sm\:pb-1{
    padding-bottom:4px
  }

  .sm\:pb-2{
    padding-bottom:8px
  }

  .sm\:pb-3{
    padding-bottom:12px
  }

  .sm\:pb-4{
    padding-bottom:16px
  }

  .sm\:pb-5{
    padding-bottom:20px
  }

  .sm\:pb-6{
    padding-bottom:24px
  }

  .sm\:pb-8{
    padding-bottom:32px
  }

  .sm\:pb-10{
    padding-bottom:40px
  }

  .sm\:pb-12{
    padding-bottom:48px
  }

  .sm\:pb-16{
    padding-bottom:64px
  }

  .sm\:pb-18{
    padding-bottom:72px
  }

  .sm\:pb-24{
    padding-bottom:96px
  }

  .sm\:pb-5dot5{
    padding-bottom:22px
  }

  .sm\:pb-full{
    padding-bottom:100%
  }

  .sm\:pl-0{
    padding-left:0
  }

  .sm\:pl-1{
    padding-left:4px
  }

  .sm\:pl-2{
    padding-left:8px
  }

  .sm\:pl-3{
    padding-left:12px
  }

  .sm\:pl-4{
    padding-left:16px
  }

  .sm\:pl-5{
    padding-left:20px
  }

  .sm\:pl-6{
    padding-left:24px
  }

  .sm\:pl-8{
    padding-left:32px
  }

  .sm\:pl-10{
    padding-left:40px
  }

  .sm\:pl-12{
    padding-left:48px
  }

  .sm\:pl-16{
    padding-left:64px
  }

  .sm\:pl-18{
    padding-left:72px
  }

  .sm\:pl-24{
    padding-left:96px
  }

  .sm\:pl-5dot5{
    padding-left:22px
  }

  .sm\:pl-full{
    padding-left:100%
  }

  .sm\:first\:pt-0:first-child{
    padding-top:0
  }

  .sm\:first\:pt-1:first-child{
    padding-top:4px
  }

  .sm\:first\:pt-2:first-child{
    padding-top:8px
  }

  .sm\:first\:pt-3:first-child{
    padding-top:12px
  }

  .sm\:first\:pt-4:first-child{
    padding-top:16px
  }

  .sm\:first\:pt-5:first-child{
    padding-top:20px
  }

  .sm\:first\:pt-6:first-child{
    padding-top:24px
  }

  .sm\:first\:pt-8:first-child{
    padding-top:32px
  }

  .sm\:first\:pt-10:first-child{
    padding-top:40px
  }

  .sm\:first\:pt-12:first-child{
    padding-top:48px
  }

  .sm\:first\:pt-16:first-child{
    padding-top:64px
  }

  .sm\:first\:pt-18:first-child{
    padding-top:72px
  }

  .sm\:first\:pt-24:first-child{
    padding-top:96px
  }

  .sm\:first\:pt-5dot5:first-child{
    padding-top:22px
  }

  .sm\:first\:pt-full:first-child{
    padding-top:100%
  }

  .sm\:first\:pr-0:first-child{
    padding-right:0
  }

  .sm\:first\:pr-1:first-child{
    padding-right:4px
  }

  .sm\:first\:pr-2:first-child{
    padding-right:8px
  }

  .sm\:first\:pr-3:first-child{
    padding-right:12px
  }

  .sm\:first\:pr-4:first-child{
    padding-right:16px
  }

  .sm\:first\:pr-5:first-child{
    padding-right:20px
  }

  .sm\:first\:pr-6:first-child{
    padding-right:24px
  }

  .sm\:first\:pr-8:first-child{
    padding-right:32px
  }

  .sm\:first\:pr-10:first-child{
    padding-right:40px
  }

  .sm\:first\:pr-12:first-child{
    padding-right:48px
  }

  .sm\:first\:pr-16:first-child{
    padding-right:64px
  }

  .sm\:first\:pr-18:first-child{
    padding-right:72px
  }

  .sm\:first\:pr-24:first-child{
    padding-right:96px
  }

  .sm\:first\:pr-5dot5:first-child{
    padding-right:22px
  }

  .sm\:first\:pr-full:first-child{
    padding-right:100%
  }

  .sm\:first\:pb-0:first-child{
    padding-bottom:0
  }

  .sm\:first\:pb-1:first-child{
    padding-bottom:4px
  }

  .sm\:first\:pb-2:first-child{
    padding-bottom:8px
  }

  .sm\:first\:pb-3:first-child{
    padding-bottom:12px
  }

  .sm\:first\:pb-4:first-child{
    padding-bottom:16px
  }

  .sm\:first\:pb-5:first-child{
    padding-bottom:20px
  }

  .sm\:first\:pb-6:first-child{
    padding-bottom:24px
  }

  .sm\:first\:pb-8:first-child{
    padding-bottom:32px
  }

  .sm\:first\:pb-10:first-child{
    padding-bottom:40px
  }

  .sm\:first\:pb-12:first-child{
    padding-bottom:48px
  }

  .sm\:first\:pb-16:first-child{
    padding-bottom:64px
  }

  .sm\:first\:pb-18:first-child{
    padding-bottom:72px
  }

  .sm\:first\:pb-24:first-child{
    padding-bottom:96px
  }

  .sm\:first\:pb-5dot5:first-child{
    padding-bottom:22px
  }

  .sm\:first\:pb-full:first-child{
    padding-bottom:100%
  }

  .sm\:first\:pl-0:first-child{
    padding-left:0
  }

  .sm\:first\:pl-1:first-child{
    padding-left:4px
  }

  .sm\:first\:pl-2:first-child{
    padding-left:8px
  }

  .sm\:first\:pl-3:first-child{
    padding-left:12px
  }

  .sm\:first\:pl-4:first-child{
    padding-left:16px
  }

  .sm\:first\:pl-5:first-child{
    padding-left:20px
  }

  .sm\:first\:pl-6:first-child{
    padding-left:24px
  }

  .sm\:first\:pl-8:first-child{
    padding-left:32px
  }

  .sm\:first\:pl-10:first-child{
    padding-left:40px
  }

  .sm\:first\:pl-12:first-child{
    padding-left:48px
  }

  .sm\:first\:pl-16:first-child{
    padding-left:64px
  }

  .sm\:first\:pl-18:first-child{
    padding-left:72px
  }

  .sm\:first\:pl-24:first-child{
    padding-left:96px
  }

  .sm\:first\:pl-5dot5:first-child{
    padding-left:22px
  }

  .sm\:first\:pl-full:first-child{
    padding-left:100%
  }

  .sm\:last\:pt-0:last-child{
    padding-top:0
  }

  .sm\:last\:pt-1:last-child{
    padding-top:4px
  }

  .sm\:last\:pt-2:last-child{
    padding-top:8px
  }

  .sm\:last\:pt-3:last-child{
    padding-top:12px
  }

  .sm\:last\:pt-4:last-child{
    padding-top:16px
  }

  .sm\:last\:pt-5:last-child{
    padding-top:20px
  }

  .sm\:last\:pt-6:last-child{
    padding-top:24px
  }

  .sm\:last\:pt-8:last-child{
    padding-top:32px
  }

  .sm\:last\:pt-10:last-child{
    padding-top:40px
  }

  .sm\:last\:pt-12:last-child{
    padding-top:48px
  }

  .sm\:last\:pt-16:last-child{
    padding-top:64px
  }

  .sm\:last\:pt-18:last-child{
    padding-top:72px
  }

  .sm\:last\:pt-24:last-child{
    padding-top:96px
  }

  .sm\:last\:pt-5dot5:last-child{
    padding-top:22px
  }

  .sm\:last\:pt-full:last-child{
    padding-top:100%
  }

  .sm\:last\:pr-0:last-child{
    padding-right:0
  }

  .sm\:last\:pr-1:last-child{
    padding-right:4px
  }

  .sm\:last\:pr-2:last-child{
    padding-right:8px
  }

  .sm\:last\:pr-3:last-child{
    padding-right:12px
  }

  .sm\:last\:pr-4:last-child{
    padding-right:16px
  }

  .sm\:last\:pr-5:last-child{
    padding-right:20px
  }

  .sm\:last\:pr-6:last-child{
    padding-right:24px
  }

  .sm\:last\:pr-8:last-child{
    padding-right:32px
  }

  .sm\:last\:pr-10:last-child{
    padding-right:40px
  }

  .sm\:last\:pr-12:last-child{
    padding-right:48px
  }

  .sm\:last\:pr-16:last-child{
    padding-right:64px
  }

  .sm\:last\:pr-18:last-child{
    padding-right:72px
  }

  .sm\:last\:pr-24:last-child{
    padding-right:96px
  }

  .sm\:last\:pr-5dot5:last-child{
    padding-right:22px
  }

  .sm\:last\:pr-full:last-child{
    padding-right:100%
  }

  .sm\:last\:pb-0:last-child{
    padding-bottom:0
  }

  .sm\:last\:pb-1:last-child{
    padding-bottom:4px
  }

  .sm\:last\:pb-2:last-child{
    padding-bottom:8px
  }

  .sm\:last\:pb-3:last-child{
    padding-bottom:12px
  }

  .sm\:last\:pb-4:last-child{
    padding-bottom:16px
  }

  .sm\:last\:pb-5:last-child{
    padding-bottom:20px
  }

  .sm\:last\:pb-6:last-child{
    padding-bottom:24px
  }

  .sm\:last\:pb-8:last-child{
    padding-bottom:32px
  }

  .sm\:last\:pb-10:last-child{
    padding-bottom:40px
  }

  .sm\:last\:pb-12:last-child{
    padding-bottom:48px
  }

  .sm\:last\:pb-16:last-child{
    padding-bottom:64px
  }

  .sm\:last\:pb-18:last-child{
    padding-bottom:72px
  }

  .sm\:last\:pb-24:last-child{
    padding-bottom:96px
  }

  .sm\:last\:pb-5dot5:last-child{
    padding-bottom:22px
  }

  .sm\:last\:pb-full:last-child{
    padding-bottom:100%
  }

  .sm\:last\:pl-0:last-child{
    padding-left:0
  }

  .sm\:last\:pl-1:last-child{
    padding-left:4px
  }

  .sm\:last\:pl-2:last-child{
    padding-left:8px
  }

  .sm\:last\:pl-3:last-child{
    padding-left:12px
  }

  .sm\:last\:pl-4:last-child{
    padding-left:16px
  }

  .sm\:last\:pl-5:last-child{
    padding-left:20px
  }

  .sm\:last\:pl-6:last-child{
    padding-left:24px
  }

  .sm\:last\:pl-8:last-child{
    padding-left:32px
  }

  .sm\:last\:pl-10:last-child{
    padding-left:40px
  }

  .sm\:last\:pl-12:last-child{
    padding-left:48px
  }

  .sm\:last\:pl-16:last-child{
    padding-left:64px
  }

  .sm\:last\:pl-18:last-child{
    padding-left:72px
  }

  .sm\:last\:pl-24:last-child{
    padding-left:96px
  }

  .sm\:last\:pl-5dot5:last-child{
    padding-left:22px
  }

  .sm\:last\:pl-full:last-child{
    padding-left:100%
  }

  .sm\:text-left{
    text-align:left
  }

  .sm\:text-center{
    text-align:center
  }

  .sm\:text-right{
    text-align:right
  }

  .sm\:text-justify{
    text-align:justify
  }

  .sm\:align-baseline{
    vertical-align:baseline
  }

  .sm\:align-top{
    vertical-align:top
  }

  .sm\:align-middle{
    vertical-align:middle
  }

  .sm\:align-bottom{
    vertical-align:bottom
  }

  .sm\:align-text-top{
    vertical-align:text-top
  }

  .sm\:align-text-bottom{
    vertical-align:text-bottom
  }

  .sm\:text-xs{
    font-size:10px
  }

  .sm\:text-s{
    font-size:12px
  }

  .sm\:text-sm{
    font-size:14px
  }

  .sm\:text-tiny{
    font-size:12px
  }

  .sm\:text-base{
    font-size:16px
  }

  .sm\:text-lg{
    font-size:20px
  }

  .sm\:text-xl{
    font-size:24px
  }

  .sm\:text-2xl{
    font-size:32px
  }

  .sm\:text-3xl{
    font-size:40px
  }

  .sm\:text-4xl{
    font-size:48px
  }

  .sm\:text-5xl{
    font-size:64px
  }

  .sm\:font-thin{
    font-weight:100
  }

  .sm\:font-extralight{
    font-weight:200
  }

  .sm\:font-light{
    font-weight:300
  }

  .sm\:font-normal{
    font-weight:400
  }

  .sm\:font-medium{
    font-weight:500
  }

  .sm\:font-semibold{
    font-weight:600
  }

  .sm\:font-bold{
    font-weight:700
  }

  .sm\:font-extrabold{
    font-weight:800
  }

  .sm\:font-black{
    font-weight:900
  }

  .sm\:uppercase{
    text-transform:uppercase
  }

  .sm\:lowercase{
    text-transform:lowercase
  }

  .sm\:capitalize{
    text-transform:capitalize
  }

  .sm\:normal-case{
    text-transform:none
  }

  .sm\:italic{
    font-style:italic
  }

  .sm\:not-italic{
    font-style:normal
  }

  .sm\:ordinal, .sm\:slashed-zero, .sm\:lining-nums, .sm\:oldstyle-nums, .sm\:proportional-nums, .sm\:tabular-nums, .sm\:diagonal-fractions, .sm\:stacked-fractions{
    --tw-ordinal:var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction:var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric:var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  .sm\:normal-nums{
    font-variant-numeric:normal
  }

  .sm\:ordinal{
    --tw-ordinal:ordinal
  }

  .sm\:slashed-zero{
    --tw-slashed-zero:slashed-zero
  }

  .sm\:lining-nums{
    --tw-numeric-figure:lining-nums
  }

  .sm\:oldstyle-nums{
    --tw-numeric-figure:oldstyle-nums
  }

  .sm\:proportional-nums{
    --tw-numeric-spacing:proportional-nums
  }

  .sm\:tabular-nums{
    --tw-numeric-spacing:tabular-nums
  }

  .sm\:diagonal-fractions{
    --tw-numeric-fraction:diagonal-fractions
  }

  .sm\:stacked-fractions{
    --tw-numeric-fraction:stacked-fractions
  }

  .sm\:leading-3{
    line-height:.75rem
  }

  .sm\:leading-4{
    line-height:1rem
  }

  .sm\:leading-5{
    line-height:1.25rem
  }

  .sm\:leading-6{
    line-height:1.5rem
  }

  .sm\:leading-7{
    line-height:1.75rem
  }

  .sm\:leading-8{
    line-height:2rem
  }

  .sm\:leading-9{
    line-height:2.25rem
  }

  .sm\:leading-10{
    line-height:2.5rem
  }

  .sm\:leading-none{
    line-height:1
  }

  .sm\:leading-tight{
    line-height:1.25
  }

  .sm\:leading-snug{
    line-height:1.375
  }

  .sm\:leading-normal{
    line-height:1.5
  }

  .sm\:leading-relaxed{
    line-height:1.625
  }

  .sm\:leading-loose{
    line-height:2
  }

  .sm\:text-red1{
    color:#CC352C
  }

  .sm\:text-red2{
    color:#FF554B
  }

  .sm\:text-red3{
    color:#FF7B73
  }

  .sm\:text-red4{
    color:#FFF1F1
  }

  .sm\:text-red5{
    color:#FFF6F6
  }

  .sm\:text-cyan1{
    color:#009897
  }

  .sm\:text-cyan2{
    color:#00BFBD
  }

  .sm\:text-cyan3{
    color:#4CD1D0
  }

  .sm\:text-cyan4{
    color:#B2ECEB
  }

  .sm\:text-cyan5{
    color:#EDFAFA
  }

  .sm\:text-bg1{
    color:#FAFAFC
  }

  .sm\:text-bg2{
    color:#ffffff
  }

  .sm\:text-bg3{
    color:#F7F7FA
  }

  .sm\:text-gray1{
    color:#333333
  }

  .sm\:text-gray2{
    color:#4D4D4D
  }

  .sm\:text-gray3{
    color:#8C8C8C
  }

  .sm\:text-gray4{
    color:#BFBFBF
  }

  .sm\:text-gray5{
    color:#D9D9D9
  }

  .sm\:text-gray6{
    color:#E9E9EB
  }

  .sm\:text-gray7{
    color:#515164
  }

  .sm\:text-white{
    color:#ffffff
  }

  .sm\:text-warning{
    color:#FFC319
  }

  .sm\:text-error{
    color:#EA696F
  }

  .sm\:text-info{
    color:#0071B9
  }

  .sm\:text-success{
    color:#98D45F
  }

  .sm\:text-special4{
    color:#59779A
  }

  .sm\:text-dark{
    color:#000000
  }

  .sm\:text-transparent{
    color:transparent
  }

  .sm\:text-secondary1{
    color:#313140
  }

  .sm\:text-secondary2{
    color:#515164
  }

  .sm\:text-secondary3{
    color:#9C9CAC
  }

  .sm\:text-secondary4{
    color:#E5E8ED
  }

  .sm\:text-secondary5{
    color:#F5F6F9
  }

  .sm\:text-green1{
    color:#0DE298
  }

  .group:hover .sm\:group-hover\:text-red1{
    color:#CC352C
  }

  .group:hover .sm\:group-hover\:text-red2{
    color:#FF554B
  }

  .group:hover .sm\:group-hover\:text-red3{
    color:#FF7B73
  }

  .group:hover .sm\:group-hover\:text-red4{
    color:#FFF1F1
  }

  .group:hover .sm\:group-hover\:text-red5{
    color:#FFF6F6
  }

  .group:hover .sm\:group-hover\:text-cyan1{
    color:#009897
  }

  .group:hover .sm\:group-hover\:text-cyan2{
    color:#00BFBD
  }

  .group:hover .sm\:group-hover\:text-cyan3{
    color:#4CD1D0
  }

  .group:hover .sm\:group-hover\:text-cyan4{
    color:#B2ECEB
  }

  .group:hover .sm\:group-hover\:text-cyan5{
    color:#EDFAFA
  }

  .group:hover .sm\:group-hover\:text-bg1{
    color:#FAFAFC
  }

  .group:hover .sm\:group-hover\:text-bg2{
    color:#ffffff
  }

  .group:hover .sm\:group-hover\:text-bg3{
    color:#F7F7FA
  }

  .group:hover .sm\:group-hover\:text-gray1{
    color:#333333
  }

  .group:hover .sm\:group-hover\:text-gray2{
    color:#4D4D4D
  }

  .group:hover .sm\:group-hover\:text-gray3{
    color:#8C8C8C
  }

  .group:hover .sm\:group-hover\:text-gray4{
    color:#BFBFBF
  }

  .group:hover .sm\:group-hover\:text-gray5{
    color:#D9D9D9
  }

  .group:hover .sm\:group-hover\:text-gray6{
    color:#E9E9EB
  }

  .group:hover .sm\:group-hover\:text-gray7{
    color:#515164
  }

  .group:hover .sm\:group-hover\:text-white{
    color:#ffffff
  }

  .group:hover .sm\:group-hover\:text-warning{
    color:#FFC319
  }

  .group:hover .sm\:group-hover\:text-error{
    color:#EA696F
  }

  .group:hover .sm\:group-hover\:text-info{
    color:#0071B9
  }

  .group:hover .sm\:group-hover\:text-success{
    color:#98D45F
  }

  .group:hover .sm\:group-hover\:text-special4{
    color:#59779A
  }

  .group:hover .sm\:group-hover\:text-dark{
    color:#000000
  }

  .group:hover .sm\:group-hover\:text-transparent{
    color:transparent
  }

  .group:hover .sm\:group-hover\:text-secondary1{
    color:#313140
  }

  .group:hover .sm\:group-hover\:text-secondary2{
    color:#515164
  }

  .group:hover .sm\:group-hover\:text-secondary3{
    color:#9C9CAC
  }

  .group:hover .sm\:group-hover\:text-secondary4{
    color:#E5E8ED
  }

  .group:hover .sm\:group-hover\:text-secondary5{
    color:#F5F6F9
  }

  .group:hover .sm\:group-hover\:text-green1{
    color:#0DE298
  }

  .sm\:focus-within\:text-red1:focus-within{
    color:#CC352C
  }

  .sm\:focus-within\:text-red2:focus-within{
    color:#FF554B
  }

  .sm\:focus-within\:text-red3:focus-within{
    color:#FF7B73
  }

  .sm\:focus-within\:text-red4:focus-within{
    color:#FFF1F1
  }

  .sm\:focus-within\:text-red5:focus-within{
    color:#FFF6F6
  }

  .sm\:focus-within\:text-cyan1:focus-within{
    color:#009897
  }

  .sm\:focus-within\:text-cyan2:focus-within{
    color:#00BFBD
  }

  .sm\:focus-within\:text-cyan3:focus-within{
    color:#4CD1D0
  }

  .sm\:focus-within\:text-cyan4:focus-within{
    color:#B2ECEB
  }

  .sm\:focus-within\:text-cyan5:focus-within{
    color:#EDFAFA
  }

  .sm\:focus-within\:text-bg1:focus-within{
    color:#FAFAFC
  }

  .sm\:focus-within\:text-bg2:focus-within{
    color:#ffffff
  }

  .sm\:focus-within\:text-bg3:focus-within{
    color:#F7F7FA
  }

  .sm\:focus-within\:text-gray1:focus-within{
    color:#333333
  }

  .sm\:focus-within\:text-gray2:focus-within{
    color:#4D4D4D
  }

  .sm\:focus-within\:text-gray3:focus-within{
    color:#8C8C8C
  }

  .sm\:focus-within\:text-gray4:focus-within{
    color:#BFBFBF
  }

  .sm\:focus-within\:text-gray5:focus-within{
    color:#D9D9D9
  }

  .sm\:focus-within\:text-gray6:focus-within{
    color:#E9E9EB
  }

  .sm\:focus-within\:text-gray7:focus-within{
    color:#515164
  }

  .sm\:focus-within\:text-white:focus-within{
    color:#ffffff
  }

  .sm\:focus-within\:text-warning:focus-within{
    color:#FFC319
  }

  .sm\:focus-within\:text-error:focus-within{
    color:#EA696F
  }

  .sm\:focus-within\:text-info:focus-within{
    color:#0071B9
  }

  .sm\:focus-within\:text-success:focus-within{
    color:#98D45F
  }

  .sm\:focus-within\:text-special4:focus-within{
    color:#59779A
  }

  .sm\:focus-within\:text-dark:focus-within{
    color:#000000
  }

  .sm\:focus-within\:text-transparent:focus-within{
    color:transparent
  }

  .sm\:focus-within\:text-secondary1:focus-within{
    color:#313140
  }

  .sm\:focus-within\:text-secondary2:focus-within{
    color:#515164
  }

  .sm\:focus-within\:text-secondary3:focus-within{
    color:#9C9CAC
  }

  .sm\:focus-within\:text-secondary4:focus-within{
    color:#E5E8ED
  }

  .sm\:focus-within\:text-secondary5:focus-within{
    color:#F5F6F9
  }

  .sm\:focus-within\:text-green1:focus-within{
    color:#0DE298
  }

  .sm\:hover\:text-red1:hover{
    color:#CC352C
  }

  .sm\:hover\:text-red2:hover{
    color:#FF554B
  }

  .sm\:hover\:text-red3:hover{
    color:#FF7B73
  }

  .sm\:hover\:text-red4:hover{
    color:#FFF1F1
  }

  .sm\:hover\:text-red5:hover{
    color:#FFF6F6
  }

  .sm\:hover\:text-cyan1:hover{
    color:#009897
  }

  .sm\:hover\:text-cyan2:hover{
    color:#00BFBD
  }

  .sm\:hover\:text-cyan3:hover{
    color:#4CD1D0
  }

  .sm\:hover\:text-cyan4:hover{
    color:#B2ECEB
  }

  .sm\:hover\:text-cyan5:hover{
    color:#EDFAFA
  }

  .sm\:hover\:text-bg1:hover{
    color:#FAFAFC
  }

  .sm\:hover\:text-bg2:hover{
    color:#ffffff
  }

  .sm\:hover\:text-bg3:hover{
    color:#F7F7FA
  }

  .sm\:hover\:text-gray1:hover{
    color:#333333
  }

  .sm\:hover\:text-gray2:hover{
    color:#4D4D4D
  }

  .sm\:hover\:text-gray3:hover{
    color:#8C8C8C
  }

  .sm\:hover\:text-gray4:hover{
    color:#BFBFBF
  }

  .sm\:hover\:text-gray5:hover{
    color:#D9D9D9
  }

  .sm\:hover\:text-gray6:hover{
    color:#E9E9EB
  }

  .sm\:hover\:text-gray7:hover{
    color:#515164
  }

  .sm\:hover\:text-white:hover{
    color:#ffffff
  }

  .sm\:hover\:text-warning:hover{
    color:#FFC319
  }

  .sm\:hover\:text-error:hover{
    color:#EA696F
  }

  .sm\:hover\:text-info:hover{
    color:#0071B9
  }

  .sm\:hover\:text-success:hover{
    color:#98D45F
  }

  .sm\:hover\:text-special4:hover{
    color:#59779A
  }

  .sm\:hover\:text-dark:hover{
    color:#000000
  }

  .sm\:hover\:text-transparent:hover{
    color:transparent
  }

  .sm\:hover\:text-secondary1:hover{
    color:#313140
  }

  .sm\:hover\:text-secondary2:hover{
    color:#515164
  }

  .sm\:hover\:text-secondary3:hover{
    color:#9C9CAC
  }

  .sm\:hover\:text-secondary4:hover{
    color:#E5E8ED
  }

  .sm\:hover\:text-secondary5:hover{
    color:#F5F6F9
  }

  .sm\:hover\:text-green1:hover{
    color:#0DE298
  }

  .sm\:focus\:text-red1:focus{
    color:#CC352C
  }

  .sm\:focus\:text-red2:focus{
    color:#FF554B
  }

  .sm\:focus\:text-red3:focus{
    color:#FF7B73
  }

  .sm\:focus\:text-red4:focus{
    color:#FFF1F1
  }

  .sm\:focus\:text-red5:focus{
    color:#FFF6F6
  }

  .sm\:focus\:text-cyan1:focus{
    color:#009897
  }

  .sm\:focus\:text-cyan2:focus{
    color:#00BFBD
  }

  .sm\:focus\:text-cyan3:focus{
    color:#4CD1D0
  }

  .sm\:focus\:text-cyan4:focus{
    color:#B2ECEB
  }

  .sm\:focus\:text-cyan5:focus{
    color:#EDFAFA
  }

  .sm\:focus\:text-bg1:focus{
    color:#FAFAFC
  }

  .sm\:focus\:text-bg2:focus{
    color:#ffffff
  }

  .sm\:focus\:text-bg3:focus{
    color:#F7F7FA
  }

  .sm\:focus\:text-gray1:focus{
    color:#333333
  }

  .sm\:focus\:text-gray2:focus{
    color:#4D4D4D
  }

  .sm\:focus\:text-gray3:focus{
    color:#8C8C8C
  }

  .sm\:focus\:text-gray4:focus{
    color:#BFBFBF
  }

  .sm\:focus\:text-gray5:focus{
    color:#D9D9D9
  }

  .sm\:focus\:text-gray6:focus{
    color:#E9E9EB
  }

  .sm\:focus\:text-gray7:focus{
    color:#515164
  }

  .sm\:focus\:text-white:focus{
    color:#ffffff
  }

  .sm\:focus\:text-warning:focus{
    color:#FFC319
  }

  .sm\:focus\:text-error:focus{
    color:#EA696F
  }

  .sm\:focus\:text-info:focus{
    color:#0071B9
  }

  .sm\:focus\:text-success:focus{
    color:#98D45F
  }

  .sm\:focus\:text-special4:focus{
    color:#59779A
  }

  .sm\:focus\:text-dark:focus{
    color:#000000
  }

  .sm\:focus\:text-transparent:focus{
    color:transparent
  }

  .sm\:focus\:text-secondary1:focus{
    color:#313140
  }

  .sm\:focus\:text-secondary2:focus{
    color:#515164
  }

  .sm\:focus\:text-secondary3:focus{
    color:#9C9CAC
  }

  .sm\:focus\:text-secondary4:focus{
    color:#E5E8ED
  }

  .sm\:focus\:text-secondary5:focus{
    color:#F5F6F9
  }

  .sm\:focus\:text-green1:focus{
    color:#0DE298
  }

  .sm\:underline{
    text-decoration:underline
  }

  .sm\:line-through{
    text-decoration:line-through
  }

  .sm\:no-underline{
    text-decoration:none
  }

  .group:hover .sm\:group-hover\:underline{
    text-decoration:underline
  }

  .group:hover .sm\:group-hover\:line-through{
    text-decoration:line-through
  }

  .group:hover .sm\:group-hover\:no-underline{
    text-decoration:none
  }

  .sm\:focus-within\:underline:focus-within{
    text-decoration:underline
  }

  .sm\:focus-within\:line-through:focus-within{
    text-decoration:line-through
  }

  .sm\:focus-within\:no-underline:focus-within{
    text-decoration:none
  }

  .sm\:hover\:underline:hover{
    text-decoration:underline
  }

  .sm\:hover\:line-through:hover{
    text-decoration:line-through
  }

  .sm\:hover\:no-underline:hover{
    text-decoration:none
  }

  .sm\:focus\:underline:focus{
    text-decoration:underline
  }

  .sm\:focus\:line-through:focus{
    text-decoration:line-through
  }

  .sm\:focus\:no-underline:focus{
    text-decoration:none
  }

  .sm\:placeholder-red1::placeholder{
    color:#CC352C
  }

  .sm\:placeholder-red2::placeholder{
    color:#FF554B
  }

  .sm\:placeholder-red3::placeholder{
    color:#FF7B73
  }

  .sm\:placeholder-red4::placeholder{
    color:#FFF1F1
  }

  .sm\:placeholder-red5::placeholder{
    color:#FFF6F6
  }

  .sm\:placeholder-cyan1::placeholder{
    color:#009897
  }

  .sm\:placeholder-cyan2::placeholder{
    color:#00BFBD
  }

  .sm\:placeholder-cyan3::placeholder{
    color:#4CD1D0
  }

  .sm\:placeholder-cyan4::placeholder{
    color:#B2ECEB
  }

  .sm\:placeholder-cyan5::placeholder{
    color:#EDFAFA
  }

  .sm\:placeholder-bg1::placeholder{
    color:#FAFAFC
  }

  .sm\:placeholder-bg2::placeholder{
    color:#ffffff
  }

  .sm\:placeholder-bg3::placeholder{
    color:#F7F7FA
  }

  .sm\:placeholder-gray1::placeholder{
    color:#333333
  }

  .sm\:placeholder-gray2::placeholder{
    color:#4D4D4D
  }

  .sm\:placeholder-gray3::placeholder{
    color:#8C8C8C
  }

  .sm\:placeholder-gray4::placeholder{
    color:#BFBFBF
  }

  .sm\:placeholder-gray5::placeholder{
    color:#D9D9D9
  }

  .sm\:placeholder-gray6::placeholder{
    color:#E9E9EB
  }

  .sm\:placeholder-gray7::placeholder{
    color:#515164
  }

  .sm\:placeholder-white::placeholder{
    color:#ffffff
  }

  .sm\:placeholder-warning::placeholder{
    color:#FFC319
  }

  .sm\:placeholder-error::placeholder{
    color:#EA696F
  }

  .sm\:placeholder-info::placeholder{
    color:#0071B9
  }

  .sm\:placeholder-success::placeholder{
    color:#98D45F
  }

  .sm\:placeholder-special4::placeholder{
    color:#59779A
  }

  .sm\:placeholder-dark::placeholder{
    color:#000000
  }

  .sm\:placeholder-transparent::placeholder{
    color:transparent
  }

  .sm\:placeholder-secondary1::placeholder{
    color:#313140
  }

  .sm\:placeholder-secondary2::placeholder{
    color:#515164
  }

  .sm\:placeholder-secondary3::placeholder{
    color:#9C9CAC
  }

  .sm\:placeholder-secondary4::placeholder{
    color:#E5E8ED
  }

  .sm\:placeholder-secondary5::placeholder{
    color:#F5F6F9
  }

  .sm\:placeholder-green1::placeholder{
    color:#0DE298
  }

  .sm\:focus\:placeholder-red1:focus::placeholder{
    color:#CC352C
  }

  .sm\:focus\:placeholder-red2:focus::placeholder{
    color:#FF554B
  }

  .sm\:focus\:placeholder-red3:focus::placeholder{
    color:#FF7B73
  }

  .sm\:focus\:placeholder-red4:focus::placeholder{
    color:#FFF1F1
  }

  .sm\:focus\:placeholder-red5:focus::placeholder{
    color:#FFF6F6
  }

  .sm\:focus\:placeholder-cyan1:focus::placeholder{
    color:#009897
  }

  .sm\:focus\:placeholder-cyan2:focus::placeholder{
    color:#00BFBD
  }

  .sm\:focus\:placeholder-cyan3:focus::placeholder{
    color:#4CD1D0
  }

  .sm\:focus\:placeholder-cyan4:focus::placeholder{
    color:#B2ECEB
  }

  .sm\:focus\:placeholder-cyan5:focus::placeholder{
    color:#EDFAFA
  }

  .sm\:focus\:placeholder-bg1:focus::placeholder{
    color:#FAFAFC
  }

  .sm\:focus\:placeholder-bg2:focus::placeholder{
    color:#ffffff
  }

  .sm\:focus\:placeholder-bg3:focus::placeholder{
    color:#F7F7FA
  }

  .sm\:focus\:placeholder-gray1:focus::placeholder{
    color:#333333
  }

  .sm\:focus\:placeholder-gray2:focus::placeholder{
    color:#4D4D4D
  }

  .sm\:focus\:placeholder-gray3:focus::placeholder{
    color:#8C8C8C
  }

  .sm\:focus\:placeholder-gray4:focus::placeholder{
    color:#BFBFBF
  }

  .sm\:focus\:placeholder-gray5:focus::placeholder{
    color:#D9D9D9
  }

  .sm\:focus\:placeholder-gray6:focus::placeholder{
    color:#E9E9EB
  }

  .sm\:focus\:placeholder-gray7:focus::placeholder{
    color:#515164
  }

  .sm\:focus\:placeholder-white:focus::placeholder{
    color:#ffffff
  }

  .sm\:focus\:placeholder-warning:focus::placeholder{
    color:#FFC319
  }

  .sm\:focus\:placeholder-error:focus::placeholder{
    color:#EA696F
  }

  .sm\:focus\:placeholder-info:focus::placeholder{
    color:#0071B9
  }

  .sm\:focus\:placeholder-success:focus::placeholder{
    color:#98D45F
  }

  .sm\:focus\:placeholder-special4:focus::placeholder{
    color:#59779A
  }

  .sm\:focus\:placeholder-dark:focus::placeholder{
    color:#000000
  }

  .sm\:focus\:placeholder-transparent:focus::placeholder{
    color:transparent
  }

  .sm\:focus\:placeholder-secondary1:focus::placeholder{
    color:#313140
  }

  .sm\:focus\:placeholder-secondary2:focus::placeholder{
    color:#515164
  }

  .sm\:focus\:placeholder-secondary3:focus::placeholder{
    color:#9C9CAC
  }

  .sm\:focus\:placeholder-secondary4:focus::placeholder{
    color:#E5E8ED
  }

  .sm\:focus\:placeholder-secondary5:focus::placeholder{
    color:#F5F6F9
  }

  .sm\:focus\:placeholder-green1:focus::placeholder{
    color:#0DE298
  }

  .sm\:opacity-0{
    opacity:0
  }

  .sm\:opacity-5{
    opacity:0.05
  }

  .sm\:opacity-10{
    opacity:0.1
  }

  .sm\:opacity-20{
    opacity:0.2
  }

  .sm\:opacity-25{
    opacity:0.25
  }

  .sm\:opacity-30{
    opacity:0.3
  }

  .sm\:opacity-40{
    opacity:0.4
  }

  .sm\:opacity-50{
    opacity:0.5
  }

  .sm\:opacity-60{
    opacity:0.6
  }

  .sm\:opacity-70{
    opacity:0.7
  }

  .sm\:opacity-75{
    opacity:0.75
  }

  .sm\:opacity-80{
    opacity:0.8
  }

  .sm\:opacity-90{
    opacity:0.9
  }

  .sm\:opacity-95{
    opacity:0.95
  }

  .sm\:opacity-100{
    opacity:1
  }

  .group:hover .sm\:group-hover\:opacity-0{
    opacity:0
  }

  .group:hover .sm\:group-hover\:opacity-5{
    opacity:0.05
  }

  .group:hover .sm\:group-hover\:opacity-10{
    opacity:0.1
  }

  .group:hover .sm\:group-hover\:opacity-20{
    opacity:0.2
  }

  .group:hover .sm\:group-hover\:opacity-25{
    opacity:0.25
  }

  .group:hover .sm\:group-hover\:opacity-30{
    opacity:0.3
  }

  .group:hover .sm\:group-hover\:opacity-40{
    opacity:0.4
  }

  .group:hover .sm\:group-hover\:opacity-50{
    opacity:0.5
  }

  .group:hover .sm\:group-hover\:opacity-60{
    opacity:0.6
  }

  .group:hover .sm\:group-hover\:opacity-70{
    opacity:0.7
  }

  .group:hover .sm\:group-hover\:opacity-75{
    opacity:0.75
  }

  .group:hover .sm\:group-hover\:opacity-80{
    opacity:0.8
  }

  .group:hover .sm\:group-hover\:opacity-90{
    opacity:0.9
  }

  .group:hover .sm\:group-hover\:opacity-95{
    opacity:0.95
  }

  .group:hover .sm\:group-hover\:opacity-100{
    opacity:1
  }

  .sm\:focus-within\:opacity-0:focus-within{
    opacity:0
  }

  .sm\:focus-within\:opacity-5:focus-within{
    opacity:0.05
  }

  .sm\:focus-within\:opacity-10:focus-within{
    opacity:0.1
  }

  .sm\:focus-within\:opacity-20:focus-within{
    opacity:0.2
  }

  .sm\:focus-within\:opacity-25:focus-within{
    opacity:0.25
  }

  .sm\:focus-within\:opacity-30:focus-within{
    opacity:0.3
  }

  .sm\:focus-within\:opacity-40:focus-within{
    opacity:0.4
  }

  .sm\:focus-within\:opacity-50:focus-within{
    opacity:0.5
  }

  .sm\:focus-within\:opacity-60:focus-within{
    opacity:0.6
  }

  .sm\:focus-within\:opacity-70:focus-within{
    opacity:0.7
  }

  .sm\:focus-within\:opacity-75:focus-within{
    opacity:0.75
  }

  .sm\:focus-within\:opacity-80:focus-within{
    opacity:0.8
  }

  .sm\:focus-within\:opacity-90:focus-within{
    opacity:0.9
  }

  .sm\:focus-within\:opacity-95:focus-within{
    opacity:0.95
  }

  .sm\:focus-within\:opacity-100:focus-within{
    opacity:1
  }

  .sm\:hover\:opacity-0:hover{
    opacity:0
  }

  .sm\:hover\:opacity-5:hover{
    opacity:0.05
  }

  .sm\:hover\:opacity-10:hover{
    opacity:0.1
  }

  .sm\:hover\:opacity-20:hover{
    opacity:0.2
  }

  .sm\:hover\:opacity-25:hover{
    opacity:0.25
  }

  .sm\:hover\:opacity-30:hover{
    opacity:0.3
  }

  .sm\:hover\:opacity-40:hover{
    opacity:0.4
  }

  .sm\:hover\:opacity-50:hover{
    opacity:0.5
  }

  .sm\:hover\:opacity-60:hover{
    opacity:0.6
  }

  .sm\:hover\:opacity-70:hover{
    opacity:0.7
  }

  .sm\:hover\:opacity-75:hover{
    opacity:0.75
  }

  .sm\:hover\:opacity-80:hover{
    opacity:0.8
  }

  .sm\:hover\:opacity-90:hover{
    opacity:0.9
  }

  .sm\:hover\:opacity-95:hover{
    opacity:0.95
  }

  .sm\:hover\:opacity-100:hover{
    opacity:1
  }

  .sm\:focus\:opacity-0:focus{
    opacity:0
  }

  .sm\:focus\:opacity-5:focus{
    opacity:0.05
  }

  .sm\:focus\:opacity-10:focus{
    opacity:0.1
  }

  .sm\:focus\:opacity-20:focus{
    opacity:0.2
  }

  .sm\:focus\:opacity-25:focus{
    opacity:0.25
  }

  .sm\:focus\:opacity-30:focus{
    opacity:0.3
  }

  .sm\:focus\:opacity-40:focus{
    opacity:0.4
  }

  .sm\:focus\:opacity-50:focus{
    opacity:0.5
  }

  .sm\:focus\:opacity-60:focus{
    opacity:0.6
  }

  .sm\:focus\:opacity-70:focus{
    opacity:0.7
  }

  .sm\:focus\:opacity-75:focus{
    opacity:0.75
  }

  .sm\:focus\:opacity-80:focus{
    opacity:0.8
  }

  .sm\:focus\:opacity-90:focus{
    opacity:0.9
  }

  .sm\:focus\:opacity-95:focus{
    opacity:0.95
  }

  .sm\:focus\:opacity-100:focus{
    opacity:1
  }

  .sm\:bg-blend-normal{
    background-blend-mode:normal
  }

  .sm\:bg-blend-multiply{
    background-blend-mode:multiply
  }

  .sm\:bg-blend-screen{
    background-blend-mode:screen
  }

  .sm\:bg-blend-overlay{
    background-blend-mode:overlay
  }

  .sm\:bg-blend-darken{
    background-blend-mode:darken
  }

  .sm\:bg-blend-lighten{
    background-blend-mode:lighten
  }

  .sm\:bg-blend-color-dodge{
    background-blend-mode:color-dodge
  }

  .sm\:bg-blend-color-burn{
    background-blend-mode:color-burn
  }

  .sm\:bg-blend-hard-light{
    background-blend-mode:hard-light
  }

  .sm\:bg-blend-soft-light{
    background-blend-mode:soft-light
  }

  .sm\:bg-blend-difference{
    background-blend-mode:difference
  }

  .sm\:bg-blend-exclusion{
    background-blend-mode:exclusion
  }

  .sm\:bg-blend-hue{
    background-blend-mode:hue
  }

  .sm\:bg-blend-saturation{
    background-blend-mode:saturation
  }

  .sm\:bg-blend-color{
    background-blend-mode:color
  }

  .sm\:bg-blend-luminosity{
    background-blend-mode:luminosity
  }

  .sm\:mix-blend-normal{
    mix-blend-mode:normal
  }

  .sm\:mix-blend-multiply{
    mix-blend-mode:multiply
  }

  .sm\:mix-blend-screen{
    mix-blend-mode:screen
  }

  .sm\:mix-blend-overlay{
    mix-blend-mode:overlay
  }

  .sm\:mix-blend-darken{
    mix-blend-mode:darken
  }

  .sm\:mix-blend-lighten{
    mix-blend-mode:lighten
  }

  .sm\:mix-blend-color-dodge{
    mix-blend-mode:color-dodge
  }

  .sm\:mix-blend-color-burn{
    mix-blend-mode:color-burn
  }

  .sm\:mix-blend-hard-light{
    mix-blend-mode:hard-light
  }

  .sm\:mix-blend-soft-light{
    mix-blend-mode:soft-light
  }

  .sm\:mix-blend-difference{
    mix-blend-mode:difference
  }

  .sm\:mix-blend-exclusion{
    mix-blend-mode:exclusion
  }

  .sm\:mix-blend-hue{
    mix-blend-mode:hue
  }

  .sm\:mix-blend-saturation{
    mix-blend-mode:saturation
  }

  .sm\:mix-blend-color{
    mix-blend-mode:color
  }

  .sm\:mix-blend-luminosity{
    mix-blend-mode:luminosity
  }

  .sm\:shadow-panel{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:shadow-popup{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-panel{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .sm\:group-hover\:shadow-popup{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-panel:focus-within{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-hover:focus-within{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus-within\:shadow-popup:focus-within{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-panel:hover{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-hover:hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:hover\:shadow-popup:hover{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-panel:focus{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-hover:focus{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:focus\:shadow-popup:focus{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .sm\:outline-none{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .sm\:outline-white{
    outline:2px dotted white;
    outline-offset:2px
  }

  .sm\:outline-black{
    outline:2px dotted black;
    outline-offset:2px
  }

  .sm\:focus-within\:outline-none:focus-within{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .sm\:focus-within\:outline-white:focus-within{
    outline:2px dotted white;
    outline-offset:2px
  }

  .sm\:focus-within\:outline-black:focus-within{
    outline:2px dotted black;
    outline-offset:2px
  }

  .sm\:focus\:outline-none:focus{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .sm\:focus\:outline-white:focus{
    outline:2px dotted white;
    outline-offset:2px
  }

  .sm\:focus\:outline-black:focus{
    outline:2px dotted black;
    outline-offset:2px
  }

  .sm\:ring-0{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring-1{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring-2{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring-4{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring-8{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring-0:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring-1:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring-4:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring-8:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus-within\:ring:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring-0:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring-1:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring-2:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring-4:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring-8:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:focus\:ring:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .sm\:ring-inset{
    --tw-ring-inset:inset
  }

  .sm\:focus-within\:ring-inset:focus-within{
    --tw-ring-inset:inset
  }

  .sm\:focus\:ring-inset:focus{
    --tw-ring-inset:inset
  }

  .sm\:ring-red1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .sm\:ring-red2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .sm\:ring-red3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .sm\:ring-red4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .sm\:ring-red5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .sm\:ring-cyan5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .sm\:ring-bg1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .sm\:ring-bg2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-bg3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .sm\:ring-gray1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .sm\:ring-gray2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .sm\:ring-gray3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .sm\:ring-gray4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .sm\:ring-gray5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .sm\:ring-gray6{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .sm\:ring-gray7{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .sm\:ring-white{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:ring-warning{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .sm\:ring-error{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .sm\:ring-info{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .sm\:ring-success{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .sm\:ring-special4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .sm\:ring-dark{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .sm\:ring-transparent{
    --tw-ring-color:transparent
  }

  .sm\:ring-secondary1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .sm\:ring-secondary2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .sm\:ring-secondary3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .sm\:ring-secondary4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .sm\:ring-secondary5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .sm\:ring-green1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-red5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-cyan5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-bg1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-bg2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-bg3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray6:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-gray7:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-white:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-warning:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-error:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-info:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-success:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-special4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-dark:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-transparent:focus-within{
    --tw-ring-color:transparent
  }

  .sm\:focus-within\:ring-secondary1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-secondary2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-secondary3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-secondary4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-secondary5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .sm\:focus-within\:ring-green1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-red5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-cyan5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-bg1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-bg2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-bg3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray6:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-gray7:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-white:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-warning:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-error:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-info:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-success:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-special4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-dark:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-transparent:focus{
    --tw-ring-color:transparent
  }

  .sm\:focus\:ring-secondary1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-secondary2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-secondary3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-secondary4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-secondary5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .sm\:focus\:ring-green1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .sm\:ring-opacity-0{
    --tw-ring-opacity:0
  }

  .sm\:ring-opacity-5{
    --tw-ring-opacity:0.05
  }

  .sm\:ring-opacity-10{
    --tw-ring-opacity:0.1
  }

  .sm\:ring-opacity-20{
    --tw-ring-opacity:0.2
  }

  .sm\:ring-opacity-25{
    --tw-ring-opacity:0.25
  }

  .sm\:ring-opacity-30{
    --tw-ring-opacity:0.3
  }

  .sm\:ring-opacity-40{
    --tw-ring-opacity:0.4
  }

  .sm\:ring-opacity-50{
    --tw-ring-opacity:0.5
  }

  .sm\:ring-opacity-60{
    --tw-ring-opacity:0.6
  }

  .sm\:ring-opacity-70{
    --tw-ring-opacity:0.7
  }

  .sm\:ring-opacity-75{
    --tw-ring-opacity:0.75
  }

  .sm\:ring-opacity-80{
    --tw-ring-opacity:0.8
  }

  .sm\:ring-opacity-90{
    --tw-ring-opacity:0.9
  }

  .sm\:ring-opacity-95{
    --tw-ring-opacity:0.95
  }

  .sm\:ring-opacity-100{
    --tw-ring-opacity:1
  }

  .sm\:focus-within\:ring-opacity-0:focus-within{
    --tw-ring-opacity:0
  }

  .sm\:focus-within\:ring-opacity-5:focus-within{
    --tw-ring-opacity:0.05
  }

  .sm\:focus-within\:ring-opacity-10:focus-within{
    --tw-ring-opacity:0.1
  }

  .sm\:focus-within\:ring-opacity-20:focus-within{
    --tw-ring-opacity:0.2
  }

  .sm\:focus-within\:ring-opacity-25:focus-within{
    --tw-ring-opacity:0.25
  }

  .sm\:focus-within\:ring-opacity-30:focus-within{
    --tw-ring-opacity:0.3
  }

  .sm\:focus-within\:ring-opacity-40:focus-within{
    --tw-ring-opacity:0.4
  }

  .sm\:focus-within\:ring-opacity-50:focus-within{
    --tw-ring-opacity:0.5
  }

  .sm\:focus-within\:ring-opacity-60:focus-within{
    --tw-ring-opacity:0.6
  }

  .sm\:focus-within\:ring-opacity-70:focus-within{
    --tw-ring-opacity:0.7
  }

  .sm\:focus-within\:ring-opacity-75:focus-within{
    --tw-ring-opacity:0.75
  }

  .sm\:focus-within\:ring-opacity-80:focus-within{
    --tw-ring-opacity:0.8
  }

  .sm\:focus-within\:ring-opacity-90:focus-within{
    --tw-ring-opacity:0.9
  }

  .sm\:focus-within\:ring-opacity-95:focus-within{
    --tw-ring-opacity:0.95
  }

  .sm\:focus-within\:ring-opacity-100:focus-within{
    --tw-ring-opacity:1
  }

  .sm\:focus\:ring-opacity-0:focus{
    --tw-ring-opacity:0
  }

  .sm\:focus\:ring-opacity-5:focus{
    --tw-ring-opacity:0.05
  }

  .sm\:focus\:ring-opacity-10:focus{
    --tw-ring-opacity:0.1
  }

  .sm\:focus\:ring-opacity-20:focus{
    --tw-ring-opacity:0.2
  }

  .sm\:focus\:ring-opacity-25:focus{
    --tw-ring-opacity:0.25
  }

  .sm\:focus\:ring-opacity-30:focus{
    --tw-ring-opacity:0.3
  }

  .sm\:focus\:ring-opacity-40:focus{
    --tw-ring-opacity:0.4
  }

  .sm\:focus\:ring-opacity-50:focus{
    --tw-ring-opacity:0.5
  }

  .sm\:focus\:ring-opacity-60:focus{
    --tw-ring-opacity:0.6
  }

  .sm\:focus\:ring-opacity-70:focus{
    --tw-ring-opacity:0.7
  }

  .sm\:focus\:ring-opacity-75:focus{
    --tw-ring-opacity:0.75
  }

  .sm\:focus\:ring-opacity-80:focus{
    --tw-ring-opacity:0.8
  }

  .sm\:focus\:ring-opacity-90:focus{
    --tw-ring-opacity:0.9
  }

  .sm\:focus\:ring-opacity-95:focus{
    --tw-ring-opacity:0.95
  }

  .sm\:focus\:ring-opacity-100:focus{
    --tw-ring-opacity:1
  }

  .sm\:ring-offset-0{
    --tw-ring-offset-width:0px
  }

  .sm\:ring-offset-1{
    --tw-ring-offset-width:1px
  }

  .sm\:ring-offset-2{
    --tw-ring-offset-width:2px
  }

  .sm\:ring-offset-4{
    --tw-ring-offset-width:4px
  }

  .sm\:ring-offset-8{
    --tw-ring-offset-width:8px
  }

  .sm\:focus-within\:ring-offset-0:focus-within{
    --tw-ring-offset-width:0px
  }

  .sm\:focus-within\:ring-offset-1:focus-within{
    --tw-ring-offset-width:1px
  }

  .sm\:focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width:2px
  }

  .sm\:focus-within\:ring-offset-4:focus-within{
    --tw-ring-offset-width:4px
  }

  .sm\:focus-within\:ring-offset-8:focus-within{
    --tw-ring-offset-width:8px
  }

  .sm\:focus\:ring-offset-0:focus{
    --tw-ring-offset-width:0px
  }

  .sm\:focus\:ring-offset-1:focus{
    --tw-ring-offset-width:1px
  }

  .sm\:focus\:ring-offset-2:focus{
    --tw-ring-offset-width:2px
  }

  .sm\:focus\:ring-offset-4:focus{
    --tw-ring-offset-width:4px
  }

  .sm\:focus\:ring-offset-8:focus{
    --tw-ring-offset-width:8px
  }

  .sm\:ring-offset-red1{
    --tw-ring-offset-color:#CC352C
  }

  .sm\:ring-offset-red2{
    --tw-ring-offset-color:#FF554B
  }

  .sm\:ring-offset-red3{
    --tw-ring-offset-color:#FF7B73
  }

  .sm\:ring-offset-red4{
    --tw-ring-offset-color:#FFF1F1
  }

  .sm\:ring-offset-red5{
    --tw-ring-offset-color:#FFF6F6
  }

  .sm\:ring-offset-cyan1{
    --tw-ring-offset-color:#009897
  }

  .sm\:ring-offset-cyan2{
    --tw-ring-offset-color:#00BFBD
  }

  .sm\:ring-offset-cyan3{
    --tw-ring-offset-color:#4CD1D0
  }

  .sm\:ring-offset-cyan4{
    --tw-ring-offset-color:#B2ECEB
  }

  .sm\:ring-offset-cyan5{
    --tw-ring-offset-color:#EDFAFA
  }

  .sm\:ring-offset-bg1{
    --tw-ring-offset-color:#FAFAFC
  }

  .sm\:ring-offset-bg2{
    --tw-ring-offset-color:#ffffff
  }

  .sm\:ring-offset-bg3{
    --tw-ring-offset-color:#F7F7FA
  }

  .sm\:ring-offset-gray1{
    --tw-ring-offset-color:#333333
  }

  .sm\:ring-offset-gray2{
    --tw-ring-offset-color:#4D4D4D
  }

  .sm\:ring-offset-gray3{
    --tw-ring-offset-color:#8C8C8C
  }

  .sm\:ring-offset-gray4{
    --tw-ring-offset-color:#BFBFBF
  }

  .sm\:ring-offset-gray5{
    --tw-ring-offset-color:#D9D9D9
  }

  .sm\:ring-offset-gray6{
    --tw-ring-offset-color:#E9E9EB
  }

  .sm\:ring-offset-gray7{
    --tw-ring-offset-color:#515164
  }

  .sm\:ring-offset-white{
    --tw-ring-offset-color:#ffffff
  }

  .sm\:ring-offset-warning{
    --tw-ring-offset-color:#FFC319
  }

  .sm\:ring-offset-error{
    --tw-ring-offset-color:#EA696F
  }

  .sm\:ring-offset-info{
    --tw-ring-offset-color:#0071B9
  }

  .sm\:ring-offset-success{
    --tw-ring-offset-color:#98D45F
  }

  .sm\:ring-offset-special4{
    --tw-ring-offset-color:#59779A
  }

  .sm\:ring-offset-dark{
    --tw-ring-offset-color:#000000
  }

  .sm\:ring-offset-transparent{
    --tw-ring-offset-color:transparent
  }

  .sm\:ring-offset-secondary1{
    --tw-ring-offset-color:#313140
  }

  .sm\:ring-offset-secondary2{
    --tw-ring-offset-color:#515164
  }

  .sm\:ring-offset-secondary3{
    --tw-ring-offset-color:#9C9CAC
  }

  .sm\:ring-offset-secondary4{
    --tw-ring-offset-color:#E5E8ED
  }

  .sm\:ring-offset-secondary5{
    --tw-ring-offset-color:#F5F6F9
  }

  .sm\:ring-offset-green1{
    --tw-ring-offset-color:#0DE298
  }

  .sm\:focus-within\:ring-offset-red1:focus-within{
    --tw-ring-offset-color:#CC352C
  }

  .sm\:focus-within\:ring-offset-red2:focus-within{
    --tw-ring-offset-color:#FF554B
  }

  .sm\:focus-within\:ring-offset-red3:focus-within{
    --tw-ring-offset-color:#FF7B73
  }

  .sm\:focus-within\:ring-offset-red4:focus-within{
    --tw-ring-offset-color:#FFF1F1
  }

  .sm\:focus-within\:ring-offset-red5:focus-within{
    --tw-ring-offset-color:#FFF6F6
  }

  .sm\:focus-within\:ring-offset-cyan1:focus-within{
    --tw-ring-offset-color:#009897
  }

  .sm\:focus-within\:ring-offset-cyan2:focus-within{
    --tw-ring-offset-color:#00BFBD
  }

  .sm\:focus-within\:ring-offset-cyan3:focus-within{
    --tw-ring-offset-color:#4CD1D0
  }

  .sm\:focus-within\:ring-offset-cyan4:focus-within{
    --tw-ring-offset-color:#B2ECEB
  }

  .sm\:focus-within\:ring-offset-cyan5:focus-within{
    --tw-ring-offset-color:#EDFAFA
  }

  .sm\:focus-within\:ring-offset-bg1:focus-within{
    --tw-ring-offset-color:#FAFAFC
  }

  .sm\:focus-within\:ring-offset-bg2:focus-within{
    --tw-ring-offset-color:#ffffff
  }

  .sm\:focus-within\:ring-offset-bg3:focus-within{
    --tw-ring-offset-color:#F7F7FA
  }

  .sm\:focus-within\:ring-offset-gray1:focus-within{
    --tw-ring-offset-color:#333333
  }

  .sm\:focus-within\:ring-offset-gray2:focus-within{
    --tw-ring-offset-color:#4D4D4D
  }

  .sm\:focus-within\:ring-offset-gray3:focus-within{
    --tw-ring-offset-color:#8C8C8C
  }

  .sm\:focus-within\:ring-offset-gray4:focus-within{
    --tw-ring-offset-color:#BFBFBF
  }

  .sm\:focus-within\:ring-offset-gray5:focus-within{
    --tw-ring-offset-color:#D9D9D9
  }

  .sm\:focus-within\:ring-offset-gray6:focus-within{
    --tw-ring-offset-color:#E9E9EB
  }

  .sm\:focus-within\:ring-offset-gray7:focus-within{
    --tw-ring-offset-color:#515164
  }

  .sm\:focus-within\:ring-offset-white:focus-within{
    --tw-ring-offset-color:#ffffff
  }

  .sm\:focus-within\:ring-offset-warning:focus-within{
    --tw-ring-offset-color:#FFC319
  }

  .sm\:focus-within\:ring-offset-error:focus-within{
    --tw-ring-offset-color:#EA696F
  }

  .sm\:focus-within\:ring-offset-info:focus-within{
    --tw-ring-offset-color:#0071B9
  }

  .sm\:focus-within\:ring-offset-success:focus-within{
    --tw-ring-offset-color:#98D45F
  }

  .sm\:focus-within\:ring-offset-special4:focus-within{
    --tw-ring-offset-color:#59779A
  }

  .sm\:focus-within\:ring-offset-dark:focus-within{
    --tw-ring-offset-color:#000000
  }

  .sm\:focus-within\:ring-offset-transparent:focus-within{
    --tw-ring-offset-color:transparent
  }

  .sm\:focus-within\:ring-offset-secondary1:focus-within{
    --tw-ring-offset-color:#313140
  }

  .sm\:focus-within\:ring-offset-secondary2:focus-within{
    --tw-ring-offset-color:#515164
  }

  .sm\:focus-within\:ring-offset-secondary3:focus-within{
    --tw-ring-offset-color:#9C9CAC
  }

  .sm\:focus-within\:ring-offset-secondary4:focus-within{
    --tw-ring-offset-color:#E5E8ED
  }

  .sm\:focus-within\:ring-offset-secondary5:focus-within{
    --tw-ring-offset-color:#F5F6F9
  }

  .sm\:focus-within\:ring-offset-green1:focus-within{
    --tw-ring-offset-color:#0DE298
  }

  .sm\:focus\:ring-offset-red1:focus{
    --tw-ring-offset-color:#CC352C
  }

  .sm\:focus\:ring-offset-red2:focus{
    --tw-ring-offset-color:#FF554B
  }

  .sm\:focus\:ring-offset-red3:focus{
    --tw-ring-offset-color:#FF7B73
  }

  .sm\:focus\:ring-offset-red4:focus{
    --tw-ring-offset-color:#FFF1F1
  }

  .sm\:focus\:ring-offset-red5:focus{
    --tw-ring-offset-color:#FFF6F6
  }

  .sm\:focus\:ring-offset-cyan1:focus{
    --tw-ring-offset-color:#009897
  }

  .sm\:focus\:ring-offset-cyan2:focus{
    --tw-ring-offset-color:#00BFBD
  }

  .sm\:focus\:ring-offset-cyan3:focus{
    --tw-ring-offset-color:#4CD1D0
  }

  .sm\:focus\:ring-offset-cyan4:focus{
    --tw-ring-offset-color:#B2ECEB
  }

  .sm\:focus\:ring-offset-cyan5:focus{
    --tw-ring-offset-color:#EDFAFA
  }

  .sm\:focus\:ring-offset-bg1:focus{
    --tw-ring-offset-color:#FAFAFC
  }

  .sm\:focus\:ring-offset-bg2:focus{
    --tw-ring-offset-color:#ffffff
  }

  .sm\:focus\:ring-offset-bg3:focus{
    --tw-ring-offset-color:#F7F7FA
  }

  .sm\:focus\:ring-offset-gray1:focus{
    --tw-ring-offset-color:#333333
  }

  .sm\:focus\:ring-offset-gray2:focus{
    --tw-ring-offset-color:#4D4D4D
  }

  .sm\:focus\:ring-offset-gray3:focus{
    --tw-ring-offset-color:#8C8C8C
  }

  .sm\:focus\:ring-offset-gray4:focus{
    --tw-ring-offset-color:#BFBFBF
  }

  .sm\:focus\:ring-offset-gray5:focus{
    --tw-ring-offset-color:#D9D9D9
  }

  .sm\:focus\:ring-offset-gray6:focus{
    --tw-ring-offset-color:#E9E9EB
  }

  .sm\:focus\:ring-offset-gray7:focus{
    --tw-ring-offset-color:#515164
  }

  .sm\:focus\:ring-offset-white:focus{
    --tw-ring-offset-color:#ffffff
  }

  .sm\:focus\:ring-offset-warning:focus{
    --tw-ring-offset-color:#FFC319
  }

  .sm\:focus\:ring-offset-error:focus{
    --tw-ring-offset-color:#EA696F
  }

  .sm\:focus\:ring-offset-info:focus{
    --tw-ring-offset-color:#0071B9
  }

  .sm\:focus\:ring-offset-success:focus{
    --tw-ring-offset-color:#98D45F
  }

  .sm\:focus\:ring-offset-special4:focus{
    --tw-ring-offset-color:#59779A
  }

  .sm\:focus\:ring-offset-dark:focus{
    --tw-ring-offset-color:#000000
  }

  .sm\:focus\:ring-offset-transparent:focus{
    --tw-ring-offset-color:transparent
  }

  .sm\:focus\:ring-offset-secondary1:focus{
    --tw-ring-offset-color:#313140
  }

  .sm\:focus\:ring-offset-secondary2:focus{
    --tw-ring-offset-color:#515164
  }

  .sm\:focus\:ring-offset-secondary3:focus{
    --tw-ring-offset-color:#9C9CAC
  }

  .sm\:focus\:ring-offset-secondary4:focus{
    --tw-ring-offset-color:#E5E8ED
  }

  .sm\:focus\:ring-offset-secondary5:focus{
    --tw-ring-offset-color:#F5F6F9
  }

  .sm\:focus\:ring-offset-green1:focus{
    --tw-ring-offset-color:#0DE298
  }

  .sm\:filter{
    --tw-blur:var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness:var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast:var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale:var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
    --tw-invert:var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate:var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia:var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow:var(--tw-empty,/*!*/ /*!*/);
    filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }

  .sm\:filter-none{
    filter:none
  }

  .sm\:blur-0{
    --tw-blur:blur(0)
  }

  .sm\:blur-none{
    --tw-blur:blur(0)
  }

  .sm\:blur-sm{
    --tw-blur:blur(4px)
  }

  .sm\:blur{
    --tw-blur:blur(8px)
  }

  .sm\:blur-md{
    --tw-blur:blur(12px)
  }

  .sm\:blur-lg{
    --tw-blur:blur(16px)
  }

  .sm\:blur-xl{
    --tw-blur:blur(24px)
  }

  .sm\:blur-2xl{
    --tw-blur:blur(40px)
  }

  .sm\:blur-3xl{
    --tw-blur:blur(64px)
  }

  .sm\:brightness-0{
    --tw-brightness:brightness(0)
  }

  .sm\:brightness-50{
    --tw-brightness:brightness(.5)
  }

  .sm\:brightness-75{
    --tw-brightness:brightness(.75)
  }

  .sm\:brightness-90{
    --tw-brightness:brightness(.9)
  }

  .sm\:brightness-95{
    --tw-brightness:brightness(.95)
  }

  .sm\:brightness-100{
    --tw-brightness:brightness(1)
  }

  .sm\:brightness-105{
    --tw-brightness:brightness(1.05)
  }

  .sm\:brightness-110{
    --tw-brightness:brightness(1.1)
  }

  .sm\:brightness-125{
    --tw-brightness:brightness(1.25)
  }

  .sm\:brightness-150{
    --tw-brightness:brightness(1.5)
  }

  .sm\:brightness-200{
    --tw-brightness:brightness(2)
  }

  .sm\:contrast-0{
    --tw-contrast:contrast(0)
  }

  .sm\:contrast-50{
    --tw-contrast:contrast(.5)
  }

  .sm\:contrast-75{
    --tw-contrast:contrast(.75)
  }

  .sm\:contrast-100{
    --tw-contrast:contrast(1)
  }

  .sm\:contrast-125{
    --tw-contrast:contrast(1.25)
  }

  .sm\:contrast-150{
    --tw-contrast:contrast(1.5)
  }

  .sm\:contrast-200{
    --tw-contrast:contrast(2)
  }

  .sm\:drop-shadow-sm{
    --tw-drop-shadow:drop-shadow(0 1px 1px rgba(0,0,0,0.05))
  }

  .sm\:drop-shadow{
    --tw-drop-shadow:drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
  }

  .sm\:drop-shadow-md{
    --tw-drop-shadow:drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
  }

  .sm\:drop-shadow-lg{
    --tw-drop-shadow:drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
  }

  .sm\:drop-shadow-xl{
    --tw-drop-shadow:drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
  }

  .sm\:drop-shadow-2xl{
    --tw-drop-shadow:drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
  }

  .sm\:drop-shadow-none{
    --tw-drop-shadow:drop-shadow(0 0 #0000)
  }

  .sm\:grayscale-0{
    --tw-grayscale:grayscale(0)
  }

  .sm\:grayscale{
    --tw-grayscale:grayscale(100%)
  }

  .sm\:hue-rotate-0{
    --tw-hue-rotate:hue-rotate(0deg)
  }

  .sm\:hue-rotate-15{
    --tw-hue-rotate:hue-rotate(15deg)
  }

  .sm\:hue-rotate-30{
    --tw-hue-rotate:hue-rotate(30deg)
  }

  .sm\:hue-rotate-60{
    --tw-hue-rotate:hue-rotate(60deg)
  }

  .sm\:hue-rotate-90{
    --tw-hue-rotate:hue-rotate(90deg)
  }

  .sm\:hue-rotate-180{
    --tw-hue-rotate:hue-rotate(180deg)
  }

  .sm\:-hue-rotate-180{
    --tw-hue-rotate:hue-rotate(-180deg)
  }

  .sm\:-hue-rotate-90{
    --tw-hue-rotate:hue-rotate(-90deg)
  }

  .sm\:-hue-rotate-60{
    --tw-hue-rotate:hue-rotate(-60deg)
  }

  .sm\:-hue-rotate-30{
    --tw-hue-rotate:hue-rotate(-30deg)
  }

  .sm\:-hue-rotate-15{
    --tw-hue-rotate:hue-rotate(-15deg)
  }

  .sm\:invert-0{
    --tw-invert:invert(0)
  }

  .sm\:invert{
    --tw-invert:invert(100%)
  }

  .sm\:saturate-0{
    --tw-saturate:saturate(0)
  }

  .sm\:saturate-50{
    --tw-saturate:saturate(.5)
  }

  .sm\:saturate-100{
    --tw-saturate:saturate(1)
  }

  .sm\:saturate-150{
    --tw-saturate:saturate(1.5)
  }

  .sm\:saturate-200{
    --tw-saturate:saturate(2)
  }

  .sm\:sepia-0{
    --tw-sepia:sepia(0)
  }

  .sm\:sepia{
    --tw-sepia:sepia(100%)
  }

  .sm\:backdrop-filter{
    --tw-backdrop-blur:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia:var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  .sm\:backdrop-filter-none{
    -webkit-backdrop-filter:none;
            backdrop-filter:none
  }

  .sm\:backdrop-blur-0{
    --tw-backdrop-blur:blur(0)
  }

  .sm\:backdrop-blur-none{
    --tw-backdrop-blur:blur(0)
  }

  .sm\:backdrop-blur-sm{
    --tw-backdrop-blur:blur(4px)
  }

  .sm\:backdrop-blur{
    --tw-backdrop-blur:blur(8px)
  }

  .sm\:backdrop-blur-md{
    --tw-backdrop-blur:blur(12px)
  }

  .sm\:backdrop-blur-lg{
    --tw-backdrop-blur:blur(16px)
  }

  .sm\:backdrop-blur-xl{
    --tw-backdrop-blur:blur(24px)
  }

  .sm\:backdrop-blur-2xl{
    --tw-backdrop-blur:blur(40px)
  }

  .sm\:backdrop-blur-3xl{
    --tw-backdrop-blur:blur(64px)
  }

  .sm\:backdrop-brightness-0{
    --tw-backdrop-brightness:brightness(0)
  }

  .sm\:backdrop-brightness-50{
    --tw-backdrop-brightness:brightness(.5)
  }

  .sm\:backdrop-brightness-75{
    --tw-backdrop-brightness:brightness(.75)
  }

  .sm\:backdrop-brightness-90{
    --tw-backdrop-brightness:brightness(.9)
  }

  .sm\:backdrop-brightness-95{
    --tw-backdrop-brightness:brightness(.95)
  }

  .sm\:backdrop-brightness-100{
    --tw-backdrop-brightness:brightness(1)
  }

  .sm\:backdrop-brightness-105{
    --tw-backdrop-brightness:brightness(1.05)
  }

  .sm\:backdrop-brightness-110{
    --tw-backdrop-brightness:brightness(1.1)
  }

  .sm\:backdrop-brightness-125{
    --tw-backdrop-brightness:brightness(1.25)
  }

  .sm\:backdrop-brightness-150{
    --tw-backdrop-brightness:brightness(1.5)
  }

  .sm\:backdrop-brightness-200{
    --tw-backdrop-brightness:brightness(2)
  }

  .sm\:backdrop-contrast-0{
    --tw-backdrop-contrast:contrast(0)
  }

  .sm\:backdrop-contrast-50{
    --tw-backdrop-contrast:contrast(.5)
  }

  .sm\:backdrop-contrast-75{
    --tw-backdrop-contrast:contrast(.75)
  }

  .sm\:backdrop-contrast-100{
    --tw-backdrop-contrast:contrast(1)
  }

  .sm\:backdrop-contrast-125{
    --tw-backdrop-contrast:contrast(1.25)
  }

  .sm\:backdrop-contrast-150{
    --tw-backdrop-contrast:contrast(1.5)
  }

  .sm\:backdrop-contrast-200{
    --tw-backdrop-contrast:contrast(2)
  }

  .sm\:backdrop-grayscale-0{
    --tw-backdrop-grayscale:grayscale(0)
  }

  .sm\:backdrop-grayscale{
    --tw-backdrop-grayscale:grayscale(100%)
  }

  .sm\:backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate:hue-rotate(0deg)
  }

  .sm\:backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate:hue-rotate(15deg)
  }

  .sm\:backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate:hue-rotate(30deg)
  }

  .sm\:backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate:hue-rotate(60deg)
  }

  .sm\:backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate:hue-rotate(90deg)
  }

  .sm\:backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate:hue-rotate(180deg)
  }

  .sm\:-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate:hue-rotate(-180deg)
  }

  .sm\:-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate:hue-rotate(-90deg)
  }

  .sm\:-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate:hue-rotate(-60deg)
  }

  .sm\:-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate:hue-rotate(-30deg)
  }

  .sm\:-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate:hue-rotate(-15deg)
  }

  .sm\:backdrop-invert-0{
    --tw-backdrop-invert:invert(0)
  }

  .sm\:backdrop-invert{
    --tw-backdrop-invert:invert(100%)
  }

  .sm\:backdrop-opacity-0{
    --tw-backdrop-opacity:opacity(0)
  }

  .sm\:backdrop-opacity-5{
    --tw-backdrop-opacity:opacity(0.05)
  }

  .sm\:backdrop-opacity-10{
    --tw-backdrop-opacity:opacity(0.1)
  }

  .sm\:backdrop-opacity-20{
    --tw-backdrop-opacity:opacity(0.2)
  }

  .sm\:backdrop-opacity-25{
    --tw-backdrop-opacity:opacity(0.25)
  }

  .sm\:backdrop-opacity-30{
    --tw-backdrop-opacity:opacity(0.3)
  }

  .sm\:backdrop-opacity-40{
    --tw-backdrop-opacity:opacity(0.4)
  }

  .sm\:backdrop-opacity-50{
    --tw-backdrop-opacity:opacity(0.5)
  }

  .sm\:backdrop-opacity-60{
    --tw-backdrop-opacity:opacity(0.6)
  }

  .sm\:backdrop-opacity-70{
    --tw-backdrop-opacity:opacity(0.7)
  }

  .sm\:backdrop-opacity-75{
    --tw-backdrop-opacity:opacity(0.75)
  }

  .sm\:backdrop-opacity-80{
    --tw-backdrop-opacity:opacity(0.8)
  }

  .sm\:backdrop-opacity-90{
    --tw-backdrop-opacity:opacity(0.9)
  }

  .sm\:backdrop-opacity-95{
    --tw-backdrop-opacity:opacity(0.95)
  }

  .sm\:backdrop-opacity-100{
    --tw-backdrop-opacity:opacity(1)
  }

  .sm\:backdrop-saturate-0{
    --tw-backdrop-saturate:saturate(0)
  }

  .sm\:backdrop-saturate-50{
    --tw-backdrop-saturate:saturate(.5)
  }

  .sm\:backdrop-saturate-100{
    --tw-backdrop-saturate:saturate(1)
  }

  .sm\:backdrop-saturate-150{
    --tw-backdrop-saturate:saturate(1.5)
  }

  .sm\:backdrop-saturate-200{
    --tw-backdrop-saturate:saturate(2)
  }

  .sm\:backdrop-sepia-0{
    --tw-backdrop-sepia:sepia(0)
  }

  .sm\:backdrop-sepia{
    --tw-backdrop-sepia:sepia(100%)
  }

  .sm\:transition-none{
    transition-property:none
  }

  .sm\:transition-all{
    transition-property:all;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .sm\:transition{
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .sm\:transition-colors{
    transition-property:background-color, border-color, color, fill, stroke;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .sm\:transition-opacity{
    transition-property:opacity;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .sm\:transition-shadow{
    transition-property:box-shadow;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .sm\:transition-transform{
    transition-property:transform;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .sm\:duration-75{
    transition-duration:75ms
  }

  .sm\:duration-100{
    transition-duration:100ms
  }

  .sm\:duration-150{
    transition-duration:150ms
  }

  .sm\:duration-200{
    transition-duration:200ms
  }

  .sm\:duration-300{
    transition-duration:300ms
  }

  .sm\:duration-500{
    transition-duration:500ms
  }

  .sm\:duration-700{
    transition-duration:700ms
  }

  .sm\:duration-1000{
    transition-duration:1000ms
  }

  .sm\:ease-linear{
    transition-timing-function:linear
  }

  .sm\:ease-in{
    transition-timing-function:cubic-bezier(0.4, 0, 1, 1)
  }

  .sm\:ease-out{
    transition-timing-function:cubic-bezier(0, 0, 0.2, 1)
  }

  .sm\:ease-in-out{
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1)
  }
}

@media (min-width: 768px){
  .md\:visible{
    visibility:visible
  }

  .md\:invisible{
    visibility:hidden
  }

  .md\:static{
    position:static
  }

  .md\:fixed{
    position:fixed
  }

  .md\:absolute{
    position:absolute
  }

  .md\:relative{
    position:relative
  }

  .md\:sticky{
    position:sticky
  }

  .md\:inset-0{
    top:0;
    right:0;
    bottom:0;
    left:0
  }

  .md\:inset-1{
    top:4px;
    right:4px;
    bottom:4px;
    left:4px
  }

  .md\:inset-2{
    top:8px;
    right:8px;
    bottom:8px;
    left:8px
  }

  .md\:inset-3{
    top:12px;
    right:12px;
    bottom:12px;
    left:12px
  }

  .md\:inset-4{
    top:16px;
    right:16px;
    bottom:16px;
    left:16px
  }

  .md\:inset-5{
    top:20px;
    right:20px;
    bottom:20px;
    left:20px
  }

  .md\:inset-6{
    top:24px;
    right:24px;
    bottom:24px;
    left:24px
  }

  .md\:inset-8{
    top:32px;
    right:32px;
    bottom:32px;
    left:32px
  }

  .md\:inset-10{
    top:40px;
    right:40px;
    bottom:40px;
    left:40px
  }

  .md\:inset-12{
    top:48px;
    right:48px;
    bottom:48px;
    left:48px
  }

  .md\:inset-16{
    top:64px;
    right:64px;
    bottom:64px;
    left:64px
  }

  .md\:inset-18{
    top:72px;
    right:72px;
    bottom:72px;
    left:72px
  }

  .md\:inset-24{
    top:96px;
    right:96px;
    bottom:96px;
    left:96px
  }

  .md\:inset-auto{
    top:auto;
    right:auto;
    bottom:auto;
    left:auto
  }

  .md\:inset-5dot5{
    top:22px;
    right:22px;
    bottom:22px;
    left:22px
  }

  .md\:inset-full{
    top:100%;
    right:100%;
    bottom:100%;
    left:100%
  }

  .md\:-inset-1{
    top:-4px;
    right:-4px;
    bottom:-4px;
    left:-4px
  }

  .md\:-inset-2{
    top:-8px;
    right:-8px;
    bottom:-8px;
    left:-8px
  }

  .md\:-inset-3{
    top:-12px;
    right:-12px;
    bottom:-12px;
    left:-12px
  }

  .md\:-inset-4{
    top:-16px;
    right:-16px;
    bottom:-16px;
    left:-16px
  }

  .md\:-inset-5{
    top:-20px;
    right:-20px;
    bottom:-20px;
    left:-20px
  }

  .md\:-inset-6{
    top:-24px;
    right:-24px;
    bottom:-24px;
    left:-24px
  }

  .md\:-inset-8{
    top:-32px;
    right:-32px;
    bottom:-32px;
    left:-32px
  }

  .md\:-inset-10{
    top:-40px;
    right:-40px;
    bottom:-40px;
    left:-40px
  }

  .md\:-inset-12{
    top:-48px;
    right:-48px;
    bottom:-48px;
    left:-48px
  }

  .md\:-inset-16{
    top:-64px;
    right:-64px;
    bottom:-64px;
    left:-64px
  }

  .md\:-inset-18{
    top:-72px;
    right:-72px;
    bottom:-72px;
    left:-72px
  }

  .md\:-inset-24{
    top:-96px;
    right:-96px;
    bottom:-96px;
    left:-96px
  }

  .md\:-inset-5dot5{
    top:-22px;
    right:-22px;
    bottom:-22px;
    left:-22px
  }

  .md\:-inset-full{
    top:-100%;
    right:-100%;
    bottom:-100%;
    left:-100%
  }

  .md\:inset-1\/2{
    top:50%;
    right:50%;
    bottom:50%;
    left:50%
  }

  .md\:inset-1\/3{
    top:33.33333%;
    right:33.33333%;
    bottom:33.33333%;
    left:33.33333%
  }

  .md\:inset-2\/3{
    top:66.666667%;
    right:66.666667%;
    bottom:66.666667%;
    left:66.666667%
  }

  .md\:inset-1\/4{
    top:25%;
    right:25%;
    bottom:25%;
    left:25%
  }

  .md\:inset-2\/4{
    top:50%;
    right:50%;
    bottom:50%;
    left:50%
  }

  .md\:inset-3\/4{
    top:75%;
    right:75%;
    bottom:75%;
    left:75%
  }

  .md\:-inset-1\/2{
    top:-50%;
    right:-50%;
    bottom:-50%;
    left:-50%
  }

  .md\:-inset-1\/3{
    top:-33.333333%;
    right:-33.333333%;
    bottom:-33.333333%;
    left:-33.333333%
  }

  .md\:-inset-2\/3{
    top:-66.666667%;
    right:-66.666667%;
    bottom:-66.666667%;
    left:-66.666667%
  }

  .md\:-inset-1\/4{
    top:-25%;
    right:-25%;
    bottom:-25%;
    left:-25%
  }

  .md\:-inset-2\/4{
    top:-50%;
    right:-50%;
    bottom:-50%;
    left:-50%
  }

  .md\:-inset-3\/4{
    top:-75%;
    right:-75%;
    bottom:-75%;
    left:-75%
  }

  .md\:inset-x-0{
    left:0;
    right:0
  }

  .md\:inset-x-1{
    left:4px;
    right:4px
  }

  .md\:inset-x-2{
    left:8px;
    right:8px
  }

  .md\:inset-x-3{
    left:12px;
    right:12px
  }

  .md\:inset-x-4{
    left:16px;
    right:16px
  }

  .md\:inset-x-5{
    left:20px;
    right:20px
  }

  .md\:inset-x-6{
    left:24px;
    right:24px
  }

  .md\:inset-x-8{
    left:32px;
    right:32px
  }

  .md\:inset-x-10{
    left:40px;
    right:40px
  }

  .md\:inset-x-12{
    left:48px;
    right:48px
  }

  .md\:inset-x-16{
    left:64px;
    right:64px
  }

  .md\:inset-x-18{
    left:72px;
    right:72px
  }

  .md\:inset-x-24{
    left:96px;
    right:96px
  }

  .md\:inset-x-auto{
    left:auto;
    right:auto
  }

  .md\:inset-x-5dot5{
    left:22px;
    right:22px
  }

  .md\:inset-x-full{
    left:100%;
    right:100%
  }

  .md\:-inset-x-1{
    left:-4px;
    right:-4px
  }

  .md\:-inset-x-2{
    left:-8px;
    right:-8px
  }

  .md\:-inset-x-3{
    left:-12px;
    right:-12px
  }

  .md\:-inset-x-4{
    left:-16px;
    right:-16px
  }

  .md\:-inset-x-5{
    left:-20px;
    right:-20px
  }

  .md\:-inset-x-6{
    left:-24px;
    right:-24px
  }

  .md\:-inset-x-8{
    left:-32px;
    right:-32px
  }

  .md\:-inset-x-10{
    left:-40px;
    right:-40px
  }

  .md\:-inset-x-12{
    left:-48px;
    right:-48px
  }

  .md\:-inset-x-16{
    left:-64px;
    right:-64px
  }

  .md\:-inset-x-18{
    left:-72px;
    right:-72px
  }

  .md\:-inset-x-24{
    left:-96px;
    right:-96px
  }

  .md\:-inset-x-5dot5{
    left:-22px;
    right:-22px
  }

  .md\:-inset-x-full{
    left:-100%;
    right:-100%
  }

  .md\:inset-x-1\/2{
    left:50%;
    right:50%
  }

  .md\:inset-x-1\/3{
    left:33.33333%;
    right:33.33333%
  }

  .md\:inset-x-2\/3{
    left:66.666667%;
    right:66.666667%
  }

  .md\:inset-x-1\/4{
    left:25%;
    right:25%
  }

  .md\:inset-x-2\/4{
    left:50%;
    right:50%
  }

  .md\:inset-x-3\/4{
    left:75%;
    right:75%
  }

  .md\:-inset-x-1\/2{
    left:-50%;
    right:-50%
  }

  .md\:-inset-x-1\/3{
    left:-33.333333%;
    right:-33.333333%
  }

  .md\:-inset-x-2\/3{
    left:-66.666667%;
    right:-66.666667%
  }

  .md\:-inset-x-1\/4{
    left:-25%;
    right:-25%
  }

  .md\:-inset-x-2\/4{
    left:-50%;
    right:-50%
  }

  .md\:-inset-x-3\/4{
    left:-75%;
    right:-75%
  }

  .md\:inset-y-0{
    top:0;
    bottom:0
  }

  .md\:inset-y-1{
    top:4px;
    bottom:4px
  }

  .md\:inset-y-2{
    top:8px;
    bottom:8px
  }

  .md\:inset-y-3{
    top:12px;
    bottom:12px
  }

  .md\:inset-y-4{
    top:16px;
    bottom:16px
  }

  .md\:inset-y-5{
    top:20px;
    bottom:20px
  }

  .md\:inset-y-6{
    top:24px;
    bottom:24px
  }

  .md\:inset-y-8{
    top:32px;
    bottom:32px
  }

  .md\:inset-y-10{
    top:40px;
    bottom:40px
  }

  .md\:inset-y-12{
    top:48px;
    bottom:48px
  }

  .md\:inset-y-16{
    top:64px;
    bottom:64px
  }

  .md\:inset-y-18{
    top:72px;
    bottom:72px
  }

  .md\:inset-y-24{
    top:96px;
    bottom:96px
  }

  .md\:inset-y-auto{
    top:auto;
    bottom:auto
  }

  .md\:inset-y-5dot5{
    top:22px;
    bottom:22px
  }

  .md\:inset-y-full{
    top:100%;
    bottom:100%
  }

  .md\:-inset-y-1{
    top:-4px;
    bottom:-4px
  }

  .md\:-inset-y-2{
    top:-8px;
    bottom:-8px
  }

  .md\:-inset-y-3{
    top:-12px;
    bottom:-12px
  }

  .md\:-inset-y-4{
    top:-16px;
    bottom:-16px
  }

  .md\:-inset-y-5{
    top:-20px;
    bottom:-20px
  }

  .md\:-inset-y-6{
    top:-24px;
    bottom:-24px
  }

  .md\:-inset-y-8{
    top:-32px;
    bottom:-32px
  }

  .md\:-inset-y-10{
    top:-40px;
    bottom:-40px
  }

  .md\:-inset-y-12{
    top:-48px;
    bottom:-48px
  }

  .md\:-inset-y-16{
    top:-64px;
    bottom:-64px
  }

  .md\:-inset-y-18{
    top:-72px;
    bottom:-72px
  }

  .md\:-inset-y-24{
    top:-96px;
    bottom:-96px
  }

  .md\:-inset-y-5dot5{
    top:-22px;
    bottom:-22px
  }

  .md\:-inset-y-full{
    top:-100%;
    bottom:-100%
  }

  .md\:inset-y-1\/2{
    top:50%;
    bottom:50%
  }

  .md\:inset-y-1\/3{
    top:33.33333%;
    bottom:33.33333%
  }

  .md\:inset-y-2\/3{
    top:66.666667%;
    bottom:66.666667%
  }

  .md\:inset-y-1\/4{
    top:25%;
    bottom:25%
  }

  .md\:inset-y-2\/4{
    top:50%;
    bottom:50%
  }

  .md\:inset-y-3\/4{
    top:75%;
    bottom:75%
  }

  .md\:-inset-y-1\/2{
    top:-50%;
    bottom:-50%
  }

  .md\:-inset-y-1\/3{
    top:-33.333333%;
    bottom:-33.333333%
  }

  .md\:-inset-y-2\/3{
    top:-66.666667%;
    bottom:-66.666667%
  }

  .md\:-inset-y-1\/4{
    top:-25%;
    bottom:-25%
  }

  .md\:-inset-y-2\/4{
    top:-50%;
    bottom:-50%
  }

  .md\:-inset-y-3\/4{
    top:-75%;
    bottom:-75%
  }

  .md\:top-0{
    top:0
  }

  .md\:top-1{
    top:4px
  }

  .md\:top-2{
    top:8px
  }

  .md\:top-3{
    top:12px
  }

  .md\:top-4{
    top:16px
  }

  .md\:top-5{
    top:20px
  }

  .md\:top-6{
    top:24px
  }

  .md\:top-8{
    top:32px
  }

  .md\:top-10{
    top:40px
  }

  .md\:top-12{
    top:48px
  }

  .md\:top-16{
    top:64px
  }

  .md\:top-18{
    top:72px
  }

  .md\:top-24{
    top:96px
  }

  .md\:top-auto{
    top:auto
  }

  .md\:top-5dot5{
    top:22px
  }

  .md\:top-full{
    top:100%
  }

  .md\:-top-1{
    top:-4px
  }

  .md\:-top-2{
    top:-8px
  }

  .md\:-top-3{
    top:-12px
  }

  .md\:-top-4{
    top:-16px
  }

  .md\:-top-5{
    top:-20px
  }

  .md\:-top-6{
    top:-24px
  }

  .md\:-top-8{
    top:-32px
  }

  .md\:-top-10{
    top:-40px
  }

  .md\:-top-12{
    top:-48px
  }

  .md\:-top-16{
    top:-64px
  }

  .md\:-top-18{
    top:-72px
  }

  .md\:-top-24{
    top:-96px
  }

  .md\:-top-5dot5{
    top:-22px
  }

  .md\:-top-full{
    top:-100%
  }

  .md\:top-1\/2{
    top:50%
  }

  .md\:top-1\/3{
    top:33.33333%
  }

  .md\:top-2\/3{
    top:66.666667%
  }

  .md\:top-1\/4{
    top:25%
  }

  .md\:top-2\/4{
    top:50%
  }

  .md\:top-3\/4{
    top:75%
  }

  .md\:-top-1\/2{
    top:-50%
  }

  .md\:-top-1\/3{
    top:-33.333333%
  }

  .md\:-top-2\/3{
    top:-66.666667%
  }

  .md\:-top-1\/4{
    top:-25%
  }

  .md\:-top-2\/4{
    top:-50%
  }

  .md\:-top-3\/4{
    top:-75%
  }

  .md\:right-0{
    right:0
  }

  .md\:right-1{
    right:4px
  }

  .md\:right-2{
    right:8px
  }

  .md\:right-3{
    right:12px
  }

  .md\:right-4{
    right:16px
  }

  .md\:right-5{
    right:20px
  }

  .md\:right-6{
    right:24px
  }

  .md\:right-8{
    right:32px
  }

  .md\:right-10{
    right:40px
  }

  .md\:right-12{
    right:48px
  }

  .md\:right-16{
    right:64px
  }

  .md\:right-18{
    right:72px
  }

  .md\:right-24{
    right:96px
  }

  .md\:right-auto{
    right:auto
  }

  .md\:right-5dot5{
    right:22px
  }

  .md\:right-full{
    right:100%
  }

  .md\:-right-1{
    right:-4px
  }

  .md\:-right-2{
    right:-8px
  }

  .md\:-right-3{
    right:-12px
  }

  .md\:-right-4{
    right:-16px
  }

  .md\:-right-5{
    right:-20px
  }

  .md\:-right-6{
    right:-24px
  }

  .md\:-right-8{
    right:-32px
  }

  .md\:-right-10{
    right:-40px
  }

  .md\:-right-12{
    right:-48px
  }

  .md\:-right-16{
    right:-64px
  }

  .md\:-right-18{
    right:-72px
  }

  .md\:-right-24{
    right:-96px
  }

  .md\:-right-5dot5{
    right:-22px
  }

  .md\:-right-full{
    right:-100%
  }

  .md\:right-1\/2{
    right:50%
  }

  .md\:right-1\/3{
    right:33.33333%
  }

  .md\:right-2\/3{
    right:66.666667%
  }

  .md\:right-1\/4{
    right:25%
  }

  .md\:right-2\/4{
    right:50%
  }

  .md\:right-3\/4{
    right:75%
  }

  .md\:-right-1\/2{
    right:-50%
  }

  .md\:-right-1\/3{
    right:-33.333333%
  }

  .md\:-right-2\/3{
    right:-66.666667%
  }

  .md\:-right-1\/4{
    right:-25%
  }

  .md\:-right-2\/4{
    right:-50%
  }

  .md\:-right-3\/4{
    right:-75%
  }

  .md\:bottom-0{
    bottom:0
  }

  .md\:bottom-1{
    bottom:4px
  }

  .md\:bottom-2{
    bottom:8px
  }

  .md\:bottom-3{
    bottom:12px
  }

  .md\:bottom-4{
    bottom:16px
  }

  .md\:bottom-5{
    bottom:20px
  }

  .md\:bottom-6{
    bottom:24px
  }

  .md\:bottom-8{
    bottom:32px
  }

  .md\:bottom-10{
    bottom:40px
  }

  .md\:bottom-12{
    bottom:48px
  }

  .md\:bottom-16{
    bottom:64px
  }

  .md\:bottom-18{
    bottom:72px
  }

  .md\:bottom-24{
    bottom:96px
  }

  .md\:bottom-auto{
    bottom:auto
  }

  .md\:bottom-5dot5{
    bottom:22px
  }

  .md\:bottom-full{
    bottom:100%
  }

  .md\:-bottom-1{
    bottom:-4px
  }

  .md\:-bottom-2{
    bottom:-8px
  }

  .md\:-bottom-3{
    bottom:-12px
  }

  .md\:-bottom-4{
    bottom:-16px
  }

  .md\:-bottom-5{
    bottom:-20px
  }

  .md\:-bottom-6{
    bottom:-24px
  }

  .md\:-bottom-8{
    bottom:-32px
  }

  .md\:-bottom-10{
    bottom:-40px
  }

  .md\:-bottom-12{
    bottom:-48px
  }

  .md\:-bottom-16{
    bottom:-64px
  }

  .md\:-bottom-18{
    bottom:-72px
  }

  .md\:-bottom-24{
    bottom:-96px
  }

  .md\:-bottom-5dot5{
    bottom:-22px
  }

  .md\:-bottom-full{
    bottom:-100%
  }

  .md\:bottom-1\/2{
    bottom:50%
  }

  .md\:bottom-1\/3{
    bottom:33.33333%
  }

  .md\:bottom-2\/3{
    bottom:66.666667%
  }

  .md\:bottom-1\/4{
    bottom:25%
  }

  .md\:bottom-2\/4{
    bottom:50%
  }

  .md\:bottom-3\/4{
    bottom:75%
  }

  .md\:-bottom-1\/2{
    bottom:-50%
  }

  .md\:-bottom-1\/3{
    bottom:-33.333333%
  }

  .md\:-bottom-2\/3{
    bottom:-66.666667%
  }

  .md\:-bottom-1\/4{
    bottom:-25%
  }

  .md\:-bottom-2\/4{
    bottom:-50%
  }

  .md\:-bottom-3\/4{
    bottom:-75%
  }

  .md\:left-0{
    left:0
  }

  .md\:left-1{
    left:4px
  }

  .md\:left-2{
    left:8px
  }

  .md\:left-3{
    left:12px
  }

  .md\:left-4{
    left:16px
  }

  .md\:left-5{
    left:20px
  }

  .md\:left-6{
    left:24px
  }

  .md\:left-8{
    left:32px
  }

  .md\:left-10{
    left:40px
  }

  .md\:left-12{
    left:48px
  }

  .md\:left-16{
    left:64px
  }

  .md\:left-18{
    left:72px
  }

  .md\:left-24{
    left:96px
  }

  .md\:left-auto{
    left:auto
  }

  .md\:left-5dot5{
    left:22px
  }

  .md\:left-full{
    left:100%
  }

  .md\:-left-1{
    left:-4px
  }

  .md\:-left-2{
    left:-8px
  }

  .md\:-left-3{
    left:-12px
  }

  .md\:-left-4{
    left:-16px
  }

  .md\:-left-5{
    left:-20px
  }

  .md\:-left-6{
    left:-24px
  }

  .md\:-left-8{
    left:-32px
  }

  .md\:-left-10{
    left:-40px
  }

  .md\:-left-12{
    left:-48px
  }

  .md\:-left-16{
    left:-64px
  }

  .md\:-left-18{
    left:-72px
  }

  .md\:-left-24{
    left:-96px
  }

  .md\:-left-5dot5{
    left:-22px
  }

  .md\:-left-full{
    left:-100%
  }

  .md\:left-1\/2{
    left:50%
  }

  .md\:left-1\/3{
    left:33.33333%
  }

  .md\:left-2\/3{
    left:66.666667%
  }

  .md\:left-1\/4{
    left:25%
  }

  .md\:left-2\/4{
    left:50%
  }

  .md\:left-3\/4{
    left:75%
  }

  .md\:-left-1\/2{
    left:-50%
  }

  .md\:-left-1\/3{
    left:-33.333333%
  }

  .md\:-left-2\/3{
    left:-66.666667%
  }

  .md\:-left-1\/4{
    left:-25%
  }

  .md\:-left-2\/4{
    left:-50%
  }

  .md\:-left-3\/4{
    left:-75%
  }

  .md\:isolate{
    isolation:isolate
  }

  .md\:isolation-auto{
    isolation:auto
  }

  .md\:z-0{
    z-index:0
  }

  .md\:z-10{
    z-index:10
  }

  .md\:z-20{
    z-index:20
  }

  .md\:z-30{
    z-index:30
  }

  .md\:z-40{
    z-index:40
  }

  .md\:z-50{
    z-index:50
  }

  .md\:z-auto{
    z-index:auto
  }

  .md\:focus-within\:z-0:focus-within{
    z-index:0
  }

  .md\:focus-within\:z-10:focus-within{
    z-index:10
  }

  .md\:focus-within\:z-20:focus-within{
    z-index:20
  }

  .md\:focus-within\:z-30:focus-within{
    z-index:30
  }

  .md\:focus-within\:z-40:focus-within{
    z-index:40
  }

  .md\:focus-within\:z-50:focus-within{
    z-index:50
  }

  .md\:focus-within\:z-auto:focus-within{
    z-index:auto
  }

  .md\:focus\:z-0:focus{
    z-index:0
  }

  .md\:focus\:z-10:focus{
    z-index:10
  }

  .md\:focus\:z-20:focus{
    z-index:20
  }

  .md\:focus\:z-30:focus{
    z-index:30
  }

  .md\:focus\:z-40:focus{
    z-index:40
  }

  .md\:focus\:z-50:focus{
    z-index:50
  }

  .md\:focus\:z-auto:focus{
    z-index:auto
  }

  .md\:col-auto{
    grid-column:auto
  }

  .md\:col-span-1{
    grid-column:span 1 / span 1
  }

  .md\:col-span-2{
    grid-column:span 2 / span 2
  }

  .md\:col-span-3{
    grid-column:span 3 / span 3
  }

  .md\:col-span-4{
    grid-column:span 4 / span 4
  }

  .md\:col-span-5{
    grid-column:span 5 / span 5
  }

  .md\:col-span-6{
    grid-column:span 6 / span 6
  }

  .md\:col-span-7{
    grid-column:span 7 / span 7
  }

  .md\:col-span-8{
    grid-column:span 8 / span 8
  }

  .md\:col-span-9{
    grid-column:span 9 / span 9
  }

  .md\:col-span-10{
    grid-column:span 10 / span 10
  }

  .md\:col-span-11{
    grid-column:span 11 / span 11
  }

  .md\:col-span-12{
    grid-column:span 12 / span 12
  }

  .md\:col-span-full{
    grid-column:1 / -1
  }

  .md\:col-start-1{
    grid-column-start:1
  }

  .md\:col-start-2{
    grid-column-start:2
  }

  .md\:col-start-3{
    grid-column-start:3
  }

  .md\:col-start-4{
    grid-column-start:4
  }

  .md\:col-start-5{
    grid-column-start:5
  }

  .md\:col-start-6{
    grid-column-start:6
  }

  .md\:col-start-7{
    grid-column-start:7
  }

  .md\:col-start-8{
    grid-column-start:8
  }

  .md\:col-start-9{
    grid-column-start:9
  }

  .md\:col-start-10{
    grid-column-start:10
  }

  .md\:col-start-11{
    grid-column-start:11
  }

  .md\:col-start-12{
    grid-column-start:12
  }

  .md\:col-start-13{
    grid-column-start:13
  }

  .md\:col-start-auto{
    grid-column-start:auto
  }

  .md\:col-end-1{
    grid-column-end:1
  }

  .md\:col-end-2{
    grid-column-end:2
  }

  .md\:col-end-3{
    grid-column-end:3
  }

  .md\:col-end-4{
    grid-column-end:4
  }

  .md\:col-end-5{
    grid-column-end:5
  }

  .md\:col-end-6{
    grid-column-end:6
  }

  .md\:col-end-7{
    grid-column-end:7
  }

  .md\:col-end-8{
    grid-column-end:8
  }

  .md\:col-end-9{
    grid-column-end:9
  }

  .md\:col-end-10{
    grid-column-end:10
  }

  .md\:col-end-11{
    grid-column-end:11
  }

  .md\:col-end-12{
    grid-column-end:12
  }

  .md\:col-end-13{
    grid-column-end:13
  }

  .md\:col-end-auto{
    grid-column-end:auto
  }

  .md\:row-auto{
    grid-row:auto
  }

  .md\:row-span-1{
    grid-row:span 1 / span 1
  }

  .md\:row-span-2{
    grid-row:span 2 / span 2
  }

  .md\:row-span-3{
    grid-row:span 3 / span 3
  }

  .md\:row-span-4{
    grid-row:span 4 / span 4
  }

  .md\:row-span-5{
    grid-row:span 5 / span 5
  }

  .md\:row-span-6{
    grid-row:span 6 / span 6
  }

  .md\:row-span-full{
    grid-row:1 / -1
  }

  .md\:row-start-1{
    grid-row-start:1
  }

  .md\:row-start-2{
    grid-row-start:2
  }

  .md\:row-start-3{
    grid-row-start:3
  }

  .md\:row-start-4{
    grid-row-start:4
  }

  .md\:row-start-5{
    grid-row-start:5
  }

  .md\:row-start-6{
    grid-row-start:6
  }

  .md\:row-start-7{
    grid-row-start:7
  }

  .md\:row-start-auto{
    grid-row-start:auto
  }

  .md\:row-end-1{
    grid-row-end:1
  }

  .md\:row-end-2{
    grid-row-end:2
  }

  .md\:row-end-3{
    grid-row-end:3
  }

  .md\:row-end-4{
    grid-row-end:4
  }

  .md\:row-end-5{
    grid-row-end:5
  }

  .md\:row-end-6{
    grid-row-end:6
  }

  .md\:row-end-7{
    grid-row-end:7
  }

  .md\:row-end-auto{
    grid-row-end:auto
  }

  .md\:m-0{
    margin:0
  }

  .md\:m-1{
    margin:4px
  }

  .md\:m-2{
    margin:8px
  }

  .md\:m-3{
    margin:12px
  }

  .md\:m-4{
    margin:16px
  }

  .md\:m-5{
    margin:20px
  }

  .md\:m-6{
    margin:24px
  }

  .md\:m-8{
    margin:32px
  }

  .md\:m-10{
    margin:40px
  }

  .md\:m-12{
    margin:48px
  }

  .md\:m-16{
    margin:64px
  }

  .md\:m-18{
    margin:72px
  }

  .md\:m-24{
    margin:96px
  }

  .md\:m-auto{
    margin:auto
  }

  .md\:m-5dot5{
    margin:22px
  }

  .md\:m-full{
    margin:100%
  }

  .md\:-m-1{
    margin:-4px
  }

  .md\:-m-2{
    margin:-8px
  }

  .md\:-m-3{
    margin:-12px
  }

  .md\:-m-4{
    margin:-16px
  }

  .md\:-m-5{
    margin:-20px
  }

  .md\:-m-6{
    margin:-24px
  }

  .md\:-m-8{
    margin:-32px
  }

  .md\:-m-10{
    margin:-40px
  }

  .md\:-m-12{
    margin:-48px
  }

  .md\:-m-16{
    margin:-64px
  }

  .md\:-m-18{
    margin:-72px
  }

  .md\:-m-24{
    margin:-96px
  }

  .md\:-m-5dot5{
    margin:-22px
  }

  .md\:-m-full{
    margin:-100%
  }

  .md\:first\:m-0:first-child{
    margin:0
  }

  .md\:first\:m-1:first-child{
    margin:4px
  }

  .md\:first\:m-2:first-child{
    margin:8px
  }

  .md\:first\:m-3:first-child{
    margin:12px
  }

  .md\:first\:m-4:first-child{
    margin:16px
  }

  .md\:first\:m-5:first-child{
    margin:20px
  }

  .md\:first\:m-6:first-child{
    margin:24px
  }

  .md\:first\:m-8:first-child{
    margin:32px
  }

  .md\:first\:m-10:first-child{
    margin:40px
  }

  .md\:first\:m-12:first-child{
    margin:48px
  }

  .md\:first\:m-16:first-child{
    margin:64px
  }

  .md\:first\:m-18:first-child{
    margin:72px
  }

  .md\:first\:m-24:first-child{
    margin:96px
  }

  .md\:first\:m-auto:first-child{
    margin:auto
  }

  .md\:first\:m-5dot5:first-child{
    margin:22px
  }

  .md\:first\:m-full:first-child{
    margin:100%
  }

  .md\:first\:-m-1:first-child{
    margin:-4px
  }

  .md\:first\:-m-2:first-child{
    margin:-8px
  }

  .md\:first\:-m-3:first-child{
    margin:-12px
  }

  .md\:first\:-m-4:first-child{
    margin:-16px
  }

  .md\:first\:-m-5:first-child{
    margin:-20px
  }

  .md\:first\:-m-6:first-child{
    margin:-24px
  }

  .md\:first\:-m-8:first-child{
    margin:-32px
  }

  .md\:first\:-m-10:first-child{
    margin:-40px
  }

  .md\:first\:-m-12:first-child{
    margin:-48px
  }

  .md\:first\:-m-16:first-child{
    margin:-64px
  }

  .md\:first\:-m-18:first-child{
    margin:-72px
  }

  .md\:first\:-m-24:first-child{
    margin:-96px
  }

  .md\:first\:-m-5dot5:first-child{
    margin:-22px
  }

  .md\:first\:-m-full:first-child{
    margin:-100%
  }

  .md\:last\:m-0:last-child{
    margin:0
  }

  .md\:last\:m-1:last-child{
    margin:4px
  }

  .md\:last\:m-2:last-child{
    margin:8px
  }

  .md\:last\:m-3:last-child{
    margin:12px
  }

  .md\:last\:m-4:last-child{
    margin:16px
  }

  .md\:last\:m-5:last-child{
    margin:20px
  }

  .md\:last\:m-6:last-child{
    margin:24px
  }

  .md\:last\:m-8:last-child{
    margin:32px
  }

  .md\:last\:m-10:last-child{
    margin:40px
  }

  .md\:last\:m-12:last-child{
    margin:48px
  }

  .md\:last\:m-16:last-child{
    margin:64px
  }

  .md\:last\:m-18:last-child{
    margin:72px
  }

  .md\:last\:m-24:last-child{
    margin:96px
  }

  .md\:last\:m-auto:last-child{
    margin:auto
  }

  .md\:last\:m-5dot5:last-child{
    margin:22px
  }

  .md\:last\:m-full:last-child{
    margin:100%
  }

  .md\:last\:-m-1:last-child{
    margin:-4px
  }

  .md\:last\:-m-2:last-child{
    margin:-8px
  }

  .md\:last\:-m-3:last-child{
    margin:-12px
  }

  .md\:last\:-m-4:last-child{
    margin:-16px
  }

  .md\:last\:-m-5:last-child{
    margin:-20px
  }

  .md\:last\:-m-6:last-child{
    margin:-24px
  }

  .md\:last\:-m-8:last-child{
    margin:-32px
  }

  .md\:last\:-m-10:last-child{
    margin:-40px
  }

  .md\:last\:-m-12:last-child{
    margin:-48px
  }

  .md\:last\:-m-16:last-child{
    margin:-64px
  }

  .md\:last\:-m-18:last-child{
    margin:-72px
  }

  .md\:last\:-m-24:last-child{
    margin:-96px
  }

  .md\:last\:-m-5dot5:last-child{
    margin:-22px
  }

  .md\:last\:-m-full:last-child{
    margin:-100%
  }

  .md\:mx-0{
    margin-left:0;
    margin-right:0
  }

  .md\:mx-1{
    margin-left:4px;
    margin-right:4px
  }

  .md\:mx-2{
    margin-left:8px;
    margin-right:8px
  }

  .md\:mx-3{
    margin-left:12px;
    margin-right:12px
  }

  .md\:mx-4{
    margin-left:16px;
    margin-right:16px
  }

  .md\:mx-5{
    margin-left:20px;
    margin-right:20px
  }

  .md\:mx-6{
    margin-left:24px;
    margin-right:24px
  }

  .md\:mx-8{
    margin-left:32px;
    margin-right:32px
  }

  .md\:mx-10{
    margin-left:40px;
    margin-right:40px
  }

  .md\:mx-12{
    margin-left:48px;
    margin-right:48px
  }

  .md\:mx-16{
    margin-left:64px;
    margin-right:64px
  }

  .md\:mx-18{
    margin-left:72px;
    margin-right:72px
  }

  .md\:mx-24{
    margin-left:96px;
    margin-right:96px
  }

  .md\:mx-auto{
    margin-left:auto;
    margin-right:auto
  }

  .md\:mx-5dot5{
    margin-left:22px;
    margin-right:22px
  }

  .md\:mx-full{
    margin-left:100%;
    margin-right:100%
  }

  .md\:-mx-1{
    margin-left:-4px;
    margin-right:-4px
  }

  .md\:-mx-2{
    margin-left:-8px;
    margin-right:-8px
  }

  .md\:-mx-3{
    margin-left:-12px;
    margin-right:-12px
  }

  .md\:-mx-4{
    margin-left:-16px;
    margin-right:-16px
  }

  .md\:-mx-5{
    margin-left:-20px;
    margin-right:-20px
  }

  .md\:-mx-6{
    margin-left:-24px;
    margin-right:-24px
  }

  .md\:-mx-8{
    margin-left:-32px;
    margin-right:-32px
  }

  .md\:-mx-10{
    margin-left:-40px;
    margin-right:-40px
  }

  .md\:-mx-12{
    margin-left:-48px;
    margin-right:-48px
  }

  .md\:-mx-16{
    margin-left:-64px;
    margin-right:-64px
  }

  .md\:-mx-18{
    margin-left:-72px;
    margin-right:-72px
  }

  .md\:-mx-24{
    margin-left:-96px;
    margin-right:-96px
  }

  .md\:-mx-5dot5{
    margin-left:-22px;
    margin-right:-22px
  }

  .md\:-mx-full{
    margin-left:-100%;
    margin-right:-100%
  }

  .md\:my-0{
    margin-top:0;
    margin-bottom:0
  }

  .md\:my-1{
    margin-top:4px;
    margin-bottom:4px
  }

  .md\:my-2{
    margin-top:8px;
    margin-bottom:8px
  }

  .md\:my-3{
    margin-top:12px;
    margin-bottom:12px
  }

  .md\:my-4{
    margin-top:16px;
    margin-bottom:16px
  }

  .md\:my-5{
    margin-top:20px;
    margin-bottom:20px
  }

  .md\:my-6{
    margin-top:24px;
    margin-bottom:24px
  }

  .md\:my-8{
    margin-top:32px;
    margin-bottom:32px
  }

  .md\:my-10{
    margin-top:40px;
    margin-bottom:40px
  }

  .md\:my-12{
    margin-top:48px;
    margin-bottom:48px
  }

  .md\:my-16{
    margin-top:64px;
    margin-bottom:64px
  }

  .md\:my-18{
    margin-top:72px;
    margin-bottom:72px
  }

  .md\:my-24{
    margin-top:96px;
    margin-bottom:96px
  }

  .md\:my-auto{
    margin-top:auto;
    margin-bottom:auto
  }

  .md\:my-5dot5{
    margin-top:22px;
    margin-bottom:22px
  }

  .md\:my-full{
    margin-top:100%;
    margin-bottom:100%
  }

  .md\:-my-1{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .md\:-my-2{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .md\:-my-3{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .md\:-my-4{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .md\:-my-5{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .md\:-my-6{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .md\:-my-8{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .md\:-my-10{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .md\:-my-12{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .md\:-my-16{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .md\:-my-18{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .md\:-my-24{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .md\:-my-5dot5{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .md\:-my-full{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .md\:first\:mx-0:first-child{
    margin-left:0;
    margin-right:0
  }

  .md\:first\:mx-1:first-child{
    margin-left:4px;
    margin-right:4px
  }

  .md\:first\:mx-2:first-child{
    margin-left:8px;
    margin-right:8px
  }

  .md\:first\:mx-3:first-child{
    margin-left:12px;
    margin-right:12px
  }

  .md\:first\:mx-4:first-child{
    margin-left:16px;
    margin-right:16px
  }

  .md\:first\:mx-5:first-child{
    margin-left:20px;
    margin-right:20px
  }

  .md\:first\:mx-6:first-child{
    margin-left:24px;
    margin-right:24px
  }

  .md\:first\:mx-8:first-child{
    margin-left:32px;
    margin-right:32px
  }

  .md\:first\:mx-10:first-child{
    margin-left:40px;
    margin-right:40px
  }

  .md\:first\:mx-12:first-child{
    margin-left:48px;
    margin-right:48px
  }

  .md\:first\:mx-16:first-child{
    margin-left:64px;
    margin-right:64px
  }

  .md\:first\:mx-18:first-child{
    margin-left:72px;
    margin-right:72px
  }

  .md\:first\:mx-24:first-child{
    margin-left:96px;
    margin-right:96px
  }

  .md\:first\:mx-auto:first-child{
    margin-left:auto;
    margin-right:auto
  }

  .md\:first\:mx-5dot5:first-child{
    margin-left:22px;
    margin-right:22px
  }

  .md\:first\:mx-full:first-child{
    margin-left:100%;
    margin-right:100%
  }

  .md\:first\:-mx-1:first-child{
    margin-left:-4px;
    margin-right:-4px
  }

  .md\:first\:-mx-2:first-child{
    margin-left:-8px;
    margin-right:-8px
  }

  .md\:first\:-mx-3:first-child{
    margin-left:-12px;
    margin-right:-12px
  }

  .md\:first\:-mx-4:first-child{
    margin-left:-16px;
    margin-right:-16px
  }

  .md\:first\:-mx-5:first-child{
    margin-left:-20px;
    margin-right:-20px
  }

  .md\:first\:-mx-6:first-child{
    margin-left:-24px;
    margin-right:-24px
  }

  .md\:first\:-mx-8:first-child{
    margin-left:-32px;
    margin-right:-32px
  }

  .md\:first\:-mx-10:first-child{
    margin-left:-40px;
    margin-right:-40px
  }

  .md\:first\:-mx-12:first-child{
    margin-left:-48px;
    margin-right:-48px
  }

  .md\:first\:-mx-16:first-child{
    margin-left:-64px;
    margin-right:-64px
  }

  .md\:first\:-mx-18:first-child{
    margin-left:-72px;
    margin-right:-72px
  }

  .md\:first\:-mx-24:first-child{
    margin-left:-96px;
    margin-right:-96px
  }

  .md\:first\:-mx-5dot5:first-child{
    margin-left:-22px;
    margin-right:-22px
  }

  .md\:first\:-mx-full:first-child{
    margin-left:-100%;
    margin-right:-100%
  }

  .md\:first\:my-0:first-child{
    margin-top:0;
    margin-bottom:0
  }

  .md\:first\:my-1:first-child{
    margin-top:4px;
    margin-bottom:4px
  }

  .md\:first\:my-2:first-child{
    margin-top:8px;
    margin-bottom:8px
  }

  .md\:first\:my-3:first-child{
    margin-top:12px;
    margin-bottom:12px
  }

  .md\:first\:my-4:first-child{
    margin-top:16px;
    margin-bottom:16px
  }

  .md\:first\:my-5:first-child{
    margin-top:20px;
    margin-bottom:20px
  }

  .md\:first\:my-6:first-child{
    margin-top:24px;
    margin-bottom:24px
  }

  .md\:first\:my-8:first-child{
    margin-top:32px;
    margin-bottom:32px
  }

  .md\:first\:my-10:first-child{
    margin-top:40px;
    margin-bottom:40px
  }

  .md\:first\:my-12:first-child{
    margin-top:48px;
    margin-bottom:48px
  }

  .md\:first\:my-16:first-child{
    margin-top:64px;
    margin-bottom:64px
  }

  .md\:first\:my-18:first-child{
    margin-top:72px;
    margin-bottom:72px
  }

  .md\:first\:my-24:first-child{
    margin-top:96px;
    margin-bottom:96px
  }

  .md\:first\:my-auto:first-child{
    margin-top:auto;
    margin-bottom:auto
  }

  .md\:first\:my-5dot5:first-child{
    margin-top:22px;
    margin-bottom:22px
  }

  .md\:first\:my-full:first-child{
    margin-top:100%;
    margin-bottom:100%
  }

  .md\:first\:-my-1:first-child{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .md\:first\:-my-2:first-child{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .md\:first\:-my-3:first-child{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .md\:first\:-my-4:first-child{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .md\:first\:-my-5:first-child{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .md\:first\:-my-6:first-child{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .md\:first\:-my-8:first-child{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .md\:first\:-my-10:first-child{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .md\:first\:-my-12:first-child{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .md\:first\:-my-16:first-child{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .md\:first\:-my-18:first-child{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .md\:first\:-my-24:first-child{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .md\:first\:-my-5dot5:first-child{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .md\:first\:-my-full:first-child{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .md\:last\:mx-0:last-child{
    margin-left:0;
    margin-right:0
  }

  .md\:last\:mx-1:last-child{
    margin-left:4px;
    margin-right:4px
  }

  .md\:last\:mx-2:last-child{
    margin-left:8px;
    margin-right:8px
  }

  .md\:last\:mx-3:last-child{
    margin-left:12px;
    margin-right:12px
  }

  .md\:last\:mx-4:last-child{
    margin-left:16px;
    margin-right:16px
  }

  .md\:last\:mx-5:last-child{
    margin-left:20px;
    margin-right:20px
  }

  .md\:last\:mx-6:last-child{
    margin-left:24px;
    margin-right:24px
  }

  .md\:last\:mx-8:last-child{
    margin-left:32px;
    margin-right:32px
  }

  .md\:last\:mx-10:last-child{
    margin-left:40px;
    margin-right:40px
  }

  .md\:last\:mx-12:last-child{
    margin-left:48px;
    margin-right:48px
  }

  .md\:last\:mx-16:last-child{
    margin-left:64px;
    margin-right:64px
  }

  .md\:last\:mx-18:last-child{
    margin-left:72px;
    margin-right:72px
  }

  .md\:last\:mx-24:last-child{
    margin-left:96px;
    margin-right:96px
  }

  .md\:last\:mx-auto:last-child{
    margin-left:auto;
    margin-right:auto
  }

  .md\:last\:mx-5dot5:last-child{
    margin-left:22px;
    margin-right:22px
  }

  .md\:last\:mx-full:last-child{
    margin-left:100%;
    margin-right:100%
  }

  .md\:last\:-mx-1:last-child{
    margin-left:-4px;
    margin-right:-4px
  }

  .md\:last\:-mx-2:last-child{
    margin-left:-8px;
    margin-right:-8px
  }

  .md\:last\:-mx-3:last-child{
    margin-left:-12px;
    margin-right:-12px
  }

  .md\:last\:-mx-4:last-child{
    margin-left:-16px;
    margin-right:-16px
  }

  .md\:last\:-mx-5:last-child{
    margin-left:-20px;
    margin-right:-20px
  }

  .md\:last\:-mx-6:last-child{
    margin-left:-24px;
    margin-right:-24px
  }

  .md\:last\:-mx-8:last-child{
    margin-left:-32px;
    margin-right:-32px
  }

  .md\:last\:-mx-10:last-child{
    margin-left:-40px;
    margin-right:-40px
  }

  .md\:last\:-mx-12:last-child{
    margin-left:-48px;
    margin-right:-48px
  }

  .md\:last\:-mx-16:last-child{
    margin-left:-64px;
    margin-right:-64px
  }

  .md\:last\:-mx-18:last-child{
    margin-left:-72px;
    margin-right:-72px
  }

  .md\:last\:-mx-24:last-child{
    margin-left:-96px;
    margin-right:-96px
  }

  .md\:last\:-mx-5dot5:last-child{
    margin-left:-22px;
    margin-right:-22px
  }

  .md\:last\:-mx-full:last-child{
    margin-left:-100%;
    margin-right:-100%
  }

  .md\:last\:my-0:last-child{
    margin-top:0;
    margin-bottom:0
  }

  .md\:last\:my-1:last-child{
    margin-top:4px;
    margin-bottom:4px
  }

  .md\:last\:my-2:last-child{
    margin-top:8px;
    margin-bottom:8px
  }

  .md\:last\:my-3:last-child{
    margin-top:12px;
    margin-bottom:12px
  }

  .md\:last\:my-4:last-child{
    margin-top:16px;
    margin-bottom:16px
  }

  .md\:last\:my-5:last-child{
    margin-top:20px;
    margin-bottom:20px
  }

  .md\:last\:my-6:last-child{
    margin-top:24px;
    margin-bottom:24px
  }

  .md\:last\:my-8:last-child{
    margin-top:32px;
    margin-bottom:32px
  }

  .md\:last\:my-10:last-child{
    margin-top:40px;
    margin-bottom:40px
  }

  .md\:last\:my-12:last-child{
    margin-top:48px;
    margin-bottom:48px
  }

  .md\:last\:my-16:last-child{
    margin-top:64px;
    margin-bottom:64px
  }

  .md\:last\:my-18:last-child{
    margin-top:72px;
    margin-bottom:72px
  }

  .md\:last\:my-24:last-child{
    margin-top:96px;
    margin-bottom:96px
  }

  .md\:last\:my-auto:last-child{
    margin-top:auto;
    margin-bottom:auto
  }

  .md\:last\:my-5dot5:last-child{
    margin-top:22px;
    margin-bottom:22px
  }

  .md\:last\:my-full:last-child{
    margin-top:100%;
    margin-bottom:100%
  }

  .md\:last\:-my-1:last-child{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .md\:last\:-my-2:last-child{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .md\:last\:-my-3:last-child{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .md\:last\:-my-4:last-child{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .md\:last\:-my-5:last-child{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .md\:last\:-my-6:last-child{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .md\:last\:-my-8:last-child{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .md\:last\:-my-10:last-child{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .md\:last\:-my-12:last-child{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .md\:last\:-my-16:last-child{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .md\:last\:-my-18:last-child{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .md\:last\:-my-24:last-child{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .md\:last\:-my-5dot5:last-child{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .md\:last\:-my-full:last-child{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .md\:mt-0{
    margin-top:0
  }

  .md\:mt-1{
    margin-top:4px
  }

  .md\:mt-2{
    margin-top:8px
  }

  .md\:mt-3{
    margin-top:12px
  }

  .md\:mt-4{
    margin-top:16px
  }

  .md\:mt-5{
    margin-top:20px
  }

  .md\:mt-6{
    margin-top:24px
  }

  .md\:mt-8{
    margin-top:32px
  }

  .md\:mt-10{
    margin-top:40px
  }

  .md\:mt-12{
    margin-top:48px
  }

  .md\:mt-16{
    margin-top:64px
  }

  .md\:mt-18{
    margin-top:72px
  }

  .md\:mt-24{
    margin-top:96px
  }

  .md\:mt-auto{
    margin-top:auto
  }

  .md\:mt-5dot5{
    margin-top:22px
  }

  .md\:mt-full{
    margin-top:100%
  }

  .md\:-mt-1{
    margin-top:-4px
  }

  .md\:-mt-2{
    margin-top:-8px
  }

  .md\:-mt-3{
    margin-top:-12px
  }

  .md\:-mt-4{
    margin-top:-16px
  }

  .md\:-mt-5{
    margin-top:-20px
  }

  .md\:-mt-6{
    margin-top:-24px
  }

  .md\:-mt-8{
    margin-top:-32px
  }

  .md\:-mt-10{
    margin-top:-40px
  }

  .md\:-mt-12{
    margin-top:-48px
  }

  .md\:-mt-16{
    margin-top:-64px
  }

  .md\:-mt-18{
    margin-top:-72px
  }

  .md\:-mt-24{
    margin-top:-96px
  }

  .md\:-mt-5dot5{
    margin-top:-22px
  }

  .md\:-mt-full{
    margin-top:-100%
  }

  .md\:mr-0{
    margin-right:0
  }

  .md\:mr-1{
    margin-right:4px
  }

  .md\:mr-2{
    margin-right:8px
  }

  .md\:mr-3{
    margin-right:12px
  }

  .md\:mr-4{
    margin-right:16px
  }

  .md\:mr-5{
    margin-right:20px
  }

  .md\:mr-6{
    margin-right:24px
  }

  .md\:mr-8{
    margin-right:32px
  }

  .md\:mr-10{
    margin-right:40px
  }

  .md\:mr-12{
    margin-right:48px
  }

  .md\:mr-16{
    margin-right:64px
  }

  .md\:mr-18{
    margin-right:72px
  }

  .md\:mr-24{
    margin-right:96px
  }

  .md\:mr-auto{
    margin-right:auto
  }

  .md\:mr-5dot5{
    margin-right:22px
  }

  .md\:mr-full{
    margin-right:100%
  }

  .md\:-mr-1{
    margin-right:-4px
  }

  .md\:-mr-2{
    margin-right:-8px
  }

  .md\:-mr-3{
    margin-right:-12px
  }

  .md\:-mr-4{
    margin-right:-16px
  }

  .md\:-mr-5{
    margin-right:-20px
  }

  .md\:-mr-6{
    margin-right:-24px
  }

  .md\:-mr-8{
    margin-right:-32px
  }

  .md\:-mr-10{
    margin-right:-40px
  }

  .md\:-mr-12{
    margin-right:-48px
  }

  .md\:-mr-16{
    margin-right:-64px
  }

  .md\:-mr-18{
    margin-right:-72px
  }

  .md\:-mr-24{
    margin-right:-96px
  }

  .md\:-mr-5dot5{
    margin-right:-22px
  }

  .md\:-mr-full{
    margin-right:-100%
  }

  .md\:mb-0{
    margin-bottom:0
  }

  .md\:mb-1{
    margin-bottom:4px
  }

  .md\:mb-2{
    margin-bottom:8px
  }

  .md\:mb-3{
    margin-bottom:12px
  }

  .md\:mb-4{
    margin-bottom:16px
  }

  .md\:mb-5{
    margin-bottom:20px
  }

  .md\:mb-6{
    margin-bottom:24px
  }

  .md\:mb-8{
    margin-bottom:32px
  }

  .md\:mb-10{
    margin-bottom:40px
  }

  .md\:mb-12{
    margin-bottom:48px
  }

  .md\:mb-16{
    margin-bottom:64px
  }

  .md\:mb-18{
    margin-bottom:72px
  }

  .md\:mb-24{
    margin-bottom:96px
  }

  .md\:mb-auto{
    margin-bottom:auto
  }

  .md\:mb-5dot5{
    margin-bottom:22px
  }

  .md\:mb-full{
    margin-bottom:100%
  }

  .md\:-mb-1{
    margin-bottom:-4px
  }

  .md\:-mb-2{
    margin-bottom:-8px
  }

  .md\:-mb-3{
    margin-bottom:-12px
  }

  .md\:-mb-4{
    margin-bottom:-16px
  }

  .md\:-mb-5{
    margin-bottom:-20px
  }

  .md\:-mb-6{
    margin-bottom:-24px
  }

  .md\:-mb-8{
    margin-bottom:-32px
  }

  .md\:-mb-10{
    margin-bottom:-40px
  }

  .md\:-mb-12{
    margin-bottom:-48px
  }

  .md\:-mb-16{
    margin-bottom:-64px
  }

  .md\:-mb-18{
    margin-bottom:-72px
  }

  .md\:-mb-24{
    margin-bottom:-96px
  }

  .md\:-mb-5dot5{
    margin-bottom:-22px
  }

  .md\:-mb-full{
    margin-bottom:-100%
  }

  .md\:ml-0{
    margin-left:0
  }

  .md\:ml-1{
    margin-left:4px
  }

  .md\:ml-2{
    margin-left:8px
  }

  .md\:ml-3{
    margin-left:12px
  }

  .md\:ml-4{
    margin-left:16px
  }

  .md\:ml-5{
    margin-left:20px
  }

  .md\:ml-6{
    margin-left:24px
  }

  .md\:ml-8{
    margin-left:32px
  }

  .md\:ml-10{
    margin-left:40px
  }

  .md\:ml-12{
    margin-left:48px
  }

  .md\:ml-16{
    margin-left:64px
  }

  .md\:ml-18{
    margin-left:72px
  }

  .md\:ml-24{
    margin-left:96px
  }

  .md\:ml-auto{
    margin-left:auto
  }

  .md\:ml-5dot5{
    margin-left:22px
  }

  .md\:ml-full{
    margin-left:100%
  }

  .md\:-ml-1{
    margin-left:-4px
  }

  .md\:-ml-2{
    margin-left:-8px
  }

  .md\:-ml-3{
    margin-left:-12px
  }

  .md\:-ml-4{
    margin-left:-16px
  }

  .md\:-ml-5{
    margin-left:-20px
  }

  .md\:-ml-6{
    margin-left:-24px
  }

  .md\:-ml-8{
    margin-left:-32px
  }

  .md\:-ml-10{
    margin-left:-40px
  }

  .md\:-ml-12{
    margin-left:-48px
  }

  .md\:-ml-16{
    margin-left:-64px
  }

  .md\:-ml-18{
    margin-left:-72px
  }

  .md\:-ml-24{
    margin-left:-96px
  }

  .md\:-ml-5dot5{
    margin-left:-22px
  }

  .md\:-ml-full{
    margin-left:-100%
  }

  .md\:first\:mt-0:first-child{
    margin-top:0
  }

  .md\:first\:mt-1:first-child{
    margin-top:4px
  }

  .md\:first\:mt-2:first-child{
    margin-top:8px
  }

  .md\:first\:mt-3:first-child{
    margin-top:12px
  }

  .md\:first\:mt-4:first-child{
    margin-top:16px
  }

  .md\:first\:mt-5:first-child{
    margin-top:20px
  }

  .md\:first\:mt-6:first-child{
    margin-top:24px
  }

  .md\:first\:mt-8:first-child{
    margin-top:32px
  }

  .md\:first\:mt-10:first-child{
    margin-top:40px
  }

  .md\:first\:mt-12:first-child{
    margin-top:48px
  }

  .md\:first\:mt-16:first-child{
    margin-top:64px
  }

  .md\:first\:mt-18:first-child{
    margin-top:72px
  }

  .md\:first\:mt-24:first-child{
    margin-top:96px
  }

  .md\:first\:mt-auto:first-child{
    margin-top:auto
  }

  .md\:first\:mt-5dot5:first-child{
    margin-top:22px
  }

  .md\:first\:mt-full:first-child{
    margin-top:100%
  }

  .md\:first\:-mt-1:first-child{
    margin-top:-4px
  }

  .md\:first\:-mt-2:first-child{
    margin-top:-8px
  }

  .md\:first\:-mt-3:first-child{
    margin-top:-12px
  }

  .md\:first\:-mt-4:first-child{
    margin-top:-16px
  }

  .md\:first\:-mt-5:first-child{
    margin-top:-20px
  }

  .md\:first\:-mt-6:first-child{
    margin-top:-24px
  }

  .md\:first\:-mt-8:first-child{
    margin-top:-32px
  }

  .md\:first\:-mt-10:first-child{
    margin-top:-40px
  }

  .md\:first\:-mt-12:first-child{
    margin-top:-48px
  }

  .md\:first\:-mt-16:first-child{
    margin-top:-64px
  }

  .md\:first\:-mt-18:first-child{
    margin-top:-72px
  }

  .md\:first\:-mt-24:first-child{
    margin-top:-96px
  }

  .md\:first\:-mt-5dot5:first-child{
    margin-top:-22px
  }

  .md\:first\:-mt-full:first-child{
    margin-top:-100%
  }

  .md\:first\:mr-0:first-child{
    margin-right:0
  }

  .md\:first\:mr-1:first-child{
    margin-right:4px
  }

  .md\:first\:mr-2:first-child{
    margin-right:8px
  }

  .md\:first\:mr-3:first-child{
    margin-right:12px
  }

  .md\:first\:mr-4:first-child{
    margin-right:16px
  }

  .md\:first\:mr-5:first-child{
    margin-right:20px
  }

  .md\:first\:mr-6:first-child{
    margin-right:24px
  }

  .md\:first\:mr-8:first-child{
    margin-right:32px
  }

  .md\:first\:mr-10:first-child{
    margin-right:40px
  }

  .md\:first\:mr-12:first-child{
    margin-right:48px
  }

  .md\:first\:mr-16:first-child{
    margin-right:64px
  }

  .md\:first\:mr-18:first-child{
    margin-right:72px
  }

  .md\:first\:mr-24:first-child{
    margin-right:96px
  }

  .md\:first\:mr-auto:first-child{
    margin-right:auto
  }

  .md\:first\:mr-5dot5:first-child{
    margin-right:22px
  }

  .md\:first\:mr-full:first-child{
    margin-right:100%
  }

  .md\:first\:-mr-1:first-child{
    margin-right:-4px
  }

  .md\:first\:-mr-2:first-child{
    margin-right:-8px
  }

  .md\:first\:-mr-3:first-child{
    margin-right:-12px
  }

  .md\:first\:-mr-4:first-child{
    margin-right:-16px
  }

  .md\:first\:-mr-5:first-child{
    margin-right:-20px
  }

  .md\:first\:-mr-6:first-child{
    margin-right:-24px
  }

  .md\:first\:-mr-8:first-child{
    margin-right:-32px
  }

  .md\:first\:-mr-10:first-child{
    margin-right:-40px
  }

  .md\:first\:-mr-12:first-child{
    margin-right:-48px
  }

  .md\:first\:-mr-16:first-child{
    margin-right:-64px
  }

  .md\:first\:-mr-18:first-child{
    margin-right:-72px
  }

  .md\:first\:-mr-24:first-child{
    margin-right:-96px
  }

  .md\:first\:-mr-5dot5:first-child{
    margin-right:-22px
  }

  .md\:first\:-mr-full:first-child{
    margin-right:-100%
  }

  .md\:first\:mb-0:first-child{
    margin-bottom:0
  }

  .md\:first\:mb-1:first-child{
    margin-bottom:4px
  }

  .md\:first\:mb-2:first-child{
    margin-bottom:8px
  }

  .md\:first\:mb-3:first-child{
    margin-bottom:12px
  }

  .md\:first\:mb-4:first-child{
    margin-bottom:16px
  }

  .md\:first\:mb-5:first-child{
    margin-bottom:20px
  }

  .md\:first\:mb-6:first-child{
    margin-bottom:24px
  }

  .md\:first\:mb-8:first-child{
    margin-bottom:32px
  }

  .md\:first\:mb-10:first-child{
    margin-bottom:40px
  }

  .md\:first\:mb-12:first-child{
    margin-bottom:48px
  }

  .md\:first\:mb-16:first-child{
    margin-bottom:64px
  }

  .md\:first\:mb-18:first-child{
    margin-bottom:72px
  }

  .md\:first\:mb-24:first-child{
    margin-bottom:96px
  }

  .md\:first\:mb-auto:first-child{
    margin-bottom:auto
  }

  .md\:first\:mb-5dot5:first-child{
    margin-bottom:22px
  }

  .md\:first\:mb-full:first-child{
    margin-bottom:100%
  }

  .md\:first\:-mb-1:first-child{
    margin-bottom:-4px
  }

  .md\:first\:-mb-2:first-child{
    margin-bottom:-8px
  }

  .md\:first\:-mb-3:first-child{
    margin-bottom:-12px
  }

  .md\:first\:-mb-4:first-child{
    margin-bottom:-16px
  }

  .md\:first\:-mb-5:first-child{
    margin-bottom:-20px
  }

  .md\:first\:-mb-6:first-child{
    margin-bottom:-24px
  }

  .md\:first\:-mb-8:first-child{
    margin-bottom:-32px
  }

  .md\:first\:-mb-10:first-child{
    margin-bottom:-40px
  }

  .md\:first\:-mb-12:first-child{
    margin-bottom:-48px
  }

  .md\:first\:-mb-16:first-child{
    margin-bottom:-64px
  }

  .md\:first\:-mb-18:first-child{
    margin-bottom:-72px
  }

  .md\:first\:-mb-24:first-child{
    margin-bottom:-96px
  }

  .md\:first\:-mb-5dot5:first-child{
    margin-bottom:-22px
  }

  .md\:first\:-mb-full:first-child{
    margin-bottom:-100%
  }

  .md\:first\:ml-0:first-child{
    margin-left:0
  }

  .md\:first\:ml-1:first-child{
    margin-left:4px
  }

  .md\:first\:ml-2:first-child{
    margin-left:8px
  }

  .md\:first\:ml-3:first-child{
    margin-left:12px
  }

  .md\:first\:ml-4:first-child{
    margin-left:16px
  }

  .md\:first\:ml-5:first-child{
    margin-left:20px
  }

  .md\:first\:ml-6:first-child{
    margin-left:24px
  }

  .md\:first\:ml-8:first-child{
    margin-left:32px
  }

  .md\:first\:ml-10:first-child{
    margin-left:40px
  }

  .md\:first\:ml-12:first-child{
    margin-left:48px
  }

  .md\:first\:ml-16:first-child{
    margin-left:64px
  }

  .md\:first\:ml-18:first-child{
    margin-left:72px
  }

  .md\:first\:ml-24:first-child{
    margin-left:96px
  }

  .md\:first\:ml-auto:first-child{
    margin-left:auto
  }

  .md\:first\:ml-5dot5:first-child{
    margin-left:22px
  }

  .md\:first\:ml-full:first-child{
    margin-left:100%
  }

  .md\:first\:-ml-1:first-child{
    margin-left:-4px
  }

  .md\:first\:-ml-2:first-child{
    margin-left:-8px
  }

  .md\:first\:-ml-3:first-child{
    margin-left:-12px
  }

  .md\:first\:-ml-4:first-child{
    margin-left:-16px
  }

  .md\:first\:-ml-5:first-child{
    margin-left:-20px
  }

  .md\:first\:-ml-6:first-child{
    margin-left:-24px
  }

  .md\:first\:-ml-8:first-child{
    margin-left:-32px
  }

  .md\:first\:-ml-10:first-child{
    margin-left:-40px
  }

  .md\:first\:-ml-12:first-child{
    margin-left:-48px
  }

  .md\:first\:-ml-16:first-child{
    margin-left:-64px
  }

  .md\:first\:-ml-18:first-child{
    margin-left:-72px
  }

  .md\:first\:-ml-24:first-child{
    margin-left:-96px
  }

  .md\:first\:-ml-5dot5:first-child{
    margin-left:-22px
  }

  .md\:first\:-ml-full:first-child{
    margin-left:-100%
  }

  .md\:last\:mt-0:last-child{
    margin-top:0
  }

  .md\:last\:mt-1:last-child{
    margin-top:4px
  }

  .md\:last\:mt-2:last-child{
    margin-top:8px
  }

  .md\:last\:mt-3:last-child{
    margin-top:12px
  }

  .md\:last\:mt-4:last-child{
    margin-top:16px
  }

  .md\:last\:mt-5:last-child{
    margin-top:20px
  }

  .md\:last\:mt-6:last-child{
    margin-top:24px
  }

  .md\:last\:mt-8:last-child{
    margin-top:32px
  }

  .md\:last\:mt-10:last-child{
    margin-top:40px
  }

  .md\:last\:mt-12:last-child{
    margin-top:48px
  }

  .md\:last\:mt-16:last-child{
    margin-top:64px
  }

  .md\:last\:mt-18:last-child{
    margin-top:72px
  }

  .md\:last\:mt-24:last-child{
    margin-top:96px
  }

  .md\:last\:mt-auto:last-child{
    margin-top:auto
  }

  .md\:last\:mt-5dot5:last-child{
    margin-top:22px
  }

  .md\:last\:mt-full:last-child{
    margin-top:100%
  }

  .md\:last\:-mt-1:last-child{
    margin-top:-4px
  }

  .md\:last\:-mt-2:last-child{
    margin-top:-8px
  }

  .md\:last\:-mt-3:last-child{
    margin-top:-12px
  }

  .md\:last\:-mt-4:last-child{
    margin-top:-16px
  }

  .md\:last\:-mt-5:last-child{
    margin-top:-20px
  }

  .md\:last\:-mt-6:last-child{
    margin-top:-24px
  }

  .md\:last\:-mt-8:last-child{
    margin-top:-32px
  }

  .md\:last\:-mt-10:last-child{
    margin-top:-40px
  }

  .md\:last\:-mt-12:last-child{
    margin-top:-48px
  }

  .md\:last\:-mt-16:last-child{
    margin-top:-64px
  }

  .md\:last\:-mt-18:last-child{
    margin-top:-72px
  }

  .md\:last\:-mt-24:last-child{
    margin-top:-96px
  }

  .md\:last\:-mt-5dot5:last-child{
    margin-top:-22px
  }

  .md\:last\:-mt-full:last-child{
    margin-top:-100%
  }

  .md\:last\:mr-0:last-child{
    margin-right:0
  }

  .md\:last\:mr-1:last-child{
    margin-right:4px
  }

  .md\:last\:mr-2:last-child{
    margin-right:8px
  }

  .md\:last\:mr-3:last-child{
    margin-right:12px
  }

  .md\:last\:mr-4:last-child{
    margin-right:16px
  }

  .md\:last\:mr-5:last-child{
    margin-right:20px
  }

  .md\:last\:mr-6:last-child{
    margin-right:24px
  }

  .md\:last\:mr-8:last-child{
    margin-right:32px
  }

  .md\:last\:mr-10:last-child{
    margin-right:40px
  }

  .md\:last\:mr-12:last-child{
    margin-right:48px
  }

  .md\:last\:mr-16:last-child{
    margin-right:64px
  }

  .md\:last\:mr-18:last-child{
    margin-right:72px
  }

  .md\:last\:mr-24:last-child{
    margin-right:96px
  }

  .md\:last\:mr-auto:last-child{
    margin-right:auto
  }

  .md\:last\:mr-5dot5:last-child{
    margin-right:22px
  }

  .md\:last\:mr-full:last-child{
    margin-right:100%
  }

  .md\:last\:-mr-1:last-child{
    margin-right:-4px
  }

  .md\:last\:-mr-2:last-child{
    margin-right:-8px
  }

  .md\:last\:-mr-3:last-child{
    margin-right:-12px
  }

  .md\:last\:-mr-4:last-child{
    margin-right:-16px
  }

  .md\:last\:-mr-5:last-child{
    margin-right:-20px
  }

  .md\:last\:-mr-6:last-child{
    margin-right:-24px
  }

  .md\:last\:-mr-8:last-child{
    margin-right:-32px
  }

  .md\:last\:-mr-10:last-child{
    margin-right:-40px
  }

  .md\:last\:-mr-12:last-child{
    margin-right:-48px
  }

  .md\:last\:-mr-16:last-child{
    margin-right:-64px
  }

  .md\:last\:-mr-18:last-child{
    margin-right:-72px
  }

  .md\:last\:-mr-24:last-child{
    margin-right:-96px
  }

  .md\:last\:-mr-5dot5:last-child{
    margin-right:-22px
  }

  .md\:last\:-mr-full:last-child{
    margin-right:-100%
  }

  .md\:last\:mb-0:last-child{
    margin-bottom:0
  }

  .md\:last\:mb-1:last-child{
    margin-bottom:4px
  }

  .md\:last\:mb-2:last-child{
    margin-bottom:8px
  }

  .md\:last\:mb-3:last-child{
    margin-bottom:12px
  }

  .md\:last\:mb-4:last-child{
    margin-bottom:16px
  }

  .md\:last\:mb-5:last-child{
    margin-bottom:20px
  }

  .md\:last\:mb-6:last-child{
    margin-bottom:24px
  }

  .md\:last\:mb-8:last-child{
    margin-bottom:32px
  }

  .md\:last\:mb-10:last-child{
    margin-bottom:40px
  }

  .md\:last\:mb-12:last-child{
    margin-bottom:48px
  }

  .md\:last\:mb-16:last-child{
    margin-bottom:64px
  }

  .md\:last\:mb-18:last-child{
    margin-bottom:72px
  }

  .md\:last\:mb-24:last-child{
    margin-bottom:96px
  }

  .md\:last\:mb-auto:last-child{
    margin-bottom:auto
  }

  .md\:last\:mb-5dot5:last-child{
    margin-bottom:22px
  }

  .md\:last\:mb-full:last-child{
    margin-bottom:100%
  }

  .md\:last\:-mb-1:last-child{
    margin-bottom:-4px
  }

  .md\:last\:-mb-2:last-child{
    margin-bottom:-8px
  }

  .md\:last\:-mb-3:last-child{
    margin-bottom:-12px
  }

  .md\:last\:-mb-4:last-child{
    margin-bottom:-16px
  }

  .md\:last\:-mb-5:last-child{
    margin-bottom:-20px
  }

  .md\:last\:-mb-6:last-child{
    margin-bottom:-24px
  }

  .md\:last\:-mb-8:last-child{
    margin-bottom:-32px
  }

  .md\:last\:-mb-10:last-child{
    margin-bottom:-40px
  }

  .md\:last\:-mb-12:last-child{
    margin-bottom:-48px
  }

  .md\:last\:-mb-16:last-child{
    margin-bottom:-64px
  }

  .md\:last\:-mb-18:last-child{
    margin-bottom:-72px
  }

  .md\:last\:-mb-24:last-child{
    margin-bottom:-96px
  }

  .md\:last\:-mb-5dot5:last-child{
    margin-bottom:-22px
  }

  .md\:last\:-mb-full:last-child{
    margin-bottom:-100%
  }

  .md\:last\:ml-0:last-child{
    margin-left:0
  }

  .md\:last\:ml-1:last-child{
    margin-left:4px
  }

  .md\:last\:ml-2:last-child{
    margin-left:8px
  }

  .md\:last\:ml-3:last-child{
    margin-left:12px
  }

  .md\:last\:ml-4:last-child{
    margin-left:16px
  }

  .md\:last\:ml-5:last-child{
    margin-left:20px
  }

  .md\:last\:ml-6:last-child{
    margin-left:24px
  }

  .md\:last\:ml-8:last-child{
    margin-left:32px
  }

  .md\:last\:ml-10:last-child{
    margin-left:40px
  }

  .md\:last\:ml-12:last-child{
    margin-left:48px
  }

  .md\:last\:ml-16:last-child{
    margin-left:64px
  }

  .md\:last\:ml-18:last-child{
    margin-left:72px
  }

  .md\:last\:ml-24:last-child{
    margin-left:96px
  }

  .md\:last\:ml-auto:last-child{
    margin-left:auto
  }

  .md\:last\:ml-5dot5:last-child{
    margin-left:22px
  }

  .md\:last\:ml-full:last-child{
    margin-left:100%
  }

  .md\:last\:-ml-1:last-child{
    margin-left:-4px
  }

  .md\:last\:-ml-2:last-child{
    margin-left:-8px
  }

  .md\:last\:-ml-3:last-child{
    margin-left:-12px
  }

  .md\:last\:-ml-4:last-child{
    margin-left:-16px
  }

  .md\:last\:-ml-5:last-child{
    margin-left:-20px
  }

  .md\:last\:-ml-6:last-child{
    margin-left:-24px
  }

  .md\:last\:-ml-8:last-child{
    margin-left:-32px
  }

  .md\:last\:-ml-10:last-child{
    margin-left:-40px
  }

  .md\:last\:-ml-12:last-child{
    margin-left:-48px
  }

  .md\:last\:-ml-16:last-child{
    margin-left:-64px
  }

  .md\:last\:-ml-18:last-child{
    margin-left:-72px
  }

  .md\:last\:-ml-24:last-child{
    margin-left:-96px
  }

  .md\:last\:-ml-5dot5:last-child{
    margin-left:-22px
  }

  .md\:last\:-ml-full:last-child{
    margin-left:-100%
  }

  .md\:block{
    display:block
  }

  .md\:inline-block{
    display:inline-block
  }

  .md\:inline{
    display:inline
  }

  .md\:flex{
    display:flex
  }

  .md\:inline-flex{
    display:inline-flex
  }

  .md\:table{
    display:table
  }

  .md\:inline-table{
    display:inline-table
  }

  .md\:table-caption{
    display:table-caption
  }

  .md\:table-cell{
    display:table-cell
  }

  .md\:table-column{
    display:table-column
  }

  .md\:table-column-group{
    display:table-column-group
  }

  .md\:table-footer-group{
    display:table-footer-group
  }

  .md\:table-header-group{
    display:table-header-group
  }

  .md\:table-row-group{
    display:table-row-group
  }

  .md\:table-row{
    display:table-row
  }

  .md\:flow-root{
    display:flow-root
  }

  .md\:grid{
    display:grid
  }

  .md\:inline-grid{
    display:inline-grid
  }

  .md\:contents{
    display:contents
  }

  .md\:list-item{
    display:list-item
  }

  .md\:hidden{
    display:none
  }

  .md\:h-0{
    height:0
  }

  .md\:h-1{
    height:4px
  }

  .md\:h-2{
    height:8px
  }

  .md\:h-3{
    height:12px
  }

  .md\:h-4{
    height:16px
  }

  .md\:h-5{
    height:20px
  }

  .md\:h-6{
    height:24px
  }

  .md\:h-8{
    height:32px
  }

  .md\:h-10{
    height:40px
  }

  .md\:h-12{
    height:48px
  }

  .md\:h-15{
    height:60px
  }

  .md\:h-16{
    height:64px
  }

  .md\:h-18{
    height:72px
  }

  .md\:h-24{
    height:96px
  }

  .md\:h-auto{
    height:auto
  }

  .md\:h-5dot5{
    height:22px
  }

  .md\:h-full{
    height:100%
  }

  .md\:h-1\/2{
    height:50%
  }

  .md\:h-1\/3{
    height:33.333333%
  }

  .md\:h-2\/3{
    height:66.666667%
  }

  .md\:h-1\/4{
    height:25%
  }

  .md\:h-2\/4{
    height:50%
  }

  .md\:h-3\/4{
    height:75%
  }

  .md\:h-1\/5{
    height:20%
  }

  .md\:h-2\/5{
    height:40%
  }

  .md\:h-3\/5{
    height:60%
  }

  .md\:h-4\/5{
    height:80%
  }

  .md\:h-1\/6{
    height:16.666667%
  }

  .md\:h-2\/6{
    height:33.333333%
  }

  .md\:h-3\/6{
    height:50%
  }

  .md\:h-4\/6{
    height:66.666667%
  }

  .md\:h-5\/6{
    height:83.333333%
  }

  .md\:h-screen{
    height:100vh
  }

  .md\:max-h-0{
    max-height:0
  }

  .md\:max-h-1{
    max-height:4px
  }

  .md\:max-h-2{
    max-height:8px
  }

  .md\:max-h-3{
    max-height:12px
  }

  .md\:max-h-4{
    max-height:16px
  }

  .md\:max-h-5{
    max-height:20px
  }

  .md\:max-h-6{
    max-height:24px
  }

  .md\:max-h-8{
    max-height:32px
  }

  .md\:max-h-10{
    max-height:40px
  }

  .md\:max-h-12{
    max-height:48px
  }

  .md\:max-h-16{
    max-height:64px
  }

  .md\:max-h-18{
    max-height:72px
  }

  .md\:max-h-24{
    max-height:96px
  }

  .md\:max-h-5dot5{
    max-height:22px
  }

  .md\:max-h-full{
    max-height:100%
  }

  .md\:max-h-screen{
    max-height:100vh
  }

  .md\:min-h-0{
    min-height:0px
  }

  .md\:min-h-6{
    min-height:24px
  }

  .md\:min-h-10{
    min-height:40px
  }

  .md\:min-h-15{
    min-height:60px
  }

  .md\:min-h-100{
    min-height:400px
  }

  .md\:min-h-full{
    min-height:100%
  }

  .md\:min-h-screen{
    min-height:100vh
  }

  .md\:min-h-100vh{
    min-height:100vh
  }

  .md\:w-0{
    width:0
  }

  .md\:w-1{
    width:4px
  }

  .md\:w-2{
    width:8px
  }

  .md\:w-3{
    width:12px
  }

  .md\:w-4{
    width:16px
  }

  .md\:w-5{
    width:20px
  }

  .md\:w-6{
    width:24px
  }

  .md\:w-8{
    width:32px
  }

  .md\:w-10{
    width:40px
  }

  .md\:w-12{
    width:48px
  }

  .md\:w-16{
    width:64px
  }

  .md\:w-18{
    width:72px
  }

  .md\:w-24{
    width:96px
  }

  .md\:w-25{
    width:100px
  }

  .md\:w-26{
    width:104px
  }

  .md\:w-30{
    width:120px
  }

  .md\:w-80{
    width:320px
  }

  .md\:w-125{
    width:500px
  }

  .md\:w-160{
    width:640px
  }

  .md\:w-180{
    width:720px
  }

  .md\:w-200{
    width:800px
  }

  .md\:w-240{
    width:960px
  }

  .md\:w-320{
    width:1280px
  }

  .md\:w-auto{
    width:auto
  }

  .md\:w-5dot5{
    width:22px
  }

  .md\:w-full{
    width:100%
  }

  .md\:w-1\/2{
    width:50%
  }

  .md\:w-1\/3{
    width:33.333333%
  }

  .md\:w-2\/3{
    width:66.666667%
  }

  .md\:w-1\/4{
    width:25%
  }

  .md\:w-2\/4{
    width:50%
  }

  .md\:w-3\/4{
    width:75%
  }

  .md\:w-1\/5{
    width:20%
  }

  .md\:w-2\/5{
    width:40%
  }

  .md\:w-3\/5{
    width:60%
  }

  .md\:w-4\/5{
    width:80%
  }

  .md\:w-1\/6{
    width:16.666667%
  }

  .md\:w-2\/6{
    width:33.333333%
  }

  .md\:w-3\/6{
    width:50%
  }

  .md\:w-4\/6{
    width:66.666667%
  }

  .md\:w-5\/6{
    width:83.333333%
  }

  .md\:w-1\/12{
    width:8.333333%
  }

  .md\:w-2\/12{
    width:16.666667%
  }

  .md\:w-3\/12{
    width:25%
  }

  .md\:w-4\/12{
    width:33.333333%
  }

  .md\:w-5\/12{
    width:41.666667%
  }

  .md\:w-6\/12{
    width:50%
  }

  .md\:w-7\/12{
    width:58.333333%
  }

  .md\:w-8\/12{
    width:66.666667%
  }

  .md\:w-9\/12{
    width:75%
  }

  .md\:w-10\/12{
    width:83.333333%
  }

  .md\:w-11\/12{
    width:91.666667%
  }

  .md\:w-screen{
    width:100vw
  }

  .md\:w-min{
    width:min-content
  }

  .md\:w-max{
    width:max-content
  }

  .md\:min-w-0{
    min-width:0px
  }

  .md\:min-w-full{
    min-width:100%
  }

  .md\:min-w-min{
    min-width:min-content
  }

  .md\:min-w-max{
    min-width:max-content
  }

  .md\:max-w-0{
    max-width:0rem
  }

  .md\:max-w-none{
    max-width:none
  }

  .md\:max-w-xs{
    max-width:20rem
  }

  .md\:max-w-sm{
    max-width:24rem
  }

  .md\:max-w-md{
    max-width:28rem
  }

  .md\:max-w-lg{
    max-width:32rem
  }

  .md\:max-w-xl{
    max-width:36rem
  }

  .md\:max-w-2xl{
    max-width:42rem
  }

  .md\:max-w-3xl{
    max-width:48rem
  }

  .md\:max-w-4xl{
    max-width:56rem
  }

  .md\:max-w-5xl{
    max-width:64rem
  }

  .md\:max-w-6xl{
    max-width:72rem
  }

  .md\:max-w-7xl{
    max-width:80rem
  }

  .md\:max-w-full{
    max-width:100%
  }

  .md\:max-w-min{
    max-width:min-content
  }

  .md\:max-w-max{
    max-width:max-content
  }

  .md\:max-w-prose{
    max-width:65ch
  }

  .md\:max-w-grid-12{
    max-width:1176px
  }

  .md\:max-w-grid-10{
    max-width:984px
  }

  .md\:max-w-grid-8{
    max-width:768px
  }

  .md\:max-w-1\/4{
    max-width:25%
  }

  .md\:max-w-1\/2{
    max-width:50%
  }

  .md\:max-w-3\/4{
    max-width:75%
  }

  .md\:max-w-1\/5{
    max-width:20%
  }

  .md\:max-w-2\/5{
    max-width:40%
  }

  .md\:max-w-3\/5{
    max-width:60%
  }

  .md\:max-w-4\/5{
    max-width:80%
  }

  .md\:max-w-100vw{
    max-width:100vw
  }

  .md\:flex-1{
    flex:1 1 0%
  }

  .md\:flex-2{
    flex:2 1 0%
  }

  .md\:flex-3{
    flex:3 1 0%
  }

  .md\:flex-auto{
    flex:1 1 auto
  }

  .md\:flex-initial{
    flex:0 1 auto
  }

  .md\:flex-none{
    flex:none
  }

  .md\:flex-shrink-0{
    flex-shrink:0
  }

  .md\:flex-shrink{
    flex-shrink:1
  }

  .md\:flex-grow-0{
    flex-grow:0
  }

  .md\:flex-grow{
    flex-grow:1
  }

  .md\:border-collapse{
    border-collapse:collapse
  }

  .md\:border-separate{
    border-collapse:separate
  }

  .md\:transform{
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .md\:transform-gpu{
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    transform:translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .md\:transform-none{
    transform:none
  }

  .md\:translate-x-0{
    --tw-translate-x:0
  }

  .md\:translate-x-1{
    --tw-translate-x:4px
  }

  .md\:translate-x-2{
    --tw-translate-x:8px
  }

  .md\:translate-x-3{
    --tw-translate-x:12px
  }

  .md\:translate-x-4{
    --tw-translate-x:16px
  }

  .md\:translate-x-5{
    --tw-translate-x:20px
  }

  .md\:translate-x-6{
    --tw-translate-x:24px
  }

  .md\:translate-x-8{
    --tw-translate-x:32px
  }

  .md\:translate-x-10{
    --tw-translate-x:40px
  }

  .md\:translate-x-12{
    --tw-translate-x:48px
  }

  .md\:translate-x-16{
    --tw-translate-x:64px
  }

  .md\:translate-x-18{
    --tw-translate-x:72px
  }

  .md\:translate-x-24{
    --tw-translate-x:96px
  }

  .md\:translate-x-5dot5{
    --tw-translate-x:22px
  }

  .md\:translate-x-full{
    --tw-translate-x:100%
  }

  .md\:-translate-x-1{
    --tw-translate-x:-4px
  }

  .md\:-translate-x-2{
    --tw-translate-x:-8px
  }

  .md\:-translate-x-3{
    --tw-translate-x:-12px
  }

  .md\:-translate-x-4{
    --tw-translate-x:-16px
  }

  .md\:-translate-x-5{
    --tw-translate-x:-20px
  }

  .md\:-translate-x-6{
    --tw-translate-x:-24px
  }

  .md\:-translate-x-8{
    --tw-translate-x:-32px
  }

  .md\:-translate-x-10{
    --tw-translate-x:-40px
  }

  .md\:-translate-x-12{
    --tw-translate-x:-48px
  }

  .md\:-translate-x-16{
    --tw-translate-x:-64px
  }

  .md\:-translate-x-18{
    --tw-translate-x:-72px
  }

  .md\:-translate-x-24{
    --tw-translate-x:-96px
  }

  .md\:-translate-x-5dot5{
    --tw-translate-x:-22px
  }

  .md\:-translate-x-full{
    --tw-translate-x:-100%
  }

  .md\:translate-x-1\/2{
    --tw-translate-x:50%
  }

  .md\:translate-x-1\/3{
    --tw-translate-x:33.333333%
  }

  .md\:translate-x-2\/3{
    --tw-translate-x:66.666667%
  }

  .md\:translate-x-1\/4{
    --tw-translate-x:25%
  }

  .md\:translate-x-2\/4{
    --tw-translate-x:50%
  }

  .md\:translate-x-3\/4{
    --tw-translate-x:75%
  }

  .md\:-translate-x-1\/2{
    --tw-translate-x:-50%
  }

  .md\:-translate-x-1\/3{
    --tw-translate-x:-33.333333%
  }

  .md\:-translate-x-2\/3{
    --tw-translate-x:-66.666667%
  }

  .md\:-translate-x-1\/4{
    --tw-translate-x:-25%
  }

  .md\:-translate-x-2\/4{
    --tw-translate-x:-50%
  }

  .md\:-translate-x-3\/4{
    --tw-translate-x:-75%
  }

  .md\:translate-y-0{
    --tw-translate-y:0
  }

  .md\:translate-y-1{
    --tw-translate-y:4px
  }

  .md\:translate-y-2{
    --tw-translate-y:8px
  }

  .md\:translate-y-3{
    --tw-translate-y:12px
  }

  .md\:translate-y-4{
    --tw-translate-y:16px
  }

  .md\:translate-y-5{
    --tw-translate-y:20px
  }

  .md\:translate-y-6{
    --tw-translate-y:24px
  }

  .md\:translate-y-8{
    --tw-translate-y:32px
  }

  .md\:translate-y-10{
    --tw-translate-y:40px
  }

  .md\:translate-y-12{
    --tw-translate-y:48px
  }

  .md\:translate-y-16{
    --tw-translate-y:64px
  }

  .md\:translate-y-18{
    --tw-translate-y:72px
  }

  .md\:translate-y-24{
    --tw-translate-y:96px
  }

  .md\:translate-y-5dot5{
    --tw-translate-y:22px
  }

  .md\:translate-y-full{
    --tw-translate-y:100%
  }

  .md\:-translate-y-1{
    --tw-translate-y:-4px
  }

  .md\:-translate-y-2{
    --tw-translate-y:-8px
  }

  .md\:-translate-y-3{
    --tw-translate-y:-12px
  }

  .md\:-translate-y-4{
    --tw-translate-y:-16px
  }

  .md\:-translate-y-5{
    --tw-translate-y:-20px
  }

  .md\:-translate-y-6{
    --tw-translate-y:-24px
  }

  .md\:-translate-y-8{
    --tw-translate-y:-32px
  }

  .md\:-translate-y-10{
    --tw-translate-y:-40px
  }

  .md\:-translate-y-12{
    --tw-translate-y:-48px
  }

  .md\:-translate-y-16{
    --tw-translate-y:-64px
  }

  .md\:-translate-y-18{
    --tw-translate-y:-72px
  }

  .md\:-translate-y-24{
    --tw-translate-y:-96px
  }

  .md\:-translate-y-5dot5{
    --tw-translate-y:-22px
  }

  .md\:-translate-y-full{
    --tw-translate-y:-100%
  }

  .md\:translate-y-1\/2{
    --tw-translate-y:50%
  }

  .md\:translate-y-1\/3{
    --tw-translate-y:33.333333%
  }

  .md\:translate-y-2\/3{
    --tw-translate-y:66.666667%
  }

  .md\:translate-y-1\/4{
    --tw-translate-y:25%
  }

  .md\:translate-y-2\/4{
    --tw-translate-y:50%
  }

  .md\:translate-y-3\/4{
    --tw-translate-y:75%
  }

  .md\:-translate-y-1\/2{
    --tw-translate-y:-50%
  }

  .md\:-translate-y-1\/3{
    --tw-translate-y:-33.333333%
  }

  .md\:-translate-y-2\/3{
    --tw-translate-y:-66.666667%
  }

  .md\:-translate-y-1\/4{
    --tw-translate-y:-25%
  }

  .md\:-translate-y-2\/4{
    --tw-translate-y:-50%
  }

  .md\:-translate-y-3\/4{
    --tw-translate-y:-75%
  }

  .md\:hover\:translate-x-0:hover{
    --tw-translate-x:0
  }

  .md\:hover\:translate-x-1:hover{
    --tw-translate-x:4px
  }

  .md\:hover\:translate-x-2:hover{
    --tw-translate-x:8px
  }

  .md\:hover\:translate-x-3:hover{
    --tw-translate-x:12px
  }

  .md\:hover\:translate-x-4:hover{
    --tw-translate-x:16px
  }

  .md\:hover\:translate-x-5:hover{
    --tw-translate-x:20px
  }

  .md\:hover\:translate-x-6:hover{
    --tw-translate-x:24px
  }

  .md\:hover\:translate-x-8:hover{
    --tw-translate-x:32px
  }

  .md\:hover\:translate-x-10:hover{
    --tw-translate-x:40px
  }

  .md\:hover\:translate-x-12:hover{
    --tw-translate-x:48px
  }

  .md\:hover\:translate-x-16:hover{
    --tw-translate-x:64px
  }

  .md\:hover\:translate-x-18:hover{
    --tw-translate-x:72px
  }

  .md\:hover\:translate-x-24:hover{
    --tw-translate-x:96px
  }

  .md\:hover\:translate-x-5dot5:hover{
    --tw-translate-x:22px
  }

  .md\:hover\:translate-x-full:hover{
    --tw-translate-x:100%
  }

  .md\:hover\:-translate-x-1:hover{
    --tw-translate-x:-4px
  }

  .md\:hover\:-translate-x-2:hover{
    --tw-translate-x:-8px
  }

  .md\:hover\:-translate-x-3:hover{
    --tw-translate-x:-12px
  }

  .md\:hover\:-translate-x-4:hover{
    --tw-translate-x:-16px
  }

  .md\:hover\:-translate-x-5:hover{
    --tw-translate-x:-20px
  }

  .md\:hover\:-translate-x-6:hover{
    --tw-translate-x:-24px
  }

  .md\:hover\:-translate-x-8:hover{
    --tw-translate-x:-32px
  }

  .md\:hover\:-translate-x-10:hover{
    --tw-translate-x:-40px
  }

  .md\:hover\:-translate-x-12:hover{
    --tw-translate-x:-48px
  }

  .md\:hover\:-translate-x-16:hover{
    --tw-translate-x:-64px
  }

  .md\:hover\:-translate-x-18:hover{
    --tw-translate-x:-72px
  }

  .md\:hover\:-translate-x-24:hover{
    --tw-translate-x:-96px
  }

  .md\:hover\:-translate-x-5dot5:hover{
    --tw-translate-x:-22px
  }

  .md\:hover\:-translate-x-full:hover{
    --tw-translate-x:-100%
  }

  .md\:hover\:translate-x-1\/2:hover{
    --tw-translate-x:50%
  }

  .md\:hover\:translate-x-1\/3:hover{
    --tw-translate-x:33.333333%
  }

  .md\:hover\:translate-x-2\/3:hover{
    --tw-translate-x:66.666667%
  }

  .md\:hover\:translate-x-1\/4:hover{
    --tw-translate-x:25%
  }

  .md\:hover\:translate-x-2\/4:hover{
    --tw-translate-x:50%
  }

  .md\:hover\:translate-x-3\/4:hover{
    --tw-translate-x:75%
  }

  .md\:hover\:-translate-x-1\/2:hover{
    --tw-translate-x:-50%
  }

  .md\:hover\:-translate-x-1\/3:hover{
    --tw-translate-x:-33.333333%
  }

  .md\:hover\:-translate-x-2\/3:hover{
    --tw-translate-x:-66.666667%
  }

  .md\:hover\:-translate-x-1\/4:hover{
    --tw-translate-x:-25%
  }

  .md\:hover\:-translate-x-2\/4:hover{
    --tw-translate-x:-50%
  }

  .md\:hover\:-translate-x-3\/4:hover{
    --tw-translate-x:-75%
  }

  .md\:hover\:translate-y-0:hover{
    --tw-translate-y:0
  }

  .md\:hover\:translate-y-1:hover{
    --tw-translate-y:4px
  }

  .md\:hover\:translate-y-2:hover{
    --tw-translate-y:8px
  }

  .md\:hover\:translate-y-3:hover{
    --tw-translate-y:12px
  }

  .md\:hover\:translate-y-4:hover{
    --tw-translate-y:16px
  }

  .md\:hover\:translate-y-5:hover{
    --tw-translate-y:20px
  }

  .md\:hover\:translate-y-6:hover{
    --tw-translate-y:24px
  }

  .md\:hover\:translate-y-8:hover{
    --tw-translate-y:32px
  }

  .md\:hover\:translate-y-10:hover{
    --tw-translate-y:40px
  }

  .md\:hover\:translate-y-12:hover{
    --tw-translate-y:48px
  }

  .md\:hover\:translate-y-16:hover{
    --tw-translate-y:64px
  }

  .md\:hover\:translate-y-18:hover{
    --tw-translate-y:72px
  }

  .md\:hover\:translate-y-24:hover{
    --tw-translate-y:96px
  }

  .md\:hover\:translate-y-5dot5:hover{
    --tw-translate-y:22px
  }

  .md\:hover\:translate-y-full:hover{
    --tw-translate-y:100%
  }

  .md\:hover\:-translate-y-1:hover{
    --tw-translate-y:-4px
  }

  .md\:hover\:-translate-y-2:hover{
    --tw-translate-y:-8px
  }

  .md\:hover\:-translate-y-3:hover{
    --tw-translate-y:-12px
  }

  .md\:hover\:-translate-y-4:hover{
    --tw-translate-y:-16px
  }

  .md\:hover\:-translate-y-5:hover{
    --tw-translate-y:-20px
  }

  .md\:hover\:-translate-y-6:hover{
    --tw-translate-y:-24px
  }

  .md\:hover\:-translate-y-8:hover{
    --tw-translate-y:-32px
  }

  .md\:hover\:-translate-y-10:hover{
    --tw-translate-y:-40px
  }

  .md\:hover\:-translate-y-12:hover{
    --tw-translate-y:-48px
  }

  .md\:hover\:-translate-y-16:hover{
    --tw-translate-y:-64px
  }

  .md\:hover\:-translate-y-18:hover{
    --tw-translate-y:-72px
  }

  .md\:hover\:-translate-y-24:hover{
    --tw-translate-y:-96px
  }

  .md\:hover\:-translate-y-5dot5:hover{
    --tw-translate-y:-22px
  }

  .md\:hover\:-translate-y-full:hover{
    --tw-translate-y:-100%
  }

  .md\:hover\:translate-y-1\/2:hover{
    --tw-translate-y:50%
  }

  .md\:hover\:translate-y-1\/3:hover{
    --tw-translate-y:33.333333%
  }

  .md\:hover\:translate-y-2\/3:hover{
    --tw-translate-y:66.666667%
  }

  .md\:hover\:translate-y-1\/4:hover{
    --tw-translate-y:25%
  }

  .md\:hover\:translate-y-2\/4:hover{
    --tw-translate-y:50%
  }

  .md\:hover\:translate-y-3\/4:hover{
    --tw-translate-y:75%
  }

  .md\:hover\:-translate-y-1\/2:hover{
    --tw-translate-y:-50%
  }

  .md\:hover\:-translate-y-1\/3:hover{
    --tw-translate-y:-33.333333%
  }

  .md\:hover\:-translate-y-2\/3:hover{
    --tw-translate-y:-66.666667%
  }

  .md\:hover\:-translate-y-1\/4:hover{
    --tw-translate-y:-25%
  }

  .md\:hover\:-translate-y-2\/4:hover{
    --tw-translate-y:-50%
  }

  .md\:hover\:-translate-y-3\/4:hover{
    --tw-translate-y:-75%
  }

  .md\:focus\:translate-x-0:focus{
    --tw-translate-x:0
  }

  .md\:focus\:translate-x-1:focus{
    --tw-translate-x:4px
  }

  .md\:focus\:translate-x-2:focus{
    --tw-translate-x:8px
  }

  .md\:focus\:translate-x-3:focus{
    --tw-translate-x:12px
  }

  .md\:focus\:translate-x-4:focus{
    --tw-translate-x:16px
  }

  .md\:focus\:translate-x-5:focus{
    --tw-translate-x:20px
  }

  .md\:focus\:translate-x-6:focus{
    --tw-translate-x:24px
  }

  .md\:focus\:translate-x-8:focus{
    --tw-translate-x:32px
  }

  .md\:focus\:translate-x-10:focus{
    --tw-translate-x:40px
  }

  .md\:focus\:translate-x-12:focus{
    --tw-translate-x:48px
  }

  .md\:focus\:translate-x-16:focus{
    --tw-translate-x:64px
  }

  .md\:focus\:translate-x-18:focus{
    --tw-translate-x:72px
  }

  .md\:focus\:translate-x-24:focus{
    --tw-translate-x:96px
  }

  .md\:focus\:translate-x-5dot5:focus{
    --tw-translate-x:22px
  }

  .md\:focus\:translate-x-full:focus{
    --tw-translate-x:100%
  }

  .md\:focus\:-translate-x-1:focus{
    --tw-translate-x:-4px
  }

  .md\:focus\:-translate-x-2:focus{
    --tw-translate-x:-8px
  }

  .md\:focus\:-translate-x-3:focus{
    --tw-translate-x:-12px
  }

  .md\:focus\:-translate-x-4:focus{
    --tw-translate-x:-16px
  }

  .md\:focus\:-translate-x-5:focus{
    --tw-translate-x:-20px
  }

  .md\:focus\:-translate-x-6:focus{
    --tw-translate-x:-24px
  }

  .md\:focus\:-translate-x-8:focus{
    --tw-translate-x:-32px
  }

  .md\:focus\:-translate-x-10:focus{
    --tw-translate-x:-40px
  }

  .md\:focus\:-translate-x-12:focus{
    --tw-translate-x:-48px
  }

  .md\:focus\:-translate-x-16:focus{
    --tw-translate-x:-64px
  }

  .md\:focus\:-translate-x-18:focus{
    --tw-translate-x:-72px
  }

  .md\:focus\:-translate-x-24:focus{
    --tw-translate-x:-96px
  }

  .md\:focus\:-translate-x-5dot5:focus{
    --tw-translate-x:-22px
  }

  .md\:focus\:-translate-x-full:focus{
    --tw-translate-x:-100%
  }

  .md\:focus\:translate-x-1\/2:focus{
    --tw-translate-x:50%
  }

  .md\:focus\:translate-x-1\/3:focus{
    --tw-translate-x:33.333333%
  }

  .md\:focus\:translate-x-2\/3:focus{
    --tw-translate-x:66.666667%
  }

  .md\:focus\:translate-x-1\/4:focus{
    --tw-translate-x:25%
  }

  .md\:focus\:translate-x-2\/4:focus{
    --tw-translate-x:50%
  }

  .md\:focus\:translate-x-3\/4:focus{
    --tw-translate-x:75%
  }

  .md\:focus\:-translate-x-1\/2:focus{
    --tw-translate-x:-50%
  }

  .md\:focus\:-translate-x-1\/3:focus{
    --tw-translate-x:-33.333333%
  }

  .md\:focus\:-translate-x-2\/3:focus{
    --tw-translate-x:-66.666667%
  }

  .md\:focus\:-translate-x-1\/4:focus{
    --tw-translate-x:-25%
  }

  .md\:focus\:-translate-x-2\/4:focus{
    --tw-translate-x:-50%
  }

  .md\:focus\:-translate-x-3\/4:focus{
    --tw-translate-x:-75%
  }

  .md\:focus\:translate-y-0:focus{
    --tw-translate-y:0
  }

  .md\:focus\:translate-y-1:focus{
    --tw-translate-y:4px
  }

  .md\:focus\:translate-y-2:focus{
    --tw-translate-y:8px
  }

  .md\:focus\:translate-y-3:focus{
    --tw-translate-y:12px
  }

  .md\:focus\:translate-y-4:focus{
    --tw-translate-y:16px
  }

  .md\:focus\:translate-y-5:focus{
    --tw-translate-y:20px
  }

  .md\:focus\:translate-y-6:focus{
    --tw-translate-y:24px
  }

  .md\:focus\:translate-y-8:focus{
    --tw-translate-y:32px
  }

  .md\:focus\:translate-y-10:focus{
    --tw-translate-y:40px
  }

  .md\:focus\:translate-y-12:focus{
    --tw-translate-y:48px
  }

  .md\:focus\:translate-y-16:focus{
    --tw-translate-y:64px
  }

  .md\:focus\:translate-y-18:focus{
    --tw-translate-y:72px
  }

  .md\:focus\:translate-y-24:focus{
    --tw-translate-y:96px
  }

  .md\:focus\:translate-y-5dot5:focus{
    --tw-translate-y:22px
  }

  .md\:focus\:translate-y-full:focus{
    --tw-translate-y:100%
  }

  .md\:focus\:-translate-y-1:focus{
    --tw-translate-y:-4px
  }

  .md\:focus\:-translate-y-2:focus{
    --tw-translate-y:-8px
  }

  .md\:focus\:-translate-y-3:focus{
    --tw-translate-y:-12px
  }

  .md\:focus\:-translate-y-4:focus{
    --tw-translate-y:-16px
  }

  .md\:focus\:-translate-y-5:focus{
    --tw-translate-y:-20px
  }

  .md\:focus\:-translate-y-6:focus{
    --tw-translate-y:-24px
  }

  .md\:focus\:-translate-y-8:focus{
    --tw-translate-y:-32px
  }

  .md\:focus\:-translate-y-10:focus{
    --tw-translate-y:-40px
  }

  .md\:focus\:-translate-y-12:focus{
    --tw-translate-y:-48px
  }

  .md\:focus\:-translate-y-16:focus{
    --tw-translate-y:-64px
  }

  .md\:focus\:-translate-y-18:focus{
    --tw-translate-y:-72px
  }

  .md\:focus\:-translate-y-24:focus{
    --tw-translate-y:-96px
  }

  .md\:focus\:-translate-y-5dot5:focus{
    --tw-translate-y:-22px
  }

  .md\:focus\:-translate-y-full:focus{
    --tw-translate-y:-100%
  }

  .md\:focus\:translate-y-1\/2:focus{
    --tw-translate-y:50%
  }

  .md\:focus\:translate-y-1\/3:focus{
    --tw-translate-y:33.333333%
  }

  .md\:focus\:translate-y-2\/3:focus{
    --tw-translate-y:66.666667%
  }

  .md\:focus\:translate-y-1\/4:focus{
    --tw-translate-y:25%
  }

  .md\:focus\:translate-y-2\/4:focus{
    --tw-translate-y:50%
  }

  .md\:focus\:translate-y-3\/4:focus{
    --tw-translate-y:75%
  }

  .md\:focus\:-translate-y-1\/2:focus{
    --tw-translate-y:-50%
  }

  .md\:focus\:-translate-y-1\/3:focus{
    --tw-translate-y:-33.333333%
  }

  .md\:focus\:-translate-y-2\/3:focus{
    --tw-translate-y:-66.666667%
  }

  .md\:focus\:-translate-y-1\/4:focus{
    --tw-translate-y:-25%
  }

  .md\:focus\:-translate-y-2\/4:focus{
    --tw-translate-y:-50%
  }

  .md\:focus\:-translate-y-3\/4:focus{
    --tw-translate-y:-75%
  }

  .md\:rotate-0{
    --tw-rotate:0deg
  }

  .md\:rotate-1{
    --tw-rotate:1deg
  }

  .md\:rotate-2{
    --tw-rotate:2deg
  }

  .md\:rotate-3{
    --tw-rotate:3deg
  }

  .md\:rotate-6{
    --tw-rotate:6deg
  }

  .md\:rotate-12{
    --tw-rotate:12deg
  }

  .md\:rotate-45{
    --tw-rotate:45deg
  }

  .md\:rotate-90{
    --tw-rotate:90deg
  }

  .md\:rotate-180{
    --tw-rotate:180deg
  }

  .md\:-rotate-180{
    --tw-rotate:-180deg
  }

  .md\:-rotate-90{
    --tw-rotate:-90deg
  }

  .md\:-rotate-45{
    --tw-rotate:-45deg
  }

  .md\:-rotate-12{
    --tw-rotate:-12deg
  }

  .md\:-rotate-6{
    --tw-rotate:-6deg
  }

  .md\:-rotate-3{
    --tw-rotate:-3deg
  }

  .md\:-rotate-2{
    --tw-rotate:-2deg
  }

  .md\:-rotate-1{
    --tw-rotate:-1deg
  }

  .md\:hover\:rotate-0:hover{
    --tw-rotate:0deg
  }

  .md\:hover\:rotate-1:hover{
    --tw-rotate:1deg
  }

  .md\:hover\:rotate-2:hover{
    --tw-rotate:2deg
  }

  .md\:hover\:rotate-3:hover{
    --tw-rotate:3deg
  }

  .md\:hover\:rotate-6:hover{
    --tw-rotate:6deg
  }

  .md\:hover\:rotate-12:hover{
    --tw-rotate:12deg
  }

  .md\:hover\:rotate-45:hover{
    --tw-rotate:45deg
  }

  .md\:hover\:rotate-90:hover{
    --tw-rotate:90deg
  }

  .md\:hover\:rotate-180:hover{
    --tw-rotate:180deg
  }

  .md\:hover\:-rotate-180:hover{
    --tw-rotate:-180deg
  }

  .md\:hover\:-rotate-90:hover{
    --tw-rotate:-90deg
  }

  .md\:hover\:-rotate-45:hover{
    --tw-rotate:-45deg
  }

  .md\:hover\:-rotate-12:hover{
    --tw-rotate:-12deg
  }

  .md\:hover\:-rotate-6:hover{
    --tw-rotate:-6deg
  }

  .md\:hover\:-rotate-3:hover{
    --tw-rotate:-3deg
  }

  .md\:hover\:-rotate-2:hover{
    --tw-rotate:-2deg
  }

  .md\:hover\:-rotate-1:hover{
    --tw-rotate:-1deg
  }

  .md\:focus\:rotate-0:focus{
    --tw-rotate:0deg
  }

  .md\:focus\:rotate-1:focus{
    --tw-rotate:1deg
  }

  .md\:focus\:rotate-2:focus{
    --tw-rotate:2deg
  }

  .md\:focus\:rotate-3:focus{
    --tw-rotate:3deg
  }

  .md\:focus\:rotate-6:focus{
    --tw-rotate:6deg
  }

  .md\:focus\:rotate-12:focus{
    --tw-rotate:12deg
  }

  .md\:focus\:rotate-45:focus{
    --tw-rotate:45deg
  }

  .md\:focus\:rotate-90:focus{
    --tw-rotate:90deg
  }

  .md\:focus\:rotate-180:focus{
    --tw-rotate:180deg
  }

  .md\:focus\:-rotate-180:focus{
    --tw-rotate:-180deg
  }

  .md\:focus\:-rotate-90:focus{
    --tw-rotate:-90deg
  }

  .md\:focus\:-rotate-45:focus{
    --tw-rotate:-45deg
  }

  .md\:focus\:-rotate-12:focus{
    --tw-rotate:-12deg
  }

  .md\:focus\:-rotate-6:focus{
    --tw-rotate:-6deg
  }

  .md\:focus\:-rotate-3:focus{
    --tw-rotate:-3deg
  }

  .md\:focus\:-rotate-2:focus{
    --tw-rotate:-2deg
  }

  .md\:focus\:-rotate-1:focus{
    --tw-rotate:-1deg
  }

  .md\:animate-none{
    animation:none
  }

  .md\:animate-spin{
    animation:spin 1s linear infinite
  }

  .md\:animate-ping{
    animation:ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .md\:animate-pulse{
    animation:pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .md\:animate-bounce{
    animation:bounce 1s infinite
  }

  .md\:animate-spin-delay{
    animation:spin-delay 2.4s ease infinite
  }

  .md\:cursor-auto{
    cursor:auto
  }

  .md\:cursor-default{
    cursor:default
  }

  .md\:cursor-pointer{
    cursor:pointer
  }

  .md\:cursor-wait{
    cursor:wait
  }

  .md\:cursor-text{
    cursor:text
  }

  .md\:cursor-move{
    cursor:move
  }

  .md\:cursor-help{
    cursor:help
  }

  .md\:cursor-not-allowed{
    cursor:not-allowed
  }

  .md\:select-none{
    -webkit-user-select:none;
            user-select:none
  }

  .md\:select-text{
    -webkit-user-select:text;
            user-select:text
  }

  .md\:select-all{
    -webkit-user-select:all;
            user-select:all
  }

  .md\:select-auto{
    -webkit-user-select:auto;
            user-select:auto
  }

  .md\:auto-cols-auto{
    grid-auto-columns:auto
  }

  .md\:auto-cols-min{
    grid-auto-columns:min-content
  }

  .md\:auto-cols-max{
    grid-auto-columns:max-content
  }

  .md\:auto-cols-fr{
    grid-auto-columns:minmax(0, 1fr)
  }

  .md\:grid-flow-row{
    grid-auto-flow:row
  }

  .md\:grid-flow-col{
    grid-auto-flow:column
  }

  .md\:grid-flow-row-dense{
    grid-auto-flow:row dense
  }

  .md\:grid-flow-col-dense{
    grid-auto-flow:column dense
  }

  .md\:auto-rows-auto{
    grid-auto-rows:auto
  }

  .md\:auto-rows-min{
    grid-auto-rows:min-content
  }

  .md\:auto-rows-max{
    grid-auto-rows:max-content
  }

  .md\:auto-rows-fr{
    grid-auto-rows:minmax(0, 1fr)
  }

  .md\:grid-cols-1{
    grid-template-columns:repeat(1, minmax(0, 1fr))
  }

  .md\:grid-cols-2{
    grid-template-columns:repeat(2, minmax(0, 1fr))
  }

  .md\:grid-cols-3{
    grid-template-columns:repeat(3, minmax(0, 1fr))
  }

  .md\:grid-cols-4{
    grid-template-columns:repeat(4, minmax(0, 1fr))
  }

  .md\:grid-cols-5{
    grid-template-columns:repeat(5, minmax(0, 1fr))
  }

  .md\:grid-cols-6{
    grid-template-columns:repeat(6, minmax(0, 1fr))
  }

  .md\:grid-cols-7{
    grid-template-columns:repeat(7, minmax(0, 1fr))
  }

  .md\:grid-cols-8{
    grid-template-columns:repeat(8, minmax(0, 1fr))
  }

  .md\:grid-cols-9{
    grid-template-columns:repeat(9, minmax(0, 1fr))
  }

  .md\:grid-cols-10{
    grid-template-columns:repeat(10, minmax(0, 1fr))
  }

  .md\:grid-cols-11{
    grid-template-columns:repeat(11, minmax(0, 1fr))
  }

  .md\:grid-cols-12{
    grid-template-columns:repeat(12, minmax(0, 1fr))
  }

  .md\:grid-cols-none{
    grid-template-columns:none
  }

  .md\:grid-rows-1{
    grid-template-rows:repeat(1, minmax(0, 1fr))
  }

  .md\:grid-rows-2{
    grid-template-rows:repeat(2, minmax(0, 1fr))
  }

  .md\:grid-rows-3{
    grid-template-rows:repeat(3, minmax(0, 1fr))
  }

  .md\:grid-rows-4{
    grid-template-rows:repeat(4, minmax(0, 1fr))
  }

  .md\:grid-rows-5{
    grid-template-rows:repeat(5, minmax(0, 1fr))
  }

  .md\:grid-rows-6{
    grid-template-rows:repeat(6, minmax(0, 1fr))
  }

  .md\:grid-rows-none{
    grid-template-rows:none
  }

  .md\:flex-row{
    flex-direction:row
  }

  .md\:flex-row-reverse{
    flex-direction:row-reverse
  }

  .md\:flex-col{
    flex-direction:column
  }

  .md\:flex-col-reverse{
    flex-direction:column-reverse
  }

  .md\:flex-wrap{
    flex-wrap:wrap
  }

  .md\:flex-wrap-reverse{
    flex-wrap:wrap-reverse
  }

  .md\:flex-nowrap{
    flex-wrap:nowrap
  }

  .md\:place-content-center{
    place-content:center
  }

  .md\:place-content-start{
    place-content:start
  }

  .md\:place-content-end{
    place-content:end
  }

  .md\:place-content-between{
    place-content:space-between
  }

  .md\:place-content-around{
    place-content:space-around
  }

  .md\:place-content-evenly{
    place-content:space-evenly
  }

  .md\:place-content-stretch{
    place-content:stretch
  }

  .md\:place-items-start{
    place-items:start
  }

  .md\:place-items-end{
    place-items:end
  }

  .md\:place-items-center{
    place-items:center
  }

  .md\:place-items-stretch{
    place-items:stretch
  }

  .md\:content-center{
    align-content:center
  }

  .md\:content-start{
    align-content:flex-start
  }

  .md\:content-end{
    align-content:flex-end
  }

  .md\:content-between{
    align-content:space-between
  }

  .md\:content-around{
    align-content:space-around
  }

  .md\:content-evenly{
    align-content:space-evenly
  }

  .md\:items-start{
    align-items:flex-start
  }

  .md\:items-end{
    align-items:flex-end
  }

  .md\:items-center{
    align-items:center
  }

  .md\:items-baseline{
    align-items:baseline
  }

  .md\:items-stretch{
    align-items:stretch
  }

  .md\:justify-start{
    justify-content:flex-start
  }

  .md\:justify-end{
    justify-content:flex-end
  }

  .md\:justify-center{
    justify-content:center
  }

  .md\:justify-between{
    justify-content:space-between
  }

  .md\:justify-around{
    justify-content:space-around
  }

  .md\:justify-evenly{
    justify-content:space-evenly
  }

  .md\:justify-items-start{
    justify-items:start
  }

  .md\:justify-items-end{
    justify-items:end
  }

  .md\:justify-items-center{
    justify-items:center
  }

  .md\:justify-items-stretch{
    justify-items:stretch
  }

  .md\:space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(0px * var(--tw-space-x-reverse));
    margin-left:calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(4px * var(--tw-space-x-reverse));
    margin-left:calc(4px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(8px * var(--tw-space-x-reverse));
    margin-left:calc(8px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(12px * var(--tw-space-x-reverse));
    margin-left:calc(12px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(16px * var(--tw-space-x-reverse));
    margin-left:calc(16px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(20px * var(--tw-space-x-reverse));
    margin-left:calc(20px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(24px * var(--tw-space-x-reverse));
    margin-left:calc(24px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(32px * var(--tw-space-x-reverse));
    margin-left:calc(32px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(40px * var(--tw-space-x-reverse));
    margin-left:calc(40px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(48px * var(--tw-space-x-reverse));
    margin-left:calc(48px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(64px * var(--tw-space-x-reverse));
    margin-left:calc(64px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(72px * var(--tw-space-x-reverse));
    margin-left:calc(72px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(96px * var(--tw-space-x-reverse));
    margin-left:calc(96px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(22px * var(--tw-space-x-reverse));
    margin-left:calc(22px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(100% * var(--tw-space-x-reverse));
    margin-left:calc(100% * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-4px * var(--tw-space-x-reverse));
    margin-left:calc(-4px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-8px * var(--tw-space-x-reverse));
    margin-left:calc(-8px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-12px * var(--tw-space-x-reverse));
    margin-left:calc(-12px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-16px * var(--tw-space-x-reverse));
    margin-left:calc(-16px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-20px * var(--tw-space-x-reverse));
    margin-left:calc(-20px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-24px * var(--tw-space-x-reverse));
    margin-left:calc(-24px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-32px * var(--tw-space-x-reverse));
    margin-left:calc(-32px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-40px * var(--tw-space-x-reverse));
    margin-left:calc(-40px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-48px * var(--tw-space-x-reverse));
    margin-left:calc(-48px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-64px * var(--tw-space-x-reverse));
    margin-left:calc(-64px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-72px * var(--tw-space-x-reverse));
    margin-left:calc(-72px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-96px * var(--tw-space-x-reverse));
    margin-left:calc(-96px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-22px * var(--tw-space-x-reverse));
    margin-left:calc(-22px * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:-space-x-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-100% * var(--tw-space-x-reverse));
    margin-left:calc(-100% * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }

  .md\:space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(4px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(4px * var(--tw-space-y-reverse))
  }

  .md\:space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(8px * var(--tw-space-y-reverse))
  }

  .md\:space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(12px * var(--tw-space-y-reverse))
  }

  .md\:space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(16px * var(--tw-space-y-reverse))
  }

  .md\:space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(20px * var(--tw-space-y-reverse))
  }

  .md\:space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(24px * var(--tw-space-y-reverse))
  }

  .md\:space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(32px * var(--tw-space-y-reverse))
  }

  .md\:space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(40px * var(--tw-space-y-reverse))
  }

  .md\:space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(48px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(48px * var(--tw-space-y-reverse))
  }

  .md\:space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(64px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(64px * var(--tw-space-y-reverse))
  }

  .md\:space-y-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(72px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(72px * var(--tw-space-y-reverse))
  }

  .md\:space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(96px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(96px * var(--tw-space-y-reverse))
  }

  .md\:space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(22px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(22px * var(--tw-space-y-reverse))
  }

  .md\:space-y-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(100% * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(100% * var(--tw-space-y-reverse))
  }

  .md\:-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-4px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-4px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-8px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-12px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-16px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-20px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-24px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-32px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-40px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-48px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-48px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-64px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-64px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-72px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-72px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-96px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-96px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-22px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-22px * var(--tw-space-y-reverse))
  }

  .md\:-space-y-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-100% * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-100% * var(--tw-space-y-reverse))
  }

  .md\:space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:1
  }

  .md\:space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:1
  }

  .md\:divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style:solid
  }

  .md\:divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style:dashed
  }

  .md\:divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style:dotted
  }

  .md\:divide-double > :not([hidden]) ~ :not([hidden]){
    border-style:double
  }

  .md\:divide-none > :not([hidden]) ~ :not([hidden]){
    border-style:none
  }

  .md\:place-self-auto{
    place-self:auto
  }

  .md\:place-self-start{
    place-self:start
  }

  .md\:place-self-end{
    place-self:end
  }

  .md\:place-self-center{
    place-self:center
  }

  .md\:place-self-stretch{
    place-self:stretch
  }

  .md\:self-auto{
    align-self:auto
  }

  .md\:self-start{
    align-self:flex-start
  }

  .md\:self-end{
    align-self:flex-end
  }

  .md\:self-center{
    align-self:center
  }

  .md\:self-stretch{
    align-self:stretch
  }

  .md\:self-baseline{
    align-self:baseline
  }

  .md\:justify-self-auto{
    justify-self:auto
  }

  .md\:justify-self-start{
    justify-self:start
  }

  .md\:justify-self-end{
    justify-self:end
  }

  .md\:justify-self-center{
    justify-self:center
  }

  .md\:justify-self-stretch{
    justify-self:stretch
  }

  .md\:overflow-auto{
    overflow:auto
  }

  .md\:overflow-hidden{
    overflow:hidden
  }

  .md\:overflow-visible{
    overflow:visible
  }

  .md\:overflow-scroll{
    overflow:scroll
  }

  .md\:overflow-x-auto{
    overflow-x:auto
  }

  .md\:overflow-y-auto{
    overflow-y:auto
  }

  .md\:overflow-x-hidden{
    overflow-x:hidden
  }

  .md\:overflow-y-hidden{
    overflow-y:hidden
  }

  .md\:overflow-x-visible{
    overflow-x:visible
  }

  .md\:overflow-y-visible{
    overflow-y:visible
  }

  .md\:overflow-x-scroll{
    overflow-x:scroll
  }

  .md\:overflow-y-scroll{
    overflow-y:scroll
  }

  .md\:overscroll-auto{
    overscroll-behavior:auto
  }

  .md\:overscroll-contain{
    overscroll-behavior:contain
  }

  .md\:overscroll-none{
    overscroll-behavior:none
  }

  .md\:overscroll-y-auto{
    overscroll-behavior-y:auto
  }

  .md\:overscroll-y-contain{
    overscroll-behavior-y:contain
  }

  .md\:overscroll-y-none{
    overscroll-behavior-y:none
  }

  .md\:overscroll-x-auto{
    overscroll-behavior-x:auto
  }

  .md\:overscroll-x-contain{
    overscroll-behavior-x:contain
  }

  .md\:overscroll-x-none{
    overscroll-behavior-x:none
  }

  .md\:truncate{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
  }

  .md\:overflow-ellipsis{
    text-overflow:ellipsis
  }

  .md\:overflow-clip{
    text-overflow:clip
  }

  .md\:whitespace-normal{
    white-space:normal
  }

  .md\:whitespace-nowrap{
    white-space:nowrap
  }

  .md\:whitespace-pre{
    white-space:pre
  }

  .md\:whitespace-pre-line{
    white-space:pre-line
  }

  .md\:whitespace-pre-wrap{
    white-space:pre-wrap
  }

  .md\:break-normal{
    overflow-wrap:normal;
    word-break:normal
  }

  .md\:break-words{
    overflow-wrap:break-word
  }

  .md\:break-all{
    word-break:break-all
  }

  .md\:rounded-1{
    border-radius:4px
  }

  .md\:rounded-2{
    border-radius:8px
  }

  .md\:rounded-3{
    border-radius:12px
  }

  .md\:rounded-4{
    border-radius:16px
  }

  .md\:rounded-none{
    border-radius:0px
  }

  .md\:rounded-sm{
    border-radius:0.125rem
  }

  .md\:rounded{
    border-radius:0.25rem
  }

  .md\:rounded-md{
    border-radius:0.375rem
  }

  .md\:rounded-lg{
    border-radius:0.5rem
  }

  .md\:rounded-xl{
    border-radius:0.75rem
  }

  .md\:rounded-2xl{
    border-radius:1rem
  }

  .md\:rounded-3xl{
    border-radius:1.5rem
  }

  .md\:rounded-full{
    border-radius:9999px
  }

  .md\:rounded-default{
    border-radius:4px
  }

  .md\:rounded-t-1{
    border-top-left-radius:4px;
    border-top-right-radius:4px
  }

  .md\:rounded-t-2{
    border-top-left-radius:8px;
    border-top-right-radius:8px
  }

  .md\:rounded-t-3{
    border-top-left-radius:12px;
    border-top-right-radius:12px
  }

  .md\:rounded-t-4{
    border-top-left-radius:16px;
    border-top-right-radius:16px
  }

  .md\:rounded-t-none{
    border-top-left-radius:0px;
    border-top-right-radius:0px
  }

  .md\:rounded-t-sm{
    border-top-left-radius:0.125rem;
    border-top-right-radius:0.125rem
  }

  .md\:rounded-t{
    border-top-left-radius:0.25rem;
    border-top-right-radius:0.25rem
  }

  .md\:rounded-t-md{
    border-top-left-radius:0.375rem;
    border-top-right-radius:0.375rem
  }

  .md\:rounded-t-lg{
    border-top-left-radius:0.5rem;
    border-top-right-radius:0.5rem
  }

  .md\:rounded-t-xl{
    border-top-left-radius:0.75rem;
    border-top-right-radius:0.75rem
  }

  .md\:rounded-t-2xl{
    border-top-left-radius:1rem;
    border-top-right-radius:1rem
  }

  .md\:rounded-t-3xl{
    border-top-left-radius:1.5rem;
    border-top-right-radius:1.5rem
  }

  .md\:rounded-t-full{
    border-top-left-radius:9999px;
    border-top-right-radius:9999px
  }

  .md\:rounded-t-default{
    border-top-left-radius:4px;
    border-top-right-radius:4px
  }

  .md\:rounded-r-1{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
  }

  .md\:rounded-r-2{
    border-top-right-radius:8px;
    border-bottom-right-radius:8px
  }

  .md\:rounded-r-3{
    border-top-right-radius:12px;
    border-bottom-right-radius:12px
  }

  .md\:rounded-r-4{
    border-top-right-radius:16px;
    border-bottom-right-radius:16px
  }

  .md\:rounded-r-none{
    border-top-right-radius:0px;
    border-bottom-right-radius:0px
  }

  .md\:rounded-r-sm{
    border-top-right-radius:0.125rem;
    border-bottom-right-radius:0.125rem
  }

  .md\:rounded-r{
    border-top-right-radius:0.25rem;
    border-bottom-right-radius:0.25rem
  }

  .md\:rounded-r-md{
    border-top-right-radius:0.375rem;
    border-bottom-right-radius:0.375rem
  }

  .md\:rounded-r-lg{
    border-top-right-radius:0.5rem;
    border-bottom-right-radius:0.5rem
  }

  .md\:rounded-r-xl{
    border-top-right-radius:0.75rem;
    border-bottom-right-radius:0.75rem
  }

  .md\:rounded-r-2xl{
    border-top-right-radius:1rem;
    border-bottom-right-radius:1rem
  }

  .md\:rounded-r-3xl{
    border-top-right-radius:1.5rem;
    border-bottom-right-radius:1.5rem
  }

  .md\:rounded-r-full{
    border-top-right-radius:9999px;
    border-bottom-right-radius:9999px
  }

  .md\:rounded-r-default{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
  }

  .md\:rounded-b-1{
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px
  }

  .md\:rounded-b-2{
    border-bottom-right-radius:8px;
    border-bottom-left-radius:8px
  }

  .md\:rounded-b-3{
    border-bottom-right-radius:12px;
    border-bottom-left-radius:12px
  }

  .md\:rounded-b-4{
    border-bottom-right-radius:16px;
    border-bottom-left-radius:16px
  }

  .md\:rounded-b-none{
    border-bottom-right-radius:0px;
    border-bottom-left-radius:0px
  }

  .md\:rounded-b-sm{
    border-bottom-right-radius:0.125rem;
    border-bottom-left-radius:0.125rem
  }

  .md\:rounded-b{
    border-bottom-right-radius:0.25rem;
    border-bottom-left-radius:0.25rem
  }

  .md\:rounded-b-md{
    border-bottom-right-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }

  .md\:rounded-b-lg{
    border-bottom-right-radius:0.5rem;
    border-bottom-left-radius:0.5rem
  }

  .md\:rounded-b-xl{
    border-bottom-right-radius:0.75rem;
    border-bottom-left-radius:0.75rem
  }

  .md\:rounded-b-2xl{
    border-bottom-right-radius:1rem;
    border-bottom-left-radius:1rem
  }

  .md\:rounded-b-3xl{
    border-bottom-right-radius:1.5rem;
    border-bottom-left-radius:1.5rem
  }

  .md\:rounded-b-full{
    border-bottom-right-radius:9999px;
    border-bottom-left-radius:9999px
  }

  .md\:rounded-b-default{
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px
  }

  .md\:rounded-l-1{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
  }

  .md\:rounded-l-2{
    border-top-left-radius:8px;
    border-bottom-left-radius:8px
  }

  .md\:rounded-l-3{
    border-top-left-radius:12px;
    border-bottom-left-radius:12px
  }

  .md\:rounded-l-4{
    border-top-left-radius:16px;
    border-bottom-left-radius:16px
  }

  .md\:rounded-l-none{
    border-top-left-radius:0px;
    border-bottom-left-radius:0px
  }

  .md\:rounded-l-sm{
    border-top-left-radius:0.125rem;
    border-bottom-left-radius:0.125rem
  }

  .md\:rounded-l{
    border-top-left-radius:0.25rem;
    border-bottom-left-radius:0.25rem
  }

  .md\:rounded-l-md{
    border-top-left-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }

  .md\:rounded-l-lg{
    border-top-left-radius:0.5rem;
    border-bottom-left-radius:0.5rem
  }

  .md\:rounded-l-xl{
    border-top-left-radius:0.75rem;
    border-bottom-left-radius:0.75rem
  }

  .md\:rounded-l-2xl{
    border-top-left-radius:1rem;
    border-bottom-left-radius:1rem
  }

  .md\:rounded-l-3xl{
    border-top-left-radius:1.5rem;
    border-bottom-left-radius:1.5rem
  }

  .md\:rounded-l-full{
    border-top-left-radius:9999px;
    border-bottom-left-radius:9999px
  }

  .md\:rounded-l-default{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
  }

  .md\:rounded-tl-1{
    border-top-left-radius:4px
  }

  .md\:rounded-tl-2{
    border-top-left-radius:8px
  }

  .md\:rounded-tl-3{
    border-top-left-radius:12px
  }

  .md\:rounded-tl-4{
    border-top-left-radius:16px
  }

  .md\:rounded-tl-none{
    border-top-left-radius:0px
  }

  .md\:rounded-tl-sm{
    border-top-left-radius:0.125rem
  }

  .md\:rounded-tl{
    border-top-left-radius:0.25rem
  }

  .md\:rounded-tl-md{
    border-top-left-radius:0.375rem
  }

  .md\:rounded-tl-lg{
    border-top-left-radius:0.5rem
  }

  .md\:rounded-tl-xl{
    border-top-left-radius:0.75rem
  }

  .md\:rounded-tl-2xl{
    border-top-left-radius:1rem
  }

  .md\:rounded-tl-3xl{
    border-top-left-radius:1.5rem
  }

  .md\:rounded-tl-full{
    border-top-left-radius:9999px
  }

  .md\:rounded-tl-default{
    border-top-left-radius:4px
  }

  .md\:rounded-tr-1{
    border-top-right-radius:4px
  }

  .md\:rounded-tr-2{
    border-top-right-radius:8px
  }

  .md\:rounded-tr-3{
    border-top-right-radius:12px
  }

  .md\:rounded-tr-4{
    border-top-right-radius:16px
  }

  .md\:rounded-tr-none{
    border-top-right-radius:0px
  }

  .md\:rounded-tr-sm{
    border-top-right-radius:0.125rem
  }

  .md\:rounded-tr{
    border-top-right-radius:0.25rem
  }

  .md\:rounded-tr-md{
    border-top-right-radius:0.375rem
  }

  .md\:rounded-tr-lg{
    border-top-right-radius:0.5rem
  }

  .md\:rounded-tr-xl{
    border-top-right-radius:0.75rem
  }

  .md\:rounded-tr-2xl{
    border-top-right-radius:1rem
  }

  .md\:rounded-tr-3xl{
    border-top-right-radius:1.5rem
  }

  .md\:rounded-tr-full{
    border-top-right-radius:9999px
  }

  .md\:rounded-tr-default{
    border-top-right-radius:4px
  }

  .md\:rounded-br-1{
    border-bottom-right-radius:4px
  }

  .md\:rounded-br-2{
    border-bottom-right-radius:8px
  }

  .md\:rounded-br-3{
    border-bottom-right-radius:12px
  }

  .md\:rounded-br-4{
    border-bottom-right-radius:16px
  }

  .md\:rounded-br-none{
    border-bottom-right-radius:0px
  }

  .md\:rounded-br-sm{
    border-bottom-right-radius:0.125rem
  }

  .md\:rounded-br{
    border-bottom-right-radius:0.25rem
  }

  .md\:rounded-br-md{
    border-bottom-right-radius:0.375rem
  }

  .md\:rounded-br-lg{
    border-bottom-right-radius:0.5rem
  }

  .md\:rounded-br-xl{
    border-bottom-right-radius:0.75rem
  }

  .md\:rounded-br-2xl{
    border-bottom-right-radius:1rem
  }

  .md\:rounded-br-3xl{
    border-bottom-right-radius:1.5rem
  }

  .md\:rounded-br-full{
    border-bottom-right-radius:9999px
  }

  .md\:rounded-br-default{
    border-bottom-right-radius:4px
  }

  .md\:rounded-bl-1{
    border-bottom-left-radius:4px
  }

  .md\:rounded-bl-2{
    border-bottom-left-radius:8px
  }

  .md\:rounded-bl-3{
    border-bottom-left-radius:12px
  }

  .md\:rounded-bl-4{
    border-bottom-left-radius:16px
  }

  .md\:rounded-bl-none{
    border-bottom-left-radius:0px
  }

  .md\:rounded-bl-sm{
    border-bottom-left-radius:0.125rem
  }

  .md\:rounded-bl{
    border-bottom-left-radius:0.25rem
  }

  .md\:rounded-bl-md{
    border-bottom-left-radius:0.375rem
  }

  .md\:rounded-bl-lg{
    border-bottom-left-radius:0.5rem
  }

  .md\:rounded-bl-xl{
    border-bottom-left-radius:0.75rem
  }

  .md\:rounded-bl-2xl{
    border-bottom-left-radius:1rem
  }

  .md\:rounded-bl-3xl{
    border-bottom-left-radius:1.5rem
  }

  .md\:rounded-bl-full{
    border-bottom-left-radius:9999px
  }

  .md\:rounded-bl-default{
    border-bottom-left-radius:4px
  }

  .md\:border-0{
    border-width:0px
  }

  .md\:border-2{
    border-width:2px
  }

  .md\:border-4{
    border-width:4px
  }

  .md\:border-8{
    border-width:8px
  }

  .md\:border{
    border-width:1px
  }

  .md\:border-t-0{
    border-top-width:0px
  }

  .md\:border-t-2{
    border-top-width:2px
  }

  .md\:border-t-4{
    border-top-width:4px
  }

  .md\:border-t-8{
    border-top-width:8px
  }

  .md\:border-t{
    border-top-width:1px
  }

  .md\:border-r-0{
    border-right-width:0px
  }

  .md\:border-r-2{
    border-right-width:2px
  }

  .md\:border-r-4{
    border-right-width:4px
  }

  .md\:border-r-8{
    border-right-width:8px
  }

  .md\:border-r{
    border-right-width:1px
  }

  .md\:border-b-0{
    border-bottom-width:0px
  }

  .md\:border-b-2{
    border-bottom-width:2px
  }

  .md\:border-b-4{
    border-bottom-width:4px
  }

  .md\:border-b-8{
    border-bottom-width:8px
  }

  .md\:border-b{
    border-bottom-width:1px
  }

  .md\:border-l-0{
    border-left-width:0px
  }

  .md\:border-l-2{
    border-left-width:2px
  }

  .md\:border-l-4{
    border-left-width:4px
  }

  .md\:border-l-8{
    border-left-width:8px
  }

  .md\:border-l{
    border-left-width:1px
  }

  .md\:border-solid{
    border-style:solid
  }

  .md\:border-dashed{
    border-style:dashed
  }

  .md\:border-dotted{
    border-style:dotted
  }

  .md\:border-double{
    border-style:double
  }

  .md\:border-none{
    border-style:none
  }

  .md\:border-red1{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .md\:border-red2{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .md\:border-red3{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .md\:border-red4{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .md\:border-red5{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .md\:border-cyan1{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .md\:border-cyan2{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .md\:border-cyan3{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .md\:border-cyan4{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .md\:border-cyan5{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .md\:border-bg1{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .md\:border-bg2{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:border-bg3{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .md\:border-gray1{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .md\:border-gray2{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .md\:border-gray3{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .md\:border-gray4{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .md\:border-gray5{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .md\:border-gray6{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .md\:border-gray7{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .md\:border-white{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:border-warning{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .md\:border-error{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .md\:border-info{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .md\:border-success{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .md\:border-special4{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .md\:border-dark{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .md\:border-transparent{
    border-color:transparent
  }

  .md\:border-secondary1{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .md\:border-secondary2{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .md\:border-secondary3{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .md\:border-secondary4{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .md\:border-secondary5{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .md\:border-green1{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .md\:hover\:border-red1:hover{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .md\:hover\:border-red2:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .md\:hover\:border-red3:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .md\:hover\:border-red4:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .md\:hover\:border-red5:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan1:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan2:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan3:hover{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan4:hover{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .md\:hover\:border-cyan5:hover{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .md\:hover\:border-bg1:hover{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .md\:hover\:border-bg2:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-bg3:hover{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray1:hover{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray2:hover{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray3:hover{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray4:hover{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray5:hover{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray6:hover{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .md\:hover\:border-gray7:hover{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .md\:hover\:border-white:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:hover\:border-warning:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .md\:hover\:border-error:hover{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .md\:hover\:border-info:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .md\:hover\:border-success:hover{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .md\:hover\:border-special4:hover{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .md\:hover\:border-dark:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .md\:hover\:border-transparent:hover{
    border-color:transparent
  }

  .md\:hover\:border-secondary1:hover{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .md\:hover\:border-secondary2:hover{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .md\:hover\:border-secondary3:hover{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .md\:hover\:border-secondary4:hover{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .md\:hover\:border-secondary5:hover{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .md\:hover\:border-green1:hover{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .md\:focus\:border-red1:focus{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .md\:focus\:border-red2:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .md\:focus\:border-red3:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .md\:focus\:border-red4:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .md\:focus\:border-red5:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan1:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan2:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan3:focus{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan4:focus{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .md\:focus\:border-cyan5:focus{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .md\:focus\:border-bg1:focus{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .md\:focus\:border-bg2:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-bg3:focus{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray1:focus{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray2:focus{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray3:focus{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray4:focus{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray5:focus{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray6:focus{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .md\:focus\:border-gray7:focus{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .md\:focus\:border-white:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:focus\:border-warning:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .md\:focus\:border-error:focus{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .md\:focus\:border-info:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .md\:focus\:border-success:focus{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .md\:focus\:border-special4:focus{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .md\:focus\:border-dark:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .md\:focus\:border-transparent:focus{
    border-color:transparent
  }

  .md\:focus\:border-secondary1:focus{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .md\:focus\:border-secondary2:focus{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .md\:focus\:border-secondary3:focus{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .md\:focus\:border-secondary4:focus{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .md\:focus\:border-secondary5:focus{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .md\:focus\:border-green1:focus{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .md\:active\:border-red1:active{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .md\:active\:border-red2:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .md\:active\:border-red3:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .md\:active\:border-red4:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .md\:active\:border-red5:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .md\:active\:border-cyan1:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .md\:active\:border-cyan2:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .md\:active\:border-cyan3:active{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .md\:active\:border-cyan4:active{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .md\:active\:border-cyan5:active{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .md\:active\:border-bg1:active{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .md\:active\:border-bg2:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:active\:border-bg3:active{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .md\:active\:border-gray1:active{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .md\:active\:border-gray2:active{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .md\:active\:border-gray3:active{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .md\:active\:border-gray4:active{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .md\:active\:border-gray5:active{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .md\:active\:border-gray6:active{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .md\:active\:border-gray7:active{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .md\:active\:border-white:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .md\:active\:border-warning:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .md\:active\:border-error:active{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .md\:active\:border-info:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .md\:active\:border-success:active{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .md\:active\:border-special4:active{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .md\:active\:border-dark:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .md\:active\:border-transparent:active{
    border-color:transparent
  }

  .md\:active\:border-secondary1:active{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .md\:active\:border-secondary2:active{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .md\:active\:border-secondary3:active{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .md\:active\:border-secondary4:active{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .md\:active\:border-secondary5:active{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .md\:active\:border-green1:active{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .md\:border-opacity-0{
    --tw-border-opacity:0
  }

  .md\:border-opacity-5{
    --tw-border-opacity:0.05
  }

  .md\:border-opacity-10{
    --tw-border-opacity:0.1
  }

  .md\:border-opacity-20{
    --tw-border-opacity:0.2
  }

  .md\:border-opacity-25{
    --tw-border-opacity:0.25
  }

  .md\:border-opacity-30{
    --tw-border-opacity:0.3
  }

  .md\:border-opacity-40{
    --tw-border-opacity:0.4
  }

  .md\:border-opacity-50{
    --tw-border-opacity:0.5
  }

  .md\:border-opacity-60{
    --tw-border-opacity:0.6
  }

  .md\:border-opacity-70{
    --tw-border-opacity:0.7
  }

  .md\:border-opacity-75{
    --tw-border-opacity:0.75
  }

  .md\:border-opacity-80{
    --tw-border-opacity:0.8
  }

  .md\:border-opacity-90{
    --tw-border-opacity:0.9
  }

  .md\:border-opacity-95{
    --tw-border-opacity:0.95
  }

  .md\:border-opacity-100{
    --tw-border-opacity:1
  }

  .group:hover .md\:group-hover\:border-opacity-0{
    --tw-border-opacity:0
  }

  .group:hover .md\:group-hover\:border-opacity-5{
    --tw-border-opacity:0.05
  }

  .group:hover .md\:group-hover\:border-opacity-10{
    --tw-border-opacity:0.1
  }

  .group:hover .md\:group-hover\:border-opacity-20{
    --tw-border-opacity:0.2
  }

  .group:hover .md\:group-hover\:border-opacity-25{
    --tw-border-opacity:0.25
  }

  .group:hover .md\:group-hover\:border-opacity-30{
    --tw-border-opacity:0.3
  }

  .group:hover .md\:group-hover\:border-opacity-40{
    --tw-border-opacity:0.4
  }

  .group:hover .md\:group-hover\:border-opacity-50{
    --tw-border-opacity:0.5
  }

  .group:hover .md\:group-hover\:border-opacity-60{
    --tw-border-opacity:0.6
  }

  .group:hover .md\:group-hover\:border-opacity-70{
    --tw-border-opacity:0.7
  }

  .group:hover .md\:group-hover\:border-opacity-75{
    --tw-border-opacity:0.75
  }

  .group:hover .md\:group-hover\:border-opacity-80{
    --tw-border-opacity:0.8
  }

  .group:hover .md\:group-hover\:border-opacity-90{
    --tw-border-opacity:0.9
  }

  .group:hover .md\:group-hover\:border-opacity-95{
    --tw-border-opacity:0.95
  }

  .group:hover .md\:group-hover\:border-opacity-100{
    --tw-border-opacity:1
  }

  .md\:focus-within\:border-opacity-0:focus-within{
    --tw-border-opacity:0
  }

  .md\:focus-within\:border-opacity-5:focus-within{
    --tw-border-opacity:0.05
  }

  .md\:focus-within\:border-opacity-10:focus-within{
    --tw-border-opacity:0.1
  }

  .md\:focus-within\:border-opacity-20:focus-within{
    --tw-border-opacity:0.2
  }

  .md\:focus-within\:border-opacity-25:focus-within{
    --tw-border-opacity:0.25
  }

  .md\:focus-within\:border-opacity-30:focus-within{
    --tw-border-opacity:0.3
  }

  .md\:focus-within\:border-opacity-40:focus-within{
    --tw-border-opacity:0.4
  }

  .md\:focus-within\:border-opacity-50:focus-within{
    --tw-border-opacity:0.5
  }

  .md\:focus-within\:border-opacity-60:focus-within{
    --tw-border-opacity:0.6
  }

  .md\:focus-within\:border-opacity-70:focus-within{
    --tw-border-opacity:0.7
  }

  .md\:focus-within\:border-opacity-75:focus-within{
    --tw-border-opacity:0.75
  }

  .md\:focus-within\:border-opacity-80:focus-within{
    --tw-border-opacity:0.8
  }

  .md\:focus-within\:border-opacity-90:focus-within{
    --tw-border-opacity:0.9
  }

  .md\:focus-within\:border-opacity-95:focus-within{
    --tw-border-opacity:0.95
  }

  .md\:focus-within\:border-opacity-100:focus-within{
    --tw-border-opacity:1
  }

  .md\:hover\:border-opacity-0:hover{
    --tw-border-opacity:0
  }

  .md\:hover\:border-opacity-5:hover{
    --tw-border-opacity:0.05
  }

  .md\:hover\:border-opacity-10:hover{
    --tw-border-opacity:0.1
  }

  .md\:hover\:border-opacity-20:hover{
    --tw-border-opacity:0.2
  }

  .md\:hover\:border-opacity-25:hover{
    --tw-border-opacity:0.25
  }

  .md\:hover\:border-opacity-30:hover{
    --tw-border-opacity:0.3
  }

  .md\:hover\:border-opacity-40:hover{
    --tw-border-opacity:0.4
  }

  .md\:hover\:border-opacity-50:hover{
    --tw-border-opacity:0.5
  }

  .md\:hover\:border-opacity-60:hover{
    --tw-border-opacity:0.6
  }

  .md\:hover\:border-opacity-70:hover{
    --tw-border-opacity:0.7
  }

  .md\:hover\:border-opacity-75:hover{
    --tw-border-opacity:0.75
  }

  .md\:hover\:border-opacity-80:hover{
    --tw-border-opacity:0.8
  }

  .md\:hover\:border-opacity-90:hover{
    --tw-border-opacity:0.9
  }

  .md\:hover\:border-opacity-95:hover{
    --tw-border-opacity:0.95
  }

  .md\:hover\:border-opacity-100:hover{
    --tw-border-opacity:1
  }

  .md\:focus\:border-opacity-0:focus{
    --tw-border-opacity:0
  }

  .md\:focus\:border-opacity-5:focus{
    --tw-border-opacity:0.05
  }

  .md\:focus\:border-opacity-10:focus{
    --tw-border-opacity:0.1
  }

  .md\:focus\:border-opacity-20:focus{
    --tw-border-opacity:0.2
  }

  .md\:focus\:border-opacity-25:focus{
    --tw-border-opacity:0.25
  }

  .md\:focus\:border-opacity-30:focus{
    --tw-border-opacity:0.3
  }

  .md\:focus\:border-opacity-40:focus{
    --tw-border-opacity:0.4
  }

  .md\:focus\:border-opacity-50:focus{
    --tw-border-opacity:0.5
  }

  .md\:focus\:border-opacity-60:focus{
    --tw-border-opacity:0.6
  }

  .md\:focus\:border-opacity-70:focus{
    --tw-border-opacity:0.7
  }

  .md\:focus\:border-opacity-75:focus{
    --tw-border-opacity:0.75
  }

  .md\:focus\:border-opacity-80:focus{
    --tw-border-opacity:0.8
  }

  .md\:focus\:border-opacity-90:focus{
    --tw-border-opacity:0.9
  }

  .md\:focus\:border-opacity-95:focus{
    --tw-border-opacity:0.95
  }

  .md\:focus\:border-opacity-100:focus{
    --tw-border-opacity:1
  }

  .md\:bg-red1{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .md\:bg-red2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .md\:bg-red3{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .md\:bg-red4{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .md\:bg-red5{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .md\:bg-cyan1{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .md\:bg-cyan2{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .md\:bg-cyan3{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .md\:bg-cyan4{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .md\:bg-cyan5{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .md\:bg-bg1{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .md\:bg-bg2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:bg-bg3{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .md\:bg-gray1{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .md\:bg-gray2{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .md\:bg-gray3{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .md\:bg-gray4{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .md\:bg-gray5{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .md\:bg-gray6{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .md\:bg-gray7{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .md\:bg-white{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:bg-warning{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .md\:bg-error{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .md\:bg-info{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .md\:bg-success{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .md\:bg-special4{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .md\:bg-dark{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .md\:bg-transparent{
    background-color:transparent
  }

  .md\:bg-secondary1{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .md\:bg-secondary2{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .md\:bg-secondary3{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .md\:bg-secondary4{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .md\:bg-secondary5{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .md\:bg-green1{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-red5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-cyan5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-bg1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-bg2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-bg3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray6:hover{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-gray7:hover{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-white:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-warning:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-error:hover{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-info:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-success:hover{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-special4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-dark:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-transparent:hover{
    background-color:transparent
  }

  .md\:hover\:bg-secondary1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-secondary2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-secondary3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-secondary4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-secondary5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .md\:hover\:bg-green1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-red5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-cyan5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-bg1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-bg2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-bg3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray6:focus{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-gray7:focus{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-white:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-warning:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-error:focus{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-info:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-success:focus{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-special4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-dark:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-transparent:focus{
    background-color:transparent
  }

  .md\:focus\:bg-secondary1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-secondary2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-secondary3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-secondary4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-secondary5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .md\:focus\:bg-green1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .md\:active\:bg-red1:active{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .md\:active\:bg-red2:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .md\:active\:bg-red3:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .md\:active\:bg-red4:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .md\:active\:bg-red5:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .md\:active\:bg-cyan1:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .md\:active\:bg-cyan2:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .md\:active\:bg-cyan3:active{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .md\:active\:bg-cyan4:active{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .md\:active\:bg-cyan5:active{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .md\:active\:bg-bg1:active{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .md\:active\:bg-bg2:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:active\:bg-bg3:active{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .md\:active\:bg-gray1:active{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .md\:active\:bg-gray2:active{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .md\:active\:bg-gray3:active{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .md\:active\:bg-gray4:active{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .md\:active\:bg-gray5:active{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .md\:active\:bg-gray6:active{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .md\:active\:bg-gray7:active{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .md\:active\:bg-white:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .md\:active\:bg-warning:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .md\:active\:bg-error:active{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .md\:active\:bg-info:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .md\:active\:bg-success:active{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .md\:active\:bg-special4:active{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .md\:active\:bg-dark:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .md\:active\:bg-transparent:active{
    background-color:transparent
  }

  .md\:active\:bg-secondary1:active{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .md\:active\:bg-secondary2:active{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .md\:active\:bg-secondary3:active{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .md\:active\:bg-secondary4:active{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .md\:active\:bg-secondary5:active{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .md\:active\:bg-green1:active{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red1{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red3{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red4{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-red5{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan1{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan2{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan3{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan4{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-cyan5{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-bg1{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-bg2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-bg3{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray1{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray2{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray3{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray4{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray5{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray6{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-gray7{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-white{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-warning{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-error{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-info{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-success{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-special4{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-dark{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-transparent{
    background-color:transparent
  }

  .group:hover .md\:group-hover\:bg-secondary1{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-secondary2{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-secondary3{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-secondary4{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-secondary5{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .group:hover .md\:group-hover\:bg-green1{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .md\:bg-opacity-0{
    --tw-bg-opacity:0
  }

  .md\:bg-opacity-5{
    --tw-bg-opacity:0.05
  }

  .md\:bg-opacity-10{
    --tw-bg-opacity:0.1
  }

  .md\:bg-opacity-20{
    --tw-bg-opacity:0.2
  }

  .md\:bg-opacity-25{
    --tw-bg-opacity:0.25
  }

  .md\:bg-opacity-30{
    --tw-bg-opacity:0.3
  }

  .md\:bg-opacity-40{
    --tw-bg-opacity:0.4
  }

  .md\:bg-opacity-50{
    --tw-bg-opacity:0.5
  }

  .md\:bg-opacity-60{
    --tw-bg-opacity:0.6
  }

  .md\:bg-opacity-70{
    --tw-bg-opacity:0.7
  }

  .md\:bg-opacity-75{
    --tw-bg-opacity:0.75
  }

  .md\:bg-opacity-80{
    --tw-bg-opacity:0.8
  }

  .md\:bg-opacity-90{
    --tw-bg-opacity:0.9
  }

  .md\:bg-opacity-95{
    --tw-bg-opacity:0.95
  }

  .md\:bg-opacity-100{
    --tw-bg-opacity:1
  }

  .group:hover .md\:group-hover\:bg-opacity-0{
    --tw-bg-opacity:0
  }

  .group:hover .md\:group-hover\:bg-opacity-5{
    --tw-bg-opacity:0.05
  }

  .group:hover .md\:group-hover\:bg-opacity-10{
    --tw-bg-opacity:0.1
  }

  .group:hover .md\:group-hover\:bg-opacity-20{
    --tw-bg-opacity:0.2
  }

  .group:hover .md\:group-hover\:bg-opacity-25{
    --tw-bg-opacity:0.25
  }

  .group:hover .md\:group-hover\:bg-opacity-30{
    --tw-bg-opacity:0.3
  }

  .group:hover .md\:group-hover\:bg-opacity-40{
    --tw-bg-opacity:0.4
  }

  .group:hover .md\:group-hover\:bg-opacity-50{
    --tw-bg-opacity:0.5
  }

  .group:hover .md\:group-hover\:bg-opacity-60{
    --tw-bg-opacity:0.6
  }

  .group:hover .md\:group-hover\:bg-opacity-70{
    --tw-bg-opacity:0.7
  }

  .group:hover .md\:group-hover\:bg-opacity-75{
    --tw-bg-opacity:0.75
  }

  .group:hover .md\:group-hover\:bg-opacity-80{
    --tw-bg-opacity:0.8
  }

  .group:hover .md\:group-hover\:bg-opacity-90{
    --tw-bg-opacity:0.9
  }

  .group:hover .md\:group-hover\:bg-opacity-95{
    --tw-bg-opacity:0.95
  }

  .group:hover .md\:group-hover\:bg-opacity-100{
    --tw-bg-opacity:1
  }

  .md\:focus-within\:bg-opacity-0:focus-within{
    --tw-bg-opacity:0
  }

  .md\:focus-within\:bg-opacity-5:focus-within{
    --tw-bg-opacity:0.05
  }

  .md\:focus-within\:bg-opacity-10:focus-within{
    --tw-bg-opacity:0.1
  }

  .md\:focus-within\:bg-opacity-20:focus-within{
    --tw-bg-opacity:0.2
  }

  .md\:focus-within\:bg-opacity-25:focus-within{
    --tw-bg-opacity:0.25
  }

  .md\:focus-within\:bg-opacity-30:focus-within{
    --tw-bg-opacity:0.3
  }

  .md\:focus-within\:bg-opacity-40:focus-within{
    --tw-bg-opacity:0.4
  }

  .md\:focus-within\:bg-opacity-50:focus-within{
    --tw-bg-opacity:0.5
  }

  .md\:focus-within\:bg-opacity-60:focus-within{
    --tw-bg-opacity:0.6
  }

  .md\:focus-within\:bg-opacity-70:focus-within{
    --tw-bg-opacity:0.7
  }

  .md\:focus-within\:bg-opacity-75:focus-within{
    --tw-bg-opacity:0.75
  }

  .md\:focus-within\:bg-opacity-80:focus-within{
    --tw-bg-opacity:0.8
  }

  .md\:focus-within\:bg-opacity-90:focus-within{
    --tw-bg-opacity:0.9
  }

  .md\:focus-within\:bg-opacity-95:focus-within{
    --tw-bg-opacity:0.95
  }

  .md\:focus-within\:bg-opacity-100:focus-within{
    --tw-bg-opacity:1
  }

  .md\:hover\:bg-opacity-0:hover{
    --tw-bg-opacity:0
  }

  .md\:hover\:bg-opacity-5:hover{
    --tw-bg-opacity:0.05
  }

  .md\:hover\:bg-opacity-10:hover{
    --tw-bg-opacity:0.1
  }

  .md\:hover\:bg-opacity-20:hover{
    --tw-bg-opacity:0.2
  }

  .md\:hover\:bg-opacity-25:hover{
    --tw-bg-opacity:0.25
  }

  .md\:hover\:bg-opacity-30:hover{
    --tw-bg-opacity:0.3
  }

  .md\:hover\:bg-opacity-40:hover{
    --tw-bg-opacity:0.4
  }

  .md\:hover\:bg-opacity-50:hover{
    --tw-bg-opacity:0.5
  }

  .md\:hover\:bg-opacity-60:hover{
    --tw-bg-opacity:0.6
  }

  .md\:hover\:bg-opacity-70:hover{
    --tw-bg-opacity:0.7
  }

  .md\:hover\:bg-opacity-75:hover{
    --tw-bg-opacity:0.75
  }

  .md\:hover\:bg-opacity-80:hover{
    --tw-bg-opacity:0.8
  }

  .md\:hover\:bg-opacity-90:hover{
    --tw-bg-opacity:0.9
  }

  .md\:hover\:bg-opacity-95:hover{
    --tw-bg-opacity:0.95
  }

  .md\:hover\:bg-opacity-100:hover{
    --tw-bg-opacity:1
  }

  .md\:focus\:bg-opacity-0:focus{
    --tw-bg-opacity:0
  }

  .md\:focus\:bg-opacity-5:focus{
    --tw-bg-opacity:0.05
  }

  .md\:focus\:bg-opacity-10:focus{
    --tw-bg-opacity:0.1
  }

  .md\:focus\:bg-opacity-20:focus{
    --tw-bg-opacity:0.2
  }

  .md\:focus\:bg-opacity-25:focus{
    --tw-bg-opacity:0.25
  }

  .md\:focus\:bg-opacity-30:focus{
    --tw-bg-opacity:0.3
  }

  .md\:focus\:bg-opacity-40:focus{
    --tw-bg-opacity:0.4
  }

  .md\:focus\:bg-opacity-50:focus{
    --tw-bg-opacity:0.5
  }

  .md\:focus\:bg-opacity-60:focus{
    --tw-bg-opacity:0.6
  }

  .md\:focus\:bg-opacity-70:focus{
    --tw-bg-opacity:0.7
  }

  .md\:focus\:bg-opacity-75:focus{
    --tw-bg-opacity:0.75
  }

  .md\:focus\:bg-opacity-80:focus{
    --tw-bg-opacity:0.8
  }

  .md\:focus\:bg-opacity-90:focus{
    --tw-bg-opacity:0.9
  }

  .md\:focus\:bg-opacity-95:focus{
    --tw-bg-opacity:0.95
  }

  .md\:focus\:bg-opacity-100:focus{
    --tw-bg-opacity:1
  }

  .md\:bg-none{
    background-image:none
  }

  .md\:bg-gradient-to-t{
    background-image:linear-gradient(to top, var(--tw-gradient-stops))
  }

  .md\:bg-gradient-to-tr{
    background-image:linear-gradient(to top right, var(--tw-gradient-stops))
  }

  .md\:bg-gradient-to-r{
    background-image:linear-gradient(to right, var(--tw-gradient-stops))
  }

  .md\:bg-gradient-to-br{
    background-image:linear-gradient(to bottom right, var(--tw-gradient-stops))
  }

  .md\:bg-gradient-to-b{
    background-image:linear-gradient(to bottom, var(--tw-gradient-stops))
  }

  .md\:bg-gradient-to-bl{
    background-image:linear-gradient(to bottom left, var(--tw-gradient-stops))
  }

  .md\:bg-gradient-to-l{
    background-image:linear-gradient(to left, var(--tw-gradient-stops))
  }

  .md\:bg-gradient-to-tl{
    background-image:linear-gradient(to top left, var(--tw-gradient-stops))
  }

  .md\:from-red1{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .md\:from-red2{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .md\:from-red3{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .md\:from-red4{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .md\:from-red5{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .md\:from-cyan1{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .md\:from-cyan2{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .md\:from-cyan3{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .md\:from-cyan4{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .md\:from-cyan5{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .md\:from-bg1{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .md\:from-bg2{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:from-bg3{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .md\:from-gray1{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .md\:from-gray2{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .md\:from-gray3{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .md\:from-gray4{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .md\:from-gray5{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .md\:from-gray6{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .md\:from-gray7{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:from-white{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:from-warning{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .md\:from-error{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .md\:from-info{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .md\:from-success{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .md\:from-special4{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .md\:from-dark{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:from-transparent{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:from-secondary1{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .md\:from-secondary2{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:from-secondary3{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .md\:from-secondary4{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .md\:from-secondary5{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .md\:from-green1{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .md\:hover\:from-red1:hover{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .md\:hover\:from-red2:hover{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .md\:hover\:from-red3:hover{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .md\:hover\:from-red4:hover{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .md\:hover\:from-red5:hover{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .md\:hover\:from-cyan1:hover{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .md\:hover\:from-cyan2:hover{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .md\:hover\:from-cyan3:hover{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .md\:hover\:from-cyan4:hover{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .md\:hover\:from-cyan5:hover{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .md\:hover\:from-bg1:hover{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .md\:hover\:from-bg2:hover{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-bg3:hover{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .md\:hover\:from-gray1:hover{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .md\:hover\:from-gray2:hover{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .md\:hover\:from-gray3:hover{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .md\:hover\:from-gray4:hover{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .md\:hover\:from-gray5:hover{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .md\:hover\:from-gray6:hover{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .md\:hover\:from-gray7:hover{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:hover\:from-white:hover{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-warning:hover{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .md\:hover\:from-error:hover{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .md\:hover\:from-info:hover{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .md\:hover\:from-success:hover{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .md\:hover\:from-special4:hover{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .md\:hover\:from-dark:hover{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:hover\:from-transparent:hover{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:hover\:from-secondary1:hover{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .md\:hover\:from-secondary2:hover{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:hover\:from-secondary3:hover{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .md\:hover\:from-secondary4:hover{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .md\:hover\:from-secondary5:hover{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .md\:hover\:from-green1:hover{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .md\:focus\:from-red1:focus{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .md\:focus\:from-red2:focus{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .md\:focus\:from-red3:focus{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .md\:focus\:from-red4:focus{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .md\:focus\:from-red5:focus{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .md\:focus\:from-cyan1:focus{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .md\:focus\:from-cyan2:focus{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .md\:focus\:from-cyan3:focus{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .md\:focus\:from-cyan4:focus{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .md\:focus\:from-cyan5:focus{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .md\:focus\:from-bg1:focus{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .md\:focus\:from-bg2:focus{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-bg3:focus{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .md\:focus\:from-gray1:focus{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .md\:focus\:from-gray2:focus{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .md\:focus\:from-gray3:focus{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .md\:focus\:from-gray4:focus{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .md\:focus\:from-gray5:focus{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .md\:focus\:from-gray6:focus{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .md\:focus\:from-gray7:focus{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:focus\:from-white:focus{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-warning:focus{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .md\:focus\:from-error:focus{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .md\:focus\:from-info:focus{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .md\:focus\:from-success:focus{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .md\:focus\:from-special4:focus{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .md\:focus\:from-dark:focus{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:focus\:from-transparent:focus{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:focus\:from-secondary1:focus{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .md\:focus\:from-secondary2:focus{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:focus\:from-secondary3:focus{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .md\:focus\:from-secondary4:focus{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .md\:focus\:from-secondary5:focus{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .md\:focus\:from-green1:focus{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .md\:via-red1{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .md\:via-red2{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .md\:via-red3{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .md\:via-red4{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .md\:via-red5{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .md\:via-cyan1{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .md\:via-cyan2{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .md\:via-cyan3{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .md\:via-cyan4{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .md\:via-cyan5{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .md\:via-bg1{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .md\:via-bg2{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:via-bg3{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .md\:via-gray1{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .md\:via-gray2{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .md\:via-gray3{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .md\:via-gray4{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .md\:via-gray5{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .md\:via-gray6{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .md\:via-gray7{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:via-white{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:via-warning{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .md\:via-error{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .md\:via-info{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .md\:via-success{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .md\:via-special4{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .md\:via-dark{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:via-transparent{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:via-secondary1{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .md\:via-secondary2{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:via-secondary3{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .md\:via-secondary4{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .md\:via-secondary5{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .md\:via-green1{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .md\:hover\:via-red1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .md\:hover\:via-red2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .md\:hover\:via-red3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .md\:hover\:via-red4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .md\:hover\:via-red5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .md\:hover\:via-cyan1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .md\:hover\:via-cyan2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .md\:hover\:via-cyan3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .md\:hover\:via-cyan4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .md\:hover\:via-cyan5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .md\:hover\:via-bg1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .md\:hover\:via-bg2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-bg3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .md\:hover\:via-gray1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .md\:hover\:via-gray2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .md\:hover\:via-gray3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .md\:hover\:via-gray4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .md\:hover\:via-gray5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .md\:hover\:via-gray6:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .md\:hover\:via-gray7:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:hover\:via-white:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-warning:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .md\:hover\:via-error:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .md\:hover\:via-info:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .md\:hover\:via-success:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .md\:hover\:via-special4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .md\:hover\:via-dark:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:hover\:via-transparent:hover{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:hover\:via-secondary1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .md\:hover\:via-secondary2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:hover\:via-secondary3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .md\:hover\:via-secondary4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .md\:hover\:via-secondary5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .md\:hover\:via-green1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .md\:focus\:via-red1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .md\:focus\:via-red2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .md\:focus\:via-red3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .md\:focus\:via-red4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .md\:focus\:via-red5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .md\:focus\:via-cyan1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .md\:focus\:via-cyan2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .md\:focus\:via-cyan3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .md\:focus\:via-cyan4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .md\:focus\:via-cyan5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .md\:focus\:via-bg1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .md\:focus\:via-bg2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-bg3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .md\:focus\:via-gray1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .md\:focus\:via-gray2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .md\:focus\:via-gray3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .md\:focus\:via-gray4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .md\:focus\:via-gray5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .md\:focus\:via-gray6:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .md\:focus\:via-gray7:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:focus\:via-white:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-warning:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .md\:focus\:via-error:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .md\:focus\:via-info:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .md\:focus\:via-success:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .md\:focus\:via-special4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .md\:focus\:via-dark:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:focus\:via-transparent:focus{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .md\:focus\:via-secondary1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .md\:focus\:via-secondary2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .md\:focus\:via-secondary3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .md\:focus\:via-secondary4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .md\:focus\:via-secondary5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .md\:focus\:via-green1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .md\:to-red1{
    --tw-gradient-to:#CC352C
  }

  .md\:to-red2{
    --tw-gradient-to:#FF554B
  }

  .md\:to-red3{
    --tw-gradient-to:#FF7B73
  }

  .md\:to-red4{
    --tw-gradient-to:#FFF1F1
  }

  .md\:to-red5{
    --tw-gradient-to:#FFF6F6
  }

  .md\:to-cyan1{
    --tw-gradient-to:#009897
  }

  .md\:to-cyan2{
    --tw-gradient-to:#00BFBD
  }

  .md\:to-cyan3{
    --tw-gradient-to:#4CD1D0
  }

  .md\:to-cyan4{
    --tw-gradient-to:#B2ECEB
  }

  .md\:to-cyan5{
    --tw-gradient-to:#EDFAFA
  }

  .md\:to-bg1{
    --tw-gradient-to:#FAFAFC
  }

  .md\:to-bg2{
    --tw-gradient-to:#ffffff
  }

  .md\:to-bg3{
    --tw-gradient-to:#F7F7FA
  }

  .md\:to-gray1{
    --tw-gradient-to:#333333
  }

  .md\:to-gray2{
    --tw-gradient-to:#4D4D4D
  }

  .md\:to-gray3{
    --tw-gradient-to:#8C8C8C
  }

  .md\:to-gray4{
    --tw-gradient-to:#BFBFBF
  }

  .md\:to-gray5{
    --tw-gradient-to:#D9D9D9
  }

  .md\:to-gray6{
    --tw-gradient-to:#E9E9EB
  }

  .md\:to-gray7{
    --tw-gradient-to:#515164
  }

  .md\:to-white{
    --tw-gradient-to:#ffffff
  }

  .md\:to-warning{
    --tw-gradient-to:#FFC319
  }

  .md\:to-error{
    --tw-gradient-to:#EA696F
  }

  .md\:to-info{
    --tw-gradient-to:#0071B9
  }

  .md\:to-success{
    --tw-gradient-to:#98D45F
  }

  .md\:to-special4{
    --tw-gradient-to:#59779A
  }

  .md\:to-dark{
    --tw-gradient-to:#000000
  }

  .md\:to-transparent{
    --tw-gradient-to:transparent
  }

  .md\:to-secondary1{
    --tw-gradient-to:#313140
  }

  .md\:to-secondary2{
    --tw-gradient-to:#515164
  }

  .md\:to-secondary3{
    --tw-gradient-to:#9C9CAC
  }

  .md\:to-secondary4{
    --tw-gradient-to:#E5E8ED
  }

  .md\:to-secondary5{
    --tw-gradient-to:#F5F6F9
  }

  .md\:to-green1{
    --tw-gradient-to:#0DE298
  }

  .md\:hover\:to-red1:hover{
    --tw-gradient-to:#CC352C
  }

  .md\:hover\:to-red2:hover{
    --tw-gradient-to:#FF554B
  }

  .md\:hover\:to-red3:hover{
    --tw-gradient-to:#FF7B73
  }

  .md\:hover\:to-red4:hover{
    --tw-gradient-to:#FFF1F1
  }

  .md\:hover\:to-red5:hover{
    --tw-gradient-to:#FFF6F6
  }

  .md\:hover\:to-cyan1:hover{
    --tw-gradient-to:#009897
  }

  .md\:hover\:to-cyan2:hover{
    --tw-gradient-to:#00BFBD
  }

  .md\:hover\:to-cyan3:hover{
    --tw-gradient-to:#4CD1D0
  }

  .md\:hover\:to-cyan4:hover{
    --tw-gradient-to:#B2ECEB
  }

  .md\:hover\:to-cyan5:hover{
    --tw-gradient-to:#EDFAFA
  }

  .md\:hover\:to-bg1:hover{
    --tw-gradient-to:#FAFAFC
  }

  .md\:hover\:to-bg2:hover{
    --tw-gradient-to:#ffffff
  }

  .md\:hover\:to-bg3:hover{
    --tw-gradient-to:#F7F7FA
  }

  .md\:hover\:to-gray1:hover{
    --tw-gradient-to:#333333
  }

  .md\:hover\:to-gray2:hover{
    --tw-gradient-to:#4D4D4D
  }

  .md\:hover\:to-gray3:hover{
    --tw-gradient-to:#8C8C8C
  }

  .md\:hover\:to-gray4:hover{
    --tw-gradient-to:#BFBFBF
  }

  .md\:hover\:to-gray5:hover{
    --tw-gradient-to:#D9D9D9
  }

  .md\:hover\:to-gray6:hover{
    --tw-gradient-to:#E9E9EB
  }

  .md\:hover\:to-gray7:hover{
    --tw-gradient-to:#515164
  }

  .md\:hover\:to-white:hover{
    --tw-gradient-to:#ffffff
  }

  .md\:hover\:to-warning:hover{
    --tw-gradient-to:#FFC319
  }

  .md\:hover\:to-error:hover{
    --tw-gradient-to:#EA696F
  }

  .md\:hover\:to-info:hover{
    --tw-gradient-to:#0071B9
  }

  .md\:hover\:to-success:hover{
    --tw-gradient-to:#98D45F
  }

  .md\:hover\:to-special4:hover{
    --tw-gradient-to:#59779A
  }

  .md\:hover\:to-dark:hover{
    --tw-gradient-to:#000000
  }

  .md\:hover\:to-transparent:hover{
    --tw-gradient-to:transparent
  }

  .md\:hover\:to-secondary1:hover{
    --tw-gradient-to:#313140
  }

  .md\:hover\:to-secondary2:hover{
    --tw-gradient-to:#515164
  }

  .md\:hover\:to-secondary3:hover{
    --tw-gradient-to:#9C9CAC
  }

  .md\:hover\:to-secondary4:hover{
    --tw-gradient-to:#E5E8ED
  }

  .md\:hover\:to-secondary5:hover{
    --tw-gradient-to:#F5F6F9
  }

  .md\:hover\:to-green1:hover{
    --tw-gradient-to:#0DE298
  }

  .md\:focus\:to-red1:focus{
    --tw-gradient-to:#CC352C
  }

  .md\:focus\:to-red2:focus{
    --tw-gradient-to:#FF554B
  }

  .md\:focus\:to-red3:focus{
    --tw-gradient-to:#FF7B73
  }

  .md\:focus\:to-red4:focus{
    --tw-gradient-to:#FFF1F1
  }

  .md\:focus\:to-red5:focus{
    --tw-gradient-to:#FFF6F6
  }

  .md\:focus\:to-cyan1:focus{
    --tw-gradient-to:#009897
  }

  .md\:focus\:to-cyan2:focus{
    --tw-gradient-to:#00BFBD
  }

  .md\:focus\:to-cyan3:focus{
    --tw-gradient-to:#4CD1D0
  }

  .md\:focus\:to-cyan4:focus{
    --tw-gradient-to:#B2ECEB
  }

  .md\:focus\:to-cyan5:focus{
    --tw-gradient-to:#EDFAFA
  }

  .md\:focus\:to-bg1:focus{
    --tw-gradient-to:#FAFAFC
  }

  .md\:focus\:to-bg2:focus{
    --tw-gradient-to:#ffffff
  }

  .md\:focus\:to-bg3:focus{
    --tw-gradient-to:#F7F7FA
  }

  .md\:focus\:to-gray1:focus{
    --tw-gradient-to:#333333
  }

  .md\:focus\:to-gray2:focus{
    --tw-gradient-to:#4D4D4D
  }

  .md\:focus\:to-gray3:focus{
    --tw-gradient-to:#8C8C8C
  }

  .md\:focus\:to-gray4:focus{
    --tw-gradient-to:#BFBFBF
  }

  .md\:focus\:to-gray5:focus{
    --tw-gradient-to:#D9D9D9
  }

  .md\:focus\:to-gray6:focus{
    --tw-gradient-to:#E9E9EB
  }

  .md\:focus\:to-gray7:focus{
    --tw-gradient-to:#515164
  }

  .md\:focus\:to-white:focus{
    --tw-gradient-to:#ffffff
  }

  .md\:focus\:to-warning:focus{
    --tw-gradient-to:#FFC319
  }

  .md\:focus\:to-error:focus{
    --tw-gradient-to:#EA696F
  }

  .md\:focus\:to-info:focus{
    --tw-gradient-to:#0071B9
  }

  .md\:focus\:to-success:focus{
    --tw-gradient-to:#98D45F
  }

  .md\:focus\:to-special4:focus{
    --tw-gradient-to:#59779A
  }

  .md\:focus\:to-dark:focus{
    --tw-gradient-to:#000000
  }

  .md\:focus\:to-transparent:focus{
    --tw-gradient-to:transparent
  }

  .md\:focus\:to-secondary1:focus{
    --tw-gradient-to:#313140
  }

  .md\:focus\:to-secondary2:focus{
    --tw-gradient-to:#515164
  }

  .md\:focus\:to-secondary3:focus{
    --tw-gradient-to:#9C9CAC
  }

  .md\:focus\:to-secondary4:focus{
    --tw-gradient-to:#E5E8ED
  }

  .md\:focus\:to-secondary5:focus{
    --tw-gradient-to:#F5F6F9
  }

  .md\:focus\:to-green1:focus{
    --tw-gradient-to:#0DE298
  }

  .md\:decoration-slice{
    -webkit-box-decoration-break:slice;
            box-decoration-break:slice
  }

  .md\:decoration-clone{
    -webkit-box-decoration-break:clone;
            box-decoration-break:clone
  }

  .md\:bg-auto{
    background-size:auto
  }

  .md\:bg-cover{
    background-size:cover
  }

  .md\:bg-contain{
    background-size:contain
  }

  .md\:bg-clip-border{
    background-clip:border-box
  }

  .md\:bg-clip-padding{
    background-clip:padding-box
  }

  .md\:bg-clip-content{
    background-clip:content-box
  }

  .md\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .md\:bg-bottom{
    background-position:bottom
  }

  .md\:bg-center{
    background-position:center
  }

  .md\:bg-left{
    background-position:left
  }

  .md\:bg-left-bottom{
    background-position:left bottom
  }

  .md\:bg-left-top{
    background-position:left top
  }

  .md\:bg-right{
    background-position:right
  }

  .md\:bg-right-bottom{
    background-position:right bottom
  }

  .md\:bg-right-top{
    background-position:right top
  }

  .md\:bg-top{
    background-position:top
  }

  .md\:bg-repeat{
    background-repeat:repeat
  }

  .md\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .md\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .md\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .md\:bg-repeat-round{
    background-repeat:round
  }

  .md\:bg-repeat-space{
    background-repeat:space
  }

  .md\:bg-origin-border{
    background-origin:border-box
  }

  .md\:bg-origin-padding{
    background-origin:padding-box
  }

  .md\:bg-origin-content{
    background-origin:content-box
  }

  .md\:fill-current{
    fill:currentColor
  }

  .md\:object-contain{
    object-fit:contain
  }

  .md\:object-cover{
    object-fit:cover
  }

  .md\:object-fill{
    object-fit:fill
  }

  .md\:object-none{
    object-fit:none
  }

  .md\:object-scale-down{
    object-fit:scale-down
  }

  .md\:p-0{
    padding:0
  }

  .md\:p-1{
    padding:4px
  }

  .md\:p-2{
    padding:8px
  }

  .md\:p-3{
    padding:12px
  }

  .md\:p-4{
    padding:16px
  }

  .md\:p-5{
    padding:20px
  }

  .md\:p-6{
    padding:24px
  }

  .md\:p-8{
    padding:32px
  }

  .md\:p-10{
    padding:40px
  }

  .md\:p-12{
    padding:48px
  }

  .md\:p-16{
    padding:64px
  }

  .md\:p-18{
    padding:72px
  }

  .md\:p-24{
    padding:96px
  }

  .md\:p-5dot5{
    padding:22px
  }

  .md\:p-full{
    padding:100%
  }

  .md\:first\:p-0:first-child{
    padding:0
  }

  .md\:first\:p-1:first-child{
    padding:4px
  }

  .md\:first\:p-2:first-child{
    padding:8px
  }

  .md\:first\:p-3:first-child{
    padding:12px
  }

  .md\:first\:p-4:first-child{
    padding:16px
  }

  .md\:first\:p-5:first-child{
    padding:20px
  }

  .md\:first\:p-6:first-child{
    padding:24px
  }

  .md\:first\:p-8:first-child{
    padding:32px
  }

  .md\:first\:p-10:first-child{
    padding:40px
  }

  .md\:first\:p-12:first-child{
    padding:48px
  }

  .md\:first\:p-16:first-child{
    padding:64px
  }

  .md\:first\:p-18:first-child{
    padding:72px
  }

  .md\:first\:p-24:first-child{
    padding:96px
  }

  .md\:first\:p-5dot5:first-child{
    padding:22px
  }

  .md\:first\:p-full:first-child{
    padding:100%
  }

  .md\:last\:p-0:last-child{
    padding:0
  }

  .md\:last\:p-1:last-child{
    padding:4px
  }

  .md\:last\:p-2:last-child{
    padding:8px
  }

  .md\:last\:p-3:last-child{
    padding:12px
  }

  .md\:last\:p-4:last-child{
    padding:16px
  }

  .md\:last\:p-5:last-child{
    padding:20px
  }

  .md\:last\:p-6:last-child{
    padding:24px
  }

  .md\:last\:p-8:last-child{
    padding:32px
  }

  .md\:last\:p-10:last-child{
    padding:40px
  }

  .md\:last\:p-12:last-child{
    padding:48px
  }

  .md\:last\:p-16:last-child{
    padding:64px
  }

  .md\:last\:p-18:last-child{
    padding:72px
  }

  .md\:last\:p-24:last-child{
    padding:96px
  }

  .md\:last\:p-5dot5:last-child{
    padding:22px
  }

  .md\:last\:p-full:last-child{
    padding:100%
  }

  .md\:px-0{
    padding-left:0;
    padding-right:0
  }

  .md\:px-1{
    padding-left:4px;
    padding-right:4px
  }

  .md\:px-2{
    padding-left:8px;
    padding-right:8px
  }

  .md\:px-3{
    padding-left:12px;
    padding-right:12px
  }

  .md\:px-4{
    padding-left:16px;
    padding-right:16px
  }

  .md\:px-5{
    padding-left:20px;
    padding-right:20px
  }

  .md\:px-6{
    padding-left:24px;
    padding-right:24px
  }

  .md\:px-8{
    padding-left:32px;
    padding-right:32px
  }

  .md\:px-10{
    padding-left:40px;
    padding-right:40px
  }

  .md\:px-12{
    padding-left:48px;
    padding-right:48px
  }

  .md\:px-16{
    padding-left:64px;
    padding-right:64px
  }

  .md\:px-18{
    padding-left:72px;
    padding-right:72px
  }

  .md\:px-24{
    padding-left:96px;
    padding-right:96px
  }

  .md\:px-5dot5{
    padding-left:22px;
    padding-right:22px
  }

  .md\:px-full{
    padding-left:100%;
    padding-right:100%
  }

  .md\:py-0{
    padding-top:0;
    padding-bottom:0
  }

  .md\:py-1{
    padding-top:4px;
    padding-bottom:4px
  }

  .md\:py-2{
    padding-top:8px;
    padding-bottom:8px
  }

  .md\:py-3{
    padding-top:12px;
    padding-bottom:12px
  }

  .md\:py-4{
    padding-top:16px;
    padding-bottom:16px
  }

  .md\:py-5{
    padding-top:20px;
    padding-bottom:20px
  }

  .md\:py-6{
    padding-top:24px;
    padding-bottom:24px
  }

  .md\:py-8{
    padding-top:32px;
    padding-bottom:32px
  }

  .md\:py-10{
    padding-top:40px;
    padding-bottom:40px
  }

  .md\:py-12{
    padding-top:48px;
    padding-bottom:48px
  }

  .md\:py-16{
    padding-top:64px;
    padding-bottom:64px
  }

  .md\:py-18{
    padding-top:72px;
    padding-bottom:72px
  }

  .md\:py-24{
    padding-top:96px;
    padding-bottom:96px
  }

  .md\:py-5dot5{
    padding-top:22px;
    padding-bottom:22px
  }

  .md\:py-full{
    padding-top:100%;
    padding-bottom:100%
  }

  .md\:first\:px-0:first-child{
    padding-left:0;
    padding-right:0
  }

  .md\:first\:px-1:first-child{
    padding-left:4px;
    padding-right:4px
  }

  .md\:first\:px-2:first-child{
    padding-left:8px;
    padding-right:8px
  }

  .md\:first\:px-3:first-child{
    padding-left:12px;
    padding-right:12px
  }

  .md\:first\:px-4:first-child{
    padding-left:16px;
    padding-right:16px
  }

  .md\:first\:px-5:first-child{
    padding-left:20px;
    padding-right:20px
  }

  .md\:first\:px-6:first-child{
    padding-left:24px;
    padding-right:24px
  }

  .md\:first\:px-8:first-child{
    padding-left:32px;
    padding-right:32px
  }

  .md\:first\:px-10:first-child{
    padding-left:40px;
    padding-right:40px
  }

  .md\:first\:px-12:first-child{
    padding-left:48px;
    padding-right:48px
  }

  .md\:first\:px-16:first-child{
    padding-left:64px;
    padding-right:64px
  }

  .md\:first\:px-18:first-child{
    padding-left:72px;
    padding-right:72px
  }

  .md\:first\:px-24:first-child{
    padding-left:96px;
    padding-right:96px
  }

  .md\:first\:px-5dot5:first-child{
    padding-left:22px;
    padding-right:22px
  }

  .md\:first\:px-full:first-child{
    padding-left:100%;
    padding-right:100%
  }

  .md\:first\:py-0:first-child{
    padding-top:0;
    padding-bottom:0
  }

  .md\:first\:py-1:first-child{
    padding-top:4px;
    padding-bottom:4px
  }

  .md\:first\:py-2:first-child{
    padding-top:8px;
    padding-bottom:8px
  }

  .md\:first\:py-3:first-child{
    padding-top:12px;
    padding-bottom:12px
  }

  .md\:first\:py-4:first-child{
    padding-top:16px;
    padding-bottom:16px
  }

  .md\:first\:py-5:first-child{
    padding-top:20px;
    padding-bottom:20px
  }

  .md\:first\:py-6:first-child{
    padding-top:24px;
    padding-bottom:24px
  }

  .md\:first\:py-8:first-child{
    padding-top:32px;
    padding-bottom:32px
  }

  .md\:first\:py-10:first-child{
    padding-top:40px;
    padding-bottom:40px
  }

  .md\:first\:py-12:first-child{
    padding-top:48px;
    padding-bottom:48px
  }

  .md\:first\:py-16:first-child{
    padding-top:64px;
    padding-bottom:64px
  }

  .md\:first\:py-18:first-child{
    padding-top:72px;
    padding-bottom:72px
  }

  .md\:first\:py-24:first-child{
    padding-top:96px;
    padding-bottom:96px
  }

  .md\:first\:py-5dot5:first-child{
    padding-top:22px;
    padding-bottom:22px
  }

  .md\:first\:py-full:first-child{
    padding-top:100%;
    padding-bottom:100%
  }

  .md\:last\:px-0:last-child{
    padding-left:0;
    padding-right:0
  }

  .md\:last\:px-1:last-child{
    padding-left:4px;
    padding-right:4px
  }

  .md\:last\:px-2:last-child{
    padding-left:8px;
    padding-right:8px
  }

  .md\:last\:px-3:last-child{
    padding-left:12px;
    padding-right:12px
  }

  .md\:last\:px-4:last-child{
    padding-left:16px;
    padding-right:16px
  }

  .md\:last\:px-5:last-child{
    padding-left:20px;
    padding-right:20px
  }

  .md\:last\:px-6:last-child{
    padding-left:24px;
    padding-right:24px
  }

  .md\:last\:px-8:last-child{
    padding-left:32px;
    padding-right:32px
  }

  .md\:last\:px-10:last-child{
    padding-left:40px;
    padding-right:40px
  }

  .md\:last\:px-12:last-child{
    padding-left:48px;
    padding-right:48px
  }

  .md\:last\:px-16:last-child{
    padding-left:64px;
    padding-right:64px
  }

  .md\:last\:px-18:last-child{
    padding-left:72px;
    padding-right:72px
  }

  .md\:last\:px-24:last-child{
    padding-left:96px;
    padding-right:96px
  }

  .md\:last\:px-5dot5:last-child{
    padding-left:22px;
    padding-right:22px
  }

  .md\:last\:px-full:last-child{
    padding-left:100%;
    padding-right:100%
  }

  .md\:last\:py-0:last-child{
    padding-top:0;
    padding-bottom:0
  }

  .md\:last\:py-1:last-child{
    padding-top:4px;
    padding-bottom:4px
  }

  .md\:last\:py-2:last-child{
    padding-top:8px;
    padding-bottom:8px
  }

  .md\:last\:py-3:last-child{
    padding-top:12px;
    padding-bottom:12px
  }

  .md\:last\:py-4:last-child{
    padding-top:16px;
    padding-bottom:16px
  }

  .md\:last\:py-5:last-child{
    padding-top:20px;
    padding-bottom:20px
  }

  .md\:last\:py-6:last-child{
    padding-top:24px;
    padding-bottom:24px
  }

  .md\:last\:py-8:last-child{
    padding-top:32px;
    padding-bottom:32px
  }

  .md\:last\:py-10:last-child{
    padding-top:40px;
    padding-bottom:40px
  }

  .md\:last\:py-12:last-child{
    padding-top:48px;
    padding-bottom:48px
  }

  .md\:last\:py-16:last-child{
    padding-top:64px;
    padding-bottom:64px
  }

  .md\:last\:py-18:last-child{
    padding-top:72px;
    padding-bottom:72px
  }

  .md\:last\:py-24:last-child{
    padding-top:96px;
    padding-bottom:96px
  }

  .md\:last\:py-5dot5:last-child{
    padding-top:22px;
    padding-bottom:22px
  }

  .md\:last\:py-full:last-child{
    padding-top:100%;
    padding-bottom:100%
  }

  .md\:pt-0{
    padding-top:0
  }

  .md\:pt-1{
    padding-top:4px
  }

  .md\:pt-2{
    padding-top:8px
  }

  .md\:pt-3{
    padding-top:12px
  }

  .md\:pt-4{
    padding-top:16px
  }

  .md\:pt-5{
    padding-top:20px
  }

  .md\:pt-6{
    padding-top:24px
  }

  .md\:pt-8{
    padding-top:32px
  }

  .md\:pt-10{
    padding-top:40px
  }

  .md\:pt-12{
    padding-top:48px
  }

  .md\:pt-16{
    padding-top:64px
  }

  .md\:pt-18{
    padding-top:72px
  }

  .md\:pt-24{
    padding-top:96px
  }

  .md\:pt-5dot5{
    padding-top:22px
  }

  .md\:pt-full{
    padding-top:100%
  }

  .md\:pr-0{
    padding-right:0
  }

  .md\:pr-1{
    padding-right:4px
  }

  .md\:pr-2{
    padding-right:8px
  }

  .md\:pr-3{
    padding-right:12px
  }

  .md\:pr-4{
    padding-right:16px
  }

  .md\:pr-5{
    padding-right:20px
  }

  .md\:pr-6{
    padding-right:24px
  }

  .md\:pr-8{
    padding-right:32px
  }

  .md\:pr-10{
    padding-right:40px
  }

  .md\:pr-12{
    padding-right:48px
  }

  .md\:pr-16{
    padding-right:64px
  }

  .md\:pr-18{
    padding-right:72px
  }

  .md\:pr-24{
    padding-right:96px
  }

  .md\:pr-5dot5{
    padding-right:22px
  }

  .md\:pr-full{
    padding-right:100%
  }

  .md\:pb-0{
    padding-bottom:0
  }

  .md\:pb-1{
    padding-bottom:4px
  }

  .md\:pb-2{
    padding-bottom:8px
  }

  .md\:pb-3{
    padding-bottom:12px
  }

  .md\:pb-4{
    padding-bottom:16px
  }

  .md\:pb-5{
    padding-bottom:20px
  }

  .md\:pb-6{
    padding-bottom:24px
  }

  .md\:pb-8{
    padding-bottom:32px
  }

  .md\:pb-10{
    padding-bottom:40px
  }

  .md\:pb-12{
    padding-bottom:48px
  }

  .md\:pb-16{
    padding-bottom:64px
  }

  .md\:pb-18{
    padding-bottom:72px
  }

  .md\:pb-24{
    padding-bottom:96px
  }

  .md\:pb-5dot5{
    padding-bottom:22px
  }

  .md\:pb-full{
    padding-bottom:100%
  }

  .md\:pl-0{
    padding-left:0
  }

  .md\:pl-1{
    padding-left:4px
  }

  .md\:pl-2{
    padding-left:8px
  }

  .md\:pl-3{
    padding-left:12px
  }

  .md\:pl-4{
    padding-left:16px
  }

  .md\:pl-5{
    padding-left:20px
  }

  .md\:pl-6{
    padding-left:24px
  }

  .md\:pl-8{
    padding-left:32px
  }

  .md\:pl-10{
    padding-left:40px
  }

  .md\:pl-12{
    padding-left:48px
  }

  .md\:pl-16{
    padding-left:64px
  }

  .md\:pl-18{
    padding-left:72px
  }

  .md\:pl-24{
    padding-left:96px
  }

  .md\:pl-5dot5{
    padding-left:22px
  }

  .md\:pl-full{
    padding-left:100%
  }

  .md\:first\:pt-0:first-child{
    padding-top:0
  }

  .md\:first\:pt-1:first-child{
    padding-top:4px
  }

  .md\:first\:pt-2:first-child{
    padding-top:8px
  }

  .md\:first\:pt-3:first-child{
    padding-top:12px
  }

  .md\:first\:pt-4:first-child{
    padding-top:16px
  }

  .md\:first\:pt-5:first-child{
    padding-top:20px
  }

  .md\:first\:pt-6:first-child{
    padding-top:24px
  }

  .md\:first\:pt-8:first-child{
    padding-top:32px
  }

  .md\:first\:pt-10:first-child{
    padding-top:40px
  }

  .md\:first\:pt-12:first-child{
    padding-top:48px
  }

  .md\:first\:pt-16:first-child{
    padding-top:64px
  }

  .md\:first\:pt-18:first-child{
    padding-top:72px
  }

  .md\:first\:pt-24:first-child{
    padding-top:96px
  }

  .md\:first\:pt-5dot5:first-child{
    padding-top:22px
  }

  .md\:first\:pt-full:first-child{
    padding-top:100%
  }

  .md\:first\:pr-0:first-child{
    padding-right:0
  }

  .md\:first\:pr-1:first-child{
    padding-right:4px
  }

  .md\:first\:pr-2:first-child{
    padding-right:8px
  }

  .md\:first\:pr-3:first-child{
    padding-right:12px
  }

  .md\:first\:pr-4:first-child{
    padding-right:16px
  }

  .md\:first\:pr-5:first-child{
    padding-right:20px
  }

  .md\:first\:pr-6:first-child{
    padding-right:24px
  }

  .md\:first\:pr-8:first-child{
    padding-right:32px
  }

  .md\:first\:pr-10:first-child{
    padding-right:40px
  }

  .md\:first\:pr-12:first-child{
    padding-right:48px
  }

  .md\:first\:pr-16:first-child{
    padding-right:64px
  }

  .md\:first\:pr-18:first-child{
    padding-right:72px
  }

  .md\:first\:pr-24:first-child{
    padding-right:96px
  }

  .md\:first\:pr-5dot5:first-child{
    padding-right:22px
  }

  .md\:first\:pr-full:first-child{
    padding-right:100%
  }

  .md\:first\:pb-0:first-child{
    padding-bottom:0
  }

  .md\:first\:pb-1:first-child{
    padding-bottom:4px
  }

  .md\:first\:pb-2:first-child{
    padding-bottom:8px
  }

  .md\:first\:pb-3:first-child{
    padding-bottom:12px
  }

  .md\:first\:pb-4:first-child{
    padding-bottom:16px
  }

  .md\:first\:pb-5:first-child{
    padding-bottom:20px
  }

  .md\:first\:pb-6:first-child{
    padding-bottom:24px
  }

  .md\:first\:pb-8:first-child{
    padding-bottom:32px
  }

  .md\:first\:pb-10:first-child{
    padding-bottom:40px
  }

  .md\:first\:pb-12:first-child{
    padding-bottom:48px
  }

  .md\:first\:pb-16:first-child{
    padding-bottom:64px
  }

  .md\:first\:pb-18:first-child{
    padding-bottom:72px
  }

  .md\:first\:pb-24:first-child{
    padding-bottom:96px
  }

  .md\:first\:pb-5dot5:first-child{
    padding-bottom:22px
  }

  .md\:first\:pb-full:first-child{
    padding-bottom:100%
  }

  .md\:first\:pl-0:first-child{
    padding-left:0
  }

  .md\:first\:pl-1:first-child{
    padding-left:4px
  }

  .md\:first\:pl-2:first-child{
    padding-left:8px
  }

  .md\:first\:pl-3:first-child{
    padding-left:12px
  }

  .md\:first\:pl-4:first-child{
    padding-left:16px
  }

  .md\:first\:pl-5:first-child{
    padding-left:20px
  }

  .md\:first\:pl-6:first-child{
    padding-left:24px
  }

  .md\:first\:pl-8:first-child{
    padding-left:32px
  }

  .md\:first\:pl-10:first-child{
    padding-left:40px
  }

  .md\:first\:pl-12:first-child{
    padding-left:48px
  }

  .md\:first\:pl-16:first-child{
    padding-left:64px
  }

  .md\:first\:pl-18:first-child{
    padding-left:72px
  }

  .md\:first\:pl-24:first-child{
    padding-left:96px
  }

  .md\:first\:pl-5dot5:first-child{
    padding-left:22px
  }

  .md\:first\:pl-full:first-child{
    padding-left:100%
  }

  .md\:last\:pt-0:last-child{
    padding-top:0
  }

  .md\:last\:pt-1:last-child{
    padding-top:4px
  }

  .md\:last\:pt-2:last-child{
    padding-top:8px
  }

  .md\:last\:pt-3:last-child{
    padding-top:12px
  }

  .md\:last\:pt-4:last-child{
    padding-top:16px
  }

  .md\:last\:pt-5:last-child{
    padding-top:20px
  }

  .md\:last\:pt-6:last-child{
    padding-top:24px
  }

  .md\:last\:pt-8:last-child{
    padding-top:32px
  }

  .md\:last\:pt-10:last-child{
    padding-top:40px
  }

  .md\:last\:pt-12:last-child{
    padding-top:48px
  }

  .md\:last\:pt-16:last-child{
    padding-top:64px
  }

  .md\:last\:pt-18:last-child{
    padding-top:72px
  }

  .md\:last\:pt-24:last-child{
    padding-top:96px
  }

  .md\:last\:pt-5dot5:last-child{
    padding-top:22px
  }

  .md\:last\:pt-full:last-child{
    padding-top:100%
  }

  .md\:last\:pr-0:last-child{
    padding-right:0
  }

  .md\:last\:pr-1:last-child{
    padding-right:4px
  }

  .md\:last\:pr-2:last-child{
    padding-right:8px
  }

  .md\:last\:pr-3:last-child{
    padding-right:12px
  }

  .md\:last\:pr-4:last-child{
    padding-right:16px
  }

  .md\:last\:pr-5:last-child{
    padding-right:20px
  }

  .md\:last\:pr-6:last-child{
    padding-right:24px
  }

  .md\:last\:pr-8:last-child{
    padding-right:32px
  }

  .md\:last\:pr-10:last-child{
    padding-right:40px
  }

  .md\:last\:pr-12:last-child{
    padding-right:48px
  }

  .md\:last\:pr-16:last-child{
    padding-right:64px
  }

  .md\:last\:pr-18:last-child{
    padding-right:72px
  }

  .md\:last\:pr-24:last-child{
    padding-right:96px
  }

  .md\:last\:pr-5dot5:last-child{
    padding-right:22px
  }

  .md\:last\:pr-full:last-child{
    padding-right:100%
  }

  .md\:last\:pb-0:last-child{
    padding-bottom:0
  }

  .md\:last\:pb-1:last-child{
    padding-bottom:4px
  }

  .md\:last\:pb-2:last-child{
    padding-bottom:8px
  }

  .md\:last\:pb-3:last-child{
    padding-bottom:12px
  }

  .md\:last\:pb-4:last-child{
    padding-bottom:16px
  }

  .md\:last\:pb-5:last-child{
    padding-bottom:20px
  }

  .md\:last\:pb-6:last-child{
    padding-bottom:24px
  }

  .md\:last\:pb-8:last-child{
    padding-bottom:32px
  }

  .md\:last\:pb-10:last-child{
    padding-bottom:40px
  }

  .md\:last\:pb-12:last-child{
    padding-bottom:48px
  }

  .md\:last\:pb-16:last-child{
    padding-bottom:64px
  }

  .md\:last\:pb-18:last-child{
    padding-bottom:72px
  }

  .md\:last\:pb-24:last-child{
    padding-bottom:96px
  }

  .md\:last\:pb-5dot5:last-child{
    padding-bottom:22px
  }

  .md\:last\:pb-full:last-child{
    padding-bottom:100%
  }

  .md\:last\:pl-0:last-child{
    padding-left:0
  }

  .md\:last\:pl-1:last-child{
    padding-left:4px
  }

  .md\:last\:pl-2:last-child{
    padding-left:8px
  }

  .md\:last\:pl-3:last-child{
    padding-left:12px
  }

  .md\:last\:pl-4:last-child{
    padding-left:16px
  }

  .md\:last\:pl-5:last-child{
    padding-left:20px
  }

  .md\:last\:pl-6:last-child{
    padding-left:24px
  }

  .md\:last\:pl-8:last-child{
    padding-left:32px
  }

  .md\:last\:pl-10:last-child{
    padding-left:40px
  }

  .md\:last\:pl-12:last-child{
    padding-left:48px
  }

  .md\:last\:pl-16:last-child{
    padding-left:64px
  }

  .md\:last\:pl-18:last-child{
    padding-left:72px
  }

  .md\:last\:pl-24:last-child{
    padding-left:96px
  }

  .md\:last\:pl-5dot5:last-child{
    padding-left:22px
  }

  .md\:last\:pl-full:last-child{
    padding-left:100%
  }

  .md\:text-left{
    text-align:left
  }

  .md\:text-center{
    text-align:center
  }

  .md\:text-right{
    text-align:right
  }

  .md\:text-justify{
    text-align:justify
  }

  .md\:align-baseline{
    vertical-align:baseline
  }

  .md\:align-top{
    vertical-align:top
  }

  .md\:align-middle{
    vertical-align:middle
  }

  .md\:align-bottom{
    vertical-align:bottom
  }

  .md\:align-text-top{
    vertical-align:text-top
  }

  .md\:align-text-bottom{
    vertical-align:text-bottom
  }

  .md\:text-xs{
    font-size:10px
  }

  .md\:text-s{
    font-size:12px
  }

  .md\:text-sm{
    font-size:14px
  }

  .md\:text-tiny{
    font-size:12px
  }

  .md\:text-base{
    font-size:16px
  }

  .md\:text-lg{
    font-size:20px
  }

  .md\:text-xl{
    font-size:24px
  }

  .md\:text-2xl{
    font-size:32px
  }

  .md\:text-3xl{
    font-size:40px
  }

  .md\:text-4xl{
    font-size:48px
  }

  .md\:text-5xl{
    font-size:64px
  }

  .md\:font-thin{
    font-weight:100
  }

  .md\:font-extralight{
    font-weight:200
  }

  .md\:font-light{
    font-weight:300
  }

  .md\:font-normal{
    font-weight:400
  }

  .md\:font-medium{
    font-weight:500
  }

  .md\:font-semibold{
    font-weight:600
  }

  .md\:font-bold{
    font-weight:700
  }

  .md\:font-extrabold{
    font-weight:800
  }

  .md\:font-black{
    font-weight:900
  }

  .md\:uppercase{
    text-transform:uppercase
  }

  .md\:lowercase{
    text-transform:lowercase
  }

  .md\:capitalize{
    text-transform:capitalize
  }

  .md\:normal-case{
    text-transform:none
  }

  .md\:italic{
    font-style:italic
  }

  .md\:not-italic{
    font-style:normal
  }

  .md\:ordinal, .md\:slashed-zero, .md\:lining-nums, .md\:oldstyle-nums, .md\:proportional-nums, .md\:tabular-nums, .md\:diagonal-fractions, .md\:stacked-fractions{
    --tw-ordinal:var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction:var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric:var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  .md\:normal-nums{
    font-variant-numeric:normal
  }

  .md\:ordinal{
    --tw-ordinal:ordinal
  }

  .md\:slashed-zero{
    --tw-slashed-zero:slashed-zero
  }

  .md\:lining-nums{
    --tw-numeric-figure:lining-nums
  }

  .md\:oldstyle-nums{
    --tw-numeric-figure:oldstyle-nums
  }

  .md\:proportional-nums{
    --tw-numeric-spacing:proportional-nums
  }

  .md\:tabular-nums{
    --tw-numeric-spacing:tabular-nums
  }

  .md\:diagonal-fractions{
    --tw-numeric-fraction:diagonal-fractions
  }

  .md\:stacked-fractions{
    --tw-numeric-fraction:stacked-fractions
  }

  .md\:leading-3{
    line-height:.75rem
  }

  .md\:leading-4{
    line-height:1rem
  }

  .md\:leading-5{
    line-height:1.25rem
  }

  .md\:leading-6{
    line-height:1.5rem
  }

  .md\:leading-7{
    line-height:1.75rem
  }

  .md\:leading-8{
    line-height:2rem
  }

  .md\:leading-9{
    line-height:2.25rem
  }

  .md\:leading-10{
    line-height:2.5rem
  }

  .md\:leading-none{
    line-height:1
  }

  .md\:leading-tight{
    line-height:1.25
  }

  .md\:leading-snug{
    line-height:1.375
  }

  .md\:leading-normal{
    line-height:1.5
  }

  .md\:leading-relaxed{
    line-height:1.625
  }

  .md\:leading-loose{
    line-height:2
  }

  .md\:text-red1{
    color:#CC352C
  }

  .md\:text-red2{
    color:#FF554B
  }

  .md\:text-red3{
    color:#FF7B73
  }

  .md\:text-red4{
    color:#FFF1F1
  }

  .md\:text-red5{
    color:#FFF6F6
  }

  .md\:text-cyan1{
    color:#009897
  }

  .md\:text-cyan2{
    color:#00BFBD
  }

  .md\:text-cyan3{
    color:#4CD1D0
  }

  .md\:text-cyan4{
    color:#B2ECEB
  }

  .md\:text-cyan5{
    color:#EDFAFA
  }

  .md\:text-bg1{
    color:#FAFAFC
  }

  .md\:text-bg2{
    color:#ffffff
  }

  .md\:text-bg3{
    color:#F7F7FA
  }

  .md\:text-gray1{
    color:#333333
  }

  .md\:text-gray2{
    color:#4D4D4D
  }

  .md\:text-gray3{
    color:#8C8C8C
  }

  .md\:text-gray4{
    color:#BFBFBF
  }

  .md\:text-gray5{
    color:#D9D9D9
  }

  .md\:text-gray6{
    color:#E9E9EB
  }

  .md\:text-gray7{
    color:#515164
  }

  .md\:text-white{
    color:#ffffff
  }

  .md\:text-warning{
    color:#FFC319
  }

  .md\:text-error{
    color:#EA696F
  }

  .md\:text-info{
    color:#0071B9
  }

  .md\:text-success{
    color:#98D45F
  }

  .md\:text-special4{
    color:#59779A
  }

  .md\:text-dark{
    color:#000000
  }

  .md\:text-transparent{
    color:transparent
  }

  .md\:text-secondary1{
    color:#313140
  }

  .md\:text-secondary2{
    color:#515164
  }

  .md\:text-secondary3{
    color:#9C9CAC
  }

  .md\:text-secondary4{
    color:#E5E8ED
  }

  .md\:text-secondary5{
    color:#F5F6F9
  }

  .md\:text-green1{
    color:#0DE298
  }

  .group:hover .md\:group-hover\:text-red1{
    color:#CC352C
  }

  .group:hover .md\:group-hover\:text-red2{
    color:#FF554B
  }

  .group:hover .md\:group-hover\:text-red3{
    color:#FF7B73
  }

  .group:hover .md\:group-hover\:text-red4{
    color:#FFF1F1
  }

  .group:hover .md\:group-hover\:text-red5{
    color:#FFF6F6
  }

  .group:hover .md\:group-hover\:text-cyan1{
    color:#009897
  }

  .group:hover .md\:group-hover\:text-cyan2{
    color:#00BFBD
  }

  .group:hover .md\:group-hover\:text-cyan3{
    color:#4CD1D0
  }

  .group:hover .md\:group-hover\:text-cyan4{
    color:#B2ECEB
  }

  .group:hover .md\:group-hover\:text-cyan5{
    color:#EDFAFA
  }

  .group:hover .md\:group-hover\:text-bg1{
    color:#FAFAFC
  }

  .group:hover .md\:group-hover\:text-bg2{
    color:#ffffff
  }

  .group:hover .md\:group-hover\:text-bg3{
    color:#F7F7FA
  }

  .group:hover .md\:group-hover\:text-gray1{
    color:#333333
  }

  .group:hover .md\:group-hover\:text-gray2{
    color:#4D4D4D
  }

  .group:hover .md\:group-hover\:text-gray3{
    color:#8C8C8C
  }

  .group:hover .md\:group-hover\:text-gray4{
    color:#BFBFBF
  }

  .group:hover .md\:group-hover\:text-gray5{
    color:#D9D9D9
  }

  .group:hover .md\:group-hover\:text-gray6{
    color:#E9E9EB
  }

  .group:hover .md\:group-hover\:text-gray7{
    color:#515164
  }

  .group:hover .md\:group-hover\:text-white{
    color:#ffffff
  }

  .group:hover .md\:group-hover\:text-warning{
    color:#FFC319
  }

  .group:hover .md\:group-hover\:text-error{
    color:#EA696F
  }

  .group:hover .md\:group-hover\:text-info{
    color:#0071B9
  }

  .group:hover .md\:group-hover\:text-success{
    color:#98D45F
  }

  .group:hover .md\:group-hover\:text-special4{
    color:#59779A
  }

  .group:hover .md\:group-hover\:text-dark{
    color:#000000
  }

  .group:hover .md\:group-hover\:text-transparent{
    color:transparent
  }

  .group:hover .md\:group-hover\:text-secondary1{
    color:#313140
  }

  .group:hover .md\:group-hover\:text-secondary2{
    color:#515164
  }

  .group:hover .md\:group-hover\:text-secondary3{
    color:#9C9CAC
  }

  .group:hover .md\:group-hover\:text-secondary4{
    color:#E5E8ED
  }

  .group:hover .md\:group-hover\:text-secondary5{
    color:#F5F6F9
  }

  .group:hover .md\:group-hover\:text-green1{
    color:#0DE298
  }

  .md\:focus-within\:text-red1:focus-within{
    color:#CC352C
  }

  .md\:focus-within\:text-red2:focus-within{
    color:#FF554B
  }

  .md\:focus-within\:text-red3:focus-within{
    color:#FF7B73
  }

  .md\:focus-within\:text-red4:focus-within{
    color:#FFF1F1
  }

  .md\:focus-within\:text-red5:focus-within{
    color:#FFF6F6
  }

  .md\:focus-within\:text-cyan1:focus-within{
    color:#009897
  }

  .md\:focus-within\:text-cyan2:focus-within{
    color:#00BFBD
  }

  .md\:focus-within\:text-cyan3:focus-within{
    color:#4CD1D0
  }

  .md\:focus-within\:text-cyan4:focus-within{
    color:#B2ECEB
  }

  .md\:focus-within\:text-cyan5:focus-within{
    color:#EDFAFA
  }

  .md\:focus-within\:text-bg1:focus-within{
    color:#FAFAFC
  }

  .md\:focus-within\:text-bg2:focus-within{
    color:#ffffff
  }

  .md\:focus-within\:text-bg3:focus-within{
    color:#F7F7FA
  }

  .md\:focus-within\:text-gray1:focus-within{
    color:#333333
  }

  .md\:focus-within\:text-gray2:focus-within{
    color:#4D4D4D
  }

  .md\:focus-within\:text-gray3:focus-within{
    color:#8C8C8C
  }

  .md\:focus-within\:text-gray4:focus-within{
    color:#BFBFBF
  }

  .md\:focus-within\:text-gray5:focus-within{
    color:#D9D9D9
  }

  .md\:focus-within\:text-gray6:focus-within{
    color:#E9E9EB
  }

  .md\:focus-within\:text-gray7:focus-within{
    color:#515164
  }

  .md\:focus-within\:text-white:focus-within{
    color:#ffffff
  }

  .md\:focus-within\:text-warning:focus-within{
    color:#FFC319
  }

  .md\:focus-within\:text-error:focus-within{
    color:#EA696F
  }

  .md\:focus-within\:text-info:focus-within{
    color:#0071B9
  }

  .md\:focus-within\:text-success:focus-within{
    color:#98D45F
  }

  .md\:focus-within\:text-special4:focus-within{
    color:#59779A
  }

  .md\:focus-within\:text-dark:focus-within{
    color:#000000
  }

  .md\:focus-within\:text-transparent:focus-within{
    color:transparent
  }

  .md\:focus-within\:text-secondary1:focus-within{
    color:#313140
  }

  .md\:focus-within\:text-secondary2:focus-within{
    color:#515164
  }

  .md\:focus-within\:text-secondary3:focus-within{
    color:#9C9CAC
  }

  .md\:focus-within\:text-secondary4:focus-within{
    color:#E5E8ED
  }

  .md\:focus-within\:text-secondary5:focus-within{
    color:#F5F6F9
  }

  .md\:focus-within\:text-green1:focus-within{
    color:#0DE298
  }

  .md\:hover\:text-red1:hover{
    color:#CC352C
  }

  .md\:hover\:text-red2:hover{
    color:#FF554B
  }

  .md\:hover\:text-red3:hover{
    color:#FF7B73
  }

  .md\:hover\:text-red4:hover{
    color:#FFF1F1
  }

  .md\:hover\:text-red5:hover{
    color:#FFF6F6
  }

  .md\:hover\:text-cyan1:hover{
    color:#009897
  }

  .md\:hover\:text-cyan2:hover{
    color:#00BFBD
  }

  .md\:hover\:text-cyan3:hover{
    color:#4CD1D0
  }

  .md\:hover\:text-cyan4:hover{
    color:#B2ECEB
  }

  .md\:hover\:text-cyan5:hover{
    color:#EDFAFA
  }

  .md\:hover\:text-bg1:hover{
    color:#FAFAFC
  }

  .md\:hover\:text-bg2:hover{
    color:#ffffff
  }

  .md\:hover\:text-bg3:hover{
    color:#F7F7FA
  }

  .md\:hover\:text-gray1:hover{
    color:#333333
  }

  .md\:hover\:text-gray2:hover{
    color:#4D4D4D
  }

  .md\:hover\:text-gray3:hover{
    color:#8C8C8C
  }

  .md\:hover\:text-gray4:hover{
    color:#BFBFBF
  }

  .md\:hover\:text-gray5:hover{
    color:#D9D9D9
  }

  .md\:hover\:text-gray6:hover{
    color:#E9E9EB
  }

  .md\:hover\:text-gray7:hover{
    color:#515164
  }

  .md\:hover\:text-white:hover{
    color:#ffffff
  }

  .md\:hover\:text-warning:hover{
    color:#FFC319
  }

  .md\:hover\:text-error:hover{
    color:#EA696F
  }

  .md\:hover\:text-info:hover{
    color:#0071B9
  }

  .md\:hover\:text-success:hover{
    color:#98D45F
  }

  .md\:hover\:text-special4:hover{
    color:#59779A
  }

  .md\:hover\:text-dark:hover{
    color:#000000
  }

  .md\:hover\:text-transparent:hover{
    color:transparent
  }

  .md\:hover\:text-secondary1:hover{
    color:#313140
  }

  .md\:hover\:text-secondary2:hover{
    color:#515164
  }

  .md\:hover\:text-secondary3:hover{
    color:#9C9CAC
  }

  .md\:hover\:text-secondary4:hover{
    color:#E5E8ED
  }

  .md\:hover\:text-secondary5:hover{
    color:#F5F6F9
  }

  .md\:hover\:text-green1:hover{
    color:#0DE298
  }

  .md\:focus\:text-red1:focus{
    color:#CC352C
  }

  .md\:focus\:text-red2:focus{
    color:#FF554B
  }

  .md\:focus\:text-red3:focus{
    color:#FF7B73
  }

  .md\:focus\:text-red4:focus{
    color:#FFF1F1
  }

  .md\:focus\:text-red5:focus{
    color:#FFF6F6
  }

  .md\:focus\:text-cyan1:focus{
    color:#009897
  }

  .md\:focus\:text-cyan2:focus{
    color:#00BFBD
  }

  .md\:focus\:text-cyan3:focus{
    color:#4CD1D0
  }

  .md\:focus\:text-cyan4:focus{
    color:#B2ECEB
  }

  .md\:focus\:text-cyan5:focus{
    color:#EDFAFA
  }

  .md\:focus\:text-bg1:focus{
    color:#FAFAFC
  }

  .md\:focus\:text-bg2:focus{
    color:#ffffff
  }

  .md\:focus\:text-bg3:focus{
    color:#F7F7FA
  }

  .md\:focus\:text-gray1:focus{
    color:#333333
  }

  .md\:focus\:text-gray2:focus{
    color:#4D4D4D
  }

  .md\:focus\:text-gray3:focus{
    color:#8C8C8C
  }

  .md\:focus\:text-gray4:focus{
    color:#BFBFBF
  }

  .md\:focus\:text-gray5:focus{
    color:#D9D9D9
  }

  .md\:focus\:text-gray6:focus{
    color:#E9E9EB
  }

  .md\:focus\:text-gray7:focus{
    color:#515164
  }

  .md\:focus\:text-white:focus{
    color:#ffffff
  }

  .md\:focus\:text-warning:focus{
    color:#FFC319
  }

  .md\:focus\:text-error:focus{
    color:#EA696F
  }

  .md\:focus\:text-info:focus{
    color:#0071B9
  }

  .md\:focus\:text-success:focus{
    color:#98D45F
  }

  .md\:focus\:text-special4:focus{
    color:#59779A
  }

  .md\:focus\:text-dark:focus{
    color:#000000
  }

  .md\:focus\:text-transparent:focus{
    color:transparent
  }

  .md\:focus\:text-secondary1:focus{
    color:#313140
  }

  .md\:focus\:text-secondary2:focus{
    color:#515164
  }

  .md\:focus\:text-secondary3:focus{
    color:#9C9CAC
  }

  .md\:focus\:text-secondary4:focus{
    color:#E5E8ED
  }

  .md\:focus\:text-secondary5:focus{
    color:#F5F6F9
  }

  .md\:focus\:text-green1:focus{
    color:#0DE298
  }

  .md\:underline{
    text-decoration:underline
  }

  .md\:line-through{
    text-decoration:line-through
  }

  .md\:no-underline{
    text-decoration:none
  }

  .group:hover .md\:group-hover\:underline{
    text-decoration:underline
  }

  .group:hover .md\:group-hover\:line-through{
    text-decoration:line-through
  }

  .group:hover .md\:group-hover\:no-underline{
    text-decoration:none
  }

  .md\:focus-within\:underline:focus-within{
    text-decoration:underline
  }

  .md\:focus-within\:line-through:focus-within{
    text-decoration:line-through
  }

  .md\:focus-within\:no-underline:focus-within{
    text-decoration:none
  }

  .md\:hover\:underline:hover{
    text-decoration:underline
  }

  .md\:hover\:line-through:hover{
    text-decoration:line-through
  }

  .md\:hover\:no-underline:hover{
    text-decoration:none
  }

  .md\:focus\:underline:focus{
    text-decoration:underline
  }

  .md\:focus\:line-through:focus{
    text-decoration:line-through
  }

  .md\:focus\:no-underline:focus{
    text-decoration:none
  }

  .md\:placeholder-red1::placeholder{
    color:#CC352C
  }

  .md\:placeholder-red2::placeholder{
    color:#FF554B
  }

  .md\:placeholder-red3::placeholder{
    color:#FF7B73
  }

  .md\:placeholder-red4::placeholder{
    color:#FFF1F1
  }

  .md\:placeholder-red5::placeholder{
    color:#FFF6F6
  }

  .md\:placeholder-cyan1::placeholder{
    color:#009897
  }

  .md\:placeholder-cyan2::placeholder{
    color:#00BFBD
  }

  .md\:placeholder-cyan3::placeholder{
    color:#4CD1D0
  }

  .md\:placeholder-cyan4::placeholder{
    color:#B2ECEB
  }

  .md\:placeholder-cyan5::placeholder{
    color:#EDFAFA
  }

  .md\:placeholder-bg1::placeholder{
    color:#FAFAFC
  }

  .md\:placeholder-bg2::placeholder{
    color:#ffffff
  }

  .md\:placeholder-bg3::placeholder{
    color:#F7F7FA
  }

  .md\:placeholder-gray1::placeholder{
    color:#333333
  }

  .md\:placeholder-gray2::placeholder{
    color:#4D4D4D
  }

  .md\:placeholder-gray3::placeholder{
    color:#8C8C8C
  }

  .md\:placeholder-gray4::placeholder{
    color:#BFBFBF
  }

  .md\:placeholder-gray5::placeholder{
    color:#D9D9D9
  }

  .md\:placeholder-gray6::placeholder{
    color:#E9E9EB
  }

  .md\:placeholder-gray7::placeholder{
    color:#515164
  }

  .md\:placeholder-white::placeholder{
    color:#ffffff
  }

  .md\:placeholder-warning::placeholder{
    color:#FFC319
  }

  .md\:placeholder-error::placeholder{
    color:#EA696F
  }

  .md\:placeholder-info::placeholder{
    color:#0071B9
  }

  .md\:placeholder-success::placeholder{
    color:#98D45F
  }

  .md\:placeholder-special4::placeholder{
    color:#59779A
  }

  .md\:placeholder-dark::placeholder{
    color:#000000
  }

  .md\:placeholder-transparent::placeholder{
    color:transparent
  }

  .md\:placeholder-secondary1::placeholder{
    color:#313140
  }

  .md\:placeholder-secondary2::placeholder{
    color:#515164
  }

  .md\:placeholder-secondary3::placeholder{
    color:#9C9CAC
  }

  .md\:placeholder-secondary4::placeholder{
    color:#E5E8ED
  }

  .md\:placeholder-secondary5::placeholder{
    color:#F5F6F9
  }

  .md\:placeholder-green1::placeholder{
    color:#0DE298
  }

  .md\:focus\:placeholder-red1:focus::placeholder{
    color:#CC352C
  }

  .md\:focus\:placeholder-red2:focus::placeholder{
    color:#FF554B
  }

  .md\:focus\:placeholder-red3:focus::placeholder{
    color:#FF7B73
  }

  .md\:focus\:placeholder-red4:focus::placeholder{
    color:#FFF1F1
  }

  .md\:focus\:placeholder-red5:focus::placeholder{
    color:#FFF6F6
  }

  .md\:focus\:placeholder-cyan1:focus::placeholder{
    color:#009897
  }

  .md\:focus\:placeholder-cyan2:focus::placeholder{
    color:#00BFBD
  }

  .md\:focus\:placeholder-cyan3:focus::placeholder{
    color:#4CD1D0
  }

  .md\:focus\:placeholder-cyan4:focus::placeholder{
    color:#B2ECEB
  }

  .md\:focus\:placeholder-cyan5:focus::placeholder{
    color:#EDFAFA
  }

  .md\:focus\:placeholder-bg1:focus::placeholder{
    color:#FAFAFC
  }

  .md\:focus\:placeholder-bg2:focus::placeholder{
    color:#ffffff
  }

  .md\:focus\:placeholder-bg3:focus::placeholder{
    color:#F7F7FA
  }

  .md\:focus\:placeholder-gray1:focus::placeholder{
    color:#333333
  }

  .md\:focus\:placeholder-gray2:focus::placeholder{
    color:#4D4D4D
  }

  .md\:focus\:placeholder-gray3:focus::placeholder{
    color:#8C8C8C
  }

  .md\:focus\:placeholder-gray4:focus::placeholder{
    color:#BFBFBF
  }

  .md\:focus\:placeholder-gray5:focus::placeholder{
    color:#D9D9D9
  }

  .md\:focus\:placeholder-gray6:focus::placeholder{
    color:#E9E9EB
  }

  .md\:focus\:placeholder-gray7:focus::placeholder{
    color:#515164
  }

  .md\:focus\:placeholder-white:focus::placeholder{
    color:#ffffff
  }

  .md\:focus\:placeholder-warning:focus::placeholder{
    color:#FFC319
  }

  .md\:focus\:placeholder-error:focus::placeholder{
    color:#EA696F
  }

  .md\:focus\:placeholder-info:focus::placeholder{
    color:#0071B9
  }

  .md\:focus\:placeholder-success:focus::placeholder{
    color:#98D45F
  }

  .md\:focus\:placeholder-special4:focus::placeholder{
    color:#59779A
  }

  .md\:focus\:placeholder-dark:focus::placeholder{
    color:#000000
  }

  .md\:focus\:placeholder-transparent:focus::placeholder{
    color:transparent
  }

  .md\:focus\:placeholder-secondary1:focus::placeholder{
    color:#313140
  }

  .md\:focus\:placeholder-secondary2:focus::placeholder{
    color:#515164
  }

  .md\:focus\:placeholder-secondary3:focus::placeholder{
    color:#9C9CAC
  }

  .md\:focus\:placeholder-secondary4:focus::placeholder{
    color:#E5E8ED
  }

  .md\:focus\:placeholder-secondary5:focus::placeholder{
    color:#F5F6F9
  }

  .md\:focus\:placeholder-green1:focus::placeholder{
    color:#0DE298
  }

  .md\:opacity-0{
    opacity:0
  }

  .md\:opacity-5{
    opacity:0.05
  }

  .md\:opacity-10{
    opacity:0.1
  }

  .md\:opacity-20{
    opacity:0.2
  }

  .md\:opacity-25{
    opacity:0.25
  }

  .md\:opacity-30{
    opacity:0.3
  }

  .md\:opacity-40{
    opacity:0.4
  }

  .md\:opacity-50{
    opacity:0.5
  }

  .md\:opacity-60{
    opacity:0.6
  }

  .md\:opacity-70{
    opacity:0.7
  }

  .md\:opacity-75{
    opacity:0.75
  }

  .md\:opacity-80{
    opacity:0.8
  }

  .md\:opacity-90{
    opacity:0.9
  }

  .md\:opacity-95{
    opacity:0.95
  }

  .md\:opacity-100{
    opacity:1
  }

  .group:hover .md\:group-hover\:opacity-0{
    opacity:0
  }

  .group:hover .md\:group-hover\:opacity-5{
    opacity:0.05
  }

  .group:hover .md\:group-hover\:opacity-10{
    opacity:0.1
  }

  .group:hover .md\:group-hover\:opacity-20{
    opacity:0.2
  }

  .group:hover .md\:group-hover\:opacity-25{
    opacity:0.25
  }

  .group:hover .md\:group-hover\:opacity-30{
    opacity:0.3
  }

  .group:hover .md\:group-hover\:opacity-40{
    opacity:0.4
  }

  .group:hover .md\:group-hover\:opacity-50{
    opacity:0.5
  }

  .group:hover .md\:group-hover\:opacity-60{
    opacity:0.6
  }

  .group:hover .md\:group-hover\:opacity-70{
    opacity:0.7
  }

  .group:hover .md\:group-hover\:opacity-75{
    opacity:0.75
  }

  .group:hover .md\:group-hover\:opacity-80{
    opacity:0.8
  }

  .group:hover .md\:group-hover\:opacity-90{
    opacity:0.9
  }

  .group:hover .md\:group-hover\:opacity-95{
    opacity:0.95
  }

  .group:hover .md\:group-hover\:opacity-100{
    opacity:1
  }

  .md\:focus-within\:opacity-0:focus-within{
    opacity:0
  }

  .md\:focus-within\:opacity-5:focus-within{
    opacity:0.05
  }

  .md\:focus-within\:opacity-10:focus-within{
    opacity:0.1
  }

  .md\:focus-within\:opacity-20:focus-within{
    opacity:0.2
  }

  .md\:focus-within\:opacity-25:focus-within{
    opacity:0.25
  }

  .md\:focus-within\:opacity-30:focus-within{
    opacity:0.3
  }

  .md\:focus-within\:opacity-40:focus-within{
    opacity:0.4
  }

  .md\:focus-within\:opacity-50:focus-within{
    opacity:0.5
  }

  .md\:focus-within\:opacity-60:focus-within{
    opacity:0.6
  }

  .md\:focus-within\:opacity-70:focus-within{
    opacity:0.7
  }

  .md\:focus-within\:opacity-75:focus-within{
    opacity:0.75
  }

  .md\:focus-within\:opacity-80:focus-within{
    opacity:0.8
  }

  .md\:focus-within\:opacity-90:focus-within{
    opacity:0.9
  }

  .md\:focus-within\:opacity-95:focus-within{
    opacity:0.95
  }

  .md\:focus-within\:opacity-100:focus-within{
    opacity:1
  }

  .md\:hover\:opacity-0:hover{
    opacity:0
  }

  .md\:hover\:opacity-5:hover{
    opacity:0.05
  }

  .md\:hover\:opacity-10:hover{
    opacity:0.1
  }

  .md\:hover\:opacity-20:hover{
    opacity:0.2
  }

  .md\:hover\:opacity-25:hover{
    opacity:0.25
  }

  .md\:hover\:opacity-30:hover{
    opacity:0.3
  }

  .md\:hover\:opacity-40:hover{
    opacity:0.4
  }

  .md\:hover\:opacity-50:hover{
    opacity:0.5
  }

  .md\:hover\:opacity-60:hover{
    opacity:0.6
  }

  .md\:hover\:opacity-70:hover{
    opacity:0.7
  }

  .md\:hover\:opacity-75:hover{
    opacity:0.75
  }

  .md\:hover\:opacity-80:hover{
    opacity:0.8
  }

  .md\:hover\:opacity-90:hover{
    opacity:0.9
  }

  .md\:hover\:opacity-95:hover{
    opacity:0.95
  }

  .md\:hover\:opacity-100:hover{
    opacity:1
  }

  .md\:focus\:opacity-0:focus{
    opacity:0
  }

  .md\:focus\:opacity-5:focus{
    opacity:0.05
  }

  .md\:focus\:opacity-10:focus{
    opacity:0.1
  }

  .md\:focus\:opacity-20:focus{
    opacity:0.2
  }

  .md\:focus\:opacity-25:focus{
    opacity:0.25
  }

  .md\:focus\:opacity-30:focus{
    opacity:0.3
  }

  .md\:focus\:opacity-40:focus{
    opacity:0.4
  }

  .md\:focus\:opacity-50:focus{
    opacity:0.5
  }

  .md\:focus\:opacity-60:focus{
    opacity:0.6
  }

  .md\:focus\:opacity-70:focus{
    opacity:0.7
  }

  .md\:focus\:opacity-75:focus{
    opacity:0.75
  }

  .md\:focus\:opacity-80:focus{
    opacity:0.8
  }

  .md\:focus\:opacity-90:focus{
    opacity:0.9
  }

  .md\:focus\:opacity-95:focus{
    opacity:0.95
  }

  .md\:focus\:opacity-100:focus{
    opacity:1
  }

  .md\:bg-blend-normal{
    background-blend-mode:normal
  }

  .md\:bg-blend-multiply{
    background-blend-mode:multiply
  }

  .md\:bg-blend-screen{
    background-blend-mode:screen
  }

  .md\:bg-blend-overlay{
    background-blend-mode:overlay
  }

  .md\:bg-blend-darken{
    background-blend-mode:darken
  }

  .md\:bg-blend-lighten{
    background-blend-mode:lighten
  }

  .md\:bg-blend-color-dodge{
    background-blend-mode:color-dodge
  }

  .md\:bg-blend-color-burn{
    background-blend-mode:color-burn
  }

  .md\:bg-blend-hard-light{
    background-blend-mode:hard-light
  }

  .md\:bg-blend-soft-light{
    background-blend-mode:soft-light
  }

  .md\:bg-blend-difference{
    background-blend-mode:difference
  }

  .md\:bg-blend-exclusion{
    background-blend-mode:exclusion
  }

  .md\:bg-blend-hue{
    background-blend-mode:hue
  }

  .md\:bg-blend-saturation{
    background-blend-mode:saturation
  }

  .md\:bg-blend-color{
    background-blend-mode:color
  }

  .md\:bg-blend-luminosity{
    background-blend-mode:luminosity
  }

  .md\:mix-blend-normal{
    mix-blend-mode:normal
  }

  .md\:mix-blend-multiply{
    mix-blend-mode:multiply
  }

  .md\:mix-blend-screen{
    mix-blend-mode:screen
  }

  .md\:mix-blend-overlay{
    mix-blend-mode:overlay
  }

  .md\:mix-blend-darken{
    mix-blend-mode:darken
  }

  .md\:mix-blend-lighten{
    mix-blend-mode:lighten
  }

  .md\:mix-blend-color-dodge{
    mix-blend-mode:color-dodge
  }

  .md\:mix-blend-color-burn{
    mix-blend-mode:color-burn
  }

  .md\:mix-blend-hard-light{
    mix-blend-mode:hard-light
  }

  .md\:mix-blend-soft-light{
    mix-blend-mode:soft-light
  }

  .md\:mix-blend-difference{
    mix-blend-mode:difference
  }

  .md\:mix-blend-exclusion{
    mix-blend-mode:exclusion
  }

  .md\:mix-blend-hue{
    mix-blend-mode:hue
  }

  .md\:mix-blend-saturation{
    mix-blend-mode:saturation
  }

  .md\:mix-blend-color{
    mix-blend-mode:color
  }

  .md\:mix-blend-luminosity{
    mix-blend-mode:luminosity
  }

  .md\:shadow-panel{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-popup{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-panel{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .md\:group-hover\:shadow-popup{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-panel:focus-within{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-hover:focus-within{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus-within\:shadow-popup:focus-within{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-panel:hover{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-hover:hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:hover\:shadow-popup:hover{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-panel:focus{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-hover:focus{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:focus\:shadow-popup:focus{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:outline-none{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .md\:outline-white{
    outline:2px dotted white;
    outline-offset:2px
  }

  .md\:outline-black{
    outline:2px dotted black;
    outline-offset:2px
  }

  .md\:focus-within\:outline-none:focus-within{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .md\:focus-within\:outline-white:focus-within{
    outline:2px dotted white;
    outline-offset:2px
  }

  .md\:focus-within\:outline-black:focus-within{
    outline:2px dotted black;
    outline-offset:2px
  }

  .md\:focus\:outline-none:focus{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .md\:focus\:outline-white:focus{
    outline:2px dotted white;
    outline-offset:2px
  }

  .md\:focus\:outline-black:focus{
    outline:2px dotted black;
    outline-offset:2px
  }

  .md\:ring-0{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring-1{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring-2{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring-4{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring-8{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring-0:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring-1:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring-4:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring-8:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus-within\:ring:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring-0:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring-1:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring-2:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring-4:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring-8:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:focus\:ring:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .md\:ring-inset{
    --tw-ring-inset:inset
  }

  .md\:focus-within\:ring-inset:focus-within{
    --tw-ring-inset:inset
  }

  .md\:focus\:ring-inset:focus{
    --tw-ring-inset:inset
  }

  .md\:ring-red1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .md\:ring-red2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .md\:ring-red3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .md\:ring-red4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .md\:ring-red5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .md\:ring-cyan1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .md\:ring-cyan2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .md\:ring-cyan3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .md\:ring-cyan4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .md\:ring-cyan5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .md\:ring-bg1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .md\:ring-bg2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .md\:ring-bg3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .md\:ring-gray1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .md\:ring-gray2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .md\:ring-gray3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .md\:ring-gray4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .md\:ring-gray5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .md\:ring-gray6{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .md\:ring-gray7{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .md\:ring-white{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .md\:ring-warning{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .md\:ring-error{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .md\:ring-info{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .md\:ring-success{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .md\:ring-special4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .md\:ring-dark{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .md\:ring-transparent{
    --tw-ring-color:transparent
  }

  .md\:ring-secondary1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .md\:ring-secondary2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .md\:ring-secondary3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .md\:ring-secondary4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .md\:ring-secondary5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .md\:ring-green1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-red5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-cyan5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-bg1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-bg2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-bg3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray6:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-gray7:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-white:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-warning:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-error:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-info:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-success:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-special4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-dark:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-transparent:focus-within{
    --tw-ring-color:transparent
  }

  .md\:focus-within\:ring-secondary1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-secondary2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-secondary3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-secondary4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-secondary5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .md\:focus-within\:ring-green1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-red5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-cyan5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-bg1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-bg2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-bg3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray6:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-gray7:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-white:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-warning:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-error:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-info:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-success:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-special4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-dark:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-transparent:focus{
    --tw-ring-color:transparent
  }

  .md\:focus\:ring-secondary1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-secondary2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-secondary3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-secondary4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-secondary5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .md\:focus\:ring-green1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .md\:ring-opacity-0{
    --tw-ring-opacity:0
  }

  .md\:ring-opacity-5{
    --tw-ring-opacity:0.05
  }

  .md\:ring-opacity-10{
    --tw-ring-opacity:0.1
  }

  .md\:ring-opacity-20{
    --tw-ring-opacity:0.2
  }

  .md\:ring-opacity-25{
    --tw-ring-opacity:0.25
  }

  .md\:ring-opacity-30{
    --tw-ring-opacity:0.3
  }

  .md\:ring-opacity-40{
    --tw-ring-opacity:0.4
  }

  .md\:ring-opacity-50{
    --tw-ring-opacity:0.5
  }

  .md\:ring-opacity-60{
    --tw-ring-opacity:0.6
  }

  .md\:ring-opacity-70{
    --tw-ring-opacity:0.7
  }

  .md\:ring-opacity-75{
    --tw-ring-opacity:0.75
  }

  .md\:ring-opacity-80{
    --tw-ring-opacity:0.8
  }

  .md\:ring-opacity-90{
    --tw-ring-opacity:0.9
  }

  .md\:ring-opacity-95{
    --tw-ring-opacity:0.95
  }

  .md\:ring-opacity-100{
    --tw-ring-opacity:1
  }

  .md\:focus-within\:ring-opacity-0:focus-within{
    --tw-ring-opacity:0
  }

  .md\:focus-within\:ring-opacity-5:focus-within{
    --tw-ring-opacity:0.05
  }

  .md\:focus-within\:ring-opacity-10:focus-within{
    --tw-ring-opacity:0.1
  }

  .md\:focus-within\:ring-opacity-20:focus-within{
    --tw-ring-opacity:0.2
  }

  .md\:focus-within\:ring-opacity-25:focus-within{
    --tw-ring-opacity:0.25
  }

  .md\:focus-within\:ring-opacity-30:focus-within{
    --tw-ring-opacity:0.3
  }

  .md\:focus-within\:ring-opacity-40:focus-within{
    --tw-ring-opacity:0.4
  }

  .md\:focus-within\:ring-opacity-50:focus-within{
    --tw-ring-opacity:0.5
  }

  .md\:focus-within\:ring-opacity-60:focus-within{
    --tw-ring-opacity:0.6
  }

  .md\:focus-within\:ring-opacity-70:focus-within{
    --tw-ring-opacity:0.7
  }

  .md\:focus-within\:ring-opacity-75:focus-within{
    --tw-ring-opacity:0.75
  }

  .md\:focus-within\:ring-opacity-80:focus-within{
    --tw-ring-opacity:0.8
  }

  .md\:focus-within\:ring-opacity-90:focus-within{
    --tw-ring-opacity:0.9
  }

  .md\:focus-within\:ring-opacity-95:focus-within{
    --tw-ring-opacity:0.95
  }

  .md\:focus-within\:ring-opacity-100:focus-within{
    --tw-ring-opacity:1
  }

  .md\:focus\:ring-opacity-0:focus{
    --tw-ring-opacity:0
  }

  .md\:focus\:ring-opacity-5:focus{
    --tw-ring-opacity:0.05
  }

  .md\:focus\:ring-opacity-10:focus{
    --tw-ring-opacity:0.1
  }

  .md\:focus\:ring-opacity-20:focus{
    --tw-ring-opacity:0.2
  }

  .md\:focus\:ring-opacity-25:focus{
    --tw-ring-opacity:0.25
  }

  .md\:focus\:ring-opacity-30:focus{
    --tw-ring-opacity:0.3
  }

  .md\:focus\:ring-opacity-40:focus{
    --tw-ring-opacity:0.4
  }

  .md\:focus\:ring-opacity-50:focus{
    --tw-ring-opacity:0.5
  }

  .md\:focus\:ring-opacity-60:focus{
    --tw-ring-opacity:0.6
  }

  .md\:focus\:ring-opacity-70:focus{
    --tw-ring-opacity:0.7
  }

  .md\:focus\:ring-opacity-75:focus{
    --tw-ring-opacity:0.75
  }

  .md\:focus\:ring-opacity-80:focus{
    --tw-ring-opacity:0.8
  }

  .md\:focus\:ring-opacity-90:focus{
    --tw-ring-opacity:0.9
  }

  .md\:focus\:ring-opacity-95:focus{
    --tw-ring-opacity:0.95
  }

  .md\:focus\:ring-opacity-100:focus{
    --tw-ring-opacity:1
  }

  .md\:ring-offset-0{
    --tw-ring-offset-width:0px
  }

  .md\:ring-offset-1{
    --tw-ring-offset-width:1px
  }

  .md\:ring-offset-2{
    --tw-ring-offset-width:2px
  }

  .md\:ring-offset-4{
    --tw-ring-offset-width:4px
  }

  .md\:ring-offset-8{
    --tw-ring-offset-width:8px
  }

  .md\:focus-within\:ring-offset-0:focus-within{
    --tw-ring-offset-width:0px
  }

  .md\:focus-within\:ring-offset-1:focus-within{
    --tw-ring-offset-width:1px
  }

  .md\:focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width:2px
  }

  .md\:focus-within\:ring-offset-4:focus-within{
    --tw-ring-offset-width:4px
  }

  .md\:focus-within\:ring-offset-8:focus-within{
    --tw-ring-offset-width:8px
  }

  .md\:focus\:ring-offset-0:focus{
    --tw-ring-offset-width:0px
  }

  .md\:focus\:ring-offset-1:focus{
    --tw-ring-offset-width:1px
  }

  .md\:focus\:ring-offset-2:focus{
    --tw-ring-offset-width:2px
  }

  .md\:focus\:ring-offset-4:focus{
    --tw-ring-offset-width:4px
  }

  .md\:focus\:ring-offset-8:focus{
    --tw-ring-offset-width:8px
  }

  .md\:ring-offset-red1{
    --tw-ring-offset-color:#CC352C
  }

  .md\:ring-offset-red2{
    --tw-ring-offset-color:#FF554B
  }

  .md\:ring-offset-red3{
    --tw-ring-offset-color:#FF7B73
  }

  .md\:ring-offset-red4{
    --tw-ring-offset-color:#FFF1F1
  }

  .md\:ring-offset-red5{
    --tw-ring-offset-color:#FFF6F6
  }

  .md\:ring-offset-cyan1{
    --tw-ring-offset-color:#009897
  }

  .md\:ring-offset-cyan2{
    --tw-ring-offset-color:#00BFBD
  }

  .md\:ring-offset-cyan3{
    --tw-ring-offset-color:#4CD1D0
  }

  .md\:ring-offset-cyan4{
    --tw-ring-offset-color:#B2ECEB
  }

  .md\:ring-offset-cyan5{
    --tw-ring-offset-color:#EDFAFA
  }

  .md\:ring-offset-bg1{
    --tw-ring-offset-color:#FAFAFC
  }

  .md\:ring-offset-bg2{
    --tw-ring-offset-color:#ffffff
  }

  .md\:ring-offset-bg3{
    --tw-ring-offset-color:#F7F7FA
  }

  .md\:ring-offset-gray1{
    --tw-ring-offset-color:#333333
  }

  .md\:ring-offset-gray2{
    --tw-ring-offset-color:#4D4D4D
  }

  .md\:ring-offset-gray3{
    --tw-ring-offset-color:#8C8C8C
  }

  .md\:ring-offset-gray4{
    --tw-ring-offset-color:#BFBFBF
  }

  .md\:ring-offset-gray5{
    --tw-ring-offset-color:#D9D9D9
  }

  .md\:ring-offset-gray6{
    --tw-ring-offset-color:#E9E9EB
  }

  .md\:ring-offset-gray7{
    --tw-ring-offset-color:#515164
  }

  .md\:ring-offset-white{
    --tw-ring-offset-color:#ffffff
  }

  .md\:ring-offset-warning{
    --tw-ring-offset-color:#FFC319
  }

  .md\:ring-offset-error{
    --tw-ring-offset-color:#EA696F
  }

  .md\:ring-offset-info{
    --tw-ring-offset-color:#0071B9
  }

  .md\:ring-offset-success{
    --tw-ring-offset-color:#98D45F
  }

  .md\:ring-offset-special4{
    --tw-ring-offset-color:#59779A
  }

  .md\:ring-offset-dark{
    --tw-ring-offset-color:#000000
  }

  .md\:ring-offset-transparent{
    --tw-ring-offset-color:transparent
  }

  .md\:ring-offset-secondary1{
    --tw-ring-offset-color:#313140
  }

  .md\:ring-offset-secondary2{
    --tw-ring-offset-color:#515164
  }

  .md\:ring-offset-secondary3{
    --tw-ring-offset-color:#9C9CAC
  }

  .md\:ring-offset-secondary4{
    --tw-ring-offset-color:#E5E8ED
  }

  .md\:ring-offset-secondary5{
    --tw-ring-offset-color:#F5F6F9
  }

  .md\:ring-offset-green1{
    --tw-ring-offset-color:#0DE298
  }

  .md\:focus-within\:ring-offset-red1:focus-within{
    --tw-ring-offset-color:#CC352C
  }

  .md\:focus-within\:ring-offset-red2:focus-within{
    --tw-ring-offset-color:#FF554B
  }

  .md\:focus-within\:ring-offset-red3:focus-within{
    --tw-ring-offset-color:#FF7B73
  }

  .md\:focus-within\:ring-offset-red4:focus-within{
    --tw-ring-offset-color:#FFF1F1
  }

  .md\:focus-within\:ring-offset-red5:focus-within{
    --tw-ring-offset-color:#FFF6F6
  }

  .md\:focus-within\:ring-offset-cyan1:focus-within{
    --tw-ring-offset-color:#009897
  }

  .md\:focus-within\:ring-offset-cyan2:focus-within{
    --tw-ring-offset-color:#00BFBD
  }

  .md\:focus-within\:ring-offset-cyan3:focus-within{
    --tw-ring-offset-color:#4CD1D0
  }

  .md\:focus-within\:ring-offset-cyan4:focus-within{
    --tw-ring-offset-color:#B2ECEB
  }

  .md\:focus-within\:ring-offset-cyan5:focus-within{
    --tw-ring-offset-color:#EDFAFA
  }

  .md\:focus-within\:ring-offset-bg1:focus-within{
    --tw-ring-offset-color:#FAFAFC
  }

  .md\:focus-within\:ring-offset-bg2:focus-within{
    --tw-ring-offset-color:#ffffff
  }

  .md\:focus-within\:ring-offset-bg3:focus-within{
    --tw-ring-offset-color:#F7F7FA
  }

  .md\:focus-within\:ring-offset-gray1:focus-within{
    --tw-ring-offset-color:#333333
  }

  .md\:focus-within\:ring-offset-gray2:focus-within{
    --tw-ring-offset-color:#4D4D4D
  }

  .md\:focus-within\:ring-offset-gray3:focus-within{
    --tw-ring-offset-color:#8C8C8C
  }

  .md\:focus-within\:ring-offset-gray4:focus-within{
    --tw-ring-offset-color:#BFBFBF
  }

  .md\:focus-within\:ring-offset-gray5:focus-within{
    --tw-ring-offset-color:#D9D9D9
  }

  .md\:focus-within\:ring-offset-gray6:focus-within{
    --tw-ring-offset-color:#E9E9EB
  }

  .md\:focus-within\:ring-offset-gray7:focus-within{
    --tw-ring-offset-color:#515164
  }

  .md\:focus-within\:ring-offset-white:focus-within{
    --tw-ring-offset-color:#ffffff
  }

  .md\:focus-within\:ring-offset-warning:focus-within{
    --tw-ring-offset-color:#FFC319
  }

  .md\:focus-within\:ring-offset-error:focus-within{
    --tw-ring-offset-color:#EA696F
  }

  .md\:focus-within\:ring-offset-info:focus-within{
    --tw-ring-offset-color:#0071B9
  }

  .md\:focus-within\:ring-offset-success:focus-within{
    --tw-ring-offset-color:#98D45F
  }

  .md\:focus-within\:ring-offset-special4:focus-within{
    --tw-ring-offset-color:#59779A
  }

  .md\:focus-within\:ring-offset-dark:focus-within{
    --tw-ring-offset-color:#000000
  }

  .md\:focus-within\:ring-offset-transparent:focus-within{
    --tw-ring-offset-color:transparent
  }

  .md\:focus-within\:ring-offset-secondary1:focus-within{
    --tw-ring-offset-color:#313140
  }

  .md\:focus-within\:ring-offset-secondary2:focus-within{
    --tw-ring-offset-color:#515164
  }

  .md\:focus-within\:ring-offset-secondary3:focus-within{
    --tw-ring-offset-color:#9C9CAC
  }

  .md\:focus-within\:ring-offset-secondary4:focus-within{
    --tw-ring-offset-color:#E5E8ED
  }

  .md\:focus-within\:ring-offset-secondary5:focus-within{
    --tw-ring-offset-color:#F5F6F9
  }

  .md\:focus-within\:ring-offset-green1:focus-within{
    --tw-ring-offset-color:#0DE298
  }

  .md\:focus\:ring-offset-red1:focus{
    --tw-ring-offset-color:#CC352C
  }

  .md\:focus\:ring-offset-red2:focus{
    --tw-ring-offset-color:#FF554B
  }

  .md\:focus\:ring-offset-red3:focus{
    --tw-ring-offset-color:#FF7B73
  }

  .md\:focus\:ring-offset-red4:focus{
    --tw-ring-offset-color:#FFF1F1
  }

  .md\:focus\:ring-offset-red5:focus{
    --tw-ring-offset-color:#FFF6F6
  }

  .md\:focus\:ring-offset-cyan1:focus{
    --tw-ring-offset-color:#009897
  }

  .md\:focus\:ring-offset-cyan2:focus{
    --tw-ring-offset-color:#00BFBD
  }

  .md\:focus\:ring-offset-cyan3:focus{
    --tw-ring-offset-color:#4CD1D0
  }

  .md\:focus\:ring-offset-cyan4:focus{
    --tw-ring-offset-color:#B2ECEB
  }

  .md\:focus\:ring-offset-cyan5:focus{
    --tw-ring-offset-color:#EDFAFA
  }

  .md\:focus\:ring-offset-bg1:focus{
    --tw-ring-offset-color:#FAFAFC
  }

  .md\:focus\:ring-offset-bg2:focus{
    --tw-ring-offset-color:#ffffff
  }

  .md\:focus\:ring-offset-bg3:focus{
    --tw-ring-offset-color:#F7F7FA
  }

  .md\:focus\:ring-offset-gray1:focus{
    --tw-ring-offset-color:#333333
  }

  .md\:focus\:ring-offset-gray2:focus{
    --tw-ring-offset-color:#4D4D4D
  }

  .md\:focus\:ring-offset-gray3:focus{
    --tw-ring-offset-color:#8C8C8C
  }

  .md\:focus\:ring-offset-gray4:focus{
    --tw-ring-offset-color:#BFBFBF
  }

  .md\:focus\:ring-offset-gray5:focus{
    --tw-ring-offset-color:#D9D9D9
  }

  .md\:focus\:ring-offset-gray6:focus{
    --tw-ring-offset-color:#E9E9EB
  }

  .md\:focus\:ring-offset-gray7:focus{
    --tw-ring-offset-color:#515164
  }

  .md\:focus\:ring-offset-white:focus{
    --tw-ring-offset-color:#ffffff
  }

  .md\:focus\:ring-offset-warning:focus{
    --tw-ring-offset-color:#FFC319
  }

  .md\:focus\:ring-offset-error:focus{
    --tw-ring-offset-color:#EA696F
  }

  .md\:focus\:ring-offset-info:focus{
    --tw-ring-offset-color:#0071B9
  }

  .md\:focus\:ring-offset-success:focus{
    --tw-ring-offset-color:#98D45F
  }

  .md\:focus\:ring-offset-special4:focus{
    --tw-ring-offset-color:#59779A
  }

  .md\:focus\:ring-offset-dark:focus{
    --tw-ring-offset-color:#000000
  }

  .md\:focus\:ring-offset-transparent:focus{
    --tw-ring-offset-color:transparent
  }

  .md\:focus\:ring-offset-secondary1:focus{
    --tw-ring-offset-color:#313140
  }

  .md\:focus\:ring-offset-secondary2:focus{
    --tw-ring-offset-color:#515164
  }

  .md\:focus\:ring-offset-secondary3:focus{
    --tw-ring-offset-color:#9C9CAC
  }

  .md\:focus\:ring-offset-secondary4:focus{
    --tw-ring-offset-color:#E5E8ED
  }

  .md\:focus\:ring-offset-secondary5:focus{
    --tw-ring-offset-color:#F5F6F9
  }

  .md\:focus\:ring-offset-green1:focus{
    --tw-ring-offset-color:#0DE298
  }

  .md\:filter{
    --tw-blur:var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness:var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast:var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale:var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
    --tw-invert:var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate:var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia:var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow:var(--tw-empty,/*!*/ /*!*/);
    filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }

  .md\:filter-none{
    filter:none
  }

  .md\:blur-0{
    --tw-blur:blur(0)
  }

  .md\:blur-none{
    --tw-blur:blur(0)
  }

  .md\:blur-sm{
    --tw-blur:blur(4px)
  }

  .md\:blur{
    --tw-blur:blur(8px)
  }

  .md\:blur-md{
    --tw-blur:blur(12px)
  }

  .md\:blur-lg{
    --tw-blur:blur(16px)
  }

  .md\:blur-xl{
    --tw-blur:blur(24px)
  }

  .md\:blur-2xl{
    --tw-blur:blur(40px)
  }

  .md\:blur-3xl{
    --tw-blur:blur(64px)
  }

  .md\:brightness-0{
    --tw-brightness:brightness(0)
  }

  .md\:brightness-50{
    --tw-brightness:brightness(.5)
  }

  .md\:brightness-75{
    --tw-brightness:brightness(.75)
  }

  .md\:brightness-90{
    --tw-brightness:brightness(.9)
  }

  .md\:brightness-95{
    --tw-brightness:brightness(.95)
  }

  .md\:brightness-100{
    --tw-brightness:brightness(1)
  }

  .md\:brightness-105{
    --tw-brightness:brightness(1.05)
  }

  .md\:brightness-110{
    --tw-brightness:brightness(1.1)
  }

  .md\:brightness-125{
    --tw-brightness:brightness(1.25)
  }

  .md\:brightness-150{
    --tw-brightness:brightness(1.5)
  }

  .md\:brightness-200{
    --tw-brightness:brightness(2)
  }

  .md\:contrast-0{
    --tw-contrast:contrast(0)
  }

  .md\:contrast-50{
    --tw-contrast:contrast(.5)
  }

  .md\:contrast-75{
    --tw-contrast:contrast(.75)
  }

  .md\:contrast-100{
    --tw-contrast:contrast(1)
  }

  .md\:contrast-125{
    --tw-contrast:contrast(1.25)
  }

  .md\:contrast-150{
    --tw-contrast:contrast(1.5)
  }

  .md\:contrast-200{
    --tw-contrast:contrast(2)
  }

  .md\:drop-shadow-sm{
    --tw-drop-shadow:drop-shadow(0 1px 1px rgba(0,0,0,0.05))
  }

  .md\:drop-shadow{
    --tw-drop-shadow:drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
  }

  .md\:drop-shadow-md{
    --tw-drop-shadow:drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
  }

  .md\:drop-shadow-lg{
    --tw-drop-shadow:drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
  }

  .md\:drop-shadow-xl{
    --tw-drop-shadow:drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
  }

  .md\:drop-shadow-2xl{
    --tw-drop-shadow:drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
  }

  .md\:drop-shadow-none{
    --tw-drop-shadow:drop-shadow(0 0 #0000)
  }

  .md\:grayscale-0{
    --tw-grayscale:grayscale(0)
  }

  .md\:grayscale{
    --tw-grayscale:grayscale(100%)
  }

  .md\:hue-rotate-0{
    --tw-hue-rotate:hue-rotate(0deg)
  }

  .md\:hue-rotate-15{
    --tw-hue-rotate:hue-rotate(15deg)
  }

  .md\:hue-rotate-30{
    --tw-hue-rotate:hue-rotate(30deg)
  }

  .md\:hue-rotate-60{
    --tw-hue-rotate:hue-rotate(60deg)
  }

  .md\:hue-rotate-90{
    --tw-hue-rotate:hue-rotate(90deg)
  }

  .md\:hue-rotate-180{
    --tw-hue-rotate:hue-rotate(180deg)
  }

  .md\:-hue-rotate-180{
    --tw-hue-rotate:hue-rotate(-180deg)
  }

  .md\:-hue-rotate-90{
    --tw-hue-rotate:hue-rotate(-90deg)
  }

  .md\:-hue-rotate-60{
    --tw-hue-rotate:hue-rotate(-60deg)
  }

  .md\:-hue-rotate-30{
    --tw-hue-rotate:hue-rotate(-30deg)
  }

  .md\:-hue-rotate-15{
    --tw-hue-rotate:hue-rotate(-15deg)
  }

  .md\:invert-0{
    --tw-invert:invert(0)
  }

  .md\:invert{
    --tw-invert:invert(100%)
  }

  .md\:saturate-0{
    --tw-saturate:saturate(0)
  }

  .md\:saturate-50{
    --tw-saturate:saturate(.5)
  }

  .md\:saturate-100{
    --tw-saturate:saturate(1)
  }

  .md\:saturate-150{
    --tw-saturate:saturate(1.5)
  }

  .md\:saturate-200{
    --tw-saturate:saturate(2)
  }

  .md\:sepia-0{
    --tw-sepia:sepia(0)
  }

  .md\:sepia{
    --tw-sepia:sepia(100%)
  }

  .md\:backdrop-filter{
    --tw-backdrop-blur:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia:var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  .md\:backdrop-filter-none{
    -webkit-backdrop-filter:none;
            backdrop-filter:none
  }

  .md\:backdrop-blur-0{
    --tw-backdrop-blur:blur(0)
  }

  .md\:backdrop-blur-none{
    --tw-backdrop-blur:blur(0)
  }

  .md\:backdrop-blur-sm{
    --tw-backdrop-blur:blur(4px)
  }

  .md\:backdrop-blur{
    --tw-backdrop-blur:blur(8px)
  }

  .md\:backdrop-blur-md{
    --tw-backdrop-blur:blur(12px)
  }

  .md\:backdrop-blur-lg{
    --tw-backdrop-blur:blur(16px)
  }

  .md\:backdrop-blur-xl{
    --tw-backdrop-blur:blur(24px)
  }

  .md\:backdrop-blur-2xl{
    --tw-backdrop-blur:blur(40px)
  }

  .md\:backdrop-blur-3xl{
    --tw-backdrop-blur:blur(64px)
  }

  .md\:backdrop-brightness-0{
    --tw-backdrop-brightness:brightness(0)
  }

  .md\:backdrop-brightness-50{
    --tw-backdrop-brightness:brightness(.5)
  }

  .md\:backdrop-brightness-75{
    --tw-backdrop-brightness:brightness(.75)
  }

  .md\:backdrop-brightness-90{
    --tw-backdrop-brightness:brightness(.9)
  }

  .md\:backdrop-brightness-95{
    --tw-backdrop-brightness:brightness(.95)
  }

  .md\:backdrop-brightness-100{
    --tw-backdrop-brightness:brightness(1)
  }

  .md\:backdrop-brightness-105{
    --tw-backdrop-brightness:brightness(1.05)
  }

  .md\:backdrop-brightness-110{
    --tw-backdrop-brightness:brightness(1.1)
  }

  .md\:backdrop-brightness-125{
    --tw-backdrop-brightness:brightness(1.25)
  }

  .md\:backdrop-brightness-150{
    --tw-backdrop-brightness:brightness(1.5)
  }

  .md\:backdrop-brightness-200{
    --tw-backdrop-brightness:brightness(2)
  }

  .md\:backdrop-contrast-0{
    --tw-backdrop-contrast:contrast(0)
  }

  .md\:backdrop-contrast-50{
    --tw-backdrop-contrast:contrast(.5)
  }

  .md\:backdrop-contrast-75{
    --tw-backdrop-contrast:contrast(.75)
  }

  .md\:backdrop-contrast-100{
    --tw-backdrop-contrast:contrast(1)
  }

  .md\:backdrop-contrast-125{
    --tw-backdrop-contrast:contrast(1.25)
  }

  .md\:backdrop-contrast-150{
    --tw-backdrop-contrast:contrast(1.5)
  }

  .md\:backdrop-contrast-200{
    --tw-backdrop-contrast:contrast(2)
  }

  .md\:backdrop-grayscale-0{
    --tw-backdrop-grayscale:grayscale(0)
  }

  .md\:backdrop-grayscale{
    --tw-backdrop-grayscale:grayscale(100%)
  }

  .md\:backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate:hue-rotate(0deg)
  }

  .md\:backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate:hue-rotate(15deg)
  }

  .md\:backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate:hue-rotate(30deg)
  }

  .md\:backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate:hue-rotate(60deg)
  }

  .md\:backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate:hue-rotate(90deg)
  }

  .md\:backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate:hue-rotate(180deg)
  }

  .md\:-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate:hue-rotate(-180deg)
  }

  .md\:-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate:hue-rotate(-90deg)
  }

  .md\:-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate:hue-rotate(-60deg)
  }

  .md\:-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate:hue-rotate(-30deg)
  }

  .md\:-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate:hue-rotate(-15deg)
  }

  .md\:backdrop-invert-0{
    --tw-backdrop-invert:invert(0)
  }

  .md\:backdrop-invert{
    --tw-backdrop-invert:invert(100%)
  }

  .md\:backdrop-opacity-0{
    --tw-backdrop-opacity:opacity(0)
  }

  .md\:backdrop-opacity-5{
    --tw-backdrop-opacity:opacity(0.05)
  }

  .md\:backdrop-opacity-10{
    --tw-backdrop-opacity:opacity(0.1)
  }

  .md\:backdrop-opacity-20{
    --tw-backdrop-opacity:opacity(0.2)
  }

  .md\:backdrop-opacity-25{
    --tw-backdrop-opacity:opacity(0.25)
  }

  .md\:backdrop-opacity-30{
    --tw-backdrop-opacity:opacity(0.3)
  }

  .md\:backdrop-opacity-40{
    --tw-backdrop-opacity:opacity(0.4)
  }

  .md\:backdrop-opacity-50{
    --tw-backdrop-opacity:opacity(0.5)
  }

  .md\:backdrop-opacity-60{
    --tw-backdrop-opacity:opacity(0.6)
  }

  .md\:backdrop-opacity-70{
    --tw-backdrop-opacity:opacity(0.7)
  }

  .md\:backdrop-opacity-75{
    --tw-backdrop-opacity:opacity(0.75)
  }

  .md\:backdrop-opacity-80{
    --tw-backdrop-opacity:opacity(0.8)
  }

  .md\:backdrop-opacity-90{
    --tw-backdrop-opacity:opacity(0.9)
  }

  .md\:backdrop-opacity-95{
    --tw-backdrop-opacity:opacity(0.95)
  }

  .md\:backdrop-opacity-100{
    --tw-backdrop-opacity:opacity(1)
  }

  .md\:backdrop-saturate-0{
    --tw-backdrop-saturate:saturate(0)
  }

  .md\:backdrop-saturate-50{
    --tw-backdrop-saturate:saturate(.5)
  }

  .md\:backdrop-saturate-100{
    --tw-backdrop-saturate:saturate(1)
  }

  .md\:backdrop-saturate-150{
    --tw-backdrop-saturate:saturate(1.5)
  }

  .md\:backdrop-saturate-200{
    --tw-backdrop-saturate:saturate(2)
  }

  .md\:backdrop-sepia-0{
    --tw-backdrop-sepia:sepia(0)
  }

  .md\:backdrop-sepia{
    --tw-backdrop-sepia:sepia(100%)
  }

  .md\:transition-none{
    transition-property:none
  }

  .md\:transition-all{
    transition-property:all;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .md\:transition{
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .md\:transition-colors{
    transition-property:background-color, border-color, color, fill, stroke;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .md\:transition-opacity{
    transition-property:opacity;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .md\:transition-shadow{
    transition-property:box-shadow;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .md\:transition-transform{
    transition-property:transform;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .md\:duration-75{
    transition-duration:75ms
  }

  .md\:duration-100{
    transition-duration:100ms
  }

  .md\:duration-150{
    transition-duration:150ms
  }

  .md\:duration-200{
    transition-duration:200ms
  }

  .md\:duration-300{
    transition-duration:300ms
  }

  .md\:duration-500{
    transition-duration:500ms
  }

  .md\:duration-700{
    transition-duration:700ms
  }

  .md\:duration-1000{
    transition-duration:1000ms
  }

  .md\:ease-linear{
    transition-timing-function:linear
  }

  .md\:ease-in{
    transition-timing-function:cubic-bezier(0.4, 0, 1, 1)
  }

  .md\:ease-out{
    transition-timing-function:cubic-bezier(0, 0, 0.2, 1)
  }

  .md\:ease-in-out{
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1)
  }
}

@media (min-width: 992px){
  .lg\:visible{
    visibility:visible
  }

  .lg\:invisible{
    visibility:hidden
  }

  .lg\:static{
    position:static
  }

  .lg\:fixed{
    position:fixed
  }

  .lg\:absolute{
    position:absolute
  }

  .lg\:relative{
    position:relative
  }

  .lg\:sticky{
    position:sticky
  }

  .lg\:inset-0{
    top:0;
    right:0;
    bottom:0;
    left:0
  }

  .lg\:inset-1{
    top:4px;
    right:4px;
    bottom:4px;
    left:4px
  }

  .lg\:inset-2{
    top:8px;
    right:8px;
    bottom:8px;
    left:8px
  }

  .lg\:inset-3{
    top:12px;
    right:12px;
    bottom:12px;
    left:12px
  }

  .lg\:inset-4{
    top:16px;
    right:16px;
    bottom:16px;
    left:16px
  }

  .lg\:inset-5{
    top:20px;
    right:20px;
    bottom:20px;
    left:20px
  }

  .lg\:inset-6{
    top:24px;
    right:24px;
    bottom:24px;
    left:24px
  }

  .lg\:inset-8{
    top:32px;
    right:32px;
    bottom:32px;
    left:32px
  }

  .lg\:inset-10{
    top:40px;
    right:40px;
    bottom:40px;
    left:40px
  }

  .lg\:inset-12{
    top:48px;
    right:48px;
    bottom:48px;
    left:48px
  }

  .lg\:inset-16{
    top:64px;
    right:64px;
    bottom:64px;
    left:64px
  }

  .lg\:inset-18{
    top:72px;
    right:72px;
    bottom:72px;
    left:72px
  }

  .lg\:inset-24{
    top:96px;
    right:96px;
    bottom:96px;
    left:96px
  }

  .lg\:inset-auto{
    top:auto;
    right:auto;
    bottom:auto;
    left:auto
  }

  .lg\:inset-5dot5{
    top:22px;
    right:22px;
    bottom:22px;
    left:22px
  }

  .lg\:inset-full{
    top:100%;
    right:100%;
    bottom:100%;
    left:100%
  }

  .lg\:-inset-1{
    top:-4px;
    right:-4px;
    bottom:-4px;
    left:-4px
  }

  .lg\:-inset-2{
    top:-8px;
    right:-8px;
    bottom:-8px;
    left:-8px
  }

  .lg\:-inset-3{
    top:-12px;
    right:-12px;
    bottom:-12px;
    left:-12px
  }

  .lg\:-inset-4{
    top:-16px;
    right:-16px;
    bottom:-16px;
    left:-16px
  }

  .lg\:-inset-5{
    top:-20px;
    right:-20px;
    bottom:-20px;
    left:-20px
  }

  .lg\:-inset-6{
    top:-24px;
    right:-24px;
    bottom:-24px;
    left:-24px
  }

  .lg\:-inset-8{
    top:-32px;
    right:-32px;
    bottom:-32px;
    left:-32px
  }

  .lg\:-inset-10{
    top:-40px;
    right:-40px;
    bottom:-40px;
    left:-40px
  }

  .lg\:-inset-12{
    top:-48px;
    right:-48px;
    bottom:-48px;
    left:-48px
  }

  .lg\:-inset-16{
    top:-64px;
    right:-64px;
    bottom:-64px;
    left:-64px
  }

  .lg\:-inset-18{
    top:-72px;
    right:-72px;
    bottom:-72px;
    left:-72px
  }

  .lg\:-inset-24{
    top:-96px;
    right:-96px;
    bottom:-96px;
    left:-96px
  }

  .lg\:-inset-5dot5{
    top:-22px;
    right:-22px;
    bottom:-22px;
    left:-22px
  }

  .lg\:-inset-full{
    top:-100%;
    right:-100%;
    bottom:-100%;
    left:-100%
  }

  .lg\:inset-1\/2{
    top:50%;
    right:50%;
    bottom:50%;
    left:50%
  }

  .lg\:inset-1\/3{
    top:33.33333%;
    right:33.33333%;
    bottom:33.33333%;
    left:33.33333%
  }

  .lg\:inset-2\/3{
    top:66.666667%;
    right:66.666667%;
    bottom:66.666667%;
    left:66.666667%
  }

  .lg\:inset-1\/4{
    top:25%;
    right:25%;
    bottom:25%;
    left:25%
  }

  .lg\:inset-2\/4{
    top:50%;
    right:50%;
    bottom:50%;
    left:50%
  }

  .lg\:inset-3\/4{
    top:75%;
    right:75%;
    bottom:75%;
    left:75%
  }

  .lg\:-inset-1\/2{
    top:-50%;
    right:-50%;
    bottom:-50%;
    left:-50%
  }

  .lg\:-inset-1\/3{
    top:-33.333333%;
    right:-33.333333%;
    bottom:-33.333333%;
    left:-33.333333%
  }

  .lg\:-inset-2\/3{
    top:-66.666667%;
    right:-66.666667%;
    bottom:-66.666667%;
    left:-66.666667%
  }

  .lg\:-inset-1\/4{
    top:-25%;
    right:-25%;
    bottom:-25%;
    left:-25%
  }

  .lg\:-inset-2\/4{
    top:-50%;
    right:-50%;
    bottom:-50%;
    left:-50%
  }

  .lg\:-inset-3\/4{
    top:-75%;
    right:-75%;
    bottom:-75%;
    left:-75%
  }

  .lg\:inset-x-0{
    left:0;
    right:0
  }

  .lg\:inset-x-1{
    left:4px;
    right:4px
  }

  .lg\:inset-x-2{
    left:8px;
    right:8px
  }

  .lg\:inset-x-3{
    left:12px;
    right:12px
  }

  .lg\:inset-x-4{
    left:16px;
    right:16px
  }

  .lg\:inset-x-5{
    left:20px;
    right:20px
  }

  .lg\:inset-x-6{
    left:24px;
    right:24px
  }

  .lg\:inset-x-8{
    left:32px;
    right:32px
  }

  .lg\:inset-x-10{
    left:40px;
    right:40px
  }

  .lg\:inset-x-12{
    left:48px;
    right:48px
  }

  .lg\:inset-x-16{
    left:64px;
    right:64px
  }

  .lg\:inset-x-18{
    left:72px;
    right:72px
  }

  .lg\:inset-x-24{
    left:96px;
    right:96px
  }

  .lg\:inset-x-auto{
    left:auto;
    right:auto
  }

  .lg\:inset-x-5dot5{
    left:22px;
    right:22px
  }

  .lg\:inset-x-full{
    left:100%;
    right:100%
  }

  .lg\:-inset-x-1{
    left:-4px;
    right:-4px
  }

  .lg\:-inset-x-2{
    left:-8px;
    right:-8px
  }

  .lg\:-inset-x-3{
    left:-12px;
    right:-12px
  }

  .lg\:-inset-x-4{
    left:-16px;
    right:-16px
  }

  .lg\:-inset-x-5{
    left:-20px;
    right:-20px
  }

  .lg\:-inset-x-6{
    left:-24px;
    right:-24px
  }

  .lg\:-inset-x-8{
    left:-32px;
    right:-32px
  }

  .lg\:-inset-x-10{
    left:-40px;
    right:-40px
  }

  .lg\:-inset-x-12{
    left:-48px;
    right:-48px
  }

  .lg\:-inset-x-16{
    left:-64px;
    right:-64px
  }

  .lg\:-inset-x-18{
    left:-72px;
    right:-72px
  }

  .lg\:-inset-x-24{
    left:-96px;
    right:-96px
  }

  .lg\:-inset-x-5dot5{
    left:-22px;
    right:-22px
  }

  .lg\:-inset-x-full{
    left:-100%;
    right:-100%
  }

  .lg\:inset-x-1\/2{
    left:50%;
    right:50%
  }

  .lg\:inset-x-1\/3{
    left:33.33333%;
    right:33.33333%
  }

  .lg\:inset-x-2\/3{
    left:66.666667%;
    right:66.666667%
  }

  .lg\:inset-x-1\/4{
    left:25%;
    right:25%
  }

  .lg\:inset-x-2\/4{
    left:50%;
    right:50%
  }

  .lg\:inset-x-3\/4{
    left:75%;
    right:75%
  }

  .lg\:-inset-x-1\/2{
    left:-50%;
    right:-50%
  }

  .lg\:-inset-x-1\/3{
    left:-33.333333%;
    right:-33.333333%
  }

  .lg\:-inset-x-2\/3{
    left:-66.666667%;
    right:-66.666667%
  }

  .lg\:-inset-x-1\/4{
    left:-25%;
    right:-25%
  }

  .lg\:-inset-x-2\/4{
    left:-50%;
    right:-50%
  }

  .lg\:-inset-x-3\/4{
    left:-75%;
    right:-75%
  }

  .lg\:inset-y-0{
    top:0;
    bottom:0
  }

  .lg\:inset-y-1{
    top:4px;
    bottom:4px
  }

  .lg\:inset-y-2{
    top:8px;
    bottom:8px
  }

  .lg\:inset-y-3{
    top:12px;
    bottom:12px
  }

  .lg\:inset-y-4{
    top:16px;
    bottom:16px
  }

  .lg\:inset-y-5{
    top:20px;
    bottom:20px
  }

  .lg\:inset-y-6{
    top:24px;
    bottom:24px
  }

  .lg\:inset-y-8{
    top:32px;
    bottom:32px
  }

  .lg\:inset-y-10{
    top:40px;
    bottom:40px
  }

  .lg\:inset-y-12{
    top:48px;
    bottom:48px
  }

  .lg\:inset-y-16{
    top:64px;
    bottom:64px
  }

  .lg\:inset-y-18{
    top:72px;
    bottom:72px
  }

  .lg\:inset-y-24{
    top:96px;
    bottom:96px
  }

  .lg\:inset-y-auto{
    top:auto;
    bottom:auto
  }

  .lg\:inset-y-5dot5{
    top:22px;
    bottom:22px
  }

  .lg\:inset-y-full{
    top:100%;
    bottom:100%
  }

  .lg\:-inset-y-1{
    top:-4px;
    bottom:-4px
  }

  .lg\:-inset-y-2{
    top:-8px;
    bottom:-8px
  }

  .lg\:-inset-y-3{
    top:-12px;
    bottom:-12px
  }

  .lg\:-inset-y-4{
    top:-16px;
    bottom:-16px
  }

  .lg\:-inset-y-5{
    top:-20px;
    bottom:-20px
  }

  .lg\:-inset-y-6{
    top:-24px;
    bottom:-24px
  }

  .lg\:-inset-y-8{
    top:-32px;
    bottom:-32px
  }

  .lg\:-inset-y-10{
    top:-40px;
    bottom:-40px
  }

  .lg\:-inset-y-12{
    top:-48px;
    bottom:-48px
  }

  .lg\:-inset-y-16{
    top:-64px;
    bottom:-64px
  }

  .lg\:-inset-y-18{
    top:-72px;
    bottom:-72px
  }

  .lg\:-inset-y-24{
    top:-96px;
    bottom:-96px
  }

  .lg\:-inset-y-5dot5{
    top:-22px;
    bottom:-22px
  }

  .lg\:-inset-y-full{
    top:-100%;
    bottom:-100%
  }

  .lg\:inset-y-1\/2{
    top:50%;
    bottom:50%
  }

  .lg\:inset-y-1\/3{
    top:33.33333%;
    bottom:33.33333%
  }

  .lg\:inset-y-2\/3{
    top:66.666667%;
    bottom:66.666667%
  }

  .lg\:inset-y-1\/4{
    top:25%;
    bottom:25%
  }

  .lg\:inset-y-2\/4{
    top:50%;
    bottom:50%
  }

  .lg\:inset-y-3\/4{
    top:75%;
    bottom:75%
  }

  .lg\:-inset-y-1\/2{
    top:-50%;
    bottom:-50%
  }

  .lg\:-inset-y-1\/3{
    top:-33.333333%;
    bottom:-33.333333%
  }

  .lg\:-inset-y-2\/3{
    top:-66.666667%;
    bottom:-66.666667%
  }

  .lg\:-inset-y-1\/4{
    top:-25%;
    bottom:-25%
  }

  .lg\:-inset-y-2\/4{
    top:-50%;
    bottom:-50%
  }

  .lg\:-inset-y-3\/4{
    top:-75%;
    bottom:-75%
  }

  .lg\:top-0{
    top:0
  }

  .lg\:top-1{
    top:4px
  }

  .lg\:top-2{
    top:8px
  }

  .lg\:top-3{
    top:12px
  }

  .lg\:top-4{
    top:16px
  }

  .lg\:top-5{
    top:20px
  }

  .lg\:top-6{
    top:24px
  }

  .lg\:top-8{
    top:32px
  }

  .lg\:top-10{
    top:40px
  }

  .lg\:top-12{
    top:48px
  }

  .lg\:top-16{
    top:64px
  }

  .lg\:top-18{
    top:72px
  }

  .lg\:top-24{
    top:96px
  }

  .lg\:top-auto{
    top:auto
  }

  .lg\:top-5dot5{
    top:22px
  }

  .lg\:top-full{
    top:100%
  }

  .lg\:-top-1{
    top:-4px
  }

  .lg\:-top-2{
    top:-8px
  }

  .lg\:-top-3{
    top:-12px
  }

  .lg\:-top-4{
    top:-16px
  }

  .lg\:-top-5{
    top:-20px
  }

  .lg\:-top-6{
    top:-24px
  }

  .lg\:-top-8{
    top:-32px
  }

  .lg\:-top-10{
    top:-40px
  }

  .lg\:-top-12{
    top:-48px
  }

  .lg\:-top-16{
    top:-64px
  }

  .lg\:-top-18{
    top:-72px
  }

  .lg\:-top-24{
    top:-96px
  }

  .lg\:-top-5dot5{
    top:-22px
  }

  .lg\:-top-full{
    top:-100%
  }

  .lg\:top-1\/2{
    top:50%
  }

  .lg\:top-1\/3{
    top:33.33333%
  }

  .lg\:top-2\/3{
    top:66.666667%
  }

  .lg\:top-1\/4{
    top:25%
  }

  .lg\:top-2\/4{
    top:50%
  }

  .lg\:top-3\/4{
    top:75%
  }

  .lg\:-top-1\/2{
    top:-50%
  }

  .lg\:-top-1\/3{
    top:-33.333333%
  }

  .lg\:-top-2\/3{
    top:-66.666667%
  }

  .lg\:-top-1\/4{
    top:-25%
  }

  .lg\:-top-2\/4{
    top:-50%
  }

  .lg\:-top-3\/4{
    top:-75%
  }

  .lg\:right-0{
    right:0
  }

  .lg\:right-1{
    right:4px
  }

  .lg\:right-2{
    right:8px
  }

  .lg\:right-3{
    right:12px
  }

  .lg\:right-4{
    right:16px
  }

  .lg\:right-5{
    right:20px
  }

  .lg\:right-6{
    right:24px
  }

  .lg\:right-8{
    right:32px
  }

  .lg\:right-10{
    right:40px
  }

  .lg\:right-12{
    right:48px
  }

  .lg\:right-16{
    right:64px
  }

  .lg\:right-18{
    right:72px
  }

  .lg\:right-24{
    right:96px
  }

  .lg\:right-auto{
    right:auto
  }

  .lg\:right-5dot5{
    right:22px
  }

  .lg\:right-full{
    right:100%
  }

  .lg\:-right-1{
    right:-4px
  }

  .lg\:-right-2{
    right:-8px
  }

  .lg\:-right-3{
    right:-12px
  }

  .lg\:-right-4{
    right:-16px
  }

  .lg\:-right-5{
    right:-20px
  }

  .lg\:-right-6{
    right:-24px
  }

  .lg\:-right-8{
    right:-32px
  }

  .lg\:-right-10{
    right:-40px
  }

  .lg\:-right-12{
    right:-48px
  }

  .lg\:-right-16{
    right:-64px
  }

  .lg\:-right-18{
    right:-72px
  }

  .lg\:-right-24{
    right:-96px
  }

  .lg\:-right-5dot5{
    right:-22px
  }

  .lg\:-right-full{
    right:-100%
  }

  .lg\:right-1\/2{
    right:50%
  }

  .lg\:right-1\/3{
    right:33.33333%
  }

  .lg\:right-2\/3{
    right:66.666667%
  }

  .lg\:right-1\/4{
    right:25%
  }

  .lg\:right-2\/4{
    right:50%
  }

  .lg\:right-3\/4{
    right:75%
  }

  .lg\:-right-1\/2{
    right:-50%
  }

  .lg\:-right-1\/3{
    right:-33.333333%
  }

  .lg\:-right-2\/3{
    right:-66.666667%
  }

  .lg\:-right-1\/4{
    right:-25%
  }

  .lg\:-right-2\/4{
    right:-50%
  }

  .lg\:-right-3\/4{
    right:-75%
  }

  .lg\:bottom-0{
    bottom:0
  }

  .lg\:bottom-1{
    bottom:4px
  }

  .lg\:bottom-2{
    bottom:8px
  }

  .lg\:bottom-3{
    bottom:12px
  }

  .lg\:bottom-4{
    bottom:16px
  }

  .lg\:bottom-5{
    bottom:20px
  }

  .lg\:bottom-6{
    bottom:24px
  }

  .lg\:bottom-8{
    bottom:32px
  }

  .lg\:bottom-10{
    bottom:40px
  }

  .lg\:bottom-12{
    bottom:48px
  }

  .lg\:bottom-16{
    bottom:64px
  }

  .lg\:bottom-18{
    bottom:72px
  }

  .lg\:bottom-24{
    bottom:96px
  }

  .lg\:bottom-auto{
    bottom:auto
  }

  .lg\:bottom-5dot5{
    bottom:22px
  }

  .lg\:bottom-full{
    bottom:100%
  }

  .lg\:-bottom-1{
    bottom:-4px
  }

  .lg\:-bottom-2{
    bottom:-8px
  }

  .lg\:-bottom-3{
    bottom:-12px
  }

  .lg\:-bottom-4{
    bottom:-16px
  }

  .lg\:-bottom-5{
    bottom:-20px
  }

  .lg\:-bottom-6{
    bottom:-24px
  }

  .lg\:-bottom-8{
    bottom:-32px
  }

  .lg\:-bottom-10{
    bottom:-40px
  }

  .lg\:-bottom-12{
    bottom:-48px
  }

  .lg\:-bottom-16{
    bottom:-64px
  }

  .lg\:-bottom-18{
    bottom:-72px
  }

  .lg\:-bottom-24{
    bottom:-96px
  }

  .lg\:-bottom-5dot5{
    bottom:-22px
  }

  .lg\:-bottom-full{
    bottom:-100%
  }

  .lg\:bottom-1\/2{
    bottom:50%
  }

  .lg\:bottom-1\/3{
    bottom:33.33333%
  }

  .lg\:bottom-2\/3{
    bottom:66.666667%
  }

  .lg\:bottom-1\/4{
    bottom:25%
  }

  .lg\:bottom-2\/4{
    bottom:50%
  }

  .lg\:bottom-3\/4{
    bottom:75%
  }

  .lg\:-bottom-1\/2{
    bottom:-50%
  }

  .lg\:-bottom-1\/3{
    bottom:-33.333333%
  }

  .lg\:-bottom-2\/3{
    bottom:-66.666667%
  }

  .lg\:-bottom-1\/4{
    bottom:-25%
  }

  .lg\:-bottom-2\/4{
    bottom:-50%
  }

  .lg\:-bottom-3\/4{
    bottom:-75%
  }

  .lg\:left-0{
    left:0
  }

  .lg\:left-1{
    left:4px
  }

  .lg\:left-2{
    left:8px
  }

  .lg\:left-3{
    left:12px
  }

  .lg\:left-4{
    left:16px
  }

  .lg\:left-5{
    left:20px
  }

  .lg\:left-6{
    left:24px
  }

  .lg\:left-8{
    left:32px
  }

  .lg\:left-10{
    left:40px
  }

  .lg\:left-12{
    left:48px
  }

  .lg\:left-16{
    left:64px
  }

  .lg\:left-18{
    left:72px
  }

  .lg\:left-24{
    left:96px
  }

  .lg\:left-auto{
    left:auto
  }

  .lg\:left-5dot5{
    left:22px
  }

  .lg\:left-full{
    left:100%
  }

  .lg\:-left-1{
    left:-4px
  }

  .lg\:-left-2{
    left:-8px
  }

  .lg\:-left-3{
    left:-12px
  }

  .lg\:-left-4{
    left:-16px
  }

  .lg\:-left-5{
    left:-20px
  }

  .lg\:-left-6{
    left:-24px
  }

  .lg\:-left-8{
    left:-32px
  }

  .lg\:-left-10{
    left:-40px
  }

  .lg\:-left-12{
    left:-48px
  }

  .lg\:-left-16{
    left:-64px
  }

  .lg\:-left-18{
    left:-72px
  }

  .lg\:-left-24{
    left:-96px
  }

  .lg\:-left-5dot5{
    left:-22px
  }

  .lg\:-left-full{
    left:-100%
  }

  .lg\:left-1\/2{
    left:50%
  }

  .lg\:left-1\/3{
    left:33.33333%
  }

  .lg\:left-2\/3{
    left:66.666667%
  }

  .lg\:left-1\/4{
    left:25%
  }

  .lg\:left-2\/4{
    left:50%
  }

  .lg\:left-3\/4{
    left:75%
  }

  .lg\:-left-1\/2{
    left:-50%
  }

  .lg\:-left-1\/3{
    left:-33.333333%
  }

  .lg\:-left-2\/3{
    left:-66.666667%
  }

  .lg\:-left-1\/4{
    left:-25%
  }

  .lg\:-left-2\/4{
    left:-50%
  }

  .lg\:-left-3\/4{
    left:-75%
  }

  .lg\:isolate{
    isolation:isolate
  }

  .lg\:isolation-auto{
    isolation:auto
  }

  .lg\:z-0{
    z-index:0
  }

  .lg\:z-10{
    z-index:10
  }

  .lg\:z-20{
    z-index:20
  }

  .lg\:z-30{
    z-index:30
  }

  .lg\:z-40{
    z-index:40
  }

  .lg\:z-50{
    z-index:50
  }

  .lg\:z-auto{
    z-index:auto
  }

  .lg\:focus-within\:z-0:focus-within{
    z-index:0
  }

  .lg\:focus-within\:z-10:focus-within{
    z-index:10
  }

  .lg\:focus-within\:z-20:focus-within{
    z-index:20
  }

  .lg\:focus-within\:z-30:focus-within{
    z-index:30
  }

  .lg\:focus-within\:z-40:focus-within{
    z-index:40
  }

  .lg\:focus-within\:z-50:focus-within{
    z-index:50
  }

  .lg\:focus-within\:z-auto:focus-within{
    z-index:auto
  }

  .lg\:focus\:z-0:focus{
    z-index:0
  }

  .lg\:focus\:z-10:focus{
    z-index:10
  }

  .lg\:focus\:z-20:focus{
    z-index:20
  }

  .lg\:focus\:z-30:focus{
    z-index:30
  }

  .lg\:focus\:z-40:focus{
    z-index:40
  }

  .lg\:focus\:z-50:focus{
    z-index:50
  }

  .lg\:focus\:z-auto:focus{
    z-index:auto
  }

  .lg\:col-auto{
    grid-column:auto
  }

  .lg\:col-span-1{
    grid-column:span 1 / span 1
  }

  .lg\:col-span-2{
    grid-column:span 2 / span 2
  }

  .lg\:col-span-3{
    grid-column:span 3 / span 3
  }

  .lg\:col-span-4{
    grid-column:span 4 / span 4
  }

  .lg\:col-span-5{
    grid-column:span 5 / span 5
  }

  .lg\:col-span-6{
    grid-column:span 6 / span 6
  }

  .lg\:col-span-7{
    grid-column:span 7 / span 7
  }

  .lg\:col-span-8{
    grid-column:span 8 / span 8
  }

  .lg\:col-span-9{
    grid-column:span 9 / span 9
  }

  .lg\:col-span-10{
    grid-column:span 10 / span 10
  }

  .lg\:col-span-11{
    grid-column:span 11 / span 11
  }

  .lg\:col-span-12{
    grid-column:span 12 / span 12
  }

  .lg\:col-span-full{
    grid-column:1 / -1
  }

  .lg\:col-start-1{
    grid-column-start:1
  }

  .lg\:col-start-2{
    grid-column-start:2
  }

  .lg\:col-start-3{
    grid-column-start:3
  }

  .lg\:col-start-4{
    grid-column-start:4
  }

  .lg\:col-start-5{
    grid-column-start:5
  }

  .lg\:col-start-6{
    grid-column-start:6
  }

  .lg\:col-start-7{
    grid-column-start:7
  }

  .lg\:col-start-8{
    grid-column-start:8
  }

  .lg\:col-start-9{
    grid-column-start:9
  }

  .lg\:col-start-10{
    grid-column-start:10
  }

  .lg\:col-start-11{
    grid-column-start:11
  }

  .lg\:col-start-12{
    grid-column-start:12
  }

  .lg\:col-start-13{
    grid-column-start:13
  }

  .lg\:col-start-auto{
    grid-column-start:auto
  }

  .lg\:col-end-1{
    grid-column-end:1
  }

  .lg\:col-end-2{
    grid-column-end:2
  }

  .lg\:col-end-3{
    grid-column-end:3
  }

  .lg\:col-end-4{
    grid-column-end:4
  }

  .lg\:col-end-5{
    grid-column-end:5
  }

  .lg\:col-end-6{
    grid-column-end:6
  }

  .lg\:col-end-7{
    grid-column-end:7
  }

  .lg\:col-end-8{
    grid-column-end:8
  }

  .lg\:col-end-9{
    grid-column-end:9
  }

  .lg\:col-end-10{
    grid-column-end:10
  }

  .lg\:col-end-11{
    grid-column-end:11
  }

  .lg\:col-end-12{
    grid-column-end:12
  }

  .lg\:col-end-13{
    grid-column-end:13
  }

  .lg\:col-end-auto{
    grid-column-end:auto
  }

  .lg\:row-auto{
    grid-row:auto
  }

  .lg\:row-span-1{
    grid-row:span 1 / span 1
  }

  .lg\:row-span-2{
    grid-row:span 2 / span 2
  }

  .lg\:row-span-3{
    grid-row:span 3 / span 3
  }

  .lg\:row-span-4{
    grid-row:span 4 / span 4
  }

  .lg\:row-span-5{
    grid-row:span 5 / span 5
  }

  .lg\:row-span-6{
    grid-row:span 6 / span 6
  }

  .lg\:row-span-full{
    grid-row:1 / -1
  }

  .lg\:row-start-1{
    grid-row-start:1
  }

  .lg\:row-start-2{
    grid-row-start:2
  }

  .lg\:row-start-3{
    grid-row-start:3
  }

  .lg\:row-start-4{
    grid-row-start:4
  }

  .lg\:row-start-5{
    grid-row-start:5
  }

  .lg\:row-start-6{
    grid-row-start:6
  }

  .lg\:row-start-7{
    grid-row-start:7
  }

  .lg\:row-start-auto{
    grid-row-start:auto
  }

  .lg\:row-end-1{
    grid-row-end:1
  }

  .lg\:row-end-2{
    grid-row-end:2
  }

  .lg\:row-end-3{
    grid-row-end:3
  }

  .lg\:row-end-4{
    grid-row-end:4
  }

  .lg\:row-end-5{
    grid-row-end:5
  }

  .lg\:row-end-6{
    grid-row-end:6
  }

  .lg\:row-end-7{
    grid-row-end:7
  }

  .lg\:row-end-auto{
    grid-row-end:auto
  }

  .lg\:m-0{
    margin:0
  }

  .lg\:m-1{
    margin:4px
  }

  .lg\:m-2{
    margin:8px
  }

  .lg\:m-3{
    margin:12px
  }

  .lg\:m-4{
    margin:16px
  }

  .lg\:m-5{
    margin:20px
  }

  .lg\:m-6{
    margin:24px
  }

  .lg\:m-8{
    margin:32px
  }

  .lg\:m-10{
    margin:40px
  }

  .lg\:m-12{
    margin:48px
  }

  .lg\:m-16{
    margin:64px
  }

  .lg\:m-18{
    margin:72px
  }

  .lg\:m-24{
    margin:96px
  }

  .lg\:m-auto{
    margin:auto
  }

  .lg\:m-5dot5{
    margin:22px
  }

  .lg\:m-full{
    margin:100%
  }

  .lg\:-m-1{
    margin:-4px
  }

  .lg\:-m-2{
    margin:-8px
  }

  .lg\:-m-3{
    margin:-12px
  }

  .lg\:-m-4{
    margin:-16px
  }

  .lg\:-m-5{
    margin:-20px
  }

  .lg\:-m-6{
    margin:-24px
  }

  .lg\:-m-8{
    margin:-32px
  }

  .lg\:-m-10{
    margin:-40px
  }

  .lg\:-m-12{
    margin:-48px
  }

  .lg\:-m-16{
    margin:-64px
  }

  .lg\:-m-18{
    margin:-72px
  }

  .lg\:-m-24{
    margin:-96px
  }

  .lg\:-m-5dot5{
    margin:-22px
  }

  .lg\:-m-full{
    margin:-100%
  }

  .lg\:first\:m-0:first-child{
    margin:0
  }

  .lg\:first\:m-1:first-child{
    margin:4px
  }

  .lg\:first\:m-2:first-child{
    margin:8px
  }

  .lg\:first\:m-3:first-child{
    margin:12px
  }

  .lg\:first\:m-4:first-child{
    margin:16px
  }

  .lg\:first\:m-5:first-child{
    margin:20px
  }

  .lg\:first\:m-6:first-child{
    margin:24px
  }

  .lg\:first\:m-8:first-child{
    margin:32px
  }

  .lg\:first\:m-10:first-child{
    margin:40px
  }

  .lg\:first\:m-12:first-child{
    margin:48px
  }

  .lg\:first\:m-16:first-child{
    margin:64px
  }

  .lg\:first\:m-18:first-child{
    margin:72px
  }

  .lg\:first\:m-24:first-child{
    margin:96px
  }

  .lg\:first\:m-auto:first-child{
    margin:auto
  }

  .lg\:first\:m-5dot5:first-child{
    margin:22px
  }

  .lg\:first\:m-full:first-child{
    margin:100%
  }

  .lg\:first\:-m-1:first-child{
    margin:-4px
  }

  .lg\:first\:-m-2:first-child{
    margin:-8px
  }

  .lg\:first\:-m-3:first-child{
    margin:-12px
  }

  .lg\:first\:-m-4:first-child{
    margin:-16px
  }

  .lg\:first\:-m-5:first-child{
    margin:-20px
  }

  .lg\:first\:-m-6:first-child{
    margin:-24px
  }

  .lg\:first\:-m-8:first-child{
    margin:-32px
  }

  .lg\:first\:-m-10:first-child{
    margin:-40px
  }

  .lg\:first\:-m-12:first-child{
    margin:-48px
  }

  .lg\:first\:-m-16:first-child{
    margin:-64px
  }

  .lg\:first\:-m-18:first-child{
    margin:-72px
  }

  .lg\:first\:-m-24:first-child{
    margin:-96px
  }

  .lg\:first\:-m-5dot5:first-child{
    margin:-22px
  }

  .lg\:first\:-m-full:first-child{
    margin:-100%
  }

  .lg\:last\:m-0:last-child{
    margin:0
  }

  .lg\:last\:m-1:last-child{
    margin:4px
  }

  .lg\:last\:m-2:last-child{
    margin:8px
  }

  .lg\:last\:m-3:last-child{
    margin:12px
  }

  .lg\:last\:m-4:last-child{
    margin:16px
  }

  .lg\:last\:m-5:last-child{
    margin:20px
  }

  .lg\:last\:m-6:last-child{
    margin:24px
  }

  .lg\:last\:m-8:last-child{
    margin:32px
  }

  .lg\:last\:m-10:last-child{
    margin:40px
  }

  .lg\:last\:m-12:last-child{
    margin:48px
  }

  .lg\:last\:m-16:last-child{
    margin:64px
  }

  .lg\:last\:m-18:last-child{
    margin:72px
  }

  .lg\:last\:m-24:last-child{
    margin:96px
  }

  .lg\:last\:m-auto:last-child{
    margin:auto
  }

  .lg\:last\:m-5dot5:last-child{
    margin:22px
  }

  .lg\:last\:m-full:last-child{
    margin:100%
  }

  .lg\:last\:-m-1:last-child{
    margin:-4px
  }

  .lg\:last\:-m-2:last-child{
    margin:-8px
  }

  .lg\:last\:-m-3:last-child{
    margin:-12px
  }

  .lg\:last\:-m-4:last-child{
    margin:-16px
  }

  .lg\:last\:-m-5:last-child{
    margin:-20px
  }

  .lg\:last\:-m-6:last-child{
    margin:-24px
  }

  .lg\:last\:-m-8:last-child{
    margin:-32px
  }

  .lg\:last\:-m-10:last-child{
    margin:-40px
  }

  .lg\:last\:-m-12:last-child{
    margin:-48px
  }

  .lg\:last\:-m-16:last-child{
    margin:-64px
  }

  .lg\:last\:-m-18:last-child{
    margin:-72px
  }

  .lg\:last\:-m-24:last-child{
    margin:-96px
  }

  .lg\:last\:-m-5dot5:last-child{
    margin:-22px
  }

  .lg\:last\:-m-full:last-child{
    margin:-100%
  }

  .lg\:mx-0{
    margin-left:0;
    margin-right:0
  }

  .lg\:mx-1{
    margin-left:4px;
    margin-right:4px
  }

  .lg\:mx-2{
    margin-left:8px;
    margin-right:8px
  }

  .lg\:mx-3{
    margin-left:12px;
    margin-right:12px
  }

  .lg\:mx-4{
    margin-left:16px;
    margin-right:16px
  }

  .lg\:mx-5{
    margin-left:20px;
    margin-right:20px
  }

  .lg\:mx-6{
    margin-left:24px;
    margin-right:24px
  }

  .lg\:mx-8{
    margin-left:32px;
    margin-right:32px
  }

  .lg\:mx-10{
    margin-left:40px;
    margin-right:40px
  }

  .lg\:mx-12{
    margin-left:48px;
    margin-right:48px
  }

  .lg\:mx-16{
    margin-left:64px;
    margin-right:64px
  }

  .lg\:mx-18{
    margin-left:72px;
    margin-right:72px
  }

  .lg\:mx-24{
    margin-left:96px;
    margin-right:96px
  }

  .lg\:mx-auto{
    margin-left:auto;
    margin-right:auto
  }

  .lg\:mx-5dot5{
    margin-left:22px;
    margin-right:22px
  }

  .lg\:mx-full{
    margin-left:100%;
    margin-right:100%
  }

  .lg\:-mx-1{
    margin-left:-4px;
    margin-right:-4px
  }

  .lg\:-mx-2{
    margin-left:-8px;
    margin-right:-8px
  }

  .lg\:-mx-3{
    margin-left:-12px;
    margin-right:-12px
  }

  .lg\:-mx-4{
    margin-left:-16px;
    margin-right:-16px
  }

  .lg\:-mx-5{
    margin-left:-20px;
    margin-right:-20px
  }

  .lg\:-mx-6{
    margin-left:-24px;
    margin-right:-24px
  }

  .lg\:-mx-8{
    margin-left:-32px;
    margin-right:-32px
  }

  .lg\:-mx-10{
    margin-left:-40px;
    margin-right:-40px
  }

  .lg\:-mx-12{
    margin-left:-48px;
    margin-right:-48px
  }

  .lg\:-mx-16{
    margin-left:-64px;
    margin-right:-64px
  }

  .lg\:-mx-18{
    margin-left:-72px;
    margin-right:-72px
  }

  .lg\:-mx-24{
    margin-left:-96px;
    margin-right:-96px
  }

  .lg\:-mx-5dot5{
    margin-left:-22px;
    margin-right:-22px
  }

  .lg\:-mx-full{
    margin-left:-100%;
    margin-right:-100%
  }

  .lg\:my-0{
    margin-top:0;
    margin-bottom:0
  }

  .lg\:my-1{
    margin-top:4px;
    margin-bottom:4px
  }

  .lg\:my-2{
    margin-top:8px;
    margin-bottom:8px
  }

  .lg\:my-3{
    margin-top:12px;
    margin-bottom:12px
  }

  .lg\:my-4{
    margin-top:16px;
    margin-bottom:16px
  }

  .lg\:my-5{
    margin-top:20px;
    margin-bottom:20px
  }

  .lg\:my-6{
    margin-top:24px;
    margin-bottom:24px
  }

  .lg\:my-8{
    margin-top:32px;
    margin-bottom:32px
  }

  .lg\:my-10{
    margin-top:40px;
    margin-bottom:40px
  }

  .lg\:my-12{
    margin-top:48px;
    margin-bottom:48px
  }

  .lg\:my-16{
    margin-top:64px;
    margin-bottom:64px
  }

  .lg\:my-18{
    margin-top:72px;
    margin-bottom:72px
  }

  .lg\:my-24{
    margin-top:96px;
    margin-bottom:96px
  }

  .lg\:my-auto{
    margin-top:auto;
    margin-bottom:auto
  }

  .lg\:my-5dot5{
    margin-top:22px;
    margin-bottom:22px
  }

  .lg\:my-full{
    margin-top:100%;
    margin-bottom:100%
  }

  .lg\:-my-1{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .lg\:-my-2{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .lg\:-my-3{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .lg\:-my-4{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .lg\:-my-5{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .lg\:-my-6{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .lg\:-my-8{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .lg\:-my-10{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .lg\:-my-12{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .lg\:-my-16{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .lg\:-my-18{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .lg\:-my-24{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .lg\:-my-5dot5{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .lg\:-my-full{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .lg\:first\:mx-0:first-child{
    margin-left:0;
    margin-right:0
  }

  .lg\:first\:mx-1:first-child{
    margin-left:4px;
    margin-right:4px
  }

  .lg\:first\:mx-2:first-child{
    margin-left:8px;
    margin-right:8px
  }

  .lg\:first\:mx-3:first-child{
    margin-left:12px;
    margin-right:12px
  }

  .lg\:first\:mx-4:first-child{
    margin-left:16px;
    margin-right:16px
  }

  .lg\:first\:mx-5:first-child{
    margin-left:20px;
    margin-right:20px
  }

  .lg\:first\:mx-6:first-child{
    margin-left:24px;
    margin-right:24px
  }

  .lg\:first\:mx-8:first-child{
    margin-left:32px;
    margin-right:32px
  }

  .lg\:first\:mx-10:first-child{
    margin-left:40px;
    margin-right:40px
  }

  .lg\:first\:mx-12:first-child{
    margin-left:48px;
    margin-right:48px
  }

  .lg\:first\:mx-16:first-child{
    margin-left:64px;
    margin-right:64px
  }

  .lg\:first\:mx-18:first-child{
    margin-left:72px;
    margin-right:72px
  }

  .lg\:first\:mx-24:first-child{
    margin-left:96px;
    margin-right:96px
  }

  .lg\:first\:mx-auto:first-child{
    margin-left:auto;
    margin-right:auto
  }

  .lg\:first\:mx-5dot5:first-child{
    margin-left:22px;
    margin-right:22px
  }

  .lg\:first\:mx-full:first-child{
    margin-left:100%;
    margin-right:100%
  }

  .lg\:first\:-mx-1:first-child{
    margin-left:-4px;
    margin-right:-4px
  }

  .lg\:first\:-mx-2:first-child{
    margin-left:-8px;
    margin-right:-8px
  }

  .lg\:first\:-mx-3:first-child{
    margin-left:-12px;
    margin-right:-12px
  }

  .lg\:first\:-mx-4:first-child{
    margin-left:-16px;
    margin-right:-16px
  }

  .lg\:first\:-mx-5:first-child{
    margin-left:-20px;
    margin-right:-20px
  }

  .lg\:first\:-mx-6:first-child{
    margin-left:-24px;
    margin-right:-24px
  }

  .lg\:first\:-mx-8:first-child{
    margin-left:-32px;
    margin-right:-32px
  }

  .lg\:first\:-mx-10:first-child{
    margin-left:-40px;
    margin-right:-40px
  }

  .lg\:first\:-mx-12:first-child{
    margin-left:-48px;
    margin-right:-48px
  }

  .lg\:first\:-mx-16:first-child{
    margin-left:-64px;
    margin-right:-64px
  }

  .lg\:first\:-mx-18:first-child{
    margin-left:-72px;
    margin-right:-72px
  }

  .lg\:first\:-mx-24:first-child{
    margin-left:-96px;
    margin-right:-96px
  }

  .lg\:first\:-mx-5dot5:first-child{
    margin-left:-22px;
    margin-right:-22px
  }

  .lg\:first\:-mx-full:first-child{
    margin-left:-100%;
    margin-right:-100%
  }

  .lg\:first\:my-0:first-child{
    margin-top:0;
    margin-bottom:0
  }

  .lg\:first\:my-1:first-child{
    margin-top:4px;
    margin-bottom:4px
  }

  .lg\:first\:my-2:first-child{
    margin-top:8px;
    margin-bottom:8px
  }

  .lg\:first\:my-3:first-child{
    margin-top:12px;
    margin-bottom:12px
  }

  .lg\:first\:my-4:first-child{
    margin-top:16px;
    margin-bottom:16px
  }

  .lg\:first\:my-5:first-child{
    margin-top:20px;
    margin-bottom:20px
  }

  .lg\:first\:my-6:first-child{
    margin-top:24px;
    margin-bottom:24px
  }

  .lg\:first\:my-8:first-child{
    margin-top:32px;
    margin-bottom:32px
  }

  .lg\:first\:my-10:first-child{
    margin-top:40px;
    margin-bottom:40px
  }

  .lg\:first\:my-12:first-child{
    margin-top:48px;
    margin-bottom:48px
  }

  .lg\:first\:my-16:first-child{
    margin-top:64px;
    margin-bottom:64px
  }

  .lg\:first\:my-18:first-child{
    margin-top:72px;
    margin-bottom:72px
  }

  .lg\:first\:my-24:first-child{
    margin-top:96px;
    margin-bottom:96px
  }

  .lg\:first\:my-auto:first-child{
    margin-top:auto;
    margin-bottom:auto
  }

  .lg\:first\:my-5dot5:first-child{
    margin-top:22px;
    margin-bottom:22px
  }

  .lg\:first\:my-full:first-child{
    margin-top:100%;
    margin-bottom:100%
  }

  .lg\:first\:-my-1:first-child{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .lg\:first\:-my-2:first-child{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .lg\:first\:-my-3:first-child{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .lg\:first\:-my-4:first-child{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .lg\:first\:-my-5:first-child{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .lg\:first\:-my-6:first-child{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .lg\:first\:-my-8:first-child{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .lg\:first\:-my-10:first-child{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .lg\:first\:-my-12:first-child{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .lg\:first\:-my-16:first-child{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .lg\:first\:-my-18:first-child{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .lg\:first\:-my-24:first-child{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .lg\:first\:-my-5dot5:first-child{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .lg\:first\:-my-full:first-child{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .lg\:last\:mx-0:last-child{
    margin-left:0;
    margin-right:0
  }

  .lg\:last\:mx-1:last-child{
    margin-left:4px;
    margin-right:4px
  }

  .lg\:last\:mx-2:last-child{
    margin-left:8px;
    margin-right:8px
  }

  .lg\:last\:mx-3:last-child{
    margin-left:12px;
    margin-right:12px
  }

  .lg\:last\:mx-4:last-child{
    margin-left:16px;
    margin-right:16px
  }

  .lg\:last\:mx-5:last-child{
    margin-left:20px;
    margin-right:20px
  }

  .lg\:last\:mx-6:last-child{
    margin-left:24px;
    margin-right:24px
  }

  .lg\:last\:mx-8:last-child{
    margin-left:32px;
    margin-right:32px
  }

  .lg\:last\:mx-10:last-child{
    margin-left:40px;
    margin-right:40px
  }

  .lg\:last\:mx-12:last-child{
    margin-left:48px;
    margin-right:48px
  }

  .lg\:last\:mx-16:last-child{
    margin-left:64px;
    margin-right:64px
  }

  .lg\:last\:mx-18:last-child{
    margin-left:72px;
    margin-right:72px
  }

  .lg\:last\:mx-24:last-child{
    margin-left:96px;
    margin-right:96px
  }

  .lg\:last\:mx-auto:last-child{
    margin-left:auto;
    margin-right:auto
  }

  .lg\:last\:mx-5dot5:last-child{
    margin-left:22px;
    margin-right:22px
  }

  .lg\:last\:mx-full:last-child{
    margin-left:100%;
    margin-right:100%
  }

  .lg\:last\:-mx-1:last-child{
    margin-left:-4px;
    margin-right:-4px
  }

  .lg\:last\:-mx-2:last-child{
    margin-left:-8px;
    margin-right:-8px
  }

  .lg\:last\:-mx-3:last-child{
    margin-left:-12px;
    margin-right:-12px
  }

  .lg\:last\:-mx-4:last-child{
    margin-left:-16px;
    margin-right:-16px
  }

  .lg\:last\:-mx-5:last-child{
    margin-left:-20px;
    margin-right:-20px
  }

  .lg\:last\:-mx-6:last-child{
    margin-left:-24px;
    margin-right:-24px
  }

  .lg\:last\:-mx-8:last-child{
    margin-left:-32px;
    margin-right:-32px
  }

  .lg\:last\:-mx-10:last-child{
    margin-left:-40px;
    margin-right:-40px
  }

  .lg\:last\:-mx-12:last-child{
    margin-left:-48px;
    margin-right:-48px
  }

  .lg\:last\:-mx-16:last-child{
    margin-left:-64px;
    margin-right:-64px
  }

  .lg\:last\:-mx-18:last-child{
    margin-left:-72px;
    margin-right:-72px
  }

  .lg\:last\:-mx-24:last-child{
    margin-left:-96px;
    margin-right:-96px
  }

  .lg\:last\:-mx-5dot5:last-child{
    margin-left:-22px;
    margin-right:-22px
  }

  .lg\:last\:-mx-full:last-child{
    margin-left:-100%;
    margin-right:-100%
  }

  .lg\:last\:my-0:last-child{
    margin-top:0;
    margin-bottom:0
  }

  .lg\:last\:my-1:last-child{
    margin-top:4px;
    margin-bottom:4px
  }

  .lg\:last\:my-2:last-child{
    margin-top:8px;
    margin-bottom:8px
  }

  .lg\:last\:my-3:last-child{
    margin-top:12px;
    margin-bottom:12px
  }

  .lg\:last\:my-4:last-child{
    margin-top:16px;
    margin-bottom:16px
  }

  .lg\:last\:my-5:last-child{
    margin-top:20px;
    margin-bottom:20px
  }

  .lg\:last\:my-6:last-child{
    margin-top:24px;
    margin-bottom:24px
  }

  .lg\:last\:my-8:last-child{
    margin-top:32px;
    margin-bottom:32px
  }

  .lg\:last\:my-10:last-child{
    margin-top:40px;
    margin-bottom:40px
  }

  .lg\:last\:my-12:last-child{
    margin-top:48px;
    margin-bottom:48px
  }

  .lg\:last\:my-16:last-child{
    margin-top:64px;
    margin-bottom:64px
  }

  .lg\:last\:my-18:last-child{
    margin-top:72px;
    margin-bottom:72px
  }

  .lg\:last\:my-24:last-child{
    margin-top:96px;
    margin-bottom:96px
  }

  .lg\:last\:my-auto:last-child{
    margin-top:auto;
    margin-bottom:auto
  }

  .lg\:last\:my-5dot5:last-child{
    margin-top:22px;
    margin-bottom:22px
  }

  .lg\:last\:my-full:last-child{
    margin-top:100%;
    margin-bottom:100%
  }

  .lg\:last\:-my-1:last-child{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .lg\:last\:-my-2:last-child{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .lg\:last\:-my-3:last-child{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .lg\:last\:-my-4:last-child{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .lg\:last\:-my-5:last-child{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .lg\:last\:-my-6:last-child{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .lg\:last\:-my-8:last-child{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .lg\:last\:-my-10:last-child{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .lg\:last\:-my-12:last-child{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .lg\:last\:-my-16:last-child{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .lg\:last\:-my-18:last-child{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .lg\:last\:-my-24:last-child{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .lg\:last\:-my-5dot5:last-child{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .lg\:last\:-my-full:last-child{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .lg\:mt-0{
    margin-top:0
  }

  .lg\:mt-1{
    margin-top:4px
  }

  .lg\:mt-2{
    margin-top:8px
  }

  .lg\:mt-3{
    margin-top:12px
  }

  .lg\:mt-4{
    margin-top:16px
  }

  .lg\:mt-5{
    margin-top:20px
  }

  .lg\:mt-6{
    margin-top:24px
  }

  .lg\:mt-8{
    margin-top:32px
  }

  .lg\:mt-10{
    margin-top:40px
  }

  .lg\:mt-12{
    margin-top:48px
  }

  .lg\:mt-16{
    margin-top:64px
  }

  .lg\:mt-18{
    margin-top:72px
  }

  .lg\:mt-24{
    margin-top:96px
  }

  .lg\:mt-auto{
    margin-top:auto
  }

  .lg\:mt-5dot5{
    margin-top:22px
  }

  .lg\:mt-full{
    margin-top:100%
  }

  .lg\:-mt-1{
    margin-top:-4px
  }

  .lg\:-mt-2{
    margin-top:-8px
  }

  .lg\:-mt-3{
    margin-top:-12px
  }

  .lg\:-mt-4{
    margin-top:-16px
  }

  .lg\:-mt-5{
    margin-top:-20px
  }

  .lg\:-mt-6{
    margin-top:-24px
  }

  .lg\:-mt-8{
    margin-top:-32px
  }

  .lg\:-mt-10{
    margin-top:-40px
  }

  .lg\:-mt-12{
    margin-top:-48px
  }

  .lg\:-mt-16{
    margin-top:-64px
  }

  .lg\:-mt-18{
    margin-top:-72px
  }

  .lg\:-mt-24{
    margin-top:-96px
  }

  .lg\:-mt-5dot5{
    margin-top:-22px
  }

  .lg\:-mt-full{
    margin-top:-100%
  }

  .lg\:mr-0{
    margin-right:0
  }

  .lg\:mr-1{
    margin-right:4px
  }

  .lg\:mr-2{
    margin-right:8px
  }

  .lg\:mr-3{
    margin-right:12px
  }

  .lg\:mr-4{
    margin-right:16px
  }

  .lg\:mr-5{
    margin-right:20px
  }

  .lg\:mr-6{
    margin-right:24px
  }

  .lg\:mr-8{
    margin-right:32px
  }

  .lg\:mr-10{
    margin-right:40px
  }

  .lg\:mr-12{
    margin-right:48px
  }

  .lg\:mr-16{
    margin-right:64px
  }

  .lg\:mr-18{
    margin-right:72px
  }

  .lg\:mr-24{
    margin-right:96px
  }

  .lg\:mr-auto{
    margin-right:auto
  }

  .lg\:mr-5dot5{
    margin-right:22px
  }

  .lg\:mr-full{
    margin-right:100%
  }

  .lg\:-mr-1{
    margin-right:-4px
  }

  .lg\:-mr-2{
    margin-right:-8px
  }

  .lg\:-mr-3{
    margin-right:-12px
  }

  .lg\:-mr-4{
    margin-right:-16px
  }

  .lg\:-mr-5{
    margin-right:-20px
  }

  .lg\:-mr-6{
    margin-right:-24px
  }

  .lg\:-mr-8{
    margin-right:-32px
  }

  .lg\:-mr-10{
    margin-right:-40px
  }

  .lg\:-mr-12{
    margin-right:-48px
  }

  .lg\:-mr-16{
    margin-right:-64px
  }

  .lg\:-mr-18{
    margin-right:-72px
  }

  .lg\:-mr-24{
    margin-right:-96px
  }

  .lg\:-mr-5dot5{
    margin-right:-22px
  }

  .lg\:-mr-full{
    margin-right:-100%
  }

  .lg\:mb-0{
    margin-bottom:0
  }

  .lg\:mb-1{
    margin-bottom:4px
  }

  .lg\:mb-2{
    margin-bottom:8px
  }

  .lg\:mb-3{
    margin-bottom:12px
  }

  .lg\:mb-4{
    margin-bottom:16px
  }

  .lg\:mb-5{
    margin-bottom:20px
  }

  .lg\:mb-6{
    margin-bottom:24px
  }

  .lg\:mb-8{
    margin-bottom:32px
  }

  .lg\:mb-10{
    margin-bottom:40px
  }

  .lg\:mb-12{
    margin-bottom:48px
  }

  .lg\:mb-16{
    margin-bottom:64px
  }

  .lg\:mb-18{
    margin-bottom:72px
  }

  .lg\:mb-24{
    margin-bottom:96px
  }

  .lg\:mb-auto{
    margin-bottom:auto
  }

  .lg\:mb-5dot5{
    margin-bottom:22px
  }

  .lg\:mb-full{
    margin-bottom:100%
  }

  .lg\:-mb-1{
    margin-bottom:-4px
  }

  .lg\:-mb-2{
    margin-bottom:-8px
  }

  .lg\:-mb-3{
    margin-bottom:-12px
  }

  .lg\:-mb-4{
    margin-bottom:-16px
  }

  .lg\:-mb-5{
    margin-bottom:-20px
  }

  .lg\:-mb-6{
    margin-bottom:-24px
  }

  .lg\:-mb-8{
    margin-bottom:-32px
  }

  .lg\:-mb-10{
    margin-bottom:-40px
  }

  .lg\:-mb-12{
    margin-bottom:-48px
  }

  .lg\:-mb-16{
    margin-bottom:-64px
  }

  .lg\:-mb-18{
    margin-bottom:-72px
  }

  .lg\:-mb-24{
    margin-bottom:-96px
  }

  .lg\:-mb-5dot5{
    margin-bottom:-22px
  }

  .lg\:-mb-full{
    margin-bottom:-100%
  }

  .lg\:ml-0{
    margin-left:0
  }

  .lg\:ml-1{
    margin-left:4px
  }

  .lg\:ml-2{
    margin-left:8px
  }

  .lg\:ml-3{
    margin-left:12px
  }

  .lg\:ml-4{
    margin-left:16px
  }

  .lg\:ml-5{
    margin-left:20px
  }

  .lg\:ml-6{
    margin-left:24px
  }

  .lg\:ml-8{
    margin-left:32px
  }

  .lg\:ml-10{
    margin-left:40px
  }

  .lg\:ml-12{
    margin-left:48px
  }

  .lg\:ml-16{
    margin-left:64px
  }

  .lg\:ml-18{
    margin-left:72px
  }

  .lg\:ml-24{
    margin-left:96px
  }

  .lg\:ml-auto{
    margin-left:auto
  }

  .lg\:ml-5dot5{
    margin-left:22px
  }

  .lg\:ml-full{
    margin-left:100%
  }

  .lg\:-ml-1{
    margin-left:-4px
  }

  .lg\:-ml-2{
    margin-left:-8px
  }

  .lg\:-ml-3{
    margin-left:-12px
  }

  .lg\:-ml-4{
    margin-left:-16px
  }

  .lg\:-ml-5{
    margin-left:-20px
  }

  .lg\:-ml-6{
    margin-left:-24px
  }

  .lg\:-ml-8{
    margin-left:-32px
  }

  .lg\:-ml-10{
    margin-left:-40px
  }

  .lg\:-ml-12{
    margin-left:-48px
  }

  .lg\:-ml-16{
    margin-left:-64px
  }

  .lg\:-ml-18{
    margin-left:-72px
  }

  .lg\:-ml-24{
    margin-left:-96px
  }

  .lg\:-ml-5dot5{
    margin-left:-22px
  }

  .lg\:-ml-full{
    margin-left:-100%
  }

  .lg\:first\:mt-0:first-child{
    margin-top:0
  }

  .lg\:first\:mt-1:first-child{
    margin-top:4px
  }

  .lg\:first\:mt-2:first-child{
    margin-top:8px
  }

  .lg\:first\:mt-3:first-child{
    margin-top:12px
  }

  .lg\:first\:mt-4:first-child{
    margin-top:16px
  }

  .lg\:first\:mt-5:first-child{
    margin-top:20px
  }

  .lg\:first\:mt-6:first-child{
    margin-top:24px
  }

  .lg\:first\:mt-8:first-child{
    margin-top:32px
  }

  .lg\:first\:mt-10:first-child{
    margin-top:40px
  }

  .lg\:first\:mt-12:first-child{
    margin-top:48px
  }

  .lg\:first\:mt-16:first-child{
    margin-top:64px
  }

  .lg\:first\:mt-18:first-child{
    margin-top:72px
  }

  .lg\:first\:mt-24:first-child{
    margin-top:96px
  }

  .lg\:first\:mt-auto:first-child{
    margin-top:auto
  }

  .lg\:first\:mt-5dot5:first-child{
    margin-top:22px
  }

  .lg\:first\:mt-full:first-child{
    margin-top:100%
  }

  .lg\:first\:-mt-1:first-child{
    margin-top:-4px
  }

  .lg\:first\:-mt-2:first-child{
    margin-top:-8px
  }

  .lg\:first\:-mt-3:first-child{
    margin-top:-12px
  }

  .lg\:first\:-mt-4:first-child{
    margin-top:-16px
  }

  .lg\:first\:-mt-5:first-child{
    margin-top:-20px
  }

  .lg\:first\:-mt-6:first-child{
    margin-top:-24px
  }

  .lg\:first\:-mt-8:first-child{
    margin-top:-32px
  }

  .lg\:first\:-mt-10:first-child{
    margin-top:-40px
  }

  .lg\:first\:-mt-12:first-child{
    margin-top:-48px
  }

  .lg\:first\:-mt-16:first-child{
    margin-top:-64px
  }

  .lg\:first\:-mt-18:first-child{
    margin-top:-72px
  }

  .lg\:first\:-mt-24:first-child{
    margin-top:-96px
  }

  .lg\:first\:-mt-5dot5:first-child{
    margin-top:-22px
  }

  .lg\:first\:-mt-full:first-child{
    margin-top:-100%
  }

  .lg\:first\:mr-0:first-child{
    margin-right:0
  }

  .lg\:first\:mr-1:first-child{
    margin-right:4px
  }

  .lg\:first\:mr-2:first-child{
    margin-right:8px
  }

  .lg\:first\:mr-3:first-child{
    margin-right:12px
  }

  .lg\:first\:mr-4:first-child{
    margin-right:16px
  }

  .lg\:first\:mr-5:first-child{
    margin-right:20px
  }

  .lg\:first\:mr-6:first-child{
    margin-right:24px
  }

  .lg\:first\:mr-8:first-child{
    margin-right:32px
  }

  .lg\:first\:mr-10:first-child{
    margin-right:40px
  }

  .lg\:first\:mr-12:first-child{
    margin-right:48px
  }

  .lg\:first\:mr-16:first-child{
    margin-right:64px
  }

  .lg\:first\:mr-18:first-child{
    margin-right:72px
  }

  .lg\:first\:mr-24:first-child{
    margin-right:96px
  }

  .lg\:first\:mr-auto:first-child{
    margin-right:auto
  }

  .lg\:first\:mr-5dot5:first-child{
    margin-right:22px
  }

  .lg\:first\:mr-full:first-child{
    margin-right:100%
  }

  .lg\:first\:-mr-1:first-child{
    margin-right:-4px
  }

  .lg\:first\:-mr-2:first-child{
    margin-right:-8px
  }

  .lg\:first\:-mr-3:first-child{
    margin-right:-12px
  }

  .lg\:first\:-mr-4:first-child{
    margin-right:-16px
  }

  .lg\:first\:-mr-5:first-child{
    margin-right:-20px
  }

  .lg\:first\:-mr-6:first-child{
    margin-right:-24px
  }

  .lg\:first\:-mr-8:first-child{
    margin-right:-32px
  }

  .lg\:first\:-mr-10:first-child{
    margin-right:-40px
  }

  .lg\:first\:-mr-12:first-child{
    margin-right:-48px
  }

  .lg\:first\:-mr-16:first-child{
    margin-right:-64px
  }

  .lg\:first\:-mr-18:first-child{
    margin-right:-72px
  }

  .lg\:first\:-mr-24:first-child{
    margin-right:-96px
  }

  .lg\:first\:-mr-5dot5:first-child{
    margin-right:-22px
  }

  .lg\:first\:-mr-full:first-child{
    margin-right:-100%
  }

  .lg\:first\:mb-0:first-child{
    margin-bottom:0
  }

  .lg\:first\:mb-1:first-child{
    margin-bottom:4px
  }

  .lg\:first\:mb-2:first-child{
    margin-bottom:8px
  }

  .lg\:first\:mb-3:first-child{
    margin-bottom:12px
  }

  .lg\:first\:mb-4:first-child{
    margin-bottom:16px
  }

  .lg\:first\:mb-5:first-child{
    margin-bottom:20px
  }

  .lg\:first\:mb-6:first-child{
    margin-bottom:24px
  }

  .lg\:first\:mb-8:first-child{
    margin-bottom:32px
  }

  .lg\:first\:mb-10:first-child{
    margin-bottom:40px
  }

  .lg\:first\:mb-12:first-child{
    margin-bottom:48px
  }

  .lg\:first\:mb-16:first-child{
    margin-bottom:64px
  }

  .lg\:first\:mb-18:first-child{
    margin-bottom:72px
  }

  .lg\:first\:mb-24:first-child{
    margin-bottom:96px
  }

  .lg\:first\:mb-auto:first-child{
    margin-bottom:auto
  }

  .lg\:first\:mb-5dot5:first-child{
    margin-bottom:22px
  }

  .lg\:first\:mb-full:first-child{
    margin-bottom:100%
  }

  .lg\:first\:-mb-1:first-child{
    margin-bottom:-4px
  }

  .lg\:first\:-mb-2:first-child{
    margin-bottom:-8px
  }

  .lg\:first\:-mb-3:first-child{
    margin-bottom:-12px
  }

  .lg\:first\:-mb-4:first-child{
    margin-bottom:-16px
  }

  .lg\:first\:-mb-5:first-child{
    margin-bottom:-20px
  }

  .lg\:first\:-mb-6:first-child{
    margin-bottom:-24px
  }

  .lg\:first\:-mb-8:first-child{
    margin-bottom:-32px
  }

  .lg\:first\:-mb-10:first-child{
    margin-bottom:-40px
  }

  .lg\:first\:-mb-12:first-child{
    margin-bottom:-48px
  }

  .lg\:first\:-mb-16:first-child{
    margin-bottom:-64px
  }

  .lg\:first\:-mb-18:first-child{
    margin-bottom:-72px
  }

  .lg\:first\:-mb-24:first-child{
    margin-bottom:-96px
  }

  .lg\:first\:-mb-5dot5:first-child{
    margin-bottom:-22px
  }

  .lg\:first\:-mb-full:first-child{
    margin-bottom:-100%
  }

  .lg\:first\:ml-0:first-child{
    margin-left:0
  }

  .lg\:first\:ml-1:first-child{
    margin-left:4px
  }

  .lg\:first\:ml-2:first-child{
    margin-left:8px
  }

  .lg\:first\:ml-3:first-child{
    margin-left:12px
  }

  .lg\:first\:ml-4:first-child{
    margin-left:16px
  }

  .lg\:first\:ml-5:first-child{
    margin-left:20px
  }

  .lg\:first\:ml-6:first-child{
    margin-left:24px
  }

  .lg\:first\:ml-8:first-child{
    margin-left:32px
  }

  .lg\:first\:ml-10:first-child{
    margin-left:40px
  }

  .lg\:first\:ml-12:first-child{
    margin-left:48px
  }

  .lg\:first\:ml-16:first-child{
    margin-left:64px
  }

  .lg\:first\:ml-18:first-child{
    margin-left:72px
  }

  .lg\:first\:ml-24:first-child{
    margin-left:96px
  }

  .lg\:first\:ml-auto:first-child{
    margin-left:auto
  }

  .lg\:first\:ml-5dot5:first-child{
    margin-left:22px
  }

  .lg\:first\:ml-full:first-child{
    margin-left:100%
  }

  .lg\:first\:-ml-1:first-child{
    margin-left:-4px
  }

  .lg\:first\:-ml-2:first-child{
    margin-left:-8px
  }

  .lg\:first\:-ml-3:first-child{
    margin-left:-12px
  }

  .lg\:first\:-ml-4:first-child{
    margin-left:-16px
  }

  .lg\:first\:-ml-5:first-child{
    margin-left:-20px
  }

  .lg\:first\:-ml-6:first-child{
    margin-left:-24px
  }

  .lg\:first\:-ml-8:first-child{
    margin-left:-32px
  }

  .lg\:first\:-ml-10:first-child{
    margin-left:-40px
  }

  .lg\:first\:-ml-12:first-child{
    margin-left:-48px
  }

  .lg\:first\:-ml-16:first-child{
    margin-left:-64px
  }

  .lg\:first\:-ml-18:first-child{
    margin-left:-72px
  }

  .lg\:first\:-ml-24:first-child{
    margin-left:-96px
  }

  .lg\:first\:-ml-5dot5:first-child{
    margin-left:-22px
  }

  .lg\:first\:-ml-full:first-child{
    margin-left:-100%
  }

  .lg\:last\:mt-0:last-child{
    margin-top:0
  }

  .lg\:last\:mt-1:last-child{
    margin-top:4px
  }

  .lg\:last\:mt-2:last-child{
    margin-top:8px
  }

  .lg\:last\:mt-3:last-child{
    margin-top:12px
  }

  .lg\:last\:mt-4:last-child{
    margin-top:16px
  }

  .lg\:last\:mt-5:last-child{
    margin-top:20px
  }

  .lg\:last\:mt-6:last-child{
    margin-top:24px
  }

  .lg\:last\:mt-8:last-child{
    margin-top:32px
  }

  .lg\:last\:mt-10:last-child{
    margin-top:40px
  }

  .lg\:last\:mt-12:last-child{
    margin-top:48px
  }

  .lg\:last\:mt-16:last-child{
    margin-top:64px
  }

  .lg\:last\:mt-18:last-child{
    margin-top:72px
  }

  .lg\:last\:mt-24:last-child{
    margin-top:96px
  }

  .lg\:last\:mt-auto:last-child{
    margin-top:auto
  }

  .lg\:last\:mt-5dot5:last-child{
    margin-top:22px
  }

  .lg\:last\:mt-full:last-child{
    margin-top:100%
  }

  .lg\:last\:-mt-1:last-child{
    margin-top:-4px
  }

  .lg\:last\:-mt-2:last-child{
    margin-top:-8px
  }

  .lg\:last\:-mt-3:last-child{
    margin-top:-12px
  }

  .lg\:last\:-mt-4:last-child{
    margin-top:-16px
  }

  .lg\:last\:-mt-5:last-child{
    margin-top:-20px
  }

  .lg\:last\:-mt-6:last-child{
    margin-top:-24px
  }

  .lg\:last\:-mt-8:last-child{
    margin-top:-32px
  }

  .lg\:last\:-mt-10:last-child{
    margin-top:-40px
  }

  .lg\:last\:-mt-12:last-child{
    margin-top:-48px
  }

  .lg\:last\:-mt-16:last-child{
    margin-top:-64px
  }

  .lg\:last\:-mt-18:last-child{
    margin-top:-72px
  }

  .lg\:last\:-mt-24:last-child{
    margin-top:-96px
  }

  .lg\:last\:-mt-5dot5:last-child{
    margin-top:-22px
  }

  .lg\:last\:-mt-full:last-child{
    margin-top:-100%
  }

  .lg\:last\:mr-0:last-child{
    margin-right:0
  }

  .lg\:last\:mr-1:last-child{
    margin-right:4px
  }

  .lg\:last\:mr-2:last-child{
    margin-right:8px
  }

  .lg\:last\:mr-3:last-child{
    margin-right:12px
  }

  .lg\:last\:mr-4:last-child{
    margin-right:16px
  }

  .lg\:last\:mr-5:last-child{
    margin-right:20px
  }

  .lg\:last\:mr-6:last-child{
    margin-right:24px
  }

  .lg\:last\:mr-8:last-child{
    margin-right:32px
  }

  .lg\:last\:mr-10:last-child{
    margin-right:40px
  }

  .lg\:last\:mr-12:last-child{
    margin-right:48px
  }

  .lg\:last\:mr-16:last-child{
    margin-right:64px
  }

  .lg\:last\:mr-18:last-child{
    margin-right:72px
  }

  .lg\:last\:mr-24:last-child{
    margin-right:96px
  }

  .lg\:last\:mr-auto:last-child{
    margin-right:auto
  }

  .lg\:last\:mr-5dot5:last-child{
    margin-right:22px
  }

  .lg\:last\:mr-full:last-child{
    margin-right:100%
  }

  .lg\:last\:-mr-1:last-child{
    margin-right:-4px
  }

  .lg\:last\:-mr-2:last-child{
    margin-right:-8px
  }

  .lg\:last\:-mr-3:last-child{
    margin-right:-12px
  }

  .lg\:last\:-mr-4:last-child{
    margin-right:-16px
  }

  .lg\:last\:-mr-5:last-child{
    margin-right:-20px
  }

  .lg\:last\:-mr-6:last-child{
    margin-right:-24px
  }

  .lg\:last\:-mr-8:last-child{
    margin-right:-32px
  }

  .lg\:last\:-mr-10:last-child{
    margin-right:-40px
  }

  .lg\:last\:-mr-12:last-child{
    margin-right:-48px
  }

  .lg\:last\:-mr-16:last-child{
    margin-right:-64px
  }

  .lg\:last\:-mr-18:last-child{
    margin-right:-72px
  }

  .lg\:last\:-mr-24:last-child{
    margin-right:-96px
  }

  .lg\:last\:-mr-5dot5:last-child{
    margin-right:-22px
  }

  .lg\:last\:-mr-full:last-child{
    margin-right:-100%
  }

  .lg\:last\:mb-0:last-child{
    margin-bottom:0
  }

  .lg\:last\:mb-1:last-child{
    margin-bottom:4px
  }

  .lg\:last\:mb-2:last-child{
    margin-bottom:8px
  }

  .lg\:last\:mb-3:last-child{
    margin-bottom:12px
  }

  .lg\:last\:mb-4:last-child{
    margin-bottom:16px
  }

  .lg\:last\:mb-5:last-child{
    margin-bottom:20px
  }

  .lg\:last\:mb-6:last-child{
    margin-bottom:24px
  }

  .lg\:last\:mb-8:last-child{
    margin-bottom:32px
  }

  .lg\:last\:mb-10:last-child{
    margin-bottom:40px
  }

  .lg\:last\:mb-12:last-child{
    margin-bottom:48px
  }

  .lg\:last\:mb-16:last-child{
    margin-bottom:64px
  }

  .lg\:last\:mb-18:last-child{
    margin-bottom:72px
  }

  .lg\:last\:mb-24:last-child{
    margin-bottom:96px
  }

  .lg\:last\:mb-auto:last-child{
    margin-bottom:auto
  }

  .lg\:last\:mb-5dot5:last-child{
    margin-bottom:22px
  }

  .lg\:last\:mb-full:last-child{
    margin-bottom:100%
  }

  .lg\:last\:-mb-1:last-child{
    margin-bottom:-4px
  }

  .lg\:last\:-mb-2:last-child{
    margin-bottom:-8px
  }

  .lg\:last\:-mb-3:last-child{
    margin-bottom:-12px
  }

  .lg\:last\:-mb-4:last-child{
    margin-bottom:-16px
  }

  .lg\:last\:-mb-5:last-child{
    margin-bottom:-20px
  }

  .lg\:last\:-mb-6:last-child{
    margin-bottom:-24px
  }

  .lg\:last\:-mb-8:last-child{
    margin-bottom:-32px
  }

  .lg\:last\:-mb-10:last-child{
    margin-bottom:-40px
  }

  .lg\:last\:-mb-12:last-child{
    margin-bottom:-48px
  }

  .lg\:last\:-mb-16:last-child{
    margin-bottom:-64px
  }

  .lg\:last\:-mb-18:last-child{
    margin-bottom:-72px
  }

  .lg\:last\:-mb-24:last-child{
    margin-bottom:-96px
  }

  .lg\:last\:-mb-5dot5:last-child{
    margin-bottom:-22px
  }

  .lg\:last\:-mb-full:last-child{
    margin-bottom:-100%
  }

  .lg\:last\:ml-0:last-child{
    margin-left:0
  }

  .lg\:last\:ml-1:last-child{
    margin-left:4px
  }

  .lg\:last\:ml-2:last-child{
    margin-left:8px
  }

  .lg\:last\:ml-3:last-child{
    margin-left:12px
  }

  .lg\:last\:ml-4:last-child{
    margin-left:16px
  }

  .lg\:last\:ml-5:last-child{
    margin-left:20px
  }

  .lg\:last\:ml-6:last-child{
    margin-left:24px
  }

  .lg\:last\:ml-8:last-child{
    margin-left:32px
  }

  .lg\:last\:ml-10:last-child{
    margin-left:40px
  }

  .lg\:last\:ml-12:last-child{
    margin-left:48px
  }

  .lg\:last\:ml-16:last-child{
    margin-left:64px
  }

  .lg\:last\:ml-18:last-child{
    margin-left:72px
  }

  .lg\:last\:ml-24:last-child{
    margin-left:96px
  }

  .lg\:last\:ml-auto:last-child{
    margin-left:auto
  }

  .lg\:last\:ml-5dot5:last-child{
    margin-left:22px
  }

  .lg\:last\:ml-full:last-child{
    margin-left:100%
  }

  .lg\:last\:-ml-1:last-child{
    margin-left:-4px
  }

  .lg\:last\:-ml-2:last-child{
    margin-left:-8px
  }

  .lg\:last\:-ml-3:last-child{
    margin-left:-12px
  }

  .lg\:last\:-ml-4:last-child{
    margin-left:-16px
  }

  .lg\:last\:-ml-5:last-child{
    margin-left:-20px
  }

  .lg\:last\:-ml-6:last-child{
    margin-left:-24px
  }

  .lg\:last\:-ml-8:last-child{
    margin-left:-32px
  }

  .lg\:last\:-ml-10:last-child{
    margin-left:-40px
  }

  .lg\:last\:-ml-12:last-child{
    margin-left:-48px
  }

  .lg\:last\:-ml-16:last-child{
    margin-left:-64px
  }

  .lg\:last\:-ml-18:last-child{
    margin-left:-72px
  }

  .lg\:last\:-ml-24:last-child{
    margin-left:-96px
  }

  .lg\:last\:-ml-5dot5:last-child{
    margin-left:-22px
  }

  .lg\:last\:-ml-full:last-child{
    margin-left:-100%
  }

  .lg\:block{
    display:block
  }

  .lg\:inline-block{
    display:inline-block
  }

  .lg\:inline{
    display:inline
  }

  .lg\:flex{
    display:flex
  }

  .lg\:inline-flex{
    display:inline-flex
  }

  .lg\:table{
    display:table
  }

  .lg\:inline-table{
    display:inline-table
  }

  .lg\:table-caption{
    display:table-caption
  }

  .lg\:table-cell{
    display:table-cell
  }

  .lg\:table-column{
    display:table-column
  }

  .lg\:table-column-group{
    display:table-column-group
  }

  .lg\:table-footer-group{
    display:table-footer-group
  }

  .lg\:table-header-group{
    display:table-header-group
  }

  .lg\:table-row-group{
    display:table-row-group
  }

  .lg\:table-row{
    display:table-row
  }

  .lg\:flow-root{
    display:flow-root
  }

  .lg\:grid{
    display:grid
  }

  .lg\:inline-grid{
    display:inline-grid
  }

  .lg\:contents{
    display:contents
  }

  .lg\:list-item{
    display:list-item
  }

  .lg\:hidden{
    display:none
  }

  .lg\:h-0{
    height:0
  }

  .lg\:h-1{
    height:4px
  }

  .lg\:h-2{
    height:8px
  }

  .lg\:h-3{
    height:12px
  }

  .lg\:h-4{
    height:16px
  }

  .lg\:h-5{
    height:20px
  }

  .lg\:h-6{
    height:24px
  }

  .lg\:h-8{
    height:32px
  }

  .lg\:h-10{
    height:40px
  }

  .lg\:h-12{
    height:48px
  }

  .lg\:h-15{
    height:60px
  }

  .lg\:h-16{
    height:64px
  }

  .lg\:h-18{
    height:72px
  }

  .lg\:h-24{
    height:96px
  }

  .lg\:h-auto{
    height:auto
  }

  .lg\:h-5dot5{
    height:22px
  }

  .lg\:h-full{
    height:100%
  }

  .lg\:h-1\/2{
    height:50%
  }

  .lg\:h-1\/3{
    height:33.333333%
  }

  .lg\:h-2\/3{
    height:66.666667%
  }

  .lg\:h-1\/4{
    height:25%
  }

  .lg\:h-2\/4{
    height:50%
  }

  .lg\:h-3\/4{
    height:75%
  }

  .lg\:h-1\/5{
    height:20%
  }

  .lg\:h-2\/5{
    height:40%
  }

  .lg\:h-3\/5{
    height:60%
  }

  .lg\:h-4\/5{
    height:80%
  }

  .lg\:h-1\/6{
    height:16.666667%
  }

  .lg\:h-2\/6{
    height:33.333333%
  }

  .lg\:h-3\/6{
    height:50%
  }

  .lg\:h-4\/6{
    height:66.666667%
  }

  .lg\:h-5\/6{
    height:83.333333%
  }

  .lg\:h-screen{
    height:100vh
  }

  .lg\:max-h-0{
    max-height:0
  }

  .lg\:max-h-1{
    max-height:4px
  }

  .lg\:max-h-2{
    max-height:8px
  }

  .lg\:max-h-3{
    max-height:12px
  }

  .lg\:max-h-4{
    max-height:16px
  }

  .lg\:max-h-5{
    max-height:20px
  }

  .lg\:max-h-6{
    max-height:24px
  }

  .lg\:max-h-8{
    max-height:32px
  }

  .lg\:max-h-10{
    max-height:40px
  }

  .lg\:max-h-12{
    max-height:48px
  }

  .lg\:max-h-16{
    max-height:64px
  }

  .lg\:max-h-18{
    max-height:72px
  }

  .lg\:max-h-24{
    max-height:96px
  }

  .lg\:max-h-5dot5{
    max-height:22px
  }

  .lg\:max-h-full{
    max-height:100%
  }

  .lg\:max-h-screen{
    max-height:100vh
  }

  .lg\:min-h-0{
    min-height:0px
  }

  .lg\:min-h-6{
    min-height:24px
  }

  .lg\:min-h-10{
    min-height:40px
  }

  .lg\:min-h-15{
    min-height:60px
  }

  .lg\:min-h-100{
    min-height:400px
  }

  .lg\:min-h-full{
    min-height:100%
  }

  .lg\:min-h-screen{
    min-height:100vh
  }

  .lg\:min-h-100vh{
    min-height:100vh
  }

  .lg\:w-0{
    width:0
  }

  .lg\:w-1{
    width:4px
  }

  .lg\:w-2{
    width:8px
  }

  .lg\:w-3{
    width:12px
  }

  .lg\:w-4{
    width:16px
  }

  .lg\:w-5{
    width:20px
  }

  .lg\:w-6{
    width:24px
  }

  .lg\:w-8{
    width:32px
  }

  .lg\:w-10{
    width:40px
  }

  .lg\:w-12{
    width:48px
  }

  .lg\:w-16{
    width:64px
  }

  .lg\:w-18{
    width:72px
  }

  .lg\:w-24{
    width:96px
  }

  .lg\:w-25{
    width:100px
  }

  .lg\:w-26{
    width:104px
  }

  .lg\:w-30{
    width:120px
  }

  .lg\:w-80{
    width:320px
  }

  .lg\:w-125{
    width:500px
  }

  .lg\:w-160{
    width:640px
  }

  .lg\:w-180{
    width:720px
  }

  .lg\:w-200{
    width:800px
  }

  .lg\:w-240{
    width:960px
  }

  .lg\:w-320{
    width:1280px
  }

  .lg\:w-auto{
    width:auto
  }

  .lg\:w-5dot5{
    width:22px
  }

  .lg\:w-full{
    width:100%
  }

  .lg\:w-1\/2{
    width:50%
  }

  .lg\:w-1\/3{
    width:33.333333%
  }

  .lg\:w-2\/3{
    width:66.666667%
  }

  .lg\:w-1\/4{
    width:25%
  }

  .lg\:w-2\/4{
    width:50%
  }

  .lg\:w-3\/4{
    width:75%
  }

  .lg\:w-1\/5{
    width:20%
  }

  .lg\:w-2\/5{
    width:40%
  }

  .lg\:w-3\/5{
    width:60%
  }

  .lg\:w-4\/5{
    width:80%
  }

  .lg\:w-1\/6{
    width:16.666667%
  }

  .lg\:w-2\/6{
    width:33.333333%
  }

  .lg\:w-3\/6{
    width:50%
  }

  .lg\:w-4\/6{
    width:66.666667%
  }

  .lg\:w-5\/6{
    width:83.333333%
  }

  .lg\:w-1\/12{
    width:8.333333%
  }

  .lg\:w-2\/12{
    width:16.666667%
  }

  .lg\:w-3\/12{
    width:25%
  }

  .lg\:w-4\/12{
    width:33.333333%
  }

  .lg\:w-5\/12{
    width:41.666667%
  }

  .lg\:w-6\/12{
    width:50%
  }

  .lg\:w-7\/12{
    width:58.333333%
  }

  .lg\:w-8\/12{
    width:66.666667%
  }

  .lg\:w-9\/12{
    width:75%
  }

  .lg\:w-10\/12{
    width:83.333333%
  }

  .lg\:w-11\/12{
    width:91.666667%
  }

  .lg\:w-screen{
    width:100vw
  }

  .lg\:w-min{
    width:min-content
  }

  .lg\:w-max{
    width:max-content
  }

  .lg\:min-w-0{
    min-width:0px
  }

  .lg\:min-w-full{
    min-width:100%
  }

  .lg\:min-w-min{
    min-width:min-content
  }

  .lg\:min-w-max{
    min-width:max-content
  }

  .lg\:max-w-0{
    max-width:0rem
  }

  .lg\:max-w-none{
    max-width:none
  }

  .lg\:max-w-xs{
    max-width:20rem
  }

  .lg\:max-w-sm{
    max-width:24rem
  }

  .lg\:max-w-md{
    max-width:28rem
  }

  .lg\:max-w-lg{
    max-width:32rem
  }

  .lg\:max-w-xl{
    max-width:36rem
  }

  .lg\:max-w-2xl{
    max-width:42rem
  }

  .lg\:max-w-3xl{
    max-width:48rem
  }

  .lg\:max-w-4xl{
    max-width:56rem
  }

  .lg\:max-w-5xl{
    max-width:64rem
  }

  .lg\:max-w-6xl{
    max-width:72rem
  }

  .lg\:max-w-7xl{
    max-width:80rem
  }

  .lg\:max-w-full{
    max-width:100%
  }

  .lg\:max-w-min{
    max-width:min-content
  }

  .lg\:max-w-max{
    max-width:max-content
  }

  .lg\:max-w-prose{
    max-width:65ch
  }

  .lg\:max-w-grid-12{
    max-width:1176px
  }

  .lg\:max-w-grid-10{
    max-width:984px
  }

  .lg\:max-w-grid-8{
    max-width:768px
  }

  .lg\:max-w-1\/4{
    max-width:25%
  }

  .lg\:max-w-1\/2{
    max-width:50%
  }

  .lg\:max-w-3\/4{
    max-width:75%
  }

  .lg\:max-w-1\/5{
    max-width:20%
  }

  .lg\:max-w-2\/5{
    max-width:40%
  }

  .lg\:max-w-3\/5{
    max-width:60%
  }

  .lg\:max-w-4\/5{
    max-width:80%
  }

  .lg\:max-w-100vw{
    max-width:100vw
  }

  .lg\:flex-1{
    flex:1 1 0%
  }

  .lg\:flex-2{
    flex:2 1 0%
  }

  .lg\:flex-3{
    flex:3 1 0%
  }

  .lg\:flex-auto{
    flex:1 1 auto
  }

  .lg\:flex-initial{
    flex:0 1 auto
  }

  .lg\:flex-none{
    flex:none
  }

  .lg\:flex-shrink-0{
    flex-shrink:0
  }

  .lg\:flex-shrink{
    flex-shrink:1
  }

  .lg\:flex-grow-0{
    flex-grow:0
  }

  .lg\:flex-grow{
    flex-grow:1
  }

  .lg\:border-collapse{
    border-collapse:collapse
  }

  .lg\:border-separate{
    border-collapse:separate
  }

  .lg\:transform{
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .lg\:transform-gpu{
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    transform:translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .lg\:transform-none{
    transform:none
  }

  .lg\:translate-x-0{
    --tw-translate-x:0
  }

  .lg\:translate-x-1{
    --tw-translate-x:4px
  }

  .lg\:translate-x-2{
    --tw-translate-x:8px
  }

  .lg\:translate-x-3{
    --tw-translate-x:12px
  }

  .lg\:translate-x-4{
    --tw-translate-x:16px
  }

  .lg\:translate-x-5{
    --tw-translate-x:20px
  }

  .lg\:translate-x-6{
    --tw-translate-x:24px
  }

  .lg\:translate-x-8{
    --tw-translate-x:32px
  }

  .lg\:translate-x-10{
    --tw-translate-x:40px
  }

  .lg\:translate-x-12{
    --tw-translate-x:48px
  }

  .lg\:translate-x-16{
    --tw-translate-x:64px
  }

  .lg\:translate-x-18{
    --tw-translate-x:72px
  }

  .lg\:translate-x-24{
    --tw-translate-x:96px
  }

  .lg\:translate-x-5dot5{
    --tw-translate-x:22px
  }

  .lg\:translate-x-full{
    --tw-translate-x:100%
  }

  .lg\:-translate-x-1{
    --tw-translate-x:-4px
  }

  .lg\:-translate-x-2{
    --tw-translate-x:-8px
  }

  .lg\:-translate-x-3{
    --tw-translate-x:-12px
  }

  .lg\:-translate-x-4{
    --tw-translate-x:-16px
  }

  .lg\:-translate-x-5{
    --tw-translate-x:-20px
  }

  .lg\:-translate-x-6{
    --tw-translate-x:-24px
  }

  .lg\:-translate-x-8{
    --tw-translate-x:-32px
  }

  .lg\:-translate-x-10{
    --tw-translate-x:-40px
  }

  .lg\:-translate-x-12{
    --tw-translate-x:-48px
  }

  .lg\:-translate-x-16{
    --tw-translate-x:-64px
  }

  .lg\:-translate-x-18{
    --tw-translate-x:-72px
  }

  .lg\:-translate-x-24{
    --tw-translate-x:-96px
  }

  .lg\:-translate-x-5dot5{
    --tw-translate-x:-22px
  }

  .lg\:-translate-x-full{
    --tw-translate-x:-100%
  }

  .lg\:translate-x-1\/2{
    --tw-translate-x:50%
  }

  .lg\:translate-x-1\/3{
    --tw-translate-x:33.333333%
  }

  .lg\:translate-x-2\/3{
    --tw-translate-x:66.666667%
  }

  .lg\:translate-x-1\/4{
    --tw-translate-x:25%
  }

  .lg\:translate-x-2\/4{
    --tw-translate-x:50%
  }

  .lg\:translate-x-3\/4{
    --tw-translate-x:75%
  }

  .lg\:-translate-x-1\/2{
    --tw-translate-x:-50%
  }

  .lg\:-translate-x-1\/3{
    --tw-translate-x:-33.333333%
  }

  .lg\:-translate-x-2\/3{
    --tw-translate-x:-66.666667%
  }

  .lg\:-translate-x-1\/4{
    --tw-translate-x:-25%
  }

  .lg\:-translate-x-2\/4{
    --tw-translate-x:-50%
  }

  .lg\:-translate-x-3\/4{
    --tw-translate-x:-75%
  }

  .lg\:translate-y-0{
    --tw-translate-y:0
  }

  .lg\:translate-y-1{
    --tw-translate-y:4px
  }

  .lg\:translate-y-2{
    --tw-translate-y:8px
  }

  .lg\:translate-y-3{
    --tw-translate-y:12px
  }

  .lg\:translate-y-4{
    --tw-translate-y:16px
  }

  .lg\:translate-y-5{
    --tw-translate-y:20px
  }

  .lg\:translate-y-6{
    --tw-translate-y:24px
  }

  .lg\:translate-y-8{
    --tw-translate-y:32px
  }

  .lg\:translate-y-10{
    --tw-translate-y:40px
  }

  .lg\:translate-y-12{
    --tw-translate-y:48px
  }

  .lg\:translate-y-16{
    --tw-translate-y:64px
  }

  .lg\:translate-y-18{
    --tw-translate-y:72px
  }

  .lg\:translate-y-24{
    --tw-translate-y:96px
  }

  .lg\:translate-y-5dot5{
    --tw-translate-y:22px
  }

  .lg\:translate-y-full{
    --tw-translate-y:100%
  }

  .lg\:-translate-y-1{
    --tw-translate-y:-4px
  }

  .lg\:-translate-y-2{
    --tw-translate-y:-8px
  }

  .lg\:-translate-y-3{
    --tw-translate-y:-12px
  }

  .lg\:-translate-y-4{
    --tw-translate-y:-16px
  }

  .lg\:-translate-y-5{
    --tw-translate-y:-20px
  }

  .lg\:-translate-y-6{
    --tw-translate-y:-24px
  }

  .lg\:-translate-y-8{
    --tw-translate-y:-32px
  }

  .lg\:-translate-y-10{
    --tw-translate-y:-40px
  }

  .lg\:-translate-y-12{
    --tw-translate-y:-48px
  }

  .lg\:-translate-y-16{
    --tw-translate-y:-64px
  }

  .lg\:-translate-y-18{
    --tw-translate-y:-72px
  }

  .lg\:-translate-y-24{
    --tw-translate-y:-96px
  }

  .lg\:-translate-y-5dot5{
    --tw-translate-y:-22px
  }

  .lg\:-translate-y-full{
    --tw-translate-y:-100%
  }

  .lg\:translate-y-1\/2{
    --tw-translate-y:50%
  }

  .lg\:translate-y-1\/3{
    --tw-translate-y:33.333333%
  }

  .lg\:translate-y-2\/3{
    --tw-translate-y:66.666667%
  }

  .lg\:translate-y-1\/4{
    --tw-translate-y:25%
  }

  .lg\:translate-y-2\/4{
    --tw-translate-y:50%
  }

  .lg\:translate-y-3\/4{
    --tw-translate-y:75%
  }

  .lg\:-translate-y-1\/2{
    --tw-translate-y:-50%
  }

  .lg\:-translate-y-1\/3{
    --tw-translate-y:-33.333333%
  }

  .lg\:-translate-y-2\/3{
    --tw-translate-y:-66.666667%
  }

  .lg\:-translate-y-1\/4{
    --tw-translate-y:-25%
  }

  .lg\:-translate-y-2\/4{
    --tw-translate-y:-50%
  }

  .lg\:-translate-y-3\/4{
    --tw-translate-y:-75%
  }

  .lg\:hover\:translate-x-0:hover{
    --tw-translate-x:0
  }

  .lg\:hover\:translate-x-1:hover{
    --tw-translate-x:4px
  }

  .lg\:hover\:translate-x-2:hover{
    --tw-translate-x:8px
  }

  .lg\:hover\:translate-x-3:hover{
    --tw-translate-x:12px
  }

  .lg\:hover\:translate-x-4:hover{
    --tw-translate-x:16px
  }

  .lg\:hover\:translate-x-5:hover{
    --tw-translate-x:20px
  }

  .lg\:hover\:translate-x-6:hover{
    --tw-translate-x:24px
  }

  .lg\:hover\:translate-x-8:hover{
    --tw-translate-x:32px
  }

  .lg\:hover\:translate-x-10:hover{
    --tw-translate-x:40px
  }

  .lg\:hover\:translate-x-12:hover{
    --tw-translate-x:48px
  }

  .lg\:hover\:translate-x-16:hover{
    --tw-translate-x:64px
  }

  .lg\:hover\:translate-x-18:hover{
    --tw-translate-x:72px
  }

  .lg\:hover\:translate-x-24:hover{
    --tw-translate-x:96px
  }

  .lg\:hover\:translate-x-5dot5:hover{
    --tw-translate-x:22px
  }

  .lg\:hover\:translate-x-full:hover{
    --tw-translate-x:100%
  }

  .lg\:hover\:-translate-x-1:hover{
    --tw-translate-x:-4px
  }

  .lg\:hover\:-translate-x-2:hover{
    --tw-translate-x:-8px
  }

  .lg\:hover\:-translate-x-3:hover{
    --tw-translate-x:-12px
  }

  .lg\:hover\:-translate-x-4:hover{
    --tw-translate-x:-16px
  }

  .lg\:hover\:-translate-x-5:hover{
    --tw-translate-x:-20px
  }

  .lg\:hover\:-translate-x-6:hover{
    --tw-translate-x:-24px
  }

  .lg\:hover\:-translate-x-8:hover{
    --tw-translate-x:-32px
  }

  .lg\:hover\:-translate-x-10:hover{
    --tw-translate-x:-40px
  }

  .lg\:hover\:-translate-x-12:hover{
    --tw-translate-x:-48px
  }

  .lg\:hover\:-translate-x-16:hover{
    --tw-translate-x:-64px
  }

  .lg\:hover\:-translate-x-18:hover{
    --tw-translate-x:-72px
  }

  .lg\:hover\:-translate-x-24:hover{
    --tw-translate-x:-96px
  }

  .lg\:hover\:-translate-x-5dot5:hover{
    --tw-translate-x:-22px
  }

  .lg\:hover\:-translate-x-full:hover{
    --tw-translate-x:-100%
  }

  .lg\:hover\:translate-x-1\/2:hover{
    --tw-translate-x:50%
  }

  .lg\:hover\:translate-x-1\/3:hover{
    --tw-translate-x:33.333333%
  }

  .lg\:hover\:translate-x-2\/3:hover{
    --tw-translate-x:66.666667%
  }

  .lg\:hover\:translate-x-1\/4:hover{
    --tw-translate-x:25%
  }

  .lg\:hover\:translate-x-2\/4:hover{
    --tw-translate-x:50%
  }

  .lg\:hover\:translate-x-3\/4:hover{
    --tw-translate-x:75%
  }

  .lg\:hover\:-translate-x-1\/2:hover{
    --tw-translate-x:-50%
  }

  .lg\:hover\:-translate-x-1\/3:hover{
    --tw-translate-x:-33.333333%
  }

  .lg\:hover\:-translate-x-2\/3:hover{
    --tw-translate-x:-66.666667%
  }

  .lg\:hover\:-translate-x-1\/4:hover{
    --tw-translate-x:-25%
  }

  .lg\:hover\:-translate-x-2\/4:hover{
    --tw-translate-x:-50%
  }

  .lg\:hover\:-translate-x-3\/4:hover{
    --tw-translate-x:-75%
  }

  .lg\:hover\:translate-y-0:hover{
    --tw-translate-y:0
  }

  .lg\:hover\:translate-y-1:hover{
    --tw-translate-y:4px
  }

  .lg\:hover\:translate-y-2:hover{
    --tw-translate-y:8px
  }

  .lg\:hover\:translate-y-3:hover{
    --tw-translate-y:12px
  }

  .lg\:hover\:translate-y-4:hover{
    --tw-translate-y:16px
  }

  .lg\:hover\:translate-y-5:hover{
    --tw-translate-y:20px
  }

  .lg\:hover\:translate-y-6:hover{
    --tw-translate-y:24px
  }

  .lg\:hover\:translate-y-8:hover{
    --tw-translate-y:32px
  }

  .lg\:hover\:translate-y-10:hover{
    --tw-translate-y:40px
  }

  .lg\:hover\:translate-y-12:hover{
    --tw-translate-y:48px
  }

  .lg\:hover\:translate-y-16:hover{
    --tw-translate-y:64px
  }

  .lg\:hover\:translate-y-18:hover{
    --tw-translate-y:72px
  }

  .lg\:hover\:translate-y-24:hover{
    --tw-translate-y:96px
  }

  .lg\:hover\:translate-y-5dot5:hover{
    --tw-translate-y:22px
  }

  .lg\:hover\:translate-y-full:hover{
    --tw-translate-y:100%
  }

  .lg\:hover\:-translate-y-1:hover{
    --tw-translate-y:-4px
  }

  .lg\:hover\:-translate-y-2:hover{
    --tw-translate-y:-8px
  }

  .lg\:hover\:-translate-y-3:hover{
    --tw-translate-y:-12px
  }

  .lg\:hover\:-translate-y-4:hover{
    --tw-translate-y:-16px
  }

  .lg\:hover\:-translate-y-5:hover{
    --tw-translate-y:-20px
  }

  .lg\:hover\:-translate-y-6:hover{
    --tw-translate-y:-24px
  }

  .lg\:hover\:-translate-y-8:hover{
    --tw-translate-y:-32px
  }

  .lg\:hover\:-translate-y-10:hover{
    --tw-translate-y:-40px
  }

  .lg\:hover\:-translate-y-12:hover{
    --tw-translate-y:-48px
  }

  .lg\:hover\:-translate-y-16:hover{
    --tw-translate-y:-64px
  }

  .lg\:hover\:-translate-y-18:hover{
    --tw-translate-y:-72px
  }

  .lg\:hover\:-translate-y-24:hover{
    --tw-translate-y:-96px
  }

  .lg\:hover\:-translate-y-5dot5:hover{
    --tw-translate-y:-22px
  }

  .lg\:hover\:-translate-y-full:hover{
    --tw-translate-y:-100%
  }

  .lg\:hover\:translate-y-1\/2:hover{
    --tw-translate-y:50%
  }

  .lg\:hover\:translate-y-1\/3:hover{
    --tw-translate-y:33.333333%
  }

  .lg\:hover\:translate-y-2\/3:hover{
    --tw-translate-y:66.666667%
  }

  .lg\:hover\:translate-y-1\/4:hover{
    --tw-translate-y:25%
  }

  .lg\:hover\:translate-y-2\/4:hover{
    --tw-translate-y:50%
  }

  .lg\:hover\:translate-y-3\/4:hover{
    --tw-translate-y:75%
  }

  .lg\:hover\:-translate-y-1\/2:hover{
    --tw-translate-y:-50%
  }

  .lg\:hover\:-translate-y-1\/3:hover{
    --tw-translate-y:-33.333333%
  }

  .lg\:hover\:-translate-y-2\/3:hover{
    --tw-translate-y:-66.666667%
  }

  .lg\:hover\:-translate-y-1\/4:hover{
    --tw-translate-y:-25%
  }

  .lg\:hover\:-translate-y-2\/4:hover{
    --tw-translate-y:-50%
  }

  .lg\:hover\:-translate-y-3\/4:hover{
    --tw-translate-y:-75%
  }

  .lg\:focus\:translate-x-0:focus{
    --tw-translate-x:0
  }

  .lg\:focus\:translate-x-1:focus{
    --tw-translate-x:4px
  }

  .lg\:focus\:translate-x-2:focus{
    --tw-translate-x:8px
  }

  .lg\:focus\:translate-x-3:focus{
    --tw-translate-x:12px
  }

  .lg\:focus\:translate-x-4:focus{
    --tw-translate-x:16px
  }

  .lg\:focus\:translate-x-5:focus{
    --tw-translate-x:20px
  }

  .lg\:focus\:translate-x-6:focus{
    --tw-translate-x:24px
  }

  .lg\:focus\:translate-x-8:focus{
    --tw-translate-x:32px
  }

  .lg\:focus\:translate-x-10:focus{
    --tw-translate-x:40px
  }

  .lg\:focus\:translate-x-12:focus{
    --tw-translate-x:48px
  }

  .lg\:focus\:translate-x-16:focus{
    --tw-translate-x:64px
  }

  .lg\:focus\:translate-x-18:focus{
    --tw-translate-x:72px
  }

  .lg\:focus\:translate-x-24:focus{
    --tw-translate-x:96px
  }

  .lg\:focus\:translate-x-5dot5:focus{
    --tw-translate-x:22px
  }

  .lg\:focus\:translate-x-full:focus{
    --tw-translate-x:100%
  }

  .lg\:focus\:-translate-x-1:focus{
    --tw-translate-x:-4px
  }

  .lg\:focus\:-translate-x-2:focus{
    --tw-translate-x:-8px
  }

  .lg\:focus\:-translate-x-3:focus{
    --tw-translate-x:-12px
  }

  .lg\:focus\:-translate-x-4:focus{
    --tw-translate-x:-16px
  }

  .lg\:focus\:-translate-x-5:focus{
    --tw-translate-x:-20px
  }

  .lg\:focus\:-translate-x-6:focus{
    --tw-translate-x:-24px
  }

  .lg\:focus\:-translate-x-8:focus{
    --tw-translate-x:-32px
  }

  .lg\:focus\:-translate-x-10:focus{
    --tw-translate-x:-40px
  }

  .lg\:focus\:-translate-x-12:focus{
    --tw-translate-x:-48px
  }

  .lg\:focus\:-translate-x-16:focus{
    --tw-translate-x:-64px
  }

  .lg\:focus\:-translate-x-18:focus{
    --tw-translate-x:-72px
  }

  .lg\:focus\:-translate-x-24:focus{
    --tw-translate-x:-96px
  }

  .lg\:focus\:-translate-x-5dot5:focus{
    --tw-translate-x:-22px
  }

  .lg\:focus\:-translate-x-full:focus{
    --tw-translate-x:-100%
  }

  .lg\:focus\:translate-x-1\/2:focus{
    --tw-translate-x:50%
  }

  .lg\:focus\:translate-x-1\/3:focus{
    --tw-translate-x:33.333333%
  }

  .lg\:focus\:translate-x-2\/3:focus{
    --tw-translate-x:66.666667%
  }

  .lg\:focus\:translate-x-1\/4:focus{
    --tw-translate-x:25%
  }

  .lg\:focus\:translate-x-2\/4:focus{
    --tw-translate-x:50%
  }

  .lg\:focus\:translate-x-3\/4:focus{
    --tw-translate-x:75%
  }

  .lg\:focus\:-translate-x-1\/2:focus{
    --tw-translate-x:-50%
  }

  .lg\:focus\:-translate-x-1\/3:focus{
    --tw-translate-x:-33.333333%
  }

  .lg\:focus\:-translate-x-2\/3:focus{
    --tw-translate-x:-66.666667%
  }

  .lg\:focus\:-translate-x-1\/4:focus{
    --tw-translate-x:-25%
  }

  .lg\:focus\:-translate-x-2\/4:focus{
    --tw-translate-x:-50%
  }

  .lg\:focus\:-translate-x-3\/4:focus{
    --tw-translate-x:-75%
  }

  .lg\:focus\:translate-y-0:focus{
    --tw-translate-y:0
  }

  .lg\:focus\:translate-y-1:focus{
    --tw-translate-y:4px
  }

  .lg\:focus\:translate-y-2:focus{
    --tw-translate-y:8px
  }

  .lg\:focus\:translate-y-3:focus{
    --tw-translate-y:12px
  }

  .lg\:focus\:translate-y-4:focus{
    --tw-translate-y:16px
  }

  .lg\:focus\:translate-y-5:focus{
    --tw-translate-y:20px
  }

  .lg\:focus\:translate-y-6:focus{
    --tw-translate-y:24px
  }

  .lg\:focus\:translate-y-8:focus{
    --tw-translate-y:32px
  }

  .lg\:focus\:translate-y-10:focus{
    --tw-translate-y:40px
  }

  .lg\:focus\:translate-y-12:focus{
    --tw-translate-y:48px
  }

  .lg\:focus\:translate-y-16:focus{
    --tw-translate-y:64px
  }

  .lg\:focus\:translate-y-18:focus{
    --tw-translate-y:72px
  }

  .lg\:focus\:translate-y-24:focus{
    --tw-translate-y:96px
  }

  .lg\:focus\:translate-y-5dot5:focus{
    --tw-translate-y:22px
  }

  .lg\:focus\:translate-y-full:focus{
    --tw-translate-y:100%
  }

  .lg\:focus\:-translate-y-1:focus{
    --tw-translate-y:-4px
  }

  .lg\:focus\:-translate-y-2:focus{
    --tw-translate-y:-8px
  }

  .lg\:focus\:-translate-y-3:focus{
    --tw-translate-y:-12px
  }

  .lg\:focus\:-translate-y-4:focus{
    --tw-translate-y:-16px
  }

  .lg\:focus\:-translate-y-5:focus{
    --tw-translate-y:-20px
  }

  .lg\:focus\:-translate-y-6:focus{
    --tw-translate-y:-24px
  }

  .lg\:focus\:-translate-y-8:focus{
    --tw-translate-y:-32px
  }

  .lg\:focus\:-translate-y-10:focus{
    --tw-translate-y:-40px
  }

  .lg\:focus\:-translate-y-12:focus{
    --tw-translate-y:-48px
  }

  .lg\:focus\:-translate-y-16:focus{
    --tw-translate-y:-64px
  }

  .lg\:focus\:-translate-y-18:focus{
    --tw-translate-y:-72px
  }

  .lg\:focus\:-translate-y-24:focus{
    --tw-translate-y:-96px
  }

  .lg\:focus\:-translate-y-5dot5:focus{
    --tw-translate-y:-22px
  }

  .lg\:focus\:-translate-y-full:focus{
    --tw-translate-y:-100%
  }

  .lg\:focus\:translate-y-1\/2:focus{
    --tw-translate-y:50%
  }

  .lg\:focus\:translate-y-1\/3:focus{
    --tw-translate-y:33.333333%
  }

  .lg\:focus\:translate-y-2\/3:focus{
    --tw-translate-y:66.666667%
  }

  .lg\:focus\:translate-y-1\/4:focus{
    --tw-translate-y:25%
  }

  .lg\:focus\:translate-y-2\/4:focus{
    --tw-translate-y:50%
  }

  .lg\:focus\:translate-y-3\/4:focus{
    --tw-translate-y:75%
  }

  .lg\:focus\:-translate-y-1\/2:focus{
    --tw-translate-y:-50%
  }

  .lg\:focus\:-translate-y-1\/3:focus{
    --tw-translate-y:-33.333333%
  }

  .lg\:focus\:-translate-y-2\/3:focus{
    --tw-translate-y:-66.666667%
  }

  .lg\:focus\:-translate-y-1\/4:focus{
    --tw-translate-y:-25%
  }

  .lg\:focus\:-translate-y-2\/4:focus{
    --tw-translate-y:-50%
  }

  .lg\:focus\:-translate-y-3\/4:focus{
    --tw-translate-y:-75%
  }

  .lg\:rotate-0{
    --tw-rotate:0deg
  }

  .lg\:rotate-1{
    --tw-rotate:1deg
  }

  .lg\:rotate-2{
    --tw-rotate:2deg
  }

  .lg\:rotate-3{
    --tw-rotate:3deg
  }

  .lg\:rotate-6{
    --tw-rotate:6deg
  }

  .lg\:rotate-12{
    --tw-rotate:12deg
  }

  .lg\:rotate-45{
    --tw-rotate:45deg
  }

  .lg\:rotate-90{
    --tw-rotate:90deg
  }

  .lg\:rotate-180{
    --tw-rotate:180deg
  }

  .lg\:-rotate-180{
    --tw-rotate:-180deg
  }

  .lg\:-rotate-90{
    --tw-rotate:-90deg
  }

  .lg\:-rotate-45{
    --tw-rotate:-45deg
  }

  .lg\:-rotate-12{
    --tw-rotate:-12deg
  }

  .lg\:-rotate-6{
    --tw-rotate:-6deg
  }

  .lg\:-rotate-3{
    --tw-rotate:-3deg
  }

  .lg\:-rotate-2{
    --tw-rotate:-2deg
  }

  .lg\:-rotate-1{
    --tw-rotate:-1deg
  }

  .lg\:hover\:rotate-0:hover{
    --tw-rotate:0deg
  }

  .lg\:hover\:rotate-1:hover{
    --tw-rotate:1deg
  }

  .lg\:hover\:rotate-2:hover{
    --tw-rotate:2deg
  }

  .lg\:hover\:rotate-3:hover{
    --tw-rotate:3deg
  }

  .lg\:hover\:rotate-6:hover{
    --tw-rotate:6deg
  }

  .lg\:hover\:rotate-12:hover{
    --tw-rotate:12deg
  }

  .lg\:hover\:rotate-45:hover{
    --tw-rotate:45deg
  }

  .lg\:hover\:rotate-90:hover{
    --tw-rotate:90deg
  }

  .lg\:hover\:rotate-180:hover{
    --tw-rotate:180deg
  }

  .lg\:hover\:-rotate-180:hover{
    --tw-rotate:-180deg
  }

  .lg\:hover\:-rotate-90:hover{
    --tw-rotate:-90deg
  }

  .lg\:hover\:-rotate-45:hover{
    --tw-rotate:-45deg
  }

  .lg\:hover\:-rotate-12:hover{
    --tw-rotate:-12deg
  }

  .lg\:hover\:-rotate-6:hover{
    --tw-rotate:-6deg
  }

  .lg\:hover\:-rotate-3:hover{
    --tw-rotate:-3deg
  }

  .lg\:hover\:-rotate-2:hover{
    --tw-rotate:-2deg
  }

  .lg\:hover\:-rotate-1:hover{
    --tw-rotate:-1deg
  }

  .lg\:focus\:rotate-0:focus{
    --tw-rotate:0deg
  }

  .lg\:focus\:rotate-1:focus{
    --tw-rotate:1deg
  }

  .lg\:focus\:rotate-2:focus{
    --tw-rotate:2deg
  }

  .lg\:focus\:rotate-3:focus{
    --tw-rotate:3deg
  }

  .lg\:focus\:rotate-6:focus{
    --tw-rotate:6deg
  }

  .lg\:focus\:rotate-12:focus{
    --tw-rotate:12deg
  }

  .lg\:focus\:rotate-45:focus{
    --tw-rotate:45deg
  }

  .lg\:focus\:rotate-90:focus{
    --tw-rotate:90deg
  }

  .lg\:focus\:rotate-180:focus{
    --tw-rotate:180deg
  }

  .lg\:focus\:-rotate-180:focus{
    --tw-rotate:-180deg
  }

  .lg\:focus\:-rotate-90:focus{
    --tw-rotate:-90deg
  }

  .lg\:focus\:-rotate-45:focus{
    --tw-rotate:-45deg
  }

  .lg\:focus\:-rotate-12:focus{
    --tw-rotate:-12deg
  }

  .lg\:focus\:-rotate-6:focus{
    --tw-rotate:-6deg
  }

  .lg\:focus\:-rotate-3:focus{
    --tw-rotate:-3deg
  }

  .lg\:focus\:-rotate-2:focus{
    --tw-rotate:-2deg
  }

  .lg\:focus\:-rotate-1:focus{
    --tw-rotate:-1deg
  }

  .lg\:animate-none{
    animation:none
  }

  .lg\:animate-spin{
    animation:spin 1s linear infinite
  }

  .lg\:animate-ping{
    animation:ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .lg\:animate-pulse{
    animation:pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .lg\:animate-bounce{
    animation:bounce 1s infinite
  }

  .lg\:animate-spin-delay{
    animation:spin-delay 2.4s ease infinite
  }

  .lg\:cursor-auto{
    cursor:auto
  }

  .lg\:cursor-default{
    cursor:default
  }

  .lg\:cursor-pointer{
    cursor:pointer
  }

  .lg\:cursor-wait{
    cursor:wait
  }

  .lg\:cursor-text{
    cursor:text
  }

  .lg\:cursor-move{
    cursor:move
  }

  .lg\:cursor-help{
    cursor:help
  }

  .lg\:cursor-not-allowed{
    cursor:not-allowed
  }

  .lg\:select-none{
    -webkit-user-select:none;
            user-select:none
  }

  .lg\:select-text{
    -webkit-user-select:text;
            user-select:text
  }

  .lg\:select-all{
    -webkit-user-select:all;
            user-select:all
  }

  .lg\:select-auto{
    -webkit-user-select:auto;
            user-select:auto
  }

  .lg\:auto-cols-auto{
    grid-auto-columns:auto
  }

  .lg\:auto-cols-min{
    grid-auto-columns:min-content
  }

  .lg\:auto-cols-max{
    grid-auto-columns:max-content
  }

  .lg\:auto-cols-fr{
    grid-auto-columns:minmax(0, 1fr)
  }

  .lg\:grid-flow-row{
    grid-auto-flow:row
  }

  .lg\:grid-flow-col{
    grid-auto-flow:column
  }

  .lg\:grid-flow-row-dense{
    grid-auto-flow:row dense
  }

  .lg\:grid-flow-col-dense{
    grid-auto-flow:column dense
  }

  .lg\:auto-rows-auto{
    grid-auto-rows:auto
  }

  .lg\:auto-rows-min{
    grid-auto-rows:min-content
  }

  .lg\:auto-rows-max{
    grid-auto-rows:max-content
  }

  .lg\:auto-rows-fr{
    grid-auto-rows:minmax(0, 1fr)
  }

  .lg\:grid-cols-1{
    grid-template-columns:repeat(1, minmax(0, 1fr))
  }

  .lg\:grid-cols-2{
    grid-template-columns:repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-cols-3{
    grid-template-columns:repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-cols-4{
    grid-template-columns:repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-cols-5{
    grid-template-columns:repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-cols-6{
    grid-template-columns:repeat(6, minmax(0, 1fr))
  }

  .lg\:grid-cols-7{
    grid-template-columns:repeat(7, minmax(0, 1fr))
  }

  .lg\:grid-cols-8{
    grid-template-columns:repeat(8, minmax(0, 1fr))
  }

  .lg\:grid-cols-9{
    grid-template-columns:repeat(9, minmax(0, 1fr))
  }

  .lg\:grid-cols-10{
    grid-template-columns:repeat(10, minmax(0, 1fr))
  }

  .lg\:grid-cols-11{
    grid-template-columns:repeat(11, minmax(0, 1fr))
  }

  .lg\:grid-cols-12{
    grid-template-columns:repeat(12, minmax(0, 1fr))
  }

  .lg\:grid-cols-none{
    grid-template-columns:none
  }

  .lg\:grid-rows-1{
    grid-template-rows:repeat(1, minmax(0, 1fr))
  }

  .lg\:grid-rows-2{
    grid-template-rows:repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-rows-3{
    grid-template-rows:repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-rows-4{
    grid-template-rows:repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-rows-5{
    grid-template-rows:repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-rows-6{
    grid-template-rows:repeat(6, minmax(0, 1fr))
  }

  .lg\:grid-rows-none{
    grid-template-rows:none
  }

  .lg\:flex-row{
    flex-direction:row
  }

  .lg\:flex-row-reverse{
    flex-direction:row-reverse
  }

  .lg\:flex-col{
    flex-direction:column
  }

  .lg\:flex-col-reverse{
    flex-direction:column-reverse
  }

  .lg\:flex-wrap{
    flex-wrap:wrap
  }

  .lg\:flex-wrap-reverse{
    flex-wrap:wrap-reverse
  }

  .lg\:flex-nowrap{
    flex-wrap:nowrap
  }

  .lg\:place-content-center{
    place-content:center
  }

  .lg\:place-content-start{
    place-content:start
  }

  .lg\:place-content-end{
    place-content:end
  }

  .lg\:place-content-between{
    place-content:space-between
  }

  .lg\:place-content-around{
    place-content:space-around
  }

  .lg\:place-content-evenly{
    place-content:space-evenly
  }

  .lg\:place-content-stretch{
    place-content:stretch
  }

  .lg\:place-items-start{
    place-items:start
  }

  .lg\:place-items-end{
    place-items:end
  }

  .lg\:place-items-center{
    place-items:center
  }

  .lg\:place-items-stretch{
    place-items:stretch
  }

  .lg\:content-center{
    align-content:center
  }

  .lg\:content-start{
    align-content:flex-start
  }

  .lg\:content-end{
    align-content:flex-end
  }

  .lg\:content-between{
    align-content:space-between
  }

  .lg\:content-around{
    align-content:space-around
  }

  .lg\:content-evenly{
    align-content:space-evenly
  }

  .lg\:items-start{
    align-items:flex-start
  }

  .lg\:items-end{
    align-items:flex-end
  }

  .lg\:items-center{
    align-items:center
  }

  .lg\:items-baseline{
    align-items:baseline
  }

  .lg\:items-stretch{
    align-items:stretch
  }

  .lg\:justify-start{
    justify-content:flex-start
  }

  .lg\:justify-end{
    justify-content:flex-end
  }

  .lg\:justify-center{
    justify-content:center
  }

  .lg\:justify-between{
    justify-content:space-between
  }

  .lg\:justify-around{
    justify-content:space-around
  }

  .lg\:justify-evenly{
    justify-content:space-evenly
  }

  .lg\:justify-items-start{
    justify-items:start
  }

  .lg\:justify-items-end{
    justify-items:end
  }

  .lg\:justify-items-center{
    justify-items:center
  }

  .lg\:justify-items-stretch{
    justify-items:stretch
  }

  .lg\:space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(0px * var(--tw-space-x-reverse));
    margin-left:calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(4px * var(--tw-space-x-reverse));
    margin-left:calc(4px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(8px * var(--tw-space-x-reverse));
    margin-left:calc(8px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(12px * var(--tw-space-x-reverse));
    margin-left:calc(12px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(16px * var(--tw-space-x-reverse));
    margin-left:calc(16px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(20px * var(--tw-space-x-reverse));
    margin-left:calc(20px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(24px * var(--tw-space-x-reverse));
    margin-left:calc(24px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(32px * var(--tw-space-x-reverse));
    margin-left:calc(32px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(40px * var(--tw-space-x-reverse));
    margin-left:calc(40px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(48px * var(--tw-space-x-reverse));
    margin-left:calc(48px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(64px * var(--tw-space-x-reverse));
    margin-left:calc(64px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(72px * var(--tw-space-x-reverse));
    margin-left:calc(72px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(96px * var(--tw-space-x-reverse));
    margin-left:calc(96px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(22px * var(--tw-space-x-reverse));
    margin-left:calc(22px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-x-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(100% * var(--tw-space-x-reverse));
    margin-left:calc(100% * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-4px * var(--tw-space-x-reverse));
    margin-left:calc(-4px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-8px * var(--tw-space-x-reverse));
    margin-left:calc(-8px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-12px * var(--tw-space-x-reverse));
    margin-left:calc(-12px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-16px * var(--tw-space-x-reverse));
    margin-left:calc(-16px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-20px * var(--tw-space-x-reverse));
    margin-left:calc(-20px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-24px * var(--tw-space-x-reverse));
    margin-left:calc(-24px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-32px * var(--tw-space-x-reverse));
    margin-left:calc(-32px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-40px * var(--tw-space-x-reverse));
    margin-left:calc(-40px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-48px * var(--tw-space-x-reverse));
    margin-left:calc(-48px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-64px * var(--tw-space-x-reverse));
    margin-left:calc(-64px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-72px * var(--tw-space-x-reverse));
    margin-left:calc(-72px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-96px * var(--tw-space-x-reverse));
    margin-left:calc(-96px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-22px * var(--tw-space-x-reverse));
    margin-left:calc(-22px * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:-space-x-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-100% * var(--tw-space-x-reverse));
    margin-left:calc(-100% * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(4px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(4px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(8px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(12px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(16px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(20px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(24px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(32px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(40px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(48px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(48px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(64px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(64px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(72px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(72px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(96px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(96px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(22px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(22px * var(--tw-space-y-reverse))
  }

  .lg\:space-y-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(100% * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(100% * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-4px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-4px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-8px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-12px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-16px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-20px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-24px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-32px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-40px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-48px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-48px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-64px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-64px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-72px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-72px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-96px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-96px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-22px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-22px * var(--tw-space-y-reverse))
  }

  .lg\:-space-y-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-100% * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-100% * var(--tw-space-y-reverse))
  }

  .lg\:space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:1
  }

  .lg\:space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:1
  }

  .lg\:divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style:solid
  }

  .lg\:divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style:dashed
  }

  .lg\:divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style:dotted
  }

  .lg\:divide-double > :not([hidden]) ~ :not([hidden]){
    border-style:double
  }

  .lg\:divide-none > :not([hidden]) ~ :not([hidden]){
    border-style:none
  }

  .lg\:place-self-auto{
    place-self:auto
  }

  .lg\:place-self-start{
    place-self:start
  }

  .lg\:place-self-end{
    place-self:end
  }

  .lg\:place-self-center{
    place-self:center
  }

  .lg\:place-self-stretch{
    place-self:stretch
  }

  .lg\:self-auto{
    align-self:auto
  }

  .lg\:self-start{
    align-self:flex-start
  }

  .lg\:self-end{
    align-self:flex-end
  }

  .lg\:self-center{
    align-self:center
  }

  .lg\:self-stretch{
    align-self:stretch
  }

  .lg\:self-baseline{
    align-self:baseline
  }

  .lg\:justify-self-auto{
    justify-self:auto
  }

  .lg\:justify-self-start{
    justify-self:start
  }

  .lg\:justify-self-end{
    justify-self:end
  }

  .lg\:justify-self-center{
    justify-self:center
  }

  .lg\:justify-self-stretch{
    justify-self:stretch
  }

  .lg\:overflow-auto{
    overflow:auto
  }

  .lg\:overflow-hidden{
    overflow:hidden
  }

  .lg\:overflow-visible{
    overflow:visible
  }

  .lg\:overflow-scroll{
    overflow:scroll
  }

  .lg\:overflow-x-auto{
    overflow-x:auto
  }

  .lg\:overflow-y-auto{
    overflow-y:auto
  }

  .lg\:overflow-x-hidden{
    overflow-x:hidden
  }

  .lg\:overflow-y-hidden{
    overflow-y:hidden
  }

  .lg\:overflow-x-visible{
    overflow-x:visible
  }

  .lg\:overflow-y-visible{
    overflow-y:visible
  }

  .lg\:overflow-x-scroll{
    overflow-x:scroll
  }

  .lg\:overflow-y-scroll{
    overflow-y:scroll
  }

  .lg\:overscroll-auto{
    overscroll-behavior:auto
  }

  .lg\:overscroll-contain{
    overscroll-behavior:contain
  }

  .lg\:overscroll-none{
    overscroll-behavior:none
  }

  .lg\:overscroll-y-auto{
    overscroll-behavior-y:auto
  }

  .lg\:overscroll-y-contain{
    overscroll-behavior-y:contain
  }

  .lg\:overscroll-y-none{
    overscroll-behavior-y:none
  }

  .lg\:overscroll-x-auto{
    overscroll-behavior-x:auto
  }

  .lg\:overscroll-x-contain{
    overscroll-behavior-x:contain
  }

  .lg\:overscroll-x-none{
    overscroll-behavior-x:none
  }

  .lg\:truncate{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
  }

  .lg\:overflow-ellipsis{
    text-overflow:ellipsis
  }

  .lg\:overflow-clip{
    text-overflow:clip
  }

  .lg\:whitespace-normal{
    white-space:normal
  }

  .lg\:whitespace-nowrap{
    white-space:nowrap
  }

  .lg\:whitespace-pre{
    white-space:pre
  }

  .lg\:whitespace-pre-line{
    white-space:pre-line
  }

  .lg\:whitespace-pre-wrap{
    white-space:pre-wrap
  }

  .lg\:break-normal{
    overflow-wrap:normal;
    word-break:normal
  }

  .lg\:break-words{
    overflow-wrap:break-word
  }

  .lg\:break-all{
    word-break:break-all
  }

  .lg\:rounded-1{
    border-radius:4px
  }

  .lg\:rounded-2{
    border-radius:8px
  }

  .lg\:rounded-3{
    border-radius:12px
  }

  .lg\:rounded-4{
    border-radius:16px
  }

  .lg\:rounded-none{
    border-radius:0px
  }

  .lg\:rounded-sm{
    border-radius:0.125rem
  }

  .lg\:rounded{
    border-radius:0.25rem
  }

  .lg\:rounded-md{
    border-radius:0.375rem
  }

  .lg\:rounded-lg{
    border-radius:0.5rem
  }

  .lg\:rounded-xl{
    border-radius:0.75rem
  }

  .lg\:rounded-2xl{
    border-radius:1rem
  }

  .lg\:rounded-3xl{
    border-radius:1.5rem
  }

  .lg\:rounded-full{
    border-radius:9999px
  }

  .lg\:rounded-default{
    border-radius:4px
  }

  .lg\:rounded-t-1{
    border-top-left-radius:4px;
    border-top-right-radius:4px
  }

  .lg\:rounded-t-2{
    border-top-left-radius:8px;
    border-top-right-radius:8px
  }

  .lg\:rounded-t-3{
    border-top-left-radius:12px;
    border-top-right-radius:12px
  }

  .lg\:rounded-t-4{
    border-top-left-radius:16px;
    border-top-right-radius:16px
  }

  .lg\:rounded-t-none{
    border-top-left-radius:0px;
    border-top-right-radius:0px
  }

  .lg\:rounded-t-sm{
    border-top-left-radius:0.125rem;
    border-top-right-radius:0.125rem
  }

  .lg\:rounded-t{
    border-top-left-radius:0.25rem;
    border-top-right-radius:0.25rem
  }

  .lg\:rounded-t-md{
    border-top-left-radius:0.375rem;
    border-top-right-radius:0.375rem
  }

  .lg\:rounded-t-lg{
    border-top-left-radius:0.5rem;
    border-top-right-radius:0.5rem
  }

  .lg\:rounded-t-xl{
    border-top-left-radius:0.75rem;
    border-top-right-radius:0.75rem
  }

  .lg\:rounded-t-2xl{
    border-top-left-radius:1rem;
    border-top-right-radius:1rem
  }

  .lg\:rounded-t-3xl{
    border-top-left-radius:1.5rem;
    border-top-right-radius:1.5rem
  }

  .lg\:rounded-t-full{
    border-top-left-radius:9999px;
    border-top-right-radius:9999px
  }

  .lg\:rounded-t-default{
    border-top-left-radius:4px;
    border-top-right-radius:4px
  }

  .lg\:rounded-r-1{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
  }

  .lg\:rounded-r-2{
    border-top-right-radius:8px;
    border-bottom-right-radius:8px
  }

  .lg\:rounded-r-3{
    border-top-right-radius:12px;
    border-bottom-right-radius:12px
  }

  .lg\:rounded-r-4{
    border-top-right-radius:16px;
    border-bottom-right-radius:16px
  }

  .lg\:rounded-r-none{
    border-top-right-radius:0px;
    border-bottom-right-radius:0px
  }

  .lg\:rounded-r-sm{
    border-top-right-radius:0.125rem;
    border-bottom-right-radius:0.125rem
  }

  .lg\:rounded-r{
    border-top-right-radius:0.25rem;
    border-bottom-right-radius:0.25rem
  }

  .lg\:rounded-r-md{
    border-top-right-radius:0.375rem;
    border-bottom-right-radius:0.375rem
  }

  .lg\:rounded-r-lg{
    border-top-right-radius:0.5rem;
    border-bottom-right-radius:0.5rem
  }

  .lg\:rounded-r-xl{
    border-top-right-radius:0.75rem;
    border-bottom-right-radius:0.75rem
  }

  .lg\:rounded-r-2xl{
    border-top-right-radius:1rem;
    border-bottom-right-radius:1rem
  }

  .lg\:rounded-r-3xl{
    border-top-right-radius:1.5rem;
    border-bottom-right-radius:1.5rem
  }

  .lg\:rounded-r-full{
    border-top-right-radius:9999px;
    border-bottom-right-radius:9999px
  }

  .lg\:rounded-r-default{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
  }

  .lg\:rounded-b-1{
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px
  }

  .lg\:rounded-b-2{
    border-bottom-right-radius:8px;
    border-bottom-left-radius:8px
  }

  .lg\:rounded-b-3{
    border-bottom-right-radius:12px;
    border-bottom-left-radius:12px
  }

  .lg\:rounded-b-4{
    border-bottom-right-radius:16px;
    border-bottom-left-radius:16px
  }

  .lg\:rounded-b-none{
    border-bottom-right-radius:0px;
    border-bottom-left-radius:0px
  }

  .lg\:rounded-b-sm{
    border-bottom-right-radius:0.125rem;
    border-bottom-left-radius:0.125rem
  }

  .lg\:rounded-b{
    border-bottom-right-radius:0.25rem;
    border-bottom-left-radius:0.25rem
  }

  .lg\:rounded-b-md{
    border-bottom-right-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }

  .lg\:rounded-b-lg{
    border-bottom-right-radius:0.5rem;
    border-bottom-left-radius:0.5rem
  }

  .lg\:rounded-b-xl{
    border-bottom-right-radius:0.75rem;
    border-bottom-left-radius:0.75rem
  }

  .lg\:rounded-b-2xl{
    border-bottom-right-radius:1rem;
    border-bottom-left-radius:1rem
  }

  .lg\:rounded-b-3xl{
    border-bottom-right-radius:1.5rem;
    border-bottom-left-radius:1.5rem
  }

  .lg\:rounded-b-full{
    border-bottom-right-radius:9999px;
    border-bottom-left-radius:9999px
  }

  .lg\:rounded-b-default{
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px
  }

  .lg\:rounded-l-1{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
  }

  .lg\:rounded-l-2{
    border-top-left-radius:8px;
    border-bottom-left-radius:8px
  }

  .lg\:rounded-l-3{
    border-top-left-radius:12px;
    border-bottom-left-radius:12px
  }

  .lg\:rounded-l-4{
    border-top-left-radius:16px;
    border-bottom-left-radius:16px
  }

  .lg\:rounded-l-none{
    border-top-left-radius:0px;
    border-bottom-left-radius:0px
  }

  .lg\:rounded-l-sm{
    border-top-left-radius:0.125rem;
    border-bottom-left-radius:0.125rem
  }

  .lg\:rounded-l{
    border-top-left-radius:0.25rem;
    border-bottom-left-radius:0.25rem
  }

  .lg\:rounded-l-md{
    border-top-left-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }

  .lg\:rounded-l-lg{
    border-top-left-radius:0.5rem;
    border-bottom-left-radius:0.5rem
  }

  .lg\:rounded-l-xl{
    border-top-left-radius:0.75rem;
    border-bottom-left-radius:0.75rem
  }

  .lg\:rounded-l-2xl{
    border-top-left-radius:1rem;
    border-bottom-left-radius:1rem
  }

  .lg\:rounded-l-3xl{
    border-top-left-radius:1.5rem;
    border-bottom-left-radius:1.5rem
  }

  .lg\:rounded-l-full{
    border-top-left-radius:9999px;
    border-bottom-left-radius:9999px
  }

  .lg\:rounded-l-default{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
  }

  .lg\:rounded-tl-1{
    border-top-left-radius:4px
  }

  .lg\:rounded-tl-2{
    border-top-left-radius:8px
  }

  .lg\:rounded-tl-3{
    border-top-left-radius:12px
  }

  .lg\:rounded-tl-4{
    border-top-left-radius:16px
  }

  .lg\:rounded-tl-none{
    border-top-left-radius:0px
  }

  .lg\:rounded-tl-sm{
    border-top-left-radius:0.125rem
  }

  .lg\:rounded-tl{
    border-top-left-radius:0.25rem
  }

  .lg\:rounded-tl-md{
    border-top-left-radius:0.375rem
  }

  .lg\:rounded-tl-lg{
    border-top-left-radius:0.5rem
  }

  .lg\:rounded-tl-xl{
    border-top-left-radius:0.75rem
  }

  .lg\:rounded-tl-2xl{
    border-top-left-radius:1rem
  }

  .lg\:rounded-tl-3xl{
    border-top-left-radius:1.5rem
  }

  .lg\:rounded-tl-full{
    border-top-left-radius:9999px
  }

  .lg\:rounded-tl-default{
    border-top-left-radius:4px
  }

  .lg\:rounded-tr-1{
    border-top-right-radius:4px
  }

  .lg\:rounded-tr-2{
    border-top-right-radius:8px
  }

  .lg\:rounded-tr-3{
    border-top-right-radius:12px
  }

  .lg\:rounded-tr-4{
    border-top-right-radius:16px
  }

  .lg\:rounded-tr-none{
    border-top-right-radius:0px
  }

  .lg\:rounded-tr-sm{
    border-top-right-radius:0.125rem
  }

  .lg\:rounded-tr{
    border-top-right-radius:0.25rem
  }

  .lg\:rounded-tr-md{
    border-top-right-radius:0.375rem
  }

  .lg\:rounded-tr-lg{
    border-top-right-radius:0.5rem
  }

  .lg\:rounded-tr-xl{
    border-top-right-radius:0.75rem
  }

  .lg\:rounded-tr-2xl{
    border-top-right-radius:1rem
  }

  .lg\:rounded-tr-3xl{
    border-top-right-radius:1.5rem
  }

  .lg\:rounded-tr-full{
    border-top-right-radius:9999px
  }

  .lg\:rounded-tr-default{
    border-top-right-radius:4px
  }

  .lg\:rounded-br-1{
    border-bottom-right-radius:4px
  }

  .lg\:rounded-br-2{
    border-bottom-right-radius:8px
  }

  .lg\:rounded-br-3{
    border-bottom-right-radius:12px
  }

  .lg\:rounded-br-4{
    border-bottom-right-radius:16px
  }

  .lg\:rounded-br-none{
    border-bottom-right-radius:0px
  }

  .lg\:rounded-br-sm{
    border-bottom-right-radius:0.125rem
  }

  .lg\:rounded-br{
    border-bottom-right-radius:0.25rem
  }

  .lg\:rounded-br-md{
    border-bottom-right-radius:0.375rem
  }

  .lg\:rounded-br-lg{
    border-bottom-right-radius:0.5rem
  }

  .lg\:rounded-br-xl{
    border-bottom-right-radius:0.75rem
  }

  .lg\:rounded-br-2xl{
    border-bottom-right-radius:1rem
  }

  .lg\:rounded-br-3xl{
    border-bottom-right-radius:1.5rem
  }

  .lg\:rounded-br-full{
    border-bottom-right-radius:9999px
  }

  .lg\:rounded-br-default{
    border-bottom-right-radius:4px
  }

  .lg\:rounded-bl-1{
    border-bottom-left-radius:4px
  }

  .lg\:rounded-bl-2{
    border-bottom-left-radius:8px
  }

  .lg\:rounded-bl-3{
    border-bottom-left-radius:12px
  }

  .lg\:rounded-bl-4{
    border-bottom-left-radius:16px
  }

  .lg\:rounded-bl-none{
    border-bottom-left-radius:0px
  }

  .lg\:rounded-bl-sm{
    border-bottom-left-radius:0.125rem
  }

  .lg\:rounded-bl{
    border-bottom-left-radius:0.25rem
  }

  .lg\:rounded-bl-md{
    border-bottom-left-radius:0.375rem
  }

  .lg\:rounded-bl-lg{
    border-bottom-left-radius:0.5rem
  }

  .lg\:rounded-bl-xl{
    border-bottom-left-radius:0.75rem
  }

  .lg\:rounded-bl-2xl{
    border-bottom-left-radius:1rem
  }

  .lg\:rounded-bl-3xl{
    border-bottom-left-radius:1.5rem
  }

  .lg\:rounded-bl-full{
    border-bottom-left-radius:9999px
  }

  .lg\:rounded-bl-default{
    border-bottom-left-radius:4px
  }

  .lg\:border-0{
    border-width:0px
  }

  .lg\:border-2{
    border-width:2px
  }

  .lg\:border-4{
    border-width:4px
  }

  .lg\:border-8{
    border-width:8px
  }

  .lg\:border{
    border-width:1px
  }

  .lg\:border-t-0{
    border-top-width:0px
  }

  .lg\:border-t-2{
    border-top-width:2px
  }

  .lg\:border-t-4{
    border-top-width:4px
  }

  .lg\:border-t-8{
    border-top-width:8px
  }

  .lg\:border-t{
    border-top-width:1px
  }

  .lg\:border-r-0{
    border-right-width:0px
  }

  .lg\:border-r-2{
    border-right-width:2px
  }

  .lg\:border-r-4{
    border-right-width:4px
  }

  .lg\:border-r-8{
    border-right-width:8px
  }

  .lg\:border-r{
    border-right-width:1px
  }

  .lg\:border-b-0{
    border-bottom-width:0px
  }

  .lg\:border-b-2{
    border-bottom-width:2px
  }

  .lg\:border-b-4{
    border-bottom-width:4px
  }

  .lg\:border-b-8{
    border-bottom-width:8px
  }

  .lg\:border-b{
    border-bottom-width:1px
  }

  .lg\:border-l-0{
    border-left-width:0px
  }

  .lg\:border-l-2{
    border-left-width:2px
  }

  .lg\:border-l-4{
    border-left-width:4px
  }

  .lg\:border-l-8{
    border-left-width:8px
  }

  .lg\:border-l{
    border-left-width:1px
  }

  .lg\:border-solid{
    border-style:solid
  }

  .lg\:border-dashed{
    border-style:dashed
  }

  .lg\:border-dotted{
    border-style:dotted
  }

  .lg\:border-double{
    border-style:double
  }

  .lg\:border-none{
    border-style:none
  }

  .lg\:border-red1{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .lg\:border-red2{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .lg\:border-red3{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .lg\:border-red4{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .lg\:border-red5{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .lg\:border-cyan1{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .lg\:border-cyan2{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .lg\:border-cyan3{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .lg\:border-cyan4{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .lg\:border-cyan5{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .lg\:border-bg1{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .lg\:border-bg2{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:border-bg3{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .lg\:border-gray1{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .lg\:border-gray2{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .lg\:border-gray3{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .lg\:border-gray4{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .lg\:border-gray5{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .lg\:border-gray6{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .lg\:border-gray7{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .lg\:border-white{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:border-warning{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .lg\:border-error{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .lg\:border-info{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .lg\:border-success{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .lg\:border-special4{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .lg\:border-dark{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .lg\:border-transparent{
    border-color:transparent
  }

  .lg\:border-secondary1{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .lg\:border-secondary2{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .lg\:border-secondary3{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .lg\:border-secondary4{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .lg\:border-secondary5{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .lg\:border-green1{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red1:hover{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red2:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red3:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red4:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .lg\:hover\:border-red5:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan1:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan2:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan3:hover{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan4:hover{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .lg\:hover\:border-cyan5:hover{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .lg\:hover\:border-bg1:hover{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .lg\:hover\:border-bg2:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-bg3:hover{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray1:hover{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray2:hover{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray3:hover{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray4:hover{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray5:hover{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray6:hover{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .lg\:hover\:border-gray7:hover{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .lg\:hover\:border-white:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:hover\:border-warning:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .lg\:hover\:border-error:hover{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .lg\:hover\:border-info:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .lg\:hover\:border-success:hover{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .lg\:hover\:border-special4:hover{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .lg\:hover\:border-dark:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .lg\:hover\:border-transparent:hover{
    border-color:transparent
  }

  .lg\:hover\:border-secondary1:hover{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .lg\:hover\:border-secondary2:hover{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .lg\:hover\:border-secondary3:hover{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .lg\:hover\:border-secondary4:hover{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .lg\:hover\:border-secondary5:hover{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .lg\:hover\:border-green1:hover{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red1:focus{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red2:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red3:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red4:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .lg\:focus\:border-red5:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan1:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan2:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan3:focus{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan4:focus{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .lg\:focus\:border-cyan5:focus{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .lg\:focus\:border-bg1:focus{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .lg\:focus\:border-bg2:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-bg3:focus{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray1:focus{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray2:focus{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray3:focus{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray4:focus{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray5:focus{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray6:focus{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .lg\:focus\:border-gray7:focus{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .lg\:focus\:border-white:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:focus\:border-warning:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .lg\:focus\:border-error:focus{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .lg\:focus\:border-info:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .lg\:focus\:border-success:focus{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .lg\:focus\:border-special4:focus{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .lg\:focus\:border-dark:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .lg\:focus\:border-transparent:focus{
    border-color:transparent
  }

  .lg\:focus\:border-secondary1:focus{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .lg\:focus\:border-secondary2:focus{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .lg\:focus\:border-secondary3:focus{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .lg\:focus\:border-secondary4:focus{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .lg\:focus\:border-secondary5:focus{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .lg\:focus\:border-green1:focus{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .lg\:active\:border-red1:active{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .lg\:active\:border-red2:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .lg\:active\:border-red3:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .lg\:active\:border-red4:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .lg\:active\:border-red5:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .lg\:active\:border-cyan1:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .lg\:active\:border-cyan2:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .lg\:active\:border-cyan3:active{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .lg\:active\:border-cyan4:active{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .lg\:active\:border-cyan5:active{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .lg\:active\:border-bg1:active{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .lg\:active\:border-bg2:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:active\:border-bg3:active{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .lg\:active\:border-gray1:active{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .lg\:active\:border-gray2:active{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .lg\:active\:border-gray3:active{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .lg\:active\:border-gray4:active{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .lg\:active\:border-gray5:active{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .lg\:active\:border-gray6:active{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .lg\:active\:border-gray7:active{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .lg\:active\:border-white:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .lg\:active\:border-warning:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .lg\:active\:border-error:active{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .lg\:active\:border-info:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .lg\:active\:border-success:active{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .lg\:active\:border-special4:active{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .lg\:active\:border-dark:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .lg\:active\:border-transparent:active{
    border-color:transparent
  }

  .lg\:active\:border-secondary1:active{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .lg\:active\:border-secondary2:active{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .lg\:active\:border-secondary3:active{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .lg\:active\:border-secondary4:active{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .lg\:active\:border-secondary5:active{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .lg\:active\:border-green1:active{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .lg\:border-opacity-0{
    --tw-border-opacity:0
  }

  .lg\:border-opacity-5{
    --tw-border-opacity:0.05
  }

  .lg\:border-opacity-10{
    --tw-border-opacity:0.1
  }

  .lg\:border-opacity-20{
    --tw-border-opacity:0.2
  }

  .lg\:border-opacity-25{
    --tw-border-opacity:0.25
  }

  .lg\:border-opacity-30{
    --tw-border-opacity:0.3
  }

  .lg\:border-opacity-40{
    --tw-border-opacity:0.4
  }

  .lg\:border-opacity-50{
    --tw-border-opacity:0.5
  }

  .lg\:border-opacity-60{
    --tw-border-opacity:0.6
  }

  .lg\:border-opacity-70{
    --tw-border-opacity:0.7
  }

  .lg\:border-opacity-75{
    --tw-border-opacity:0.75
  }

  .lg\:border-opacity-80{
    --tw-border-opacity:0.8
  }

  .lg\:border-opacity-90{
    --tw-border-opacity:0.9
  }

  .lg\:border-opacity-95{
    --tw-border-opacity:0.95
  }

  .lg\:border-opacity-100{
    --tw-border-opacity:1
  }

  .group:hover .lg\:group-hover\:border-opacity-0{
    --tw-border-opacity:0
  }

  .group:hover .lg\:group-hover\:border-opacity-5{
    --tw-border-opacity:0.05
  }

  .group:hover .lg\:group-hover\:border-opacity-10{
    --tw-border-opacity:0.1
  }

  .group:hover .lg\:group-hover\:border-opacity-20{
    --tw-border-opacity:0.2
  }

  .group:hover .lg\:group-hover\:border-opacity-25{
    --tw-border-opacity:0.25
  }

  .group:hover .lg\:group-hover\:border-opacity-30{
    --tw-border-opacity:0.3
  }

  .group:hover .lg\:group-hover\:border-opacity-40{
    --tw-border-opacity:0.4
  }

  .group:hover .lg\:group-hover\:border-opacity-50{
    --tw-border-opacity:0.5
  }

  .group:hover .lg\:group-hover\:border-opacity-60{
    --tw-border-opacity:0.6
  }

  .group:hover .lg\:group-hover\:border-opacity-70{
    --tw-border-opacity:0.7
  }

  .group:hover .lg\:group-hover\:border-opacity-75{
    --tw-border-opacity:0.75
  }

  .group:hover .lg\:group-hover\:border-opacity-80{
    --tw-border-opacity:0.8
  }

  .group:hover .lg\:group-hover\:border-opacity-90{
    --tw-border-opacity:0.9
  }

  .group:hover .lg\:group-hover\:border-opacity-95{
    --tw-border-opacity:0.95
  }

  .group:hover .lg\:group-hover\:border-opacity-100{
    --tw-border-opacity:1
  }

  .lg\:focus-within\:border-opacity-0:focus-within{
    --tw-border-opacity:0
  }

  .lg\:focus-within\:border-opacity-5:focus-within{
    --tw-border-opacity:0.05
  }

  .lg\:focus-within\:border-opacity-10:focus-within{
    --tw-border-opacity:0.1
  }

  .lg\:focus-within\:border-opacity-20:focus-within{
    --tw-border-opacity:0.2
  }

  .lg\:focus-within\:border-opacity-25:focus-within{
    --tw-border-opacity:0.25
  }

  .lg\:focus-within\:border-opacity-30:focus-within{
    --tw-border-opacity:0.3
  }

  .lg\:focus-within\:border-opacity-40:focus-within{
    --tw-border-opacity:0.4
  }

  .lg\:focus-within\:border-opacity-50:focus-within{
    --tw-border-opacity:0.5
  }

  .lg\:focus-within\:border-opacity-60:focus-within{
    --tw-border-opacity:0.6
  }

  .lg\:focus-within\:border-opacity-70:focus-within{
    --tw-border-opacity:0.7
  }

  .lg\:focus-within\:border-opacity-75:focus-within{
    --tw-border-opacity:0.75
  }

  .lg\:focus-within\:border-opacity-80:focus-within{
    --tw-border-opacity:0.8
  }

  .lg\:focus-within\:border-opacity-90:focus-within{
    --tw-border-opacity:0.9
  }

  .lg\:focus-within\:border-opacity-95:focus-within{
    --tw-border-opacity:0.95
  }

  .lg\:focus-within\:border-opacity-100:focus-within{
    --tw-border-opacity:1
  }

  .lg\:hover\:border-opacity-0:hover{
    --tw-border-opacity:0
  }

  .lg\:hover\:border-opacity-5:hover{
    --tw-border-opacity:0.05
  }

  .lg\:hover\:border-opacity-10:hover{
    --tw-border-opacity:0.1
  }

  .lg\:hover\:border-opacity-20:hover{
    --tw-border-opacity:0.2
  }

  .lg\:hover\:border-opacity-25:hover{
    --tw-border-opacity:0.25
  }

  .lg\:hover\:border-opacity-30:hover{
    --tw-border-opacity:0.3
  }

  .lg\:hover\:border-opacity-40:hover{
    --tw-border-opacity:0.4
  }

  .lg\:hover\:border-opacity-50:hover{
    --tw-border-opacity:0.5
  }

  .lg\:hover\:border-opacity-60:hover{
    --tw-border-opacity:0.6
  }

  .lg\:hover\:border-opacity-70:hover{
    --tw-border-opacity:0.7
  }

  .lg\:hover\:border-opacity-75:hover{
    --tw-border-opacity:0.75
  }

  .lg\:hover\:border-opacity-80:hover{
    --tw-border-opacity:0.8
  }

  .lg\:hover\:border-opacity-90:hover{
    --tw-border-opacity:0.9
  }

  .lg\:hover\:border-opacity-95:hover{
    --tw-border-opacity:0.95
  }

  .lg\:hover\:border-opacity-100:hover{
    --tw-border-opacity:1
  }

  .lg\:focus\:border-opacity-0:focus{
    --tw-border-opacity:0
  }

  .lg\:focus\:border-opacity-5:focus{
    --tw-border-opacity:0.05
  }

  .lg\:focus\:border-opacity-10:focus{
    --tw-border-opacity:0.1
  }

  .lg\:focus\:border-opacity-20:focus{
    --tw-border-opacity:0.2
  }

  .lg\:focus\:border-opacity-25:focus{
    --tw-border-opacity:0.25
  }

  .lg\:focus\:border-opacity-30:focus{
    --tw-border-opacity:0.3
  }

  .lg\:focus\:border-opacity-40:focus{
    --tw-border-opacity:0.4
  }

  .lg\:focus\:border-opacity-50:focus{
    --tw-border-opacity:0.5
  }

  .lg\:focus\:border-opacity-60:focus{
    --tw-border-opacity:0.6
  }

  .lg\:focus\:border-opacity-70:focus{
    --tw-border-opacity:0.7
  }

  .lg\:focus\:border-opacity-75:focus{
    --tw-border-opacity:0.75
  }

  .lg\:focus\:border-opacity-80:focus{
    --tw-border-opacity:0.8
  }

  .lg\:focus\:border-opacity-90:focus{
    --tw-border-opacity:0.9
  }

  .lg\:focus\:border-opacity-95:focus{
    --tw-border-opacity:0.95
  }

  .lg\:focus\:border-opacity-100:focus{
    --tw-border-opacity:1
  }

  .lg\:bg-red1{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .lg\:bg-red2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .lg\:bg-red3{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .lg\:bg-red4{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .lg\:bg-red5{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan1{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan2{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan3{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan4{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .lg\:bg-cyan5{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:bg-bg1{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .lg\:bg-bg2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-bg3{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .lg\:bg-gray1{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .lg\:bg-gray2{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .lg\:bg-gray3{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .lg\:bg-gray4{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .lg\:bg-gray5{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .lg\:bg-gray6{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .lg\:bg-gray7{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .lg\:bg-white{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:bg-warning{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .lg\:bg-error{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .lg\:bg-info{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .lg\:bg-success{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .lg\:bg-special4{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .lg\:bg-dark{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .lg\:bg-transparent{
    background-color:transparent
  }

  .lg\:bg-secondary1{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .lg\:bg-secondary2{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .lg\:bg-secondary3{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .lg\:bg-secondary4{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .lg\:bg-secondary5{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .lg\:bg-green1{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-red5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-cyan5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-bg1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-bg2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-bg3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray6:hover{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-gray7:hover{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-white:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-warning:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-error:hover{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-info:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-success:hover{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-special4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-dark:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-transparent:hover{
    background-color:transparent
  }

  .lg\:hover\:bg-secondary1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-secondary2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-secondary3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-secondary4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-secondary5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .lg\:hover\:bg-green1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-red5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-cyan5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-bg1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-bg2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-bg3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray6:focus{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-gray7:focus{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-white:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-warning:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-error:focus{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-info:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-success:focus{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-special4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-dark:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-transparent:focus{
    background-color:transparent
  }

  .lg\:focus\:bg-secondary1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-secondary2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-secondary3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-secondary4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-secondary5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .lg\:focus\:bg-green1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-red1:active{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-red2:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-red3:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-red4:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-red5:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-cyan1:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-cyan2:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-cyan3:active{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-cyan4:active{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-cyan5:active{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-bg1:active{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-bg2:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-bg3:active{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-gray1:active{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-gray2:active{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-gray3:active{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-gray4:active{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-gray5:active{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-gray6:active{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-gray7:active{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-white:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-warning:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-error:active{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-info:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-success:active{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-special4:active{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-dark:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-transparent:active{
    background-color:transparent
  }

  .lg\:active\:bg-secondary1:active{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-secondary2:active{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-secondary3:active{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-secondary4:active{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-secondary5:active{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .lg\:active\:bg-green1:active{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red1{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red3{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red4{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-red5{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan1{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan2{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan3{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan4{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-cyan5{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-bg1{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-bg2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-bg3{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray1{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray2{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray3{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray4{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray5{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray6{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-gray7{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-white{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-warning{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-error{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-info{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-success{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-special4{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-dark{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-transparent{
    background-color:transparent
  }

  .group:hover .lg\:group-hover\:bg-secondary1{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-secondary2{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-secondary3{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-secondary4{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-secondary5{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .group:hover .lg\:group-hover\:bg-green1{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .lg\:bg-opacity-0{
    --tw-bg-opacity:0
  }

  .lg\:bg-opacity-5{
    --tw-bg-opacity:0.05
  }

  .lg\:bg-opacity-10{
    --tw-bg-opacity:0.1
  }

  .lg\:bg-opacity-20{
    --tw-bg-opacity:0.2
  }

  .lg\:bg-opacity-25{
    --tw-bg-opacity:0.25
  }

  .lg\:bg-opacity-30{
    --tw-bg-opacity:0.3
  }

  .lg\:bg-opacity-40{
    --tw-bg-opacity:0.4
  }

  .lg\:bg-opacity-50{
    --tw-bg-opacity:0.5
  }

  .lg\:bg-opacity-60{
    --tw-bg-opacity:0.6
  }

  .lg\:bg-opacity-70{
    --tw-bg-opacity:0.7
  }

  .lg\:bg-opacity-75{
    --tw-bg-opacity:0.75
  }

  .lg\:bg-opacity-80{
    --tw-bg-opacity:0.8
  }

  .lg\:bg-opacity-90{
    --tw-bg-opacity:0.9
  }

  .lg\:bg-opacity-95{
    --tw-bg-opacity:0.95
  }

  .lg\:bg-opacity-100{
    --tw-bg-opacity:1
  }

  .group:hover .lg\:group-hover\:bg-opacity-0{
    --tw-bg-opacity:0
  }

  .group:hover .lg\:group-hover\:bg-opacity-5{
    --tw-bg-opacity:0.05
  }

  .group:hover .lg\:group-hover\:bg-opacity-10{
    --tw-bg-opacity:0.1
  }

  .group:hover .lg\:group-hover\:bg-opacity-20{
    --tw-bg-opacity:0.2
  }

  .group:hover .lg\:group-hover\:bg-opacity-25{
    --tw-bg-opacity:0.25
  }

  .group:hover .lg\:group-hover\:bg-opacity-30{
    --tw-bg-opacity:0.3
  }

  .group:hover .lg\:group-hover\:bg-opacity-40{
    --tw-bg-opacity:0.4
  }

  .group:hover .lg\:group-hover\:bg-opacity-50{
    --tw-bg-opacity:0.5
  }

  .group:hover .lg\:group-hover\:bg-opacity-60{
    --tw-bg-opacity:0.6
  }

  .group:hover .lg\:group-hover\:bg-opacity-70{
    --tw-bg-opacity:0.7
  }

  .group:hover .lg\:group-hover\:bg-opacity-75{
    --tw-bg-opacity:0.75
  }

  .group:hover .lg\:group-hover\:bg-opacity-80{
    --tw-bg-opacity:0.8
  }

  .group:hover .lg\:group-hover\:bg-opacity-90{
    --tw-bg-opacity:0.9
  }

  .group:hover .lg\:group-hover\:bg-opacity-95{
    --tw-bg-opacity:0.95
  }

  .group:hover .lg\:group-hover\:bg-opacity-100{
    --tw-bg-opacity:1
  }

  .lg\:focus-within\:bg-opacity-0:focus-within{
    --tw-bg-opacity:0
  }

  .lg\:focus-within\:bg-opacity-5:focus-within{
    --tw-bg-opacity:0.05
  }

  .lg\:focus-within\:bg-opacity-10:focus-within{
    --tw-bg-opacity:0.1
  }

  .lg\:focus-within\:bg-opacity-20:focus-within{
    --tw-bg-opacity:0.2
  }

  .lg\:focus-within\:bg-opacity-25:focus-within{
    --tw-bg-opacity:0.25
  }

  .lg\:focus-within\:bg-opacity-30:focus-within{
    --tw-bg-opacity:0.3
  }

  .lg\:focus-within\:bg-opacity-40:focus-within{
    --tw-bg-opacity:0.4
  }

  .lg\:focus-within\:bg-opacity-50:focus-within{
    --tw-bg-opacity:0.5
  }

  .lg\:focus-within\:bg-opacity-60:focus-within{
    --tw-bg-opacity:0.6
  }

  .lg\:focus-within\:bg-opacity-70:focus-within{
    --tw-bg-opacity:0.7
  }

  .lg\:focus-within\:bg-opacity-75:focus-within{
    --tw-bg-opacity:0.75
  }

  .lg\:focus-within\:bg-opacity-80:focus-within{
    --tw-bg-opacity:0.8
  }

  .lg\:focus-within\:bg-opacity-90:focus-within{
    --tw-bg-opacity:0.9
  }

  .lg\:focus-within\:bg-opacity-95:focus-within{
    --tw-bg-opacity:0.95
  }

  .lg\:focus-within\:bg-opacity-100:focus-within{
    --tw-bg-opacity:1
  }

  .lg\:hover\:bg-opacity-0:hover{
    --tw-bg-opacity:0
  }

  .lg\:hover\:bg-opacity-5:hover{
    --tw-bg-opacity:0.05
  }

  .lg\:hover\:bg-opacity-10:hover{
    --tw-bg-opacity:0.1
  }

  .lg\:hover\:bg-opacity-20:hover{
    --tw-bg-opacity:0.2
  }

  .lg\:hover\:bg-opacity-25:hover{
    --tw-bg-opacity:0.25
  }

  .lg\:hover\:bg-opacity-30:hover{
    --tw-bg-opacity:0.3
  }

  .lg\:hover\:bg-opacity-40:hover{
    --tw-bg-opacity:0.4
  }

  .lg\:hover\:bg-opacity-50:hover{
    --tw-bg-opacity:0.5
  }

  .lg\:hover\:bg-opacity-60:hover{
    --tw-bg-opacity:0.6
  }

  .lg\:hover\:bg-opacity-70:hover{
    --tw-bg-opacity:0.7
  }

  .lg\:hover\:bg-opacity-75:hover{
    --tw-bg-opacity:0.75
  }

  .lg\:hover\:bg-opacity-80:hover{
    --tw-bg-opacity:0.8
  }

  .lg\:hover\:bg-opacity-90:hover{
    --tw-bg-opacity:0.9
  }

  .lg\:hover\:bg-opacity-95:hover{
    --tw-bg-opacity:0.95
  }

  .lg\:hover\:bg-opacity-100:hover{
    --tw-bg-opacity:1
  }

  .lg\:focus\:bg-opacity-0:focus{
    --tw-bg-opacity:0
  }

  .lg\:focus\:bg-opacity-5:focus{
    --tw-bg-opacity:0.05
  }

  .lg\:focus\:bg-opacity-10:focus{
    --tw-bg-opacity:0.1
  }

  .lg\:focus\:bg-opacity-20:focus{
    --tw-bg-opacity:0.2
  }

  .lg\:focus\:bg-opacity-25:focus{
    --tw-bg-opacity:0.25
  }

  .lg\:focus\:bg-opacity-30:focus{
    --tw-bg-opacity:0.3
  }

  .lg\:focus\:bg-opacity-40:focus{
    --tw-bg-opacity:0.4
  }

  .lg\:focus\:bg-opacity-50:focus{
    --tw-bg-opacity:0.5
  }

  .lg\:focus\:bg-opacity-60:focus{
    --tw-bg-opacity:0.6
  }

  .lg\:focus\:bg-opacity-70:focus{
    --tw-bg-opacity:0.7
  }

  .lg\:focus\:bg-opacity-75:focus{
    --tw-bg-opacity:0.75
  }

  .lg\:focus\:bg-opacity-80:focus{
    --tw-bg-opacity:0.8
  }

  .lg\:focus\:bg-opacity-90:focus{
    --tw-bg-opacity:0.9
  }

  .lg\:focus\:bg-opacity-95:focus{
    --tw-bg-opacity:0.95
  }

  .lg\:focus\:bg-opacity-100:focus{
    --tw-bg-opacity:1
  }

  .lg\:bg-none{
    background-image:none
  }

  .lg\:bg-gradient-to-t{
    background-image:linear-gradient(to top, var(--tw-gradient-stops))
  }

  .lg\:bg-gradient-to-tr{
    background-image:linear-gradient(to top right, var(--tw-gradient-stops))
  }

  .lg\:bg-gradient-to-r{
    background-image:linear-gradient(to right, var(--tw-gradient-stops))
  }

  .lg\:bg-gradient-to-br{
    background-image:linear-gradient(to bottom right, var(--tw-gradient-stops))
  }

  .lg\:bg-gradient-to-b{
    background-image:linear-gradient(to bottom, var(--tw-gradient-stops))
  }

  .lg\:bg-gradient-to-bl{
    background-image:linear-gradient(to bottom left, var(--tw-gradient-stops))
  }

  .lg\:bg-gradient-to-l{
    background-image:linear-gradient(to left, var(--tw-gradient-stops))
  }

  .lg\:bg-gradient-to-tl{
    background-image:linear-gradient(to top left, var(--tw-gradient-stops))
  }

  .lg\:from-red1{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .lg\:from-red2{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .lg\:from-red3{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .lg\:from-red4{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .lg\:from-red5{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .lg\:from-cyan1{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .lg\:from-cyan2{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .lg\:from-cyan3{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .lg\:from-cyan4{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .lg\:from-cyan5{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .lg\:from-bg1{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .lg\:from-bg2{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:from-bg3{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .lg\:from-gray1{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .lg\:from-gray2{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .lg\:from-gray3{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .lg\:from-gray4{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .lg\:from-gray5{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .lg\:from-gray6{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .lg\:from-gray7{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:from-white{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:from-warning{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .lg\:from-error{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .lg\:from-info{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .lg\:from-success{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .lg\:from-special4{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .lg\:from-dark{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:from-transparent{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:from-secondary1{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .lg\:from-secondary2{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:from-secondary3{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .lg\:from-secondary4{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .lg\:from-secondary5{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .lg\:from-green1{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .lg\:hover\:from-red1:hover{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .lg\:hover\:from-red2:hover{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .lg\:hover\:from-red3:hover{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .lg\:hover\:from-red4:hover{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .lg\:hover\:from-red5:hover{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .lg\:hover\:from-cyan1:hover{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .lg\:hover\:from-cyan2:hover{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .lg\:hover\:from-cyan3:hover{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .lg\:hover\:from-cyan4:hover{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .lg\:hover\:from-cyan5:hover{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .lg\:hover\:from-bg1:hover{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .lg\:hover\:from-bg2:hover{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-bg3:hover{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .lg\:hover\:from-gray1:hover{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .lg\:hover\:from-gray2:hover{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .lg\:hover\:from-gray3:hover{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .lg\:hover\:from-gray4:hover{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .lg\:hover\:from-gray5:hover{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .lg\:hover\:from-gray6:hover{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .lg\:hover\:from-gray7:hover{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:hover\:from-white:hover{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-warning:hover{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .lg\:hover\:from-error:hover{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .lg\:hover\:from-info:hover{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .lg\:hover\:from-success:hover{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .lg\:hover\:from-special4:hover{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .lg\:hover\:from-dark:hover{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:from-transparent:hover{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:from-secondary1:hover{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .lg\:hover\:from-secondary2:hover{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:hover\:from-secondary3:hover{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .lg\:hover\:from-secondary4:hover{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .lg\:hover\:from-secondary5:hover{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .lg\:hover\:from-green1:hover{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .lg\:focus\:from-red1:focus{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .lg\:focus\:from-red2:focus{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .lg\:focus\:from-red3:focus{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .lg\:focus\:from-red4:focus{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .lg\:focus\:from-red5:focus{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .lg\:focus\:from-cyan1:focus{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .lg\:focus\:from-cyan2:focus{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .lg\:focus\:from-cyan3:focus{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .lg\:focus\:from-cyan4:focus{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .lg\:focus\:from-cyan5:focus{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .lg\:focus\:from-bg1:focus{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .lg\:focus\:from-bg2:focus{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-bg3:focus{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .lg\:focus\:from-gray1:focus{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .lg\:focus\:from-gray2:focus{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .lg\:focus\:from-gray3:focus{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .lg\:focus\:from-gray4:focus{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .lg\:focus\:from-gray5:focus{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .lg\:focus\:from-gray6:focus{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .lg\:focus\:from-gray7:focus{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:focus\:from-white:focus{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-warning:focus{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .lg\:focus\:from-error:focus{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .lg\:focus\:from-info:focus{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .lg\:focus\:from-success:focus{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .lg\:focus\:from-special4:focus{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .lg\:focus\:from-dark:focus{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:from-transparent:focus{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:from-secondary1:focus{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .lg\:focus\:from-secondary2:focus{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:focus\:from-secondary3:focus{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .lg\:focus\:from-secondary4:focus{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .lg\:focus\:from-secondary5:focus{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .lg\:focus\:from-green1:focus{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .lg\:via-red1{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .lg\:via-red2{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .lg\:via-red3{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .lg\:via-red4{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .lg\:via-red5{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .lg\:via-cyan1{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .lg\:via-cyan2{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .lg\:via-cyan3{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .lg\:via-cyan4{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .lg\:via-cyan5{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .lg\:via-bg1{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .lg\:via-bg2{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:via-bg3{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .lg\:via-gray1{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .lg\:via-gray2{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .lg\:via-gray3{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .lg\:via-gray4{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .lg\:via-gray5{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .lg\:via-gray6{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .lg\:via-gray7{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:via-white{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:via-warning{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .lg\:via-error{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .lg\:via-info{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .lg\:via-success{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .lg\:via-special4{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .lg\:via-dark{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:via-transparent{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:via-secondary1{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .lg\:via-secondary2{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:via-secondary3{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .lg\:via-secondary4{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .lg\:via-secondary5{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .lg\:via-green1{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .lg\:hover\:via-red1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .lg\:hover\:via-red2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .lg\:hover\:via-red3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .lg\:hover\:via-red4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .lg\:hover\:via-red5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .lg\:hover\:via-cyan1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .lg\:hover\:via-cyan2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .lg\:hover\:via-cyan3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .lg\:hover\:via-cyan4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .lg\:hover\:via-cyan5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .lg\:hover\:via-bg1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .lg\:hover\:via-bg2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-bg3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .lg\:hover\:via-gray1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .lg\:hover\:via-gray2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .lg\:hover\:via-gray3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .lg\:hover\:via-gray4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .lg\:hover\:via-gray5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .lg\:hover\:via-gray6:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .lg\:hover\:via-gray7:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:hover\:via-white:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-warning:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .lg\:hover\:via-error:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .lg\:hover\:via-info:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .lg\:hover\:via-success:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .lg\:hover\:via-special4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .lg\:hover\:via-dark:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:via-transparent:hover{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:hover\:via-secondary1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .lg\:hover\:via-secondary2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:hover\:via-secondary3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .lg\:hover\:via-secondary4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .lg\:hover\:via-secondary5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .lg\:hover\:via-green1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .lg\:focus\:via-red1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .lg\:focus\:via-red2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .lg\:focus\:via-red3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .lg\:focus\:via-red4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .lg\:focus\:via-red5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .lg\:focus\:via-cyan1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .lg\:focus\:via-cyan2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .lg\:focus\:via-cyan3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .lg\:focus\:via-cyan4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .lg\:focus\:via-cyan5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .lg\:focus\:via-bg1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .lg\:focus\:via-bg2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-bg3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .lg\:focus\:via-gray1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .lg\:focus\:via-gray2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .lg\:focus\:via-gray3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .lg\:focus\:via-gray4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .lg\:focus\:via-gray5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .lg\:focus\:via-gray6:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .lg\:focus\:via-gray7:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:focus\:via-white:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-warning:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .lg\:focus\:via-error:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .lg\:focus\:via-info:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .lg\:focus\:via-success:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .lg\:focus\:via-special4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .lg\:focus\:via-dark:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:via-transparent:focus{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .lg\:focus\:via-secondary1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .lg\:focus\:via-secondary2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .lg\:focus\:via-secondary3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .lg\:focus\:via-secondary4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .lg\:focus\:via-secondary5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .lg\:focus\:via-green1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .lg\:to-red1{
    --tw-gradient-to:#CC352C
  }

  .lg\:to-red2{
    --tw-gradient-to:#FF554B
  }

  .lg\:to-red3{
    --tw-gradient-to:#FF7B73
  }

  .lg\:to-red4{
    --tw-gradient-to:#FFF1F1
  }

  .lg\:to-red5{
    --tw-gradient-to:#FFF6F6
  }

  .lg\:to-cyan1{
    --tw-gradient-to:#009897
  }

  .lg\:to-cyan2{
    --tw-gradient-to:#00BFBD
  }

  .lg\:to-cyan3{
    --tw-gradient-to:#4CD1D0
  }

  .lg\:to-cyan4{
    --tw-gradient-to:#B2ECEB
  }

  .lg\:to-cyan5{
    --tw-gradient-to:#EDFAFA
  }

  .lg\:to-bg1{
    --tw-gradient-to:#FAFAFC
  }

  .lg\:to-bg2{
    --tw-gradient-to:#ffffff
  }

  .lg\:to-bg3{
    --tw-gradient-to:#F7F7FA
  }

  .lg\:to-gray1{
    --tw-gradient-to:#333333
  }

  .lg\:to-gray2{
    --tw-gradient-to:#4D4D4D
  }

  .lg\:to-gray3{
    --tw-gradient-to:#8C8C8C
  }

  .lg\:to-gray4{
    --tw-gradient-to:#BFBFBF
  }

  .lg\:to-gray5{
    --tw-gradient-to:#D9D9D9
  }

  .lg\:to-gray6{
    --tw-gradient-to:#E9E9EB
  }

  .lg\:to-gray7{
    --tw-gradient-to:#515164
  }

  .lg\:to-white{
    --tw-gradient-to:#ffffff
  }

  .lg\:to-warning{
    --tw-gradient-to:#FFC319
  }

  .lg\:to-error{
    --tw-gradient-to:#EA696F
  }

  .lg\:to-info{
    --tw-gradient-to:#0071B9
  }

  .lg\:to-success{
    --tw-gradient-to:#98D45F
  }

  .lg\:to-special4{
    --tw-gradient-to:#59779A
  }

  .lg\:to-dark{
    --tw-gradient-to:#000000
  }

  .lg\:to-transparent{
    --tw-gradient-to:transparent
  }

  .lg\:to-secondary1{
    --tw-gradient-to:#313140
  }

  .lg\:to-secondary2{
    --tw-gradient-to:#515164
  }

  .lg\:to-secondary3{
    --tw-gradient-to:#9C9CAC
  }

  .lg\:to-secondary4{
    --tw-gradient-to:#E5E8ED
  }

  .lg\:to-secondary5{
    --tw-gradient-to:#F5F6F9
  }

  .lg\:to-green1{
    --tw-gradient-to:#0DE298
  }

  .lg\:hover\:to-red1:hover{
    --tw-gradient-to:#CC352C
  }

  .lg\:hover\:to-red2:hover{
    --tw-gradient-to:#FF554B
  }

  .lg\:hover\:to-red3:hover{
    --tw-gradient-to:#FF7B73
  }

  .lg\:hover\:to-red4:hover{
    --tw-gradient-to:#FFF1F1
  }

  .lg\:hover\:to-red5:hover{
    --tw-gradient-to:#FFF6F6
  }

  .lg\:hover\:to-cyan1:hover{
    --tw-gradient-to:#009897
  }

  .lg\:hover\:to-cyan2:hover{
    --tw-gradient-to:#00BFBD
  }

  .lg\:hover\:to-cyan3:hover{
    --tw-gradient-to:#4CD1D0
  }

  .lg\:hover\:to-cyan4:hover{
    --tw-gradient-to:#B2ECEB
  }

  .lg\:hover\:to-cyan5:hover{
    --tw-gradient-to:#EDFAFA
  }

  .lg\:hover\:to-bg1:hover{
    --tw-gradient-to:#FAFAFC
  }

  .lg\:hover\:to-bg2:hover{
    --tw-gradient-to:#ffffff
  }

  .lg\:hover\:to-bg3:hover{
    --tw-gradient-to:#F7F7FA
  }

  .lg\:hover\:to-gray1:hover{
    --tw-gradient-to:#333333
  }

  .lg\:hover\:to-gray2:hover{
    --tw-gradient-to:#4D4D4D
  }

  .lg\:hover\:to-gray3:hover{
    --tw-gradient-to:#8C8C8C
  }

  .lg\:hover\:to-gray4:hover{
    --tw-gradient-to:#BFBFBF
  }

  .lg\:hover\:to-gray5:hover{
    --tw-gradient-to:#D9D9D9
  }

  .lg\:hover\:to-gray6:hover{
    --tw-gradient-to:#E9E9EB
  }

  .lg\:hover\:to-gray7:hover{
    --tw-gradient-to:#515164
  }

  .lg\:hover\:to-white:hover{
    --tw-gradient-to:#ffffff
  }

  .lg\:hover\:to-warning:hover{
    --tw-gradient-to:#FFC319
  }

  .lg\:hover\:to-error:hover{
    --tw-gradient-to:#EA696F
  }

  .lg\:hover\:to-info:hover{
    --tw-gradient-to:#0071B9
  }

  .lg\:hover\:to-success:hover{
    --tw-gradient-to:#98D45F
  }

  .lg\:hover\:to-special4:hover{
    --tw-gradient-to:#59779A
  }

  .lg\:hover\:to-dark:hover{
    --tw-gradient-to:#000000
  }

  .lg\:hover\:to-transparent:hover{
    --tw-gradient-to:transparent
  }

  .lg\:hover\:to-secondary1:hover{
    --tw-gradient-to:#313140
  }

  .lg\:hover\:to-secondary2:hover{
    --tw-gradient-to:#515164
  }

  .lg\:hover\:to-secondary3:hover{
    --tw-gradient-to:#9C9CAC
  }

  .lg\:hover\:to-secondary4:hover{
    --tw-gradient-to:#E5E8ED
  }

  .lg\:hover\:to-secondary5:hover{
    --tw-gradient-to:#F5F6F9
  }

  .lg\:hover\:to-green1:hover{
    --tw-gradient-to:#0DE298
  }

  .lg\:focus\:to-red1:focus{
    --tw-gradient-to:#CC352C
  }

  .lg\:focus\:to-red2:focus{
    --tw-gradient-to:#FF554B
  }

  .lg\:focus\:to-red3:focus{
    --tw-gradient-to:#FF7B73
  }

  .lg\:focus\:to-red4:focus{
    --tw-gradient-to:#FFF1F1
  }

  .lg\:focus\:to-red5:focus{
    --tw-gradient-to:#FFF6F6
  }

  .lg\:focus\:to-cyan1:focus{
    --tw-gradient-to:#009897
  }

  .lg\:focus\:to-cyan2:focus{
    --tw-gradient-to:#00BFBD
  }

  .lg\:focus\:to-cyan3:focus{
    --tw-gradient-to:#4CD1D0
  }

  .lg\:focus\:to-cyan4:focus{
    --tw-gradient-to:#B2ECEB
  }

  .lg\:focus\:to-cyan5:focus{
    --tw-gradient-to:#EDFAFA
  }

  .lg\:focus\:to-bg1:focus{
    --tw-gradient-to:#FAFAFC
  }

  .lg\:focus\:to-bg2:focus{
    --tw-gradient-to:#ffffff
  }

  .lg\:focus\:to-bg3:focus{
    --tw-gradient-to:#F7F7FA
  }

  .lg\:focus\:to-gray1:focus{
    --tw-gradient-to:#333333
  }

  .lg\:focus\:to-gray2:focus{
    --tw-gradient-to:#4D4D4D
  }

  .lg\:focus\:to-gray3:focus{
    --tw-gradient-to:#8C8C8C
  }

  .lg\:focus\:to-gray4:focus{
    --tw-gradient-to:#BFBFBF
  }

  .lg\:focus\:to-gray5:focus{
    --tw-gradient-to:#D9D9D9
  }

  .lg\:focus\:to-gray6:focus{
    --tw-gradient-to:#E9E9EB
  }

  .lg\:focus\:to-gray7:focus{
    --tw-gradient-to:#515164
  }

  .lg\:focus\:to-white:focus{
    --tw-gradient-to:#ffffff
  }

  .lg\:focus\:to-warning:focus{
    --tw-gradient-to:#FFC319
  }

  .lg\:focus\:to-error:focus{
    --tw-gradient-to:#EA696F
  }

  .lg\:focus\:to-info:focus{
    --tw-gradient-to:#0071B9
  }

  .lg\:focus\:to-success:focus{
    --tw-gradient-to:#98D45F
  }

  .lg\:focus\:to-special4:focus{
    --tw-gradient-to:#59779A
  }

  .lg\:focus\:to-dark:focus{
    --tw-gradient-to:#000000
  }

  .lg\:focus\:to-transparent:focus{
    --tw-gradient-to:transparent
  }

  .lg\:focus\:to-secondary1:focus{
    --tw-gradient-to:#313140
  }

  .lg\:focus\:to-secondary2:focus{
    --tw-gradient-to:#515164
  }

  .lg\:focus\:to-secondary3:focus{
    --tw-gradient-to:#9C9CAC
  }

  .lg\:focus\:to-secondary4:focus{
    --tw-gradient-to:#E5E8ED
  }

  .lg\:focus\:to-secondary5:focus{
    --tw-gradient-to:#F5F6F9
  }

  .lg\:focus\:to-green1:focus{
    --tw-gradient-to:#0DE298
  }

  .lg\:decoration-slice{
    -webkit-box-decoration-break:slice;
            box-decoration-break:slice
  }

  .lg\:decoration-clone{
    -webkit-box-decoration-break:clone;
            box-decoration-break:clone
  }

  .lg\:bg-auto{
    background-size:auto
  }

  .lg\:bg-cover{
    background-size:cover
  }

  .lg\:bg-contain{
    background-size:contain
  }

  .lg\:bg-clip-border{
    background-clip:border-box
  }

  .lg\:bg-clip-padding{
    background-clip:padding-box
  }

  .lg\:bg-clip-content{
    background-clip:content-box
  }

  .lg\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .lg\:bg-bottom{
    background-position:bottom
  }

  .lg\:bg-center{
    background-position:center
  }

  .lg\:bg-left{
    background-position:left
  }

  .lg\:bg-left-bottom{
    background-position:left bottom
  }

  .lg\:bg-left-top{
    background-position:left top
  }

  .lg\:bg-right{
    background-position:right
  }

  .lg\:bg-right-bottom{
    background-position:right bottom
  }

  .lg\:bg-right-top{
    background-position:right top
  }

  .lg\:bg-top{
    background-position:top
  }

  .lg\:bg-repeat{
    background-repeat:repeat
  }

  .lg\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .lg\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .lg\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .lg\:bg-repeat-round{
    background-repeat:round
  }

  .lg\:bg-repeat-space{
    background-repeat:space
  }

  .lg\:bg-origin-border{
    background-origin:border-box
  }

  .lg\:bg-origin-padding{
    background-origin:padding-box
  }

  .lg\:bg-origin-content{
    background-origin:content-box
  }

  .lg\:fill-current{
    fill:currentColor
  }

  .lg\:object-contain{
    object-fit:contain
  }

  .lg\:object-cover{
    object-fit:cover
  }

  .lg\:object-fill{
    object-fit:fill
  }

  .lg\:object-none{
    object-fit:none
  }

  .lg\:object-scale-down{
    object-fit:scale-down
  }

  .lg\:p-0{
    padding:0
  }

  .lg\:p-1{
    padding:4px
  }

  .lg\:p-2{
    padding:8px
  }

  .lg\:p-3{
    padding:12px
  }

  .lg\:p-4{
    padding:16px
  }

  .lg\:p-5{
    padding:20px
  }

  .lg\:p-6{
    padding:24px
  }

  .lg\:p-8{
    padding:32px
  }

  .lg\:p-10{
    padding:40px
  }

  .lg\:p-12{
    padding:48px
  }

  .lg\:p-16{
    padding:64px
  }

  .lg\:p-18{
    padding:72px
  }

  .lg\:p-24{
    padding:96px
  }

  .lg\:p-5dot5{
    padding:22px
  }

  .lg\:p-full{
    padding:100%
  }

  .lg\:first\:p-0:first-child{
    padding:0
  }

  .lg\:first\:p-1:first-child{
    padding:4px
  }

  .lg\:first\:p-2:first-child{
    padding:8px
  }

  .lg\:first\:p-3:first-child{
    padding:12px
  }

  .lg\:first\:p-4:first-child{
    padding:16px
  }

  .lg\:first\:p-5:first-child{
    padding:20px
  }

  .lg\:first\:p-6:first-child{
    padding:24px
  }

  .lg\:first\:p-8:first-child{
    padding:32px
  }

  .lg\:first\:p-10:first-child{
    padding:40px
  }

  .lg\:first\:p-12:first-child{
    padding:48px
  }

  .lg\:first\:p-16:first-child{
    padding:64px
  }

  .lg\:first\:p-18:first-child{
    padding:72px
  }

  .lg\:first\:p-24:first-child{
    padding:96px
  }

  .lg\:first\:p-5dot5:first-child{
    padding:22px
  }

  .lg\:first\:p-full:first-child{
    padding:100%
  }

  .lg\:last\:p-0:last-child{
    padding:0
  }

  .lg\:last\:p-1:last-child{
    padding:4px
  }

  .lg\:last\:p-2:last-child{
    padding:8px
  }

  .lg\:last\:p-3:last-child{
    padding:12px
  }

  .lg\:last\:p-4:last-child{
    padding:16px
  }

  .lg\:last\:p-5:last-child{
    padding:20px
  }

  .lg\:last\:p-6:last-child{
    padding:24px
  }

  .lg\:last\:p-8:last-child{
    padding:32px
  }

  .lg\:last\:p-10:last-child{
    padding:40px
  }

  .lg\:last\:p-12:last-child{
    padding:48px
  }

  .lg\:last\:p-16:last-child{
    padding:64px
  }

  .lg\:last\:p-18:last-child{
    padding:72px
  }

  .lg\:last\:p-24:last-child{
    padding:96px
  }

  .lg\:last\:p-5dot5:last-child{
    padding:22px
  }

  .lg\:last\:p-full:last-child{
    padding:100%
  }

  .lg\:px-0{
    padding-left:0;
    padding-right:0
  }

  .lg\:px-1{
    padding-left:4px;
    padding-right:4px
  }

  .lg\:px-2{
    padding-left:8px;
    padding-right:8px
  }

  .lg\:px-3{
    padding-left:12px;
    padding-right:12px
  }

  .lg\:px-4{
    padding-left:16px;
    padding-right:16px
  }

  .lg\:px-5{
    padding-left:20px;
    padding-right:20px
  }

  .lg\:px-6{
    padding-left:24px;
    padding-right:24px
  }

  .lg\:px-8{
    padding-left:32px;
    padding-right:32px
  }

  .lg\:px-10{
    padding-left:40px;
    padding-right:40px
  }

  .lg\:px-12{
    padding-left:48px;
    padding-right:48px
  }

  .lg\:px-16{
    padding-left:64px;
    padding-right:64px
  }

  .lg\:px-18{
    padding-left:72px;
    padding-right:72px
  }

  .lg\:px-24{
    padding-left:96px;
    padding-right:96px
  }

  .lg\:px-5dot5{
    padding-left:22px;
    padding-right:22px
  }

  .lg\:px-full{
    padding-left:100%;
    padding-right:100%
  }

  .lg\:py-0{
    padding-top:0;
    padding-bottom:0
  }

  .lg\:py-1{
    padding-top:4px;
    padding-bottom:4px
  }

  .lg\:py-2{
    padding-top:8px;
    padding-bottom:8px
  }

  .lg\:py-3{
    padding-top:12px;
    padding-bottom:12px
  }

  .lg\:py-4{
    padding-top:16px;
    padding-bottom:16px
  }

  .lg\:py-5{
    padding-top:20px;
    padding-bottom:20px
  }

  .lg\:py-6{
    padding-top:24px;
    padding-bottom:24px
  }

  .lg\:py-8{
    padding-top:32px;
    padding-bottom:32px
  }

  .lg\:py-10{
    padding-top:40px;
    padding-bottom:40px
  }

  .lg\:py-12{
    padding-top:48px;
    padding-bottom:48px
  }

  .lg\:py-16{
    padding-top:64px;
    padding-bottom:64px
  }

  .lg\:py-18{
    padding-top:72px;
    padding-bottom:72px
  }

  .lg\:py-24{
    padding-top:96px;
    padding-bottom:96px
  }

  .lg\:py-5dot5{
    padding-top:22px;
    padding-bottom:22px
  }

  .lg\:py-full{
    padding-top:100%;
    padding-bottom:100%
  }

  .lg\:first\:px-0:first-child{
    padding-left:0;
    padding-right:0
  }

  .lg\:first\:px-1:first-child{
    padding-left:4px;
    padding-right:4px
  }

  .lg\:first\:px-2:first-child{
    padding-left:8px;
    padding-right:8px
  }

  .lg\:first\:px-3:first-child{
    padding-left:12px;
    padding-right:12px
  }

  .lg\:first\:px-4:first-child{
    padding-left:16px;
    padding-right:16px
  }

  .lg\:first\:px-5:first-child{
    padding-left:20px;
    padding-right:20px
  }

  .lg\:first\:px-6:first-child{
    padding-left:24px;
    padding-right:24px
  }

  .lg\:first\:px-8:first-child{
    padding-left:32px;
    padding-right:32px
  }

  .lg\:first\:px-10:first-child{
    padding-left:40px;
    padding-right:40px
  }

  .lg\:first\:px-12:first-child{
    padding-left:48px;
    padding-right:48px
  }

  .lg\:first\:px-16:first-child{
    padding-left:64px;
    padding-right:64px
  }

  .lg\:first\:px-18:first-child{
    padding-left:72px;
    padding-right:72px
  }

  .lg\:first\:px-24:first-child{
    padding-left:96px;
    padding-right:96px
  }

  .lg\:first\:px-5dot5:first-child{
    padding-left:22px;
    padding-right:22px
  }

  .lg\:first\:px-full:first-child{
    padding-left:100%;
    padding-right:100%
  }

  .lg\:first\:py-0:first-child{
    padding-top:0;
    padding-bottom:0
  }

  .lg\:first\:py-1:first-child{
    padding-top:4px;
    padding-bottom:4px
  }

  .lg\:first\:py-2:first-child{
    padding-top:8px;
    padding-bottom:8px
  }

  .lg\:first\:py-3:first-child{
    padding-top:12px;
    padding-bottom:12px
  }

  .lg\:first\:py-4:first-child{
    padding-top:16px;
    padding-bottom:16px
  }

  .lg\:first\:py-5:first-child{
    padding-top:20px;
    padding-bottom:20px
  }

  .lg\:first\:py-6:first-child{
    padding-top:24px;
    padding-bottom:24px
  }

  .lg\:first\:py-8:first-child{
    padding-top:32px;
    padding-bottom:32px
  }

  .lg\:first\:py-10:first-child{
    padding-top:40px;
    padding-bottom:40px
  }

  .lg\:first\:py-12:first-child{
    padding-top:48px;
    padding-bottom:48px
  }

  .lg\:first\:py-16:first-child{
    padding-top:64px;
    padding-bottom:64px
  }

  .lg\:first\:py-18:first-child{
    padding-top:72px;
    padding-bottom:72px
  }

  .lg\:first\:py-24:first-child{
    padding-top:96px;
    padding-bottom:96px
  }

  .lg\:first\:py-5dot5:first-child{
    padding-top:22px;
    padding-bottom:22px
  }

  .lg\:first\:py-full:first-child{
    padding-top:100%;
    padding-bottom:100%
  }

  .lg\:last\:px-0:last-child{
    padding-left:0;
    padding-right:0
  }

  .lg\:last\:px-1:last-child{
    padding-left:4px;
    padding-right:4px
  }

  .lg\:last\:px-2:last-child{
    padding-left:8px;
    padding-right:8px
  }

  .lg\:last\:px-3:last-child{
    padding-left:12px;
    padding-right:12px
  }

  .lg\:last\:px-4:last-child{
    padding-left:16px;
    padding-right:16px
  }

  .lg\:last\:px-5:last-child{
    padding-left:20px;
    padding-right:20px
  }

  .lg\:last\:px-6:last-child{
    padding-left:24px;
    padding-right:24px
  }

  .lg\:last\:px-8:last-child{
    padding-left:32px;
    padding-right:32px
  }

  .lg\:last\:px-10:last-child{
    padding-left:40px;
    padding-right:40px
  }

  .lg\:last\:px-12:last-child{
    padding-left:48px;
    padding-right:48px
  }

  .lg\:last\:px-16:last-child{
    padding-left:64px;
    padding-right:64px
  }

  .lg\:last\:px-18:last-child{
    padding-left:72px;
    padding-right:72px
  }

  .lg\:last\:px-24:last-child{
    padding-left:96px;
    padding-right:96px
  }

  .lg\:last\:px-5dot5:last-child{
    padding-left:22px;
    padding-right:22px
  }

  .lg\:last\:px-full:last-child{
    padding-left:100%;
    padding-right:100%
  }

  .lg\:last\:py-0:last-child{
    padding-top:0;
    padding-bottom:0
  }

  .lg\:last\:py-1:last-child{
    padding-top:4px;
    padding-bottom:4px
  }

  .lg\:last\:py-2:last-child{
    padding-top:8px;
    padding-bottom:8px
  }

  .lg\:last\:py-3:last-child{
    padding-top:12px;
    padding-bottom:12px
  }

  .lg\:last\:py-4:last-child{
    padding-top:16px;
    padding-bottom:16px
  }

  .lg\:last\:py-5:last-child{
    padding-top:20px;
    padding-bottom:20px
  }

  .lg\:last\:py-6:last-child{
    padding-top:24px;
    padding-bottom:24px
  }

  .lg\:last\:py-8:last-child{
    padding-top:32px;
    padding-bottom:32px
  }

  .lg\:last\:py-10:last-child{
    padding-top:40px;
    padding-bottom:40px
  }

  .lg\:last\:py-12:last-child{
    padding-top:48px;
    padding-bottom:48px
  }

  .lg\:last\:py-16:last-child{
    padding-top:64px;
    padding-bottom:64px
  }

  .lg\:last\:py-18:last-child{
    padding-top:72px;
    padding-bottom:72px
  }

  .lg\:last\:py-24:last-child{
    padding-top:96px;
    padding-bottom:96px
  }

  .lg\:last\:py-5dot5:last-child{
    padding-top:22px;
    padding-bottom:22px
  }

  .lg\:last\:py-full:last-child{
    padding-top:100%;
    padding-bottom:100%
  }

  .lg\:pt-0{
    padding-top:0
  }

  .lg\:pt-1{
    padding-top:4px
  }

  .lg\:pt-2{
    padding-top:8px
  }

  .lg\:pt-3{
    padding-top:12px
  }

  .lg\:pt-4{
    padding-top:16px
  }

  .lg\:pt-5{
    padding-top:20px
  }

  .lg\:pt-6{
    padding-top:24px
  }

  .lg\:pt-8{
    padding-top:32px
  }

  .lg\:pt-10{
    padding-top:40px
  }

  .lg\:pt-12{
    padding-top:48px
  }

  .lg\:pt-16{
    padding-top:64px
  }

  .lg\:pt-18{
    padding-top:72px
  }

  .lg\:pt-24{
    padding-top:96px
  }

  .lg\:pt-5dot5{
    padding-top:22px
  }

  .lg\:pt-full{
    padding-top:100%
  }

  .lg\:pr-0{
    padding-right:0
  }

  .lg\:pr-1{
    padding-right:4px
  }

  .lg\:pr-2{
    padding-right:8px
  }

  .lg\:pr-3{
    padding-right:12px
  }

  .lg\:pr-4{
    padding-right:16px
  }

  .lg\:pr-5{
    padding-right:20px
  }

  .lg\:pr-6{
    padding-right:24px
  }

  .lg\:pr-8{
    padding-right:32px
  }

  .lg\:pr-10{
    padding-right:40px
  }

  .lg\:pr-12{
    padding-right:48px
  }

  .lg\:pr-16{
    padding-right:64px
  }

  .lg\:pr-18{
    padding-right:72px
  }

  .lg\:pr-24{
    padding-right:96px
  }

  .lg\:pr-5dot5{
    padding-right:22px
  }

  .lg\:pr-full{
    padding-right:100%
  }

  .lg\:pb-0{
    padding-bottom:0
  }

  .lg\:pb-1{
    padding-bottom:4px
  }

  .lg\:pb-2{
    padding-bottom:8px
  }

  .lg\:pb-3{
    padding-bottom:12px
  }

  .lg\:pb-4{
    padding-bottom:16px
  }

  .lg\:pb-5{
    padding-bottom:20px
  }

  .lg\:pb-6{
    padding-bottom:24px
  }

  .lg\:pb-8{
    padding-bottom:32px
  }

  .lg\:pb-10{
    padding-bottom:40px
  }

  .lg\:pb-12{
    padding-bottom:48px
  }

  .lg\:pb-16{
    padding-bottom:64px
  }

  .lg\:pb-18{
    padding-bottom:72px
  }

  .lg\:pb-24{
    padding-bottom:96px
  }

  .lg\:pb-5dot5{
    padding-bottom:22px
  }

  .lg\:pb-full{
    padding-bottom:100%
  }

  .lg\:pl-0{
    padding-left:0
  }

  .lg\:pl-1{
    padding-left:4px
  }

  .lg\:pl-2{
    padding-left:8px
  }

  .lg\:pl-3{
    padding-left:12px
  }

  .lg\:pl-4{
    padding-left:16px
  }

  .lg\:pl-5{
    padding-left:20px
  }

  .lg\:pl-6{
    padding-left:24px
  }

  .lg\:pl-8{
    padding-left:32px
  }

  .lg\:pl-10{
    padding-left:40px
  }

  .lg\:pl-12{
    padding-left:48px
  }

  .lg\:pl-16{
    padding-left:64px
  }

  .lg\:pl-18{
    padding-left:72px
  }

  .lg\:pl-24{
    padding-left:96px
  }

  .lg\:pl-5dot5{
    padding-left:22px
  }

  .lg\:pl-full{
    padding-left:100%
  }

  .lg\:first\:pt-0:first-child{
    padding-top:0
  }

  .lg\:first\:pt-1:first-child{
    padding-top:4px
  }

  .lg\:first\:pt-2:first-child{
    padding-top:8px
  }

  .lg\:first\:pt-3:first-child{
    padding-top:12px
  }

  .lg\:first\:pt-4:first-child{
    padding-top:16px
  }

  .lg\:first\:pt-5:first-child{
    padding-top:20px
  }

  .lg\:first\:pt-6:first-child{
    padding-top:24px
  }

  .lg\:first\:pt-8:first-child{
    padding-top:32px
  }

  .lg\:first\:pt-10:first-child{
    padding-top:40px
  }

  .lg\:first\:pt-12:first-child{
    padding-top:48px
  }

  .lg\:first\:pt-16:first-child{
    padding-top:64px
  }

  .lg\:first\:pt-18:first-child{
    padding-top:72px
  }

  .lg\:first\:pt-24:first-child{
    padding-top:96px
  }

  .lg\:first\:pt-5dot5:first-child{
    padding-top:22px
  }

  .lg\:first\:pt-full:first-child{
    padding-top:100%
  }

  .lg\:first\:pr-0:first-child{
    padding-right:0
  }

  .lg\:first\:pr-1:first-child{
    padding-right:4px
  }

  .lg\:first\:pr-2:first-child{
    padding-right:8px
  }

  .lg\:first\:pr-3:first-child{
    padding-right:12px
  }

  .lg\:first\:pr-4:first-child{
    padding-right:16px
  }

  .lg\:first\:pr-5:first-child{
    padding-right:20px
  }

  .lg\:first\:pr-6:first-child{
    padding-right:24px
  }

  .lg\:first\:pr-8:first-child{
    padding-right:32px
  }

  .lg\:first\:pr-10:first-child{
    padding-right:40px
  }

  .lg\:first\:pr-12:first-child{
    padding-right:48px
  }

  .lg\:first\:pr-16:first-child{
    padding-right:64px
  }

  .lg\:first\:pr-18:first-child{
    padding-right:72px
  }

  .lg\:first\:pr-24:first-child{
    padding-right:96px
  }

  .lg\:first\:pr-5dot5:first-child{
    padding-right:22px
  }

  .lg\:first\:pr-full:first-child{
    padding-right:100%
  }

  .lg\:first\:pb-0:first-child{
    padding-bottom:0
  }

  .lg\:first\:pb-1:first-child{
    padding-bottom:4px
  }

  .lg\:first\:pb-2:first-child{
    padding-bottom:8px
  }

  .lg\:first\:pb-3:first-child{
    padding-bottom:12px
  }

  .lg\:first\:pb-4:first-child{
    padding-bottom:16px
  }

  .lg\:first\:pb-5:first-child{
    padding-bottom:20px
  }

  .lg\:first\:pb-6:first-child{
    padding-bottom:24px
  }

  .lg\:first\:pb-8:first-child{
    padding-bottom:32px
  }

  .lg\:first\:pb-10:first-child{
    padding-bottom:40px
  }

  .lg\:first\:pb-12:first-child{
    padding-bottom:48px
  }

  .lg\:first\:pb-16:first-child{
    padding-bottom:64px
  }

  .lg\:first\:pb-18:first-child{
    padding-bottom:72px
  }

  .lg\:first\:pb-24:first-child{
    padding-bottom:96px
  }

  .lg\:first\:pb-5dot5:first-child{
    padding-bottom:22px
  }

  .lg\:first\:pb-full:first-child{
    padding-bottom:100%
  }

  .lg\:first\:pl-0:first-child{
    padding-left:0
  }

  .lg\:first\:pl-1:first-child{
    padding-left:4px
  }

  .lg\:first\:pl-2:first-child{
    padding-left:8px
  }

  .lg\:first\:pl-3:first-child{
    padding-left:12px
  }

  .lg\:first\:pl-4:first-child{
    padding-left:16px
  }

  .lg\:first\:pl-5:first-child{
    padding-left:20px
  }

  .lg\:first\:pl-6:first-child{
    padding-left:24px
  }

  .lg\:first\:pl-8:first-child{
    padding-left:32px
  }

  .lg\:first\:pl-10:first-child{
    padding-left:40px
  }

  .lg\:first\:pl-12:first-child{
    padding-left:48px
  }

  .lg\:first\:pl-16:first-child{
    padding-left:64px
  }

  .lg\:first\:pl-18:first-child{
    padding-left:72px
  }

  .lg\:first\:pl-24:first-child{
    padding-left:96px
  }

  .lg\:first\:pl-5dot5:first-child{
    padding-left:22px
  }

  .lg\:first\:pl-full:first-child{
    padding-left:100%
  }

  .lg\:last\:pt-0:last-child{
    padding-top:0
  }

  .lg\:last\:pt-1:last-child{
    padding-top:4px
  }

  .lg\:last\:pt-2:last-child{
    padding-top:8px
  }

  .lg\:last\:pt-3:last-child{
    padding-top:12px
  }

  .lg\:last\:pt-4:last-child{
    padding-top:16px
  }

  .lg\:last\:pt-5:last-child{
    padding-top:20px
  }

  .lg\:last\:pt-6:last-child{
    padding-top:24px
  }

  .lg\:last\:pt-8:last-child{
    padding-top:32px
  }

  .lg\:last\:pt-10:last-child{
    padding-top:40px
  }

  .lg\:last\:pt-12:last-child{
    padding-top:48px
  }

  .lg\:last\:pt-16:last-child{
    padding-top:64px
  }

  .lg\:last\:pt-18:last-child{
    padding-top:72px
  }

  .lg\:last\:pt-24:last-child{
    padding-top:96px
  }

  .lg\:last\:pt-5dot5:last-child{
    padding-top:22px
  }

  .lg\:last\:pt-full:last-child{
    padding-top:100%
  }

  .lg\:last\:pr-0:last-child{
    padding-right:0
  }

  .lg\:last\:pr-1:last-child{
    padding-right:4px
  }

  .lg\:last\:pr-2:last-child{
    padding-right:8px
  }

  .lg\:last\:pr-3:last-child{
    padding-right:12px
  }

  .lg\:last\:pr-4:last-child{
    padding-right:16px
  }

  .lg\:last\:pr-5:last-child{
    padding-right:20px
  }

  .lg\:last\:pr-6:last-child{
    padding-right:24px
  }

  .lg\:last\:pr-8:last-child{
    padding-right:32px
  }

  .lg\:last\:pr-10:last-child{
    padding-right:40px
  }

  .lg\:last\:pr-12:last-child{
    padding-right:48px
  }

  .lg\:last\:pr-16:last-child{
    padding-right:64px
  }

  .lg\:last\:pr-18:last-child{
    padding-right:72px
  }

  .lg\:last\:pr-24:last-child{
    padding-right:96px
  }

  .lg\:last\:pr-5dot5:last-child{
    padding-right:22px
  }

  .lg\:last\:pr-full:last-child{
    padding-right:100%
  }

  .lg\:last\:pb-0:last-child{
    padding-bottom:0
  }

  .lg\:last\:pb-1:last-child{
    padding-bottom:4px
  }

  .lg\:last\:pb-2:last-child{
    padding-bottom:8px
  }

  .lg\:last\:pb-3:last-child{
    padding-bottom:12px
  }

  .lg\:last\:pb-4:last-child{
    padding-bottom:16px
  }

  .lg\:last\:pb-5:last-child{
    padding-bottom:20px
  }

  .lg\:last\:pb-6:last-child{
    padding-bottom:24px
  }

  .lg\:last\:pb-8:last-child{
    padding-bottom:32px
  }

  .lg\:last\:pb-10:last-child{
    padding-bottom:40px
  }

  .lg\:last\:pb-12:last-child{
    padding-bottom:48px
  }

  .lg\:last\:pb-16:last-child{
    padding-bottom:64px
  }

  .lg\:last\:pb-18:last-child{
    padding-bottom:72px
  }

  .lg\:last\:pb-24:last-child{
    padding-bottom:96px
  }

  .lg\:last\:pb-5dot5:last-child{
    padding-bottom:22px
  }

  .lg\:last\:pb-full:last-child{
    padding-bottom:100%
  }

  .lg\:last\:pl-0:last-child{
    padding-left:0
  }

  .lg\:last\:pl-1:last-child{
    padding-left:4px
  }

  .lg\:last\:pl-2:last-child{
    padding-left:8px
  }

  .lg\:last\:pl-3:last-child{
    padding-left:12px
  }

  .lg\:last\:pl-4:last-child{
    padding-left:16px
  }

  .lg\:last\:pl-5:last-child{
    padding-left:20px
  }

  .lg\:last\:pl-6:last-child{
    padding-left:24px
  }

  .lg\:last\:pl-8:last-child{
    padding-left:32px
  }

  .lg\:last\:pl-10:last-child{
    padding-left:40px
  }

  .lg\:last\:pl-12:last-child{
    padding-left:48px
  }

  .lg\:last\:pl-16:last-child{
    padding-left:64px
  }

  .lg\:last\:pl-18:last-child{
    padding-left:72px
  }

  .lg\:last\:pl-24:last-child{
    padding-left:96px
  }

  .lg\:last\:pl-5dot5:last-child{
    padding-left:22px
  }

  .lg\:last\:pl-full:last-child{
    padding-left:100%
  }

  .lg\:text-left{
    text-align:left
  }

  .lg\:text-center{
    text-align:center
  }

  .lg\:text-right{
    text-align:right
  }

  .lg\:text-justify{
    text-align:justify
  }

  .lg\:align-baseline{
    vertical-align:baseline
  }

  .lg\:align-top{
    vertical-align:top
  }

  .lg\:align-middle{
    vertical-align:middle
  }

  .lg\:align-bottom{
    vertical-align:bottom
  }

  .lg\:align-text-top{
    vertical-align:text-top
  }

  .lg\:align-text-bottom{
    vertical-align:text-bottom
  }

  .lg\:text-xs{
    font-size:10px
  }

  .lg\:text-s{
    font-size:12px
  }

  .lg\:text-sm{
    font-size:14px
  }

  .lg\:text-tiny{
    font-size:12px
  }

  .lg\:text-base{
    font-size:16px
  }

  .lg\:text-lg{
    font-size:20px
  }

  .lg\:text-xl{
    font-size:24px
  }

  .lg\:text-2xl{
    font-size:32px
  }

  .lg\:text-3xl{
    font-size:40px
  }

  .lg\:text-4xl{
    font-size:48px
  }

  .lg\:text-5xl{
    font-size:64px
  }

  .lg\:font-thin{
    font-weight:100
  }

  .lg\:font-extralight{
    font-weight:200
  }

  .lg\:font-light{
    font-weight:300
  }

  .lg\:font-normal{
    font-weight:400
  }

  .lg\:font-medium{
    font-weight:500
  }

  .lg\:font-semibold{
    font-weight:600
  }

  .lg\:font-bold{
    font-weight:700
  }

  .lg\:font-extrabold{
    font-weight:800
  }

  .lg\:font-black{
    font-weight:900
  }

  .lg\:uppercase{
    text-transform:uppercase
  }

  .lg\:lowercase{
    text-transform:lowercase
  }

  .lg\:capitalize{
    text-transform:capitalize
  }

  .lg\:normal-case{
    text-transform:none
  }

  .lg\:italic{
    font-style:italic
  }

  .lg\:not-italic{
    font-style:normal
  }

  .lg\:ordinal, .lg\:slashed-zero, .lg\:lining-nums, .lg\:oldstyle-nums, .lg\:proportional-nums, .lg\:tabular-nums, .lg\:diagonal-fractions, .lg\:stacked-fractions{
    --tw-ordinal:var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction:var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric:var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  .lg\:normal-nums{
    font-variant-numeric:normal
  }

  .lg\:ordinal{
    --tw-ordinal:ordinal
  }

  .lg\:slashed-zero{
    --tw-slashed-zero:slashed-zero
  }

  .lg\:lining-nums{
    --tw-numeric-figure:lining-nums
  }

  .lg\:oldstyle-nums{
    --tw-numeric-figure:oldstyle-nums
  }

  .lg\:proportional-nums{
    --tw-numeric-spacing:proportional-nums
  }

  .lg\:tabular-nums{
    --tw-numeric-spacing:tabular-nums
  }

  .lg\:diagonal-fractions{
    --tw-numeric-fraction:diagonal-fractions
  }

  .lg\:stacked-fractions{
    --tw-numeric-fraction:stacked-fractions
  }

  .lg\:leading-3{
    line-height:.75rem
  }

  .lg\:leading-4{
    line-height:1rem
  }

  .lg\:leading-5{
    line-height:1.25rem
  }

  .lg\:leading-6{
    line-height:1.5rem
  }

  .lg\:leading-7{
    line-height:1.75rem
  }

  .lg\:leading-8{
    line-height:2rem
  }

  .lg\:leading-9{
    line-height:2.25rem
  }

  .lg\:leading-10{
    line-height:2.5rem
  }

  .lg\:leading-none{
    line-height:1
  }

  .lg\:leading-tight{
    line-height:1.25
  }

  .lg\:leading-snug{
    line-height:1.375
  }

  .lg\:leading-normal{
    line-height:1.5
  }

  .lg\:leading-relaxed{
    line-height:1.625
  }

  .lg\:leading-loose{
    line-height:2
  }

  .lg\:text-red1{
    color:#CC352C
  }

  .lg\:text-red2{
    color:#FF554B
  }

  .lg\:text-red3{
    color:#FF7B73
  }

  .lg\:text-red4{
    color:#FFF1F1
  }

  .lg\:text-red5{
    color:#FFF6F6
  }

  .lg\:text-cyan1{
    color:#009897
  }

  .lg\:text-cyan2{
    color:#00BFBD
  }

  .lg\:text-cyan3{
    color:#4CD1D0
  }

  .lg\:text-cyan4{
    color:#B2ECEB
  }

  .lg\:text-cyan5{
    color:#EDFAFA
  }

  .lg\:text-bg1{
    color:#FAFAFC
  }

  .lg\:text-bg2{
    color:#ffffff
  }

  .lg\:text-bg3{
    color:#F7F7FA
  }

  .lg\:text-gray1{
    color:#333333
  }

  .lg\:text-gray2{
    color:#4D4D4D
  }

  .lg\:text-gray3{
    color:#8C8C8C
  }

  .lg\:text-gray4{
    color:#BFBFBF
  }

  .lg\:text-gray5{
    color:#D9D9D9
  }

  .lg\:text-gray6{
    color:#E9E9EB
  }

  .lg\:text-gray7{
    color:#515164
  }

  .lg\:text-white{
    color:#ffffff
  }

  .lg\:text-warning{
    color:#FFC319
  }

  .lg\:text-error{
    color:#EA696F
  }

  .lg\:text-info{
    color:#0071B9
  }

  .lg\:text-success{
    color:#98D45F
  }

  .lg\:text-special4{
    color:#59779A
  }

  .lg\:text-dark{
    color:#000000
  }

  .lg\:text-transparent{
    color:transparent
  }

  .lg\:text-secondary1{
    color:#313140
  }

  .lg\:text-secondary2{
    color:#515164
  }

  .lg\:text-secondary3{
    color:#9C9CAC
  }

  .lg\:text-secondary4{
    color:#E5E8ED
  }

  .lg\:text-secondary5{
    color:#F5F6F9
  }

  .lg\:text-green1{
    color:#0DE298
  }

  .group:hover .lg\:group-hover\:text-red1{
    color:#CC352C
  }

  .group:hover .lg\:group-hover\:text-red2{
    color:#FF554B
  }

  .group:hover .lg\:group-hover\:text-red3{
    color:#FF7B73
  }

  .group:hover .lg\:group-hover\:text-red4{
    color:#FFF1F1
  }

  .group:hover .lg\:group-hover\:text-red5{
    color:#FFF6F6
  }

  .group:hover .lg\:group-hover\:text-cyan1{
    color:#009897
  }

  .group:hover .lg\:group-hover\:text-cyan2{
    color:#00BFBD
  }

  .group:hover .lg\:group-hover\:text-cyan3{
    color:#4CD1D0
  }

  .group:hover .lg\:group-hover\:text-cyan4{
    color:#B2ECEB
  }

  .group:hover .lg\:group-hover\:text-cyan5{
    color:#EDFAFA
  }

  .group:hover .lg\:group-hover\:text-bg1{
    color:#FAFAFC
  }

  .group:hover .lg\:group-hover\:text-bg2{
    color:#ffffff
  }

  .group:hover .lg\:group-hover\:text-bg3{
    color:#F7F7FA
  }

  .group:hover .lg\:group-hover\:text-gray1{
    color:#333333
  }

  .group:hover .lg\:group-hover\:text-gray2{
    color:#4D4D4D
  }

  .group:hover .lg\:group-hover\:text-gray3{
    color:#8C8C8C
  }

  .group:hover .lg\:group-hover\:text-gray4{
    color:#BFBFBF
  }

  .group:hover .lg\:group-hover\:text-gray5{
    color:#D9D9D9
  }

  .group:hover .lg\:group-hover\:text-gray6{
    color:#E9E9EB
  }

  .group:hover .lg\:group-hover\:text-gray7{
    color:#515164
  }

  .group:hover .lg\:group-hover\:text-white{
    color:#ffffff
  }

  .group:hover .lg\:group-hover\:text-warning{
    color:#FFC319
  }

  .group:hover .lg\:group-hover\:text-error{
    color:#EA696F
  }

  .group:hover .lg\:group-hover\:text-info{
    color:#0071B9
  }

  .group:hover .lg\:group-hover\:text-success{
    color:#98D45F
  }

  .group:hover .lg\:group-hover\:text-special4{
    color:#59779A
  }

  .group:hover .lg\:group-hover\:text-dark{
    color:#000000
  }

  .group:hover .lg\:group-hover\:text-transparent{
    color:transparent
  }

  .group:hover .lg\:group-hover\:text-secondary1{
    color:#313140
  }

  .group:hover .lg\:group-hover\:text-secondary2{
    color:#515164
  }

  .group:hover .lg\:group-hover\:text-secondary3{
    color:#9C9CAC
  }

  .group:hover .lg\:group-hover\:text-secondary4{
    color:#E5E8ED
  }

  .group:hover .lg\:group-hover\:text-secondary5{
    color:#F5F6F9
  }

  .group:hover .lg\:group-hover\:text-green1{
    color:#0DE298
  }

  .lg\:focus-within\:text-red1:focus-within{
    color:#CC352C
  }

  .lg\:focus-within\:text-red2:focus-within{
    color:#FF554B
  }

  .lg\:focus-within\:text-red3:focus-within{
    color:#FF7B73
  }

  .lg\:focus-within\:text-red4:focus-within{
    color:#FFF1F1
  }

  .lg\:focus-within\:text-red5:focus-within{
    color:#FFF6F6
  }

  .lg\:focus-within\:text-cyan1:focus-within{
    color:#009897
  }

  .lg\:focus-within\:text-cyan2:focus-within{
    color:#00BFBD
  }

  .lg\:focus-within\:text-cyan3:focus-within{
    color:#4CD1D0
  }

  .lg\:focus-within\:text-cyan4:focus-within{
    color:#B2ECEB
  }

  .lg\:focus-within\:text-cyan5:focus-within{
    color:#EDFAFA
  }

  .lg\:focus-within\:text-bg1:focus-within{
    color:#FAFAFC
  }

  .lg\:focus-within\:text-bg2:focus-within{
    color:#ffffff
  }

  .lg\:focus-within\:text-bg3:focus-within{
    color:#F7F7FA
  }

  .lg\:focus-within\:text-gray1:focus-within{
    color:#333333
  }

  .lg\:focus-within\:text-gray2:focus-within{
    color:#4D4D4D
  }

  .lg\:focus-within\:text-gray3:focus-within{
    color:#8C8C8C
  }

  .lg\:focus-within\:text-gray4:focus-within{
    color:#BFBFBF
  }

  .lg\:focus-within\:text-gray5:focus-within{
    color:#D9D9D9
  }

  .lg\:focus-within\:text-gray6:focus-within{
    color:#E9E9EB
  }

  .lg\:focus-within\:text-gray7:focus-within{
    color:#515164
  }

  .lg\:focus-within\:text-white:focus-within{
    color:#ffffff
  }

  .lg\:focus-within\:text-warning:focus-within{
    color:#FFC319
  }

  .lg\:focus-within\:text-error:focus-within{
    color:#EA696F
  }

  .lg\:focus-within\:text-info:focus-within{
    color:#0071B9
  }

  .lg\:focus-within\:text-success:focus-within{
    color:#98D45F
  }

  .lg\:focus-within\:text-special4:focus-within{
    color:#59779A
  }

  .lg\:focus-within\:text-dark:focus-within{
    color:#000000
  }

  .lg\:focus-within\:text-transparent:focus-within{
    color:transparent
  }

  .lg\:focus-within\:text-secondary1:focus-within{
    color:#313140
  }

  .lg\:focus-within\:text-secondary2:focus-within{
    color:#515164
  }

  .lg\:focus-within\:text-secondary3:focus-within{
    color:#9C9CAC
  }

  .lg\:focus-within\:text-secondary4:focus-within{
    color:#E5E8ED
  }

  .lg\:focus-within\:text-secondary5:focus-within{
    color:#F5F6F9
  }

  .lg\:focus-within\:text-green1:focus-within{
    color:#0DE298
  }

  .lg\:hover\:text-red1:hover{
    color:#CC352C
  }

  .lg\:hover\:text-red2:hover{
    color:#FF554B
  }

  .lg\:hover\:text-red3:hover{
    color:#FF7B73
  }

  .lg\:hover\:text-red4:hover{
    color:#FFF1F1
  }

  .lg\:hover\:text-red5:hover{
    color:#FFF6F6
  }

  .lg\:hover\:text-cyan1:hover{
    color:#009897
  }

  .lg\:hover\:text-cyan2:hover{
    color:#00BFBD
  }

  .lg\:hover\:text-cyan3:hover{
    color:#4CD1D0
  }

  .lg\:hover\:text-cyan4:hover{
    color:#B2ECEB
  }

  .lg\:hover\:text-cyan5:hover{
    color:#EDFAFA
  }

  .lg\:hover\:text-bg1:hover{
    color:#FAFAFC
  }

  .lg\:hover\:text-bg2:hover{
    color:#ffffff
  }

  .lg\:hover\:text-bg3:hover{
    color:#F7F7FA
  }

  .lg\:hover\:text-gray1:hover{
    color:#333333
  }

  .lg\:hover\:text-gray2:hover{
    color:#4D4D4D
  }

  .lg\:hover\:text-gray3:hover{
    color:#8C8C8C
  }

  .lg\:hover\:text-gray4:hover{
    color:#BFBFBF
  }

  .lg\:hover\:text-gray5:hover{
    color:#D9D9D9
  }

  .lg\:hover\:text-gray6:hover{
    color:#E9E9EB
  }

  .lg\:hover\:text-gray7:hover{
    color:#515164
  }

  .lg\:hover\:text-white:hover{
    color:#ffffff
  }

  .lg\:hover\:text-warning:hover{
    color:#FFC319
  }

  .lg\:hover\:text-error:hover{
    color:#EA696F
  }

  .lg\:hover\:text-info:hover{
    color:#0071B9
  }

  .lg\:hover\:text-success:hover{
    color:#98D45F
  }

  .lg\:hover\:text-special4:hover{
    color:#59779A
  }

  .lg\:hover\:text-dark:hover{
    color:#000000
  }

  .lg\:hover\:text-transparent:hover{
    color:transparent
  }

  .lg\:hover\:text-secondary1:hover{
    color:#313140
  }

  .lg\:hover\:text-secondary2:hover{
    color:#515164
  }

  .lg\:hover\:text-secondary3:hover{
    color:#9C9CAC
  }

  .lg\:hover\:text-secondary4:hover{
    color:#E5E8ED
  }

  .lg\:hover\:text-secondary5:hover{
    color:#F5F6F9
  }

  .lg\:hover\:text-green1:hover{
    color:#0DE298
  }

  .lg\:focus\:text-red1:focus{
    color:#CC352C
  }

  .lg\:focus\:text-red2:focus{
    color:#FF554B
  }

  .lg\:focus\:text-red3:focus{
    color:#FF7B73
  }

  .lg\:focus\:text-red4:focus{
    color:#FFF1F1
  }

  .lg\:focus\:text-red5:focus{
    color:#FFF6F6
  }

  .lg\:focus\:text-cyan1:focus{
    color:#009897
  }

  .lg\:focus\:text-cyan2:focus{
    color:#00BFBD
  }

  .lg\:focus\:text-cyan3:focus{
    color:#4CD1D0
  }

  .lg\:focus\:text-cyan4:focus{
    color:#B2ECEB
  }

  .lg\:focus\:text-cyan5:focus{
    color:#EDFAFA
  }

  .lg\:focus\:text-bg1:focus{
    color:#FAFAFC
  }

  .lg\:focus\:text-bg2:focus{
    color:#ffffff
  }

  .lg\:focus\:text-bg3:focus{
    color:#F7F7FA
  }

  .lg\:focus\:text-gray1:focus{
    color:#333333
  }

  .lg\:focus\:text-gray2:focus{
    color:#4D4D4D
  }

  .lg\:focus\:text-gray3:focus{
    color:#8C8C8C
  }

  .lg\:focus\:text-gray4:focus{
    color:#BFBFBF
  }

  .lg\:focus\:text-gray5:focus{
    color:#D9D9D9
  }

  .lg\:focus\:text-gray6:focus{
    color:#E9E9EB
  }

  .lg\:focus\:text-gray7:focus{
    color:#515164
  }

  .lg\:focus\:text-white:focus{
    color:#ffffff
  }

  .lg\:focus\:text-warning:focus{
    color:#FFC319
  }

  .lg\:focus\:text-error:focus{
    color:#EA696F
  }

  .lg\:focus\:text-info:focus{
    color:#0071B9
  }

  .lg\:focus\:text-success:focus{
    color:#98D45F
  }

  .lg\:focus\:text-special4:focus{
    color:#59779A
  }

  .lg\:focus\:text-dark:focus{
    color:#000000
  }

  .lg\:focus\:text-transparent:focus{
    color:transparent
  }

  .lg\:focus\:text-secondary1:focus{
    color:#313140
  }

  .lg\:focus\:text-secondary2:focus{
    color:#515164
  }

  .lg\:focus\:text-secondary3:focus{
    color:#9C9CAC
  }

  .lg\:focus\:text-secondary4:focus{
    color:#E5E8ED
  }

  .lg\:focus\:text-secondary5:focus{
    color:#F5F6F9
  }

  .lg\:focus\:text-green1:focus{
    color:#0DE298
  }

  .lg\:underline{
    text-decoration:underline
  }

  .lg\:line-through{
    text-decoration:line-through
  }

  .lg\:no-underline{
    text-decoration:none
  }

  .group:hover .lg\:group-hover\:underline{
    text-decoration:underline
  }

  .group:hover .lg\:group-hover\:line-through{
    text-decoration:line-through
  }

  .group:hover .lg\:group-hover\:no-underline{
    text-decoration:none
  }

  .lg\:focus-within\:underline:focus-within{
    text-decoration:underline
  }

  .lg\:focus-within\:line-through:focus-within{
    text-decoration:line-through
  }

  .lg\:focus-within\:no-underline:focus-within{
    text-decoration:none
  }

  .lg\:hover\:underline:hover{
    text-decoration:underline
  }

  .lg\:hover\:line-through:hover{
    text-decoration:line-through
  }

  .lg\:hover\:no-underline:hover{
    text-decoration:none
  }

  .lg\:focus\:underline:focus{
    text-decoration:underline
  }

  .lg\:focus\:line-through:focus{
    text-decoration:line-through
  }

  .lg\:focus\:no-underline:focus{
    text-decoration:none
  }

  .lg\:placeholder-red1::placeholder{
    color:#CC352C
  }

  .lg\:placeholder-red2::placeholder{
    color:#FF554B
  }

  .lg\:placeholder-red3::placeholder{
    color:#FF7B73
  }

  .lg\:placeholder-red4::placeholder{
    color:#FFF1F1
  }

  .lg\:placeholder-red5::placeholder{
    color:#FFF6F6
  }

  .lg\:placeholder-cyan1::placeholder{
    color:#009897
  }

  .lg\:placeholder-cyan2::placeholder{
    color:#00BFBD
  }

  .lg\:placeholder-cyan3::placeholder{
    color:#4CD1D0
  }

  .lg\:placeholder-cyan4::placeholder{
    color:#B2ECEB
  }

  .lg\:placeholder-cyan5::placeholder{
    color:#EDFAFA
  }

  .lg\:placeholder-bg1::placeholder{
    color:#FAFAFC
  }

  .lg\:placeholder-bg2::placeholder{
    color:#ffffff
  }

  .lg\:placeholder-bg3::placeholder{
    color:#F7F7FA
  }

  .lg\:placeholder-gray1::placeholder{
    color:#333333
  }

  .lg\:placeholder-gray2::placeholder{
    color:#4D4D4D
  }

  .lg\:placeholder-gray3::placeholder{
    color:#8C8C8C
  }

  .lg\:placeholder-gray4::placeholder{
    color:#BFBFBF
  }

  .lg\:placeholder-gray5::placeholder{
    color:#D9D9D9
  }

  .lg\:placeholder-gray6::placeholder{
    color:#E9E9EB
  }

  .lg\:placeholder-gray7::placeholder{
    color:#515164
  }

  .lg\:placeholder-white::placeholder{
    color:#ffffff
  }

  .lg\:placeholder-warning::placeholder{
    color:#FFC319
  }

  .lg\:placeholder-error::placeholder{
    color:#EA696F
  }

  .lg\:placeholder-info::placeholder{
    color:#0071B9
  }

  .lg\:placeholder-success::placeholder{
    color:#98D45F
  }

  .lg\:placeholder-special4::placeholder{
    color:#59779A
  }

  .lg\:placeholder-dark::placeholder{
    color:#000000
  }

  .lg\:placeholder-transparent::placeholder{
    color:transparent
  }

  .lg\:placeholder-secondary1::placeholder{
    color:#313140
  }

  .lg\:placeholder-secondary2::placeholder{
    color:#515164
  }

  .lg\:placeholder-secondary3::placeholder{
    color:#9C9CAC
  }

  .lg\:placeholder-secondary4::placeholder{
    color:#E5E8ED
  }

  .lg\:placeholder-secondary5::placeholder{
    color:#F5F6F9
  }

  .lg\:placeholder-green1::placeholder{
    color:#0DE298
  }

  .lg\:focus\:placeholder-red1:focus::placeholder{
    color:#CC352C
  }

  .lg\:focus\:placeholder-red2:focus::placeholder{
    color:#FF554B
  }

  .lg\:focus\:placeholder-red3:focus::placeholder{
    color:#FF7B73
  }

  .lg\:focus\:placeholder-red4:focus::placeholder{
    color:#FFF1F1
  }

  .lg\:focus\:placeholder-red5:focus::placeholder{
    color:#FFF6F6
  }

  .lg\:focus\:placeholder-cyan1:focus::placeholder{
    color:#009897
  }

  .lg\:focus\:placeholder-cyan2:focus::placeholder{
    color:#00BFBD
  }

  .lg\:focus\:placeholder-cyan3:focus::placeholder{
    color:#4CD1D0
  }

  .lg\:focus\:placeholder-cyan4:focus::placeholder{
    color:#B2ECEB
  }

  .lg\:focus\:placeholder-cyan5:focus::placeholder{
    color:#EDFAFA
  }

  .lg\:focus\:placeholder-bg1:focus::placeholder{
    color:#FAFAFC
  }

  .lg\:focus\:placeholder-bg2:focus::placeholder{
    color:#ffffff
  }

  .lg\:focus\:placeholder-bg3:focus::placeholder{
    color:#F7F7FA
  }

  .lg\:focus\:placeholder-gray1:focus::placeholder{
    color:#333333
  }

  .lg\:focus\:placeholder-gray2:focus::placeholder{
    color:#4D4D4D
  }

  .lg\:focus\:placeholder-gray3:focus::placeholder{
    color:#8C8C8C
  }

  .lg\:focus\:placeholder-gray4:focus::placeholder{
    color:#BFBFBF
  }

  .lg\:focus\:placeholder-gray5:focus::placeholder{
    color:#D9D9D9
  }

  .lg\:focus\:placeholder-gray6:focus::placeholder{
    color:#E9E9EB
  }

  .lg\:focus\:placeholder-gray7:focus::placeholder{
    color:#515164
  }

  .lg\:focus\:placeholder-white:focus::placeholder{
    color:#ffffff
  }

  .lg\:focus\:placeholder-warning:focus::placeholder{
    color:#FFC319
  }

  .lg\:focus\:placeholder-error:focus::placeholder{
    color:#EA696F
  }

  .lg\:focus\:placeholder-info:focus::placeholder{
    color:#0071B9
  }

  .lg\:focus\:placeholder-success:focus::placeholder{
    color:#98D45F
  }

  .lg\:focus\:placeholder-special4:focus::placeholder{
    color:#59779A
  }

  .lg\:focus\:placeholder-dark:focus::placeholder{
    color:#000000
  }

  .lg\:focus\:placeholder-transparent:focus::placeholder{
    color:transparent
  }

  .lg\:focus\:placeholder-secondary1:focus::placeholder{
    color:#313140
  }

  .lg\:focus\:placeholder-secondary2:focus::placeholder{
    color:#515164
  }

  .lg\:focus\:placeholder-secondary3:focus::placeholder{
    color:#9C9CAC
  }

  .lg\:focus\:placeholder-secondary4:focus::placeholder{
    color:#E5E8ED
  }

  .lg\:focus\:placeholder-secondary5:focus::placeholder{
    color:#F5F6F9
  }

  .lg\:focus\:placeholder-green1:focus::placeholder{
    color:#0DE298
  }

  .lg\:opacity-0{
    opacity:0
  }

  .lg\:opacity-5{
    opacity:0.05
  }

  .lg\:opacity-10{
    opacity:0.1
  }

  .lg\:opacity-20{
    opacity:0.2
  }

  .lg\:opacity-25{
    opacity:0.25
  }

  .lg\:opacity-30{
    opacity:0.3
  }

  .lg\:opacity-40{
    opacity:0.4
  }

  .lg\:opacity-50{
    opacity:0.5
  }

  .lg\:opacity-60{
    opacity:0.6
  }

  .lg\:opacity-70{
    opacity:0.7
  }

  .lg\:opacity-75{
    opacity:0.75
  }

  .lg\:opacity-80{
    opacity:0.8
  }

  .lg\:opacity-90{
    opacity:0.9
  }

  .lg\:opacity-95{
    opacity:0.95
  }

  .lg\:opacity-100{
    opacity:1
  }

  .group:hover .lg\:group-hover\:opacity-0{
    opacity:0
  }

  .group:hover .lg\:group-hover\:opacity-5{
    opacity:0.05
  }

  .group:hover .lg\:group-hover\:opacity-10{
    opacity:0.1
  }

  .group:hover .lg\:group-hover\:opacity-20{
    opacity:0.2
  }

  .group:hover .lg\:group-hover\:opacity-25{
    opacity:0.25
  }

  .group:hover .lg\:group-hover\:opacity-30{
    opacity:0.3
  }

  .group:hover .lg\:group-hover\:opacity-40{
    opacity:0.4
  }

  .group:hover .lg\:group-hover\:opacity-50{
    opacity:0.5
  }

  .group:hover .lg\:group-hover\:opacity-60{
    opacity:0.6
  }

  .group:hover .lg\:group-hover\:opacity-70{
    opacity:0.7
  }

  .group:hover .lg\:group-hover\:opacity-75{
    opacity:0.75
  }

  .group:hover .lg\:group-hover\:opacity-80{
    opacity:0.8
  }

  .group:hover .lg\:group-hover\:opacity-90{
    opacity:0.9
  }

  .group:hover .lg\:group-hover\:opacity-95{
    opacity:0.95
  }

  .group:hover .lg\:group-hover\:opacity-100{
    opacity:1
  }

  .lg\:focus-within\:opacity-0:focus-within{
    opacity:0
  }

  .lg\:focus-within\:opacity-5:focus-within{
    opacity:0.05
  }

  .lg\:focus-within\:opacity-10:focus-within{
    opacity:0.1
  }

  .lg\:focus-within\:opacity-20:focus-within{
    opacity:0.2
  }

  .lg\:focus-within\:opacity-25:focus-within{
    opacity:0.25
  }

  .lg\:focus-within\:opacity-30:focus-within{
    opacity:0.3
  }

  .lg\:focus-within\:opacity-40:focus-within{
    opacity:0.4
  }

  .lg\:focus-within\:opacity-50:focus-within{
    opacity:0.5
  }

  .lg\:focus-within\:opacity-60:focus-within{
    opacity:0.6
  }

  .lg\:focus-within\:opacity-70:focus-within{
    opacity:0.7
  }

  .lg\:focus-within\:opacity-75:focus-within{
    opacity:0.75
  }

  .lg\:focus-within\:opacity-80:focus-within{
    opacity:0.8
  }

  .lg\:focus-within\:opacity-90:focus-within{
    opacity:0.9
  }

  .lg\:focus-within\:opacity-95:focus-within{
    opacity:0.95
  }

  .lg\:focus-within\:opacity-100:focus-within{
    opacity:1
  }

  .lg\:hover\:opacity-0:hover{
    opacity:0
  }

  .lg\:hover\:opacity-5:hover{
    opacity:0.05
  }

  .lg\:hover\:opacity-10:hover{
    opacity:0.1
  }

  .lg\:hover\:opacity-20:hover{
    opacity:0.2
  }

  .lg\:hover\:opacity-25:hover{
    opacity:0.25
  }

  .lg\:hover\:opacity-30:hover{
    opacity:0.3
  }

  .lg\:hover\:opacity-40:hover{
    opacity:0.4
  }

  .lg\:hover\:opacity-50:hover{
    opacity:0.5
  }

  .lg\:hover\:opacity-60:hover{
    opacity:0.6
  }

  .lg\:hover\:opacity-70:hover{
    opacity:0.7
  }

  .lg\:hover\:opacity-75:hover{
    opacity:0.75
  }

  .lg\:hover\:opacity-80:hover{
    opacity:0.8
  }

  .lg\:hover\:opacity-90:hover{
    opacity:0.9
  }

  .lg\:hover\:opacity-95:hover{
    opacity:0.95
  }

  .lg\:hover\:opacity-100:hover{
    opacity:1
  }

  .lg\:focus\:opacity-0:focus{
    opacity:0
  }

  .lg\:focus\:opacity-5:focus{
    opacity:0.05
  }

  .lg\:focus\:opacity-10:focus{
    opacity:0.1
  }

  .lg\:focus\:opacity-20:focus{
    opacity:0.2
  }

  .lg\:focus\:opacity-25:focus{
    opacity:0.25
  }

  .lg\:focus\:opacity-30:focus{
    opacity:0.3
  }

  .lg\:focus\:opacity-40:focus{
    opacity:0.4
  }

  .lg\:focus\:opacity-50:focus{
    opacity:0.5
  }

  .lg\:focus\:opacity-60:focus{
    opacity:0.6
  }

  .lg\:focus\:opacity-70:focus{
    opacity:0.7
  }

  .lg\:focus\:opacity-75:focus{
    opacity:0.75
  }

  .lg\:focus\:opacity-80:focus{
    opacity:0.8
  }

  .lg\:focus\:opacity-90:focus{
    opacity:0.9
  }

  .lg\:focus\:opacity-95:focus{
    opacity:0.95
  }

  .lg\:focus\:opacity-100:focus{
    opacity:1
  }

  .lg\:bg-blend-normal{
    background-blend-mode:normal
  }

  .lg\:bg-blend-multiply{
    background-blend-mode:multiply
  }

  .lg\:bg-blend-screen{
    background-blend-mode:screen
  }

  .lg\:bg-blend-overlay{
    background-blend-mode:overlay
  }

  .lg\:bg-blend-darken{
    background-blend-mode:darken
  }

  .lg\:bg-blend-lighten{
    background-blend-mode:lighten
  }

  .lg\:bg-blend-color-dodge{
    background-blend-mode:color-dodge
  }

  .lg\:bg-blend-color-burn{
    background-blend-mode:color-burn
  }

  .lg\:bg-blend-hard-light{
    background-blend-mode:hard-light
  }

  .lg\:bg-blend-soft-light{
    background-blend-mode:soft-light
  }

  .lg\:bg-blend-difference{
    background-blend-mode:difference
  }

  .lg\:bg-blend-exclusion{
    background-blend-mode:exclusion
  }

  .lg\:bg-blend-hue{
    background-blend-mode:hue
  }

  .lg\:bg-blend-saturation{
    background-blend-mode:saturation
  }

  .lg\:bg-blend-color{
    background-blend-mode:color
  }

  .lg\:bg-blend-luminosity{
    background-blend-mode:luminosity
  }

  .lg\:mix-blend-normal{
    mix-blend-mode:normal
  }

  .lg\:mix-blend-multiply{
    mix-blend-mode:multiply
  }

  .lg\:mix-blend-screen{
    mix-blend-mode:screen
  }

  .lg\:mix-blend-overlay{
    mix-blend-mode:overlay
  }

  .lg\:mix-blend-darken{
    mix-blend-mode:darken
  }

  .lg\:mix-blend-lighten{
    mix-blend-mode:lighten
  }

  .lg\:mix-blend-color-dodge{
    mix-blend-mode:color-dodge
  }

  .lg\:mix-blend-color-burn{
    mix-blend-mode:color-burn
  }

  .lg\:mix-blend-hard-light{
    mix-blend-mode:hard-light
  }

  .lg\:mix-blend-soft-light{
    mix-blend-mode:soft-light
  }

  .lg\:mix-blend-difference{
    mix-blend-mode:difference
  }

  .lg\:mix-blend-exclusion{
    mix-blend-mode:exclusion
  }

  .lg\:mix-blend-hue{
    mix-blend-mode:hue
  }

  .lg\:mix-blend-saturation{
    mix-blend-mode:saturation
  }

  .lg\:mix-blend-color{
    mix-blend-mode:color
  }

  .lg\:mix-blend-luminosity{
    mix-blend-mode:luminosity
  }

  .lg\:shadow-panel{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:shadow-popup{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-panel{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .lg\:group-hover\:shadow-popup{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-panel:focus-within{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-hover:focus-within{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus-within\:shadow-popup:focus-within{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-panel:hover{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-hover:hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:hover\:shadow-popup:hover{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-panel:focus{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-hover:focus{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:focus\:shadow-popup:focus{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .lg\:outline-none{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .lg\:outline-white{
    outline:2px dotted white;
    outline-offset:2px
  }

  .lg\:outline-black{
    outline:2px dotted black;
    outline-offset:2px
  }

  .lg\:focus-within\:outline-none:focus-within{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .lg\:focus-within\:outline-white:focus-within{
    outline:2px dotted white;
    outline-offset:2px
  }

  .lg\:focus-within\:outline-black:focus-within{
    outline:2px dotted black;
    outline-offset:2px
  }

  .lg\:focus\:outline-none:focus{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .lg\:focus\:outline-white:focus{
    outline:2px dotted white;
    outline-offset:2px
  }

  .lg\:focus\:outline-black:focus{
    outline:2px dotted black;
    outline-offset:2px
  }

  .lg\:ring-0{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring-1{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring-2{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring-4{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring-8{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring-0:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring-1:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring-4:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring-8:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus-within\:ring:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring-0:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring-1:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring-2:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring-4:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring-8:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:focus\:ring:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .lg\:ring-inset{
    --tw-ring-inset:inset
  }

  .lg\:focus-within\:ring-inset:focus-within{
    --tw-ring-inset:inset
  }

  .lg\:focus\:ring-inset:focus{
    --tw-ring-inset:inset
  }

  .lg\:ring-red1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .lg\:ring-red2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .lg\:ring-red3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .lg\:ring-red4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .lg\:ring-red5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .lg\:ring-cyan5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .lg\:ring-bg1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .lg\:ring-bg2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-bg3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .lg\:ring-gray1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .lg\:ring-gray2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .lg\:ring-gray3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .lg\:ring-gray4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .lg\:ring-gray5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .lg\:ring-gray6{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .lg\:ring-gray7{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .lg\:ring-white{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:ring-warning{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .lg\:ring-error{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .lg\:ring-info{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .lg\:ring-success{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .lg\:ring-special4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .lg\:ring-dark{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .lg\:ring-transparent{
    --tw-ring-color:transparent
  }

  .lg\:ring-secondary1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .lg\:ring-secondary2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .lg\:ring-secondary3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .lg\:ring-secondary4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .lg\:ring-secondary5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .lg\:ring-green1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-red5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-cyan5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-bg1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-bg2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-bg3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray6:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-gray7:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-white:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-warning:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-error:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-info:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-success:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-special4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-dark:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-transparent:focus-within{
    --tw-ring-color:transparent
  }

  .lg\:focus-within\:ring-secondary1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-secondary2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-secondary3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-secondary4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-secondary5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .lg\:focus-within\:ring-green1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-red5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-cyan5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-bg1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-bg2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-bg3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray6:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-gray7:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-white:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-warning:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-error:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-info:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-success:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-special4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-dark:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-transparent:focus{
    --tw-ring-color:transparent
  }

  .lg\:focus\:ring-secondary1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-secondary2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-secondary3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-secondary4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-secondary5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .lg\:focus\:ring-green1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .lg\:ring-opacity-0{
    --tw-ring-opacity:0
  }

  .lg\:ring-opacity-5{
    --tw-ring-opacity:0.05
  }

  .lg\:ring-opacity-10{
    --tw-ring-opacity:0.1
  }

  .lg\:ring-opacity-20{
    --tw-ring-opacity:0.2
  }

  .lg\:ring-opacity-25{
    --tw-ring-opacity:0.25
  }

  .lg\:ring-opacity-30{
    --tw-ring-opacity:0.3
  }

  .lg\:ring-opacity-40{
    --tw-ring-opacity:0.4
  }

  .lg\:ring-opacity-50{
    --tw-ring-opacity:0.5
  }

  .lg\:ring-opacity-60{
    --tw-ring-opacity:0.6
  }

  .lg\:ring-opacity-70{
    --tw-ring-opacity:0.7
  }

  .lg\:ring-opacity-75{
    --tw-ring-opacity:0.75
  }

  .lg\:ring-opacity-80{
    --tw-ring-opacity:0.8
  }

  .lg\:ring-opacity-90{
    --tw-ring-opacity:0.9
  }

  .lg\:ring-opacity-95{
    --tw-ring-opacity:0.95
  }

  .lg\:ring-opacity-100{
    --tw-ring-opacity:1
  }

  .lg\:focus-within\:ring-opacity-0:focus-within{
    --tw-ring-opacity:0
  }

  .lg\:focus-within\:ring-opacity-5:focus-within{
    --tw-ring-opacity:0.05
  }

  .lg\:focus-within\:ring-opacity-10:focus-within{
    --tw-ring-opacity:0.1
  }

  .lg\:focus-within\:ring-opacity-20:focus-within{
    --tw-ring-opacity:0.2
  }

  .lg\:focus-within\:ring-opacity-25:focus-within{
    --tw-ring-opacity:0.25
  }

  .lg\:focus-within\:ring-opacity-30:focus-within{
    --tw-ring-opacity:0.3
  }

  .lg\:focus-within\:ring-opacity-40:focus-within{
    --tw-ring-opacity:0.4
  }

  .lg\:focus-within\:ring-opacity-50:focus-within{
    --tw-ring-opacity:0.5
  }

  .lg\:focus-within\:ring-opacity-60:focus-within{
    --tw-ring-opacity:0.6
  }

  .lg\:focus-within\:ring-opacity-70:focus-within{
    --tw-ring-opacity:0.7
  }

  .lg\:focus-within\:ring-opacity-75:focus-within{
    --tw-ring-opacity:0.75
  }

  .lg\:focus-within\:ring-opacity-80:focus-within{
    --tw-ring-opacity:0.8
  }

  .lg\:focus-within\:ring-opacity-90:focus-within{
    --tw-ring-opacity:0.9
  }

  .lg\:focus-within\:ring-opacity-95:focus-within{
    --tw-ring-opacity:0.95
  }

  .lg\:focus-within\:ring-opacity-100:focus-within{
    --tw-ring-opacity:1
  }

  .lg\:focus\:ring-opacity-0:focus{
    --tw-ring-opacity:0
  }

  .lg\:focus\:ring-opacity-5:focus{
    --tw-ring-opacity:0.05
  }

  .lg\:focus\:ring-opacity-10:focus{
    --tw-ring-opacity:0.1
  }

  .lg\:focus\:ring-opacity-20:focus{
    --tw-ring-opacity:0.2
  }

  .lg\:focus\:ring-opacity-25:focus{
    --tw-ring-opacity:0.25
  }

  .lg\:focus\:ring-opacity-30:focus{
    --tw-ring-opacity:0.3
  }

  .lg\:focus\:ring-opacity-40:focus{
    --tw-ring-opacity:0.4
  }

  .lg\:focus\:ring-opacity-50:focus{
    --tw-ring-opacity:0.5
  }

  .lg\:focus\:ring-opacity-60:focus{
    --tw-ring-opacity:0.6
  }

  .lg\:focus\:ring-opacity-70:focus{
    --tw-ring-opacity:0.7
  }

  .lg\:focus\:ring-opacity-75:focus{
    --tw-ring-opacity:0.75
  }

  .lg\:focus\:ring-opacity-80:focus{
    --tw-ring-opacity:0.8
  }

  .lg\:focus\:ring-opacity-90:focus{
    --tw-ring-opacity:0.9
  }

  .lg\:focus\:ring-opacity-95:focus{
    --tw-ring-opacity:0.95
  }

  .lg\:focus\:ring-opacity-100:focus{
    --tw-ring-opacity:1
  }

  .lg\:ring-offset-0{
    --tw-ring-offset-width:0px
  }

  .lg\:ring-offset-1{
    --tw-ring-offset-width:1px
  }

  .lg\:ring-offset-2{
    --tw-ring-offset-width:2px
  }

  .lg\:ring-offset-4{
    --tw-ring-offset-width:4px
  }

  .lg\:ring-offset-8{
    --tw-ring-offset-width:8px
  }

  .lg\:focus-within\:ring-offset-0:focus-within{
    --tw-ring-offset-width:0px
  }

  .lg\:focus-within\:ring-offset-1:focus-within{
    --tw-ring-offset-width:1px
  }

  .lg\:focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width:2px
  }

  .lg\:focus-within\:ring-offset-4:focus-within{
    --tw-ring-offset-width:4px
  }

  .lg\:focus-within\:ring-offset-8:focus-within{
    --tw-ring-offset-width:8px
  }

  .lg\:focus\:ring-offset-0:focus{
    --tw-ring-offset-width:0px
  }

  .lg\:focus\:ring-offset-1:focus{
    --tw-ring-offset-width:1px
  }

  .lg\:focus\:ring-offset-2:focus{
    --tw-ring-offset-width:2px
  }

  .lg\:focus\:ring-offset-4:focus{
    --tw-ring-offset-width:4px
  }

  .lg\:focus\:ring-offset-8:focus{
    --tw-ring-offset-width:8px
  }

  .lg\:ring-offset-red1{
    --tw-ring-offset-color:#CC352C
  }

  .lg\:ring-offset-red2{
    --tw-ring-offset-color:#FF554B
  }

  .lg\:ring-offset-red3{
    --tw-ring-offset-color:#FF7B73
  }

  .lg\:ring-offset-red4{
    --tw-ring-offset-color:#FFF1F1
  }

  .lg\:ring-offset-red5{
    --tw-ring-offset-color:#FFF6F6
  }

  .lg\:ring-offset-cyan1{
    --tw-ring-offset-color:#009897
  }

  .lg\:ring-offset-cyan2{
    --tw-ring-offset-color:#00BFBD
  }

  .lg\:ring-offset-cyan3{
    --tw-ring-offset-color:#4CD1D0
  }

  .lg\:ring-offset-cyan4{
    --tw-ring-offset-color:#B2ECEB
  }

  .lg\:ring-offset-cyan5{
    --tw-ring-offset-color:#EDFAFA
  }

  .lg\:ring-offset-bg1{
    --tw-ring-offset-color:#FAFAFC
  }

  .lg\:ring-offset-bg2{
    --tw-ring-offset-color:#ffffff
  }

  .lg\:ring-offset-bg3{
    --tw-ring-offset-color:#F7F7FA
  }

  .lg\:ring-offset-gray1{
    --tw-ring-offset-color:#333333
  }

  .lg\:ring-offset-gray2{
    --tw-ring-offset-color:#4D4D4D
  }

  .lg\:ring-offset-gray3{
    --tw-ring-offset-color:#8C8C8C
  }

  .lg\:ring-offset-gray4{
    --tw-ring-offset-color:#BFBFBF
  }

  .lg\:ring-offset-gray5{
    --tw-ring-offset-color:#D9D9D9
  }

  .lg\:ring-offset-gray6{
    --tw-ring-offset-color:#E9E9EB
  }

  .lg\:ring-offset-gray7{
    --tw-ring-offset-color:#515164
  }

  .lg\:ring-offset-white{
    --tw-ring-offset-color:#ffffff
  }

  .lg\:ring-offset-warning{
    --tw-ring-offset-color:#FFC319
  }

  .lg\:ring-offset-error{
    --tw-ring-offset-color:#EA696F
  }

  .lg\:ring-offset-info{
    --tw-ring-offset-color:#0071B9
  }

  .lg\:ring-offset-success{
    --tw-ring-offset-color:#98D45F
  }

  .lg\:ring-offset-special4{
    --tw-ring-offset-color:#59779A
  }

  .lg\:ring-offset-dark{
    --tw-ring-offset-color:#000000
  }

  .lg\:ring-offset-transparent{
    --tw-ring-offset-color:transparent
  }

  .lg\:ring-offset-secondary1{
    --tw-ring-offset-color:#313140
  }

  .lg\:ring-offset-secondary2{
    --tw-ring-offset-color:#515164
  }

  .lg\:ring-offset-secondary3{
    --tw-ring-offset-color:#9C9CAC
  }

  .lg\:ring-offset-secondary4{
    --tw-ring-offset-color:#E5E8ED
  }

  .lg\:ring-offset-secondary5{
    --tw-ring-offset-color:#F5F6F9
  }

  .lg\:ring-offset-green1{
    --tw-ring-offset-color:#0DE298
  }

  .lg\:focus-within\:ring-offset-red1:focus-within{
    --tw-ring-offset-color:#CC352C
  }

  .lg\:focus-within\:ring-offset-red2:focus-within{
    --tw-ring-offset-color:#FF554B
  }

  .lg\:focus-within\:ring-offset-red3:focus-within{
    --tw-ring-offset-color:#FF7B73
  }

  .lg\:focus-within\:ring-offset-red4:focus-within{
    --tw-ring-offset-color:#FFF1F1
  }

  .lg\:focus-within\:ring-offset-red5:focus-within{
    --tw-ring-offset-color:#FFF6F6
  }

  .lg\:focus-within\:ring-offset-cyan1:focus-within{
    --tw-ring-offset-color:#009897
  }

  .lg\:focus-within\:ring-offset-cyan2:focus-within{
    --tw-ring-offset-color:#00BFBD
  }

  .lg\:focus-within\:ring-offset-cyan3:focus-within{
    --tw-ring-offset-color:#4CD1D0
  }

  .lg\:focus-within\:ring-offset-cyan4:focus-within{
    --tw-ring-offset-color:#B2ECEB
  }

  .lg\:focus-within\:ring-offset-cyan5:focus-within{
    --tw-ring-offset-color:#EDFAFA
  }

  .lg\:focus-within\:ring-offset-bg1:focus-within{
    --tw-ring-offset-color:#FAFAFC
  }

  .lg\:focus-within\:ring-offset-bg2:focus-within{
    --tw-ring-offset-color:#ffffff
  }

  .lg\:focus-within\:ring-offset-bg3:focus-within{
    --tw-ring-offset-color:#F7F7FA
  }

  .lg\:focus-within\:ring-offset-gray1:focus-within{
    --tw-ring-offset-color:#333333
  }

  .lg\:focus-within\:ring-offset-gray2:focus-within{
    --tw-ring-offset-color:#4D4D4D
  }

  .lg\:focus-within\:ring-offset-gray3:focus-within{
    --tw-ring-offset-color:#8C8C8C
  }

  .lg\:focus-within\:ring-offset-gray4:focus-within{
    --tw-ring-offset-color:#BFBFBF
  }

  .lg\:focus-within\:ring-offset-gray5:focus-within{
    --tw-ring-offset-color:#D9D9D9
  }

  .lg\:focus-within\:ring-offset-gray6:focus-within{
    --tw-ring-offset-color:#E9E9EB
  }

  .lg\:focus-within\:ring-offset-gray7:focus-within{
    --tw-ring-offset-color:#515164
  }

  .lg\:focus-within\:ring-offset-white:focus-within{
    --tw-ring-offset-color:#ffffff
  }

  .lg\:focus-within\:ring-offset-warning:focus-within{
    --tw-ring-offset-color:#FFC319
  }

  .lg\:focus-within\:ring-offset-error:focus-within{
    --tw-ring-offset-color:#EA696F
  }

  .lg\:focus-within\:ring-offset-info:focus-within{
    --tw-ring-offset-color:#0071B9
  }

  .lg\:focus-within\:ring-offset-success:focus-within{
    --tw-ring-offset-color:#98D45F
  }

  .lg\:focus-within\:ring-offset-special4:focus-within{
    --tw-ring-offset-color:#59779A
  }

  .lg\:focus-within\:ring-offset-dark:focus-within{
    --tw-ring-offset-color:#000000
  }

  .lg\:focus-within\:ring-offset-transparent:focus-within{
    --tw-ring-offset-color:transparent
  }

  .lg\:focus-within\:ring-offset-secondary1:focus-within{
    --tw-ring-offset-color:#313140
  }

  .lg\:focus-within\:ring-offset-secondary2:focus-within{
    --tw-ring-offset-color:#515164
  }

  .lg\:focus-within\:ring-offset-secondary3:focus-within{
    --tw-ring-offset-color:#9C9CAC
  }

  .lg\:focus-within\:ring-offset-secondary4:focus-within{
    --tw-ring-offset-color:#E5E8ED
  }

  .lg\:focus-within\:ring-offset-secondary5:focus-within{
    --tw-ring-offset-color:#F5F6F9
  }

  .lg\:focus-within\:ring-offset-green1:focus-within{
    --tw-ring-offset-color:#0DE298
  }

  .lg\:focus\:ring-offset-red1:focus{
    --tw-ring-offset-color:#CC352C
  }

  .lg\:focus\:ring-offset-red2:focus{
    --tw-ring-offset-color:#FF554B
  }

  .lg\:focus\:ring-offset-red3:focus{
    --tw-ring-offset-color:#FF7B73
  }

  .lg\:focus\:ring-offset-red4:focus{
    --tw-ring-offset-color:#FFF1F1
  }

  .lg\:focus\:ring-offset-red5:focus{
    --tw-ring-offset-color:#FFF6F6
  }

  .lg\:focus\:ring-offset-cyan1:focus{
    --tw-ring-offset-color:#009897
  }

  .lg\:focus\:ring-offset-cyan2:focus{
    --tw-ring-offset-color:#00BFBD
  }

  .lg\:focus\:ring-offset-cyan3:focus{
    --tw-ring-offset-color:#4CD1D0
  }

  .lg\:focus\:ring-offset-cyan4:focus{
    --tw-ring-offset-color:#B2ECEB
  }

  .lg\:focus\:ring-offset-cyan5:focus{
    --tw-ring-offset-color:#EDFAFA
  }

  .lg\:focus\:ring-offset-bg1:focus{
    --tw-ring-offset-color:#FAFAFC
  }

  .lg\:focus\:ring-offset-bg2:focus{
    --tw-ring-offset-color:#ffffff
  }

  .lg\:focus\:ring-offset-bg3:focus{
    --tw-ring-offset-color:#F7F7FA
  }

  .lg\:focus\:ring-offset-gray1:focus{
    --tw-ring-offset-color:#333333
  }

  .lg\:focus\:ring-offset-gray2:focus{
    --tw-ring-offset-color:#4D4D4D
  }

  .lg\:focus\:ring-offset-gray3:focus{
    --tw-ring-offset-color:#8C8C8C
  }

  .lg\:focus\:ring-offset-gray4:focus{
    --tw-ring-offset-color:#BFBFBF
  }

  .lg\:focus\:ring-offset-gray5:focus{
    --tw-ring-offset-color:#D9D9D9
  }

  .lg\:focus\:ring-offset-gray6:focus{
    --tw-ring-offset-color:#E9E9EB
  }

  .lg\:focus\:ring-offset-gray7:focus{
    --tw-ring-offset-color:#515164
  }

  .lg\:focus\:ring-offset-white:focus{
    --tw-ring-offset-color:#ffffff
  }

  .lg\:focus\:ring-offset-warning:focus{
    --tw-ring-offset-color:#FFC319
  }

  .lg\:focus\:ring-offset-error:focus{
    --tw-ring-offset-color:#EA696F
  }

  .lg\:focus\:ring-offset-info:focus{
    --tw-ring-offset-color:#0071B9
  }

  .lg\:focus\:ring-offset-success:focus{
    --tw-ring-offset-color:#98D45F
  }

  .lg\:focus\:ring-offset-special4:focus{
    --tw-ring-offset-color:#59779A
  }

  .lg\:focus\:ring-offset-dark:focus{
    --tw-ring-offset-color:#000000
  }

  .lg\:focus\:ring-offset-transparent:focus{
    --tw-ring-offset-color:transparent
  }

  .lg\:focus\:ring-offset-secondary1:focus{
    --tw-ring-offset-color:#313140
  }

  .lg\:focus\:ring-offset-secondary2:focus{
    --tw-ring-offset-color:#515164
  }

  .lg\:focus\:ring-offset-secondary3:focus{
    --tw-ring-offset-color:#9C9CAC
  }

  .lg\:focus\:ring-offset-secondary4:focus{
    --tw-ring-offset-color:#E5E8ED
  }

  .lg\:focus\:ring-offset-secondary5:focus{
    --tw-ring-offset-color:#F5F6F9
  }

  .lg\:focus\:ring-offset-green1:focus{
    --tw-ring-offset-color:#0DE298
  }

  .lg\:filter{
    --tw-blur:var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness:var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast:var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale:var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
    --tw-invert:var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate:var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia:var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow:var(--tw-empty,/*!*/ /*!*/);
    filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }

  .lg\:filter-none{
    filter:none
  }

  .lg\:blur-0{
    --tw-blur:blur(0)
  }

  .lg\:blur-none{
    --tw-blur:blur(0)
  }

  .lg\:blur-sm{
    --tw-blur:blur(4px)
  }

  .lg\:blur{
    --tw-blur:blur(8px)
  }

  .lg\:blur-md{
    --tw-blur:blur(12px)
  }

  .lg\:blur-lg{
    --tw-blur:blur(16px)
  }

  .lg\:blur-xl{
    --tw-blur:blur(24px)
  }

  .lg\:blur-2xl{
    --tw-blur:blur(40px)
  }

  .lg\:blur-3xl{
    --tw-blur:blur(64px)
  }

  .lg\:brightness-0{
    --tw-brightness:brightness(0)
  }

  .lg\:brightness-50{
    --tw-brightness:brightness(.5)
  }

  .lg\:brightness-75{
    --tw-brightness:brightness(.75)
  }

  .lg\:brightness-90{
    --tw-brightness:brightness(.9)
  }

  .lg\:brightness-95{
    --tw-brightness:brightness(.95)
  }

  .lg\:brightness-100{
    --tw-brightness:brightness(1)
  }

  .lg\:brightness-105{
    --tw-brightness:brightness(1.05)
  }

  .lg\:brightness-110{
    --tw-brightness:brightness(1.1)
  }

  .lg\:brightness-125{
    --tw-brightness:brightness(1.25)
  }

  .lg\:brightness-150{
    --tw-brightness:brightness(1.5)
  }

  .lg\:brightness-200{
    --tw-brightness:brightness(2)
  }

  .lg\:contrast-0{
    --tw-contrast:contrast(0)
  }

  .lg\:contrast-50{
    --tw-contrast:contrast(.5)
  }

  .lg\:contrast-75{
    --tw-contrast:contrast(.75)
  }

  .lg\:contrast-100{
    --tw-contrast:contrast(1)
  }

  .lg\:contrast-125{
    --tw-contrast:contrast(1.25)
  }

  .lg\:contrast-150{
    --tw-contrast:contrast(1.5)
  }

  .lg\:contrast-200{
    --tw-contrast:contrast(2)
  }

  .lg\:drop-shadow-sm{
    --tw-drop-shadow:drop-shadow(0 1px 1px rgba(0,0,0,0.05))
  }

  .lg\:drop-shadow{
    --tw-drop-shadow:drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
  }

  .lg\:drop-shadow-md{
    --tw-drop-shadow:drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
  }

  .lg\:drop-shadow-lg{
    --tw-drop-shadow:drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
  }

  .lg\:drop-shadow-xl{
    --tw-drop-shadow:drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
  }

  .lg\:drop-shadow-2xl{
    --tw-drop-shadow:drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
  }

  .lg\:drop-shadow-none{
    --tw-drop-shadow:drop-shadow(0 0 #0000)
  }

  .lg\:grayscale-0{
    --tw-grayscale:grayscale(0)
  }

  .lg\:grayscale{
    --tw-grayscale:grayscale(100%)
  }

  .lg\:hue-rotate-0{
    --tw-hue-rotate:hue-rotate(0deg)
  }

  .lg\:hue-rotate-15{
    --tw-hue-rotate:hue-rotate(15deg)
  }

  .lg\:hue-rotate-30{
    --tw-hue-rotate:hue-rotate(30deg)
  }

  .lg\:hue-rotate-60{
    --tw-hue-rotate:hue-rotate(60deg)
  }

  .lg\:hue-rotate-90{
    --tw-hue-rotate:hue-rotate(90deg)
  }

  .lg\:hue-rotate-180{
    --tw-hue-rotate:hue-rotate(180deg)
  }

  .lg\:-hue-rotate-180{
    --tw-hue-rotate:hue-rotate(-180deg)
  }

  .lg\:-hue-rotate-90{
    --tw-hue-rotate:hue-rotate(-90deg)
  }

  .lg\:-hue-rotate-60{
    --tw-hue-rotate:hue-rotate(-60deg)
  }

  .lg\:-hue-rotate-30{
    --tw-hue-rotate:hue-rotate(-30deg)
  }

  .lg\:-hue-rotate-15{
    --tw-hue-rotate:hue-rotate(-15deg)
  }

  .lg\:invert-0{
    --tw-invert:invert(0)
  }

  .lg\:invert{
    --tw-invert:invert(100%)
  }

  .lg\:saturate-0{
    --tw-saturate:saturate(0)
  }

  .lg\:saturate-50{
    --tw-saturate:saturate(.5)
  }

  .lg\:saturate-100{
    --tw-saturate:saturate(1)
  }

  .lg\:saturate-150{
    --tw-saturate:saturate(1.5)
  }

  .lg\:saturate-200{
    --tw-saturate:saturate(2)
  }

  .lg\:sepia-0{
    --tw-sepia:sepia(0)
  }

  .lg\:sepia{
    --tw-sepia:sepia(100%)
  }

  .lg\:backdrop-filter{
    --tw-backdrop-blur:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia:var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  .lg\:backdrop-filter-none{
    -webkit-backdrop-filter:none;
            backdrop-filter:none
  }

  .lg\:backdrop-blur-0{
    --tw-backdrop-blur:blur(0)
  }

  .lg\:backdrop-blur-none{
    --tw-backdrop-blur:blur(0)
  }

  .lg\:backdrop-blur-sm{
    --tw-backdrop-blur:blur(4px)
  }

  .lg\:backdrop-blur{
    --tw-backdrop-blur:blur(8px)
  }

  .lg\:backdrop-blur-md{
    --tw-backdrop-blur:blur(12px)
  }

  .lg\:backdrop-blur-lg{
    --tw-backdrop-blur:blur(16px)
  }

  .lg\:backdrop-blur-xl{
    --tw-backdrop-blur:blur(24px)
  }

  .lg\:backdrop-blur-2xl{
    --tw-backdrop-blur:blur(40px)
  }

  .lg\:backdrop-blur-3xl{
    --tw-backdrop-blur:blur(64px)
  }

  .lg\:backdrop-brightness-0{
    --tw-backdrop-brightness:brightness(0)
  }

  .lg\:backdrop-brightness-50{
    --tw-backdrop-brightness:brightness(.5)
  }

  .lg\:backdrop-brightness-75{
    --tw-backdrop-brightness:brightness(.75)
  }

  .lg\:backdrop-brightness-90{
    --tw-backdrop-brightness:brightness(.9)
  }

  .lg\:backdrop-brightness-95{
    --tw-backdrop-brightness:brightness(.95)
  }

  .lg\:backdrop-brightness-100{
    --tw-backdrop-brightness:brightness(1)
  }

  .lg\:backdrop-brightness-105{
    --tw-backdrop-brightness:brightness(1.05)
  }

  .lg\:backdrop-brightness-110{
    --tw-backdrop-brightness:brightness(1.1)
  }

  .lg\:backdrop-brightness-125{
    --tw-backdrop-brightness:brightness(1.25)
  }

  .lg\:backdrop-brightness-150{
    --tw-backdrop-brightness:brightness(1.5)
  }

  .lg\:backdrop-brightness-200{
    --tw-backdrop-brightness:brightness(2)
  }

  .lg\:backdrop-contrast-0{
    --tw-backdrop-contrast:contrast(0)
  }

  .lg\:backdrop-contrast-50{
    --tw-backdrop-contrast:contrast(.5)
  }

  .lg\:backdrop-contrast-75{
    --tw-backdrop-contrast:contrast(.75)
  }

  .lg\:backdrop-contrast-100{
    --tw-backdrop-contrast:contrast(1)
  }

  .lg\:backdrop-contrast-125{
    --tw-backdrop-contrast:contrast(1.25)
  }

  .lg\:backdrop-contrast-150{
    --tw-backdrop-contrast:contrast(1.5)
  }

  .lg\:backdrop-contrast-200{
    --tw-backdrop-contrast:contrast(2)
  }

  .lg\:backdrop-grayscale-0{
    --tw-backdrop-grayscale:grayscale(0)
  }

  .lg\:backdrop-grayscale{
    --tw-backdrop-grayscale:grayscale(100%)
  }

  .lg\:backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate:hue-rotate(0deg)
  }

  .lg\:backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate:hue-rotate(15deg)
  }

  .lg\:backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate:hue-rotate(30deg)
  }

  .lg\:backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate:hue-rotate(60deg)
  }

  .lg\:backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate:hue-rotate(90deg)
  }

  .lg\:backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate:hue-rotate(180deg)
  }

  .lg\:-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate:hue-rotate(-180deg)
  }

  .lg\:-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate:hue-rotate(-90deg)
  }

  .lg\:-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate:hue-rotate(-60deg)
  }

  .lg\:-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate:hue-rotate(-30deg)
  }

  .lg\:-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate:hue-rotate(-15deg)
  }

  .lg\:backdrop-invert-0{
    --tw-backdrop-invert:invert(0)
  }

  .lg\:backdrop-invert{
    --tw-backdrop-invert:invert(100%)
  }

  .lg\:backdrop-opacity-0{
    --tw-backdrop-opacity:opacity(0)
  }

  .lg\:backdrop-opacity-5{
    --tw-backdrop-opacity:opacity(0.05)
  }

  .lg\:backdrop-opacity-10{
    --tw-backdrop-opacity:opacity(0.1)
  }

  .lg\:backdrop-opacity-20{
    --tw-backdrop-opacity:opacity(0.2)
  }

  .lg\:backdrop-opacity-25{
    --tw-backdrop-opacity:opacity(0.25)
  }

  .lg\:backdrop-opacity-30{
    --tw-backdrop-opacity:opacity(0.3)
  }

  .lg\:backdrop-opacity-40{
    --tw-backdrop-opacity:opacity(0.4)
  }

  .lg\:backdrop-opacity-50{
    --tw-backdrop-opacity:opacity(0.5)
  }

  .lg\:backdrop-opacity-60{
    --tw-backdrop-opacity:opacity(0.6)
  }

  .lg\:backdrop-opacity-70{
    --tw-backdrop-opacity:opacity(0.7)
  }

  .lg\:backdrop-opacity-75{
    --tw-backdrop-opacity:opacity(0.75)
  }

  .lg\:backdrop-opacity-80{
    --tw-backdrop-opacity:opacity(0.8)
  }

  .lg\:backdrop-opacity-90{
    --tw-backdrop-opacity:opacity(0.9)
  }

  .lg\:backdrop-opacity-95{
    --tw-backdrop-opacity:opacity(0.95)
  }

  .lg\:backdrop-opacity-100{
    --tw-backdrop-opacity:opacity(1)
  }

  .lg\:backdrop-saturate-0{
    --tw-backdrop-saturate:saturate(0)
  }

  .lg\:backdrop-saturate-50{
    --tw-backdrop-saturate:saturate(.5)
  }

  .lg\:backdrop-saturate-100{
    --tw-backdrop-saturate:saturate(1)
  }

  .lg\:backdrop-saturate-150{
    --tw-backdrop-saturate:saturate(1.5)
  }

  .lg\:backdrop-saturate-200{
    --tw-backdrop-saturate:saturate(2)
  }

  .lg\:backdrop-sepia-0{
    --tw-backdrop-sepia:sepia(0)
  }

  .lg\:backdrop-sepia{
    --tw-backdrop-sepia:sepia(100%)
  }

  .lg\:transition-none{
    transition-property:none
  }

  .lg\:transition-all{
    transition-property:all;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .lg\:transition{
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .lg\:transition-colors{
    transition-property:background-color, border-color, color, fill, stroke;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .lg\:transition-opacity{
    transition-property:opacity;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .lg\:transition-shadow{
    transition-property:box-shadow;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .lg\:transition-transform{
    transition-property:transform;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .lg\:duration-75{
    transition-duration:75ms
  }

  .lg\:duration-100{
    transition-duration:100ms
  }

  .lg\:duration-150{
    transition-duration:150ms
  }

  .lg\:duration-200{
    transition-duration:200ms
  }

  .lg\:duration-300{
    transition-duration:300ms
  }

  .lg\:duration-500{
    transition-duration:500ms
  }

  .lg\:duration-700{
    transition-duration:700ms
  }

  .lg\:duration-1000{
    transition-duration:1000ms
  }

  .lg\:ease-linear{
    transition-timing-function:linear
  }

  .lg\:ease-in{
    transition-timing-function:cubic-bezier(0.4, 0, 1, 1)
  }

  .lg\:ease-out{
    transition-timing-function:cubic-bezier(0, 0, 0.2, 1)
  }

  .lg\:ease-in-out{
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1)
  }
}

@media (min-width: 1280px){
  .xl\:visible{
    visibility:visible
  }

  .xl\:invisible{
    visibility:hidden
  }

  .xl\:static{
    position:static
  }

  .xl\:fixed{
    position:fixed
  }

  .xl\:absolute{
    position:absolute
  }

  .xl\:relative{
    position:relative
  }

  .xl\:sticky{
    position:sticky
  }

  .xl\:inset-0{
    top:0;
    right:0;
    bottom:0;
    left:0
  }

  .xl\:inset-1{
    top:4px;
    right:4px;
    bottom:4px;
    left:4px
  }

  .xl\:inset-2{
    top:8px;
    right:8px;
    bottom:8px;
    left:8px
  }

  .xl\:inset-3{
    top:12px;
    right:12px;
    bottom:12px;
    left:12px
  }

  .xl\:inset-4{
    top:16px;
    right:16px;
    bottom:16px;
    left:16px
  }

  .xl\:inset-5{
    top:20px;
    right:20px;
    bottom:20px;
    left:20px
  }

  .xl\:inset-6{
    top:24px;
    right:24px;
    bottom:24px;
    left:24px
  }

  .xl\:inset-8{
    top:32px;
    right:32px;
    bottom:32px;
    left:32px
  }

  .xl\:inset-10{
    top:40px;
    right:40px;
    bottom:40px;
    left:40px
  }

  .xl\:inset-12{
    top:48px;
    right:48px;
    bottom:48px;
    left:48px
  }

  .xl\:inset-16{
    top:64px;
    right:64px;
    bottom:64px;
    left:64px
  }

  .xl\:inset-18{
    top:72px;
    right:72px;
    bottom:72px;
    left:72px
  }

  .xl\:inset-24{
    top:96px;
    right:96px;
    bottom:96px;
    left:96px
  }

  .xl\:inset-auto{
    top:auto;
    right:auto;
    bottom:auto;
    left:auto
  }

  .xl\:inset-5dot5{
    top:22px;
    right:22px;
    bottom:22px;
    left:22px
  }

  .xl\:inset-full{
    top:100%;
    right:100%;
    bottom:100%;
    left:100%
  }

  .xl\:-inset-1{
    top:-4px;
    right:-4px;
    bottom:-4px;
    left:-4px
  }

  .xl\:-inset-2{
    top:-8px;
    right:-8px;
    bottom:-8px;
    left:-8px
  }

  .xl\:-inset-3{
    top:-12px;
    right:-12px;
    bottom:-12px;
    left:-12px
  }

  .xl\:-inset-4{
    top:-16px;
    right:-16px;
    bottom:-16px;
    left:-16px
  }

  .xl\:-inset-5{
    top:-20px;
    right:-20px;
    bottom:-20px;
    left:-20px
  }

  .xl\:-inset-6{
    top:-24px;
    right:-24px;
    bottom:-24px;
    left:-24px
  }

  .xl\:-inset-8{
    top:-32px;
    right:-32px;
    bottom:-32px;
    left:-32px
  }

  .xl\:-inset-10{
    top:-40px;
    right:-40px;
    bottom:-40px;
    left:-40px
  }

  .xl\:-inset-12{
    top:-48px;
    right:-48px;
    bottom:-48px;
    left:-48px
  }

  .xl\:-inset-16{
    top:-64px;
    right:-64px;
    bottom:-64px;
    left:-64px
  }

  .xl\:-inset-18{
    top:-72px;
    right:-72px;
    bottom:-72px;
    left:-72px
  }

  .xl\:-inset-24{
    top:-96px;
    right:-96px;
    bottom:-96px;
    left:-96px
  }

  .xl\:-inset-5dot5{
    top:-22px;
    right:-22px;
    bottom:-22px;
    left:-22px
  }

  .xl\:-inset-full{
    top:-100%;
    right:-100%;
    bottom:-100%;
    left:-100%
  }

  .xl\:inset-1\/2{
    top:50%;
    right:50%;
    bottom:50%;
    left:50%
  }

  .xl\:inset-1\/3{
    top:33.33333%;
    right:33.33333%;
    bottom:33.33333%;
    left:33.33333%
  }

  .xl\:inset-2\/3{
    top:66.666667%;
    right:66.666667%;
    bottom:66.666667%;
    left:66.666667%
  }

  .xl\:inset-1\/4{
    top:25%;
    right:25%;
    bottom:25%;
    left:25%
  }

  .xl\:inset-2\/4{
    top:50%;
    right:50%;
    bottom:50%;
    left:50%
  }

  .xl\:inset-3\/4{
    top:75%;
    right:75%;
    bottom:75%;
    left:75%
  }

  .xl\:-inset-1\/2{
    top:-50%;
    right:-50%;
    bottom:-50%;
    left:-50%
  }

  .xl\:-inset-1\/3{
    top:-33.333333%;
    right:-33.333333%;
    bottom:-33.333333%;
    left:-33.333333%
  }

  .xl\:-inset-2\/3{
    top:-66.666667%;
    right:-66.666667%;
    bottom:-66.666667%;
    left:-66.666667%
  }

  .xl\:-inset-1\/4{
    top:-25%;
    right:-25%;
    bottom:-25%;
    left:-25%
  }

  .xl\:-inset-2\/4{
    top:-50%;
    right:-50%;
    bottom:-50%;
    left:-50%
  }

  .xl\:-inset-3\/4{
    top:-75%;
    right:-75%;
    bottom:-75%;
    left:-75%
  }

  .xl\:inset-x-0{
    left:0;
    right:0
  }

  .xl\:inset-x-1{
    left:4px;
    right:4px
  }

  .xl\:inset-x-2{
    left:8px;
    right:8px
  }

  .xl\:inset-x-3{
    left:12px;
    right:12px
  }

  .xl\:inset-x-4{
    left:16px;
    right:16px
  }

  .xl\:inset-x-5{
    left:20px;
    right:20px
  }

  .xl\:inset-x-6{
    left:24px;
    right:24px
  }

  .xl\:inset-x-8{
    left:32px;
    right:32px
  }

  .xl\:inset-x-10{
    left:40px;
    right:40px
  }

  .xl\:inset-x-12{
    left:48px;
    right:48px
  }

  .xl\:inset-x-16{
    left:64px;
    right:64px
  }

  .xl\:inset-x-18{
    left:72px;
    right:72px
  }

  .xl\:inset-x-24{
    left:96px;
    right:96px
  }

  .xl\:inset-x-auto{
    left:auto;
    right:auto
  }

  .xl\:inset-x-5dot5{
    left:22px;
    right:22px
  }

  .xl\:inset-x-full{
    left:100%;
    right:100%
  }

  .xl\:-inset-x-1{
    left:-4px;
    right:-4px
  }

  .xl\:-inset-x-2{
    left:-8px;
    right:-8px
  }

  .xl\:-inset-x-3{
    left:-12px;
    right:-12px
  }

  .xl\:-inset-x-4{
    left:-16px;
    right:-16px
  }

  .xl\:-inset-x-5{
    left:-20px;
    right:-20px
  }

  .xl\:-inset-x-6{
    left:-24px;
    right:-24px
  }

  .xl\:-inset-x-8{
    left:-32px;
    right:-32px
  }

  .xl\:-inset-x-10{
    left:-40px;
    right:-40px
  }

  .xl\:-inset-x-12{
    left:-48px;
    right:-48px
  }

  .xl\:-inset-x-16{
    left:-64px;
    right:-64px
  }

  .xl\:-inset-x-18{
    left:-72px;
    right:-72px
  }

  .xl\:-inset-x-24{
    left:-96px;
    right:-96px
  }

  .xl\:-inset-x-5dot5{
    left:-22px;
    right:-22px
  }

  .xl\:-inset-x-full{
    left:-100%;
    right:-100%
  }

  .xl\:inset-x-1\/2{
    left:50%;
    right:50%
  }

  .xl\:inset-x-1\/3{
    left:33.33333%;
    right:33.33333%
  }

  .xl\:inset-x-2\/3{
    left:66.666667%;
    right:66.666667%
  }

  .xl\:inset-x-1\/4{
    left:25%;
    right:25%
  }

  .xl\:inset-x-2\/4{
    left:50%;
    right:50%
  }

  .xl\:inset-x-3\/4{
    left:75%;
    right:75%
  }

  .xl\:-inset-x-1\/2{
    left:-50%;
    right:-50%
  }

  .xl\:-inset-x-1\/3{
    left:-33.333333%;
    right:-33.333333%
  }

  .xl\:-inset-x-2\/3{
    left:-66.666667%;
    right:-66.666667%
  }

  .xl\:-inset-x-1\/4{
    left:-25%;
    right:-25%
  }

  .xl\:-inset-x-2\/4{
    left:-50%;
    right:-50%
  }

  .xl\:-inset-x-3\/4{
    left:-75%;
    right:-75%
  }

  .xl\:inset-y-0{
    top:0;
    bottom:0
  }

  .xl\:inset-y-1{
    top:4px;
    bottom:4px
  }

  .xl\:inset-y-2{
    top:8px;
    bottom:8px
  }

  .xl\:inset-y-3{
    top:12px;
    bottom:12px
  }

  .xl\:inset-y-4{
    top:16px;
    bottom:16px
  }

  .xl\:inset-y-5{
    top:20px;
    bottom:20px
  }

  .xl\:inset-y-6{
    top:24px;
    bottom:24px
  }

  .xl\:inset-y-8{
    top:32px;
    bottom:32px
  }

  .xl\:inset-y-10{
    top:40px;
    bottom:40px
  }

  .xl\:inset-y-12{
    top:48px;
    bottom:48px
  }

  .xl\:inset-y-16{
    top:64px;
    bottom:64px
  }

  .xl\:inset-y-18{
    top:72px;
    bottom:72px
  }

  .xl\:inset-y-24{
    top:96px;
    bottom:96px
  }

  .xl\:inset-y-auto{
    top:auto;
    bottom:auto
  }

  .xl\:inset-y-5dot5{
    top:22px;
    bottom:22px
  }

  .xl\:inset-y-full{
    top:100%;
    bottom:100%
  }

  .xl\:-inset-y-1{
    top:-4px;
    bottom:-4px
  }

  .xl\:-inset-y-2{
    top:-8px;
    bottom:-8px
  }

  .xl\:-inset-y-3{
    top:-12px;
    bottom:-12px
  }

  .xl\:-inset-y-4{
    top:-16px;
    bottom:-16px
  }

  .xl\:-inset-y-5{
    top:-20px;
    bottom:-20px
  }

  .xl\:-inset-y-6{
    top:-24px;
    bottom:-24px
  }

  .xl\:-inset-y-8{
    top:-32px;
    bottom:-32px
  }

  .xl\:-inset-y-10{
    top:-40px;
    bottom:-40px
  }

  .xl\:-inset-y-12{
    top:-48px;
    bottom:-48px
  }

  .xl\:-inset-y-16{
    top:-64px;
    bottom:-64px
  }

  .xl\:-inset-y-18{
    top:-72px;
    bottom:-72px
  }

  .xl\:-inset-y-24{
    top:-96px;
    bottom:-96px
  }

  .xl\:-inset-y-5dot5{
    top:-22px;
    bottom:-22px
  }

  .xl\:-inset-y-full{
    top:-100%;
    bottom:-100%
  }

  .xl\:inset-y-1\/2{
    top:50%;
    bottom:50%
  }

  .xl\:inset-y-1\/3{
    top:33.33333%;
    bottom:33.33333%
  }

  .xl\:inset-y-2\/3{
    top:66.666667%;
    bottom:66.666667%
  }

  .xl\:inset-y-1\/4{
    top:25%;
    bottom:25%
  }

  .xl\:inset-y-2\/4{
    top:50%;
    bottom:50%
  }

  .xl\:inset-y-3\/4{
    top:75%;
    bottom:75%
  }

  .xl\:-inset-y-1\/2{
    top:-50%;
    bottom:-50%
  }

  .xl\:-inset-y-1\/3{
    top:-33.333333%;
    bottom:-33.333333%
  }

  .xl\:-inset-y-2\/3{
    top:-66.666667%;
    bottom:-66.666667%
  }

  .xl\:-inset-y-1\/4{
    top:-25%;
    bottom:-25%
  }

  .xl\:-inset-y-2\/4{
    top:-50%;
    bottom:-50%
  }

  .xl\:-inset-y-3\/4{
    top:-75%;
    bottom:-75%
  }

  .xl\:top-0{
    top:0
  }

  .xl\:top-1{
    top:4px
  }

  .xl\:top-2{
    top:8px
  }

  .xl\:top-3{
    top:12px
  }

  .xl\:top-4{
    top:16px
  }

  .xl\:top-5{
    top:20px
  }

  .xl\:top-6{
    top:24px
  }

  .xl\:top-8{
    top:32px
  }

  .xl\:top-10{
    top:40px
  }

  .xl\:top-12{
    top:48px
  }

  .xl\:top-16{
    top:64px
  }

  .xl\:top-18{
    top:72px
  }

  .xl\:top-24{
    top:96px
  }

  .xl\:top-auto{
    top:auto
  }

  .xl\:top-5dot5{
    top:22px
  }

  .xl\:top-full{
    top:100%
  }

  .xl\:-top-1{
    top:-4px
  }

  .xl\:-top-2{
    top:-8px
  }

  .xl\:-top-3{
    top:-12px
  }

  .xl\:-top-4{
    top:-16px
  }

  .xl\:-top-5{
    top:-20px
  }

  .xl\:-top-6{
    top:-24px
  }

  .xl\:-top-8{
    top:-32px
  }

  .xl\:-top-10{
    top:-40px
  }

  .xl\:-top-12{
    top:-48px
  }

  .xl\:-top-16{
    top:-64px
  }

  .xl\:-top-18{
    top:-72px
  }

  .xl\:-top-24{
    top:-96px
  }

  .xl\:-top-5dot5{
    top:-22px
  }

  .xl\:-top-full{
    top:-100%
  }

  .xl\:top-1\/2{
    top:50%
  }

  .xl\:top-1\/3{
    top:33.33333%
  }

  .xl\:top-2\/3{
    top:66.666667%
  }

  .xl\:top-1\/4{
    top:25%
  }

  .xl\:top-2\/4{
    top:50%
  }

  .xl\:top-3\/4{
    top:75%
  }

  .xl\:-top-1\/2{
    top:-50%
  }

  .xl\:-top-1\/3{
    top:-33.333333%
  }

  .xl\:-top-2\/3{
    top:-66.666667%
  }

  .xl\:-top-1\/4{
    top:-25%
  }

  .xl\:-top-2\/4{
    top:-50%
  }

  .xl\:-top-3\/4{
    top:-75%
  }

  .xl\:right-0{
    right:0
  }

  .xl\:right-1{
    right:4px
  }

  .xl\:right-2{
    right:8px
  }

  .xl\:right-3{
    right:12px
  }

  .xl\:right-4{
    right:16px
  }

  .xl\:right-5{
    right:20px
  }

  .xl\:right-6{
    right:24px
  }

  .xl\:right-8{
    right:32px
  }

  .xl\:right-10{
    right:40px
  }

  .xl\:right-12{
    right:48px
  }

  .xl\:right-16{
    right:64px
  }

  .xl\:right-18{
    right:72px
  }

  .xl\:right-24{
    right:96px
  }

  .xl\:right-auto{
    right:auto
  }

  .xl\:right-5dot5{
    right:22px
  }

  .xl\:right-full{
    right:100%
  }

  .xl\:-right-1{
    right:-4px
  }

  .xl\:-right-2{
    right:-8px
  }

  .xl\:-right-3{
    right:-12px
  }

  .xl\:-right-4{
    right:-16px
  }

  .xl\:-right-5{
    right:-20px
  }

  .xl\:-right-6{
    right:-24px
  }

  .xl\:-right-8{
    right:-32px
  }

  .xl\:-right-10{
    right:-40px
  }

  .xl\:-right-12{
    right:-48px
  }

  .xl\:-right-16{
    right:-64px
  }

  .xl\:-right-18{
    right:-72px
  }

  .xl\:-right-24{
    right:-96px
  }

  .xl\:-right-5dot5{
    right:-22px
  }

  .xl\:-right-full{
    right:-100%
  }

  .xl\:right-1\/2{
    right:50%
  }

  .xl\:right-1\/3{
    right:33.33333%
  }

  .xl\:right-2\/3{
    right:66.666667%
  }

  .xl\:right-1\/4{
    right:25%
  }

  .xl\:right-2\/4{
    right:50%
  }

  .xl\:right-3\/4{
    right:75%
  }

  .xl\:-right-1\/2{
    right:-50%
  }

  .xl\:-right-1\/3{
    right:-33.333333%
  }

  .xl\:-right-2\/3{
    right:-66.666667%
  }

  .xl\:-right-1\/4{
    right:-25%
  }

  .xl\:-right-2\/4{
    right:-50%
  }

  .xl\:-right-3\/4{
    right:-75%
  }

  .xl\:bottom-0{
    bottom:0
  }

  .xl\:bottom-1{
    bottom:4px
  }

  .xl\:bottom-2{
    bottom:8px
  }

  .xl\:bottom-3{
    bottom:12px
  }

  .xl\:bottom-4{
    bottom:16px
  }

  .xl\:bottom-5{
    bottom:20px
  }

  .xl\:bottom-6{
    bottom:24px
  }

  .xl\:bottom-8{
    bottom:32px
  }

  .xl\:bottom-10{
    bottom:40px
  }

  .xl\:bottom-12{
    bottom:48px
  }

  .xl\:bottom-16{
    bottom:64px
  }

  .xl\:bottom-18{
    bottom:72px
  }

  .xl\:bottom-24{
    bottom:96px
  }

  .xl\:bottom-auto{
    bottom:auto
  }

  .xl\:bottom-5dot5{
    bottom:22px
  }

  .xl\:bottom-full{
    bottom:100%
  }

  .xl\:-bottom-1{
    bottom:-4px
  }

  .xl\:-bottom-2{
    bottom:-8px
  }

  .xl\:-bottom-3{
    bottom:-12px
  }

  .xl\:-bottom-4{
    bottom:-16px
  }

  .xl\:-bottom-5{
    bottom:-20px
  }

  .xl\:-bottom-6{
    bottom:-24px
  }

  .xl\:-bottom-8{
    bottom:-32px
  }

  .xl\:-bottom-10{
    bottom:-40px
  }

  .xl\:-bottom-12{
    bottom:-48px
  }

  .xl\:-bottom-16{
    bottom:-64px
  }

  .xl\:-bottom-18{
    bottom:-72px
  }

  .xl\:-bottom-24{
    bottom:-96px
  }

  .xl\:-bottom-5dot5{
    bottom:-22px
  }

  .xl\:-bottom-full{
    bottom:-100%
  }

  .xl\:bottom-1\/2{
    bottom:50%
  }

  .xl\:bottom-1\/3{
    bottom:33.33333%
  }

  .xl\:bottom-2\/3{
    bottom:66.666667%
  }

  .xl\:bottom-1\/4{
    bottom:25%
  }

  .xl\:bottom-2\/4{
    bottom:50%
  }

  .xl\:bottom-3\/4{
    bottom:75%
  }

  .xl\:-bottom-1\/2{
    bottom:-50%
  }

  .xl\:-bottom-1\/3{
    bottom:-33.333333%
  }

  .xl\:-bottom-2\/3{
    bottom:-66.666667%
  }

  .xl\:-bottom-1\/4{
    bottom:-25%
  }

  .xl\:-bottom-2\/4{
    bottom:-50%
  }

  .xl\:-bottom-3\/4{
    bottom:-75%
  }

  .xl\:left-0{
    left:0
  }

  .xl\:left-1{
    left:4px
  }

  .xl\:left-2{
    left:8px
  }

  .xl\:left-3{
    left:12px
  }

  .xl\:left-4{
    left:16px
  }

  .xl\:left-5{
    left:20px
  }

  .xl\:left-6{
    left:24px
  }

  .xl\:left-8{
    left:32px
  }

  .xl\:left-10{
    left:40px
  }

  .xl\:left-12{
    left:48px
  }

  .xl\:left-16{
    left:64px
  }

  .xl\:left-18{
    left:72px
  }

  .xl\:left-24{
    left:96px
  }

  .xl\:left-auto{
    left:auto
  }

  .xl\:left-5dot5{
    left:22px
  }

  .xl\:left-full{
    left:100%
  }

  .xl\:-left-1{
    left:-4px
  }

  .xl\:-left-2{
    left:-8px
  }

  .xl\:-left-3{
    left:-12px
  }

  .xl\:-left-4{
    left:-16px
  }

  .xl\:-left-5{
    left:-20px
  }

  .xl\:-left-6{
    left:-24px
  }

  .xl\:-left-8{
    left:-32px
  }

  .xl\:-left-10{
    left:-40px
  }

  .xl\:-left-12{
    left:-48px
  }

  .xl\:-left-16{
    left:-64px
  }

  .xl\:-left-18{
    left:-72px
  }

  .xl\:-left-24{
    left:-96px
  }

  .xl\:-left-5dot5{
    left:-22px
  }

  .xl\:-left-full{
    left:-100%
  }

  .xl\:left-1\/2{
    left:50%
  }

  .xl\:left-1\/3{
    left:33.33333%
  }

  .xl\:left-2\/3{
    left:66.666667%
  }

  .xl\:left-1\/4{
    left:25%
  }

  .xl\:left-2\/4{
    left:50%
  }

  .xl\:left-3\/4{
    left:75%
  }

  .xl\:-left-1\/2{
    left:-50%
  }

  .xl\:-left-1\/3{
    left:-33.333333%
  }

  .xl\:-left-2\/3{
    left:-66.666667%
  }

  .xl\:-left-1\/4{
    left:-25%
  }

  .xl\:-left-2\/4{
    left:-50%
  }

  .xl\:-left-3\/4{
    left:-75%
  }

  .xl\:isolate{
    isolation:isolate
  }

  .xl\:isolation-auto{
    isolation:auto
  }

  .xl\:z-0{
    z-index:0
  }

  .xl\:z-10{
    z-index:10
  }

  .xl\:z-20{
    z-index:20
  }

  .xl\:z-30{
    z-index:30
  }

  .xl\:z-40{
    z-index:40
  }

  .xl\:z-50{
    z-index:50
  }

  .xl\:z-auto{
    z-index:auto
  }

  .xl\:focus-within\:z-0:focus-within{
    z-index:0
  }

  .xl\:focus-within\:z-10:focus-within{
    z-index:10
  }

  .xl\:focus-within\:z-20:focus-within{
    z-index:20
  }

  .xl\:focus-within\:z-30:focus-within{
    z-index:30
  }

  .xl\:focus-within\:z-40:focus-within{
    z-index:40
  }

  .xl\:focus-within\:z-50:focus-within{
    z-index:50
  }

  .xl\:focus-within\:z-auto:focus-within{
    z-index:auto
  }

  .xl\:focus\:z-0:focus{
    z-index:0
  }

  .xl\:focus\:z-10:focus{
    z-index:10
  }

  .xl\:focus\:z-20:focus{
    z-index:20
  }

  .xl\:focus\:z-30:focus{
    z-index:30
  }

  .xl\:focus\:z-40:focus{
    z-index:40
  }

  .xl\:focus\:z-50:focus{
    z-index:50
  }

  .xl\:focus\:z-auto:focus{
    z-index:auto
  }

  .xl\:col-auto{
    grid-column:auto
  }

  .xl\:col-span-1{
    grid-column:span 1 / span 1
  }

  .xl\:col-span-2{
    grid-column:span 2 / span 2
  }

  .xl\:col-span-3{
    grid-column:span 3 / span 3
  }

  .xl\:col-span-4{
    grid-column:span 4 / span 4
  }

  .xl\:col-span-5{
    grid-column:span 5 / span 5
  }

  .xl\:col-span-6{
    grid-column:span 6 / span 6
  }

  .xl\:col-span-7{
    grid-column:span 7 / span 7
  }

  .xl\:col-span-8{
    grid-column:span 8 / span 8
  }

  .xl\:col-span-9{
    grid-column:span 9 / span 9
  }

  .xl\:col-span-10{
    grid-column:span 10 / span 10
  }

  .xl\:col-span-11{
    grid-column:span 11 / span 11
  }

  .xl\:col-span-12{
    grid-column:span 12 / span 12
  }

  .xl\:col-span-full{
    grid-column:1 / -1
  }

  .xl\:col-start-1{
    grid-column-start:1
  }

  .xl\:col-start-2{
    grid-column-start:2
  }

  .xl\:col-start-3{
    grid-column-start:3
  }

  .xl\:col-start-4{
    grid-column-start:4
  }

  .xl\:col-start-5{
    grid-column-start:5
  }

  .xl\:col-start-6{
    grid-column-start:6
  }

  .xl\:col-start-7{
    grid-column-start:7
  }

  .xl\:col-start-8{
    grid-column-start:8
  }

  .xl\:col-start-9{
    grid-column-start:9
  }

  .xl\:col-start-10{
    grid-column-start:10
  }

  .xl\:col-start-11{
    grid-column-start:11
  }

  .xl\:col-start-12{
    grid-column-start:12
  }

  .xl\:col-start-13{
    grid-column-start:13
  }

  .xl\:col-start-auto{
    grid-column-start:auto
  }

  .xl\:col-end-1{
    grid-column-end:1
  }

  .xl\:col-end-2{
    grid-column-end:2
  }

  .xl\:col-end-3{
    grid-column-end:3
  }

  .xl\:col-end-4{
    grid-column-end:4
  }

  .xl\:col-end-5{
    grid-column-end:5
  }

  .xl\:col-end-6{
    grid-column-end:6
  }

  .xl\:col-end-7{
    grid-column-end:7
  }

  .xl\:col-end-8{
    grid-column-end:8
  }

  .xl\:col-end-9{
    grid-column-end:9
  }

  .xl\:col-end-10{
    grid-column-end:10
  }

  .xl\:col-end-11{
    grid-column-end:11
  }

  .xl\:col-end-12{
    grid-column-end:12
  }

  .xl\:col-end-13{
    grid-column-end:13
  }

  .xl\:col-end-auto{
    grid-column-end:auto
  }

  .xl\:row-auto{
    grid-row:auto
  }

  .xl\:row-span-1{
    grid-row:span 1 / span 1
  }

  .xl\:row-span-2{
    grid-row:span 2 / span 2
  }

  .xl\:row-span-3{
    grid-row:span 3 / span 3
  }

  .xl\:row-span-4{
    grid-row:span 4 / span 4
  }

  .xl\:row-span-5{
    grid-row:span 5 / span 5
  }

  .xl\:row-span-6{
    grid-row:span 6 / span 6
  }

  .xl\:row-span-full{
    grid-row:1 / -1
  }

  .xl\:row-start-1{
    grid-row-start:1
  }

  .xl\:row-start-2{
    grid-row-start:2
  }

  .xl\:row-start-3{
    grid-row-start:3
  }

  .xl\:row-start-4{
    grid-row-start:4
  }

  .xl\:row-start-5{
    grid-row-start:5
  }

  .xl\:row-start-6{
    grid-row-start:6
  }

  .xl\:row-start-7{
    grid-row-start:7
  }

  .xl\:row-start-auto{
    grid-row-start:auto
  }

  .xl\:row-end-1{
    grid-row-end:1
  }

  .xl\:row-end-2{
    grid-row-end:2
  }

  .xl\:row-end-3{
    grid-row-end:3
  }

  .xl\:row-end-4{
    grid-row-end:4
  }

  .xl\:row-end-5{
    grid-row-end:5
  }

  .xl\:row-end-6{
    grid-row-end:6
  }

  .xl\:row-end-7{
    grid-row-end:7
  }

  .xl\:row-end-auto{
    grid-row-end:auto
  }

  .xl\:m-0{
    margin:0
  }

  .xl\:m-1{
    margin:4px
  }

  .xl\:m-2{
    margin:8px
  }

  .xl\:m-3{
    margin:12px
  }

  .xl\:m-4{
    margin:16px
  }

  .xl\:m-5{
    margin:20px
  }

  .xl\:m-6{
    margin:24px
  }

  .xl\:m-8{
    margin:32px
  }

  .xl\:m-10{
    margin:40px
  }

  .xl\:m-12{
    margin:48px
  }

  .xl\:m-16{
    margin:64px
  }

  .xl\:m-18{
    margin:72px
  }

  .xl\:m-24{
    margin:96px
  }

  .xl\:m-auto{
    margin:auto
  }

  .xl\:m-5dot5{
    margin:22px
  }

  .xl\:m-full{
    margin:100%
  }

  .xl\:-m-1{
    margin:-4px
  }

  .xl\:-m-2{
    margin:-8px
  }

  .xl\:-m-3{
    margin:-12px
  }

  .xl\:-m-4{
    margin:-16px
  }

  .xl\:-m-5{
    margin:-20px
  }

  .xl\:-m-6{
    margin:-24px
  }

  .xl\:-m-8{
    margin:-32px
  }

  .xl\:-m-10{
    margin:-40px
  }

  .xl\:-m-12{
    margin:-48px
  }

  .xl\:-m-16{
    margin:-64px
  }

  .xl\:-m-18{
    margin:-72px
  }

  .xl\:-m-24{
    margin:-96px
  }

  .xl\:-m-5dot5{
    margin:-22px
  }

  .xl\:-m-full{
    margin:-100%
  }

  .xl\:first\:m-0:first-child{
    margin:0
  }

  .xl\:first\:m-1:first-child{
    margin:4px
  }

  .xl\:first\:m-2:first-child{
    margin:8px
  }

  .xl\:first\:m-3:first-child{
    margin:12px
  }

  .xl\:first\:m-4:first-child{
    margin:16px
  }

  .xl\:first\:m-5:first-child{
    margin:20px
  }

  .xl\:first\:m-6:first-child{
    margin:24px
  }

  .xl\:first\:m-8:first-child{
    margin:32px
  }

  .xl\:first\:m-10:first-child{
    margin:40px
  }

  .xl\:first\:m-12:first-child{
    margin:48px
  }

  .xl\:first\:m-16:first-child{
    margin:64px
  }

  .xl\:first\:m-18:first-child{
    margin:72px
  }

  .xl\:first\:m-24:first-child{
    margin:96px
  }

  .xl\:first\:m-auto:first-child{
    margin:auto
  }

  .xl\:first\:m-5dot5:first-child{
    margin:22px
  }

  .xl\:first\:m-full:first-child{
    margin:100%
  }

  .xl\:first\:-m-1:first-child{
    margin:-4px
  }

  .xl\:first\:-m-2:first-child{
    margin:-8px
  }

  .xl\:first\:-m-3:first-child{
    margin:-12px
  }

  .xl\:first\:-m-4:first-child{
    margin:-16px
  }

  .xl\:first\:-m-5:first-child{
    margin:-20px
  }

  .xl\:first\:-m-6:first-child{
    margin:-24px
  }

  .xl\:first\:-m-8:first-child{
    margin:-32px
  }

  .xl\:first\:-m-10:first-child{
    margin:-40px
  }

  .xl\:first\:-m-12:first-child{
    margin:-48px
  }

  .xl\:first\:-m-16:first-child{
    margin:-64px
  }

  .xl\:first\:-m-18:first-child{
    margin:-72px
  }

  .xl\:first\:-m-24:first-child{
    margin:-96px
  }

  .xl\:first\:-m-5dot5:first-child{
    margin:-22px
  }

  .xl\:first\:-m-full:first-child{
    margin:-100%
  }

  .xl\:last\:m-0:last-child{
    margin:0
  }

  .xl\:last\:m-1:last-child{
    margin:4px
  }

  .xl\:last\:m-2:last-child{
    margin:8px
  }

  .xl\:last\:m-3:last-child{
    margin:12px
  }

  .xl\:last\:m-4:last-child{
    margin:16px
  }

  .xl\:last\:m-5:last-child{
    margin:20px
  }

  .xl\:last\:m-6:last-child{
    margin:24px
  }

  .xl\:last\:m-8:last-child{
    margin:32px
  }

  .xl\:last\:m-10:last-child{
    margin:40px
  }

  .xl\:last\:m-12:last-child{
    margin:48px
  }

  .xl\:last\:m-16:last-child{
    margin:64px
  }

  .xl\:last\:m-18:last-child{
    margin:72px
  }

  .xl\:last\:m-24:last-child{
    margin:96px
  }

  .xl\:last\:m-auto:last-child{
    margin:auto
  }

  .xl\:last\:m-5dot5:last-child{
    margin:22px
  }

  .xl\:last\:m-full:last-child{
    margin:100%
  }

  .xl\:last\:-m-1:last-child{
    margin:-4px
  }

  .xl\:last\:-m-2:last-child{
    margin:-8px
  }

  .xl\:last\:-m-3:last-child{
    margin:-12px
  }

  .xl\:last\:-m-4:last-child{
    margin:-16px
  }

  .xl\:last\:-m-5:last-child{
    margin:-20px
  }

  .xl\:last\:-m-6:last-child{
    margin:-24px
  }

  .xl\:last\:-m-8:last-child{
    margin:-32px
  }

  .xl\:last\:-m-10:last-child{
    margin:-40px
  }

  .xl\:last\:-m-12:last-child{
    margin:-48px
  }

  .xl\:last\:-m-16:last-child{
    margin:-64px
  }

  .xl\:last\:-m-18:last-child{
    margin:-72px
  }

  .xl\:last\:-m-24:last-child{
    margin:-96px
  }

  .xl\:last\:-m-5dot5:last-child{
    margin:-22px
  }

  .xl\:last\:-m-full:last-child{
    margin:-100%
  }

  .xl\:mx-0{
    margin-left:0;
    margin-right:0
  }

  .xl\:mx-1{
    margin-left:4px;
    margin-right:4px
  }

  .xl\:mx-2{
    margin-left:8px;
    margin-right:8px
  }

  .xl\:mx-3{
    margin-left:12px;
    margin-right:12px
  }

  .xl\:mx-4{
    margin-left:16px;
    margin-right:16px
  }

  .xl\:mx-5{
    margin-left:20px;
    margin-right:20px
  }

  .xl\:mx-6{
    margin-left:24px;
    margin-right:24px
  }

  .xl\:mx-8{
    margin-left:32px;
    margin-right:32px
  }

  .xl\:mx-10{
    margin-left:40px;
    margin-right:40px
  }

  .xl\:mx-12{
    margin-left:48px;
    margin-right:48px
  }

  .xl\:mx-16{
    margin-left:64px;
    margin-right:64px
  }

  .xl\:mx-18{
    margin-left:72px;
    margin-right:72px
  }

  .xl\:mx-24{
    margin-left:96px;
    margin-right:96px
  }

  .xl\:mx-auto{
    margin-left:auto;
    margin-right:auto
  }

  .xl\:mx-5dot5{
    margin-left:22px;
    margin-right:22px
  }

  .xl\:mx-full{
    margin-left:100%;
    margin-right:100%
  }

  .xl\:-mx-1{
    margin-left:-4px;
    margin-right:-4px
  }

  .xl\:-mx-2{
    margin-left:-8px;
    margin-right:-8px
  }

  .xl\:-mx-3{
    margin-left:-12px;
    margin-right:-12px
  }

  .xl\:-mx-4{
    margin-left:-16px;
    margin-right:-16px
  }

  .xl\:-mx-5{
    margin-left:-20px;
    margin-right:-20px
  }

  .xl\:-mx-6{
    margin-left:-24px;
    margin-right:-24px
  }

  .xl\:-mx-8{
    margin-left:-32px;
    margin-right:-32px
  }

  .xl\:-mx-10{
    margin-left:-40px;
    margin-right:-40px
  }

  .xl\:-mx-12{
    margin-left:-48px;
    margin-right:-48px
  }

  .xl\:-mx-16{
    margin-left:-64px;
    margin-right:-64px
  }

  .xl\:-mx-18{
    margin-left:-72px;
    margin-right:-72px
  }

  .xl\:-mx-24{
    margin-left:-96px;
    margin-right:-96px
  }

  .xl\:-mx-5dot5{
    margin-left:-22px;
    margin-right:-22px
  }

  .xl\:-mx-full{
    margin-left:-100%;
    margin-right:-100%
  }

  .xl\:my-0{
    margin-top:0;
    margin-bottom:0
  }

  .xl\:my-1{
    margin-top:4px;
    margin-bottom:4px
  }

  .xl\:my-2{
    margin-top:8px;
    margin-bottom:8px
  }

  .xl\:my-3{
    margin-top:12px;
    margin-bottom:12px
  }

  .xl\:my-4{
    margin-top:16px;
    margin-bottom:16px
  }

  .xl\:my-5{
    margin-top:20px;
    margin-bottom:20px
  }

  .xl\:my-6{
    margin-top:24px;
    margin-bottom:24px
  }

  .xl\:my-8{
    margin-top:32px;
    margin-bottom:32px
  }

  .xl\:my-10{
    margin-top:40px;
    margin-bottom:40px
  }

  .xl\:my-12{
    margin-top:48px;
    margin-bottom:48px
  }

  .xl\:my-16{
    margin-top:64px;
    margin-bottom:64px
  }

  .xl\:my-18{
    margin-top:72px;
    margin-bottom:72px
  }

  .xl\:my-24{
    margin-top:96px;
    margin-bottom:96px
  }

  .xl\:my-auto{
    margin-top:auto;
    margin-bottom:auto
  }

  .xl\:my-5dot5{
    margin-top:22px;
    margin-bottom:22px
  }

  .xl\:my-full{
    margin-top:100%;
    margin-bottom:100%
  }

  .xl\:-my-1{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .xl\:-my-2{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .xl\:-my-3{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .xl\:-my-4{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .xl\:-my-5{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .xl\:-my-6{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .xl\:-my-8{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .xl\:-my-10{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .xl\:-my-12{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .xl\:-my-16{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .xl\:-my-18{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .xl\:-my-24{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .xl\:-my-5dot5{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .xl\:-my-full{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .xl\:first\:mx-0:first-child{
    margin-left:0;
    margin-right:0
  }

  .xl\:first\:mx-1:first-child{
    margin-left:4px;
    margin-right:4px
  }

  .xl\:first\:mx-2:first-child{
    margin-left:8px;
    margin-right:8px
  }

  .xl\:first\:mx-3:first-child{
    margin-left:12px;
    margin-right:12px
  }

  .xl\:first\:mx-4:first-child{
    margin-left:16px;
    margin-right:16px
  }

  .xl\:first\:mx-5:first-child{
    margin-left:20px;
    margin-right:20px
  }

  .xl\:first\:mx-6:first-child{
    margin-left:24px;
    margin-right:24px
  }

  .xl\:first\:mx-8:first-child{
    margin-left:32px;
    margin-right:32px
  }

  .xl\:first\:mx-10:first-child{
    margin-left:40px;
    margin-right:40px
  }

  .xl\:first\:mx-12:first-child{
    margin-left:48px;
    margin-right:48px
  }

  .xl\:first\:mx-16:first-child{
    margin-left:64px;
    margin-right:64px
  }

  .xl\:first\:mx-18:first-child{
    margin-left:72px;
    margin-right:72px
  }

  .xl\:first\:mx-24:first-child{
    margin-left:96px;
    margin-right:96px
  }

  .xl\:first\:mx-auto:first-child{
    margin-left:auto;
    margin-right:auto
  }

  .xl\:first\:mx-5dot5:first-child{
    margin-left:22px;
    margin-right:22px
  }

  .xl\:first\:mx-full:first-child{
    margin-left:100%;
    margin-right:100%
  }

  .xl\:first\:-mx-1:first-child{
    margin-left:-4px;
    margin-right:-4px
  }

  .xl\:first\:-mx-2:first-child{
    margin-left:-8px;
    margin-right:-8px
  }

  .xl\:first\:-mx-3:first-child{
    margin-left:-12px;
    margin-right:-12px
  }

  .xl\:first\:-mx-4:first-child{
    margin-left:-16px;
    margin-right:-16px
  }

  .xl\:first\:-mx-5:first-child{
    margin-left:-20px;
    margin-right:-20px
  }

  .xl\:first\:-mx-6:first-child{
    margin-left:-24px;
    margin-right:-24px
  }

  .xl\:first\:-mx-8:first-child{
    margin-left:-32px;
    margin-right:-32px
  }

  .xl\:first\:-mx-10:first-child{
    margin-left:-40px;
    margin-right:-40px
  }

  .xl\:first\:-mx-12:first-child{
    margin-left:-48px;
    margin-right:-48px
  }

  .xl\:first\:-mx-16:first-child{
    margin-left:-64px;
    margin-right:-64px
  }

  .xl\:first\:-mx-18:first-child{
    margin-left:-72px;
    margin-right:-72px
  }

  .xl\:first\:-mx-24:first-child{
    margin-left:-96px;
    margin-right:-96px
  }

  .xl\:first\:-mx-5dot5:first-child{
    margin-left:-22px;
    margin-right:-22px
  }

  .xl\:first\:-mx-full:first-child{
    margin-left:-100%;
    margin-right:-100%
  }

  .xl\:first\:my-0:first-child{
    margin-top:0;
    margin-bottom:0
  }

  .xl\:first\:my-1:first-child{
    margin-top:4px;
    margin-bottom:4px
  }

  .xl\:first\:my-2:first-child{
    margin-top:8px;
    margin-bottom:8px
  }

  .xl\:first\:my-3:first-child{
    margin-top:12px;
    margin-bottom:12px
  }

  .xl\:first\:my-4:first-child{
    margin-top:16px;
    margin-bottom:16px
  }

  .xl\:first\:my-5:first-child{
    margin-top:20px;
    margin-bottom:20px
  }

  .xl\:first\:my-6:first-child{
    margin-top:24px;
    margin-bottom:24px
  }

  .xl\:first\:my-8:first-child{
    margin-top:32px;
    margin-bottom:32px
  }

  .xl\:first\:my-10:first-child{
    margin-top:40px;
    margin-bottom:40px
  }

  .xl\:first\:my-12:first-child{
    margin-top:48px;
    margin-bottom:48px
  }

  .xl\:first\:my-16:first-child{
    margin-top:64px;
    margin-bottom:64px
  }

  .xl\:first\:my-18:first-child{
    margin-top:72px;
    margin-bottom:72px
  }

  .xl\:first\:my-24:first-child{
    margin-top:96px;
    margin-bottom:96px
  }

  .xl\:first\:my-auto:first-child{
    margin-top:auto;
    margin-bottom:auto
  }

  .xl\:first\:my-5dot5:first-child{
    margin-top:22px;
    margin-bottom:22px
  }

  .xl\:first\:my-full:first-child{
    margin-top:100%;
    margin-bottom:100%
  }

  .xl\:first\:-my-1:first-child{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .xl\:first\:-my-2:first-child{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .xl\:first\:-my-3:first-child{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .xl\:first\:-my-4:first-child{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .xl\:first\:-my-5:first-child{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .xl\:first\:-my-6:first-child{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .xl\:first\:-my-8:first-child{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .xl\:first\:-my-10:first-child{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .xl\:first\:-my-12:first-child{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .xl\:first\:-my-16:first-child{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .xl\:first\:-my-18:first-child{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .xl\:first\:-my-24:first-child{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .xl\:first\:-my-5dot5:first-child{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .xl\:first\:-my-full:first-child{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .xl\:last\:mx-0:last-child{
    margin-left:0;
    margin-right:0
  }

  .xl\:last\:mx-1:last-child{
    margin-left:4px;
    margin-right:4px
  }

  .xl\:last\:mx-2:last-child{
    margin-left:8px;
    margin-right:8px
  }

  .xl\:last\:mx-3:last-child{
    margin-left:12px;
    margin-right:12px
  }

  .xl\:last\:mx-4:last-child{
    margin-left:16px;
    margin-right:16px
  }

  .xl\:last\:mx-5:last-child{
    margin-left:20px;
    margin-right:20px
  }

  .xl\:last\:mx-6:last-child{
    margin-left:24px;
    margin-right:24px
  }

  .xl\:last\:mx-8:last-child{
    margin-left:32px;
    margin-right:32px
  }

  .xl\:last\:mx-10:last-child{
    margin-left:40px;
    margin-right:40px
  }

  .xl\:last\:mx-12:last-child{
    margin-left:48px;
    margin-right:48px
  }

  .xl\:last\:mx-16:last-child{
    margin-left:64px;
    margin-right:64px
  }

  .xl\:last\:mx-18:last-child{
    margin-left:72px;
    margin-right:72px
  }

  .xl\:last\:mx-24:last-child{
    margin-left:96px;
    margin-right:96px
  }

  .xl\:last\:mx-auto:last-child{
    margin-left:auto;
    margin-right:auto
  }

  .xl\:last\:mx-5dot5:last-child{
    margin-left:22px;
    margin-right:22px
  }

  .xl\:last\:mx-full:last-child{
    margin-left:100%;
    margin-right:100%
  }

  .xl\:last\:-mx-1:last-child{
    margin-left:-4px;
    margin-right:-4px
  }

  .xl\:last\:-mx-2:last-child{
    margin-left:-8px;
    margin-right:-8px
  }

  .xl\:last\:-mx-3:last-child{
    margin-left:-12px;
    margin-right:-12px
  }

  .xl\:last\:-mx-4:last-child{
    margin-left:-16px;
    margin-right:-16px
  }

  .xl\:last\:-mx-5:last-child{
    margin-left:-20px;
    margin-right:-20px
  }

  .xl\:last\:-mx-6:last-child{
    margin-left:-24px;
    margin-right:-24px
  }

  .xl\:last\:-mx-8:last-child{
    margin-left:-32px;
    margin-right:-32px
  }

  .xl\:last\:-mx-10:last-child{
    margin-left:-40px;
    margin-right:-40px
  }

  .xl\:last\:-mx-12:last-child{
    margin-left:-48px;
    margin-right:-48px
  }

  .xl\:last\:-mx-16:last-child{
    margin-left:-64px;
    margin-right:-64px
  }

  .xl\:last\:-mx-18:last-child{
    margin-left:-72px;
    margin-right:-72px
  }

  .xl\:last\:-mx-24:last-child{
    margin-left:-96px;
    margin-right:-96px
  }

  .xl\:last\:-mx-5dot5:last-child{
    margin-left:-22px;
    margin-right:-22px
  }

  .xl\:last\:-mx-full:last-child{
    margin-left:-100%;
    margin-right:-100%
  }

  .xl\:last\:my-0:last-child{
    margin-top:0;
    margin-bottom:0
  }

  .xl\:last\:my-1:last-child{
    margin-top:4px;
    margin-bottom:4px
  }

  .xl\:last\:my-2:last-child{
    margin-top:8px;
    margin-bottom:8px
  }

  .xl\:last\:my-3:last-child{
    margin-top:12px;
    margin-bottom:12px
  }

  .xl\:last\:my-4:last-child{
    margin-top:16px;
    margin-bottom:16px
  }

  .xl\:last\:my-5:last-child{
    margin-top:20px;
    margin-bottom:20px
  }

  .xl\:last\:my-6:last-child{
    margin-top:24px;
    margin-bottom:24px
  }

  .xl\:last\:my-8:last-child{
    margin-top:32px;
    margin-bottom:32px
  }

  .xl\:last\:my-10:last-child{
    margin-top:40px;
    margin-bottom:40px
  }

  .xl\:last\:my-12:last-child{
    margin-top:48px;
    margin-bottom:48px
  }

  .xl\:last\:my-16:last-child{
    margin-top:64px;
    margin-bottom:64px
  }

  .xl\:last\:my-18:last-child{
    margin-top:72px;
    margin-bottom:72px
  }

  .xl\:last\:my-24:last-child{
    margin-top:96px;
    margin-bottom:96px
  }

  .xl\:last\:my-auto:last-child{
    margin-top:auto;
    margin-bottom:auto
  }

  .xl\:last\:my-5dot5:last-child{
    margin-top:22px;
    margin-bottom:22px
  }

  .xl\:last\:my-full:last-child{
    margin-top:100%;
    margin-bottom:100%
  }

  .xl\:last\:-my-1:last-child{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .xl\:last\:-my-2:last-child{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .xl\:last\:-my-3:last-child{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .xl\:last\:-my-4:last-child{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .xl\:last\:-my-5:last-child{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .xl\:last\:-my-6:last-child{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .xl\:last\:-my-8:last-child{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .xl\:last\:-my-10:last-child{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .xl\:last\:-my-12:last-child{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .xl\:last\:-my-16:last-child{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .xl\:last\:-my-18:last-child{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .xl\:last\:-my-24:last-child{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .xl\:last\:-my-5dot5:last-child{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .xl\:last\:-my-full:last-child{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .xl\:mt-0{
    margin-top:0
  }

  .xl\:mt-1{
    margin-top:4px
  }

  .xl\:mt-2{
    margin-top:8px
  }

  .xl\:mt-3{
    margin-top:12px
  }

  .xl\:mt-4{
    margin-top:16px
  }

  .xl\:mt-5{
    margin-top:20px
  }

  .xl\:mt-6{
    margin-top:24px
  }

  .xl\:mt-8{
    margin-top:32px
  }

  .xl\:mt-10{
    margin-top:40px
  }

  .xl\:mt-12{
    margin-top:48px
  }

  .xl\:mt-16{
    margin-top:64px
  }

  .xl\:mt-18{
    margin-top:72px
  }

  .xl\:mt-24{
    margin-top:96px
  }

  .xl\:mt-auto{
    margin-top:auto
  }

  .xl\:mt-5dot5{
    margin-top:22px
  }

  .xl\:mt-full{
    margin-top:100%
  }

  .xl\:-mt-1{
    margin-top:-4px
  }

  .xl\:-mt-2{
    margin-top:-8px
  }

  .xl\:-mt-3{
    margin-top:-12px
  }

  .xl\:-mt-4{
    margin-top:-16px
  }

  .xl\:-mt-5{
    margin-top:-20px
  }

  .xl\:-mt-6{
    margin-top:-24px
  }

  .xl\:-mt-8{
    margin-top:-32px
  }

  .xl\:-mt-10{
    margin-top:-40px
  }

  .xl\:-mt-12{
    margin-top:-48px
  }

  .xl\:-mt-16{
    margin-top:-64px
  }

  .xl\:-mt-18{
    margin-top:-72px
  }

  .xl\:-mt-24{
    margin-top:-96px
  }

  .xl\:-mt-5dot5{
    margin-top:-22px
  }

  .xl\:-mt-full{
    margin-top:-100%
  }

  .xl\:mr-0{
    margin-right:0
  }

  .xl\:mr-1{
    margin-right:4px
  }

  .xl\:mr-2{
    margin-right:8px
  }

  .xl\:mr-3{
    margin-right:12px
  }

  .xl\:mr-4{
    margin-right:16px
  }

  .xl\:mr-5{
    margin-right:20px
  }

  .xl\:mr-6{
    margin-right:24px
  }

  .xl\:mr-8{
    margin-right:32px
  }

  .xl\:mr-10{
    margin-right:40px
  }

  .xl\:mr-12{
    margin-right:48px
  }

  .xl\:mr-16{
    margin-right:64px
  }

  .xl\:mr-18{
    margin-right:72px
  }

  .xl\:mr-24{
    margin-right:96px
  }

  .xl\:mr-auto{
    margin-right:auto
  }

  .xl\:mr-5dot5{
    margin-right:22px
  }

  .xl\:mr-full{
    margin-right:100%
  }

  .xl\:-mr-1{
    margin-right:-4px
  }

  .xl\:-mr-2{
    margin-right:-8px
  }

  .xl\:-mr-3{
    margin-right:-12px
  }

  .xl\:-mr-4{
    margin-right:-16px
  }

  .xl\:-mr-5{
    margin-right:-20px
  }

  .xl\:-mr-6{
    margin-right:-24px
  }

  .xl\:-mr-8{
    margin-right:-32px
  }

  .xl\:-mr-10{
    margin-right:-40px
  }

  .xl\:-mr-12{
    margin-right:-48px
  }

  .xl\:-mr-16{
    margin-right:-64px
  }

  .xl\:-mr-18{
    margin-right:-72px
  }

  .xl\:-mr-24{
    margin-right:-96px
  }

  .xl\:-mr-5dot5{
    margin-right:-22px
  }

  .xl\:-mr-full{
    margin-right:-100%
  }

  .xl\:mb-0{
    margin-bottom:0
  }

  .xl\:mb-1{
    margin-bottom:4px
  }

  .xl\:mb-2{
    margin-bottom:8px
  }

  .xl\:mb-3{
    margin-bottom:12px
  }

  .xl\:mb-4{
    margin-bottom:16px
  }

  .xl\:mb-5{
    margin-bottom:20px
  }

  .xl\:mb-6{
    margin-bottom:24px
  }

  .xl\:mb-8{
    margin-bottom:32px
  }

  .xl\:mb-10{
    margin-bottom:40px
  }

  .xl\:mb-12{
    margin-bottom:48px
  }

  .xl\:mb-16{
    margin-bottom:64px
  }

  .xl\:mb-18{
    margin-bottom:72px
  }

  .xl\:mb-24{
    margin-bottom:96px
  }

  .xl\:mb-auto{
    margin-bottom:auto
  }

  .xl\:mb-5dot5{
    margin-bottom:22px
  }

  .xl\:mb-full{
    margin-bottom:100%
  }

  .xl\:-mb-1{
    margin-bottom:-4px
  }

  .xl\:-mb-2{
    margin-bottom:-8px
  }

  .xl\:-mb-3{
    margin-bottom:-12px
  }

  .xl\:-mb-4{
    margin-bottom:-16px
  }

  .xl\:-mb-5{
    margin-bottom:-20px
  }

  .xl\:-mb-6{
    margin-bottom:-24px
  }

  .xl\:-mb-8{
    margin-bottom:-32px
  }

  .xl\:-mb-10{
    margin-bottom:-40px
  }

  .xl\:-mb-12{
    margin-bottom:-48px
  }

  .xl\:-mb-16{
    margin-bottom:-64px
  }

  .xl\:-mb-18{
    margin-bottom:-72px
  }

  .xl\:-mb-24{
    margin-bottom:-96px
  }

  .xl\:-mb-5dot5{
    margin-bottom:-22px
  }

  .xl\:-mb-full{
    margin-bottom:-100%
  }

  .xl\:ml-0{
    margin-left:0
  }

  .xl\:ml-1{
    margin-left:4px
  }

  .xl\:ml-2{
    margin-left:8px
  }

  .xl\:ml-3{
    margin-left:12px
  }

  .xl\:ml-4{
    margin-left:16px
  }

  .xl\:ml-5{
    margin-left:20px
  }

  .xl\:ml-6{
    margin-left:24px
  }

  .xl\:ml-8{
    margin-left:32px
  }

  .xl\:ml-10{
    margin-left:40px
  }

  .xl\:ml-12{
    margin-left:48px
  }

  .xl\:ml-16{
    margin-left:64px
  }

  .xl\:ml-18{
    margin-left:72px
  }

  .xl\:ml-24{
    margin-left:96px
  }

  .xl\:ml-auto{
    margin-left:auto
  }

  .xl\:ml-5dot5{
    margin-left:22px
  }

  .xl\:ml-full{
    margin-left:100%
  }

  .xl\:-ml-1{
    margin-left:-4px
  }

  .xl\:-ml-2{
    margin-left:-8px
  }

  .xl\:-ml-3{
    margin-left:-12px
  }

  .xl\:-ml-4{
    margin-left:-16px
  }

  .xl\:-ml-5{
    margin-left:-20px
  }

  .xl\:-ml-6{
    margin-left:-24px
  }

  .xl\:-ml-8{
    margin-left:-32px
  }

  .xl\:-ml-10{
    margin-left:-40px
  }

  .xl\:-ml-12{
    margin-left:-48px
  }

  .xl\:-ml-16{
    margin-left:-64px
  }

  .xl\:-ml-18{
    margin-left:-72px
  }

  .xl\:-ml-24{
    margin-left:-96px
  }

  .xl\:-ml-5dot5{
    margin-left:-22px
  }

  .xl\:-ml-full{
    margin-left:-100%
  }

  .xl\:first\:mt-0:first-child{
    margin-top:0
  }

  .xl\:first\:mt-1:first-child{
    margin-top:4px
  }

  .xl\:first\:mt-2:first-child{
    margin-top:8px
  }

  .xl\:first\:mt-3:first-child{
    margin-top:12px
  }

  .xl\:first\:mt-4:first-child{
    margin-top:16px
  }

  .xl\:first\:mt-5:first-child{
    margin-top:20px
  }

  .xl\:first\:mt-6:first-child{
    margin-top:24px
  }

  .xl\:first\:mt-8:first-child{
    margin-top:32px
  }

  .xl\:first\:mt-10:first-child{
    margin-top:40px
  }

  .xl\:first\:mt-12:first-child{
    margin-top:48px
  }

  .xl\:first\:mt-16:first-child{
    margin-top:64px
  }

  .xl\:first\:mt-18:first-child{
    margin-top:72px
  }

  .xl\:first\:mt-24:first-child{
    margin-top:96px
  }

  .xl\:first\:mt-auto:first-child{
    margin-top:auto
  }

  .xl\:first\:mt-5dot5:first-child{
    margin-top:22px
  }

  .xl\:first\:mt-full:first-child{
    margin-top:100%
  }

  .xl\:first\:-mt-1:first-child{
    margin-top:-4px
  }

  .xl\:first\:-mt-2:first-child{
    margin-top:-8px
  }

  .xl\:first\:-mt-3:first-child{
    margin-top:-12px
  }

  .xl\:first\:-mt-4:first-child{
    margin-top:-16px
  }

  .xl\:first\:-mt-5:first-child{
    margin-top:-20px
  }

  .xl\:first\:-mt-6:first-child{
    margin-top:-24px
  }

  .xl\:first\:-mt-8:first-child{
    margin-top:-32px
  }

  .xl\:first\:-mt-10:first-child{
    margin-top:-40px
  }

  .xl\:first\:-mt-12:first-child{
    margin-top:-48px
  }

  .xl\:first\:-mt-16:first-child{
    margin-top:-64px
  }

  .xl\:first\:-mt-18:first-child{
    margin-top:-72px
  }

  .xl\:first\:-mt-24:first-child{
    margin-top:-96px
  }

  .xl\:first\:-mt-5dot5:first-child{
    margin-top:-22px
  }

  .xl\:first\:-mt-full:first-child{
    margin-top:-100%
  }

  .xl\:first\:mr-0:first-child{
    margin-right:0
  }

  .xl\:first\:mr-1:first-child{
    margin-right:4px
  }

  .xl\:first\:mr-2:first-child{
    margin-right:8px
  }

  .xl\:first\:mr-3:first-child{
    margin-right:12px
  }

  .xl\:first\:mr-4:first-child{
    margin-right:16px
  }

  .xl\:first\:mr-5:first-child{
    margin-right:20px
  }

  .xl\:first\:mr-6:first-child{
    margin-right:24px
  }

  .xl\:first\:mr-8:first-child{
    margin-right:32px
  }

  .xl\:first\:mr-10:first-child{
    margin-right:40px
  }

  .xl\:first\:mr-12:first-child{
    margin-right:48px
  }

  .xl\:first\:mr-16:first-child{
    margin-right:64px
  }

  .xl\:first\:mr-18:first-child{
    margin-right:72px
  }

  .xl\:first\:mr-24:first-child{
    margin-right:96px
  }

  .xl\:first\:mr-auto:first-child{
    margin-right:auto
  }

  .xl\:first\:mr-5dot5:first-child{
    margin-right:22px
  }

  .xl\:first\:mr-full:first-child{
    margin-right:100%
  }

  .xl\:first\:-mr-1:first-child{
    margin-right:-4px
  }

  .xl\:first\:-mr-2:first-child{
    margin-right:-8px
  }

  .xl\:first\:-mr-3:first-child{
    margin-right:-12px
  }

  .xl\:first\:-mr-4:first-child{
    margin-right:-16px
  }

  .xl\:first\:-mr-5:first-child{
    margin-right:-20px
  }

  .xl\:first\:-mr-6:first-child{
    margin-right:-24px
  }

  .xl\:first\:-mr-8:first-child{
    margin-right:-32px
  }

  .xl\:first\:-mr-10:first-child{
    margin-right:-40px
  }

  .xl\:first\:-mr-12:first-child{
    margin-right:-48px
  }

  .xl\:first\:-mr-16:first-child{
    margin-right:-64px
  }

  .xl\:first\:-mr-18:first-child{
    margin-right:-72px
  }

  .xl\:first\:-mr-24:first-child{
    margin-right:-96px
  }

  .xl\:first\:-mr-5dot5:first-child{
    margin-right:-22px
  }

  .xl\:first\:-mr-full:first-child{
    margin-right:-100%
  }

  .xl\:first\:mb-0:first-child{
    margin-bottom:0
  }

  .xl\:first\:mb-1:first-child{
    margin-bottom:4px
  }

  .xl\:first\:mb-2:first-child{
    margin-bottom:8px
  }

  .xl\:first\:mb-3:first-child{
    margin-bottom:12px
  }

  .xl\:first\:mb-4:first-child{
    margin-bottom:16px
  }

  .xl\:first\:mb-5:first-child{
    margin-bottom:20px
  }

  .xl\:first\:mb-6:first-child{
    margin-bottom:24px
  }

  .xl\:first\:mb-8:first-child{
    margin-bottom:32px
  }

  .xl\:first\:mb-10:first-child{
    margin-bottom:40px
  }

  .xl\:first\:mb-12:first-child{
    margin-bottom:48px
  }

  .xl\:first\:mb-16:first-child{
    margin-bottom:64px
  }

  .xl\:first\:mb-18:first-child{
    margin-bottom:72px
  }

  .xl\:first\:mb-24:first-child{
    margin-bottom:96px
  }

  .xl\:first\:mb-auto:first-child{
    margin-bottom:auto
  }

  .xl\:first\:mb-5dot5:first-child{
    margin-bottom:22px
  }

  .xl\:first\:mb-full:first-child{
    margin-bottom:100%
  }

  .xl\:first\:-mb-1:first-child{
    margin-bottom:-4px
  }

  .xl\:first\:-mb-2:first-child{
    margin-bottom:-8px
  }

  .xl\:first\:-mb-3:first-child{
    margin-bottom:-12px
  }

  .xl\:first\:-mb-4:first-child{
    margin-bottom:-16px
  }

  .xl\:first\:-mb-5:first-child{
    margin-bottom:-20px
  }

  .xl\:first\:-mb-6:first-child{
    margin-bottom:-24px
  }

  .xl\:first\:-mb-8:first-child{
    margin-bottom:-32px
  }

  .xl\:first\:-mb-10:first-child{
    margin-bottom:-40px
  }

  .xl\:first\:-mb-12:first-child{
    margin-bottom:-48px
  }

  .xl\:first\:-mb-16:first-child{
    margin-bottom:-64px
  }

  .xl\:first\:-mb-18:first-child{
    margin-bottom:-72px
  }

  .xl\:first\:-mb-24:first-child{
    margin-bottom:-96px
  }

  .xl\:first\:-mb-5dot5:first-child{
    margin-bottom:-22px
  }

  .xl\:first\:-mb-full:first-child{
    margin-bottom:-100%
  }

  .xl\:first\:ml-0:first-child{
    margin-left:0
  }

  .xl\:first\:ml-1:first-child{
    margin-left:4px
  }

  .xl\:first\:ml-2:first-child{
    margin-left:8px
  }

  .xl\:first\:ml-3:first-child{
    margin-left:12px
  }

  .xl\:first\:ml-4:first-child{
    margin-left:16px
  }

  .xl\:first\:ml-5:first-child{
    margin-left:20px
  }

  .xl\:first\:ml-6:first-child{
    margin-left:24px
  }

  .xl\:first\:ml-8:first-child{
    margin-left:32px
  }

  .xl\:first\:ml-10:first-child{
    margin-left:40px
  }

  .xl\:first\:ml-12:first-child{
    margin-left:48px
  }

  .xl\:first\:ml-16:first-child{
    margin-left:64px
  }

  .xl\:first\:ml-18:first-child{
    margin-left:72px
  }

  .xl\:first\:ml-24:first-child{
    margin-left:96px
  }

  .xl\:first\:ml-auto:first-child{
    margin-left:auto
  }

  .xl\:first\:ml-5dot5:first-child{
    margin-left:22px
  }

  .xl\:first\:ml-full:first-child{
    margin-left:100%
  }

  .xl\:first\:-ml-1:first-child{
    margin-left:-4px
  }

  .xl\:first\:-ml-2:first-child{
    margin-left:-8px
  }

  .xl\:first\:-ml-3:first-child{
    margin-left:-12px
  }

  .xl\:first\:-ml-4:first-child{
    margin-left:-16px
  }

  .xl\:first\:-ml-5:first-child{
    margin-left:-20px
  }

  .xl\:first\:-ml-6:first-child{
    margin-left:-24px
  }

  .xl\:first\:-ml-8:first-child{
    margin-left:-32px
  }

  .xl\:first\:-ml-10:first-child{
    margin-left:-40px
  }

  .xl\:first\:-ml-12:first-child{
    margin-left:-48px
  }

  .xl\:first\:-ml-16:first-child{
    margin-left:-64px
  }

  .xl\:first\:-ml-18:first-child{
    margin-left:-72px
  }

  .xl\:first\:-ml-24:first-child{
    margin-left:-96px
  }

  .xl\:first\:-ml-5dot5:first-child{
    margin-left:-22px
  }

  .xl\:first\:-ml-full:first-child{
    margin-left:-100%
  }

  .xl\:last\:mt-0:last-child{
    margin-top:0
  }

  .xl\:last\:mt-1:last-child{
    margin-top:4px
  }

  .xl\:last\:mt-2:last-child{
    margin-top:8px
  }

  .xl\:last\:mt-3:last-child{
    margin-top:12px
  }

  .xl\:last\:mt-4:last-child{
    margin-top:16px
  }

  .xl\:last\:mt-5:last-child{
    margin-top:20px
  }

  .xl\:last\:mt-6:last-child{
    margin-top:24px
  }

  .xl\:last\:mt-8:last-child{
    margin-top:32px
  }

  .xl\:last\:mt-10:last-child{
    margin-top:40px
  }

  .xl\:last\:mt-12:last-child{
    margin-top:48px
  }

  .xl\:last\:mt-16:last-child{
    margin-top:64px
  }

  .xl\:last\:mt-18:last-child{
    margin-top:72px
  }

  .xl\:last\:mt-24:last-child{
    margin-top:96px
  }

  .xl\:last\:mt-auto:last-child{
    margin-top:auto
  }

  .xl\:last\:mt-5dot5:last-child{
    margin-top:22px
  }

  .xl\:last\:mt-full:last-child{
    margin-top:100%
  }

  .xl\:last\:-mt-1:last-child{
    margin-top:-4px
  }

  .xl\:last\:-mt-2:last-child{
    margin-top:-8px
  }

  .xl\:last\:-mt-3:last-child{
    margin-top:-12px
  }

  .xl\:last\:-mt-4:last-child{
    margin-top:-16px
  }

  .xl\:last\:-mt-5:last-child{
    margin-top:-20px
  }

  .xl\:last\:-mt-6:last-child{
    margin-top:-24px
  }

  .xl\:last\:-mt-8:last-child{
    margin-top:-32px
  }

  .xl\:last\:-mt-10:last-child{
    margin-top:-40px
  }

  .xl\:last\:-mt-12:last-child{
    margin-top:-48px
  }

  .xl\:last\:-mt-16:last-child{
    margin-top:-64px
  }

  .xl\:last\:-mt-18:last-child{
    margin-top:-72px
  }

  .xl\:last\:-mt-24:last-child{
    margin-top:-96px
  }

  .xl\:last\:-mt-5dot5:last-child{
    margin-top:-22px
  }

  .xl\:last\:-mt-full:last-child{
    margin-top:-100%
  }

  .xl\:last\:mr-0:last-child{
    margin-right:0
  }

  .xl\:last\:mr-1:last-child{
    margin-right:4px
  }

  .xl\:last\:mr-2:last-child{
    margin-right:8px
  }

  .xl\:last\:mr-3:last-child{
    margin-right:12px
  }

  .xl\:last\:mr-4:last-child{
    margin-right:16px
  }

  .xl\:last\:mr-5:last-child{
    margin-right:20px
  }

  .xl\:last\:mr-6:last-child{
    margin-right:24px
  }

  .xl\:last\:mr-8:last-child{
    margin-right:32px
  }

  .xl\:last\:mr-10:last-child{
    margin-right:40px
  }

  .xl\:last\:mr-12:last-child{
    margin-right:48px
  }

  .xl\:last\:mr-16:last-child{
    margin-right:64px
  }

  .xl\:last\:mr-18:last-child{
    margin-right:72px
  }

  .xl\:last\:mr-24:last-child{
    margin-right:96px
  }

  .xl\:last\:mr-auto:last-child{
    margin-right:auto
  }

  .xl\:last\:mr-5dot5:last-child{
    margin-right:22px
  }

  .xl\:last\:mr-full:last-child{
    margin-right:100%
  }

  .xl\:last\:-mr-1:last-child{
    margin-right:-4px
  }

  .xl\:last\:-mr-2:last-child{
    margin-right:-8px
  }

  .xl\:last\:-mr-3:last-child{
    margin-right:-12px
  }

  .xl\:last\:-mr-4:last-child{
    margin-right:-16px
  }

  .xl\:last\:-mr-5:last-child{
    margin-right:-20px
  }

  .xl\:last\:-mr-6:last-child{
    margin-right:-24px
  }

  .xl\:last\:-mr-8:last-child{
    margin-right:-32px
  }

  .xl\:last\:-mr-10:last-child{
    margin-right:-40px
  }

  .xl\:last\:-mr-12:last-child{
    margin-right:-48px
  }

  .xl\:last\:-mr-16:last-child{
    margin-right:-64px
  }

  .xl\:last\:-mr-18:last-child{
    margin-right:-72px
  }

  .xl\:last\:-mr-24:last-child{
    margin-right:-96px
  }

  .xl\:last\:-mr-5dot5:last-child{
    margin-right:-22px
  }

  .xl\:last\:-mr-full:last-child{
    margin-right:-100%
  }

  .xl\:last\:mb-0:last-child{
    margin-bottom:0
  }

  .xl\:last\:mb-1:last-child{
    margin-bottom:4px
  }

  .xl\:last\:mb-2:last-child{
    margin-bottom:8px
  }

  .xl\:last\:mb-3:last-child{
    margin-bottom:12px
  }

  .xl\:last\:mb-4:last-child{
    margin-bottom:16px
  }

  .xl\:last\:mb-5:last-child{
    margin-bottom:20px
  }

  .xl\:last\:mb-6:last-child{
    margin-bottom:24px
  }

  .xl\:last\:mb-8:last-child{
    margin-bottom:32px
  }

  .xl\:last\:mb-10:last-child{
    margin-bottom:40px
  }

  .xl\:last\:mb-12:last-child{
    margin-bottom:48px
  }

  .xl\:last\:mb-16:last-child{
    margin-bottom:64px
  }

  .xl\:last\:mb-18:last-child{
    margin-bottom:72px
  }

  .xl\:last\:mb-24:last-child{
    margin-bottom:96px
  }

  .xl\:last\:mb-auto:last-child{
    margin-bottom:auto
  }

  .xl\:last\:mb-5dot5:last-child{
    margin-bottom:22px
  }

  .xl\:last\:mb-full:last-child{
    margin-bottom:100%
  }

  .xl\:last\:-mb-1:last-child{
    margin-bottom:-4px
  }

  .xl\:last\:-mb-2:last-child{
    margin-bottom:-8px
  }

  .xl\:last\:-mb-3:last-child{
    margin-bottom:-12px
  }

  .xl\:last\:-mb-4:last-child{
    margin-bottom:-16px
  }

  .xl\:last\:-mb-5:last-child{
    margin-bottom:-20px
  }

  .xl\:last\:-mb-6:last-child{
    margin-bottom:-24px
  }

  .xl\:last\:-mb-8:last-child{
    margin-bottom:-32px
  }

  .xl\:last\:-mb-10:last-child{
    margin-bottom:-40px
  }

  .xl\:last\:-mb-12:last-child{
    margin-bottom:-48px
  }

  .xl\:last\:-mb-16:last-child{
    margin-bottom:-64px
  }

  .xl\:last\:-mb-18:last-child{
    margin-bottom:-72px
  }

  .xl\:last\:-mb-24:last-child{
    margin-bottom:-96px
  }

  .xl\:last\:-mb-5dot5:last-child{
    margin-bottom:-22px
  }

  .xl\:last\:-mb-full:last-child{
    margin-bottom:-100%
  }

  .xl\:last\:ml-0:last-child{
    margin-left:0
  }

  .xl\:last\:ml-1:last-child{
    margin-left:4px
  }

  .xl\:last\:ml-2:last-child{
    margin-left:8px
  }

  .xl\:last\:ml-3:last-child{
    margin-left:12px
  }

  .xl\:last\:ml-4:last-child{
    margin-left:16px
  }

  .xl\:last\:ml-5:last-child{
    margin-left:20px
  }

  .xl\:last\:ml-6:last-child{
    margin-left:24px
  }

  .xl\:last\:ml-8:last-child{
    margin-left:32px
  }

  .xl\:last\:ml-10:last-child{
    margin-left:40px
  }

  .xl\:last\:ml-12:last-child{
    margin-left:48px
  }

  .xl\:last\:ml-16:last-child{
    margin-left:64px
  }

  .xl\:last\:ml-18:last-child{
    margin-left:72px
  }

  .xl\:last\:ml-24:last-child{
    margin-left:96px
  }

  .xl\:last\:ml-auto:last-child{
    margin-left:auto
  }

  .xl\:last\:ml-5dot5:last-child{
    margin-left:22px
  }

  .xl\:last\:ml-full:last-child{
    margin-left:100%
  }

  .xl\:last\:-ml-1:last-child{
    margin-left:-4px
  }

  .xl\:last\:-ml-2:last-child{
    margin-left:-8px
  }

  .xl\:last\:-ml-3:last-child{
    margin-left:-12px
  }

  .xl\:last\:-ml-4:last-child{
    margin-left:-16px
  }

  .xl\:last\:-ml-5:last-child{
    margin-left:-20px
  }

  .xl\:last\:-ml-6:last-child{
    margin-left:-24px
  }

  .xl\:last\:-ml-8:last-child{
    margin-left:-32px
  }

  .xl\:last\:-ml-10:last-child{
    margin-left:-40px
  }

  .xl\:last\:-ml-12:last-child{
    margin-left:-48px
  }

  .xl\:last\:-ml-16:last-child{
    margin-left:-64px
  }

  .xl\:last\:-ml-18:last-child{
    margin-left:-72px
  }

  .xl\:last\:-ml-24:last-child{
    margin-left:-96px
  }

  .xl\:last\:-ml-5dot5:last-child{
    margin-left:-22px
  }

  .xl\:last\:-ml-full:last-child{
    margin-left:-100%
  }

  .xl\:block{
    display:block
  }

  .xl\:inline-block{
    display:inline-block
  }

  .xl\:inline{
    display:inline
  }

  .xl\:flex{
    display:flex
  }

  .xl\:inline-flex{
    display:inline-flex
  }

  .xl\:table{
    display:table
  }

  .xl\:inline-table{
    display:inline-table
  }

  .xl\:table-caption{
    display:table-caption
  }

  .xl\:table-cell{
    display:table-cell
  }

  .xl\:table-column{
    display:table-column
  }

  .xl\:table-column-group{
    display:table-column-group
  }

  .xl\:table-footer-group{
    display:table-footer-group
  }

  .xl\:table-header-group{
    display:table-header-group
  }

  .xl\:table-row-group{
    display:table-row-group
  }

  .xl\:table-row{
    display:table-row
  }

  .xl\:flow-root{
    display:flow-root
  }

  .xl\:grid{
    display:grid
  }

  .xl\:inline-grid{
    display:inline-grid
  }

  .xl\:contents{
    display:contents
  }

  .xl\:list-item{
    display:list-item
  }

  .xl\:hidden{
    display:none
  }

  .xl\:h-0{
    height:0
  }

  .xl\:h-1{
    height:4px
  }

  .xl\:h-2{
    height:8px
  }

  .xl\:h-3{
    height:12px
  }

  .xl\:h-4{
    height:16px
  }

  .xl\:h-5{
    height:20px
  }

  .xl\:h-6{
    height:24px
  }

  .xl\:h-8{
    height:32px
  }

  .xl\:h-10{
    height:40px
  }

  .xl\:h-12{
    height:48px
  }

  .xl\:h-15{
    height:60px
  }

  .xl\:h-16{
    height:64px
  }

  .xl\:h-18{
    height:72px
  }

  .xl\:h-24{
    height:96px
  }

  .xl\:h-auto{
    height:auto
  }

  .xl\:h-5dot5{
    height:22px
  }

  .xl\:h-full{
    height:100%
  }

  .xl\:h-1\/2{
    height:50%
  }

  .xl\:h-1\/3{
    height:33.333333%
  }

  .xl\:h-2\/3{
    height:66.666667%
  }

  .xl\:h-1\/4{
    height:25%
  }

  .xl\:h-2\/4{
    height:50%
  }

  .xl\:h-3\/4{
    height:75%
  }

  .xl\:h-1\/5{
    height:20%
  }

  .xl\:h-2\/5{
    height:40%
  }

  .xl\:h-3\/5{
    height:60%
  }

  .xl\:h-4\/5{
    height:80%
  }

  .xl\:h-1\/6{
    height:16.666667%
  }

  .xl\:h-2\/6{
    height:33.333333%
  }

  .xl\:h-3\/6{
    height:50%
  }

  .xl\:h-4\/6{
    height:66.666667%
  }

  .xl\:h-5\/6{
    height:83.333333%
  }

  .xl\:h-screen{
    height:100vh
  }

  .xl\:max-h-0{
    max-height:0
  }

  .xl\:max-h-1{
    max-height:4px
  }

  .xl\:max-h-2{
    max-height:8px
  }

  .xl\:max-h-3{
    max-height:12px
  }

  .xl\:max-h-4{
    max-height:16px
  }

  .xl\:max-h-5{
    max-height:20px
  }

  .xl\:max-h-6{
    max-height:24px
  }

  .xl\:max-h-8{
    max-height:32px
  }

  .xl\:max-h-10{
    max-height:40px
  }

  .xl\:max-h-12{
    max-height:48px
  }

  .xl\:max-h-16{
    max-height:64px
  }

  .xl\:max-h-18{
    max-height:72px
  }

  .xl\:max-h-24{
    max-height:96px
  }

  .xl\:max-h-5dot5{
    max-height:22px
  }

  .xl\:max-h-full{
    max-height:100%
  }

  .xl\:max-h-screen{
    max-height:100vh
  }

  .xl\:min-h-0{
    min-height:0px
  }

  .xl\:min-h-6{
    min-height:24px
  }

  .xl\:min-h-10{
    min-height:40px
  }

  .xl\:min-h-15{
    min-height:60px
  }

  .xl\:min-h-100{
    min-height:400px
  }

  .xl\:min-h-full{
    min-height:100%
  }

  .xl\:min-h-screen{
    min-height:100vh
  }

  .xl\:min-h-100vh{
    min-height:100vh
  }

  .xl\:w-0{
    width:0
  }

  .xl\:w-1{
    width:4px
  }

  .xl\:w-2{
    width:8px
  }

  .xl\:w-3{
    width:12px
  }

  .xl\:w-4{
    width:16px
  }

  .xl\:w-5{
    width:20px
  }

  .xl\:w-6{
    width:24px
  }

  .xl\:w-8{
    width:32px
  }

  .xl\:w-10{
    width:40px
  }

  .xl\:w-12{
    width:48px
  }

  .xl\:w-16{
    width:64px
  }

  .xl\:w-18{
    width:72px
  }

  .xl\:w-24{
    width:96px
  }

  .xl\:w-25{
    width:100px
  }

  .xl\:w-26{
    width:104px
  }

  .xl\:w-30{
    width:120px
  }

  .xl\:w-80{
    width:320px
  }

  .xl\:w-125{
    width:500px
  }

  .xl\:w-160{
    width:640px
  }

  .xl\:w-180{
    width:720px
  }

  .xl\:w-200{
    width:800px
  }

  .xl\:w-240{
    width:960px
  }

  .xl\:w-320{
    width:1280px
  }

  .xl\:w-auto{
    width:auto
  }

  .xl\:w-5dot5{
    width:22px
  }

  .xl\:w-full{
    width:100%
  }

  .xl\:w-1\/2{
    width:50%
  }

  .xl\:w-1\/3{
    width:33.333333%
  }

  .xl\:w-2\/3{
    width:66.666667%
  }

  .xl\:w-1\/4{
    width:25%
  }

  .xl\:w-2\/4{
    width:50%
  }

  .xl\:w-3\/4{
    width:75%
  }

  .xl\:w-1\/5{
    width:20%
  }

  .xl\:w-2\/5{
    width:40%
  }

  .xl\:w-3\/5{
    width:60%
  }

  .xl\:w-4\/5{
    width:80%
  }

  .xl\:w-1\/6{
    width:16.666667%
  }

  .xl\:w-2\/6{
    width:33.333333%
  }

  .xl\:w-3\/6{
    width:50%
  }

  .xl\:w-4\/6{
    width:66.666667%
  }

  .xl\:w-5\/6{
    width:83.333333%
  }

  .xl\:w-1\/12{
    width:8.333333%
  }

  .xl\:w-2\/12{
    width:16.666667%
  }

  .xl\:w-3\/12{
    width:25%
  }

  .xl\:w-4\/12{
    width:33.333333%
  }

  .xl\:w-5\/12{
    width:41.666667%
  }

  .xl\:w-6\/12{
    width:50%
  }

  .xl\:w-7\/12{
    width:58.333333%
  }

  .xl\:w-8\/12{
    width:66.666667%
  }

  .xl\:w-9\/12{
    width:75%
  }

  .xl\:w-10\/12{
    width:83.333333%
  }

  .xl\:w-11\/12{
    width:91.666667%
  }

  .xl\:w-screen{
    width:100vw
  }

  .xl\:w-min{
    width:min-content
  }

  .xl\:w-max{
    width:max-content
  }

  .xl\:min-w-0{
    min-width:0px
  }

  .xl\:min-w-full{
    min-width:100%
  }

  .xl\:min-w-min{
    min-width:min-content
  }

  .xl\:min-w-max{
    min-width:max-content
  }

  .xl\:max-w-0{
    max-width:0rem
  }

  .xl\:max-w-none{
    max-width:none
  }

  .xl\:max-w-xs{
    max-width:20rem
  }

  .xl\:max-w-sm{
    max-width:24rem
  }

  .xl\:max-w-md{
    max-width:28rem
  }

  .xl\:max-w-lg{
    max-width:32rem
  }

  .xl\:max-w-xl{
    max-width:36rem
  }

  .xl\:max-w-2xl{
    max-width:42rem
  }

  .xl\:max-w-3xl{
    max-width:48rem
  }

  .xl\:max-w-4xl{
    max-width:56rem
  }

  .xl\:max-w-5xl{
    max-width:64rem
  }

  .xl\:max-w-6xl{
    max-width:72rem
  }

  .xl\:max-w-7xl{
    max-width:80rem
  }

  .xl\:max-w-full{
    max-width:100%
  }

  .xl\:max-w-min{
    max-width:min-content
  }

  .xl\:max-w-max{
    max-width:max-content
  }

  .xl\:max-w-prose{
    max-width:65ch
  }

  .xl\:max-w-grid-12{
    max-width:1176px
  }

  .xl\:max-w-grid-10{
    max-width:984px
  }

  .xl\:max-w-grid-8{
    max-width:768px
  }

  .xl\:max-w-1\/4{
    max-width:25%
  }

  .xl\:max-w-1\/2{
    max-width:50%
  }

  .xl\:max-w-3\/4{
    max-width:75%
  }

  .xl\:max-w-1\/5{
    max-width:20%
  }

  .xl\:max-w-2\/5{
    max-width:40%
  }

  .xl\:max-w-3\/5{
    max-width:60%
  }

  .xl\:max-w-4\/5{
    max-width:80%
  }

  .xl\:max-w-100vw{
    max-width:100vw
  }

  .xl\:flex-1{
    flex:1 1 0%
  }

  .xl\:flex-2{
    flex:2 1 0%
  }

  .xl\:flex-3{
    flex:3 1 0%
  }

  .xl\:flex-auto{
    flex:1 1 auto
  }

  .xl\:flex-initial{
    flex:0 1 auto
  }

  .xl\:flex-none{
    flex:none
  }

  .xl\:flex-shrink-0{
    flex-shrink:0
  }

  .xl\:flex-shrink{
    flex-shrink:1
  }

  .xl\:flex-grow-0{
    flex-grow:0
  }

  .xl\:flex-grow{
    flex-grow:1
  }

  .xl\:border-collapse{
    border-collapse:collapse
  }

  .xl\:border-separate{
    border-collapse:separate
  }

  .xl\:transform{
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .xl\:transform-gpu{
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    transform:translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .xl\:transform-none{
    transform:none
  }

  .xl\:translate-x-0{
    --tw-translate-x:0
  }

  .xl\:translate-x-1{
    --tw-translate-x:4px
  }

  .xl\:translate-x-2{
    --tw-translate-x:8px
  }

  .xl\:translate-x-3{
    --tw-translate-x:12px
  }

  .xl\:translate-x-4{
    --tw-translate-x:16px
  }

  .xl\:translate-x-5{
    --tw-translate-x:20px
  }

  .xl\:translate-x-6{
    --tw-translate-x:24px
  }

  .xl\:translate-x-8{
    --tw-translate-x:32px
  }

  .xl\:translate-x-10{
    --tw-translate-x:40px
  }

  .xl\:translate-x-12{
    --tw-translate-x:48px
  }

  .xl\:translate-x-16{
    --tw-translate-x:64px
  }

  .xl\:translate-x-18{
    --tw-translate-x:72px
  }

  .xl\:translate-x-24{
    --tw-translate-x:96px
  }

  .xl\:translate-x-5dot5{
    --tw-translate-x:22px
  }

  .xl\:translate-x-full{
    --tw-translate-x:100%
  }

  .xl\:-translate-x-1{
    --tw-translate-x:-4px
  }

  .xl\:-translate-x-2{
    --tw-translate-x:-8px
  }

  .xl\:-translate-x-3{
    --tw-translate-x:-12px
  }

  .xl\:-translate-x-4{
    --tw-translate-x:-16px
  }

  .xl\:-translate-x-5{
    --tw-translate-x:-20px
  }

  .xl\:-translate-x-6{
    --tw-translate-x:-24px
  }

  .xl\:-translate-x-8{
    --tw-translate-x:-32px
  }

  .xl\:-translate-x-10{
    --tw-translate-x:-40px
  }

  .xl\:-translate-x-12{
    --tw-translate-x:-48px
  }

  .xl\:-translate-x-16{
    --tw-translate-x:-64px
  }

  .xl\:-translate-x-18{
    --tw-translate-x:-72px
  }

  .xl\:-translate-x-24{
    --tw-translate-x:-96px
  }

  .xl\:-translate-x-5dot5{
    --tw-translate-x:-22px
  }

  .xl\:-translate-x-full{
    --tw-translate-x:-100%
  }

  .xl\:translate-x-1\/2{
    --tw-translate-x:50%
  }

  .xl\:translate-x-1\/3{
    --tw-translate-x:33.333333%
  }

  .xl\:translate-x-2\/3{
    --tw-translate-x:66.666667%
  }

  .xl\:translate-x-1\/4{
    --tw-translate-x:25%
  }

  .xl\:translate-x-2\/4{
    --tw-translate-x:50%
  }

  .xl\:translate-x-3\/4{
    --tw-translate-x:75%
  }

  .xl\:-translate-x-1\/2{
    --tw-translate-x:-50%
  }

  .xl\:-translate-x-1\/3{
    --tw-translate-x:-33.333333%
  }

  .xl\:-translate-x-2\/3{
    --tw-translate-x:-66.666667%
  }

  .xl\:-translate-x-1\/4{
    --tw-translate-x:-25%
  }

  .xl\:-translate-x-2\/4{
    --tw-translate-x:-50%
  }

  .xl\:-translate-x-3\/4{
    --tw-translate-x:-75%
  }

  .xl\:translate-y-0{
    --tw-translate-y:0
  }

  .xl\:translate-y-1{
    --tw-translate-y:4px
  }

  .xl\:translate-y-2{
    --tw-translate-y:8px
  }

  .xl\:translate-y-3{
    --tw-translate-y:12px
  }

  .xl\:translate-y-4{
    --tw-translate-y:16px
  }

  .xl\:translate-y-5{
    --tw-translate-y:20px
  }

  .xl\:translate-y-6{
    --tw-translate-y:24px
  }

  .xl\:translate-y-8{
    --tw-translate-y:32px
  }

  .xl\:translate-y-10{
    --tw-translate-y:40px
  }

  .xl\:translate-y-12{
    --tw-translate-y:48px
  }

  .xl\:translate-y-16{
    --tw-translate-y:64px
  }

  .xl\:translate-y-18{
    --tw-translate-y:72px
  }

  .xl\:translate-y-24{
    --tw-translate-y:96px
  }

  .xl\:translate-y-5dot5{
    --tw-translate-y:22px
  }

  .xl\:translate-y-full{
    --tw-translate-y:100%
  }

  .xl\:-translate-y-1{
    --tw-translate-y:-4px
  }

  .xl\:-translate-y-2{
    --tw-translate-y:-8px
  }

  .xl\:-translate-y-3{
    --tw-translate-y:-12px
  }

  .xl\:-translate-y-4{
    --tw-translate-y:-16px
  }

  .xl\:-translate-y-5{
    --tw-translate-y:-20px
  }

  .xl\:-translate-y-6{
    --tw-translate-y:-24px
  }

  .xl\:-translate-y-8{
    --tw-translate-y:-32px
  }

  .xl\:-translate-y-10{
    --tw-translate-y:-40px
  }

  .xl\:-translate-y-12{
    --tw-translate-y:-48px
  }

  .xl\:-translate-y-16{
    --tw-translate-y:-64px
  }

  .xl\:-translate-y-18{
    --tw-translate-y:-72px
  }

  .xl\:-translate-y-24{
    --tw-translate-y:-96px
  }

  .xl\:-translate-y-5dot5{
    --tw-translate-y:-22px
  }

  .xl\:-translate-y-full{
    --tw-translate-y:-100%
  }

  .xl\:translate-y-1\/2{
    --tw-translate-y:50%
  }

  .xl\:translate-y-1\/3{
    --tw-translate-y:33.333333%
  }

  .xl\:translate-y-2\/3{
    --tw-translate-y:66.666667%
  }

  .xl\:translate-y-1\/4{
    --tw-translate-y:25%
  }

  .xl\:translate-y-2\/4{
    --tw-translate-y:50%
  }

  .xl\:translate-y-3\/4{
    --tw-translate-y:75%
  }

  .xl\:-translate-y-1\/2{
    --tw-translate-y:-50%
  }

  .xl\:-translate-y-1\/3{
    --tw-translate-y:-33.333333%
  }

  .xl\:-translate-y-2\/3{
    --tw-translate-y:-66.666667%
  }

  .xl\:-translate-y-1\/4{
    --tw-translate-y:-25%
  }

  .xl\:-translate-y-2\/4{
    --tw-translate-y:-50%
  }

  .xl\:-translate-y-3\/4{
    --tw-translate-y:-75%
  }

  .xl\:hover\:translate-x-0:hover{
    --tw-translate-x:0
  }

  .xl\:hover\:translate-x-1:hover{
    --tw-translate-x:4px
  }

  .xl\:hover\:translate-x-2:hover{
    --tw-translate-x:8px
  }

  .xl\:hover\:translate-x-3:hover{
    --tw-translate-x:12px
  }

  .xl\:hover\:translate-x-4:hover{
    --tw-translate-x:16px
  }

  .xl\:hover\:translate-x-5:hover{
    --tw-translate-x:20px
  }

  .xl\:hover\:translate-x-6:hover{
    --tw-translate-x:24px
  }

  .xl\:hover\:translate-x-8:hover{
    --tw-translate-x:32px
  }

  .xl\:hover\:translate-x-10:hover{
    --tw-translate-x:40px
  }

  .xl\:hover\:translate-x-12:hover{
    --tw-translate-x:48px
  }

  .xl\:hover\:translate-x-16:hover{
    --tw-translate-x:64px
  }

  .xl\:hover\:translate-x-18:hover{
    --tw-translate-x:72px
  }

  .xl\:hover\:translate-x-24:hover{
    --tw-translate-x:96px
  }

  .xl\:hover\:translate-x-5dot5:hover{
    --tw-translate-x:22px
  }

  .xl\:hover\:translate-x-full:hover{
    --tw-translate-x:100%
  }

  .xl\:hover\:-translate-x-1:hover{
    --tw-translate-x:-4px
  }

  .xl\:hover\:-translate-x-2:hover{
    --tw-translate-x:-8px
  }

  .xl\:hover\:-translate-x-3:hover{
    --tw-translate-x:-12px
  }

  .xl\:hover\:-translate-x-4:hover{
    --tw-translate-x:-16px
  }

  .xl\:hover\:-translate-x-5:hover{
    --tw-translate-x:-20px
  }

  .xl\:hover\:-translate-x-6:hover{
    --tw-translate-x:-24px
  }

  .xl\:hover\:-translate-x-8:hover{
    --tw-translate-x:-32px
  }

  .xl\:hover\:-translate-x-10:hover{
    --tw-translate-x:-40px
  }

  .xl\:hover\:-translate-x-12:hover{
    --tw-translate-x:-48px
  }

  .xl\:hover\:-translate-x-16:hover{
    --tw-translate-x:-64px
  }

  .xl\:hover\:-translate-x-18:hover{
    --tw-translate-x:-72px
  }

  .xl\:hover\:-translate-x-24:hover{
    --tw-translate-x:-96px
  }

  .xl\:hover\:-translate-x-5dot5:hover{
    --tw-translate-x:-22px
  }

  .xl\:hover\:-translate-x-full:hover{
    --tw-translate-x:-100%
  }

  .xl\:hover\:translate-x-1\/2:hover{
    --tw-translate-x:50%
  }

  .xl\:hover\:translate-x-1\/3:hover{
    --tw-translate-x:33.333333%
  }

  .xl\:hover\:translate-x-2\/3:hover{
    --tw-translate-x:66.666667%
  }

  .xl\:hover\:translate-x-1\/4:hover{
    --tw-translate-x:25%
  }

  .xl\:hover\:translate-x-2\/4:hover{
    --tw-translate-x:50%
  }

  .xl\:hover\:translate-x-3\/4:hover{
    --tw-translate-x:75%
  }

  .xl\:hover\:-translate-x-1\/2:hover{
    --tw-translate-x:-50%
  }

  .xl\:hover\:-translate-x-1\/3:hover{
    --tw-translate-x:-33.333333%
  }

  .xl\:hover\:-translate-x-2\/3:hover{
    --tw-translate-x:-66.666667%
  }

  .xl\:hover\:-translate-x-1\/4:hover{
    --tw-translate-x:-25%
  }

  .xl\:hover\:-translate-x-2\/4:hover{
    --tw-translate-x:-50%
  }

  .xl\:hover\:-translate-x-3\/4:hover{
    --tw-translate-x:-75%
  }

  .xl\:hover\:translate-y-0:hover{
    --tw-translate-y:0
  }

  .xl\:hover\:translate-y-1:hover{
    --tw-translate-y:4px
  }

  .xl\:hover\:translate-y-2:hover{
    --tw-translate-y:8px
  }

  .xl\:hover\:translate-y-3:hover{
    --tw-translate-y:12px
  }

  .xl\:hover\:translate-y-4:hover{
    --tw-translate-y:16px
  }

  .xl\:hover\:translate-y-5:hover{
    --tw-translate-y:20px
  }

  .xl\:hover\:translate-y-6:hover{
    --tw-translate-y:24px
  }

  .xl\:hover\:translate-y-8:hover{
    --tw-translate-y:32px
  }

  .xl\:hover\:translate-y-10:hover{
    --tw-translate-y:40px
  }

  .xl\:hover\:translate-y-12:hover{
    --tw-translate-y:48px
  }

  .xl\:hover\:translate-y-16:hover{
    --tw-translate-y:64px
  }

  .xl\:hover\:translate-y-18:hover{
    --tw-translate-y:72px
  }

  .xl\:hover\:translate-y-24:hover{
    --tw-translate-y:96px
  }

  .xl\:hover\:translate-y-5dot5:hover{
    --tw-translate-y:22px
  }

  .xl\:hover\:translate-y-full:hover{
    --tw-translate-y:100%
  }

  .xl\:hover\:-translate-y-1:hover{
    --tw-translate-y:-4px
  }

  .xl\:hover\:-translate-y-2:hover{
    --tw-translate-y:-8px
  }

  .xl\:hover\:-translate-y-3:hover{
    --tw-translate-y:-12px
  }

  .xl\:hover\:-translate-y-4:hover{
    --tw-translate-y:-16px
  }

  .xl\:hover\:-translate-y-5:hover{
    --tw-translate-y:-20px
  }

  .xl\:hover\:-translate-y-6:hover{
    --tw-translate-y:-24px
  }

  .xl\:hover\:-translate-y-8:hover{
    --tw-translate-y:-32px
  }

  .xl\:hover\:-translate-y-10:hover{
    --tw-translate-y:-40px
  }

  .xl\:hover\:-translate-y-12:hover{
    --tw-translate-y:-48px
  }

  .xl\:hover\:-translate-y-16:hover{
    --tw-translate-y:-64px
  }

  .xl\:hover\:-translate-y-18:hover{
    --tw-translate-y:-72px
  }

  .xl\:hover\:-translate-y-24:hover{
    --tw-translate-y:-96px
  }

  .xl\:hover\:-translate-y-5dot5:hover{
    --tw-translate-y:-22px
  }

  .xl\:hover\:-translate-y-full:hover{
    --tw-translate-y:-100%
  }

  .xl\:hover\:translate-y-1\/2:hover{
    --tw-translate-y:50%
  }

  .xl\:hover\:translate-y-1\/3:hover{
    --tw-translate-y:33.333333%
  }

  .xl\:hover\:translate-y-2\/3:hover{
    --tw-translate-y:66.666667%
  }

  .xl\:hover\:translate-y-1\/4:hover{
    --tw-translate-y:25%
  }

  .xl\:hover\:translate-y-2\/4:hover{
    --tw-translate-y:50%
  }

  .xl\:hover\:translate-y-3\/4:hover{
    --tw-translate-y:75%
  }

  .xl\:hover\:-translate-y-1\/2:hover{
    --tw-translate-y:-50%
  }

  .xl\:hover\:-translate-y-1\/3:hover{
    --tw-translate-y:-33.333333%
  }

  .xl\:hover\:-translate-y-2\/3:hover{
    --tw-translate-y:-66.666667%
  }

  .xl\:hover\:-translate-y-1\/4:hover{
    --tw-translate-y:-25%
  }

  .xl\:hover\:-translate-y-2\/4:hover{
    --tw-translate-y:-50%
  }

  .xl\:hover\:-translate-y-3\/4:hover{
    --tw-translate-y:-75%
  }

  .xl\:focus\:translate-x-0:focus{
    --tw-translate-x:0
  }

  .xl\:focus\:translate-x-1:focus{
    --tw-translate-x:4px
  }

  .xl\:focus\:translate-x-2:focus{
    --tw-translate-x:8px
  }

  .xl\:focus\:translate-x-3:focus{
    --tw-translate-x:12px
  }

  .xl\:focus\:translate-x-4:focus{
    --tw-translate-x:16px
  }

  .xl\:focus\:translate-x-5:focus{
    --tw-translate-x:20px
  }

  .xl\:focus\:translate-x-6:focus{
    --tw-translate-x:24px
  }

  .xl\:focus\:translate-x-8:focus{
    --tw-translate-x:32px
  }

  .xl\:focus\:translate-x-10:focus{
    --tw-translate-x:40px
  }

  .xl\:focus\:translate-x-12:focus{
    --tw-translate-x:48px
  }

  .xl\:focus\:translate-x-16:focus{
    --tw-translate-x:64px
  }

  .xl\:focus\:translate-x-18:focus{
    --tw-translate-x:72px
  }

  .xl\:focus\:translate-x-24:focus{
    --tw-translate-x:96px
  }

  .xl\:focus\:translate-x-5dot5:focus{
    --tw-translate-x:22px
  }

  .xl\:focus\:translate-x-full:focus{
    --tw-translate-x:100%
  }

  .xl\:focus\:-translate-x-1:focus{
    --tw-translate-x:-4px
  }

  .xl\:focus\:-translate-x-2:focus{
    --tw-translate-x:-8px
  }

  .xl\:focus\:-translate-x-3:focus{
    --tw-translate-x:-12px
  }

  .xl\:focus\:-translate-x-4:focus{
    --tw-translate-x:-16px
  }

  .xl\:focus\:-translate-x-5:focus{
    --tw-translate-x:-20px
  }

  .xl\:focus\:-translate-x-6:focus{
    --tw-translate-x:-24px
  }

  .xl\:focus\:-translate-x-8:focus{
    --tw-translate-x:-32px
  }

  .xl\:focus\:-translate-x-10:focus{
    --tw-translate-x:-40px
  }

  .xl\:focus\:-translate-x-12:focus{
    --tw-translate-x:-48px
  }

  .xl\:focus\:-translate-x-16:focus{
    --tw-translate-x:-64px
  }

  .xl\:focus\:-translate-x-18:focus{
    --tw-translate-x:-72px
  }

  .xl\:focus\:-translate-x-24:focus{
    --tw-translate-x:-96px
  }

  .xl\:focus\:-translate-x-5dot5:focus{
    --tw-translate-x:-22px
  }

  .xl\:focus\:-translate-x-full:focus{
    --tw-translate-x:-100%
  }

  .xl\:focus\:translate-x-1\/2:focus{
    --tw-translate-x:50%
  }

  .xl\:focus\:translate-x-1\/3:focus{
    --tw-translate-x:33.333333%
  }

  .xl\:focus\:translate-x-2\/3:focus{
    --tw-translate-x:66.666667%
  }

  .xl\:focus\:translate-x-1\/4:focus{
    --tw-translate-x:25%
  }

  .xl\:focus\:translate-x-2\/4:focus{
    --tw-translate-x:50%
  }

  .xl\:focus\:translate-x-3\/4:focus{
    --tw-translate-x:75%
  }

  .xl\:focus\:-translate-x-1\/2:focus{
    --tw-translate-x:-50%
  }

  .xl\:focus\:-translate-x-1\/3:focus{
    --tw-translate-x:-33.333333%
  }

  .xl\:focus\:-translate-x-2\/3:focus{
    --tw-translate-x:-66.666667%
  }

  .xl\:focus\:-translate-x-1\/4:focus{
    --tw-translate-x:-25%
  }

  .xl\:focus\:-translate-x-2\/4:focus{
    --tw-translate-x:-50%
  }

  .xl\:focus\:-translate-x-3\/4:focus{
    --tw-translate-x:-75%
  }

  .xl\:focus\:translate-y-0:focus{
    --tw-translate-y:0
  }

  .xl\:focus\:translate-y-1:focus{
    --tw-translate-y:4px
  }

  .xl\:focus\:translate-y-2:focus{
    --tw-translate-y:8px
  }

  .xl\:focus\:translate-y-3:focus{
    --tw-translate-y:12px
  }

  .xl\:focus\:translate-y-4:focus{
    --tw-translate-y:16px
  }

  .xl\:focus\:translate-y-5:focus{
    --tw-translate-y:20px
  }

  .xl\:focus\:translate-y-6:focus{
    --tw-translate-y:24px
  }

  .xl\:focus\:translate-y-8:focus{
    --tw-translate-y:32px
  }

  .xl\:focus\:translate-y-10:focus{
    --tw-translate-y:40px
  }

  .xl\:focus\:translate-y-12:focus{
    --tw-translate-y:48px
  }

  .xl\:focus\:translate-y-16:focus{
    --tw-translate-y:64px
  }

  .xl\:focus\:translate-y-18:focus{
    --tw-translate-y:72px
  }

  .xl\:focus\:translate-y-24:focus{
    --tw-translate-y:96px
  }

  .xl\:focus\:translate-y-5dot5:focus{
    --tw-translate-y:22px
  }

  .xl\:focus\:translate-y-full:focus{
    --tw-translate-y:100%
  }

  .xl\:focus\:-translate-y-1:focus{
    --tw-translate-y:-4px
  }

  .xl\:focus\:-translate-y-2:focus{
    --tw-translate-y:-8px
  }

  .xl\:focus\:-translate-y-3:focus{
    --tw-translate-y:-12px
  }

  .xl\:focus\:-translate-y-4:focus{
    --tw-translate-y:-16px
  }

  .xl\:focus\:-translate-y-5:focus{
    --tw-translate-y:-20px
  }

  .xl\:focus\:-translate-y-6:focus{
    --tw-translate-y:-24px
  }

  .xl\:focus\:-translate-y-8:focus{
    --tw-translate-y:-32px
  }

  .xl\:focus\:-translate-y-10:focus{
    --tw-translate-y:-40px
  }

  .xl\:focus\:-translate-y-12:focus{
    --tw-translate-y:-48px
  }

  .xl\:focus\:-translate-y-16:focus{
    --tw-translate-y:-64px
  }

  .xl\:focus\:-translate-y-18:focus{
    --tw-translate-y:-72px
  }

  .xl\:focus\:-translate-y-24:focus{
    --tw-translate-y:-96px
  }

  .xl\:focus\:-translate-y-5dot5:focus{
    --tw-translate-y:-22px
  }

  .xl\:focus\:-translate-y-full:focus{
    --tw-translate-y:-100%
  }

  .xl\:focus\:translate-y-1\/2:focus{
    --tw-translate-y:50%
  }

  .xl\:focus\:translate-y-1\/3:focus{
    --tw-translate-y:33.333333%
  }

  .xl\:focus\:translate-y-2\/3:focus{
    --tw-translate-y:66.666667%
  }

  .xl\:focus\:translate-y-1\/4:focus{
    --tw-translate-y:25%
  }

  .xl\:focus\:translate-y-2\/4:focus{
    --tw-translate-y:50%
  }

  .xl\:focus\:translate-y-3\/4:focus{
    --tw-translate-y:75%
  }

  .xl\:focus\:-translate-y-1\/2:focus{
    --tw-translate-y:-50%
  }

  .xl\:focus\:-translate-y-1\/3:focus{
    --tw-translate-y:-33.333333%
  }

  .xl\:focus\:-translate-y-2\/3:focus{
    --tw-translate-y:-66.666667%
  }

  .xl\:focus\:-translate-y-1\/4:focus{
    --tw-translate-y:-25%
  }

  .xl\:focus\:-translate-y-2\/4:focus{
    --tw-translate-y:-50%
  }

  .xl\:focus\:-translate-y-3\/4:focus{
    --tw-translate-y:-75%
  }

  .xl\:rotate-0{
    --tw-rotate:0deg
  }

  .xl\:rotate-1{
    --tw-rotate:1deg
  }

  .xl\:rotate-2{
    --tw-rotate:2deg
  }

  .xl\:rotate-3{
    --tw-rotate:3deg
  }

  .xl\:rotate-6{
    --tw-rotate:6deg
  }

  .xl\:rotate-12{
    --tw-rotate:12deg
  }

  .xl\:rotate-45{
    --tw-rotate:45deg
  }

  .xl\:rotate-90{
    --tw-rotate:90deg
  }

  .xl\:rotate-180{
    --tw-rotate:180deg
  }

  .xl\:-rotate-180{
    --tw-rotate:-180deg
  }

  .xl\:-rotate-90{
    --tw-rotate:-90deg
  }

  .xl\:-rotate-45{
    --tw-rotate:-45deg
  }

  .xl\:-rotate-12{
    --tw-rotate:-12deg
  }

  .xl\:-rotate-6{
    --tw-rotate:-6deg
  }

  .xl\:-rotate-3{
    --tw-rotate:-3deg
  }

  .xl\:-rotate-2{
    --tw-rotate:-2deg
  }

  .xl\:-rotate-1{
    --tw-rotate:-1deg
  }

  .xl\:hover\:rotate-0:hover{
    --tw-rotate:0deg
  }

  .xl\:hover\:rotate-1:hover{
    --tw-rotate:1deg
  }

  .xl\:hover\:rotate-2:hover{
    --tw-rotate:2deg
  }

  .xl\:hover\:rotate-3:hover{
    --tw-rotate:3deg
  }

  .xl\:hover\:rotate-6:hover{
    --tw-rotate:6deg
  }

  .xl\:hover\:rotate-12:hover{
    --tw-rotate:12deg
  }

  .xl\:hover\:rotate-45:hover{
    --tw-rotate:45deg
  }

  .xl\:hover\:rotate-90:hover{
    --tw-rotate:90deg
  }

  .xl\:hover\:rotate-180:hover{
    --tw-rotate:180deg
  }

  .xl\:hover\:-rotate-180:hover{
    --tw-rotate:-180deg
  }

  .xl\:hover\:-rotate-90:hover{
    --tw-rotate:-90deg
  }

  .xl\:hover\:-rotate-45:hover{
    --tw-rotate:-45deg
  }

  .xl\:hover\:-rotate-12:hover{
    --tw-rotate:-12deg
  }

  .xl\:hover\:-rotate-6:hover{
    --tw-rotate:-6deg
  }

  .xl\:hover\:-rotate-3:hover{
    --tw-rotate:-3deg
  }

  .xl\:hover\:-rotate-2:hover{
    --tw-rotate:-2deg
  }

  .xl\:hover\:-rotate-1:hover{
    --tw-rotate:-1deg
  }

  .xl\:focus\:rotate-0:focus{
    --tw-rotate:0deg
  }

  .xl\:focus\:rotate-1:focus{
    --tw-rotate:1deg
  }

  .xl\:focus\:rotate-2:focus{
    --tw-rotate:2deg
  }

  .xl\:focus\:rotate-3:focus{
    --tw-rotate:3deg
  }

  .xl\:focus\:rotate-6:focus{
    --tw-rotate:6deg
  }

  .xl\:focus\:rotate-12:focus{
    --tw-rotate:12deg
  }

  .xl\:focus\:rotate-45:focus{
    --tw-rotate:45deg
  }

  .xl\:focus\:rotate-90:focus{
    --tw-rotate:90deg
  }

  .xl\:focus\:rotate-180:focus{
    --tw-rotate:180deg
  }

  .xl\:focus\:-rotate-180:focus{
    --tw-rotate:-180deg
  }

  .xl\:focus\:-rotate-90:focus{
    --tw-rotate:-90deg
  }

  .xl\:focus\:-rotate-45:focus{
    --tw-rotate:-45deg
  }

  .xl\:focus\:-rotate-12:focus{
    --tw-rotate:-12deg
  }

  .xl\:focus\:-rotate-6:focus{
    --tw-rotate:-6deg
  }

  .xl\:focus\:-rotate-3:focus{
    --tw-rotate:-3deg
  }

  .xl\:focus\:-rotate-2:focus{
    --tw-rotate:-2deg
  }

  .xl\:focus\:-rotate-1:focus{
    --tw-rotate:-1deg
  }

  .xl\:animate-none{
    animation:none
  }

  .xl\:animate-spin{
    animation:spin 1s linear infinite
  }

  .xl\:animate-ping{
    animation:ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .xl\:animate-pulse{
    animation:pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .xl\:animate-bounce{
    animation:bounce 1s infinite
  }

  .xl\:animate-spin-delay{
    animation:spin-delay 2.4s ease infinite
  }

  .xl\:cursor-auto{
    cursor:auto
  }

  .xl\:cursor-default{
    cursor:default
  }

  .xl\:cursor-pointer{
    cursor:pointer
  }

  .xl\:cursor-wait{
    cursor:wait
  }

  .xl\:cursor-text{
    cursor:text
  }

  .xl\:cursor-move{
    cursor:move
  }

  .xl\:cursor-help{
    cursor:help
  }

  .xl\:cursor-not-allowed{
    cursor:not-allowed
  }

  .xl\:select-none{
    -webkit-user-select:none;
            user-select:none
  }

  .xl\:select-text{
    -webkit-user-select:text;
            user-select:text
  }

  .xl\:select-all{
    -webkit-user-select:all;
            user-select:all
  }

  .xl\:select-auto{
    -webkit-user-select:auto;
            user-select:auto
  }

  .xl\:auto-cols-auto{
    grid-auto-columns:auto
  }

  .xl\:auto-cols-min{
    grid-auto-columns:min-content
  }

  .xl\:auto-cols-max{
    grid-auto-columns:max-content
  }

  .xl\:auto-cols-fr{
    grid-auto-columns:minmax(0, 1fr)
  }

  .xl\:grid-flow-row{
    grid-auto-flow:row
  }

  .xl\:grid-flow-col{
    grid-auto-flow:column
  }

  .xl\:grid-flow-row-dense{
    grid-auto-flow:row dense
  }

  .xl\:grid-flow-col-dense{
    grid-auto-flow:column dense
  }

  .xl\:auto-rows-auto{
    grid-auto-rows:auto
  }

  .xl\:auto-rows-min{
    grid-auto-rows:min-content
  }

  .xl\:auto-rows-max{
    grid-auto-rows:max-content
  }

  .xl\:auto-rows-fr{
    grid-auto-rows:minmax(0, 1fr)
  }

  .xl\:grid-cols-1{
    grid-template-columns:repeat(1, minmax(0, 1fr))
  }

  .xl\:grid-cols-2{
    grid-template-columns:repeat(2, minmax(0, 1fr))
  }

  .xl\:grid-cols-3{
    grid-template-columns:repeat(3, minmax(0, 1fr))
  }

  .xl\:grid-cols-4{
    grid-template-columns:repeat(4, minmax(0, 1fr))
  }

  .xl\:grid-cols-5{
    grid-template-columns:repeat(5, minmax(0, 1fr))
  }

  .xl\:grid-cols-6{
    grid-template-columns:repeat(6, minmax(0, 1fr))
  }

  .xl\:grid-cols-7{
    grid-template-columns:repeat(7, minmax(0, 1fr))
  }

  .xl\:grid-cols-8{
    grid-template-columns:repeat(8, minmax(0, 1fr))
  }

  .xl\:grid-cols-9{
    grid-template-columns:repeat(9, minmax(0, 1fr))
  }

  .xl\:grid-cols-10{
    grid-template-columns:repeat(10, minmax(0, 1fr))
  }

  .xl\:grid-cols-11{
    grid-template-columns:repeat(11, minmax(0, 1fr))
  }

  .xl\:grid-cols-12{
    grid-template-columns:repeat(12, minmax(0, 1fr))
  }

  .xl\:grid-cols-none{
    grid-template-columns:none
  }

  .xl\:grid-rows-1{
    grid-template-rows:repeat(1, minmax(0, 1fr))
  }

  .xl\:grid-rows-2{
    grid-template-rows:repeat(2, minmax(0, 1fr))
  }

  .xl\:grid-rows-3{
    grid-template-rows:repeat(3, minmax(0, 1fr))
  }

  .xl\:grid-rows-4{
    grid-template-rows:repeat(4, minmax(0, 1fr))
  }

  .xl\:grid-rows-5{
    grid-template-rows:repeat(5, minmax(0, 1fr))
  }

  .xl\:grid-rows-6{
    grid-template-rows:repeat(6, minmax(0, 1fr))
  }

  .xl\:grid-rows-none{
    grid-template-rows:none
  }

  .xl\:flex-row{
    flex-direction:row
  }

  .xl\:flex-row-reverse{
    flex-direction:row-reverse
  }

  .xl\:flex-col{
    flex-direction:column
  }

  .xl\:flex-col-reverse{
    flex-direction:column-reverse
  }

  .xl\:flex-wrap{
    flex-wrap:wrap
  }

  .xl\:flex-wrap-reverse{
    flex-wrap:wrap-reverse
  }

  .xl\:flex-nowrap{
    flex-wrap:nowrap
  }

  .xl\:place-content-center{
    place-content:center
  }

  .xl\:place-content-start{
    place-content:start
  }

  .xl\:place-content-end{
    place-content:end
  }

  .xl\:place-content-between{
    place-content:space-between
  }

  .xl\:place-content-around{
    place-content:space-around
  }

  .xl\:place-content-evenly{
    place-content:space-evenly
  }

  .xl\:place-content-stretch{
    place-content:stretch
  }

  .xl\:place-items-start{
    place-items:start
  }

  .xl\:place-items-end{
    place-items:end
  }

  .xl\:place-items-center{
    place-items:center
  }

  .xl\:place-items-stretch{
    place-items:stretch
  }

  .xl\:content-center{
    align-content:center
  }

  .xl\:content-start{
    align-content:flex-start
  }

  .xl\:content-end{
    align-content:flex-end
  }

  .xl\:content-between{
    align-content:space-between
  }

  .xl\:content-around{
    align-content:space-around
  }

  .xl\:content-evenly{
    align-content:space-evenly
  }

  .xl\:items-start{
    align-items:flex-start
  }

  .xl\:items-end{
    align-items:flex-end
  }

  .xl\:items-center{
    align-items:center
  }

  .xl\:items-baseline{
    align-items:baseline
  }

  .xl\:items-stretch{
    align-items:stretch
  }

  .xl\:justify-start{
    justify-content:flex-start
  }

  .xl\:justify-end{
    justify-content:flex-end
  }

  .xl\:justify-center{
    justify-content:center
  }

  .xl\:justify-between{
    justify-content:space-between
  }

  .xl\:justify-around{
    justify-content:space-around
  }

  .xl\:justify-evenly{
    justify-content:space-evenly
  }

  .xl\:justify-items-start{
    justify-items:start
  }

  .xl\:justify-items-end{
    justify-items:end
  }

  .xl\:justify-items-center{
    justify-items:center
  }

  .xl\:justify-items-stretch{
    justify-items:stretch
  }

  .xl\:space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(0px * var(--tw-space-x-reverse));
    margin-left:calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(4px * var(--tw-space-x-reverse));
    margin-left:calc(4px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(8px * var(--tw-space-x-reverse));
    margin-left:calc(8px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(12px * var(--tw-space-x-reverse));
    margin-left:calc(12px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(16px * var(--tw-space-x-reverse));
    margin-left:calc(16px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(20px * var(--tw-space-x-reverse));
    margin-left:calc(20px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(24px * var(--tw-space-x-reverse));
    margin-left:calc(24px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(32px * var(--tw-space-x-reverse));
    margin-left:calc(32px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(40px * var(--tw-space-x-reverse));
    margin-left:calc(40px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(48px * var(--tw-space-x-reverse));
    margin-left:calc(48px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(64px * var(--tw-space-x-reverse));
    margin-left:calc(64px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(72px * var(--tw-space-x-reverse));
    margin-left:calc(72px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(96px * var(--tw-space-x-reverse));
    margin-left:calc(96px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(22px * var(--tw-space-x-reverse));
    margin-left:calc(22px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-x-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(100% * var(--tw-space-x-reverse));
    margin-left:calc(100% * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-4px * var(--tw-space-x-reverse));
    margin-left:calc(-4px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-8px * var(--tw-space-x-reverse));
    margin-left:calc(-8px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-12px * var(--tw-space-x-reverse));
    margin-left:calc(-12px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-16px * var(--tw-space-x-reverse));
    margin-left:calc(-16px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-20px * var(--tw-space-x-reverse));
    margin-left:calc(-20px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-24px * var(--tw-space-x-reverse));
    margin-left:calc(-24px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-32px * var(--tw-space-x-reverse));
    margin-left:calc(-32px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-40px * var(--tw-space-x-reverse));
    margin-left:calc(-40px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-48px * var(--tw-space-x-reverse));
    margin-left:calc(-48px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-64px * var(--tw-space-x-reverse));
    margin-left:calc(-64px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-72px * var(--tw-space-x-reverse));
    margin-left:calc(-72px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-96px * var(--tw-space-x-reverse));
    margin-left:calc(-96px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-22px * var(--tw-space-x-reverse));
    margin-left:calc(-22px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:-space-x-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-100% * var(--tw-space-x-reverse));
    margin-left:calc(-100% * calc(1 - var(--tw-space-x-reverse)))
  }

  .xl\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(4px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(4px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(8px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(12px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(16px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(20px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(24px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(32px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(40px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(48px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(48px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(64px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(64px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(72px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(72px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(96px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(96px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(22px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(22px * var(--tw-space-y-reverse))
  }

  .xl\:space-y-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(100% * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(100% * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-4px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-4px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-8px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-12px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-16px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-20px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-24px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-32px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-40px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-48px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-48px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-64px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-64px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-72px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-72px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-96px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-96px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-22px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-22px * var(--tw-space-y-reverse))
  }

  .xl\:-space-y-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-100% * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-100% * var(--tw-space-y-reverse))
  }

  .xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:1
  }

  .xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:1
  }

  .xl\:divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style:solid
  }

  .xl\:divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style:dashed
  }

  .xl\:divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style:dotted
  }

  .xl\:divide-double > :not([hidden]) ~ :not([hidden]){
    border-style:double
  }

  .xl\:divide-none > :not([hidden]) ~ :not([hidden]){
    border-style:none
  }

  .xl\:place-self-auto{
    place-self:auto
  }

  .xl\:place-self-start{
    place-self:start
  }

  .xl\:place-self-end{
    place-self:end
  }

  .xl\:place-self-center{
    place-self:center
  }

  .xl\:place-self-stretch{
    place-self:stretch
  }

  .xl\:self-auto{
    align-self:auto
  }

  .xl\:self-start{
    align-self:flex-start
  }

  .xl\:self-end{
    align-self:flex-end
  }

  .xl\:self-center{
    align-self:center
  }

  .xl\:self-stretch{
    align-self:stretch
  }

  .xl\:self-baseline{
    align-self:baseline
  }

  .xl\:justify-self-auto{
    justify-self:auto
  }

  .xl\:justify-self-start{
    justify-self:start
  }

  .xl\:justify-self-end{
    justify-self:end
  }

  .xl\:justify-self-center{
    justify-self:center
  }

  .xl\:justify-self-stretch{
    justify-self:stretch
  }

  .xl\:overflow-auto{
    overflow:auto
  }

  .xl\:overflow-hidden{
    overflow:hidden
  }

  .xl\:overflow-visible{
    overflow:visible
  }

  .xl\:overflow-scroll{
    overflow:scroll
  }

  .xl\:overflow-x-auto{
    overflow-x:auto
  }

  .xl\:overflow-y-auto{
    overflow-y:auto
  }

  .xl\:overflow-x-hidden{
    overflow-x:hidden
  }

  .xl\:overflow-y-hidden{
    overflow-y:hidden
  }

  .xl\:overflow-x-visible{
    overflow-x:visible
  }

  .xl\:overflow-y-visible{
    overflow-y:visible
  }

  .xl\:overflow-x-scroll{
    overflow-x:scroll
  }

  .xl\:overflow-y-scroll{
    overflow-y:scroll
  }

  .xl\:overscroll-auto{
    overscroll-behavior:auto
  }

  .xl\:overscroll-contain{
    overscroll-behavior:contain
  }

  .xl\:overscroll-none{
    overscroll-behavior:none
  }

  .xl\:overscroll-y-auto{
    overscroll-behavior-y:auto
  }

  .xl\:overscroll-y-contain{
    overscroll-behavior-y:contain
  }

  .xl\:overscroll-y-none{
    overscroll-behavior-y:none
  }

  .xl\:overscroll-x-auto{
    overscroll-behavior-x:auto
  }

  .xl\:overscroll-x-contain{
    overscroll-behavior-x:contain
  }

  .xl\:overscroll-x-none{
    overscroll-behavior-x:none
  }

  .xl\:truncate{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
  }

  .xl\:overflow-ellipsis{
    text-overflow:ellipsis
  }

  .xl\:overflow-clip{
    text-overflow:clip
  }

  .xl\:whitespace-normal{
    white-space:normal
  }

  .xl\:whitespace-nowrap{
    white-space:nowrap
  }

  .xl\:whitespace-pre{
    white-space:pre
  }

  .xl\:whitespace-pre-line{
    white-space:pre-line
  }

  .xl\:whitespace-pre-wrap{
    white-space:pre-wrap
  }

  .xl\:break-normal{
    overflow-wrap:normal;
    word-break:normal
  }

  .xl\:break-words{
    overflow-wrap:break-word
  }

  .xl\:break-all{
    word-break:break-all
  }

  .xl\:rounded-1{
    border-radius:4px
  }

  .xl\:rounded-2{
    border-radius:8px
  }

  .xl\:rounded-3{
    border-radius:12px
  }

  .xl\:rounded-4{
    border-radius:16px
  }

  .xl\:rounded-none{
    border-radius:0px
  }

  .xl\:rounded-sm{
    border-radius:0.125rem
  }

  .xl\:rounded{
    border-radius:0.25rem
  }

  .xl\:rounded-md{
    border-radius:0.375rem
  }

  .xl\:rounded-lg{
    border-radius:0.5rem
  }

  .xl\:rounded-xl{
    border-radius:0.75rem
  }

  .xl\:rounded-2xl{
    border-radius:1rem
  }

  .xl\:rounded-3xl{
    border-radius:1.5rem
  }

  .xl\:rounded-full{
    border-radius:9999px
  }

  .xl\:rounded-default{
    border-radius:4px
  }

  .xl\:rounded-t-1{
    border-top-left-radius:4px;
    border-top-right-radius:4px
  }

  .xl\:rounded-t-2{
    border-top-left-radius:8px;
    border-top-right-radius:8px
  }

  .xl\:rounded-t-3{
    border-top-left-radius:12px;
    border-top-right-radius:12px
  }

  .xl\:rounded-t-4{
    border-top-left-radius:16px;
    border-top-right-radius:16px
  }

  .xl\:rounded-t-none{
    border-top-left-radius:0px;
    border-top-right-radius:0px
  }

  .xl\:rounded-t-sm{
    border-top-left-radius:0.125rem;
    border-top-right-radius:0.125rem
  }

  .xl\:rounded-t{
    border-top-left-radius:0.25rem;
    border-top-right-radius:0.25rem
  }

  .xl\:rounded-t-md{
    border-top-left-radius:0.375rem;
    border-top-right-radius:0.375rem
  }

  .xl\:rounded-t-lg{
    border-top-left-radius:0.5rem;
    border-top-right-radius:0.5rem
  }

  .xl\:rounded-t-xl{
    border-top-left-radius:0.75rem;
    border-top-right-radius:0.75rem
  }

  .xl\:rounded-t-2xl{
    border-top-left-radius:1rem;
    border-top-right-radius:1rem
  }

  .xl\:rounded-t-3xl{
    border-top-left-radius:1.5rem;
    border-top-right-radius:1.5rem
  }

  .xl\:rounded-t-full{
    border-top-left-radius:9999px;
    border-top-right-radius:9999px
  }

  .xl\:rounded-t-default{
    border-top-left-radius:4px;
    border-top-right-radius:4px
  }

  .xl\:rounded-r-1{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
  }

  .xl\:rounded-r-2{
    border-top-right-radius:8px;
    border-bottom-right-radius:8px
  }

  .xl\:rounded-r-3{
    border-top-right-radius:12px;
    border-bottom-right-radius:12px
  }

  .xl\:rounded-r-4{
    border-top-right-radius:16px;
    border-bottom-right-radius:16px
  }

  .xl\:rounded-r-none{
    border-top-right-radius:0px;
    border-bottom-right-radius:0px
  }

  .xl\:rounded-r-sm{
    border-top-right-radius:0.125rem;
    border-bottom-right-radius:0.125rem
  }

  .xl\:rounded-r{
    border-top-right-radius:0.25rem;
    border-bottom-right-radius:0.25rem
  }

  .xl\:rounded-r-md{
    border-top-right-radius:0.375rem;
    border-bottom-right-radius:0.375rem
  }

  .xl\:rounded-r-lg{
    border-top-right-radius:0.5rem;
    border-bottom-right-radius:0.5rem
  }

  .xl\:rounded-r-xl{
    border-top-right-radius:0.75rem;
    border-bottom-right-radius:0.75rem
  }

  .xl\:rounded-r-2xl{
    border-top-right-radius:1rem;
    border-bottom-right-radius:1rem
  }

  .xl\:rounded-r-3xl{
    border-top-right-radius:1.5rem;
    border-bottom-right-radius:1.5rem
  }

  .xl\:rounded-r-full{
    border-top-right-radius:9999px;
    border-bottom-right-radius:9999px
  }

  .xl\:rounded-r-default{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
  }

  .xl\:rounded-b-1{
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px
  }

  .xl\:rounded-b-2{
    border-bottom-right-radius:8px;
    border-bottom-left-radius:8px
  }

  .xl\:rounded-b-3{
    border-bottom-right-radius:12px;
    border-bottom-left-radius:12px
  }

  .xl\:rounded-b-4{
    border-bottom-right-radius:16px;
    border-bottom-left-radius:16px
  }

  .xl\:rounded-b-none{
    border-bottom-right-radius:0px;
    border-bottom-left-radius:0px
  }

  .xl\:rounded-b-sm{
    border-bottom-right-radius:0.125rem;
    border-bottom-left-radius:0.125rem
  }

  .xl\:rounded-b{
    border-bottom-right-radius:0.25rem;
    border-bottom-left-radius:0.25rem
  }

  .xl\:rounded-b-md{
    border-bottom-right-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }

  .xl\:rounded-b-lg{
    border-bottom-right-radius:0.5rem;
    border-bottom-left-radius:0.5rem
  }

  .xl\:rounded-b-xl{
    border-bottom-right-radius:0.75rem;
    border-bottom-left-radius:0.75rem
  }

  .xl\:rounded-b-2xl{
    border-bottom-right-radius:1rem;
    border-bottom-left-radius:1rem
  }

  .xl\:rounded-b-3xl{
    border-bottom-right-radius:1.5rem;
    border-bottom-left-radius:1.5rem
  }

  .xl\:rounded-b-full{
    border-bottom-right-radius:9999px;
    border-bottom-left-radius:9999px
  }

  .xl\:rounded-b-default{
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px
  }

  .xl\:rounded-l-1{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
  }

  .xl\:rounded-l-2{
    border-top-left-radius:8px;
    border-bottom-left-radius:8px
  }

  .xl\:rounded-l-3{
    border-top-left-radius:12px;
    border-bottom-left-radius:12px
  }

  .xl\:rounded-l-4{
    border-top-left-radius:16px;
    border-bottom-left-radius:16px
  }

  .xl\:rounded-l-none{
    border-top-left-radius:0px;
    border-bottom-left-radius:0px
  }

  .xl\:rounded-l-sm{
    border-top-left-radius:0.125rem;
    border-bottom-left-radius:0.125rem
  }

  .xl\:rounded-l{
    border-top-left-radius:0.25rem;
    border-bottom-left-radius:0.25rem
  }

  .xl\:rounded-l-md{
    border-top-left-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }

  .xl\:rounded-l-lg{
    border-top-left-radius:0.5rem;
    border-bottom-left-radius:0.5rem
  }

  .xl\:rounded-l-xl{
    border-top-left-radius:0.75rem;
    border-bottom-left-radius:0.75rem
  }

  .xl\:rounded-l-2xl{
    border-top-left-radius:1rem;
    border-bottom-left-radius:1rem
  }

  .xl\:rounded-l-3xl{
    border-top-left-radius:1.5rem;
    border-bottom-left-radius:1.5rem
  }

  .xl\:rounded-l-full{
    border-top-left-radius:9999px;
    border-bottom-left-radius:9999px
  }

  .xl\:rounded-l-default{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
  }

  .xl\:rounded-tl-1{
    border-top-left-radius:4px
  }

  .xl\:rounded-tl-2{
    border-top-left-radius:8px
  }

  .xl\:rounded-tl-3{
    border-top-left-radius:12px
  }

  .xl\:rounded-tl-4{
    border-top-left-radius:16px
  }

  .xl\:rounded-tl-none{
    border-top-left-radius:0px
  }

  .xl\:rounded-tl-sm{
    border-top-left-radius:0.125rem
  }

  .xl\:rounded-tl{
    border-top-left-radius:0.25rem
  }

  .xl\:rounded-tl-md{
    border-top-left-radius:0.375rem
  }

  .xl\:rounded-tl-lg{
    border-top-left-radius:0.5rem
  }

  .xl\:rounded-tl-xl{
    border-top-left-radius:0.75rem
  }

  .xl\:rounded-tl-2xl{
    border-top-left-radius:1rem
  }

  .xl\:rounded-tl-3xl{
    border-top-left-radius:1.5rem
  }

  .xl\:rounded-tl-full{
    border-top-left-radius:9999px
  }

  .xl\:rounded-tl-default{
    border-top-left-radius:4px
  }

  .xl\:rounded-tr-1{
    border-top-right-radius:4px
  }

  .xl\:rounded-tr-2{
    border-top-right-radius:8px
  }

  .xl\:rounded-tr-3{
    border-top-right-radius:12px
  }

  .xl\:rounded-tr-4{
    border-top-right-radius:16px
  }

  .xl\:rounded-tr-none{
    border-top-right-radius:0px
  }

  .xl\:rounded-tr-sm{
    border-top-right-radius:0.125rem
  }

  .xl\:rounded-tr{
    border-top-right-radius:0.25rem
  }

  .xl\:rounded-tr-md{
    border-top-right-radius:0.375rem
  }

  .xl\:rounded-tr-lg{
    border-top-right-radius:0.5rem
  }

  .xl\:rounded-tr-xl{
    border-top-right-radius:0.75rem
  }

  .xl\:rounded-tr-2xl{
    border-top-right-radius:1rem
  }

  .xl\:rounded-tr-3xl{
    border-top-right-radius:1.5rem
  }

  .xl\:rounded-tr-full{
    border-top-right-radius:9999px
  }

  .xl\:rounded-tr-default{
    border-top-right-radius:4px
  }

  .xl\:rounded-br-1{
    border-bottom-right-radius:4px
  }

  .xl\:rounded-br-2{
    border-bottom-right-radius:8px
  }

  .xl\:rounded-br-3{
    border-bottom-right-radius:12px
  }

  .xl\:rounded-br-4{
    border-bottom-right-radius:16px
  }

  .xl\:rounded-br-none{
    border-bottom-right-radius:0px
  }

  .xl\:rounded-br-sm{
    border-bottom-right-radius:0.125rem
  }

  .xl\:rounded-br{
    border-bottom-right-radius:0.25rem
  }

  .xl\:rounded-br-md{
    border-bottom-right-radius:0.375rem
  }

  .xl\:rounded-br-lg{
    border-bottom-right-radius:0.5rem
  }

  .xl\:rounded-br-xl{
    border-bottom-right-radius:0.75rem
  }

  .xl\:rounded-br-2xl{
    border-bottom-right-radius:1rem
  }

  .xl\:rounded-br-3xl{
    border-bottom-right-radius:1.5rem
  }

  .xl\:rounded-br-full{
    border-bottom-right-radius:9999px
  }

  .xl\:rounded-br-default{
    border-bottom-right-radius:4px
  }

  .xl\:rounded-bl-1{
    border-bottom-left-radius:4px
  }

  .xl\:rounded-bl-2{
    border-bottom-left-radius:8px
  }

  .xl\:rounded-bl-3{
    border-bottom-left-radius:12px
  }

  .xl\:rounded-bl-4{
    border-bottom-left-radius:16px
  }

  .xl\:rounded-bl-none{
    border-bottom-left-radius:0px
  }

  .xl\:rounded-bl-sm{
    border-bottom-left-radius:0.125rem
  }

  .xl\:rounded-bl{
    border-bottom-left-radius:0.25rem
  }

  .xl\:rounded-bl-md{
    border-bottom-left-radius:0.375rem
  }

  .xl\:rounded-bl-lg{
    border-bottom-left-radius:0.5rem
  }

  .xl\:rounded-bl-xl{
    border-bottom-left-radius:0.75rem
  }

  .xl\:rounded-bl-2xl{
    border-bottom-left-radius:1rem
  }

  .xl\:rounded-bl-3xl{
    border-bottom-left-radius:1.5rem
  }

  .xl\:rounded-bl-full{
    border-bottom-left-radius:9999px
  }

  .xl\:rounded-bl-default{
    border-bottom-left-radius:4px
  }

  .xl\:border-0{
    border-width:0px
  }

  .xl\:border-2{
    border-width:2px
  }

  .xl\:border-4{
    border-width:4px
  }

  .xl\:border-8{
    border-width:8px
  }

  .xl\:border{
    border-width:1px
  }

  .xl\:border-t-0{
    border-top-width:0px
  }

  .xl\:border-t-2{
    border-top-width:2px
  }

  .xl\:border-t-4{
    border-top-width:4px
  }

  .xl\:border-t-8{
    border-top-width:8px
  }

  .xl\:border-t{
    border-top-width:1px
  }

  .xl\:border-r-0{
    border-right-width:0px
  }

  .xl\:border-r-2{
    border-right-width:2px
  }

  .xl\:border-r-4{
    border-right-width:4px
  }

  .xl\:border-r-8{
    border-right-width:8px
  }

  .xl\:border-r{
    border-right-width:1px
  }

  .xl\:border-b-0{
    border-bottom-width:0px
  }

  .xl\:border-b-2{
    border-bottom-width:2px
  }

  .xl\:border-b-4{
    border-bottom-width:4px
  }

  .xl\:border-b-8{
    border-bottom-width:8px
  }

  .xl\:border-b{
    border-bottom-width:1px
  }

  .xl\:border-l-0{
    border-left-width:0px
  }

  .xl\:border-l-2{
    border-left-width:2px
  }

  .xl\:border-l-4{
    border-left-width:4px
  }

  .xl\:border-l-8{
    border-left-width:8px
  }

  .xl\:border-l{
    border-left-width:1px
  }

  .xl\:border-solid{
    border-style:solid
  }

  .xl\:border-dashed{
    border-style:dashed
  }

  .xl\:border-dotted{
    border-style:dotted
  }

  .xl\:border-double{
    border-style:double
  }

  .xl\:border-none{
    border-style:none
  }

  .xl\:border-red1{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .xl\:border-red2{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .xl\:border-red3{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .xl\:border-red4{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .xl\:border-red5{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .xl\:border-cyan1{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .xl\:border-cyan2{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .xl\:border-cyan3{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .xl\:border-cyan4{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .xl\:border-cyan5{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .xl\:border-bg1{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .xl\:border-bg2{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:border-bg3{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .xl\:border-gray1{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .xl\:border-gray2{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .xl\:border-gray3{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .xl\:border-gray4{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .xl\:border-gray5{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .xl\:border-gray6{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .xl\:border-gray7{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xl\:border-white{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:border-warning{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .xl\:border-error{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .xl\:border-info{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .xl\:border-success{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .xl\:border-special4{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .xl\:border-dark{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .xl\:border-transparent{
    border-color:transparent
  }

  .xl\:border-secondary1{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .xl\:border-secondary2{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xl\:border-secondary3{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .xl\:border-secondary4{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .xl\:border-secondary5{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .xl\:border-green1{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red1:hover{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red2:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red3:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red4:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .xl\:hover\:border-red5:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan1:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan2:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan3:hover{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan4:hover{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .xl\:hover\:border-cyan5:hover{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .xl\:hover\:border-bg1:hover{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .xl\:hover\:border-bg2:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-bg3:hover{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray1:hover{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray2:hover{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray3:hover{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray4:hover{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray5:hover{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray6:hover{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .xl\:hover\:border-gray7:hover{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xl\:hover\:border-white:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:hover\:border-warning:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .xl\:hover\:border-error:hover{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .xl\:hover\:border-info:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .xl\:hover\:border-success:hover{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .xl\:hover\:border-special4:hover{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .xl\:hover\:border-dark:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .xl\:hover\:border-transparent:hover{
    border-color:transparent
  }

  .xl\:hover\:border-secondary1:hover{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .xl\:hover\:border-secondary2:hover{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xl\:hover\:border-secondary3:hover{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .xl\:hover\:border-secondary4:hover{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .xl\:hover\:border-secondary5:hover{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .xl\:hover\:border-green1:hover{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red1:focus{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red2:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red3:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red4:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .xl\:focus\:border-red5:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan1:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan2:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan3:focus{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan4:focus{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .xl\:focus\:border-cyan5:focus{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .xl\:focus\:border-bg1:focus{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .xl\:focus\:border-bg2:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-bg3:focus{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray1:focus{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray2:focus{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray3:focus{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray4:focus{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray5:focus{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray6:focus{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .xl\:focus\:border-gray7:focus{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xl\:focus\:border-white:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:focus\:border-warning:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .xl\:focus\:border-error:focus{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .xl\:focus\:border-info:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .xl\:focus\:border-success:focus{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .xl\:focus\:border-special4:focus{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .xl\:focus\:border-dark:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .xl\:focus\:border-transparent:focus{
    border-color:transparent
  }

  .xl\:focus\:border-secondary1:focus{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .xl\:focus\:border-secondary2:focus{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xl\:focus\:border-secondary3:focus{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .xl\:focus\:border-secondary4:focus{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .xl\:focus\:border-secondary5:focus{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .xl\:focus\:border-green1:focus{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .xl\:active\:border-red1:active{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .xl\:active\:border-red2:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .xl\:active\:border-red3:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .xl\:active\:border-red4:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .xl\:active\:border-red5:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .xl\:active\:border-cyan1:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .xl\:active\:border-cyan2:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .xl\:active\:border-cyan3:active{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .xl\:active\:border-cyan4:active{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .xl\:active\:border-cyan5:active{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .xl\:active\:border-bg1:active{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .xl\:active\:border-bg2:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:active\:border-bg3:active{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .xl\:active\:border-gray1:active{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .xl\:active\:border-gray2:active{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .xl\:active\:border-gray3:active{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .xl\:active\:border-gray4:active{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .xl\:active\:border-gray5:active{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .xl\:active\:border-gray6:active{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .xl\:active\:border-gray7:active{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xl\:active\:border-white:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xl\:active\:border-warning:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .xl\:active\:border-error:active{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .xl\:active\:border-info:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .xl\:active\:border-success:active{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .xl\:active\:border-special4:active{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .xl\:active\:border-dark:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .xl\:active\:border-transparent:active{
    border-color:transparent
  }

  .xl\:active\:border-secondary1:active{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .xl\:active\:border-secondary2:active{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xl\:active\:border-secondary3:active{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .xl\:active\:border-secondary4:active{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .xl\:active\:border-secondary5:active{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .xl\:active\:border-green1:active{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .xl\:border-opacity-0{
    --tw-border-opacity:0
  }

  .xl\:border-opacity-5{
    --tw-border-opacity:0.05
  }

  .xl\:border-opacity-10{
    --tw-border-opacity:0.1
  }

  .xl\:border-opacity-20{
    --tw-border-opacity:0.2
  }

  .xl\:border-opacity-25{
    --tw-border-opacity:0.25
  }

  .xl\:border-opacity-30{
    --tw-border-opacity:0.3
  }

  .xl\:border-opacity-40{
    --tw-border-opacity:0.4
  }

  .xl\:border-opacity-50{
    --tw-border-opacity:0.5
  }

  .xl\:border-opacity-60{
    --tw-border-opacity:0.6
  }

  .xl\:border-opacity-70{
    --tw-border-opacity:0.7
  }

  .xl\:border-opacity-75{
    --tw-border-opacity:0.75
  }

  .xl\:border-opacity-80{
    --tw-border-opacity:0.8
  }

  .xl\:border-opacity-90{
    --tw-border-opacity:0.9
  }

  .xl\:border-opacity-95{
    --tw-border-opacity:0.95
  }

  .xl\:border-opacity-100{
    --tw-border-opacity:1
  }

  .group:hover .xl\:group-hover\:border-opacity-0{
    --tw-border-opacity:0
  }

  .group:hover .xl\:group-hover\:border-opacity-5{
    --tw-border-opacity:0.05
  }

  .group:hover .xl\:group-hover\:border-opacity-10{
    --tw-border-opacity:0.1
  }

  .group:hover .xl\:group-hover\:border-opacity-20{
    --tw-border-opacity:0.2
  }

  .group:hover .xl\:group-hover\:border-opacity-25{
    --tw-border-opacity:0.25
  }

  .group:hover .xl\:group-hover\:border-opacity-30{
    --tw-border-opacity:0.3
  }

  .group:hover .xl\:group-hover\:border-opacity-40{
    --tw-border-opacity:0.4
  }

  .group:hover .xl\:group-hover\:border-opacity-50{
    --tw-border-opacity:0.5
  }

  .group:hover .xl\:group-hover\:border-opacity-60{
    --tw-border-opacity:0.6
  }

  .group:hover .xl\:group-hover\:border-opacity-70{
    --tw-border-opacity:0.7
  }

  .group:hover .xl\:group-hover\:border-opacity-75{
    --tw-border-opacity:0.75
  }

  .group:hover .xl\:group-hover\:border-opacity-80{
    --tw-border-opacity:0.8
  }

  .group:hover .xl\:group-hover\:border-opacity-90{
    --tw-border-opacity:0.9
  }

  .group:hover .xl\:group-hover\:border-opacity-95{
    --tw-border-opacity:0.95
  }

  .group:hover .xl\:group-hover\:border-opacity-100{
    --tw-border-opacity:1
  }

  .xl\:focus-within\:border-opacity-0:focus-within{
    --tw-border-opacity:0
  }

  .xl\:focus-within\:border-opacity-5:focus-within{
    --tw-border-opacity:0.05
  }

  .xl\:focus-within\:border-opacity-10:focus-within{
    --tw-border-opacity:0.1
  }

  .xl\:focus-within\:border-opacity-20:focus-within{
    --tw-border-opacity:0.2
  }

  .xl\:focus-within\:border-opacity-25:focus-within{
    --tw-border-opacity:0.25
  }

  .xl\:focus-within\:border-opacity-30:focus-within{
    --tw-border-opacity:0.3
  }

  .xl\:focus-within\:border-opacity-40:focus-within{
    --tw-border-opacity:0.4
  }

  .xl\:focus-within\:border-opacity-50:focus-within{
    --tw-border-opacity:0.5
  }

  .xl\:focus-within\:border-opacity-60:focus-within{
    --tw-border-opacity:0.6
  }

  .xl\:focus-within\:border-opacity-70:focus-within{
    --tw-border-opacity:0.7
  }

  .xl\:focus-within\:border-opacity-75:focus-within{
    --tw-border-opacity:0.75
  }

  .xl\:focus-within\:border-opacity-80:focus-within{
    --tw-border-opacity:0.8
  }

  .xl\:focus-within\:border-opacity-90:focus-within{
    --tw-border-opacity:0.9
  }

  .xl\:focus-within\:border-opacity-95:focus-within{
    --tw-border-opacity:0.95
  }

  .xl\:focus-within\:border-opacity-100:focus-within{
    --tw-border-opacity:1
  }

  .xl\:hover\:border-opacity-0:hover{
    --tw-border-opacity:0
  }

  .xl\:hover\:border-opacity-5:hover{
    --tw-border-opacity:0.05
  }

  .xl\:hover\:border-opacity-10:hover{
    --tw-border-opacity:0.1
  }

  .xl\:hover\:border-opacity-20:hover{
    --tw-border-opacity:0.2
  }

  .xl\:hover\:border-opacity-25:hover{
    --tw-border-opacity:0.25
  }

  .xl\:hover\:border-opacity-30:hover{
    --tw-border-opacity:0.3
  }

  .xl\:hover\:border-opacity-40:hover{
    --tw-border-opacity:0.4
  }

  .xl\:hover\:border-opacity-50:hover{
    --tw-border-opacity:0.5
  }

  .xl\:hover\:border-opacity-60:hover{
    --tw-border-opacity:0.6
  }

  .xl\:hover\:border-opacity-70:hover{
    --tw-border-opacity:0.7
  }

  .xl\:hover\:border-opacity-75:hover{
    --tw-border-opacity:0.75
  }

  .xl\:hover\:border-opacity-80:hover{
    --tw-border-opacity:0.8
  }

  .xl\:hover\:border-opacity-90:hover{
    --tw-border-opacity:0.9
  }

  .xl\:hover\:border-opacity-95:hover{
    --tw-border-opacity:0.95
  }

  .xl\:hover\:border-opacity-100:hover{
    --tw-border-opacity:1
  }

  .xl\:focus\:border-opacity-0:focus{
    --tw-border-opacity:0
  }

  .xl\:focus\:border-opacity-5:focus{
    --tw-border-opacity:0.05
  }

  .xl\:focus\:border-opacity-10:focus{
    --tw-border-opacity:0.1
  }

  .xl\:focus\:border-opacity-20:focus{
    --tw-border-opacity:0.2
  }

  .xl\:focus\:border-opacity-25:focus{
    --tw-border-opacity:0.25
  }

  .xl\:focus\:border-opacity-30:focus{
    --tw-border-opacity:0.3
  }

  .xl\:focus\:border-opacity-40:focus{
    --tw-border-opacity:0.4
  }

  .xl\:focus\:border-opacity-50:focus{
    --tw-border-opacity:0.5
  }

  .xl\:focus\:border-opacity-60:focus{
    --tw-border-opacity:0.6
  }

  .xl\:focus\:border-opacity-70:focus{
    --tw-border-opacity:0.7
  }

  .xl\:focus\:border-opacity-75:focus{
    --tw-border-opacity:0.75
  }

  .xl\:focus\:border-opacity-80:focus{
    --tw-border-opacity:0.8
  }

  .xl\:focus\:border-opacity-90:focus{
    --tw-border-opacity:0.9
  }

  .xl\:focus\:border-opacity-95:focus{
    --tw-border-opacity:0.95
  }

  .xl\:focus\:border-opacity-100:focus{
    --tw-border-opacity:1
  }

  .xl\:bg-red1{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .xl\:bg-red2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .xl\:bg-red3{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .xl\:bg-red4{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .xl\:bg-red5{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan1{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan2{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan3{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan4{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .xl\:bg-cyan5{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:bg-bg1{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .xl\:bg-bg2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-bg3{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .xl\:bg-gray1{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .xl\:bg-gray2{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .xl\:bg-gray3{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .xl\:bg-gray4{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .xl\:bg-gray5{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .xl\:bg-gray6{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .xl\:bg-gray7{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xl\:bg-white{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:bg-warning{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .xl\:bg-error{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .xl\:bg-info{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .xl\:bg-success{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .xl\:bg-special4{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .xl\:bg-dark{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .xl\:bg-transparent{
    background-color:transparent
  }

  .xl\:bg-secondary1{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .xl\:bg-secondary2{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xl\:bg-secondary3{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .xl\:bg-secondary4{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .xl\:bg-secondary5{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .xl\:bg-green1{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-red5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-cyan5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-bg1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-bg2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-bg3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray6:hover{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-gray7:hover{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-white:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-warning:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-error:hover{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-info:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-success:hover{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-special4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-dark:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-transparent:hover{
    background-color:transparent
  }

  .xl\:hover\:bg-secondary1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-secondary2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-secondary3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-secondary4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-secondary5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .xl\:hover\:bg-green1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-red5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-cyan5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-bg1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-bg2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-bg3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray6:focus{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-gray7:focus{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-white:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-warning:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-error:focus{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-info:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-success:focus{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-special4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-dark:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-transparent:focus{
    background-color:transparent
  }

  .xl\:focus\:bg-secondary1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-secondary2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-secondary3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-secondary4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-secondary5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .xl\:focus\:bg-green1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-red1:active{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-red2:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-red3:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-red4:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-red5:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-cyan1:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-cyan2:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-cyan3:active{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-cyan4:active{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-cyan5:active{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-bg1:active{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-bg2:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-bg3:active{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-gray1:active{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-gray2:active{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-gray3:active{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-gray4:active{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-gray5:active{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-gray6:active{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-gray7:active{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-white:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-warning:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-error:active{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-info:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-success:active{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-special4:active{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-dark:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-transparent:active{
    background-color:transparent
  }

  .xl\:active\:bg-secondary1:active{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-secondary2:active{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-secondary3:active{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-secondary4:active{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-secondary5:active{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .xl\:active\:bg-green1:active{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red1{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red3{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red4{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-red5{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan1{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan2{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan3{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan4{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-cyan5{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-bg1{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-bg2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-bg3{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray1{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray2{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray3{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray4{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray5{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray6{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-gray7{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-white{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-warning{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-error{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-info{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-success{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-special4{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-dark{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-transparent{
    background-color:transparent
  }

  .group:hover .xl\:group-hover\:bg-secondary1{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-secondary2{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-secondary3{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-secondary4{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-secondary5{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .group:hover .xl\:group-hover\:bg-green1{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .xl\:bg-opacity-0{
    --tw-bg-opacity:0
  }

  .xl\:bg-opacity-5{
    --tw-bg-opacity:0.05
  }

  .xl\:bg-opacity-10{
    --tw-bg-opacity:0.1
  }

  .xl\:bg-opacity-20{
    --tw-bg-opacity:0.2
  }

  .xl\:bg-opacity-25{
    --tw-bg-opacity:0.25
  }

  .xl\:bg-opacity-30{
    --tw-bg-opacity:0.3
  }

  .xl\:bg-opacity-40{
    --tw-bg-opacity:0.4
  }

  .xl\:bg-opacity-50{
    --tw-bg-opacity:0.5
  }

  .xl\:bg-opacity-60{
    --tw-bg-opacity:0.6
  }

  .xl\:bg-opacity-70{
    --tw-bg-opacity:0.7
  }

  .xl\:bg-opacity-75{
    --tw-bg-opacity:0.75
  }

  .xl\:bg-opacity-80{
    --tw-bg-opacity:0.8
  }

  .xl\:bg-opacity-90{
    --tw-bg-opacity:0.9
  }

  .xl\:bg-opacity-95{
    --tw-bg-opacity:0.95
  }

  .xl\:bg-opacity-100{
    --tw-bg-opacity:1
  }

  .group:hover .xl\:group-hover\:bg-opacity-0{
    --tw-bg-opacity:0
  }

  .group:hover .xl\:group-hover\:bg-opacity-5{
    --tw-bg-opacity:0.05
  }

  .group:hover .xl\:group-hover\:bg-opacity-10{
    --tw-bg-opacity:0.1
  }

  .group:hover .xl\:group-hover\:bg-opacity-20{
    --tw-bg-opacity:0.2
  }

  .group:hover .xl\:group-hover\:bg-opacity-25{
    --tw-bg-opacity:0.25
  }

  .group:hover .xl\:group-hover\:bg-opacity-30{
    --tw-bg-opacity:0.3
  }

  .group:hover .xl\:group-hover\:bg-opacity-40{
    --tw-bg-opacity:0.4
  }

  .group:hover .xl\:group-hover\:bg-opacity-50{
    --tw-bg-opacity:0.5
  }

  .group:hover .xl\:group-hover\:bg-opacity-60{
    --tw-bg-opacity:0.6
  }

  .group:hover .xl\:group-hover\:bg-opacity-70{
    --tw-bg-opacity:0.7
  }

  .group:hover .xl\:group-hover\:bg-opacity-75{
    --tw-bg-opacity:0.75
  }

  .group:hover .xl\:group-hover\:bg-opacity-80{
    --tw-bg-opacity:0.8
  }

  .group:hover .xl\:group-hover\:bg-opacity-90{
    --tw-bg-opacity:0.9
  }

  .group:hover .xl\:group-hover\:bg-opacity-95{
    --tw-bg-opacity:0.95
  }

  .group:hover .xl\:group-hover\:bg-opacity-100{
    --tw-bg-opacity:1
  }

  .xl\:focus-within\:bg-opacity-0:focus-within{
    --tw-bg-opacity:0
  }

  .xl\:focus-within\:bg-opacity-5:focus-within{
    --tw-bg-opacity:0.05
  }

  .xl\:focus-within\:bg-opacity-10:focus-within{
    --tw-bg-opacity:0.1
  }

  .xl\:focus-within\:bg-opacity-20:focus-within{
    --tw-bg-opacity:0.2
  }

  .xl\:focus-within\:bg-opacity-25:focus-within{
    --tw-bg-opacity:0.25
  }

  .xl\:focus-within\:bg-opacity-30:focus-within{
    --tw-bg-opacity:0.3
  }

  .xl\:focus-within\:bg-opacity-40:focus-within{
    --tw-bg-opacity:0.4
  }

  .xl\:focus-within\:bg-opacity-50:focus-within{
    --tw-bg-opacity:0.5
  }

  .xl\:focus-within\:bg-opacity-60:focus-within{
    --tw-bg-opacity:0.6
  }

  .xl\:focus-within\:bg-opacity-70:focus-within{
    --tw-bg-opacity:0.7
  }

  .xl\:focus-within\:bg-opacity-75:focus-within{
    --tw-bg-opacity:0.75
  }

  .xl\:focus-within\:bg-opacity-80:focus-within{
    --tw-bg-opacity:0.8
  }

  .xl\:focus-within\:bg-opacity-90:focus-within{
    --tw-bg-opacity:0.9
  }

  .xl\:focus-within\:bg-opacity-95:focus-within{
    --tw-bg-opacity:0.95
  }

  .xl\:focus-within\:bg-opacity-100:focus-within{
    --tw-bg-opacity:1
  }

  .xl\:hover\:bg-opacity-0:hover{
    --tw-bg-opacity:0
  }

  .xl\:hover\:bg-opacity-5:hover{
    --tw-bg-opacity:0.05
  }

  .xl\:hover\:bg-opacity-10:hover{
    --tw-bg-opacity:0.1
  }

  .xl\:hover\:bg-opacity-20:hover{
    --tw-bg-opacity:0.2
  }

  .xl\:hover\:bg-opacity-25:hover{
    --tw-bg-opacity:0.25
  }

  .xl\:hover\:bg-opacity-30:hover{
    --tw-bg-opacity:0.3
  }

  .xl\:hover\:bg-opacity-40:hover{
    --tw-bg-opacity:0.4
  }

  .xl\:hover\:bg-opacity-50:hover{
    --tw-bg-opacity:0.5
  }

  .xl\:hover\:bg-opacity-60:hover{
    --tw-bg-opacity:0.6
  }

  .xl\:hover\:bg-opacity-70:hover{
    --tw-bg-opacity:0.7
  }

  .xl\:hover\:bg-opacity-75:hover{
    --tw-bg-opacity:0.75
  }

  .xl\:hover\:bg-opacity-80:hover{
    --tw-bg-opacity:0.8
  }

  .xl\:hover\:bg-opacity-90:hover{
    --tw-bg-opacity:0.9
  }

  .xl\:hover\:bg-opacity-95:hover{
    --tw-bg-opacity:0.95
  }

  .xl\:hover\:bg-opacity-100:hover{
    --tw-bg-opacity:1
  }

  .xl\:focus\:bg-opacity-0:focus{
    --tw-bg-opacity:0
  }

  .xl\:focus\:bg-opacity-5:focus{
    --tw-bg-opacity:0.05
  }

  .xl\:focus\:bg-opacity-10:focus{
    --tw-bg-opacity:0.1
  }

  .xl\:focus\:bg-opacity-20:focus{
    --tw-bg-opacity:0.2
  }

  .xl\:focus\:bg-opacity-25:focus{
    --tw-bg-opacity:0.25
  }

  .xl\:focus\:bg-opacity-30:focus{
    --tw-bg-opacity:0.3
  }

  .xl\:focus\:bg-opacity-40:focus{
    --tw-bg-opacity:0.4
  }

  .xl\:focus\:bg-opacity-50:focus{
    --tw-bg-opacity:0.5
  }

  .xl\:focus\:bg-opacity-60:focus{
    --tw-bg-opacity:0.6
  }

  .xl\:focus\:bg-opacity-70:focus{
    --tw-bg-opacity:0.7
  }

  .xl\:focus\:bg-opacity-75:focus{
    --tw-bg-opacity:0.75
  }

  .xl\:focus\:bg-opacity-80:focus{
    --tw-bg-opacity:0.8
  }

  .xl\:focus\:bg-opacity-90:focus{
    --tw-bg-opacity:0.9
  }

  .xl\:focus\:bg-opacity-95:focus{
    --tw-bg-opacity:0.95
  }

  .xl\:focus\:bg-opacity-100:focus{
    --tw-bg-opacity:1
  }

  .xl\:bg-none{
    background-image:none
  }

  .xl\:bg-gradient-to-t{
    background-image:linear-gradient(to top, var(--tw-gradient-stops))
  }

  .xl\:bg-gradient-to-tr{
    background-image:linear-gradient(to top right, var(--tw-gradient-stops))
  }

  .xl\:bg-gradient-to-r{
    background-image:linear-gradient(to right, var(--tw-gradient-stops))
  }

  .xl\:bg-gradient-to-br{
    background-image:linear-gradient(to bottom right, var(--tw-gradient-stops))
  }

  .xl\:bg-gradient-to-b{
    background-image:linear-gradient(to bottom, var(--tw-gradient-stops))
  }

  .xl\:bg-gradient-to-bl{
    background-image:linear-gradient(to bottom left, var(--tw-gradient-stops))
  }

  .xl\:bg-gradient-to-l{
    background-image:linear-gradient(to left, var(--tw-gradient-stops))
  }

  .xl\:bg-gradient-to-tl{
    background-image:linear-gradient(to top left, var(--tw-gradient-stops))
  }

  .xl\:from-red1{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xl\:from-red2{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xl\:from-red3{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xl\:from-red4{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xl\:from-red5{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xl\:from-cyan1{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xl\:from-cyan2{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xl\:from-cyan3{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xl\:from-cyan4{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xl\:from-cyan5{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xl\:from-bg1{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xl\:from-bg2{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:from-bg3{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xl\:from-gray1{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xl\:from-gray2{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xl\:from-gray3{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xl\:from-gray4{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xl\:from-gray5{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xl\:from-gray6{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xl\:from-gray7{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:from-white{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:from-warning{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xl\:from-error{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xl\:from-info{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xl\:from-success{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xl\:from-special4{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xl\:from-dark{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:from-transparent{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:from-secondary1{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xl\:from-secondary2{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:from-secondary3{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xl\:from-secondary4{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xl\:from-secondary5{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xl\:from-green1{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xl\:hover\:from-red1:hover{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xl\:hover\:from-red2:hover{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xl\:hover\:from-red3:hover{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xl\:hover\:from-red4:hover{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xl\:hover\:from-red5:hover{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xl\:hover\:from-cyan1:hover{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xl\:hover\:from-cyan2:hover{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xl\:hover\:from-cyan3:hover{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xl\:hover\:from-cyan4:hover{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xl\:hover\:from-cyan5:hover{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xl\:hover\:from-bg1:hover{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xl\:hover\:from-bg2:hover{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-bg3:hover{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xl\:hover\:from-gray1:hover{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xl\:hover\:from-gray2:hover{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xl\:hover\:from-gray3:hover{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xl\:hover\:from-gray4:hover{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xl\:hover\:from-gray5:hover{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xl\:hover\:from-gray6:hover{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xl\:hover\:from-gray7:hover{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:hover\:from-white:hover{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-warning:hover{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xl\:hover\:from-error:hover{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xl\:hover\:from-info:hover{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xl\:hover\:from-success:hover{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xl\:hover\:from-special4:hover{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xl\:hover\:from-dark:hover{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:from-transparent:hover{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:from-secondary1:hover{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xl\:hover\:from-secondary2:hover{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:hover\:from-secondary3:hover{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xl\:hover\:from-secondary4:hover{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xl\:hover\:from-secondary5:hover{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xl\:hover\:from-green1:hover{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xl\:focus\:from-red1:focus{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xl\:focus\:from-red2:focus{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xl\:focus\:from-red3:focus{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xl\:focus\:from-red4:focus{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xl\:focus\:from-red5:focus{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xl\:focus\:from-cyan1:focus{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xl\:focus\:from-cyan2:focus{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xl\:focus\:from-cyan3:focus{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xl\:focus\:from-cyan4:focus{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xl\:focus\:from-cyan5:focus{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xl\:focus\:from-bg1:focus{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xl\:focus\:from-bg2:focus{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-bg3:focus{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xl\:focus\:from-gray1:focus{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xl\:focus\:from-gray2:focus{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xl\:focus\:from-gray3:focus{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xl\:focus\:from-gray4:focus{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xl\:focus\:from-gray5:focus{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xl\:focus\:from-gray6:focus{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xl\:focus\:from-gray7:focus{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:focus\:from-white:focus{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-warning:focus{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xl\:focus\:from-error:focus{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xl\:focus\:from-info:focus{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xl\:focus\:from-success:focus{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xl\:focus\:from-special4:focus{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xl\:focus\:from-dark:focus{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:from-transparent:focus{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:from-secondary1:focus{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xl\:focus\:from-secondary2:focus{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:focus\:from-secondary3:focus{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xl\:focus\:from-secondary4:focus{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xl\:focus\:from-secondary5:focus{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xl\:focus\:from-green1:focus{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xl\:via-red1{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xl\:via-red2{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xl\:via-red3{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xl\:via-red4{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xl\:via-red5{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xl\:via-cyan1{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xl\:via-cyan2{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xl\:via-cyan3{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xl\:via-cyan4{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xl\:via-cyan5{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xl\:via-bg1{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xl\:via-bg2{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:via-bg3{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xl\:via-gray1{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xl\:via-gray2{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xl\:via-gray3{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xl\:via-gray4{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xl\:via-gray5{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xl\:via-gray6{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xl\:via-gray7{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:via-white{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:via-warning{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xl\:via-error{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xl\:via-info{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xl\:via-success{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xl\:via-special4{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xl\:via-dark{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:via-transparent{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:via-secondary1{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xl\:via-secondary2{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:via-secondary3{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xl\:via-secondary4{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xl\:via-secondary5{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xl\:via-green1{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xl\:hover\:via-red1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xl\:hover\:via-red2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xl\:hover\:via-red3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xl\:hover\:via-red4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xl\:hover\:via-red5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xl\:hover\:via-cyan1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xl\:hover\:via-cyan2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xl\:hover\:via-cyan3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xl\:hover\:via-cyan4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xl\:hover\:via-cyan5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xl\:hover\:via-bg1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xl\:hover\:via-bg2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-bg3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xl\:hover\:via-gray1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xl\:hover\:via-gray2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xl\:hover\:via-gray3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xl\:hover\:via-gray4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xl\:hover\:via-gray5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xl\:hover\:via-gray6:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xl\:hover\:via-gray7:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:hover\:via-white:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-warning:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xl\:hover\:via-error:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xl\:hover\:via-info:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xl\:hover\:via-success:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xl\:hover\:via-special4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xl\:hover\:via-dark:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:via-transparent:hover{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:hover\:via-secondary1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xl\:hover\:via-secondary2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:hover\:via-secondary3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xl\:hover\:via-secondary4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xl\:hover\:via-secondary5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xl\:hover\:via-green1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xl\:focus\:via-red1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xl\:focus\:via-red2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xl\:focus\:via-red3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xl\:focus\:via-red4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xl\:focus\:via-red5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xl\:focus\:via-cyan1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xl\:focus\:via-cyan2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xl\:focus\:via-cyan3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xl\:focus\:via-cyan4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xl\:focus\:via-cyan5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xl\:focus\:via-bg1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xl\:focus\:via-bg2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-bg3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xl\:focus\:via-gray1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xl\:focus\:via-gray2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xl\:focus\:via-gray3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xl\:focus\:via-gray4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xl\:focus\:via-gray5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xl\:focus\:via-gray6:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xl\:focus\:via-gray7:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:focus\:via-white:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-warning:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xl\:focus\:via-error:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xl\:focus\:via-info:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xl\:focus\:via-success:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xl\:focus\:via-special4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xl\:focus\:via-dark:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:via-transparent:focus{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xl\:focus\:via-secondary1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xl\:focus\:via-secondary2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xl\:focus\:via-secondary3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xl\:focus\:via-secondary4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xl\:focus\:via-secondary5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xl\:focus\:via-green1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xl\:to-red1{
    --tw-gradient-to:#CC352C
  }

  .xl\:to-red2{
    --tw-gradient-to:#FF554B
  }

  .xl\:to-red3{
    --tw-gradient-to:#FF7B73
  }

  .xl\:to-red4{
    --tw-gradient-to:#FFF1F1
  }

  .xl\:to-red5{
    --tw-gradient-to:#FFF6F6
  }

  .xl\:to-cyan1{
    --tw-gradient-to:#009897
  }

  .xl\:to-cyan2{
    --tw-gradient-to:#00BFBD
  }

  .xl\:to-cyan3{
    --tw-gradient-to:#4CD1D0
  }

  .xl\:to-cyan4{
    --tw-gradient-to:#B2ECEB
  }

  .xl\:to-cyan5{
    --tw-gradient-to:#EDFAFA
  }

  .xl\:to-bg1{
    --tw-gradient-to:#FAFAFC
  }

  .xl\:to-bg2{
    --tw-gradient-to:#ffffff
  }

  .xl\:to-bg3{
    --tw-gradient-to:#F7F7FA
  }

  .xl\:to-gray1{
    --tw-gradient-to:#333333
  }

  .xl\:to-gray2{
    --tw-gradient-to:#4D4D4D
  }

  .xl\:to-gray3{
    --tw-gradient-to:#8C8C8C
  }

  .xl\:to-gray4{
    --tw-gradient-to:#BFBFBF
  }

  .xl\:to-gray5{
    --tw-gradient-to:#D9D9D9
  }

  .xl\:to-gray6{
    --tw-gradient-to:#E9E9EB
  }

  .xl\:to-gray7{
    --tw-gradient-to:#515164
  }

  .xl\:to-white{
    --tw-gradient-to:#ffffff
  }

  .xl\:to-warning{
    --tw-gradient-to:#FFC319
  }

  .xl\:to-error{
    --tw-gradient-to:#EA696F
  }

  .xl\:to-info{
    --tw-gradient-to:#0071B9
  }

  .xl\:to-success{
    --tw-gradient-to:#98D45F
  }

  .xl\:to-special4{
    --tw-gradient-to:#59779A
  }

  .xl\:to-dark{
    --tw-gradient-to:#000000
  }

  .xl\:to-transparent{
    --tw-gradient-to:transparent
  }

  .xl\:to-secondary1{
    --tw-gradient-to:#313140
  }

  .xl\:to-secondary2{
    --tw-gradient-to:#515164
  }

  .xl\:to-secondary3{
    --tw-gradient-to:#9C9CAC
  }

  .xl\:to-secondary4{
    --tw-gradient-to:#E5E8ED
  }

  .xl\:to-secondary5{
    --tw-gradient-to:#F5F6F9
  }

  .xl\:to-green1{
    --tw-gradient-to:#0DE298
  }

  .xl\:hover\:to-red1:hover{
    --tw-gradient-to:#CC352C
  }

  .xl\:hover\:to-red2:hover{
    --tw-gradient-to:#FF554B
  }

  .xl\:hover\:to-red3:hover{
    --tw-gradient-to:#FF7B73
  }

  .xl\:hover\:to-red4:hover{
    --tw-gradient-to:#FFF1F1
  }

  .xl\:hover\:to-red5:hover{
    --tw-gradient-to:#FFF6F6
  }

  .xl\:hover\:to-cyan1:hover{
    --tw-gradient-to:#009897
  }

  .xl\:hover\:to-cyan2:hover{
    --tw-gradient-to:#00BFBD
  }

  .xl\:hover\:to-cyan3:hover{
    --tw-gradient-to:#4CD1D0
  }

  .xl\:hover\:to-cyan4:hover{
    --tw-gradient-to:#B2ECEB
  }

  .xl\:hover\:to-cyan5:hover{
    --tw-gradient-to:#EDFAFA
  }

  .xl\:hover\:to-bg1:hover{
    --tw-gradient-to:#FAFAFC
  }

  .xl\:hover\:to-bg2:hover{
    --tw-gradient-to:#ffffff
  }

  .xl\:hover\:to-bg3:hover{
    --tw-gradient-to:#F7F7FA
  }

  .xl\:hover\:to-gray1:hover{
    --tw-gradient-to:#333333
  }

  .xl\:hover\:to-gray2:hover{
    --tw-gradient-to:#4D4D4D
  }

  .xl\:hover\:to-gray3:hover{
    --tw-gradient-to:#8C8C8C
  }

  .xl\:hover\:to-gray4:hover{
    --tw-gradient-to:#BFBFBF
  }

  .xl\:hover\:to-gray5:hover{
    --tw-gradient-to:#D9D9D9
  }

  .xl\:hover\:to-gray6:hover{
    --tw-gradient-to:#E9E9EB
  }

  .xl\:hover\:to-gray7:hover{
    --tw-gradient-to:#515164
  }

  .xl\:hover\:to-white:hover{
    --tw-gradient-to:#ffffff
  }

  .xl\:hover\:to-warning:hover{
    --tw-gradient-to:#FFC319
  }

  .xl\:hover\:to-error:hover{
    --tw-gradient-to:#EA696F
  }

  .xl\:hover\:to-info:hover{
    --tw-gradient-to:#0071B9
  }

  .xl\:hover\:to-success:hover{
    --tw-gradient-to:#98D45F
  }

  .xl\:hover\:to-special4:hover{
    --tw-gradient-to:#59779A
  }

  .xl\:hover\:to-dark:hover{
    --tw-gradient-to:#000000
  }

  .xl\:hover\:to-transparent:hover{
    --tw-gradient-to:transparent
  }

  .xl\:hover\:to-secondary1:hover{
    --tw-gradient-to:#313140
  }

  .xl\:hover\:to-secondary2:hover{
    --tw-gradient-to:#515164
  }

  .xl\:hover\:to-secondary3:hover{
    --tw-gradient-to:#9C9CAC
  }

  .xl\:hover\:to-secondary4:hover{
    --tw-gradient-to:#E5E8ED
  }

  .xl\:hover\:to-secondary5:hover{
    --tw-gradient-to:#F5F6F9
  }

  .xl\:hover\:to-green1:hover{
    --tw-gradient-to:#0DE298
  }

  .xl\:focus\:to-red1:focus{
    --tw-gradient-to:#CC352C
  }

  .xl\:focus\:to-red2:focus{
    --tw-gradient-to:#FF554B
  }

  .xl\:focus\:to-red3:focus{
    --tw-gradient-to:#FF7B73
  }

  .xl\:focus\:to-red4:focus{
    --tw-gradient-to:#FFF1F1
  }

  .xl\:focus\:to-red5:focus{
    --tw-gradient-to:#FFF6F6
  }

  .xl\:focus\:to-cyan1:focus{
    --tw-gradient-to:#009897
  }

  .xl\:focus\:to-cyan2:focus{
    --tw-gradient-to:#00BFBD
  }

  .xl\:focus\:to-cyan3:focus{
    --tw-gradient-to:#4CD1D0
  }

  .xl\:focus\:to-cyan4:focus{
    --tw-gradient-to:#B2ECEB
  }

  .xl\:focus\:to-cyan5:focus{
    --tw-gradient-to:#EDFAFA
  }

  .xl\:focus\:to-bg1:focus{
    --tw-gradient-to:#FAFAFC
  }

  .xl\:focus\:to-bg2:focus{
    --tw-gradient-to:#ffffff
  }

  .xl\:focus\:to-bg3:focus{
    --tw-gradient-to:#F7F7FA
  }

  .xl\:focus\:to-gray1:focus{
    --tw-gradient-to:#333333
  }

  .xl\:focus\:to-gray2:focus{
    --tw-gradient-to:#4D4D4D
  }

  .xl\:focus\:to-gray3:focus{
    --tw-gradient-to:#8C8C8C
  }

  .xl\:focus\:to-gray4:focus{
    --tw-gradient-to:#BFBFBF
  }

  .xl\:focus\:to-gray5:focus{
    --tw-gradient-to:#D9D9D9
  }

  .xl\:focus\:to-gray6:focus{
    --tw-gradient-to:#E9E9EB
  }

  .xl\:focus\:to-gray7:focus{
    --tw-gradient-to:#515164
  }

  .xl\:focus\:to-white:focus{
    --tw-gradient-to:#ffffff
  }

  .xl\:focus\:to-warning:focus{
    --tw-gradient-to:#FFC319
  }

  .xl\:focus\:to-error:focus{
    --tw-gradient-to:#EA696F
  }

  .xl\:focus\:to-info:focus{
    --tw-gradient-to:#0071B9
  }

  .xl\:focus\:to-success:focus{
    --tw-gradient-to:#98D45F
  }

  .xl\:focus\:to-special4:focus{
    --tw-gradient-to:#59779A
  }

  .xl\:focus\:to-dark:focus{
    --tw-gradient-to:#000000
  }

  .xl\:focus\:to-transparent:focus{
    --tw-gradient-to:transparent
  }

  .xl\:focus\:to-secondary1:focus{
    --tw-gradient-to:#313140
  }

  .xl\:focus\:to-secondary2:focus{
    --tw-gradient-to:#515164
  }

  .xl\:focus\:to-secondary3:focus{
    --tw-gradient-to:#9C9CAC
  }

  .xl\:focus\:to-secondary4:focus{
    --tw-gradient-to:#E5E8ED
  }

  .xl\:focus\:to-secondary5:focus{
    --tw-gradient-to:#F5F6F9
  }

  .xl\:focus\:to-green1:focus{
    --tw-gradient-to:#0DE298
  }

  .xl\:decoration-slice{
    -webkit-box-decoration-break:slice;
            box-decoration-break:slice
  }

  .xl\:decoration-clone{
    -webkit-box-decoration-break:clone;
            box-decoration-break:clone
  }

  .xl\:bg-auto{
    background-size:auto
  }

  .xl\:bg-cover{
    background-size:cover
  }

  .xl\:bg-contain{
    background-size:contain
  }

  .xl\:bg-clip-border{
    background-clip:border-box
  }

  .xl\:bg-clip-padding{
    background-clip:padding-box
  }

  .xl\:bg-clip-content{
    background-clip:content-box
  }

  .xl\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .xl\:bg-bottom{
    background-position:bottom
  }

  .xl\:bg-center{
    background-position:center
  }

  .xl\:bg-left{
    background-position:left
  }

  .xl\:bg-left-bottom{
    background-position:left bottom
  }

  .xl\:bg-left-top{
    background-position:left top
  }

  .xl\:bg-right{
    background-position:right
  }

  .xl\:bg-right-bottom{
    background-position:right bottom
  }

  .xl\:bg-right-top{
    background-position:right top
  }

  .xl\:bg-top{
    background-position:top
  }

  .xl\:bg-repeat{
    background-repeat:repeat
  }

  .xl\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .xl\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .xl\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .xl\:bg-repeat-round{
    background-repeat:round
  }

  .xl\:bg-repeat-space{
    background-repeat:space
  }

  .xl\:bg-origin-border{
    background-origin:border-box
  }

  .xl\:bg-origin-padding{
    background-origin:padding-box
  }

  .xl\:bg-origin-content{
    background-origin:content-box
  }

  .xl\:fill-current{
    fill:currentColor
  }

  .xl\:object-contain{
    object-fit:contain
  }

  .xl\:object-cover{
    object-fit:cover
  }

  .xl\:object-fill{
    object-fit:fill
  }

  .xl\:object-none{
    object-fit:none
  }

  .xl\:object-scale-down{
    object-fit:scale-down
  }

  .xl\:p-0{
    padding:0
  }

  .xl\:p-1{
    padding:4px
  }

  .xl\:p-2{
    padding:8px
  }

  .xl\:p-3{
    padding:12px
  }

  .xl\:p-4{
    padding:16px
  }

  .xl\:p-5{
    padding:20px
  }

  .xl\:p-6{
    padding:24px
  }

  .xl\:p-8{
    padding:32px
  }

  .xl\:p-10{
    padding:40px
  }

  .xl\:p-12{
    padding:48px
  }

  .xl\:p-16{
    padding:64px
  }

  .xl\:p-18{
    padding:72px
  }

  .xl\:p-24{
    padding:96px
  }

  .xl\:p-5dot5{
    padding:22px
  }

  .xl\:p-full{
    padding:100%
  }

  .xl\:first\:p-0:first-child{
    padding:0
  }

  .xl\:first\:p-1:first-child{
    padding:4px
  }

  .xl\:first\:p-2:first-child{
    padding:8px
  }

  .xl\:first\:p-3:first-child{
    padding:12px
  }

  .xl\:first\:p-4:first-child{
    padding:16px
  }

  .xl\:first\:p-5:first-child{
    padding:20px
  }

  .xl\:first\:p-6:first-child{
    padding:24px
  }

  .xl\:first\:p-8:first-child{
    padding:32px
  }

  .xl\:first\:p-10:first-child{
    padding:40px
  }

  .xl\:first\:p-12:first-child{
    padding:48px
  }

  .xl\:first\:p-16:first-child{
    padding:64px
  }

  .xl\:first\:p-18:first-child{
    padding:72px
  }

  .xl\:first\:p-24:first-child{
    padding:96px
  }

  .xl\:first\:p-5dot5:first-child{
    padding:22px
  }

  .xl\:first\:p-full:first-child{
    padding:100%
  }

  .xl\:last\:p-0:last-child{
    padding:0
  }

  .xl\:last\:p-1:last-child{
    padding:4px
  }

  .xl\:last\:p-2:last-child{
    padding:8px
  }

  .xl\:last\:p-3:last-child{
    padding:12px
  }

  .xl\:last\:p-4:last-child{
    padding:16px
  }

  .xl\:last\:p-5:last-child{
    padding:20px
  }

  .xl\:last\:p-6:last-child{
    padding:24px
  }

  .xl\:last\:p-8:last-child{
    padding:32px
  }

  .xl\:last\:p-10:last-child{
    padding:40px
  }

  .xl\:last\:p-12:last-child{
    padding:48px
  }

  .xl\:last\:p-16:last-child{
    padding:64px
  }

  .xl\:last\:p-18:last-child{
    padding:72px
  }

  .xl\:last\:p-24:last-child{
    padding:96px
  }

  .xl\:last\:p-5dot5:last-child{
    padding:22px
  }

  .xl\:last\:p-full:last-child{
    padding:100%
  }

  .xl\:px-0{
    padding-left:0;
    padding-right:0
  }

  .xl\:px-1{
    padding-left:4px;
    padding-right:4px
  }

  .xl\:px-2{
    padding-left:8px;
    padding-right:8px
  }

  .xl\:px-3{
    padding-left:12px;
    padding-right:12px
  }

  .xl\:px-4{
    padding-left:16px;
    padding-right:16px
  }

  .xl\:px-5{
    padding-left:20px;
    padding-right:20px
  }

  .xl\:px-6{
    padding-left:24px;
    padding-right:24px
  }

  .xl\:px-8{
    padding-left:32px;
    padding-right:32px
  }

  .xl\:px-10{
    padding-left:40px;
    padding-right:40px
  }

  .xl\:px-12{
    padding-left:48px;
    padding-right:48px
  }

  .xl\:px-16{
    padding-left:64px;
    padding-right:64px
  }

  .xl\:px-18{
    padding-left:72px;
    padding-right:72px
  }

  .xl\:px-24{
    padding-left:96px;
    padding-right:96px
  }

  .xl\:px-5dot5{
    padding-left:22px;
    padding-right:22px
  }

  .xl\:px-full{
    padding-left:100%;
    padding-right:100%
  }

  .xl\:py-0{
    padding-top:0;
    padding-bottom:0
  }

  .xl\:py-1{
    padding-top:4px;
    padding-bottom:4px
  }

  .xl\:py-2{
    padding-top:8px;
    padding-bottom:8px
  }

  .xl\:py-3{
    padding-top:12px;
    padding-bottom:12px
  }

  .xl\:py-4{
    padding-top:16px;
    padding-bottom:16px
  }

  .xl\:py-5{
    padding-top:20px;
    padding-bottom:20px
  }

  .xl\:py-6{
    padding-top:24px;
    padding-bottom:24px
  }

  .xl\:py-8{
    padding-top:32px;
    padding-bottom:32px
  }

  .xl\:py-10{
    padding-top:40px;
    padding-bottom:40px
  }

  .xl\:py-12{
    padding-top:48px;
    padding-bottom:48px
  }

  .xl\:py-16{
    padding-top:64px;
    padding-bottom:64px
  }

  .xl\:py-18{
    padding-top:72px;
    padding-bottom:72px
  }

  .xl\:py-24{
    padding-top:96px;
    padding-bottom:96px
  }

  .xl\:py-5dot5{
    padding-top:22px;
    padding-bottom:22px
  }

  .xl\:py-full{
    padding-top:100%;
    padding-bottom:100%
  }

  .xl\:first\:px-0:first-child{
    padding-left:0;
    padding-right:0
  }

  .xl\:first\:px-1:first-child{
    padding-left:4px;
    padding-right:4px
  }

  .xl\:first\:px-2:first-child{
    padding-left:8px;
    padding-right:8px
  }

  .xl\:first\:px-3:first-child{
    padding-left:12px;
    padding-right:12px
  }

  .xl\:first\:px-4:first-child{
    padding-left:16px;
    padding-right:16px
  }

  .xl\:first\:px-5:first-child{
    padding-left:20px;
    padding-right:20px
  }

  .xl\:first\:px-6:first-child{
    padding-left:24px;
    padding-right:24px
  }

  .xl\:first\:px-8:first-child{
    padding-left:32px;
    padding-right:32px
  }

  .xl\:first\:px-10:first-child{
    padding-left:40px;
    padding-right:40px
  }

  .xl\:first\:px-12:first-child{
    padding-left:48px;
    padding-right:48px
  }

  .xl\:first\:px-16:first-child{
    padding-left:64px;
    padding-right:64px
  }

  .xl\:first\:px-18:first-child{
    padding-left:72px;
    padding-right:72px
  }

  .xl\:first\:px-24:first-child{
    padding-left:96px;
    padding-right:96px
  }

  .xl\:first\:px-5dot5:first-child{
    padding-left:22px;
    padding-right:22px
  }

  .xl\:first\:px-full:first-child{
    padding-left:100%;
    padding-right:100%
  }

  .xl\:first\:py-0:first-child{
    padding-top:0;
    padding-bottom:0
  }

  .xl\:first\:py-1:first-child{
    padding-top:4px;
    padding-bottom:4px
  }

  .xl\:first\:py-2:first-child{
    padding-top:8px;
    padding-bottom:8px
  }

  .xl\:first\:py-3:first-child{
    padding-top:12px;
    padding-bottom:12px
  }

  .xl\:first\:py-4:first-child{
    padding-top:16px;
    padding-bottom:16px
  }

  .xl\:first\:py-5:first-child{
    padding-top:20px;
    padding-bottom:20px
  }

  .xl\:first\:py-6:first-child{
    padding-top:24px;
    padding-bottom:24px
  }

  .xl\:first\:py-8:first-child{
    padding-top:32px;
    padding-bottom:32px
  }

  .xl\:first\:py-10:first-child{
    padding-top:40px;
    padding-bottom:40px
  }

  .xl\:first\:py-12:first-child{
    padding-top:48px;
    padding-bottom:48px
  }

  .xl\:first\:py-16:first-child{
    padding-top:64px;
    padding-bottom:64px
  }

  .xl\:first\:py-18:first-child{
    padding-top:72px;
    padding-bottom:72px
  }

  .xl\:first\:py-24:first-child{
    padding-top:96px;
    padding-bottom:96px
  }

  .xl\:first\:py-5dot5:first-child{
    padding-top:22px;
    padding-bottom:22px
  }

  .xl\:first\:py-full:first-child{
    padding-top:100%;
    padding-bottom:100%
  }

  .xl\:last\:px-0:last-child{
    padding-left:0;
    padding-right:0
  }

  .xl\:last\:px-1:last-child{
    padding-left:4px;
    padding-right:4px
  }

  .xl\:last\:px-2:last-child{
    padding-left:8px;
    padding-right:8px
  }

  .xl\:last\:px-3:last-child{
    padding-left:12px;
    padding-right:12px
  }

  .xl\:last\:px-4:last-child{
    padding-left:16px;
    padding-right:16px
  }

  .xl\:last\:px-5:last-child{
    padding-left:20px;
    padding-right:20px
  }

  .xl\:last\:px-6:last-child{
    padding-left:24px;
    padding-right:24px
  }

  .xl\:last\:px-8:last-child{
    padding-left:32px;
    padding-right:32px
  }

  .xl\:last\:px-10:last-child{
    padding-left:40px;
    padding-right:40px
  }

  .xl\:last\:px-12:last-child{
    padding-left:48px;
    padding-right:48px
  }

  .xl\:last\:px-16:last-child{
    padding-left:64px;
    padding-right:64px
  }

  .xl\:last\:px-18:last-child{
    padding-left:72px;
    padding-right:72px
  }

  .xl\:last\:px-24:last-child{
    padding-left:96px;
    padding-right:96px
  }

  .xl\:last\:px-5dot5:last-child{
    padding-left:22px;
    padding-right:22px
  }

  .xl\:last\:px-full:last-child{
    padding-left:100%;
    padding-right:100%
  }

  .xl\:last\:py-0:last-child{
    padding-top:0;
    padding-bottom:0
  }

  .xl\:last\:py-1:last-child{
    padding-top:4px;
    padding-bottom:4px
  }

  .xl\:last\:py-2:last-child{
    padding-top:8px;
    padding-bottom:8px
  }

  .xl\:last\:py-3:last-child{
    padding-top:12px;
    padding-bottom:12px
  }

  .xl\:last\:py-4:last-child{
    padding-top:16px;
    padding-bottom:16px
  }

  .xl\:last\:py-5:last-child{
    padding-top:20px;
    padding-bottom:20px
  }

  .xl\:last\:py-6:last-child{
    padding-top:24px;
    padding-bottom:24px
  }

  .xl\:last\:py-8:last-child{
    padding-top:32px;
    padding-bottom:32px
  }

  .xl\:last\:py-10:last-child{
    padding-top:40px;
    padding-bottom:40px
  }

  .xl\:last\:py-12:last-child{
    padding-top:48px;
    padding-bottom:48px
  }

  .xl\:last\:py-16:last-child{
    padding-top:64px;
    padding-bottom:64px
  }

  .xl\:last\:py-18:last-child{
    padding-top:72px;
    padding-bottom:72px
  }

  .xl\:last\:py-24:last-child{
    padding-top:96px;
    padding-bottom:96px
  }

  .xl\:last\:py-5dot5:last-child{
    padding-top:22px;
    padding-bottom:22px
  }

  .xl\:last\:py-full:last-child{
    padding-top:100%;
    padding-bottom:100%
  }

  .xl\:pt-0{
    padding-top:0
  }

  .xl\:pt-1{
    padding-top:4px
  }

  .xl\:pt-2{
    padding-top:8px
  }

  .xl\:pt-3{
    padding-top:12px
  }

  .xl\:pt-4{
    padding-top:16px
  }

  .xl\:pt-5{
    padding-top:20px
  }

  .xl\:pt-6{
    padding-top:24px
  }

  .xl\:pt-8{
    padding-top:32px
  }

  .xl\:pt-10{
    padding-top:40px
  }

  .xl\:pt-12{
    padding-top:48px
  }

  .xl\:pt-16{
    padding-top:64px
  }

  .xl\:pt-18{
    padding-top:72px
  }

  .xl\:pt-24{
    padding-top:96px
  }

  .xl\:pt-5dot5{
    padding-top:22px
  }

  .xl\:pt-full{
    padding-top:100%
  }

  .xl\:pr-0{
    padding-right:0
  }

  .xl\:pr-1{
    padding-right:4px
  }

  .xl\:pr-2{
    padding-right:8px
  }

  .xl\:pr-3{
    padding-right:12px
  }

  .xl\:pr-4{
    padding-right:16px
  }

  .xl\:pr-5{
    padding-right:20px
  }

  .xl\:pr-6{
    padding-right:24px
  }

  .xl\:pr-8{
    padding-right:32px
  }

  .xl\:pr-10{
    padding-right:40px
  }

  .xl\:pr-12{
    padding-right:48px
  }

  .xl\:pr-16{
    padding-right:64px
  }

  .xl\:pr-18{
    padding-right:72px
  }

  .xl\:pr-24{
    padding-right:96px
  }

  .xl\:pr-5dot5{
    padding-right:22px
  }

  .xl\:pr-full{
    padding-right:100%
  }

  .xl\:pb-0{
    padding-bottom:0
  }

  .xl\:pb-1{
    padding-bottom:4px
  }

  .xl\:pb-2{
    padding-bottom:8px
  }

  .xl\:pb-3{
    padding-bottom:12px
  }

  .xl\:pb-4{
    padding-bottom:16px
  }

  .xl\:pb-5{
    padding-bottom:20px
  }

  .xl\:pb-6{
    padding-bottom:24px
  }

  .xl\:pb-8{
    padding-bottom:32px
  }

  .xl\:pb-10{
    padding-bottom:40px
  }

  .xl\:pb-12{
    padding-bottom:48px
  }

  .xl\:pb-16{
    padding-bottom:64px
  }

  .xl\:pb-18{
    padding-bottom:72px
  }

  .xl\:pb-24{
    padding-bottom:96px
  }

  .xl\:pb-5dot5{
    padding-bottom:22px
  }

  .xl\:pb-full{
    padding-bottom:100%
  }

  .xl\:pl-0{
    padding-left:0
  }

  .xl\:pl-1{
    padding-left:4px
  }

  .xl\:pl-2{
    padding-left:8px
  }

  .xl\:pl-3{
    padding-left:12px
  }

  .xl\:pl-4{
    padding-left:16px
  }

  .xl\:pl-5{
    padding-left:20px
  }

  .xl\:pl-6{
    padding-left:24px
  }

  .xl\:pl-8{
    padding-left:32px
  }

  .xl\:pl-10{
    padding-left:40px
  }

  .xl\:pl-12{
    padding-left:48px
  }

  .xl\:pl-16{
    padding-left:64px
  }

  .xl\:pl-18{
    padding-left:72px
  }

  .xl\:pl-24{
    padding-left:96px
  }

  .xl\:pl-5dot5{
    padding-left:22px
  }

  .xl\:pl-full{
    padding-left:100%
  }

  .xl\:first\:pt-0:first-child{
    padding-top:0
  }

  .xl\:first\:pt-1:first-child{
    padding-top:4px
  }

  .xl\:first\:pt-2:first-child{
    padding-top:8px
  }

  .xl\:first\:pt-3:first-child{
    padding-top:12px
  }

  .xl\:first\:pt-4:first-child{
    padding-top:16px
  }

  .xl\:first\:pt-5:first-child{
    padding-top:20px
  }

  .xl\:first\:pt-6:first-child{
    padding-top:24px
  }

  .xl\:first\:pt-8:first-child{
    padding-top:32px
  }

  .xl\:first\:pt-10:first-child{
    padding-top:40px
  }

  .xl\:first\:pt-12:first-child{
    padding-top:48px
  }

  .xl\:first\:pt-16:first-child{
    padding-top:64px
  }

  .xl\:first\:pt-18:first-child{
    padding-top:72px
  }

  .xl\:first\:pt-24:first-child{
    padding-top:96px
  }

  .xl\:first\:pt-5dot5:first-child{
    padding-top:22px
  }

  .xl\:first\:pt-full:first-child{
    padding-top:100%
  }

  .xl\:first\:pr-0:first-child{
    padding-right:0
  }

  .xl\:first\:pr-1:first-child{
    padding-right:4px
  }

  .xl\:first\:pr-2:first-child{
    padding-right:8px
  }

  .xl\:first\:pr-3:first-child{
    padding-right:12px
  }

  .xl\:first\:pr-4:first-child{
    padding-right:16px
  }

  .xl\:first\:pr-5:first-child{
    padding-right:20px
  }

  .xl\:first\:pr-6:first-child{
    padding-right:24px
  }

  .xl\:first\:pr-8:first-child{
    padding-right:32px
  }

  .xl\:first\:pr-10:first-child{
    padding-right:40px
  }

  .xl\:first\:pr-12:first-child{
    padding-right:48px
  }

  .xl\:first\:pr-16:first-child{
    padding-right:64px
  }

  .xl\:first\:pr-18:first-child{
    padding-right:72px
  }

  .xl\:first\:pr-24:first-child{
    padding-right:96px
  }

  .xl\:first\:pr-5dot5:first-child{
    padding-right:22px
  }

  .xl\:first\:pr-full:first-child{
    padding-right:100%
  }

  .xl\:first\:pb-0:first-child{
    padding-bottom:0
  }

  .xl\:first\:pb-1:first-child{
    padding-bottom:4px
  }

  .xl\:first\:pb-2:first-child{
    padding-bottom:8px
  }

  .xl\:first\:pb-3:first-child{
    padding-bottom:12px
  }

  .xl\:first\:pb-4:first-child{
    padding-bottom:16px
  }

  .xl\:first\:pb-5:first-child{
    padding-bottom:20px
  }

  .xl\:first\:pb-6:first-child{
    padding-bottom:24px
  }

  .xl\:first\:pb-8:first-child{
    padding-bottom:32px
  }

  .xl\:first\:pb-10:first-child{
    padding-bottom:40px
  }

  .xl\:first\:pb-12:first-child{
    padding-bottom:48px
  }

  .xl\:first\:pb-16:first-child{
    padding-bottom:64px
  }

  .xl\:first\:pb-18:first-child{
    padding-bottom:72px
  }

  .xl\:first\:pb-24:first-child{
    padding-bottom:96px
  }

  .xl\:first\:pb-5dot5:first-child{
    padding-bottom:22px
  }

  .xl\:first\:pb-full:first-child{
    padding-bottom:100%
  }

  .xl\:first\:pl-0:first-child{
    padding-left:0
  }

  .xl\:first\:pl-1:first-child{
    padding-left:4px
  }

  .xl\:first\:pl-2:first-child{
    padding-left:8px
  }

  .xl\:first\:pl-3:first-child{
    padding-left:12px
  }

  .xl\:first\:pl-4:first-child{
    padding-left:16px
  }

  .xl\:first\:pl-5:first-child{
    padding-left:20px
  }

  .xl\:first\:pl-6:first-child{
    padding-left:24px
  }

  .xl\:first\:pl-8:first-child{
    padding-left:32px
  }

  .xl\:first\:pl-10:first-child{
    padding-left:40px
  }

  .xl\:first\:pl-12:first-child{
    padding-left:48px
  }

  .xl\:first\:pl-16:first-child{
    padding-left:64px
  }

  .xl\:first\:pl-18:first-child{
    padding-left:72px
  }

  .xl\:first\:pl-24:first-child{
    padding-left:96px
  }

  .xl\:first\:pl-5dot5:first-child{
    padding-left:22px
  }

  .xl\:first\:pl-full:first-child{
    padding-left:100%
  }

  .xl\:last\:pt-0:last-child{
    padding-top:0
  }

  .xl\:last\:pt-1:last-child{
    padding-top:4px
  }

  .xl\:last\:pt-2:last-child{
    padding-top:8px
  }

  .xl\:last\:pt-3:last-child{
    padding-top:12px
  }

  .xl\:last\:pt-4:last-child{
    padding-top:16px
  }

  .xl\:last\:pt-5:last-child{
    padding-top:20px
  }

  .xl\:last\:pt-6:last-child{
    padding-top:24px
  }

  .xl\:last\:pt-8:last-child{
    padding-top:32px
  }

  .xl\:last\:pt-10:last-child{
    padding-top:40px
  }

  .xl\:last\:pt-12:last-child{
    padding-top:48px
  }

  .xl\:last\:pt-16:last-child{
    padding-top:64px
  }

  .xl\:last\:pt-18:last-child{
    padding-top:72px
  }

  .xl\:last\:pt-24:last-child{
    padding-top:96px
  }

  .xl\:last\:pt-5dot5:last-child{
    padding-top:22px
  }

  .xl\:last\:pt-full:last-child{
    padding-top:100%
  }

  .xl\:last\:pr-0:last-child{
    padding-right:0
  }

  .xl\:last\:pr-1:last-child{
    padding-right:4px
  }

  .xl\:last\:pr-2:last-child{
    padding-right:8px
  }

  .xl\:last\:pr-3:last-child{
    padding-right:12px
  }

  .xl\:last\:pr-4:last-child{
    padding-right:16px
  }

  .xl\:last\:pr-5:last-child{
    padding-right:20px
  }

  .xl\:last\:pr-6:last-child{
    padding-right:24px
  }

  .xl\:last\:pr-8:last-child{
    padding-right:32px
  }

  .xl\:last\:pr-10:last-child{
    padding-right:40px
  }

  .xl\:last\:pr-12:last-child{
    padding-right:48px
  }

  .xl\:last\:pr-16:last-child{
    padding-right:64px
  }

  .xl\:last\:pr-18:last-child{
    padding-right:72px
  }

  .xl\:last\:pr-24:last-child{
    padding-right:96px
  }

  .xl\:last\:pr-5dot5:last-child{
    padding-right:22px
  }

  .xl\:last\:pr-full:last-child{
    padding-right:100%
  }

  .xl\:last\:pb-0:last-child{
    padding-bottom:0
  }

  .xl\:last\:pb-1:last-child{
    padding-bottom:4px
  }

  .xl\:last\:pb-2:last-child{
    padding-bottom:8px
  }

  .xl\:last\:pb-3:last-child{
    padding-bottom:12px
  }

  .xl\:last\:pb-4:last-child{
    padding-bottom:16px
  }

  .xl\:last\:pb-5:last-child{
    padding-bottom:20px
  }

  .xl\:last\:pb-6:last-child{
    padding-bottom:24px
  }

  .xl\:last\:pb-8:last-child{
    padding-bottom:32px
  }

  .xl\:last\:pb-10:last-child{
    padding-bottom:40px
  }

  .xl\:last\:pb-12:last-child{
    padding-bottom:48px
  }

  .xl\:last\:pb-16:last-child{
    padding-bottom:64px
  }

  .xl\:last\:pb-18:last-child{
    padding-bottom:72px
  }

  .xl\:last\:pb-24:last-child{
    padding-bottom:96px
  }

  .xl\:last\:pb-5dot5:last-child{
    padding-bottom:22px
  }

  .xl\:last\:pb-full:last-child{
    padding-bottom:100%
  }

  .xl\:last\:pl-0:last-child{
    padding-left:0
  }

  .xl\:last\:pl-1:last-child{
    padding-left:4px
  }

  .xl\:last\:pl-2:last-child{
    padding-left:8px
  }

  .xl\:last\:pl-3:last-child{
    padding-left:12px
  }

  .xl\:last\:pl-4:last-child{
    padding-left:16px
  }

  .xl\:last\:pl-5:last-child{
    padding-left:20px
  }

  .xl\:last\:pl-6:last-child{
    padding-left:24px
  }

  .xl\:last\:pl-8:last-child{
    padding-left:32px
  }

  .xl\:last\:pl-10:last-child{
    padding-left:40px
  }

  .xl\:last\:pl-12:last-child{
    padding-left:48px
  }

  .xl\:last\:pl-16:last-child{
    padding-left:64px
  }

  .xl\:last\:pl-18:last-child{
    padding-left:72px
  }

  .xl\:last\:pl-24:last-child{
    padding-left:96px
  }

  .xl\:last\:pl-5dot5:last-child{
    padding-left:22px
  }

  .xl\:last\:pl-full:last-child{
    padding-left:100%
  }

  .xl\:text-left{
    text-align:left
  }

  .xl\:text-center{
    text-align:center
  }

  .xl\:text-right{
    text-align:right
  }

  .xl\:text-justify{
    text-align:justify
  }

  .xl\:align-baseline{
    vertical-align:baseline
  }

  .xl\:align-top{
    vertical-align:top
  }

  .xl\:align-middle{
    vertical-align:middle
  }

  .xl\:align-bottom{
    vertical-align:bottom
  }

  .xl\:align-text-top{
    vertical-align:text-top
  }

  .xl\:align-text-bottom{
    vertical-align:text-bottom
  }

  .xl\:text-xs{
    font-size:10px
  }

  .xl\:text-s{
    font-size:12px
  }

  .xl\:text-sm{
    font-size:14px
  }

  .xl\:text-tiny{
    font-size:12px
  }

  .xl\:text-base{
    font-size:16px
  }

  .xl\:text-lg{
    font-size:20px
  }

  .xl\:text-xl{
    font-size:24px
  }

  .xl\:text-2xl{
    font-size:32px
  }

  .xl\:text-3xl{
    font-size:40px
  }

  .xl\:text-4xl{
    font-size:48px
  }

  .xl\:text-5xl{
    font-size:64px
  }

  .xl\:font-thin{
    font-weight:100
  }

  .xl\:font-extralight{
    font-weight:200
  }

  .xl\:font-light{
    font-weight:300
  }

  .xl\:font-normal{
    font-weight:400
  }

  .xl\:font-medium{
    font-weight:500
  }

  .xl\:font-semibold{
    font-weight:600
  }

  .xl\:font-bold{
    font-weight:700
  }

  .xl\:font-extrabold{
    font-weight:800
  }

  .xl\:font-black{
    font-weight:900
  }

  .xl\:uppercase{
    text-transform:uppercase
  }

  .xl\:lowercase{
    text-transform:lowercase
  }

  .xl\:capitalize{
    text-transform:capitalize
  }

  .xl\:normal-case{
    text-transform:none
  }

  .xl\:italic{
    font-style:italic
  }

  .xl\:not-italic{
    font-style:normal
  }

  .xl\:ordinal, .xl\:slashed-zero, .xl\:lining-nums, .xl\:oldstyle-nums, .xl\:proportional-nums, .xl\:tabular-nums, .xl\:diagonal-fractions, .xl\:stacked-fractions{
    --tw-ordinal:var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction:var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric:var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  .xl\:normal-nums{
    font-variant-numeric:normal
  }

  .xl\:ordinal{
    --tw-ordinal:ordinal
  }

  .xl\:slashed-zero{
    --tw-slashed-zero:slashed-zero
  }

  .xl\:lining-nums{
    --tw-numeric-figure:lining-nums
  }

  .xl\:oldstyle-nums{
    --tw-numeric-figure:oldstyle-nums
  }

  .xl\:proportional-nums{
    --tw-numeric-spacing:proportional-nums
  }

  .xl\:tabular-nums{
    --tw-numeric-spacing:tabular-nums
  }

  .xl\:diagonal-fractions{
    --tw-numeric-fraction:diagonal-fractions
  }

  .xl\:stacked-fractions{
    --tw-numeric-fraction:stacked-fractions
  }

  .xl\:leading-3{
    line-height:.75rem
  }

  .xl\:leading-4{
    line-height:1rem
  }

  .xl\:leading-5{
    line-height:1.25rem
  }

  .xl\:leading-6{
    line-height:1.5rem
  }

  .xl\:leading-7{
    line-height:1.75rem
  }

  .xl\:leading-8{
    line-height:2rem
  }

  .xl\:leading-9{
    line-height:2.25rem
  }

  .xl\:leading-10{
    line-height:2.5rem
  }

  .xl\:leading-none{
    line-height:1
  }

  .xl\:leading-tight{
    line-height:1.25
  }

  .xl\:leading-snug{
    line-height:1.375
  }

  .xl\:leading-normal{
    line-height:1.5
  }

  .xl\:leading-relaxed{
    line-height:1.625
  }

  .xl\:leading-loose{
    line-height:2
  }

  .xl\:text-red1{
    color:#CC352C
  }

  .xl\:text-red2{
    color:#FF554B
  }

  .xl\:text-red3{
    color:#FF7B73
  }

  .xl\:text-red4{
    color:#FFF1F1
  }

  .xl\:text-red5{
    color:#FFF6F6
  }

  .xl\:text-cyan1{
    color:#009897
  }

  .xl\:text-cyan2{
    color:#00BFBD
  }

  .xl\:text-cyan3{
    color:#4CD1D0
  }

  .xl\:text-cyan4{
    color:#B2ECEB
  }

  .xl\:text-cyan5{
    color:#EDFAFA
  }

  .xl\:text-bg1{
    color:#FAFAFC
  }

  .xl\:text-bg2{
    color:#ffffff
  }

  .xl\:text-bg3{
    color:#F7F7FA
  }

  .xl\:text-gray1{
    color:#333333
  }

  .xl\:text-gray2{
    color:#4D4D4D
  }

  .xl\:text-gray3{
    color:#8C8C8C
  }

  .xl\:text-gray4{
    color:#BFBFBF
  }

  .xl\:text-gray5{
    color:#D9D9D9
  }

  .xl\:text-gray6{
    color:#E9E9EB
  }

  .xl\:text-gray7{
    color:#515164
  }

  .xl\:text-white{
    color:#ffffff
  }

  .xl\:text-warning{
    color:#FFC319
  }

  .xl\:text-error{
    color:#EA696F
  }

  .xl\:text-info{
    color:#0071B9
  }

  .xl\:text-success{
    color:#98D45F
  }

  .xl\:text-special4{
    color:#59779A
  }

  .xl\:text-dark{
    color:#000000
  }

  .xl\:text-transparent{
    color:transparent
  }

  .xl\:text-secondary1{
    color:#313140
  }

  .xl\:text-secondary2{
    color:#515164
  }

  .xl\:text-secondary3{
    color:#9C9CAC
  }

  .xl\:text-secondary4{
    color:#E5E8ED
  }

  .xl\:text-secondary5{
    color:#F5F6F9
  }

  .xl\:text-green1{
    color:#0DE298
  }

  .group:hover .xl\:group-hover\:text-red1{
    color:#CC352C
  }

  .group:hover .xl\:group-hover\:text-red2{
    color:#FF554B
  }

  .group:hover .xl\:group-hover\:text-red3{
    color:#FF7B73
  }

  .group:hover .xl\:group-hover\:text-red4{
    color:#FFF1F1
  }

  .group:hover .xl\:group-hover\:text-red5{
    color:#FFF6F6
  }

  .group:hover .xl\:group-hover\:text-cyan1{
    color:#009897
  }

  .group:hover .xl\:group-hover\:text-cyan2{
    color:#00BFBD
  }

  .group:hover .xl\:group-hover\:text-cyan3{
    color:#4CD1D0
  }

  .group:hover .xl\:group-hover\:text-cyan4{
    color:#B2ECEB
  }

  .group:hover .xl\:group-hover\:text-cyan5{
    color:#EDFAFA
  }

  .group:hover .xl\:group-hover\:text-bg1{
    color:#FAFAFC
  }

  .group:hover .xl\:group-hover\:text-bg2{
    color:#ffffff
  }

  .group:hover .xl\:group-hover\:text-bg3{
    color:#F7F7FA
  }

  .group:hover .xl\:group-hover\:text-gray1{
    color:#333333
  }

  .group:hover .xl\:group-hover\:text-gray2{
    color:#4D4D4D
  }

  .group:hover .xl\:group-hover\:text-gray3{
    color:#8C8C8C
  }

  .group:hover .xl\:group-hover\:text-gray4{
    color:#BFBFBF
  }

  .group:hover .xl\:group-hover\:text-gray5{
    color:#D9D9D9
  }

  .group:hover .xl\:group-hover\:text-gray6{
    color:#E9E9EB
  }

  .group:hover .xl\:group-hover\:text-gray7{
    color:#515164
  }

  .group:hover .xl\:group-hover\:text-white{
    color:#ffffff
  }

  .group:hover .xl\:group-hover\:text-warning{
    color:#FFC319
  }

  .group:hover .xl\:group-hover\:text-error{
    color:#EA696F
  }

  .group:hover .xl\:group-hover\:text-info{
    color:#0071B9
  }

  .group:hover .xl\:group-hover\:text-success{
    color:#98D45F
  }

  .group:hover .xl\:group-hover\:text-special4{
    color:#59779A
  }

  .group:hover .xl\:group-hover\:text-dark{
    color:#000000
  }

  .group:hover .xl\:group-hover\:text-transparent{
    color:transparent
  }

  .group:hover .xl\:group-hover\:text-secondary1{
    color:#313140
  }

  .group:hover .xl\:group-hover\:text-secondary2{
    color:#515164
  }

  .group:hover .xl\:group-hover\:text-secondary3{
    color:#9C9CAC
  }

  .group:hover .xl\:group-hover\:text-secondary4{
    color:#E5E8ED
  }

  .group:hover .xl\:group-hover\:text-secondary5{
    color:#F5F6F9
  }

  .group:hover .xl\:group-hover\:text-green1{
    color:#0DE298
  }

  .xl\:focus-within\:text-red1:focus-within{
    color:#CC352C
  }

  .xl\:focus-within\:text-red2:focus-within{
    color:#FF554B
  }

  .xl\:focus-within\:text-red3:focus-within{
    color:#FF7B73
  }

  .xl\:focus-within\:text-red4:focus-within{
    color:#FFF1F1
  }

  .xl\:focus-within\:text-red5:focus-within{
    color:#FFF6F6
  }

  .xl\:focus-within\:text-cyan1:focus-within{
    color:#009897
  }

  .xl\:focus-within\:text-cyan2:focus-within{
    color:#00BFBD
  }

  .xl\:focus-within\:text-cyan3:focus-within{
    color:#4CD1D0
  }

  .xl\:focus-within\:text-cyan4:focus-within{
    color:#B2ECEB
  }

  .xl\:focus-within\:text-cyan5:focus-within{
    color:#EDFAFA
  }

  .xl\:focus-within\:text-bg1:focus-within{
    color:#FAFAFC
  }

  .xl\:focus-within\:text-bg2:focus-within{
    color:#ffffff
  }

  .xl\:focus-within\:text-bg3:focus-within{
    color:#F7F7FA
  }

  .xl\:focus-within\:text-gray1:focus-within{
    color:#333333
  }

  .xl\:focus-within\:text-gray2:focus-within{
    color:#4D4D4D
  }

  .xl\:focus-within\:text-gray3:focus-within{
    color:#8C8C8C
  }

  .xl\:focus-within\:text-gray4:focus-within{
    color:#BFBFBF
  }

  .xl\:focus-within\:text-gray5:focus-within{
    color:#D9D9D9
  }

  .xl\:focus-within\:text-gray6:focus-within{
    color:#E9E9EB
  }

  .xl\:focus-within\:text-gray7:focus-within{
    color:#515164
  }

  .xl\:focus-within\:text-white:focus-within{
    color:#ffffff
  }

  .xl\:focus-within\:text-warning:focus-within{
    color:#FFC319
  }

  .xl\:focus-within\:text-error:focus-within{
    color:#EA696F
  }

  .xl\:focus-within\:text-info:focus-within{
    color:#0071B9
  }

  .xl\:focus-within\:text-success:focus-within{
    color:#98D45F
  }

  .xl\:focus-within\:text-special4:focus-within{
    color:#59779A
  }

  .xl\:focus-within\:text-dark:focus-within{
    color:#000000
  }

  .xl\:focus-within\:text-transparent:focus-within{
    color:transparent
  }

  .xl\:focus-within\:text-secondary1:focus-within{
    color:#313140
  }

  .xl\:focus-within\:text-secondary2:focus-within{
    color:#515164
  }

  .xl\:focus-within\:text-secondary3:focus-within{
    color:#9C9CAC
  }

  .xl\:focus-within\:text-secondary4:focus-within{
    color:#E5E8ED
  }

  .xl\:focus-within\:text-secondary5:focus-within{
    color:#F5F6F9
  }

  .xl\:focus-within\:text-green1:focus-within{
    color:#0DE298
  }

  .xl\:hover\:text-red1:hover{
    color:#CC352C
  }

  .xl\:hover\:text-red2:hover{
    color:#FF554B
  }

  .xl\:hover\:text-red3:hover{
    color:#FF7B73
  }

  .xl\:hover\:text-red4:hover{
    color:#FFF1F1
  }

  .xl\:hover\:text-red5:hover{
    color:#FFF6F6
  }

  .xl\:hover\:text-cyan1:hover{
    color:#009897
  }

  .xl\:hover\:text-cyan2:hover{
    color:#00BFBD
  }

  .xl\:hover\:text-cyan3:hover{
    color:#4CD1D0
  }

  .xl\:hover\:text-cyan4:hover{
    color:#B2ECEB
  }

  .xl\:hover\:text-cyan5:hover{
    color:#EDFAFA
  }

  .xl\:hover\:text-bg1:hover{
    color:#FAFAFC
  }

  .xl\:hover\:text-bg2:hover{
    color:#ffffff
  }

  .xl\:hover\:text-bg3:hover{
    color:#F7F7FA
  }

  .xl\:hover\:text-gray1:hover{
    color:#333333
  }

  .xl\:hover\:text-gray2:hover{
    color:#4D4D4D
  }

  .xl\:hover\:text-gray3:hover{
    color:#8C8C8C
  }

  .xl\:hover\:text-gray4:hover{
    color:#BFBFBF
  }

  .xl\:hover\:text-gray5:hover{
    color:#D9D9D9
  }

  .xl\:hover\:text-gray6:hover{
    color:#E9E9EB
  }

  .xl\:hover\:text-gray7:hover{
    color:#515164
  }

  .xl\:hover\:text-white:hover{
    color:#ffffff
  }

  .xl\:hover\:text-warning:hover{
    color:#FFC319
  }

  .xl\:hover\:text-error:hover{
    color:#EA696F
  }

  .xl\:hover\:text-info:hover{
    color:#0071B9
  }

  .xl\:hover\:text-success:hover{
    color:#98D45F
  }

  .xl\:hover\:text-special4:hover{
    color:#59779A
  }

  .xl\:hover\:text-dark:hover{
    color:#000000
  }

  .xl\:hover\:text-transparent:hover{
    color:transparent
  }

  .xl\:hover\:text-secondary1:hover{
    color:#313140
  }

  .xl\:hover\:text-secondary2:hover{
    color:#515164
  }

  .xl\:hover\:text-secondary3:hover{
    color:#9C9CAC
  }

  .xl\:hover\:text-secondary4:hover{
    color:#E5E8ED
  }

  .xl\:hover\:text-secondary5:hover{
    color:#F5F6F9
  }

  .xl\:hover\:text-green1:hover{
    color:#0DE298
  }

  .xl\:focus\:text-red1:focus{
    color:#CC352C
  }

  .xl\:focus\:text-red2:focus{
    color:#FF554B
  }

  .xl\:focus\:text-red3:focus{
    color:#FF7B73
  }

  .xl\:focus\:text-red4:focus{
    color:#FFF1F1
  }

  .xl\:focus\:text-red5:focus{
    color:#FFF6F6
  }

  .xl\:focus\:text-cyan1:focus{
    color:#009897
  }

  .xl\:focus\:text-cyan2:focus{
    color:#00BFBD
  }

  .xl\:focus\:text-cyan3:focus{
    color:#4CD1D0
  }

  .xl\:focus\:text-cyan4:focus{
    color:#B2ECEB
  }

  .xl\:focus\:text-cyan5:focus{
    color:#EDFAFA
  }

  .xl\:focus\:text-bg1:focus{
    color:#FAFAFC
  }

  .xl\:focus\:text-bg2:focus{
    color:#ffffff
  }

  .xl\:focus\:text-bg3:focus{
    color:#F7F7FA
  }

  .xl\:focus\:text-gray1:focus{
    color:#333333
  }

  .xl\:focus\:text-gray2:focus{
    color:#4D4D4D
  }

  .xl\:focus\:text-gray3:focus{
    color:#8C8C8C
  }

  .xl\:focus\:text-gray4:focus{
    color:#BFBFBF
  }

  .xl\:focus\:text-gray5:focus{
    color:#D9D9D9
  }

  .xl\:focus\:text-gray6:focus{
    color:#E9E9EB
  }

  .xl\:focus\:text-gray7:focus{
    color:#515164
  }

  .xl\:focus\:text-white:focus{
    color:#ffffff
  }

  .xl\:focus\:text-warning:focus{
    color:#FFC319
  }

  .xl\:focus\:text-error:focus{
    color:#EA696F
  }

  .xl\:focus\:text-info:focus{
    color:#0071B9
  }

  .xl\:focus\:text-success:focus{
    color:#98D45F
  }

  .xl\:focus\:text-special4:focus{
    color:#59779A
  }

  .xl\:focus\:text-dark:focus{
    color:#000000
  }

  .xl\:focus\:text-transparent:focus{
    color:transparent
  }

  .xl\:focus\:text-secondary1:focus{
    color:#313140
  }

  .xl\:focus\:text-secondary2:focus{
    color:#515164
  }

  .xl\:focus\:text-secondary3:focus{
    color:#9C9CAC
  }

  .xl\:focus\:text-secondary4:focus{
    color:#E5E8ED
  }

  .xl\:focus\:text-secondary5:focus{
    color:#F5F6F9
  }

  .xl\:focus\:text-green1:focus{
    color:#0DE298
  }

  .xl\:underline{
    text-decoration:underline
  }

  .xl\:line-through{
    text-decoration:line-through
  }

  .xl\:no-underline{
    text-decoration:none
  }

  .group:hover .xl\:group-hover\:underline{
    text-decoration:underline
  }

  .group:hover .xl\:group-hover\:line-through{
    text-decoration:line-through
  }

  .group:hover .xl\:group-hover\:no-underline{
    text-decoration:none
  }

  .xl\:focus-within\:underline:focus-within{
    text-decoration:underline
  }

  .xl\:focus-within\:line-through:focus-within{
    text-decoration:line-through
  }

  .xl\:focus-within\:no-underline:focus-within{
    text-decoration:none
  }

  .xl\:hover\:underline:hover{
    text-decoration:underline
  }

  .xl\:hover\:line-through:hover{
    text-decoration:line-through
  }

  .xl\:hover\:no-underline:hover{
    text-decoration:none
  }

  .xl\:focus\:underline:focus{
    text-decoration:underline
  }

  .xl\:focus\:line-through:focus{
    text-decoration:line-through
  }

  .xl\:focus\:no-underline:focus{
    text-decoration:none
  }

  .xl\:placeholder-red1::placeholder{
    color:#CC352C
  }

  .xl\:placeholder-red2::placeholder{
    color:#FF554B
  }

  .xl\:placeholder-red3::placeholder{
    color:#FF7B73
  }

  .xl\:placeholder-red4::placeholder{
    color:#FFF1F1
  }

  .xl\:placeholder-red5::placeholder{
    color:#FFF6F6
  }

  .xl\:placeholder-cyan1::placeholder{
    color:#009897
  }

  .xl\:placeholder-cyan2::placeholder{
    color:#00BFBD
  }

  .xl\:placeholder-cyan3::placeholder{
    color:#4CD1D0
  }

  .xl\:placeholder-cyan4::placeholder{
    color:#B2ECEB
  }

  .xl\:placeholder-cyan5::placeholder{
    color:#EDFAFA
  }

  .xl\:placeholder-bg1::placeholder{
    color:#FAFAFC
  }

  .xl\:placeholder-bg2::placeholder{
    color:#ffffff
  }

  .xl\:placeholder-bg3::placeholder{
    color:#F7F7FA
  }

  .xl\:placeholder-gray1::placeholder{
    color:#333333
  }

  .xl\:placeholder-gray2::placeholder{
    color:#4D4D4D
  }

  .xl\:placeholder-gray3::placeholder{
    color:#8C8C8C
  }

  .xl\:placeholder-gray4::placeholder{
    color:#BFBFBF
  }

  .xl\:placeholder-gray5::placeholder{
    color:#D9D9D9
  }

  .xl\:placeholder-gray6::placeholder{
    color:#E9E9EB
  }

  .xl\:placeholder-gray7::placeholder{
    color:#515164
  }

  .xl\:placeholder-white::placeholder{
    color:#ffffff
  }

  .xl\:placeholder-warning::placeholder{
    color:#FFC319
  }

  .xl\:placeholder-error::placeholder{
    color:#EA696F
  }

  .xl\:placeholder-info::placeholder{
    color:#0071B9
  }

  .xl\:placeholder-success::placeholder{
    color:#98D45F
  }

  .xl\:placeholder-special4::placeholder{
    color:#59779A
  }

  .xl\:placeholder-dark::placeholder{
    color:#000000
  }

  .xl\:placeholder-transparent::placeholder{
    color:transparent
  }

  .xl\:placeholder-secondary1::placeholder{
    color:#313140
  }

  .xl\:placeholder-secondary2::placeholder{
    color:#515164
  }

  .xl\:placeholder-secondary3::placeholder{
    color:#9C9CAC
  }

  .xl\:placeholder-secondary4::placeholder{
    color:#E5E8ED
  }

  .xl\:placeholder-secondary5::placeholder{
    color:#F5F6F9
  }

  .xl\:placeholder-green1::placeholder{
    color:#0DE298
  }

  .xl\:focus\:placeholder-red1:focus::placeholder{
    color:#CC352C
  }

  .xl\:focus\:placeholder-red2:focus::placeholder{
    color:#FF554B
  }

  .xl\:focus\:placeholder-red3:focus::placeholder{
    color:#FF7B73
  }

  .xl\:focus\:placeholder-red4:focus::placeholder{
    color:#FFF1F1
  }

  .xl\:focus\:placeholder-red5:focus::placeholder{
    color:#FFF6F6
  }

  .xl\:focus\:placeholder-cyan1:focus::placeholder{
    color:#009897
  }

  .xl\:focus\:placeholder-cyan2:focus::placeholder{
    color:#00BFBD
  }

  .xl\:focus\:placeholder-cyan3:focus::placeholder{
    color:#4CD1D0
  }

  .xl\:focus\:placeholder-cyan4:focus::placeholder{
    color:#B2ECEB
  }

  .xl\:focus\:placeholder-cyan5:focus::placeholder{
    color:#EDFAFA
  }

  .xl\:focus\:placeholder-bg1:focus::placeholder{
    color:#FAFAFC
  }

  .xl\:focus\:placeholder-bg2:focus::placeholder{
    color:#ffffff
  }

  .xl\:focus\:placeholder-bg3:focus::placeholder{
    color:#F7F7FA
  }

  .xl\:focus\:placeholder-gray1:focus::placeholder{
    color:#333333
  }

  .xl\:focus\:placeholder-gray2:focus::placeholder{
    color:#4D4D4D
  }

  .xl\:focus\:placeholder-gray3:focus::placeholder{
    color:#8C8C8C
  }

  .xl\:focus\:placeholder-gray4:focus::placeholder{
    color:#BFBFBF
  }

  .xl\:focus\:placeholder-gray5:focus::placeholder{
    color:#D9D9D9
  }

  .xl\:focus\:placeholder-gray6:focus::placeholder{
    color:#E9E9EB
  }

  .xl\:focus\:placeholder-gray7:focus::placeholder{
    color:#515164
  }

  .xl\:focus\:placeholder-white:focus::placeholder{
    color:#ffffff
  }

  .xl\:focus\:placeholder-warning:focus::placeholder{
    color:#FFC319
  }

  .xl\:focus\:placeholder-error:focus::placeholder{
    color:#EA696F
  }

  .xl\:focus\:placeholder-info:focus::placeholder{
    color:#0071B9
  }

  .xl\:focus\:placeholder-success:focus::placeholder{
    color:#98D45F
  }

  .xl\:focus\:placeholder-special4:focus::placeholder{
    color:#59779A
  }

  .xl\:focus\:placeholder-dark:focus::placeholder{
    color:#000000
  }

  .xl\:focus\:placeholder-transparent:focus::placeholder{
    color:transparent
  }

  .xl\:focus\:placeholder-secondary1:focus::placeholder{
    color:#313140
  }

  .xl\:focus\:placeholder-secondary2:focus::placeholder{
    color:#515164
  }

  .xl\:focus\:placeholder-secondary3:focus::placeholder{
    color:#9C9CAC
  }

  .xl\:focus\:placeholder-secondary4:focus::placeholder{
    color:#E5E8ED
  }

  .xl\:focus\:placeholder-secondary5:focus::placeholder{
    color:#F5F6F9
  }

  .xl\:focus\:placeholder-green1:focus::placeholder{
    color:#0DE298
  }

  .xl\:opacity-0{
    opacity:0
  }

  .xl\:opacity-5{
    opacity:0.05
  }

  .xl\:opacity-10{
    opacity:0.1
  }

  .xl\:opacity-20{
    opacity:0.2
  }

  .xl\:opacity-25{
    opacity:0.25
  }

  .xl\:opacity-30{
    opacity:0.3
  }

  .xl\:opacity-40{
    opacity:0.4
  }

  .xl\:opacity-50{
    opacity:0.5
  }

  .xl\:opacity-60{
    opacity:0.6
  }

  .xl\:opacity-70{
    opacity:0.7
  }

  .xl\:opacity-75{
    opacity:0.75
  }

  .xl\:opacity-80{
    opacity:0.8
  }

  .xl\:opacity-90{
    opacity:0.9
  }

  .xl\:opacity-95{
    opacity:0.95
  }

  .xl\:opacity-100{
    opacity:1
  }

  .group:hover .xl\:group-hover\:opacity-0{
    opacity:0
  }

  .group:hover .xl\:group-hover\:opacity-5{
    opacity:0.05
  }

  .group:hover .xl\:group-hover\:opacity-10{
    opacity:0.1
  }

  .group:hover .xl\:group-hover\:opacity-20{
    opacity:0.2
  }

  .group:hover .xl\:group-hover\:opacity-25{
    opacity:0.25
  }

  .group:hover .xl\:group-hover\:opacity-30{
    opacity:0.3
  }

  .group:hover .xl\:group-hover\:opacity-40{
    opacity:0.4
  }

  .group:hover .xl\:group-hover\:opacity-50{
    opacity:0.5
  }

  .group:hover .xl\:group-hover\:opacity-60{
    opacity:0.6
  }

  .group:hover .xl\:group-hover\:opacity-70{
    opacity:0.7
  }

  .group:hover .xl\:group-hover\:opacity-75{
    opacity:0.75
  }

  .group:hover .xl\:group-hover\:opacity-80{
    opacity:0.8
  }

  .group:hover .xl\:group-hover\:opacity-90{
    opacity:0.9
  }

  .group:hover .xl\:group-hover\:opacity-95{
    opacity:0.95
  }

  .group:hover .xl\:group-hover\:opacity-100{
    opacity:1
  }

  .xl\:focus-within\:opacity-0:focus-within{
    opacity:0
  }

  .xl\:focus-within\:opacity-5:focus-within{
    opacity:0.05
  }

  .xl\:focus-within\:opacity-10:focus-within{
    opacity:0.1
  }

  .xl\:focus-within\:opacity-20:focus-within{
    opacity:0.2
  }

  .xl\:focus-within\:opacity-25:focus-within{
    opacity:0.25
  }

  .xl\:focus-within\:opacity-30:focus-within{
    opacity:0.3
  }

  .xl\:focus-within\:opacity-40:focus-within{
    opacity:0.4
  }

  .xl\:focus-within\:opacity-50:focus-within{
    opacity:0.5
  }

  .xl\:focus-within\:opacity-60:focus-within{
    opacity:0.6
  }

  .xl\:focus-within\:opacity-70:focus-within{
    opacity:0.7
  }

  .xl\:focus-within\:opacity-75:focus-within{
    opacity:0.75
  }

  .xl\:focus-within\:opacity-80:focus-within{
    opacity:0.8
  }

  .xl\:focus-within\:opacity-90:focus-within{
    opacity:0.9
  }

  .xl\:focus-within\:opacity-95:focus-within{
    opacity:0.95
  }

  .xl\:focus-within\:opacity-100:focus-within{
    opacity:1
  }

  .xl\:hover\:opacity-0:hover{
    opacity:0
  }

  .xl\:hover\:opacity-5:hover{
    opacity:0.05
  }

  .xl\:hover\:opacity-10:hover{
    opacity:0.1
  }

  .xl\:hover\:opacity-20:hover{
    opacity:0.2
  }

  .xl\:hover\:opacity-25:hover{
    opacity:0.25
  }

  .xl\:hover\:opacity-30:hover{
    opacity:0.3
  }

  .xl\:hover\:opacity-40:hover{
    opacity:0.4
  }

  .xl\:hover\:opacity-50:hover{
    opacity:0.5
  }

  .xl\:hover\:opacity-60:hover{
    opacity:0.6
  }

  .xl\:hover\:opacity-70:hover{
    opacity:0.7
  }

  .xl\:hover\:opacity-75:hover{
    opacity:0.75
  }

  .xl\:hover\:opacity-80:hover{
    opacity:0.8
  }

  .xl\:hover\:opacity-90:hover{
    opacity:0.9
  }

  .xl\:hover\:opacity-95:hover{
    opacity:0.95
  }

  .xl\:hover\:opacity-100:hover{
    opacity:1
  }

  .xl\:focus\:opacity-0:focus{
    opacity:0
  }

  .xl\:focus\:opacity-5:focus{
    opacity:0.05
  }

  .xl\:focus\:opacity-10:focus{
    opacity:0.1
  }

  .xl\:focus\:opacity-20:focus{
    opacity:0.2
  }

  .xl\:focus\:opacity-25:focus{
    opacity:0.25
  }

  .xl\:focus\:opacity-30:focus{
    opacity:0.3
  }

  .xl\:focus\:opacity-40:focus{
    opacity:0.4
  }

  .xl\:focus\:opacity-50:focus{
    opacity:0.5
  }

  .xl\:focus\:opacity-60:focus{
    opacity:0.6
  }

  .xl\:focus\:opacity-70:focus{
    opacity:0.7
  }

  .xl\:focus\:opacity-75:focus{
    opacity:0.75
  }

  .xl\:focus\:opacity-80:focus{
    opacity:0.8
  }

  .xl\:focus\:opacity-90:focus{
    opacity:0.9
  }

  .xl\:focus\:opacity-95:focus{
    opacity:0.95
  }

  .xl\:focus\:opacity-100:focus{
    opacity:1
  }

  .xl\:bg-blend-normal{
    background-blend-mode:normal
  }

  .xl\:bg-blend-multiply{
    background-blend-mode:multiply
  }

  .xl\:bg-blend-screen{
    background-blend-mode:screen
  }

  .xl\:bg-blend-overlay{
    background-blend-mode:overlay
  }

  .xl\:bg-blend-darken{
    background-blend-mode:darken
  }

  .xl\:bg-blend-lighten{
    background-blend-mode:lighten
  }

  .xl\:bg-blend-color-dodge{
    background-blend-mode:color-dodge
  }

  .xl\:bg-blend-color-burn{
    background-blend-mode:color-burn
  }

  .xl\:bg-blend-hard-light{
    background-blend-mode:hard-light
  }

  .xl\:bg-blend-soft-light{
    background-blend-mode:soft-light
  }

  .xl\:bg-blend-difference{
    background-blend-mode:difference
  }

  .xl\:bg-blend-exclusion{
    background-blend-mode:exclusion
  }

  .xl\:bg-blend-hue{
    background-blend-mode:hue
  }

  .xl\:bg-blend-saturation{
    background-blend-mode:saturation
  }

  .xl\:bg-blend-color{
    background-blend-mode:color
  }

  .xl\:bg-blend-luminosity{
    background-blend-mode:luminosity
  }

  .xl\:mix-blend-normal{
    mix-blend-mode:normal
  }

  .xl\:mix-blend-multiply{
    mix-blend-mode:multiply
  }

  .xl\:mix-blend-screen{
    mix-blend-mode:screen
  }

  .xl\:mix-blend-overlay{
    mix-blend-mode:overlay
  }

  .xl\:mix-blend-darken{
    mix-blend-mode:darken
  }

  .xl\:mix-blend-lighten{
    mix-blend-mode:lighten
  }

  .xl\:mix-blend-color-dodge{
    mix-blend-mode:color-dodge
  }

  .xl\:mix-blend-color-burn{
    mix-blend-mode:color-burn
  }

  .xl\:mix-blend-hard-light{
    mix-blend-mode:hard-light
  }

  .xl\:mix-blend-soft-light{
    mix-blend-mode:soft-light
  }

  .xl\:mix-blend-difference{
    mix-blend-mode:difference
  }

  .xl\:mix-blend-exclusion{
    mix-blend-mode:exclusion
  }

  .xl\:mix-blend-hue{
    mix-blend-mode:hue
  }

  .xl\:mix-blend-saturation{
    mix-blend-mode:saturation
  }

  .xl\:mix-blend-color{
    mix-blend-mode:color
  }

  .xl\:mix-blend-luminosity{
    mix-blend-mode:luminosity
  }

  .xl\:shadow-panel{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:shadow-popup{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-panel{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xl\:group-hover\:shadow-popup{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-panel:focus-within{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-hover:focus-within{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus-within\:shadow-popup:focus-within{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-panel:hover{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-hover:hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:hover\:shadow-popup:hover{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-panel:focus{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-hover:focus{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:focus\:shadow-popup:focus{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xl\:outline-none{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .xl\:outline-white{
    outline:2px dotted white;
    outline-offset:2px
  }

  .xl\:outline-black{
    outline:2px dotted black;
    outline-offset:2px
  }

  .xl\:focus-within\:outline-none:focus-within{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .xl\:focus-within\:outline-white:focus-within{
    outline:2px dotted white;
    outline-offset:2px
  }

  .xl\:focus-within\:outline-black:focus-within{
    outline:2px dotted black;
    outline-offset:2px
  }

  .xl\:focus\:outline-none:focus{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .xl\:focus\:outline-white:focus{
    outline:2px dotted white;
    outline-offset:2px
  }

  .xl\:focus\:outline-black:focus{
    outline:2px dotted black;
    outline-offset:2px
  }

  .xl\:ring-0{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring-1{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring-2{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring-4{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring-8{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring-0:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring-1:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring-4:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring-8:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus-within\:ring:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring-0:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring-1:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring-2:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring-4:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring-8:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:focus\:ring:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xl\:ring-inset{
    --tw-ring-inset:inset
  }

  .xl\:focus-within\:ring-inset:focus-within{
    --tw-ring-inset:inset
  }

  .xl\:focus\:ring-inset:focus{
    --tw-ring-inset:inset
  }

  .xl\:ring-red1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .xl\:ring-red2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .xl\:ring-red3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .xl\:ring-red4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .xl\:ring-red5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .xl\:ring-cyan5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .xl\:ring-bg1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .xl\:ring-bg2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-bg3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .xl\:ring-gray1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .xl\:ring-gray2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .xl\:ring-gray3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .xl\:ring-gray4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .xl\:ring-gray5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .xl\:ring-gray6{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .xl\:ring-gray7{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xl\:ring-white{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:ring-warning{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .xl\:ring-error{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .xl\:ring-info{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .xl\:ring-success{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .xl\:ring-special4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .xl\:ring-dark{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .xl\:ring-transparent{
    --tw-ring-color:transparent
  }

  .xl\:ring-secondary1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .xl\:ring-secondary2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xl\:ring-secondary3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .xl\:ring-secondary4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .xl\:ring-secondary5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .xl\:ring-green1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-red5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-cyan5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-bg1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-bg2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-bg3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray6:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-gray7:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-white:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-warning:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-error:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-info:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-success:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-special4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-dark:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-transparent:focus-within{
    --tw-ring-color:transparent
  }

  .xl\:focus-within\:ring-secondary1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-secondary2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-secondary3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-secondary4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-secondary5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .xl\:focus-within\:ring-green1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-red5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-cyan5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-bg1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-bg2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-bg3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray6:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-gray7:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-white:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-warning:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-error:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-info:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-success:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-special4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-dark:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-transparent:focus{
    --tw-ring-color:transparent
  }

  .xl\:focus\:ring-secondary1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-secondary2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-secondary3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-secondary4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-secondary5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .xl\:focus\:ring-green1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .xl\:ring-opacity-0{
    --tw-ring-opacity:0
  }

  .xl\:ring-opacity-5{
    --tw-ring-opacity:0.05
  }

  .xl\:ring-opacity-10{
    --tw-ring-opacity:0.1
  }

  .xl\:ring-opacity-20{
    --tw-ring-opacity:0.2
  }

  .xl\:ring-opacity-25{
    --tw-ring-opacity:0.25
  }

  .xl\:ring-opacity-30{
    --tw-ring-opacity:0.3
  }

  .xl\:ring-opacity-40{
    --tw-ring-opacity:0.4
  }

  .xl\:ring-opacity-50{
    --tw-ring-opacity:0.5
  }

  .xl\:ring-opacity-60{
    --tw-ring-opacity:0.6
  }

  .xl\:ring-opacity-70{
    --tw-ring-opacity:0.7
  }

  .xl\:ring-opacity-75{
    --tw-ring-opacity:0.75
  }

  .xl\:ring-opacity-80{
    --tw-ring-opacity:0.8
  }

  .xl\:ring-opacity-90{
    --tw-ring-opacity:0.9
  }

  .xl\:ring-opacity-95{
    --tw-ring-opacity:0.95
  }

  .xl\:ring-opacity-100{
    --tw-ring-opacity:1
  }

  .xl\:focus-within\:ring-opacity-0:focus-within{
    --tw-ring-opacity:0
  }

  .xl\:focus-within\:ring-opacity-5:focus-within{
    --tw-ring-opacity:0.05
  }

  .xl\:focus-within\:ring-opacity-10:focus-within{
    --tw-ring-opacity:0.1
  }

  .xl\:focus-within\:ring-opacity-20:focus-within{
    --tw-ring-opacity:0.2
  }

  .xl\:focus-within\:ring-opacity-25:focus-within{
    --tw-ring-opacity:0.25
  }

  .xl\:focus-within\:ring-opacity-30:focus-within{
    --tw-ring-opacity:0.3
  }

  .xl\:focus-within\:ring-opacity-40:focus-within{
    --tw-ring-opacity:0.4
  }

  .xl\:focus-within\:ring-opacity-50:focus-within{
    --tw-ring-opacity:0.5
  }

  .xl\:focus-within\:ring-opacity-60:focus-within{
    --tw-ring-opacity:0.6
  }

  .xl\:focus-within\:ring-opacity-70:focus-within{
    --tw-ring-opacity:0.7
  }

  .xl\:focus-within\:ring-opacity-75:focus-within{
    --tw-ring-opacity:0.75
  }

  .xl\:focus-within\:ring-opacity-80:focus-within{
    --tw-ring-opacity:0.8
  }

  .xl\:focus-within\:ring-opacity-90:focus-within{
    --tw-ring-opacity:0.9
  }

  .xl\:focus-within\:ring-opacity-95:focus-within{
    --tw-ring-opacity:0.95
  }

  .xl\:focus-within\:ring-opacity-100:focus-within{
    --tw-ring-opacity:1
  }

  .xl\:focus\:ring-opacity-0:focus{
    --tw-ring-opacity:0
  }

  .xl\:focus\:ring-opacity-5:focus{
    --tw-ring-opacity:0.05
  }

  .xl\:focus\:ring-opacity-10:focus{
    --tw-ring-opacity:0.1
  }

  .xl\:focus\:ring-opacity-20:focus{
    --tw-ring-opacity:0.2
  }

  .xl\:focus\:ring-opacity-25:focus{
    --tw-ring-opacity:0.25
  }

  .xl\:focus\:ring-opacity-30:focus{
    --tw-ring-opacity:0.3
  }

  .xl\:focus\:ring-opacity-40:focus{
    --tw-ring-opacity:0.4
  }

  .xl\:focus\:ring-opacity-50:focus{
    --tw-ring-opacity:0.5
  }

  .xl\:focus\:ring-opacity-60:focus{
    --tw-ring-opacity:0.6
  }

  .xl\:focus\:ring-opacity-70:focus{
    --tw-ring-opacity:0.7
  }

  .xl\:focus\:ring-opacity-75:focus{
    --tw-ring-opacity:0.75
  }

  .xl\:focus\:ring-opacity-80:focus{
    --tw-ring-opacity:0.8
  }

  .xl\:focus\:ring-opacity-90:focus{
    --tw-ring-opacity:0.9
  }

  .xl\:focus\:ring-opacity-95:focus{
    --tw-ring-opacity:0.95
  }

  .xl\:focus\:ring-opacity-100:focus{
    --tw-ring-opacity:1
  }

  .xl\:ring-offset-0{
    --tw-ring-offset-width:0px
  }

  .xl\:ring-offset-1{
    --tw-ring-offset-width:1px
  }

  .xl\:ring-offset-2{
    --tw-ring-offset-width:2px
  }

  .xl\:ring-offset-4{
    --tw-ring-offset-width:4px
  }

  .xl\:ring-offset-8{
    --tw-ring-offset-width:8px
  }

  .xl\:focus-within\:ring-offset-0:focus-within{
    --tw-ring-offset-width:0px
  }

  .xl\:focus-within\:ring-offset-1:focus-within{
    --tw-ring-offset-width:1px
  }

  .xl\:focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width:2px
  }

  .xl\:focus-within\:ring-offset-4:focus-within{
    --tw-ring-offset-width:4px
  }

  .xl\:focus-within\:ring-offset-8:focus-within{
    --tw-ring-offset-width:8px
  }

  .xl\:focus\:ring-offset-0:focus{
    --tw-ring-offset-width:0px
  }

  .xl\:focus\:ring-offset-1:focus{
    --tw-ring-offset-width:1px
  }

  .xl\:focus\:ring-offset-2:focus{
    --tw-ring-offset-width:2px
  }

  .xl\:focus\:ring-offset-4:focus{
    --tw-ring-offset-width:4px
  }

  .xl\:focus\:ring-offset-8:focus{
    --tw-ring-offset-width:8px
  }

  .xl\:ring-offset-red1{
    --tw-ring-offset-color:#CC352C
  }

  .xl\:ring-offset-red2{
    --tw-ring-offset-color:#FF554B
  }

  .xl\:ring-offset-red3{
    --tw-ring-offset-color:#FF7B73
  }

  .xl\:ring-offset-red4{
    --tw-ring-offset-color:#FFF1F1
  }

  .xl\:ring-offset-red5{
    --tw-ring-offset-color:#FFF6F6
  }

  .xl\:ring-offset-cyan1{
    --tw-ring-offset-color:#009897
  }

  .xl\:ring-offset-cyan2{
    --tw-ring-offset-color:#00BFBD
  }

  .xl\:ring-offset-cyan3{
    --tw-ring-offset-color:#4CD1D0
  }

  .xl\:ring-offset-cyan4{
    --tw-ring-offset-color:#B2ECEB
  }

  .xl\:ring-offset-cyan5{
    --tw-ring-offset-color:#EDFAFA
  }

  .xl\:ring-offset-bg1{
    --tw-ring-offset-color:#FAFAFC
  }

  .xl\:ring-offset-bg2{
    --tw-ring-offset-color:#ffffff
  }

  .xl\:ring-offset-bg3{
    --tw-ring-offset-color:#F7F7FA
  }

  .xl\:ring-offset-gray1{
    --tw-ring-offset-color:#333333
  }

  .xl\:ring-offset-gray2{
    --tw-ring-offset-color:#4D4D4D
  }

  .xl\:ring-offset-gray3{
    --tw-ring-offset-color:#8C8C8C
  }

  .xl\:ring-offset-gray4{
    --tw-ring-offset-color:#BFBFBF
  }

  .xl\:ring-offset-gray5{
    --tw-ring-offset-color:#D9D9D9
  }

  .xl\:ring-offset-gray6{
    --tw-ring-offset-color:#E9E9EB
  }

  .xl\:ring-offset-gray7{
    --tw-ring-offset-color:#515164
  }

  .xl\:ring-offset-white{
    --tw-ring-offset-color:#ffffff
  }

  .xl\:ring-offset-warning{
    --tw-ring-offset-color:#FFC319
  }

  .xl\:ring-offset-error{
    --tw-ring-offset-color:#EA696F
  }

  .xl\:ring-offset-info{
    --tw-ring-offset-color:#0071B9
  }

  .xl\:ring-offset-success{
    --tw-ring-offset-color:#98D45F
  }

  .xl\:ring-offset-special4{
    --tw-ring-offset-color:#59779A
  }

  .xl\:ring-offset-dark{
    --tw-ring-offset-color:#000000
  }

  .xl\:ring-offset-transparent{
    --tw-ring-offset-color:transparent
  }

  .xl\:ring-offset-secondary1{
    --tw-ring-offset-color:#313140
  }

  .xl\:ring-offset-secondary2{
    --tw-ring-offset-color:#515164
  }

  .xl\:ring-offset-secondary3{
    --tw-ring-offset-color:#9C9CAC
  }

  .xl\:ring-offset-secondary4{
    --tw-ring-offset-color:#E5E8ED
  }

  .xl\:ring-offset-secondary5{
    --tw-ring-offset-color:#F5F6F9
  }

  .xl\:ring-offset-green1{
    --tw-ring-offset-color:#0DE298
  }

  .xl\:focus-within\:ring-offset-red1:focus-within{
    --tw-ring-offset-color:#CC352C
  }

  .xl\:focus-within\:ring-offset-red2:focus-within{
    --tw-ring-offset-color:#FF554B
  }

  .xl\:focus-within\:ring-offset-red3:focus-within{
    --tw-ring-offset-color:#FF7B73
  }

  .xl\:focus-within\:ring-offset-red4:focus-within{
    --tw-ring-offset-color:#FFF1F1
  }

  .xl\:focus-within\:ring-offset-red5:focus-within{
    --tw-ring-offset-color:#FFF6F6
  }

  .xl\:focus-within\:ring-offset-cyan1:focus-within{
    --tw-ring-offset-color:#009897
  }

  .xl\:focus-within\:ring-offset-cyan2:focus-within{
    --tw-ring-offset-color:#00BFBD
  }

  .xl\:focus-within\:ring-offset-cyan3:focus-within{
    --tw-ring-offset-color:#4CD1D0
  }

  .xl\:focus-within\:ring-offset-cyan4:focus-within{
    --tw-ring-offset-color:#B2ECEB
  }

  .xl\:focus-within\:ring-offset-cyan5:focus-within{
    --tw-ring-offset-color:#EDFAFA
  }

  .xl\:focus-within\:ring-offset-bg1:focus-within{
    --tw-ring-offset-color:#FAFAFC
  }

  .xl\:focus-within\:ring-offset-bg2:focus-within{
    --tw-ring-offset-color:#ffffff
  }

  .xl\:focus-within\:ring-offset-bg3:focus-within{
    --tw-ring-offset-color:#F7F7FA
  }

  .xl\:focus-within\:ring-offset-gray1:focus-within{
    --tw-ring-offset-color:#333333
  }

  .xl\:focus-within\:ring-offset-gray2:focus-within{
    --tw-ring-offset-color:#4D4D4D
  }

  .xl\:focus-within\:ring-offset-gray3:focus-within{
    --tw-ring-offset-color:#8C8C8C
  }

  .xl\:focus-within\:ring-offset-gray4:focus-within{
    --tw-ring-offset-color:#BFBFBF
  }

  .xl\:focus-within\:ring-offset-gray5:focus-within{
    --tw-ring-offset-color:#D9D9D9
  }

  .xl\:focus-within\:ring-offset-gray6:focus-within{
    --tw-ring-offset-color:#E9E9EB
  }

  .xl\:focus-within\:ring-offset-gray7:focus-within{
    --tw-ring-offset-color:#515164
  }

  .xl\:focus-within\:ring-offset-white:focus-within{
    --tw-ring-offset-color:#ffffff
  }

  .xl\:focus-within\:ring-offset-warning:focus-within{
    --tw-ring-offset-color:#FFC319
  }

  .xl\:focus-within\:ring-offset-error:focus-within{
    --tw-ring-offset-color:#EA696F
  }

  .xl\:focus-within\:ring-offset-info:focus-within{
    --tw-ring-offset-color:#0071B9
  }

  .xl\:focus-within\:ring-offset-success:focus-within{
    --tw-ring-offset-color:#98D45F
  }

  .xl\:focus-within\:ring-offset-special4:focus-within{
    --tw-ring-offset-color:#59779A
  }

  .xl\:focus-within\:ring-offset-dark:focus-within{
    --tw-ring-offset-color:#000000
  }

  .xl\:focus-within\:ring-offset-transparent:focus-within{
    --tw-ring-offset-color:transparent
  }

  .xl\:focus-within\:ring-offset-secondary1:focus-within{
    --tw-ring-offset-color:#313140
  }

  .xl\:focus-within\:ring-offset-secondary2:focus-within{
    --tw-ring-offset-color:#515164
  }

  .xl\:focus-within\:ring-offset-secondary3:focus-within{
    --tw-ring-offset-color:#9C9CAC
  }

  .xl\:focus-within\:ring-offset-secondary4:focus-within{
    --tw-ring-offset-color:#E5E8ED
  }

  .xl\:focus-within\:ring-offset-secondary5:focus-within{
    --tw-ring-offset-color:#F5F6F9
  }

  .xl\:focus-within\:ring-offset-green1:focus-within{
    --tw-ring-offset-color:#0DE298
  }

  .xl\:focus\:ring-offset-red1:focus{
    --tw-ring-offset-color:#CC352C
  }

  .xl\:focus\:ring-offset-red2:focus{
    --tw-ring-offset-color:#FF554B
  }

  .xl\:focus\:ring-offset-red3:focus{
    --tw-ring-offset-color:#FF7B73
  }

  .xl\:focus\:ring-offset-red4:focus{
    --tw-ring-offset-color:#FFF1F1
  }

  .xl\:focus\:ring-offset-red5:focus{
    --tw-ring-offset-color:#FFF6F6
  }

  .xl\:focus\:ring-offset-cyan1:focus{
    --tw-ring-offset-color:#009897
  }

  .xl\:focus\:ring-offset-cyan2:focus{
    --tw-ring-offset-color:#00BFBD
  }

  .xl\:focus\:ring-offset-cyan3:focus{
    --tw-ring-offset-color:#4CD1D0
  }

  .xl\:focus\:ring-offset-cyan4:focus{
    --tw-ring-offset-color:#B2ECEB
  }

  .xl\:focus\:ring-offset-cyan5:focus{
    --tw-ring-offset-color:#EDFAFA
  }

  .xl\:focus\:ring-offset-bg1:focus{
    --tw-ring-offset-color:#FAFAFC
  }

  .xl\:focus\:ring-offset-bg2:focus{
    --tw-ring-offset-color:#ffffff
  }

  .xl\:focus\:ring-offset-bg3:focus{
    --tw-ring-offset-color:#F7F7FA
  }

  .xl\:focus\:ring-offset-gray1:focus{
    --tw-ring-offset-color:#333333
  }

  .xl\:focus\:ring-offset-gray2:focus{
    --tw-ring-offset-color:#4D4D4D
  }

  .xl\:focus\:ring-offset-gray3:focus{
    --tw-ring-offset-color:#8C8C8C
  }

  .xl\:focus\:ring-offset-gray4:focus{
    --tw-ring-offset-color:#BFBFBF
  }

  .xl\:focus\:ring-offset-gray5:focus{
    --tw-ring-offset-color:#D9D9D9
  }

  .xl\:focus\:ring-offset-gray6:focus{
    --tw-ring-offset-color:#E9E9EB
  }

  .xl\:focus\:ring-offset-gray7:focus{
    --tw-ring-offset-color:#515164
  }

  .xl\:focus\:ring-offset-white:focus{
    --tw-ring-offset-color:#ffffff
  }

  .xl\:focus\:ring-offset-warning:focus{
    --tw-ring-offset-color:#FFC319
  }

  .xl\:focus\:ring-offset-error:focus{
    --tw-ring-offset-color:#EA696F
  }

  .xl\:focus\:ring-offset-info:focus{
    --tw-ring-offset-color:#0071B9
  }

  .xl\:focus\:ring-offset-success:focus{
    --tw-ring-offset-color:#98D45F
  }

  .xl\:focus\:ring-offset-special4:focus{
    --tw-ring-offset-color:#59779A
  }

  .xl\:focus\:ring-offset-dark:focus{
    --tw-ring-offset-color:#000000
  }

  .xl\:focus\:ring-offset-transparent:focus{
    --tw-ring-offset-color:transparent
  }

  .xl\:focus\:ring-offset-secondary1:focus{
    --tw-ring-offset-color:#313140
  }

  .xl\:focus\:ring-offset-secondary2:focus{
    --tw-ring-offset-color:#515164
  }

  .xl\:focus\:ring-offset-secondary3:focus{
    --tw-ring-offset-color:#9C9CAC
  }

  .xl\:focus\:ring-offset-secondary4:focus{
    --tw-ring-offset-color:#E5E8ED
  }

  .xl\:focus\:ring-offset-secondary5:focus{
    --tw-ring-offset-color:#F5F6F9
  }

  .xl\:focus\:ring-offset-green1:focus{
    --tw-ring-offset-color:#0DE298
  }

  .xl\:filter{
    --tw-blur:var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness:var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast:var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale:var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
    --tw-invert:var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate:var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia:var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow:var(--tw-empty,/*!*/ /*!*/);
    filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }

  .xl\:filter-none{
    filter:none
  }

  .xl\:blur-0{
    --tw-blur:blur(0)
  }

  .xl\:blur-none{
    --tw-blur:blur(0)
  }

  .xl\:blur-sm{
    --tw-blur:blur(4px)
  }

  .xl\:blur{
    --tw-blur:blur(8px)
  }

  .xl\:blur-md{
    --tw-blur:blur(12px)
  }

  .xl\:blur-lg{
    --tw-blur:blur(16px)
  }

  .xl\:blur-xl{
    --tw-blur:blur(24px)
  }

  .xl\:blur-2xl{
    --tw-blur:blur(40px)
  }

  .xl\:blur-3xl{
    --tw-blur:blur(64px)
  }

  .xl\:brightness-0{
    --tw-brightness:brightness(0)
  }

  .xl\:brightness-50{
    --tw-brightness:brightness(.5)
  }

  .xl\:brightness-75{
    --tw-brightness:brightness(.75)
  }

  .xl\:brightness-90{
    --tw-brightness:brightness(.9)
  }

  .xl\:brightness-95{
    --tw-brightness:brightness(.95)
  }

  .xl\:brightness-100{
    --tw-brightness:brightness(1)
  }

  .xl\:brightness-105{
    --tw-brightness:brightness(1.05)
  }

  .xl\:brightness-110{
    --tw-brightness:brightness(1.1)
  }

  .xl\:brightness-125{
    --tw-brightness:brightness(1.25)
  }

  .xl\:brightness-150{
    --tw-brightness:brightness(1.5)
  }

  .xl\:brightness-200{
    --tw-brightness:brightness(2)
  }

  .xl\:contrast-0{
    --tw-contrast:contrast(0)
  }

  .xl\:contrast-50{
    --tw-contrast:contrast(.5)
  }

  .xl\:contrast-75{
    --tw-contrast:contrast(.75)
  }

  .xl\:contrast-100{
    --tw-contrast:contrast(1)
  }

  .xl\:contrast-125{
    --tw-contrast:contrast(1.25)
  }

  .xl\:contrast-150{
    --tw-contrast:contrast(1.5)
  }

  .xl\:contrast-200{
    --tw-contrast:contrast(2)
  }

  .xl\:drop-shadow-sm{
    --tw-drop-shadow:drop-shadow(0 1px 1px rgba(0,0,0,0.05))
  }

  .xl\:drop-shadow{
    --tw-drop-shadow:drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
  }

  .xl\:drop-shadow-md{
    --tw-drop-shadow:drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
  }

  .xl\:drop-shadow-lg{
    --tw-drop-shadow:drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
  }

  .xl\:drop-shadow-xl{
    --tw-drop-shadow:drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
  }

  .xl\:drop-shadow-2xl{
    --tw-drop-shadow:drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
  }

  .xl\:drop-shadow-none{
    --tw-drop-shadow:drop-shadow(0 0 #0000)
  }

  .xl\:grayscale-0{
    --tw-grayscale:grayscale(0)
  }

  .xl\:grayscale{
    --tw-grayscale:grayscale(100%)
  }

  .xl\:hue-rotate-0{
    --tw-hue-rotate:hue-rotate(0deg)
  }

  .xl\:hue-rotate-15{
    --tw-hue-rotate:hue-rotate(15deg)
  }

  .xl\:hue-rotate-30{
    --tw-hue-rotate:hue-rotate(30deg)
  }

  .xl\:hue-rotate-60{
    --tw-hue-rotate:hue-rotate(60deg)
  }

  .xl\:hue-rotate-90{
    --tw-hue-rotate:hue-rotate(90deg)
  }

  .xl\:hue-rotate-180{
    --tw-hue-rotate:hue-rotate(180deg)
  }

  .xl\:-hue-rotate-180{
    --tw-hue-rotate:hue-rotate(-180deg)
  }

  .xl\:-hue-rotate-90{
    --tw-hue-rotate:hue-rotate(-90deg)
  }

  .xl\:-hue-rotate-60{
    --tw-hue-rotate:hue-rotate(-60deg)
  }

  .xl\:-hue-rotate-30{
    --tw-hue-rotate:hue-rotate(-30deg)
  }

  .xl\:-hue-rotate-15{
    --tw-hue-rotate:hue-rotate(-15deg)
  }

  .xl\:invert-0{
    --tw-invert:invert(0)
  }

  .xl\:invert{
    --tw-invert:invert(100%)
  }

  .xl\:saturate-0{
    --tw-saturate:saturate(0)
  }

  .xl\:saturate-50{
    --tw-saturate:saturate(.5)
  }

  .xl\:saturate-100{
    --tw-saturate:saturate(1)
  }

  .xl\:saturate-150{
    --tw-saturate:saturate(1.5)
  }

  .xl\:saturate-200{
    --tw-saturate:saturate(2)
  }

  .xl\:sepia-0{
    --tw-sepia:sepia(0)
  }

  .xl\:sepia{
    --tw-sepia:sepia(100%)
  }

  .xl\:backdrop-filter{
    --tw-backdrop-blur:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia:var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  .xl\:backdrop-filter-none{
    -webkit-backdrop-filter:none;
            backdrop-filter:none
  }

  .xl\:backdrop-blur-0{
    --tw-backdrop-blur:blur(0)
  }

  .xl\:backdrop-blur-none{
    --tw-backdrop-blur:blur(0)
  }

  .xl\:backdrop-blur-sm{
    --tw-backdrop-blur:blur(4px)
  }

  .xl\:backdrop-blur{
    --tw-backdrop-blur:blur(8px)
  }

  .xl\:backdrop-blur-md{
    --tw-backdrop-blur:blur(12px)
  }

  .xl\:backdrop-blur-lg{
    --tw-backdrop-blur:blur(16px)
  }

  .xl\:backdrop-blur-xl{
    --tw-backdrop-blur:blur(24px)
  }

  .xl\:backdrop-blur-2xl{
    --tw-backdrop-blur:blur(40px)
  }

  .xl\:backdrop-blur-3xl{
    --tw-backdrop-blur:blur(64px)
  }

  .xl\:backdrop-brightness-0{
    --tw-backdrop-brightness:brightness(0)
  }

  .xl\:backdrop-brightness-50{
    --tw-backdrop-brightness:brightness(.5)
  }

  .xl\:backdrop-brightness-75{
    --tw-backdrop-brightness:brightness(.75)
  }

  .xl\:backdrop-brightness-90{
    --tw-backdrop-brightness:brightness(.9)
  }

  .xl\:backdrop-brightness-95{
    --tw-backdrop-brightness:brightness(.95)
  }

  .xl\:backdrop-brightness-100{
    --tw-backdrop-brightness:brightness(1)
  }

  .xl\:backdrop-brightness-105{
    --tw-backdrop-brightness:brightness(1.05)
  }

  .xl\:backdrop-brightness-110{
    --tw-backdrop-brightness:brightness(1.1)
  }

  .xl\:backdrop-brightness-125{
    --tw-backdrop-brightness:brightness(1.25)
  }

  .xl\:backdrop-brightness-150{
    --tw-backdrop-brightness:brightness(1.5)
  }

  .xl\:backdrop-brightness-200{
    --tw-backdrop-brightness:brightness(2)
  }

  .xl\:backdrop-contrast-0{
    --tw-backdrop-contrast:contrast(0)
  }

  .xl\:backdrop-contrast-50{
    --tw-backdrop-contrast:contrast(.5)
  }

  .xl\:backdrop-contrast-75{
    --tw-backdrop-contrast:contrast(.75)
  }

  .xl\:backdrop-contrast-100{
    --tw-backdrop-contrast:contrast(1)
  }

  .xl\:backdrop-contrast-125{
    --tw-backdrop-contrast:contrast(1.25)
  }

  .xl\:backdrop-contrast-150{
    --tw-backdrop-contrast:contrast(1.5)
  }

  .xl\:backdrop-contrast-200{
    --tw-backdrop-contrast:contrast(2)
  }

  .xl\:backdrop-grayscale-0{
    --tw-backdrop-grayscale:grayscale(0)
  }

  .xl\:backdrop-grayscale{
    --tw-backdrop-grayscale:grayscale(100%)
  }

  .xl\:backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate:hue-rotate(0deg)
  }

  .xl\:backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate:hue-rotate(15deg)
  }

  .xl\:backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate:hue-rotate(30deg)
  }

  .xl\:backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate:hue-rotate(60deg)
  }

  .xl\:backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate:hue-rotate(90deg)
  }

  .xl\:backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate:hue-rotate(180deg)
  }

  .xl\:-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate:hue-rotate(-180deg)
  }

  .xl\:-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate:hue-rotate(-90deg)
  }

  .xl\:-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate:hue-rotate(-60deg)
  }

  .xl\:-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate:hue-rotate(-30deg)
  }

  .xl\:-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate:hue-rotate(-15deg)
  }

  .xl\:backdrop-invert-0{
    --tw-backdrop-invert:invert(0)
  }

  .xl\:backdrop-invert{
    --tw-backdrop-invert:invert(100%)
  }

  .xl\:backdrop-opacity-0{
    --tw-backdrop-opacity:opacity(0)
  }

  .xl\:backdrop-opacity-5{
    --tw-backdrop-opacity:opacity(0.05)
  }

  .xl\:backdrop-opacity-10{
    --tw-backdrop-opacity:opacity(0.1)
  }

  .xl\:backdrop-opacity-20{
    --tw-backdrop-opacity:opacity(0.2)
  }

  .xl\:backdrop-opacity-25{
    --tw-backdrop-opacity:opacity(0.25)
  }

  .xl\:backdrop-opacity-30{
    --tw-backdrop-opacity:opacity(0.3)
  }

  .xl\:backdrop-opacity-40{
    --tw-backdrop-opacity:opacity(0.4)
  }

  .xl\:backdrop-opacity-50{
    --tw-backdrop-opacity:opacity(0.5)
  }

  .xl\:backdrop-opacity-60{
    --tw-backdrop-opacity:opacity(0.6)
  }

  .xl\:backdrop-opacity-70{
    --tw-backdrop-opacity:opacity(0.7)
  }

  .xl\:backdrop-opacity-75{
    --tw-backdrop-opacity:opacity(0.75)
  }

  .xl\:backdrop-opacity-80{
    --tw-backdrop-opacity:opacity(0.8)
  }

  .xl\:backdrop-opacity-90{
    --tw-backdrop-opacity:opacity(0.9)
  }

  .xl\:backdrop-opacity-95{
    --tw-backdrop-opacity:opacity(0.95)
  }

  .xl\:backdrop-opacity-100{
    --tw-backdrop-opacity:opacity(1)
  }

  .xl\:backdrop-saturate-0{
    --tw-backdrop-saturate:saturate(0)
  }

  .xl\:backdrop-saturate-50{
    --tw-backdrop-saturate:saturate(.5)
  }

  .xl\:backdrop-saturate-100{
    --tw-backdrop-saturate:saturate(1)
  }

  .xl\:backdrop-saturate-150{
    --tw-backdrop-saturate:saturate(1.5)
  }

  .xl\:backdrop-saturate-200{
    --tw-backdrop-saturate:saturate(2)
  }

  .xl\:backdrop-sepia-0{
    --tw-backdrop-sepia:sepia(0)
  }

  .xl\:backdrop-sepia{
    --tw-backdrop-sepia:sepia(100%)
  }

  .xl\:transition-none{
    transition-property:none
  }

  .xl\:transition-all{
    transition-property:all;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xl\:transition{
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xl\:transition-colors{
    transition-property:background-color, border-color, color, fill, stroke;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xl\:transition-opacity{
    transition-property:opacity;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xl\:transition-shadow{
    transition-property:box-shadow;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xl\:transition-transform{
    transition-property:transform;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xl\:duration-75{
    transition-duration:75ms
  }

  .xl\:duration-100{
    transition-duration:100ms
  }

  .xl\:duration-150{
    transition-duration:150ms
  }

  .xl\:duration-200{
    transition-duration:200ms
  }

  .xl\:duration-300{
    transition-duration:300ms
  }

  .xl\:duration-500{
    transition-duration:500ms
  }

  .xl\:duration-700{
    transition-duration:700ms
  }

  .xl\:duration-1000{
    transition-duration:1000ms
  }

  .xl\:ease-linear{
    transition-timing-function:linear
  }

  .xl\:ease-in{
    transition-timing-function:cubic-bezier(0.4, 0, 1, 1)
  }

  .xl\:ease-out{
    transition-timing-function:cubic-bezier(0, 0, 0.2, 1)
  }

  .xl\:ease-in-out{
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1)
  }
}

@media (min-width: 1440px){
  .xxl\:visible{
    visibility:visible
  }

  .xxl\:invisible{
    visibility:hidden
  }

  .xxl\:static{
    position:static
  }

  .xxl\:fixed{
    position:fixed
  }

  .xxl\:absolute{
    position:absolute
  }

  .xxl\:relative{
    position:relative
  }

  .xxl\:sticky{
    position:sticky
  }

  .xxl\:inset-0{
    top:0;
    right:0;
    bottom:0;
    left:0
  }

  .xxl\:inset-1{
    top:4px;
    right:4px;
    bottom:4px;
    left:4px
  }

  .xxl\:inset-2{
    top:8px;
    right:8px;
    bottom:8px;
    left:8px
  }

  .xxl\:inset-3{
    top:12px;
    right:12px;
    bottom:12px;
    left:12px
  }

  .xxl\:inset-4{
    top:16px;
    right:16px;
    bottom:16px;
    left:16px
  }

  .xxl\:inset-5{
    top:20px;
    right:20px;
    bottom:20px;
    left:20px
  }

  .xxl\:inset-6{
    top:24px;
    right:24px;
    bottom:24px;
    left:24px
  }

  .xxl\:inset-8{
    top:32px;
    right:32px;
    bottom:32px;
    left:32px
  }

  .xxl\:inset-10{
    top:40px;
    right:40px;
    bottom:40px;
    left:40px
  }

  .xxl\:inset-12{
    top:48px;
    right:48px;
    bottom:48px;
    left:48px
  }

  .xxl\:inset-16{
    top:64px;
    right:64px;
    bottom:64px;
    left:64px
  }

  .xxl\:inset-18{
    top:72px;
    right:72px;
    bottom:72px;
    left:72px
  }

  .xxl\:inset-24{
    top:96px;
    right:96px;
    bottom:96px;
    left:96px
  }

  .xxl\:inset-auto{
    top:auto;
    right:auto;
    bottom:auto;
    left:auto
  }

  .xxl\:inset-5dot5{
    top:22px;
    right:22px;
    bottom:22px;
    left:22px
  }

  .xxl\:inset-full{
    top:100%;
    right:100%;
    bottom:100%;
    left:100%
  }

  .xxl\:-inset-1{
    top:-4px;
    right:-4px;
    bottom:-4px;
    left:-4px
  }

  .xxl\:-inset-2{
    top:-8px;
    right:-8px;
    bottom:-8px;
    left:-8px
  }

  .xxl\:-inset-3{
    top:-12px;
    right:-12px;
    bottom:-12px;
    left:-12px
  }

  .xxl\:-inset-4{
    top:-16px;
    right:-16px;
    bottom:-16px;
    left:-16px
  }

  .xxl\:-inset-5{
    top:-20px;
    right:-20px;
    bottom:-20px;
    left:-20px
  }

  .xxl\:-inset-6{
    top:-24px;
    right:-24px;
    bottom:-24px;
    left:-24px
  }

  .xxl\:-inset-8{
    top:-32px;
    right:-32px;
    bottom:-32px;
    left:-32px
  }

  .xxl\:-inset-10{
    top:-40px;
    right:-40px;
    bottom:-40px;
    left:-40px
  }

  .xxl\:-inset-12{
    top:-48px;
    right:-48px;
    bottom:-48px;
    left:-48px
  }

  .xxl\:-inset-16{
    top:-64px;
    right:-64px;
    bottom:-64px;
    left:-64px
  }

  .xxl\:-inset-18{
    top:-72px;
    right:-72px;
    bottom:-72px;
    left:-72px
  }

  .xxl\:-inset-24{
    top:-96px;
    right:-96px;
    bottom:-96px;
    left:-96px
  }

  .xxl\:-inset-5dot5{
    top:-22px;
    right:-22px;
    bottom:-22px;
    left:-22px
  }

  .xxl\:-inset-full{
    top:-100%;
    right:-100%;
    bottom:-100%;
    left:-100%
  }

  .xxl\:inset-1\/2{
    top:50%;
    right:50%;
    bottom:50%;
    left:50%
  }

  .xxl\:inset-1\/3{
    top:33.33333%;
    right:33.33333%;
    bottom:33.33333%;
    left:33.33333%
  }

  .xxl\:inset-2\/3{
    top:66.666667%;
    right:66.666667%;
    bottom:66.666667%;
    left:66.666667%
  }

  .xxl\:inset-1\/4{
    top:25%;
    right:25%;
    bottom:25%;
    left:25%
  }

  .xxl\:inset-2\/4{
    top:50%;
    right:50%;
    bottom:50%;
    left:50%
  }

  .xxl\:inset-3\/4{
    top:75%;
    right:75%;
    bottom:75%;
    left:75%
  }

  .xxl\:-inset-1\/2{
    top:-50%;
    right:-50%;
    bottom:-50%;
    left:-50%
  }

  .xxl\:-inset-1\/3{
    top:-33.333333%;
    right:-33.333333%;
    bottom:-33.333333%;
    left:-33.333333%
  }

  .xxl\:-inset-2\/3{
    top:-66.666667%;
    right:-66.666667%;
    bottom:-66.666667%;
    left:-66.666667%
  }

  .xxl\:-inset-1\/4{
    top:-25%;
    right:-25%;
    bottom:-25%;
    left:-25%
  }

  .xxl\:-inset-2\/4{
    top:-50%;
    right:-50%;
    bottom:-50%;
    left:-50%
  }

  .xxl\:-inset-3\/4{
    top:-75%;
    right:-75%;
    bottom:-75%;
    left:-75%
  }

  .xxl\:inset-x-0{
    left:0;
    right:0
  }

  .xxl\:inset-x-1{
    left:4px;
    right:4px
  }

  .xxl\:inset-x-2{
    left:8px;
    right:8px
  }

  .xxl\:inset-x-3{
    left:12px;
    right:12px
  }

  .xxl\:inset-x-4{
    left:16px;
    right:16px
  }

  .xxl\:inset-x-5{
    left:20px;
    right:20px
  }

  .xxl\:inset-x-6{
    left:24px;
    right:24px
  }

  .xxl\:inset-x-8{
    left:32px;
    right:32px
  }

  .xxl\:inset-x-10{
    left:40px;
    right:40px
  }

  .xxl\:inset-x-12{
    left:48px;
    right:48px
  }

  .xxl\:inset-x-16{
    left:64px;
    right:64px
  }

  .xxl\:inset-x-18{
    left:72px;
    right:72px
  }

  .xxl\:inset-x-24{
    left:96px;
    right:96px
  }

  .xxl\:inset-x-auto{
    left:auto;
    right:auto
  }

  .xxl\:inset-x-5dot5{
    left:22px;
    right:22px
  }

  .xxl\:inset-x-full{
    left:100%;
    right:100%
  }

  .xxl\:-inset-x-1{
    left:-4px;
    right:-4px
  }

  .xxl\:-inset-x-2{
    left:-8px;
    right:-8px
  }

  .xxl\:-inset-x-3{
    left:-12px;
    right:-12px
  }

  .xxl\:-inset-x-4{
    left:-16px;
    right:-16px
  }

  .xxl\:-inset-x-5{
    left:-20px;
    right:-20px
  }

  .xxl\:-inset-x-6{
    left:-24px;
    right:-24px
  }

  .xxl\:-inset-x-8{
    left:-32px;
    right:-32px
  }

  .xxl\:-inset-x-10{
    left:-40px;
    right:-40px
  }

  .xxl\:-inset-x-12{
    left:-48px;
    right:-48px
  }

  .xxl\:-inset-x-16{
    left:-64px;
    right:-64px
  }

  .xxl\:-inset-x-18{
    left:-72px;
    right:-72px
  }

  .xxl\:-inset-x-24{
    left:-96px;
    right:-96px
  }

  .xxl\:-inset-x-5dot5{
    left:-22px;
    right:-22px
  }

  .xxl\:-inset-x-full{
    left:-100%;
    right:-100%
  }

  .xxl\:inset-x-1\/2{
    left:50%;
    right:50%
  }

  .xxl\:inset-x-1\/3{
    left:33.33333%;
    right:33.33333%
  }

  .xxl\:inset-x-2\/3{
    left:66.666667%;
    right:66.666667%
  }

  .xxl\:inset-x-1\/4{
    left:25%;
    right:25%
  }

  .xxl\:inset-x-2\/4{
    left:50%;
    right:50%
  }

  .xxl\:inset-x-3\/4{
    left:75%;
    right:75%
  }

  .xxl\:-inset-x-1\/2{
    left:-50%;
    right:-50%
  }

  .xxl\:-inset-x-1\/3{
    left:-33.333333%;
    right:-33.333333%
  }

  .xxl\:-inset-x-2\/3{
    left:-66.666667%;
    right:-66.666667%
  }

  .xxl\:-inset-x-1\/4{
    left:-25%;
    right:-25%
  }

  .xxl\:-inset-x-2\/4{
    left:-50%;
    right:-50%
  }

  .xxl\:-inset-x-3\/4{
    left:-75%;
    right:-75%
  }

  .xxl\:inset-y-0{
    top:0;
    bottom:0
  }

  .xxl\:inset-y-1{
    top:4px;
    bottom:4px
  }

  .xxl\:inset-y-2{
    top:8px;
    bottom:8px
  }

  .xxl\:inset-y-3{
    top:12px;
    bottom:12px
  }

  .xxl\:inset-y-4{
    top:16px;
    bottom:16px
  }

  .xxl\:inset-y-5{
    top:20px;
    bottom:20px
  }

  .xxl\:inset-y-6{
    top:24px;
    bottom:24px
  }

  .xxl\:inset-y-8{
    top:32px;
    bottom:32px
  }

  .xxl\:inset-y-10{
    top:40px;
    bottom:40px
  }

  .xxl\:inset-y-12{
    top:48px;
    bottom:48px
  }

  .xxl\:inset-y-16{
    top:64px;
    bottom:64px
  }

  .xxl\:inset-y-18{
    top:72px;
    bottom:72px
  }

  .xxl\:inset-y-24{
    top:96px;
    bottom:96px
  }

  .xxl\:inset-y-auto{
    top:auto;
    bottom:auto
  }

  .xxl\:inset-y-5dot5{
    top:22px;
    bottom:22px
  }

  .xxl\:inset-y-full{
    top:100%;
    bottom:100%
  }

  .xxl\:-inset-y-1{
    top:-4px;
    bottom:-4px
  }

  .xxl\:-inset-y-2{
    top:-8px;
    bottom:-8px
  }

  .xxl\:-inset-y-3{
    top:-12px;
    bottom:-12px
  }

  .xxl\:-inset-y-4{
    top:-16px;
    bottom:-16px
  }

  .xxl\:-inset-y-5{
    top:-20px;
    bottom:-20px
  }

  .xxl\:-inset-y-6{
    top:-24px;
    bottom:-24px
  }

  .xxl\:-inset-y-8{
    top:-32px;
    bottom:-32px
  }

  .xxl\:-inset-y-10{
    top:-40px;
    bottom:-40px
  }

  .xxl\:-inset-y-12{
    top:-48px;
    bottom:-48px
  }

  .xxl\:-inset-y-16{
    top:-64px;
    bottom:-64px
  }

  .xxl\:-inset-y-18{
    top:-72px;
    bottom:-72px
  }

  .xxl\:-inset-y-24{
    top:-96px;
    bottom:-96px
  }

  .xxl\:-inset-y-5dot5{
    top:-22px;
    bottom:-22px
  }

  .xxl\:-inset-y-full{
    top:-100%;
    bottom:-100%
  }

  .xxl\:inset-y-1\/2{
    top:50%;
    bottom:50%
  }

  .xxl\:inset-y-1\/3{
    top:33.33333%;
    bottom:33.33333%
  }

  .xxl\:inset-y-2\/3{
    top:66.666667%;
    bottom:66.666667%
  }

  .xxl\:inset-y-1\/4{
    top:25%;
    bottom:25%
  }

  .xxl\:inset-y-2\/4{
    top:50%;
    bottom:50%
  }

  .xxl\:inset-y-3\/4{
    top:75%;
    bottom:75%
  }

  .xxl\:-inset-y-1\/2{
    top:-50%;
    bottom:-50%
  }

  .xxl\:-inset-y-1\/3{
    top:-33.333333%;
    bottom:-33.333333%
  }

  .xxl\:-inset-y-2\/3{
    top:-66.666667%;
    bottom:-66.666667%
  }

  .xxl\:-inset-y-1\/4{
    top:-25%;
    bottom:-25%
  }

  .xxl\:-inset-y-2\/4{
    top:-50%;
    bottom:-50%
  }

  .xxl\:-inset-y-3\/4{
    top:-75%;
    bottom:-75%
  }

  .xxl\:top-0{
    top:0
  }

  .xxl\:top-1{
    top:4px
  }

  .xxl\:top-2{
    top:8px
  }

  .xxl\:top-3{
    top:12px
  }

  .xxl\:top-4{
    top:16px
  }

  .xxl\:top-5{
    top:20px
  }

  .xxl\:top-6{
    top:24px
  }

  .xxl\:top-8{
    top:32px
  }

  .xxl\:top-10{
    top:40px
  }

  .xxl\:top-12{
    top:48px
  }

  .xxl\:top-16{
    top:64px
  }

  .xxl\:top-18{
    top:72px
  }

  .xxl\:top-24{
    top:96px
  }

  .xxl\:top-auto{
    top:auto
  }

  .xxl\:top-5dot5{
    top:22px
  }

  .xxl\:top-full{
    top:100%
  }

  .xxl\:-top-1{
    top:-4px
  }

  .xxl\:-top-2{
    top:-8px
  }

  .xxl\:-top-3{
    top:-12px
  }

  .xxl\:-top-4{
    top:-16px
  }

  .xxl\:-top-5{
    top:-20px
  }

  .xxl\:-top-6{
    top:-24px
  }

  .xxl\:-top-8{
    top:-32px
  }

  .xxl\:-top-10{
    top:-40px
  }

  .xxl\:-top-12{
    top:-48px
  }

  .xxl\:-top-16{
    top:-64px
  }

  .xxl\:-top-18{
    top:-72px
  }

  .xxl\:-top-24{
    top:-96px
  }

  .xxl\:-top-5dot5{
    top:-22px
  }

  .xxl\:-top-full{
    top:-100%
  }

  .xxl\:top-1\/2{
    top:50%
  }

  .xxl\:top-1\/3{
    top:33.33333%
  }

  .xxl\:top-2\/3{
    top:66.666667%
  }

  .xxl\:top-1\/4{
    top:25%
  }

  .xxl\:top-2\/4{
    top:50%
  }

  .xxl\:top-3\/4{
    top:75%
  }

  .xxl\:-top-1\/2{
    top:-50%
  }

  .xxl\:-top-1\/3{
    top:-33.333333%
  }

  .xxl\:-top-2\/3{
    top:-66.666667%
  }

  .xxl\:-top-1\/4{
    top:-25%
  }

  .xxl\:-top-2\/4{
    top:-50%
  }

  .xxl\:-top-3\/4{
    top:-75%
  }

  .xxl\:right-0{
    right:0
  }

  .xxl\:right-1{
    right:4px
  }

  .xxl\:right-2{
    right:8px
  }

  .xxl\:right-3{
    right:12px
  }

  .xxl\:right-4{
    right:16px
  }

  .xxl\:right-5{
    right:20px
  }

  .xxl\:right-6{
    right:24px
  }

  .xxl\:right-8{
    right:32px
  }

  .xxl\:right-10{
    right:40px
  }

  .xxl\:right-12{
    right:48px
  }

  .xxl\:right-16{
    right:64px
  }

  .xxl\:right-18{
    right:72px
  }

  .xxl\:right-24{
    right:96px
  }

  .xxl\:right-auto{
    right:auto
  }

  .xxl\:right-5dot5{
    right:22px
  }

  .xxl\:right-full{
    right:100%
  }

  .xxl\:-right-1{
    right:-4px
  }

  .xxl\:-right-2{
    right:-8px
  }

  .xxl\:-right-3{
    right:-12px
  }

  .xxl\:-right-4{
    right:-16px
  }

  .xxl\:-right-5{
    right:-20px
  }

  .xxl\:-right-6{
    right:-24px
  }

  .xxl\:-right-8{
    right:-32px
  }

  .xxl\:-right-10{
    right:-40px
  }

  .xxl\:-right-12{
    right:-48px
  }

  .xxl\:-right-16{
    right:-64px
  }

  .xxl\:-right-18{
    right:-72px
  }

  .xxl\:-right-24{
    right:-96px
  }

  .xxl\:-right-5dot5{
    right:-22px
  }

  .xxl\:-right-full{
    right:-100%
  }

  .xxl\:right-1\/2{
    right:50%
  }

  .xxl\:right-1\/3{
    right:33.33333%
  }

  .xxl\:right-2\/3{
    right:66.666667%
  }

  .xxl\:right-1\/4{
    right:25%
  }

  .xxl\:right-2\/4{
    right:50%
  }

  .xxl\:right-3\/4{
    right:75%
  }

  .xxl\:-right-1\/2{
    right:-50%
  }

  .xxl\:-right-1\/3{
    right:-33.333333%
  }

  .xxl\:-right-2\/3{
    right:-66.666667%
  }

  .xxl\:-right-1\/4{
    right:-25%
  }

  .xxl\:-right-2\/4{
    right:-50%
  }

  .xxl\:-right-3\/4{
    right:-75%
  }

  .xxl\:bottom-0{
    bottom:0
  }

  .xxl\:bottom-1{
    bottom:4px
  }

  .xxl\:bottom-2{
    bottom:8px
  }

  .xxl\:bottom-3{
    bottom:12px
  }

  .xxl\:bottom-4{
    bottom:16px
  }

  .xxl\:bottom-5{
    bottom:20px
  }

  .xxl\:bottom-6{
    bottom:24px
  }

  .xxl\:bottom-8{
    bottom:32px
  }

  .xxl\:bottom-10{
    bottom:40px
  }

  .xxl\:bottom-12{
    bottom:48px
  }

  .xxl\:bottom-16{
    bottom:64px
  }

  .xxl\:bottom-18{
    bottom:72px
  }

  .xxl\:bottom-24{
    bottom:96px
  }

  .xxl\:bottom-auto{
    bottom:auto
  }

  .xxl\:bottom-5dot5{
    bottom:22px
  }

  .xxl\:bottom-full{
    bottom:100%
  }

  .xxl\:-bottom-1{
    bottom:-4px
  }

  .xxl\:-bottom-2{
    bottom:-8px
  }

  .xxl\:-bottom-3{
    bottom:-12px
  }

  .xxl\:-bottom-4{
    bottom:-16px
  }

  .xxl\:-bottom-5{
    bottom:-20px
  }

  .xxl\:-bottom-6{
    bottom:-24px
  }

  .xxl\:-bottom-8{
    bottom:-32px
  }

  .xxl\:-bottom-10{
    bottom:-40px
  }

  .xxl\:-bottom-12{
    bottom:-48px
  }

  .xxl\:-bottom-16{
    bottom:-64px
  }

  .xxl\:-bottom-18{
    bottom:-72px
  }

  .xxl\:-bottom-24{
    bottom:-96px
  }

  .xxl\:-bottom-5dot5{
    bottom:-22px
  }

  .xxl\:-bottom-full{
    bottom:-100%
  }

  .xxl\:bottom-1\/2{
    bottom:50%
  }

  .xxl\:bottom-1\/3{
    bottom:33.33333%
  }

  .xxl\:bottom-2\/3{
    bottom:66.666667%
  }

  .xxl\:bottom-1\/4{
    bottom:25%
  }

  .xxl\:bottom-2\/4{
    bottom:50%
  }

  .xxl\:bottom-3\/4{
    bottom:75%
  }

  .xxl\:-bottom-1\/2{
    bottom:-50%
  }

  .xxl\:-bottom-1\/3{
    bottom:-33.333333%
  }

  .xxl\:-bottom-2\/3{
    bottom:-66.666667%
  }

  .xxl\:-bottom-1\/4{
    bottom:-25%
  }

  .xxl\:-bottom-2\/4{
    bottom:-50%
  }

  .xxl\:-bottom-3\/4{
    bottom:-75%
  }

  .xxl\:left-0{
    left:0
  }

  .xxl\:left-1{
    left:4px
  }

  .xxl\:left-2{
    left:8px
  }

  .xxl\:left-3{
    left:12px
  }

  .xxl\:left-4{
    left:16px
  }

  .xxl\:left-5{
    left:20px
  }

  .xxl\:left-6{
    left:24px
  }

  .xxl\:left-8{
    left:32px
  }

  .xxl\:left-10{
    left:40px
  }

  .xxl\:left-12{
    left:48px
  }

  .xxl\:left-16{
    left:64px
  }

  .xxl\:left-18{
    left:72px
  }

  .xxl\:left-24{
    left:96px
  }

  .xxl\:left-auto{
    left:auto
  }

  .xxl\:left-5dot5{
    left:22px
  }

  .xxl\:left-full{
    left:100%
  }

  .xxl\:-left-1{
    left:-4px
  }

  .xxl\:-left-2{
    left:-8px
  }

  .xxl\:-left-3{
    left:-12px
  }

  .xxl\:-left-4{
    left:-16px
  }

  .xxl\:-left-5{
    left:-20px
  }

  .xxl\:-left-6{
    left:-24px
  }

  .xxl\:-left-8{
    left:-32px
  }

  .xxl\:-left-10{
    left:-40px
  }

  .xxl\:-left-12{
    left:-48px
  }

  .xxl\:-left-16{
    left:-64px
  }

  .xxl\:-left-18{
    left:-72px
  }

  .xxl\:-left-24{
    left:-96px
  }

  .xxl\:-left-5dot5{
    left:-22px
  }

  .xxl\:-left-full{
    left:-100%
  }

  .xxl\:left-1\/2{
    left:50%
  }

  .xxl\:left-1\/3{
    left:33.33333%
  }

  .xxl\:left-2\/3{
    left:66.666667%
  }

  .xxl\:left-1\/4{
    left:25%
  }

  .xxl\:left-2\/4{
    left:50%
  }

  .xxl\:left-3\/4{
    left:75%
  }

  .xxl\:-left-1\/2{
    left:-50%
  }

  .xxl\:-left-1\/3{
    left:-33.333333%
  }

  .xxl\:-left-2\/3{
    left:-66.666667%
  }

  .xxl\:-left-1\/4{
    left:-25%
  }

  .xxl\:-left-2\/4{
    left:-50%
  }

  .xxl\:-left-3\/4{
    left:-75%
  }

  .xxl\:isolate{
    isolation:isolate
  }

  .xxl\:isolation-auto{
    isolation:auto
  }

  .xxl\:z-0{
    z-index:0
  }

  .xxl\:z-10{
    z-index:10
  }

  .xxl\:z-20{
    z-index:20
  }

  .xxl\:z-30{
    z-index:30
  }

  .xxl\:z-40{
    z-index:40
  }

  .xxl\:z-50{
    z-index:50
  }

  .xxl\:z-auto{
    z-index:auto
  }

  .xxl\:focus-within\:z-0:focus-within{
    z-index:0
  }

  .xxl\:focus-within\:z-10:focus-within{
    z-index:10
  }

  .xxl\:focus-within\:z-20:focus-within{
    z-index:20
  }

  .xxl\:focus-within\:z-30:focus-within{
    z-index:30
  }

  .xxl\:focus-within\:z-40:focus-within{
    z-index:40
  }

  .xxl\:focus-within\:z-50:focus-within{
    z-index:50
  }

  .xxl\:focus-within\:z-auto:focus-within{
    z-index:auto
  }

  .xxl\:focus\:z-0:focus{
    z-index:0
  }

  .xxl\:focus\:z-10:focus{
    z-index:10
  }

  .xxl\:focus\:z-20:focus{
    z-index:20
  }

  .xxl\:focus\:z-30:focus{
    z-index:30
  }

  .xxl\:focus\:z-40:focus{
    z-index:40
  }

  .xxl\:focus\:z-50:focus{
    z-index:50
  }

  .xxl\:focus\:z-auto:focus{
    z-index:auto
  }

  .xxl\:col-auto{
    grid-column:auto
  }

  .xxl\:col-span-1{
    grid-column:span 1 / span 1
  }

  .xxl\:col-span-2{
    grid-column:span 2 / span 2
  }

  .xxl\:col-span-3{
    grid-column:span 3 / span 3
  }

  .xxl\:col-span-4{
    grid-column:span 4 / span 4
  }

  .xxl\:col-span-5{
    grid-column:span 5 / span 5
  }

  .xxl\:col-span-6{
    grid-column:span 6 / span 6
  }

  .xxl\:col-span-7{
    grid-column:span 7 / span 7
  }

  .xxl\:col-span-8{
    grid-column:span 8 / span 8
  }

  .xxl\:col-span-9{
    grid-column:span 9 / span 9
  }

  .xxl\:col-span-10{
    grid-column:span 10 / span 10
  }

  .xxl\:col-span-11{
    grid-column:span 11 / span 11
  }

  .xxl\:col-span-12{
    grid-column:span 12 / span 12
  }

  .xxl\:col-span-full{
    grid-column:1 / -1
  }

  .xxl\:col-start-1{
    grid-column-start:1
  }

  .xxl\:col-start-2{
    grid-column-start:2
  }

  .xxl\:col-start-3{
    grid-column-start:3
  }

  .xxl\:col-start-4{
    grid-column-start:4
  }

  .xxl\:col-start-5{
    grid-column-start:5
  }

  .xxl\:col-start-6{
    grid-column-start:6
  }

  .xxl\:col-start-7{
    grid-column-start:7
  }

  .xxl\:col-start-8{
    grid-column-start:8
  }

  .xxl\:col-start-9{
    grid-column-start:9
  }

  .xxl\:col-start-10{
    grid-column-start:10
  }

  .xxl\:col-start-11{
    grid-column-start:11
  }

  .xxl\:col-start-12{
    grid-column-start:12
  }

  .xxl\:col-start-13{
    grid-column-start:13
  }

  .xxl\:col-start-auto{
    grid-column-start:auto
  }

  .xxl\:col-end-1{
    grid-column-end:1
  }

  .xxl\:col-end-2{
    grid-column-end:2
  }

  .xxl\:col-end-3{
    grid-column-end:3
  }

  .xxl\:col-end-4{
    grid-column-end:4
  }

  .xxl\:col-end-5{
    grid-column-end:5
  }

  .xxl\:col-end-6{
    grid-column-end:6
  }

  .xxl\:col-end-7{
    grid-column-end:7
  }

  .xxl\:col-end-8{
    grid-column-end:8
  }

  .xxl\:col-end-9{
    grid-column-end:9
  }

  .xxl\:col-end-10{
    grid-column-end:10
  }

  .xxl\:col-end-11{
    grid-column-end:11
  }

  .xxl\:col-end-12{
    grid-column-end:12
  }

  .xxl\:col-end-13{
    grid-column-end:13
  }

  .xxl\:col-end-auto{
    grid-column-end:auto
  }

  .xxl\:row-auto{
    grid-row:auto
  }

  .xxl\:row-span-1{
    grid-row:span 1 / span 1
  }

  .xxl\:row-span-2{
    grid-row:span 2 / span 2
  }

  .xxl\:row-span-3{
    grid-row:span 3 / span 3
  }

  .xxl\:row-span-4{
    grid-row:span 4 / span 4
  }

  .xxl\:row-span-5{
    grid-row:span 5 / span 5
  }

  .xxl\:row-span-6{
    grid-row:span 6 / span 6
  }

  .xxl\:row-span-full{
    grid-row:1 / -1
  }

  .xxl\:row-start-1{
    grid-row-start:1
  }

  .xxl\:row-start-2{
    grid-row-start:2
  }

  .xxl\:row-start-3{
    grid-row-start:3
  }

  .xxl\:row-start-4{
    grid-row-start:4
  }

  .xxl\:row-start-5{
    grid-row-start:5
  }

  .xxl\:row-start-6{
    grid-row-start:6
  }

  .xxl\:row-start-7{
    grid-row-start:7
  }

  .xxl\:row-start-auto{
    grid-row-start:auto
  }

  .xxl\:row-end-1{
    grid-row-end:1
  }

  .xxl\:row-end-2{
    grid-row-end:2
  }

  .xxl\:row-end-3{
    grid-row-end:3
  }

  .xxl\:row-end-4{
    grid-row-end:4
  }

  .xxl\:row-end-5{
    grid-row-end:5
  }

  .xxl\:row-end-6{
    grid-row-end:6
  }

  .xxl\:row-end-7{
    grid-row-end:7
  }

  .xxl\:row-end-auto{
    grid-row-end:auto
  }

  .xxl\:m-0{
    margin:0
  }

  .xxl\:m-1{
    margin:4px
  }

  .xxl\:m-2{
    margin:8px
  }

  .xxl\:m-3{
    margin:12px
  }

  .xxl\:m-4{
    margin:16px
  }

  .xxl\:m-5{
    margin:20px
  }

  .xxl\:m-6{
    margin:24px
  }

  .xxl\:m-8{
    margin:32px
  }

  .xxl\:m-10{
    margin:40px
  }

  .xxl\:m-12{
    margin:48px
  }

  .xxl\:m-16{
    margin:64px
  }

  .xxl\:m-18{
    margin:72px
  }

  .xxl\:m-24{
    margin:96px
  }

  .xxl\:m-auto{
    margin:auto
  }

  .xxl\:m-5dot5{
    margin:22px
  }

  .xxl\:m-full{
    margin:100%
  }

  .xxl\:-m-1{
    margin:-4px
  }

  .xxl\:-m-2{
    margin:-8px
  }

  .xxl\:-m-3{
    margin:-12px
  }

  .xxl\:-m-4{
    margin:-16px
  }

  .xxl\:-m-5{
    margin:-20px
  }

  .xxl\:-m-6{
    margin:-24px
  }

  .xxl\:-m-8{
    margin:-32px
  }

  .xxl\:-m-10{
    margin:-40px
  }

  .xxl\:-m-12{
    margin:-48px
  }

  .xxl\:-m-16{
    margin:-64px
  }

  .xxl\:-m-18{
    margin:-72px
  }

  .xxl\:-m-24{
    margin:-96px
  }

  .xxl\:-m-5dot5{
    margin:-22px
  }

  .xxl\:-m-full{
    margin:-100%
  }

  .xxl\:first\:m-0:first-child{
    margin:0
  }

  .xxl\:first\:m-1:first-child{
    margin:4px
  }

  .xxl\:first\:m-2:first-child{
    margin:8px
  }

  .xxl\:first\:m-3:first-child{
    margin:12px
  }

  .xxl\:first\:m-4:first-child{
    margin:16px
  }

  .xxl\:first\:m-5:first-child{
    margin:20px
  }

  .xxl\:first\:m-6:first-child{
    margin:24px
  }

  .xxl\:first\:m-8:first-child{
    margin:32px
  }

  .xxl\:first\:m-10:first-child{
    margin:40px
  }

  .xxl\:first\:m-12:first-child{
    margin:48px
  }

  .xxl\:first\:m-16:first-child{
    margin:64px
  }

  .xxl\:first\:m-18:first-child{
    margin:72px
  }

  .xxl\:first\:m-24:first-child{
    margin:96px
  }

  .xxl\:first\:m-auto:first-child{
    margin:auto
  }

  .xxl\:first\:m-5dot5:first-child{
    margin:22px
  }

  .xxl\:first\:m-full:first-child{
    margin:100%
  }

  .xxl\:first\:-m-1:first-child{
    margin:-4px
  }

  .xxl\:first\:-m-2:first-child{
    margin:-8px
  }

  .xxl\:first\:-m-3:first-child{
    margin:-12px
  }

  .xxl\:first\:-m-4:first-child{
    margin:-16px
  }

  .xxl\:first\:-m-5:first-child{
    margin:-20px
  }

  .xxl\:first\:-m-6:first-child{
    margin:-24px
  }

  .xxl\:first\:-m-8:first-child{
    margin:-32px
  }

  .xxl\:first\:-m-10:first-child{
    margin:-40px
  }

  .xxl\:first\:-m-12:first-child{
    margin:-48px
  }

  .xxl\:first\:-m-16:first-child{
    margin:-64px
  }

  .xxl\:first\:-m-18:first-child{
    margin:-72px
  }

  .xxl\:first\:-m-24:first-child{
    margin:-96px
  }

  .xxl\:first\:-m-5dot5:first-child{
    margin:-22px
  }

  .xxl\:first\:-m-full:first-child{
    margin:-100%
  }

  .xxl\:last\:m-0:last-child{
    margin:0
  }

  .xxl\:last\:m-1:last-child{
    margin:4px
  }

  .xxl\:last\:m-2:last-child{
    margin:8px
  }

  .xxl\:last\:m-3:last-child{
    margin:12px
  }

  .xxl\:last\:m-4:last-child{
    margin:16px
  }

  .xxl\:last\:m-5:last-child{
    margin:20px
  }

  .xxl\:last\:m-6:last-child{
    margin:24px
  }

  .xxl\:last\:m-8:last-child{
    margin:32px
  }

  .xxl\:last\:m-10:last-child{
    margin:40px
  }

  .xxl\:last\:m-12:last-child{
    margin:48px
  }

  .xxl\:last\:m-16:last-child{
    margin:64px
  }

  .xxl\:last\:m-18:last-child{
    margin:72px
  }

  .xxl\:last\:m-24:last-child{
    margin:96px
  }

  .xxl\:last\:m-auto:last-child{
    margin:auto
  }

  .xxl\:last\:m-5dot5:last-child{
    margin:22px
  }

  .xxl\:last\:m-full:last-child{
    margin:100%
  }

  .xxl\:last\:-m-1:last-child{
    margin:-4px
  }

  .xxl\:last\:-m-2:last-child{
    margin:-8px
  }

  .xxl\:last\:-m-3:last-child{
    margin:-12px
  }

  .xxl\:last\:-m-4:last-child{
    margin:-16px
  }

  .xxl\:last\:-m-5:last-child{
    margin:-20px
  }

  .xxl\:last\:-m-6:last-child{
    margin:-24px
  }

  .xxl\:last\:-m-8:last-child{
    margin:-32px
  }

  .xxl\:last\:-m-10:last-child{
    margin:-40px
  }

  .xxl\:last\:-m-12:last-child{
    margin:-48px
  }

  .xxl\:last\:-m-16:last-child{
    margin:-64px
  }

  .xxl\:last\:-m-18:last-child{
    margin:-72px
  }

  .xxl\:last\:-m-24:last-child{
    margin:-96px
  }

  .xxl\:last\:-m-5dot5:last-child{
    margin:-22px
  }

  .xxl\:last\:-m-full:last-child{
    margin:-100%
  }

  .xxl\:mx-0{
    margin-left:0;
    margin-right:0
  }

  .xxl\:mx-1{
    margin-left:4px;
    margin-right:4px
  }

  .xxl\:mx-2{
    margin-left:8px;
    margin-right:8px
  }

  .xxl\:mx-3{
    margin-left:12px;
    margin-right:12px
  }

  .xxl\:mx-4{
    margin-left:16px;
    margin-right:16px
  }

  .xxl\:mx-5{
    margin-left:20px;
    margin-right:20px
  }

  .xxl\:mx-6{
    margin-left:24px;
    margin-right:24px
  }

  .xxl\:mx-8{
    margin-left:32px;
    margin-right:32px
  }

  .xxl\:mx-10{
    margin-left:40px;
    margin-right:40px
  }

  .xxl\:mx-12{
    margin-left:48px;
    margin-right:48px
  }

  .xxl\:mx-16{
    margin-left:64px;
    margin-right:64px
  }

  .xxl\:mx-18{
    margin-left:72px;
    margin-right:72px
  }

  .xxl\:mx-24{
    margin-left:96px;
    margin-right:96px
  }

  .xxl\:mx-auto{
    margin-left:auto;
    margin-right:auto
  }

  .xxl\:mx-5dot5{
    margin-left:22px;
    margin-right:22px
  }

  .xxl\:mx-full{
    margin-left:100%;
    margin-right:100%
  }

  .xxl\:-mx-1{
    margin-left:-4px;
    margin-right:-4px
  }

  .xxl\:-mx-2{
    margin-left:-8px;
    margin-right:-8px
  }

  .xxl\:-mx-3{
    margin-left:-12px;
    margin-right:-12px
  }

  .xxl\:-mx-4{
    margin-left:-16px;
    margin-right:-16px
  }

  .xxl\:-mx-5{
    margin-left:-20px;
    margin-right:-20px
  }

  .xxl\:-mx-6{
    margin-left:-24px;
    margin-right:-24px
  }

  .xxl\:-mx-8{
    margin-left:-32px;
    margin-right:-32px
  }

  .xxl\:-mx-10{
    margin-left:-40px;
    margin-right:-40px
  }

  .xxl\:-mx-12{
    margin-left:-48px;
    margin-right:-48px
  }

  .xxl\:-mx-16{
    margin-left:-64px;
    margin-right:-64px
  }

  .xxl\:-mx-18{
    margin-left:-72px;
    margin-right:-72px
  }

  .xxl\:-mx-24{
    margin-left:-96px;
    margin-right:-96px
  }

  .xxl\:-mx-5dot5{
    margin-left:-22px;
    margin-right:-22px
  }

  .xxl\:-mx-full{
    margin-left:-100%;
    margin-right:-100%
  }

  .xxl\:my-0{
    margin-top:0;
    margin-bottom:0
  }

  .xxl\:my-1{
    margin-top:4px;
    margin-bottom:4px
  }

  .xxl\:my-2{
    margin-top:8px;
    margin-bottom:8px
  }

  .xxl\:my-3{
    margin-top:12px;
    margin-bottom:12px
  }

  .xxl\:my-4{
    margin-top:16px;
    margin-bottom:16px
  }

  .xxl\:my-5{
    margin-top:20px;
    margin-bottom:20px
  }

  .xxl\:my-6{
    margin-top:24px;
    margin-bottom:24px
  }

  .xxl\:my-8{
    margin-top:32px;
    margin-bottom:32px
  }

  .xxl\:my-10{
    margin-top:40px;
    margin-bottom:40px
  }

  .xxl\:my-12{
    margin-top:48px;
    margin-bottom:48px
  }

  .xxl\:my-16{
    margin-top:64px;
    margin-bottom:64px
  }

  .xxl\:my-18{
    margin-top:72px;
    margin-bottom:72px
  }

  .xxl\:my-24{
    margin-top:96px;
    margin-bottom:96px
  }

  .xxl\:my-auto{
    margin-top:auto;
    margin-bottom:auto
  }

  .xxl\:my-5dot5{
    margin-top:22px;
    margin-bottom:22px
  }

  .xxl\:my-full{
    margin-top:100%;
    margin-bottom:100%
  }

  .xxl\:-my-1{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .xxl\:-my-2{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .xxl\:-my-3{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .xxl\:-my-4{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .xxl\:-my-5{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .xxl\:-my-6{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .xxl\:-my-8{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .xxl\:-my-10{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .xxl\:-my-12{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .xxl\:-my-16{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .xxl\:-my-18{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .xxl\:-my-24{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .xxl\:-my-5dot5{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .xxl\:-my-full{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .xxl\:first\:mx-0:first-child{
    margin-left:0;
    margin-right:0
  }

  .xxl\:first\:mx-1:first-child{
    margin-left:4px;
    margin-right:4px
  }

  .xxl\:first\:mx-2:first-child{
    margin-left:8px;
    margin-right:8px
  }

  .xxl\:first\:mx-3:first-child{
    margin-left:12px;
    margin-right:12px
  }

  .xxl\:first\:mx-4:first-child{
    margin-left:16px;
    margin-right:16px
  }

  .xxl\:first\:mx-5:first-child{
    margin-left:20px;
    margin-right:20px
  }

  .xxl\:first\:mx-6:first-child{
    margin-left:24px;
    margin-right:24px
  }

  .xxl\:first\:mx-8:first-child{
    margin-left:32px;
    margin-right:32px
  }

  .xxl\:first\:mx-10:first-child{
    margin-left:40px;
    margin-right:40px
  }

  .xxl\:first\:mx-12:first-child{
    margin-left:48px;
    margin-right:48px
  }

  .xxl\:first\:mx-16:first-child{
    margin-left:64px;
    margin-right:64px
  }

  .xxl\:first\:mx-18:first-child{
    margin-left:72px;
    margin-right:72px
  }

  .xxl\:first\:mx-24:first-child{
    margin-left:96px;
    margin-right:96px
  }

  .xxl\:first\:mx-auto:first-child{
    margin-left:auto;
    margin-right:auto
  }

  .xxl\:first\:mx-5dot5:first-child{
    margin-left:22px;
    margin-right:22px
  }

  .xxl\:first\:mx-full:first-child{
    margin-left:100%;
    margin-right:100%
  }

  .xxl\:first\:-mx-1:first-child{
    margin-left:-4px;
    margin-right:-4px
  }

  .xxl\:first\:-mx-2:first-child{
    margin-left:-8px;
    margin-right:-8px
  }

  .xxl\:first\:-mx-3:first-child{
    margin-left:-12px;
    margin-right:-12px
  }

  .xxl\:first\:-mx-4:first-child{
    margin-left:-16px;
    margin-right:-16px
  }

  .xxl\:first\:-mx-5:first-child{
    margin-left:-20px;
    margin-right:-20px
  }

  .xxl\:first\:-mx-6:first-child{
    margin-left:-24px;
    margin-right:-24px
  }

  .xxl\:first\:-mx-8:first-child{
    margin-left:-32px;
    margin-right:-32px
  }

  .xxl\:first\:-mx-10:first-child{
    margin-left:-40px;
    margin-right:-40px
  }

  .xxl\:first\:-mx-12:first-child{
    margin-left:-48px;
    margin-right:-48px
  }

  .xxl\:first\:-mx-16:first-child{
    margin-left:-64px;
    margin-right:-64px
  }

  .xxl\:first\:-mx-18:first-child{
    margin-left:-72px;
    margin-right:-72px
  }

  .xxl\:first\:-mx-24:first-child{
    margin-left:-96px;
    margin-right:-96px
  }

  .xxl\:first\:-mx-5dot5:first-child{
    margin-left:-22px;
    margin-right:-22px
  }

  .xxl\:first\:-mx-full:first-child{
    margin-left:-100%;
    margin-right:-100%
  }

  .xxl\:first\:my-0:first-child{
    margin-top:0;
    margin-bottom:0
  }

  .xxl\:first\:my-1:first-child{
    margin-top:4px;
    margin-bottom:4px
  }

  .xxl\:first\:my-2:first-child{
    margin-top:8px;
    margin-bottom:8px
  }

  .xxl\:first\:my-3:first-child{
    margin-top:12px;
    margin-bottom:12px
  }

  .xxl\:first\:my-4:first-child{
    margin-top:16px;
    margin-bottom:16px
  }

  .xxl\:first\:my-5:first-child{
    margin-top:20px;
    margin-bottom:20px
  }

  .xxl\:first\:my-6:first-child{
    margin-top:24px;
    margin-bottom:24px
  }

  .xxl\:first\:my-8:first-child{
    margin-top:32px;
    margin-bottom:32px
  }

  .xxl\:first\:my-10:first-child{
    margin-top:40px;
    margin-bottom:40px
  }

  .xxl\:first\:my-12:first-child{
    margin-top:48px;
    margin-bottom:48px
  }

  .xxl\:first\:my-16:first-child{
    margin-top:64px;
    margin-bottom:64px
  }

  .xxl\:first\:my-18:first-child{
    margin-top:72px;
    margin-bottom:72px
  }

  .xxl\:first\:my-24:first-child{
    margin-top:96px;
    margin-bottom:96px
  }

  .xxl\:first\:my-auto:first-child{
    margin-top:auto;
    margin-bottom:auto
  }

  .xxl\:first\:my-5dot5:first-child{
    margin-top:22px;
    margin-bottom:22px
  }

  .xxl\:first\:my-full:first-child{
    margin-top:100%;
    margin-bottom:100%
  }

  .xxl\:first\:-my-1:first-child{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .xxl\:first\:-my-2:first-child{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .xxl\:first\:-my-3:first-child{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .xxl\:first\:-my-4:first-child{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .xxl\:first\:-my-5:first-child{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .xxl\:first\:-my-6:first-child{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .xxl\:first\:-my-8:first-child{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .xxl\:first\:-my-10:first-child{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .xxl\:first\:-my-12:first-child{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .xxl\:first\:-my-16:first-child{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .xxl\:first\:-my-18:first-child{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .xxl\:first\:-my-24:first-child{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .xxl\:first\:-my-5dot5:first-child{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .xxl\:first\:-my-full:first-child{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .xxl\:last\:mx-0:last-child{
    margin-left:0;
    margin-right:0
  }

  .xxl\:last\:mx-1:last-child{
    margin-left:4px;
    margin-right:4px
  }

  .xxl\:last\:mx-2:last-child{
    margin-left:8px;
    margin-right:8px
  }

  .xxl\:last\:mx-3:last-child{
    margin-left:12px;
    margin-right:12px
  }

  .xxl\:last\:mx-4:last-child{
    margin-left:16px;
    margin-right:16px
  }

  .xxl\:last\:mx-5:last-child{
    margin-left:20px;
    margin-right:20px
  }

  .xxl\:last\:mx-6:last-child{
    margin-left:24px;
    margin-right:24px
  }

  .xxl\:last\:mx-8:last-child{
    margin-left:32px;
    margin-right:32px
  }

  .xxl\:last\:mx-10:last-child{
    margin-left:40px;
    margin-right:40px
  }

  .xxl\:last\:mx-12:last-child{
    margin-left:48px;
    margin-right:48px
  }

  .xxl\:last\:mx-16:last-child{
    margin-left:64px;
    margin-right:64px
  }

  .xxl\:last\:mx-18:last-child{
    margin-left:72px;
    margin-right:72px
  }

  .xxl\:last\:mx-24:last-child{
    margin-left:96px;
    margin-right:96px
  }

  .xxl\:last\:mx-auto:last-child{
    margin-left:auto;
    margin-right:auto
  }

  .xxl\:last\:mx-5dot5:last-child{
    margin-left:22px;
    margin-right:22px
  }

  .xxl\:last\:mx-full:last-child{
    margin-left:100%;
    margin-right:100%
  }

  .xxl\:last\:-mx-1:last-child{
    margin-left:-4px;
    margin-right:-4px
  }

  .xxl\:last\:-mx-2:last-child{
    margin-left:-8px;
    margin-right:-8px
  }

  .xxl\:last\:-mx-3:last-child{
    margin-left:-12px;
    margin-right:-12px
  }

  .xxl\:last\:-mx-4:last-child{
    margin-left:-16px;
    margin-right:-16px
  }

  .xxl\:last\:-mx-5:last-child{
    margin-left:-20px;
    margin-right:-20px
  }

  .xxl\:last\:-mx-6:last-child{
    margin-left:-24px;
    margin-right:-24px
  }

  .xxl\:last\:-mx-8:last-child{
    margin-left:-32px;
    margin-right:-32px
  }

  .xxl\:last\:-mx-10:last-child{
    margin-left:-40px;
    margin-right:-40px
  }

  .xxl\:last\:-mx-12:last-child{
    margin-left:-48px;
    margin-right:-48px
  }

  .xxl\:last\:-mx-16:last-child{
    margin-left:-64px;
    margin-right:-64px
  }

  .xxl\:last\:-mx-18:last-child{
    margin-left:-72px;
    margin-right:-72px
  }

  .xxl\:last\:-mx-24:last-child{
    margin-left:-96px;
    margin-right:-96px
  }

  .xxl\:last\:-mx-5dot5:last-child{
    margin-left:-22px;
    margin-right:-22px
  }

  .xxl\:last\:-mx-full:last-child{
    margin-left:-100%;
    margin-right:-100%
  }

  .xxl\:last\:my-0:last-child{
    margin-top:0;
    margin-bottom:0
  }

  .xxl\:last\:my-1:last-child{
    margin-top:4px;
    margin-bottom:4px
  }

  .xxl\:last\:my-2:last-child{
    margin-top:8px;
    margin-bottom:8px
  }

  .xxl\:last\:my-3:last-child{
    margin-top:12px;
    margin-bottom:12px
  }

  .xxl\:last\:my-4:last-child{
    margin-top:16px;
    margin-bottom:16px
  }

  .xxl\:last\:my-5:last-child{
    margin-top:20px;
    margin-bottom:20px
  }

  .xxl\:last\:my-6:last-child{
    margin-top:24px;
    margin-bottom:24px
  }

  .xxl\:last\:my-8:last-child{
    margin-top:32px;
    margin-bottom:32px
  }

  .xxl\:last\:my-10:last-child{
    margin-top:40px;
    margin-bottom:40px
  }

  .xxl\:last\:my-12:last-child{
    margin-top:48px;
    margin-bottom:48px
  }

  .xxl\:last\:my-16:last-child{
    margin-top:64px;
    margin-bottom:64px
  }

  .xxl\:last\:my-18:last-child{
    margin-top:72px;
    margin-bottom:72px
  }

  .xxl\:last\:my-24:last-child{
    margin-top:96px;
    margin-bottom:96px
  }

  .xxl\:last\:my-auto:last-child{
    margin-top:auto;
    margin-bottom:auto
  }

  .xxl\:last\:my-5dot5:last-child{
    margin-top:22px;
    margin-bottom:22px
  }

  .xxl\:last\:my-full:last-child{
    margin-top:100%;
    margin-bottom:100%
  }

  .xxl\:last\:-my-1:last-child{
    margin-top:-4px;
    margin-bottom:-4px
  }

  .xxl\:last\:-my-2:last-child{
    margin-top:-8px;
    margin-bottom:-8px
  }

  .xxl\:last\:-my-3:last-child{
    margin-top:-12px;
    margin-bottom:-12px
  }

  .xxl\:last\:-my-4:last-child{
    margin-top:-16px;
    margin-bottom:-16px
  }

  .xxl\:last\:-my-5:last-child{
    margin-top:-20px;
    margin-bottom:-20px
  }

  .xxl\:last\:-my-6:last-child{
    margin-top:-24px;
    margin-bottom:-24px
  }

  .xxl\:last\:-my-8:last-child{
    margin-top:-32px;
    margin-bottom:-32px
  }

  .xxl\:last\:-my-10:last-child{
    margin-top:-40px;
    margin-bottom:-40px
  }

  .xxl\:last\:-my-12:last-child{
    margin-top:-48px;
    margin-bottom:-48px
  }

  .xxl\:last\:-my-16:last-child{
    margin-top:-64px;
    margin-bottom:-64px
  }

  .xxl\:last\:-my-18:last-child{
    margin-top:-72px;
    margin-bottom:-72px
  }

  .xxl\:last\:-my-24:last-child{
    margin-top:-96px;
    margin-bottom:-96px
  }

  .xxl\:last\:-my-5dot5:last-child{
    margin-top:-22px;
    margin-bottom:-22px
  }

  .xxl\:last\:-my-full:last-child{
    margin-top:-100%;
    margin-bottom:-100%
  }

  .xxl\:mt-0{
    margin-top:0
  }

  .xxl\:mt-1{
    margin-top:4px
  }

  .xxl\:mt-2{
    margin-top:8px
  }

  .xxl\:mt-3{
    margin-top:12px
  }

  .xxl\:mt-4{
    margin-top:16px
  }

  .xxl\:mt-5{
    margin-top:20px
  }

  .xxl\:mt-6{
    margin-top:24px
  }

  .xxl\:mt-8{
    margin-top:32px
  }

  .xxl\:mt-10{
    margin-top:40px
  }

  .xxl\:mt-12{
    margin-top:48px
  }

  .xxl\:mt-16{
    margin-top:64px
  }

  .xxl\:mt-18{
    margin-top:72px
  }

  .xxl\:mt-24{
    margin-top:96px
  }

  .xxl\:mt-auto{
    margin-top:auto
  }

  .xxl\:mt-5dot5{
    margin-top:22px
  }

  .xxl\:mt-full{
    margin-top:100%
  }

  .xxl\:-mt-1{
    margin-top:-4px
  }

  .xxl\:-mt-2{
    margin-top:-8px
  }

  .xxl\:-mt-3{
    margin-top:-12px
  }

  .xxl\:-mt-4{
    margin-top:-16px
  }

  .xxl\:-mt-5{
    margin-top:-20px
  }

  .xxl\:-mt-6{
    margin-top:-24px
  }

  .xxl\:-mt-8{
    margin-top:-32px
  }

  .xxl\:-mt-10{
    margin-top:-40px
  }

  .xxl\:-mt-12{
    margin-top:-48px
  }

  .xxl\:-mt-16{
    margin-top:-64px
  }

  .xxl\:-mt-18{
    margin-top:-72px
  }

  .xxl\:-mt-24{
    margin-top:-96px
  }

  .xxl\:-mt-5dot5{
    margin-top:-22px
  }

  .xxl\:-mt-full{
    margin-top:-100%
  }

  .xxl\:mr-0{
    margin-right:0
  }

  .xxl\:mr-1{
    margin-right:4px
  }

  .xxl\:mr-2{
    margin-right:8px
  }

  .xxl\:mr-3{
    margin-right:12px
  }

  .xxl\:mr-4{
    margin-right:16px
  }

  .xxl\:mr-5{
    margin-right:20px
  }

  .xxl\:mr-6{
    margin-right:24px
  }

  .xxl\:mr-8{
    margin-right:32px
  }

  .xxl\:mr-10{
    margin-right:40px
  }

  .xxl\:mr-12{
    margin-right:48px
  }

  .xxl\:mr-16{
    margin-right:64px
  }

  .xxl\:mr-18{
    margin-right:72px
  }

  .xxl\:mr-24{
    margin-right:96px
  }

  .xxl\:mr-auto{
    margin-right:auto
  }

  .xxl\:mr-5dot5{
    margin-right:22px
  }

  .xxl\:mr-full{
    margin-right:100%
  }

  .xxl\:-mr-1{
    margin-right:-4px
  }

  .xxl\:-mr-2{
    margin-right:-8px
  }

  .xxl\:-mr-3{
    margin-right:-12px
  }

  .xxl\:-mr-4{
    margin-right:-16px
  }

  .xxl\:-mr-5{
    margin-right:-20px
  }

  .xxl\:-mr-6{
    margin-right:-24px
  }

  .xxl\:-mr-8{
    margin-right:-32px
  }

  .xxl\:-mr-10{
    margin-right:-40px
  }

  .xxl\:-mr-12{
    margin-right:-48px
  }

  .xxl\:-mr-16{
    margin-right:-64px
  }

  .xxl\:-mr-18{
    margin-right:-72px
  }

  .xxl\:-mr-24{
    margin-right:-96px
  }

  .xxl\:-mr-5dot5{
    margin-right:-22px
  }

  .xxl\:-mr-full{
    margin-right:-100%
  }

  .xxl\:mb-0{
    margin-bottom:0
  }

  .xxl\:mb-1{
    margin-bottom:4px
  }

  .xxl\:mb-2{
    margin-bottom:8px
  }

  .xxl\:mb-3{
    margin-bottom:12px
  }

  .xxl\:mb-4{
    margin-bottom:16px
  }

  .xxl\:mb-5{
    margin-bottom:20px
  }

  .xxl\:mb-6{
    margin-bottom:24px
  }

  .xxl\:mb-8{
    margin-bottom:32px
  }

  .xxl\:mb-10{
    margin-bottom:40px
  }

  .xxl\:mb-12{
    margin-bottom:48px
  }

  .xxl\:mb-16{
    margin-bottom:64px
  }

  .xxl\:mb-18{
    margin-bottom:72px
  }

  .xxl\:mb-24{
    margin-bottom:96px
  }

  .xxl\:mb-auto{
    margin-bottom:auto
  }

  .xxl\:mb-5dot5{
    margin-bottom:22px
  }

  .xxl\:mb-full{
    margin-bottom:100%
  }

  .xxl\:-mb-1{
    margin-bottom:-4px
  }

  .xxl\:-mb-2{
    margin-bottom:-8px
  }

  .xxl\:-mb-3{
    margin-bottom:-12px
  }

  .xxl\:-mb-4{
    margin-bottom:-16px
  }

  .xxl\:-mb-5{
    margin-bottom:-20px
  }

  .xxl\:-mb-6{
    margin-bottom:-24px
  }

  .xxl\:-mb-8{
    margin-bottom:-32px
  }

  .xxl\:-mb-10{
    margin-bottom:-40px
  }

  .xxl\:-mb-12{
    margin-bottom:-48px
  }

  .xxl\:-mb-16{
    margin-bottom:-64px
  }

  .xxl\:-mb-18{
    margin-bottom:-72px
  }

  .xxl\:-mb-24{
    margin-bottom:-96px
  }

  .xxl\:-mb-5dot5{
    margin-bottom:-22px
  }

  .xxl\:-mb-full{
    margin-bottom:-100%
  }

  .xxl\:ml-0{
    margin-left:0
  }

  .xxl\:ml-1{
    margin-left:4px
  }

  .xxl\:ml-2{
    margin-left:8px
  }

  .xxl\:ml-3{
    margin-left:12px
  }

  .xxl\:ml-4{
    margin-left:16px
  }

  .xxl\:ml-5{
    margin-left:20px
  }

  .xxl\:ml-6{
    margin-left:24px
  }

  .xxl\:ml-8{
    margin-left:32px
  }

  .xxl\:ml-10{
    margin-left:40px
  }

  .xxl\:ml-12{
    margin-left:48px
  }

  .xxl\:ml-16{
    margin-left:64px
  }

  .xxl\:ml-18{
    margin-left:72px
  }

  .xxl\:ml-24{
    margin-left:96px
  }

  .xxl\:ml-auto{
    margin-left:auto
  }

  .xxl\:ml-5dot5{
    margin-left:22px
  }

  .xxl\:ml-full{
    margin-left:100%
  }

  .xxl\:-ml-1{
    margin-left:-4px
  }

  .xxl\:-ml-2{
    margin-left:-8px
  }

  .xxl\:-ml-3{
    margin-left:-12px
  }

  .xxl\:-ml-4{
    margin-left:-16px
  }

  .xxl\:-ml-5{
    margin-left:-20px
  }

  .xxl\:-ml-6{
    margin-left:-24px
  }

  .xxl\:-ml-8{
    margin-left:-32px
  }

  .xxl\:-ml-10{
    margin-left:-40px
  }

  .xxl\:-ml-12{
    margin-left:-48px
  }

  .xxl\:-ml-16{
    margin-left:-64px
  }

  .xxl\:-ml-18{
    margin-left:-72px
  }

  .xxl\:-ml-24{
    margin-left:-96px
  }

  .xxl\:-ml-5dot5{
    margin-left:-22px
  }

  .xxl\:-ml-full{
    margin-left:-100%
  }

  .xxl\:first\:mt-0:first-child{
    margin-top:0
  }

  .xxl\:first\:mt-1:first-child{
    margin-top:4px
  }

  .xxl\:first\:mt-2:first-child{
    margin-top:8px
  }

  .xxl\:first\:mt-3:first-child{
    margin-top:12px
  }

  .xxl\:first\:mt-4:first-child{
    margin-top:16px
  }

  .xxl\:first\:mt-5:first-child{
    margin-top:20px
  }

  .xxl\:first\:mt-6:first-child{
    margin-top:24px
  }

  .xxl\:first\:mt-8:first-child{
    margin-top:32px
  }

  .xxl\:first\:mt-10:first-child{
    margin-top:40px
  }

  .xxl\:first\:mt-12:first-child{
    margin-top:48px
  }

  .xxl\:first\:mt-16:first-child{
    margin-top:64px
  }

  .xxl\:first\:mt-18:first-child{
    margin-top:72px
  }

  .xxl\:first\:mt-24:first-child{
    margin-top:96px
  }

  .xxl\:first\:mt-auto:first-child{
    margin-top:auto
  }

  .xxl\:first\:mt-5dot5:first-child{
    margin-top:22px
  }

  .xxl\:first\:mt-full:first-child{
    margin-top:100%
  }

  .xxl\:first\:-mt-1:first-child{
    margin-top:-4px
  }

  .xxl\:first\:-mt-2:first-child{
    margin-top:-8px
  }

  .xxl\:first\:-mt-3:first-child{
    margin-top:-12px
  }

  .xxl\:first\:-mt-4:first-child{
    margin-top:-16px
  }

  .xxl\:first\:-mt-5:first-child{
    margin-top:-20px
  }

  .xxl\:first\:-mt-6:first-child{
    margin-top:-24px
  }

  .xxl\:first\:-mt-8:first-child{
    margin-top:-32px
  }

  .xxl\:first\:-mt-10:first-child{
    margin-top:-40px
  }

  .xxl\:first\:-mt-12:first-child{
    margin-top:-48px
  }

  .xxl\:first\:-mt-16:first-child{
    margin-top:-64px
  }

  .xxl\:first\:-mt-18:first-child{
    margin-top:-72px
  }

  .xxl\:first\:-mt-24:first-child{
    margin-top:-96px
  }

  .xxl\:first\:-mt-5dot5:first-child{
    margin-top:-22px
  }

  .xxl\:first\:-mt-full:first-child{
    margin-top:-100%
  }

  .xxl\:first\:mr-0:first-child{
    margin-right:0
  }

  .xxl\:first\:mr-1:first-child{
    margin-right:4px
  }

  .xxl\:first\:mr-2:first-child{
    margin-right:8px
  }

  .xxl\:first\:mr-3:first-child{
    margin-right:12px
  }

  .xxl\:first\:mr-4:first-child{
    margin-right:16px
  }

  .xxl\:first\:mr-5:first-child{
    margin-right:20px
  }

  .xxl\:first\:mr-6:first-child{
    margin-right:24px
  }

  .xxl\:first\:mr-8:first-child{
    margin-right:32px
  }

  .xxl\:first\:mr-10:first-child{
    margin-right:40px
  }

  .xxl\:first\:mr-12:first-child{
    margin-right:48px
  }

  .xxl\:first\:mr-16:first-child{
    margin-right:64px
  }

  .xxl\:first\:mr-18:first-child{
    margin-right:72px
  }

  .xxl\:first\:mr-24:first-child{
    margin-right:96px
  }

  .xxl\:first\:mr-auto:first-child{
    margin-right:auto
  }

  .xxl\:first\:mr-5dot5:first-child{
    margin-right:22px
  }

  .xxl\:first\:mr-full:first-child{
    margin-right:100%
  }

  .xxl\:first\:-mr-1:first-child{
    margin-right:-4px
  }

  .xxl\:first\:-mr-2:first-child{
    margin-right:-8px
  }

  .xxl\:first\:-mr-3:first-child{
    margin-right:-12px
  }

  .xxl\:first\:-mr-4:first-child{
    margin-right:-16px
  }

  .xxl\:first\:-mr-5:first-child{
    margin-right:-20px
  }

  .xxl\:first\:-mr-6:first-child{
    margin-right:-24px
  }

  .xxl\:first\:-mr-8:first-child{
    margin-right:-32px
  }

  .xxl\:first\:-mr-10:first-child{
    margin-right:-40px
  }

  .xxl\:first\:-mr-12:first-child{
    margin-right:-48px
  }

  .xxl\:first\:-mr-16:first-child{
    margin-right:-64px
  }

  .xxl\:first\:-mr-18:first-child{
    margin-right:-72px
  }

  .xxl\:first\:-mr-24:first-child{
    margin-right:-96px
  }

  .xxl\:first\:-mr-5dot5:first-child{
    margin-right:-22px
  }

  .xxl\:first\:-mr-full:first-child{
    margin-right:-100%
  }

  .xxl\:first\:mb-0:first-child{
    margin-bottom:0
  }

  .xxl\:first\:mb-1:first-child{
    margin-bottom:4px
  }

  .xxl\:first\:mb-2:first-child{
    margin-bottom:8px
  }

  .xxl\:first\:mb-3:first-child{
    margin-bottom:12px
  }

  .xxl\:first\:mb-4:first-child{
    margin-bottom:16px
  }

  .xxl\:first\:mb-5:first-child{
    margin-bottom:20px
  }

  .xxl\:first\:mb-6:first-child{
    margin-bottom:24px
  }

  .xxl\:first\:mb-8:first-child{
    margin-bottom:32px
  }

  .xxl\:first\:mb-10:first-child{
    margin-bottom:40px
  }

  .xxl\:first\:mb-12:first-child{
    margin-bottom:48px
  }

  .xxl\:first\:mb-16:first-child{
    margin-bottom:64px
  }

  .xxl\:first\:mb-18:first-child{
    margin-bottom:72px
  }

  .xxl\:first\:mb-24:first-child{
    margin-bottom:96px
  }

  .xxl\:first\:mb-auto:first-child{
    margin-bottom:auto
  }

  .xxl\:first\:mb-5dot5:first-child{
    margin-bottom:22px
  }

  .xxl\:first\:mb-full:first-child{
    margin-bottom:100%
  }

  .xxl\:first\:-mb-1:first-child{
    margin-bottom:-4px
  }

  .xxl\:first\:-mb-2:first-child{
    margin-bottom:-8px
  }

  .xxl\:first\:-mb-3:first-child{
    margin-bottom:-12px
  }

  .xxl\:first\:-mb-4:first-child{
    margin-bottom:-16px
  }

  .xxl\:first\:-mb-5:first-child{
    margin-bottom:-20px
  }

  .xxl\:first\:-mb-6:first-child{
    margin-bottom:-24px
  }

  .xxl\:first\:-mb-8:first-child{
    margin-bottom:-32px
  }

  .xxl\:first\:-mb-10:first-child{
    margin-bottom:-40px
  }

  .xxl\:first\:-mb-12:first-child{
    margin-bottom:-48px
  }

  .xxl\:first\:-mb-16:first-child{
    margin-bottom:-64px
  }

  .xxl\:first\:-mb-18:first-child{
    margin-bottom:-72px
  }

  .xxl\:first\:-mb-24:first-child{
    margin-bottom:-96px
  }

  .xxl\:first\:-mb-5dot5:first-child{
    margin-bottom:-22px
  }

  .xxl\:first\:-mb-full:first-child{
    margin-bottom:-100%
  }

  .xxl\:first\:ml-0:first-child{
    margin-left:0
  }

  .xxl\:first\:ml-1:first-child{
    margin-left:4px
  }

  .xxl\:first\:ml-2:first-child{
    margin-left:8px
  }

  .xxl\:first\:ml-3:first-child{
    margin-left:12px
  }

  .xxl\:first\:ml-4:first-child{
    margin-left:16px
  }

  .xxl\:first\:ml-5:first-child{
    margin-left:20px
  }

  .xxl\:first\:ml-6:first-child{
    margin-left:24px
  }

  .xxl\:first\:ml-8:first-child{
    margin-left:32px
  }

  .xxl\:first\:ml-10:first-child{
    margin-left:40px
  }

  .xxl\:first\:ml-12:first-child{
    margin-left:48px
  }

  .xxl\:first\:ml-16:first-child{
    margin-left:64px
  }

  .xxl\:first\:ml-18:first-child{
    margin-left:72px
  }

  .xxl\:first\:ml-24:first-child{
    margin-left:96px
  }

  .xxl\:first\:ml-auto:first-child{
    margin-left:auto
  }

  .xxl\:first\:ml-5dot5:first-child{
    margin-left:22px
  }

  .xxl\:first\:ml-full:first-child{
    margin-left:100%
  }

  .xxl\:first\:-ml-1:first-child{
    margin-left:-4px
  }

  .xxl\:first\:-ml-2:first-child{
    margin-left:-8px
  }

  .xxl\:first\:-ml-3:first-child{
    margin-left:-12px
  }

  .xxl\:first\:-ml-4:first-child{
    margin-left:-16px
  }

  .xxl\:first\:-ml-5:first-child{
    margin-left:-20px
  }

  .xxl\:first\:-ml-6:first-child{
    margin-left:-24px
  }

  .xxl\:first\:-ml-8:first-child{
    margin-left:-32px
  }

  .xxl\:first\:-ml-10:first-child{
    margin-left:-40px
  }

  .xxl\:first\:-ml-12:first-child{
    margin-left:-48px
  }

  .xxl\:first\:-ml-16:first-child{
    margin-left:-64px
  }

  .xxl\:first\:-ml-18:first-child{
    margin-left:-72px
  }

  .xxl\:first\:-ml-24:first-child{
    margin-left:-96px
  }

  .xxl\:first\:-ml-5dot5:first-child{
    margin-left:-22px
  }

  .xxl\:first\:-ml-full:first-child{
    margin-left:-100%
  }

  .xxl\:last\:mt-0:last-child{
    margin-top:0
  }

  .xxl\:last\:mt-1:last-child{
    margin-top:4px
  }

  .xxl\:last\:mt-2:last-child{
    margin-top:8px
  }

  .xxl\:last\:mt-3:last-child{
    margin-top:12px
  }

  .xxl\:last\:mt-4:last-child{
    margin-top:16px
  }

  .xxl\:last\:mt-5:last-child{
    margin-top:20px
  }

  .xxl\:last\:mt-6:last-child{
    margin-top:24px
  }

  .xxl\:last\:mt-8:last-child{
    margin-top:32px
  }

  .xxl\:last\:mt-10:last-child{
    margin-top:40px
  }

  .xxl\:last\:mt-12:last-child{
    margin-top:48px
  }

  .xxl\:last\:mt-16:last-child{
    margin-top:64px
  }

  .xxl\:last\:mt-18:last-child{
    margin-top:72px
  }

  .xxl\:last\:mt-24:last-child{
    margin-top:96px
  }

  .xxl\:last\:mt-auto:last-child{
    margin-top:auto
  }

  .xxl\:last\:mt-5dot5:last-child{
    margin-top:22px
  }

  .xxl\:last\:mt-full:last-child{
    margin-top:100%
  }

  .xxl\:last\:-mt-1:last-child{
    margin-top:-4px
  }

  .xxl\:last\:-mt-2:last-child{
    margin-top:-8px
  }

  .xxl\:last\:-mt-3:last-child{
    margin-top:-12px
  }

  .xxl\:last\:-mt-4:last-child{
    margin-top:-16px
  }

  .xxl\:last\:-mt-5:last-child{
    margin-top:-20px
  }

  .xxl\:last\:-mt-6:last-child{
    margin-top:-24px
  }

  .xxl\:last\:-mt-8:last-child{
    margin-top:-32px
  }

  .xxl\:last\:-mt-10:last-child{
    margin-top:-40px
  }

  .xxl\:last\:-mt-12:last-child{
    margin-top:-48px
  }

  .xxl\:last\:-mt-16:last-child{
    margin-top:-64px
  }

  .xxl\:last\:-mt-18:last-child{
    margin-top:-72px
  }

  .xxl\:last\:-mt-24:last-child{
    margin-top:-96px
  }

  .xxl\:last\:-mt-5dot5:last-child{
    margin-top:-22px
  }

  .xxl\:last\:-mt-full:last-child{
    margin-top:-100%
  }

  .xxl\:last\:mr-0:last-child{
    margin-right:0
  }

  .xxl\:last\:mr-1:last-child{
    margin-right:4px
  }

  .xxl\:last\:mr-2:last-child{
    margin-right:8px
  }

  .xxl\:last\:mr-3:last-child{
    margin-right:12px
  }

  .xxl\:last\:mr-4:last-child{
    margin-right:16px
  }

  .xxl\:last\:mr-5:last-child{
    margin-right:20px
  }

  .xxl\:last\:mr-6:last-child{
    margin-right:24px
  }

  .xxl\:last\:mr-8:last-child{
    margin-right:32px
  }

  .xxl\:last\:mr-10:last-child{
    margin-right:40px
  }

  .xxl\:last\:mr-12:last-child{
    margin-right:48px
  }

  .xxl\:last\:mr-16:last-child{
    margin-right:64px
  }

  .xxl\:last\:mr-18:last-child{
    margin-right:72px
  }

  .xxl\:last\:mr-24:last-child{
    margin-right:96px
  }

  .xxl\:last\:mr-auto:last-child{
    margin-right:auto
  }

  .xxl\:last\:mr-5dot5:last-child{
    margin-right:22px
  }

  .xxl\:last\:mr-full:last-child{
    margin-right:100%
  }

  .xxl\:last\:-mr-1:last-child{
    margin-right:-4px
  }

  .xxl\:last\:-mr-2:last-child{
    margin-right:-8px
  }

  .xxl\:last\:-mr-3:last-child{
    margin-right:-12px
  }

  .xxl\:last\:-mr-4:last-child{
    margin-right:-16px
  }

  .xxl\:last\:-mr-5:last-child{
    margin-right:-20px
  }

  .xxl\:last\:-mr-6:last-child{
    margin-right:-24px
  }

  .xxl\:last\:-mr-8:last-child{
    margin-right:-32px
  }

  .xxl\:last\:-mr-10:last-child{
    margin-right:-40px
  }

  .xxl\:last\:-mr-12:last-child{
    margin-right:-48px
  }

  .xxl\:last\:-mr-16:last-child{
    margin-right:-64px
  }

  .xxl\:last\:-mr-18:last-child{
    margin-right:-72px
  }

  .xxl\:last\:-mr-24:last-child{
    margin-right:-96px
  }

  .xxl\:last\:-mr-5dot5:last-child{
    margin-right:-22px
  }

  .xxl\:last\:-mr-full:last-child{
    margin-right:-100%
  }

  .xxl\:last\:mb-0:last-child{
    margin-bottom:0
  }

  .xxl\:last\:mb-1:last-child{
    margin-bottom:4px
  }

  .xxl\:last\:mb-2:last-child{
    margin-bottom:8px
  }

  .xxl\:last\:mb-3:last-child{
    margin-bottom:12px
  }

  .xxl\:last\:mb-4:last-child{
    margin-bottom:16px
  }

  .xxl\:last\:mb-5:last-child{
    margin-bottom:20px
  }

  .xxl\:last\:mb-6:last-child{
    margin-bottom:24px
  }

  .xxl\:last\:mb-8:last-child{
    margin-bottom:32px
  }

  .xxl\:last\:mb-10:last-child{
    margin-bottom:40px
  }

  .xxl\:last\:mb-12:last-child{
    margin-bottom:48px
  }

  .xxl\:last\:mb-16:last-child{
    margin-bottom:64px
  }

  .xxl\:last\:mb-18:last-child{
    margin-bottom:72px
  }

  .xxl\:last\:mb-24:last-child{
    margin-bottom:96px
  }

  .xxl\:last\:mb-auto:last-child{
    margin-bottom:auto
  }

  .xxl\:last\:mb-5dot5:last-child{
    margin-bottom:22px
  }

  .xxl\:last\:mb-full:last-child{
    margin-bottom:100%
  }

  .xxl\:last\:-mb-1:last-child{
    margin-bottom:-4px
  }

  .xxl\:last\:-mb-2:last-child{
    margin-bottom:-8px
  }

  .xxl\:last\:-mb-3:last-child{
    margin-bottom:-12px
  }

  .xxl\:last\:-mb-4:last-child{
    margin-bottom:-16px
  }

  .xxl\:last\:-mb-5:last-child{
    margin-bottom:-20px
  }

  .xxl\:last\:-mb-6:last-child{
    margin-bottom:-24px
  }

  .xxl\:last\:-mb-8:last-child{
    margin-bottom:-32px
  }

  .xxl\:last\:-mb-10:last-child{
    margin-bottom:-40px
  }

  .xxl\:last\:-mb-12:last-child{
    margin-bottom:-48px
  }

  .xxl\:last\:-mb-16:last-child{
    margin-bottom:-64px
  }

  .xxl\:last\:-mb-18:last-child{
    margin-bottom:-72px
  }

  .xxl\:last\:-mb-24:last-child{
    margin-bottom:-96px
  }

  .xxl\:last\:-mb-5dot5:last-child{
    margin-bottom:-22px
  }

  .xxl\:last\:-mb-full:last-child{
    margin-bottom:-100%
  }

  .xxl\:last\:ml-0:last-child{
    margin-left:0
  }

  .xxl\:last\:ml-1:last-child{
    margin-left:4px
  }

  .xxl\:last\:ml-2:last-child{
    margin-left:8px
  }

  .xxl\:last\:ml-3:last-child{
    margin-left:12px
  }

  .xxl\:last\:ml-4:last-child{
    margin-left:16px
  }

  .xxl\:last\:ml-5:last-child{
    margin-left:20px
  }

  .xxl\:last\:ml-6:last-child{
    margin-left:24px
  }

  .xxl\:last\:ml-8:last-child{
    margin-left:32px
  }

  .xxl\:last\:ml-10:last-child{
    margin-left:40px
  }

  .xxl\:last\:ml-12:last-child{
    margin-left:48px
  }

  .xxl\:last\:ml-16:last-child{
    margin-left:64px
  }

  .xxl\:last\:ml-18:last-child{
    margin-left:72px
  }

  .xxl\:last\:ml-24:last-child{
    margin-left:96px
  }

  .xxl\:last\:ml-auto:last-child{
    margin-left:auto
  }

  .xxl\:last\:ml-5dot5:last-child{
    margin-left:22px
  }

  .xxl\:last\:ml-full:last-child{
    margin-left:100%
  }

  .xxl\:last\:-ml-1:last-child{
    margin-left:-4px
  }

  .xxl\:last\:-ml-2:last-child{
    margin-left:-8px
  }

  .xxl\:last\:-ml-3:last-child{
    margin-left:-12px
  }

  .xxl\:last\:-ml-4:last-child{
    margin-left:-16px
  }

  .xxl\:last\:-ml-5:last-child{
    margin-left:-20px
  }

  .xxl\:last\:-ml-6:last-child{
    margin-left:-24px
  }

  .xxl\:last\:-ml-8:last-child{
    margin-left:-32px
  }

  .xxl\:last\:-ml-10:last-child{
    margin-left:-40px
  }

  .xxl\:last\:-ml-12:last-child{
    margin-left:-48px
  }

  .xxl\:last\:-ml-16:last-child{
    margin-left:-64px
  }

  .xxl\:last\:-ml-18:last-child{
    margin-left:-72px
  }

  .xxl\:last\:-ml-24:last-child{
    margin-left:-96px
  }

  .xxl\:last\:-ml-5dot5:last-child{
    margin-left:-22px
  }

  .xxl\:last\:-ml-full:last-child{
    margin-left:-100%
  }

  .xxl\:block{
    display:block
  }

  .xxl\:inline-block{
    display:inline-block
  }

  .xxl\:inline{
    display:inline
  }

  .xxl\:flex{
    display:flex
  }

  .xxl\:inline-flex{
    display:inline-flex
  }

  .xxl\:table{
    display:table
  }

  .xxl\:inline-table{
    display:inline-table
  }

  .xxl\:table-caption{
    display:table-caption
  }

  .xxl\:table-cell{
    display:table-cell
  }

  .xxl\:table-column{
    display:table-column
  }

  .xxl\:table-column-group{
    display:table-column-group
  }

  .xxl\:table-footer-group{
    display:table-footer-group
  }

  .xxl\:table-header-group{
    display:table-header-group
  }

  .xxl\:table-row-group{
    display:table-row-group
  }

  .xxl\:table-row{
    display:table-row
  }

  .xxl\:flow-root{
    display:flow-root
  }

  .xxl\:grid{
    display:grid
  }

  .xxl\:inline-grid{
    display:inline-grid
  }

  .xxl\:contents{
    display:contents
  }

  .xxl\:list-item{
    display:list-item
  }

  .xxl\:hidden{
    display:none
  }

  .xxl\:h-0{
    height:0
  }

  .xxl\:h-1{
    height:4px
  }

  .xxl\:h-2{
    height:8px
  }

  .xxl\:h-3{
    height:12px
  }

  .xxl\:h-4{
    height:16px
  }

  .xxl\:h-5{
    height:20px
  }

  .xxl\:h-6{
    height:24px
  }

  .xxl\:h-8{
    height:32px
  }

  .xxl\:h-10{
    height:40px
  }

  .xxl\:h-12{
    height:48px
  }

  .xxl\:h-15{
    height:60px
  }

  .xxl\:h-16{
    height:64px
  }

  .xxl\:h-18{
    height:72px
  }

  .xxl\:h-24{
    height:96px
  }

  .xxl\:h-auto{
    height:auto
  }

  .xxl\:h-5dot5{
    height:22px
  }

  .xxl\:h-full{
    height:100%
  }

  .xxl\:h-1\/2{
    height:50%
  }

  .xxl\:h-1\/3{
    height:33.333333%
  }

  .xxl\:h-2\/3{
    height:66.666667%
  }

  .xxl\:h-1\/4{
    height:25%
  }

  .xxl\:h-2\/4{
    height:50%
  }

  .xxl\:h-3\/4{
    height:75%
  }

  .xxl\:h-1\/5{
    height:20%
  }

  .xxl\:h-2\/5{
    height:40%
  }

  .xxl\:h-3\/5{
    height:60%
  }

  .xxl\:h-4\/5{
    height:80%
  }

  .xxl\:h-1\/6{
    height:16.666667%
  }

  .xxl\:h-2\/6{
    height:33.333333%
  }

  .xxl\:h-3\/6{
    height:50%
  }

  .xxl\:h-4\/6{
    height:66.666667%
  }

  .xxl\:h-5\/6{
    height:83.333333%
  }

  .xxl\:h-screen{
    height:100vh
  }

  .xxl\:max-h-0{
    max-height:0
  }

  .xxl\:max-h-1{
    max-height:4px
  }

  .xxl\:max-h-2{
    max-height:8px
  }

  .xxl\:max-h-3{
    max-height:12px
  }

  .xxl\:max-h-4{
    max-height:16px
  }

  .xxl\:max-h-5{
    max-height:20px
  }

  .xxl\:max-h-6{
    max-height:24px
  }

  .xxl\:max-h-8{
    max-height:32px
  }

  .xxl\:max-h-10{
    max-height:40px
  }

  .xxl\:max-h-12{
    max-height:48px
  }

  .xxl\:max-h-16{
    max-height:64px
  }

  .xxl\:max-h-18{
    max-height:72px
  }

  .xxl\:max-h-24{
    max-height:96px
  }

  .xxl\:max-h-5dot5{
    max-height:22px
  }

  .xxl\:max-h-full{
    max-height:100%
  }

  .xxl\:max-h-screen{
    max-height:100vh
  }

  .xxl\:min-h-0{
    min-height:0px
  }

  .xxl\:min-h-6{
    min-height:24px
  }

  .xxl\:min-h-10{
    min-height:40px
  }

  .xxl\:min-h-15{
    min-height:60px
  }

  .xxl\:min-h-100{
    min-height:400px
  }

  .xxl\:min-h-full{
    min-height:100%
  }

  .xxl\:min-h-screen{
    min-height:100vh
  }

  .xxl\:min-h-100vh{
    min-height:100vh
  }

  .xxl\:w-0{
    width:0
  }

  .xxl\:w-1{
    width:4px
  }

  .xxl\:w-2{
    width:8px
  }

  .xxl\:w-3{
    width:12px
  }

  .xxl\:w-4{
    width:16px
  }

  .xxl\:w-5{
    width:20px
  }

  .xxl\:w-6{
    width:24px
  }

  .xxl\:w-8{
    width:32px
  }

  .xxl\:w-10{
    width:40px
  }

  .xxl\:w-12{
    width:48px
  }

  .xxl\:w-16{
    width:64px
  }

  .xxl\:w-18{
    width:72px
  }

  .xxl\:w-24{
    width:96px
  }

  .xxl\:w-25{
    width:100px
  }

  .xxl\:w-26{
    width:104px
  }

  .xxl\:w-30{
    width:120px
  }

  .xxl\:w-80{
    width:320px
  }

  .xxl\:w-125{
    width:500px
  }

  .xxl\:w-160{
    width:640px
  }

  .xxl\:w-180{
    width:720px
  }

  .xxl\:w-200{
    width:800px
  }

  .xxl\:w-240{
    width:960px
  }

  .xxl\:w-320{
    width:1280px
  }

  .xxl\:w-auto{
    width:auto
  }

  .xxl\:w-5dot5{
    width:22px
  }

  .xxl\:w-full{
    width:100%
  }

  .xxl\:w-1\/2{
    width:50%
  }

  .xxl\:w-1\/3{
    width:33.333333%
  }

  .xxl\:w-2\/3{
    width:66.666667%
  }

  .xxl\:w-1\/4{
    width:25%
  }

  .xxl\:w-2\/4{
    width:50%
  }

  .xxl\:w-3\/4{
    width:75%
  }

  .xxl\:w-1\/5{
    width:20%
  }

  .xxl\:w-2\/5{
    width:40%
  }

  .xxl\:w-3\/5{
    width:60%
  }

  .xxl\:w-4\/5{
    width:80%
  }

  .xxl\:w-1\/6{
    width:16.666667%
  }

  .xxl\:w-2\/6{
    width:33.333333%
  }

  .xxl\:w-3\/6{
    width:50%
  }

  .xxl\:w-4\/6{
    width:66.666667%
  }

  .xxl\:w-5\/6{
    width:83.333333%
  }

  .xxl\:w-1\/12{
    width:8.333333%
  }

  .xxl\:w-2\/12{
    width:16.666667%
  }

  .xxl\:w-3\/12{
    width:25%
  }

  .xxl\:w-4\/12{
    width:33.333333%
  }

  .xxl\:w-5\/12{
    width:41.666667%
  }

  .xxl\:w-6\/12{
    width:50%
  }

  .xxl\:w-7\/12{
    width:58.333333%
  }

  .xxl\:w-8\/12{
    width:66.666667%
  }

  .xxl\:w-9\/12{
    width:75%
  }

  .xxl\:w-10\/12{
    width:83.333333%
  }

  .xxl\:w-11\/12{
    width:91.666667%
  }

  .xxl\:w-screen{
    width:100vw
  }

  .xxl\:w-min{
    width:min-content
  }

  .xxl\:w-max{
    width:max-content
  }

  .xxl\:min-w-0{
    min-width:0px
  }

  .xxl\:min-w-full{
    min-width:100%
  }

  .xxl\:min-w-min{
    min-width:min-content
  }

  .xxl\:min-w-max{
    min-width:max-content
  }

  .xxl\:max-w-0{
    max-width:0rem
  }

  .xxl\:max-w-none{
    max-width:none
  }

  .xxl\:max-w-xs{
    max-width:20rem
  }

  .xxl\:max-w-sm{
    max-width:24rem
  }

  .xxl\:max-w-md{
    max-width:28rem
  }

  .xxl\:max-w-lg{
    max-width:32rem
  }

  .xxl\:max-w-xl{
    max-width:36rem
  }

  .xxl\:max-w-2xl{
    max-width:42rem
  }

  .xxl\:max-w-3xl{
    max-width:48rem
  }

  .xxl\:max-w-4xl{
    max-width:56rem
  }

  .xxl\:max-w-5xl{
    max-width:64rem
  }

  .xxl\:max-w-6xl{
    max-width:72rem
  }

  .xxl\:max-w-7xl{
    max-width:80rem
  }

  .xxl\:max-w-full{
    max-width:100%
  }

  .xxl\:max-w-min{
    max-width:min-content
  }

  .xxl\:max-w-max{
    max-width:max-content
  }

  .xxl\:max-w-prose{
    max-width:65ch
  }

  .xxl\:max-w-grid-12{
    max-width:1176px
  }

  .xxl\:max-w-grid-10{
    max-width:984px
  }

  .xxl\:max-w-grid-8{
    max-width:768px
  }

  .xxl\:max-w-1\/4{
    max-width:25%
  }

  .xxl\:max-w-1\/2{
    max-width:50%
  }

  .xxl\:max-w-3\/4{
    max-width:75%
  }

  .xxl\:max-w-1\/5{
    max-width:20%
  }

  .xxl\:max-w-2\/5{
    max-width:40%
  }

  .xxl\:max-w-3\/5{
    max-width:60%
  }

  .xxl\:max-w-4\/5{
    max-width:80%
  }

  .xxl\:max-w-100vw{
    max-width:100vw
  }

  .xxl\:flex-1{
    flex:1 1 0%
  }

  .xxl\:flex-2{
    flex:2 1 0%
  }

  .xxl\:flex-3{
    flex:3 1 0%
  }

  .xxl\:flex-auto{
    flex:1 1 auto
  }

  .xxl\:flex-initial{
    flex:0 1 auto
  }

  .xxl\:flex-none{
    flex:none
  }

  .xxl\:flex-shrink-0{
    flex-shrink:0
  }

  .xxl\:flex-shrink{
    flex-shrink:1
  }

  .xxl\:flex-grow-0{
    flex-grow:0
  }

  .xxl\:flex-grow{
    flex-grow:1
  }

  .xxl\:border-collapse{
    border-collapse:collapse
  }

  .xxl\:border-separate{
    border-collapse:separate
  }

  .xxl\:transform{
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .xxl\:transform-gpu{
    --tw-translate-x:0;
    --tw-translate-y:0;
    --tw-rotate:0;
    --tw-skew-x:0;
    --tw-skew-y:0;
    --tw-scale-x:1;
    --tw-scale-y:1;
    transform:translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .xxl\:transform-none{
    transform:none
  }

  .xxl\:translate-x-0{
    --tw-translate-x:0
  }

  .xxl\:translate-x-1{
    --tw-translate-x:4px
  }

  .xxl\:translate-x-2{
    --tw-translate-x:8px
  }

  .xxl\:translate-x-3{
    --tw-translate-x:12px
  }

  .xxl\:translate-x-4{
    --tw-translate-x:16px
  }

  .xxl\:translate-x-5{
    --tw-translate-x:20px
  }

  .xxl\:translate-x-6{
    --tw-translate-x:24px
  }

  .xxl\:translate-x-8{
    --tw-translate-x:32px
  }

  .xxl\:translate-x-10{
    --tw-translate-x:40px
  }

  .xxl\:translate-x-12{
    --tw-translate-x:48px
  }

  .xxl\:translate-x-16{
    --tw-translate-x:64px
  }

  .xxl\:translate-x-18{
    --tw-translate-x:72px
  }

  .xxl\:translate-x-24{
    --tw-translate-x:96px
  }

  .xxl\:translate-x-5dot5{
    --tw-translate-x:22px
  }

  .xxl\:translate-x-full{
    --tw-translate-x:100%
  }

  .xxl\:-translate-x-1{
    --tw-translate-x:-4px
  }

  .xxl\:-translate-x-2{
    --tw-translate-x:-8px
  }

  .xxl\:-translate-x-3{
    --tw-translate-x:-12px
  }

  .xxl\:-translate-x-4{
    --tw-translate-x:-16px
  }

  .xxl\:-translate-x-5{
    --tw-translate-x:-20px
  }

  .xxl\:-translate-x-6{
    --tw-translate-x:-24px
  }

  .xxl\:-translate-x-8{
    --tw-translate-x:-32px
  }

  .xxl\:-translate-x-10{
    --tw-translate-x:-40px
  }

  .xxl\:-translate-x-12{
    --tw-translate-x:-48px
  }

  .xxl\:-translate-x-16{
    --tw-translate-x:-64px
  }

  .xxl\:-translate-x-18{
    --tw-translate-x:-72px
  }

  .xxl\:-translate-x-24{
    --tw-translate-x:-96px
  }

  .xxl\:-translate-x-5dot5{
    --tw-translate-x:-22px
  }

  .xxl\:-translate-x-full{
    --tw-translate-x:-100%
  }

  .xxl\:translate-x-1\/2{
    --tw-translate-x:50%
  }

  .xxl\:translate-x-1\/3{
    --tw-translate-x:33.333333%
  }

  .xxl\:translate-x-2\/3{
    --tw-translate-x:66.666667%
  }

  .xxl\:translate-x-1\/4{
    --tw-translate-x:25%
  }

  .xxl\:translate-x-2\/4{
    --tw-translate-x:50%
  }

  .xxl\:translate-x-3\/4{
    --tw-translate-x:75%
  }

  .xxl\:-translate-x-1\/2{
    --tw-translate-x:-50%
  }

  .xxl\:-translate-x-1\/3{
    --tw-translate-x:-33.333333%
  }

  .xxl\:-translate-x-2\/3{
    --tw-translate-x:-66.666667%
  }

  .xxl\:-translate-x-1\/4{
    --tw-translate-x:-25%
  }

  .xxl\:-translate-x-2\/4{
    --tw-translate-x:-50%
  }

  .xxl\:-translate-x-3\/4{
    --tw-translate-x:-75%
  }

  .xxl\:translate-y-0{
    --tw-translate-y:0
  }

  .xxl\:translate-y-1{
    --tw-translate-y:4px
  }

  .xxl\:translate-y-2{
    --tw-translate-y:8px
  }

  .xxl\:translate-y-3{
    --tw-translate-y:12px
  }

  .xxl\:translate-y-4{
    --tw-translate-y:16px
  }

  .xxl\:translate-y-5{
    --tw-translate-y:20px
  }

  .xxl\:translate-y-6{
    --tw-translate-y:24px
  }

  .xxl\:translate-y-8{
    --tw-translate-y:32px
  }

  .xxl\:translate-y-10{
    --tw-translate-y:40px
  }

  .xxl\:translate-y-12{
    --tw-translate-y:48px
  }

  .xxl\:translate-y-16{
    --tw-translate-y:64px
  }

  .xxl\:translate-y-18{
    --tw-translate-y:72px
  }

  .xxl\:translate-y-24{
    --tw-translate-y:96px
  }

  .xxl\:translate-y-5dot5{
    --tw-translate-y:22px
  }

  .xxl\:translate-y-full{
    --tw-translate-y:100%
  }

  .xxl\:-translate-y-1{
    --tw-translate-y:-4px
  }

  .xxl\:-translate-y-2{
    --tw-translate-y:-8px
  }

  .xxl\:-translate-y-3{
    --tw-translate-y:-12px
  }

  .xxl\:-translate-y-4{
    --tw-translate-y:-16px
  }

  .xxl\:-translate-y-5{
    --tw-translate-y:-20px
  }

  .xxl\:-translate-y-6{
    --tw-translate-y:-24px
  }

  .xxl\:-translate-y-8{
    --tw-translate-y:-32px
  }

  .xxl\:-translate-y-10{
    --tw-translate-y:-40px
  }

  .xxl\:-translate-y-12{
    --tw-translate-y:-48px
  }

  .xxl\:-translate-y-16{
    --tw-translate-y:-64px
  }

  .xxl\:-translate-y-18{
    --tw-translate-y:-72px
  }

  .xxl\:-translate-y-24{
    --tw-translate-y:-96px
  }

  .xxl\:-translate-y-5dot5{
    --tw-translate-y:-22px
  }

  .xxl\:-translate-y-full{
    --tw-translate-y:-100%
  }

  .xxl\:translate-y-1\/2{
    --tw-translate-y:50%
  }

  .xxl\:translate-y-1\/3{
    --tw-translate-y:33.333333%
  }

  .xxl\:translate-y-2\/3{
    --tw-translate-y:66.666667%
  }

  .xxl\:translate-y-1\/4{
    --tw-translate-y:25%
  }

  .xxl\:translate-y-2\/4{
    --tw-translate-y:50%
  }

  .xxl\:translate-y-3\/4{
    --tw-translate-y:75%
  }

  .xxl\:-translate-y-1\/2{
    --tw-translate-y:-50%
  }

  .xxl\:-translate-y-1\/3{
    --tw-translate-y:-33.333333%
  }

  .xxl\:-translate-y-2\/3{
    --tw-translate-y:-66.666667%
  }

  .xxl\:-translate-y-1\/4{
    --tw-translate-y:-25%
  }

  .xxl\:-translate-y-2\/4{
    --tw-translate-y:-50%
  }

  .xxl\:-translate-y-3\/4{
    --tw-translate-y:-75%
  }

  .xxl\:hover\:translate-x-0:hover{
    --tw-translate-x:0
  }

  .xxl\:hover\:translate-x-1:hover{
    --tw-translate-x:4px
  }

  .xxl\:hover\:translate-x-2:hover{
    --tw-translate-x:8px
  }

  .xxl\:hover\:translate-x-3:hover{
    --tw-translate-x:12px
  }

  .xxl\:hover\:translate-x-4:hover{
    --tw-translate-x:16px
  }

  .xxl\:hover\:translate-x-5:hover{
    --tw-translate-x:20px
  }

  .xxl\:hover\:translate-x-6:hover{
    --tw-translate-x:24px
  }

  .xxl\:hover\:translate-x-8:hover{
    --tw-translate-x:32px
  }

  .xxl\:hover\:translate-x-10:hover{
    --tw-translate-x:40px
  }

  .xxl\:hover\:translate-x-12:hover{
    --tw-translate-x:48px
  }

  .xxl\:hover\:translate-x-16:hover{
    --tw-translate-x:64px
  }

  .xxl\:hover\:translate-x-18:hover{
    --tw-translate-x:72px
  }

  .xxl\:hover\:translate-x-24:hover{
    --tw-translate-x:96px
  }

  .xxl\:hover\:translate-x-5dot5:hover{
    --tw-translate-x:22px
  }

  .xxl\:hover\:translate-x-full:hover{
    --tw-translate-x:100%
  }

  .xxl\:hover\:-translate-x-1:hover{
    --tw-translate-x:-4px
  }

  .xxl\:hover\:-translate-x-2:hover{
    --tw-translate-x:-8px
  }

  .xxl\:hover\:-translate-x-3:hover{
    --tw-translate-x:-12px
  }

  .xxl\:hover\:-translate-x-4:hover{
    --tw-translate-x:-16px
  }

  .xxl\:hover\:-translate-x-5:hover{
    --tw-translate-x:-20px
  }

  .xxl\:hover\:-translate-x-6:hover{
    --tw-translate-x:-24px
  }

  .xxl\:hover\:-translate-x-8:hover{
    --tw-translate-x:-32px
  }

  .xxl\:hover\:-translate-x-10:hover{
    --tw-translate-x:-40px
  }

  .xxl\:hover\:-translate-x-12:hover{
    --tw-translate-x:-48px
  }

  .xxl\:hover\:-translate-x-16:hover{
    --tw-translate-x:-64px
  }

  .xxl\:hover\:-translate-x-18:hover{
    --tw-translate-x:-72px
  }

  .xxl\:hover\:-translate-x-24:hover{
    --tw-translate-x:-96px
  }

  .xxl\:hover\:-translate-x-5dot5:hover{
    --tw-translate-x:-22px
  }

  .xxl\:hover\:-translate-x-full:hover{
    --tw-translate-x:-100%
  }

  .xxl\:hover\:translate-x-1\/2:hover{
    --tw-translate-x:50%
  }

  .xxl\:hover\:translate-x-1\/3:hover{
    --tw-translate-x:33.333333%
  }

  .xxl\:hover\:translate-x-2\/3:hover{
    --tw-translate-x:66.666667%
  }

  .xxl\:hover\:translate-x-1\/4:hover{
    --tw-translate-x:25%
  }

  .xxl\:hover\:translate-x-2\/4:hover{
    --tw-translate-x:50%
  }

  .xxl\:hover\:translate-x-3\/4:hover{
    --tw-translate-x:75%
  }

  .xxl\:hover\:-translate-x-1\/2:hover{
    --tw-translate-x:-50%
  }

  .xxl\:hover\:-translate-x-1\/3:hover{
    --tw-translate-x:-33.333333%
  }

  .xxl\:hover\:-translate-x-2\/3:hover{
    --tw-translate-x:-66.666667%
  }

  .xxl\:hover\:-translate-x-1\/4:hover{
    --tw-translate-x:-25%
  }

  .xxl\:hover\:-translate-x-2\/4:hover{
    --tw-translate-x:-50%
  }

  .xxl\:hover\:-translate-x-3\/4:hover{
    --tw-translate-x:-75%
  }

  .xxl\:hover\:translate-y-0:hover{
    --tw-translate-y:0
  }

  .xxl\:hover\:translate-y-1:hover{
    --tw-translate-y:4px
  }

  .xxl\:hover\:translate-y-2:hover{
    --tw-translate-y:8px
  }

  .xxl\:hover\:translate-y-3:hover{
    --tw-translate-y:12px
  }

  .xxl\:hover\:translate-y-4:hover{
    --tw-translate-y:16px
  }

  .xxl\:hover\:translate-y-5:hover{
    --tw-translate-y:20px
  }

  .xxl\:hover\:translate-y-6:hover{
    --tw-translate-y:24px
  }

  .xxl\:hover\:translate-y-8:hover{
    --tw-translate-y:32px
  }

  .xxl\:hover\:translate-y-10:hover{
    --tw-translate-y:40px
  }

  .xxl\:hover\:translate-y-12:hover{
    --tw-translate-y:48px
  }

  .xxl\:hover\:translate-y-16:hover{
    --tw-translate-y:64px
  }

  .xxl\:hover\:translate-y-18:hover{
    --tw-translate-y:72px
  }

  .xxl\:hover\:translate-y-24:hover{
    --tw-translate-y:96px
  }

  .xxl\:hover\:translate-y-5dot5:hover{
    --tw-translate-y:22px
  }

  .xxl\:hover\:translate-y-full:hover{
    --tw-translate-y:100%
  }

  .xxl\:hover\:-translate-y-1:hover{
    --tw-translate-y:-4px
  }

  .xxl\:hover\:-translate-y-2:hover{
    --tw-translate-y:-8px
  }

  .xxl\:hover\:-translate-y-3:hover{
    --tw-translate-y:-12px
  }

  .xxl\:hover\:-translate-y-4:hover{
    --tw-translate-y:-16px
  }

  .xxl\:hover\:-translate-y-5:hover{
    --tw-translate-y:-20px
  }

  .xxl\:hover\:-translate-y-6:hover{
    --tw-translate-y:-24px
  }

  .xxl\:hover\:-translate-y-8:hover{
    --tw-translate-y:-32px
  }

  .xxl\:hover\:-translate-y-10:hover{
    --tw-translate-y:-40px
  }

  .xxl\:hover\:-translate-y-12:hover{
    --tw-translate-y:-48px
  }

  .xxl\:hover\:-translate-y-16:hover{
    --tw-translate-y:-64px
  }

  .xxl\:hover\:-translate-y-18:hover{
    --tw-translate-y:-72px
  }

  .xxl\:hover\:-translate-y-24:hover{
    --tw-translate-y:-96px
  }

  .xxl\:hover\:-translate-y-5dot5:hover{
    --tw-translate-y:-22px
  }

  .xxl\:hover\:-translate-y-full:hover{
    --tw-translate-y:-100%
  }

  .xxl\:hover\:translate-y-1\/2:hover{
    --tw-translate-y:50%
  }

  .xxl\:hover\:translate-y-1\/3:hover{
    --tw-translate-y:33.333333%
  }

  .xxl\:hover\:translate-y-2\/3:hover{
    --tw-translate-y:66.666667%
  }

  .xxl\:hover\:translate-y-1\/4:hover{
    --tw-translate-y:25%
  }

  .xxl\:hover\:translate-y-2\/4:hover{
    --tw-translate-y:50%
  }

  .xxl\:hover\:translate-y-3\/4:hover{
    --tw-translate-y:75%
  }

  .xxl\:hover\:-translate-y-1\/2:hover{
    --tw-translate-y:-50%
  }

  .xxl\:hover\:-translate-y-1\/3:hover{
    --tw-translate-y:-33.333333%
  }

  .xxl\:hover\:-translate-y-2\/3:hover{
    --tw-translate-y:-66.666667%
  }

  .xxl\:hover\:-translate-y-1\/4:hover{
    --tw-translate-y:-25%
  }

  .xxl\:hover\:-translate-y-2\/4:hover{
    --tw-translate-y:-50%
  }

  .xxl\:hover\:-translate-y-3\/4:hover{
    --tw-translate-y:-75%
  }

  .xxl\:focus\:translate-x-0:focus{
    --tw-translate-x:0
  }

  .xxl\:focus\:translate-x-1:focus{
    --tw-translate-x:4px
  }

  .xxl\:focus\:translate-x-2:focus{
    --tw-translate-x:8px
  }

  .xxl\:focus\:translate-x-3:focus{
    --tw-translate-x:12px
  }

  .xxl\:focus\:translate-x-4:focus{
    --tw-translate-x:16px
  }

  .xxl\:focus\:translate-x-5:focus{
    --tw-translate-x:20px
  }

  .xxl\:focus\:translate-x-6:focus{
    --tw-translate-x:24px
  }

  .xxl\:focus\:translate-x-8:focus{
    --tw-translate-x:32px
  }

  .xxl\:focus\:translate-x-10:focus{
    --tw-translate-x:40px
  }

  .xxl\:focus\:translate-x-12:focus{
    --tw-translate-x:48px
  }

  .xxl\:focus\:translate-x-16:focus{
    --tw-translate-x:64px
  }

  .xxl\:focus\:translate-x-18:focus{
    --tw-translate-x:72px
  }

  .xxl\:focus\:translate-x-24:focus{
    --tw-translate-x:96px
  }

  .xxl\:focus\:translate-x-5dot5:focus{
    --tw-translate-x:22px
  }

  .xxl\:focus\:translate-x-full:focus{
    --tw-translate-x:100%
  }

  .xxl\:focus\:-translate-x-1:focus{
    --tw-translate-x:-4px
  }

  .xxl\:focus\:-translate-x-2:focus{
    --tw-translate-x:-8px
  }

  .xxl\:focus\:-translate-x-3:focus{
    --tw-translate-x:-12px
  }

  .xxl\:focus\:-translate-x-4:focus{
    --tw-translate-x:-16px
  }

  .xxl\:focus\:-translate-x-5:focus{
    --tw-translate-x:-20px
  }

  .xxl\:focus\:-translate-x-6:focus{
    --tw-translate-x:-24px
  }

  .xxl\:focus\:-translate-x-8:focus{
    --tw-translate-x:-32px
  }

  .xxl\:focus\:-translate-x-10:focus{
    --tw-translate-x:-40px
  }

  .xxl\:focus\:-translate-x-12:focus{
    --tw-translate-x:-48px
  }

  .xxl\:focus\:-translate-x-16:focus{
    --tw-translate-x:-64px
  }

  .xxl\:focus\:-translate-x-18:focus{
    --tw-translate-x:-72px
  }

  .xxl\:focus\:-translate-x-24:focus{
    --tw-translate-x:-96px
  }

  .xxl\:focus\:-translate-x-5dot5:focus{
    --tw-translate-x:-22px
  }

  .xxl\:focus\:-translate-x-full:focus{
    --tw-translate-x:-100%
  }

  .xxl\:focus\:translate-x-1\/2:focus{
    --tw-translate-x:50%
  }

  .xxl\:focus\:translate-x-1\/3:focus{
    --tw-translate-x:33.333333%
  }

  .xxl\:focus\:translate-x-2\/3:focus{
    --tw-translate-x:66.666667%
  }

  .xxl\:focus\:translate-x-1\/4:focus{
    --tw-translate-x:25%
  }

  .xxl\:focus\:translate-x-2\/4:focus{
    --tw-translate-x:50%
  }

  .xxl\:focus\:translate-x-3\/4:focus{
    --tw-translate-x:75%
  }

  .xxl\:focus\:-translate-x-1\/2:focus{
    --tw-translate-x:-50%
  }

  .xxl\:focus\:-translate-x-1\/3:focus{
    --tw-translate-x:-33.333333%
  }

  .xxl\:focus\:-translate-x-2\/3:focus{
    --tw-translate-x:-66.666667%
  }

  .xxl\:focus\:-translate-x-1\/4:focus{
    --tw-translate-x:-25%
  }

  .xxl\:focus\:-translate-x-2\/4:focus{
    --tw-translate-x:-50%
  }

  .xxl\:focus\:-translate-x-3\/4:focus{
    --tw-translate-x:-75%
  }

  .xxl\:focus\:translate-y-0:focus{
    --tw-translate-y:0
  }

  .xxl\:focus\:translate-y-1:focus{
    --tw-translate-y:4px
  }

  .xxl\:focus\:translate-y-2:focus{
    --tw-translate-y:8px
  }

  .xxl\:focus\:translate-y-3:focus{
    --tw-translate-y:12px
  }

  .xxl\:focus\:translate-y-4:focus{
    --tw-translate-y:16px
  }

  .xxl\:focus\:translate-y-5:focus{
    --tw-translate-y:20px
  }

  .xxl\:focus\:translate-y-6:focus{
    --tw-translate-y:24px
  }

  .xxl\:focus\:translate-y-8:focus{
    --tw-translate-y:32px
  }

  .xxl\:focus\:translate-y-10:focus{
    --tw-translate-y:40px
  }

  .xxl\:focus\:translate-y-12:focus{
    --tw-translate-y:48px
  }

  .xxl\:focus\:translate-y-16:focus{
    --tw-translate-y:64px
  }

  .xxl\:focus\:translate-y-18:focus{
    --tw-translate-y:72px
  }

  .xxl\:focus\:translate-y-24:focus{
    --tw-translate-y:96px
  }

  .xxl\:focus\:translate-y-5dot5:focus{
    --tw-translate-y:22px
  }

  .xxl\:focus\:translate-y-full:focus{
    --tw-translate-y:100%
  }

  .xxl\:focus\:-translate-y-1:focus{
    --tw-translate-y:-4px
  }

  .xxl\:focus\:-translate-y-2:focus{
    --tw-translate-y:-8px
  }

  .xxl\:focus\:-translate-y-3:focus{
    --tw-translate-y:-12px
  }

  .xxl\:focus\:-translate-y-4:focus{
    --tw-translate-y:-16px
  }

  .xxl\:focus\:-translate-y-5:focus{
    --tw-translate-y:-20px
  }

  .xxl\:focus\:-translate-y-6:focus{
    --tw-translate-y:-24px
  }

  .xxl\:focus\:-translate-y-8:focus{
    --tw-translate-y:-32px
  }

  .xxl\:focus\:-translate-y-10:focus{
    --tw-translate-y:-40px
  }

  .xxl\:focus\:-translate-y-12:focus{
    --tw-translate-y:-48px
  }

  .xxl\:focus\:-translate-y-16:focus{
    --tw-translate-y:-64px
  }

  .xxl\:focus\:-translate-y-18:focus{
    --tw-translate-y:-72px
  }

  .xxl\:focus\:-translate-y-24:focus{
    --tw-translate-y:-96px
  }

  .xxl\:focus\:-translate-y-5dot5:focus{
    --tw-translate-y:-22px
  }

  .xxl\:focus\:-translate-y-full:focus{
    --tw-translate-y:-100%
  }

  .xxl\:focus\:translate-y-1\/2:focus{
    --tw-translate-y:50%
  }

  .xxl\:focus\:translate-y-1\/3:focus{
    --tw-translate-y:33.333333%
  }

  .xxl\:focus\:translate-y-2\/3:focus{
    --tw-translate-y:66.666667%
  }

  .xxl\:focus\:translate-y-1\/4:focus{
    --tw-translate-y:25%
  }

  .xxl\:focus\:translate-y-2\/4:focus{
    --tw-translate-y:50%
  }

  .xxl\:focus\:translate-y-3\/4:focus{
    --tw-translate-y:75%
  }

  .xxl\:focus\:-translate-y-1\/2:focus{
    --tw-translate-y:-50%
  }

  .xxl\:focus\:-translate-y-1\/3:focus{
    --tw-translate-y:-33.333333%
  }

  .xxl\:focus\:-translate-y-2\/3:focus{
    --tw-translate-y:-66.666667%
  }

  .xxl\:focus\:-translate-y-1\/4:focus{
    --tw-translate-y:-25%
  }

  .xxl\:focus\:-translate-y-2\/4:focus{
    --tw-translate-y:-50%
  }

  .xxl\:focus\:-translate-y-3\/4:focus{
    --tw-translate-y:-75%
  }

  .xxl\:rotate-0{
    --tw-rotate:0deg
  }

  .xxl\:rotate-1{
    --tw-rotate:1deg
  }

  .xxl\:rotate-2{
    --tw-rotate:2deg
  }

  .xxl\:rotate-3{
    --tw-rotate:3deg
  }

  .xxl\:rotate-6{
    --tw-rotate:6deg
  }

  .xxl\:rotate-12{
    --tw-rotate:12deg
  }

  .xxl\:rotate-45{
    --tw-rotate:45deg
  }

  .xxl\:rotate-90{
    --tw-rotate:90deg
  }

  .xxl\:rotate-180{
    --tw-rotate:180deg
  }

  .xxl\:-rotate-180{
    --tw-rotate:-180deg
  }

  .xxl\:-rotate-90{
    --tw-rotate:-90deg
  }

  .xxl\:-rotate-45{
    --tw-rotate:-45deg
  }

  .xxl\:-rotate-12{
    --tw-rotate:-12deg
  }

  .xxl\:-rotate-6{
    --tw-rotate:-6deg
  }

  .xxl\:-rotate-3{
    --tw-rotate:-3deg
  }

  .xxl\:-rotate-2{
    --tw-rotate:-2deg
  }

  .xxl\:-rotate-1{
    --tw-rotate:-1deg
  }

  .xxl\:hover\:rotate-0:hover{
    --tw-rotate:0deg
  }

  .xxl\:hover\:rotate-1:hover{
    --tw-rotate:1deg
  }

  .xxl\:hover\:rotate-2:hover{
    --tw-rotate:2deg
  }

  .xxl\:hover\:rotate-3:hover{
    --tw-rotate:3deg
  }

  .xxl\:hover\:rotate-6:hover{
    --tw-rotate:6deg
  }

  .xxl\:hover\:rotate-12:hover{
    --tw-rotate:12deg
  }

  .xxl\:hover\:rotate-45:hover{
    --tw-rotate:45deg
  }

  .xxl\:hover\:rotate-90:hover{
    --tw-rotate:90deg
  }

  .xxl\:hover\:rotate-180:hover{
    --tw-rotate:180deg
  }

  .xxl\:hover\:-rotate-180:hover{
    --tw-rotate:-180deg
  }

  .xxl\:hover\:-rotate-90:hover{
    --tw-rotate:-90deg
  }

  .xxl\:hover\:-rotate-45:hover{
    --tw-rotate:-45deg
  }

  .xxl\:hover\:-rotate-12:hover{
    --tw-rotate:-12deg
  }

  .xxl\:hover\:-rotate-6:hover{
    --tw-rotate:-6deg
  }

  .xxl\:hover\:-rotate-3:hover{
    --tw-rotate:-3deg
  }

  .xxl\:hover\:-rotate-2:hover{
    --tw-rotate:-2deg
  }

  .xxl\:hover\:-rotate-1:hover{
    --tw-rotate:-1deg
  }

  .xxl\:focus\:rotate-0:focus{
    --tw-rotate:0deg
  }

  .xxl\:focus\:rotate-1:focus{
    --tw-rotate:1deg
  }

  .xxl\:focus\:rotate-2:focus{
    --tw-rotate:2deg
  }

  .xxl\:focus\:rotate-3:focus{
    --tw-rotate:3deg
  }

  .xxl\:focus\:rotate-6:focus{
    --tw-rotate:6deg
  }

  .xxl\:focus\:rotate-12:focus{
    --tw-rotate:12deg
  }

  .xxl\:focus\:rotate-45:focus{
    --tw-rotate:45deg
  }

  .xxl\:focus\:rotate-90:focus{
    --tw-rotate:90deg
  }

  .xxl\:focus\:rotate-180:focus{
    --tw-rotate:180deg
  }

  .xxl\:focus\:-rotate-180:focus{
    --tw-rotate:-180deg
  }

  .xxl\:focus\:-rotate-90:focus{
    --tw-rotate:-90deg
  }

  .xxl\:focus\:-rotate-45:focus{
    --tw-rotate:-45deg
  }

  .xxl\:focus\:-rotate-12:focus{
    --tw-rotate:-12deg
  }

  .xxl\:focus\:-rotate-6:focus{
    --tw-rotate:-6deg
  }

  .xxl\:focus\:-rotate-3:focus{
    --tw-rotate:-3deg
  }

  .xxl\:focus\:-rotate-2:focus{
    --tw-rotate:-2deg
  }

  .xxl\:focus\:-rotate-1:focus{
    --tw-rotate:-1deg
  }

  .xxl\:animate-none{
    animation:none
  }

  .xxl\:animate-spin{
    animation:spin 1s linear infinite
  }

  .xxl\:animate-ping{
    animation:ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .xxl\:animate-pulse{
    animation:pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .xxl\:animate-bounce{
    animation:bounce 1s infinite
  }

  .xxl\:animate-spin-delay{
    animation:spin-delay 2.4s ease infinite
  }

  .xxl\:cursor-auto{
    cursor:auto
  }

  .xxl\:cursor-default{
    cursor:default
  }

  .xxl\:cursor-pointer{
    cursor:pointer
  }

  .xxl\:cursor-wait{
    cursor:wait
  }

  .xxl\:cursor-text{
    cursor:text
  }

  .xxl\:cursor-move{
    cursor:move
  }

  .xxl\:cursor-help{
    cursor:help
  }

  .xxl\:cursor-not-allowed{
    cursor:not-allowed
  }

  .xxl\:select-none{
    -webkit-user-select:none;
            user-select:none
  }

  .xxl\:select-text{
    -webkit-user-select:text;
            user-select:text
  }

  .xxl\:select-all{
    -webkit-user-select:all;
            user-select:all
  }

  .xxl\:select-auto{
    -webkit-user-select:auto;
            user-select:auto
  }

  .xxl\:auto-cols-auto{
    grid-auto-columns:auto
  }

  .xxl\:auto-cols-min{
    grid-auto-columns:min-content
  }

  .xxl\:auto-cols-max{
    grid-auto-columns:max-content
  }

  .xxl\:auto-cols-fr{
    grid-auto-columns:minmax(0, 1fr)
  }

  .xxl\:grid-flow-row{
    grid-auto-flow:row
  }

  .xxl\:grid-flow-col{
    grid-auto-flow:column
  }

  .xxl\:grid-flow-row-dense{
    grid-auto-flow:row dense
  }

  .xxl\:grid-flow-col-dense{
    grid-auto-flow:column dense
  }

  .xxl\:auto-rows-auto{
    grid-auto-rows:auto
  }

  .xxl\:auto-rows-min{
    grid-auto-rows:min-content
  }

  .xxl\:auto-rows-max{
    grid-auto-rows:max-content
  }

  .xxl\:auto-rows-fr{
    grid-auto-rows:minmax(0, 1fr)
  }

  .xxl\:grid-cols-1{
    grid-template-columns:repeat(1, minmax(0, 1fr))
  }

  .xxl\:grid-cols-2{
    grid-template-columns:repeat(2, minmax(0, 1fr))
  }

  .xxl\:grid-cols-3{
    grid-template-columns:repeat(3, minmax(0, 1fr))
  }

  .xxl\:grid-cols-4{
    grid-template-columns:repeat(4, minmax(0, 1fr))
  }

  .xxl\:grid-cols-5{
    grid-template-columns:repeat(5, minmax(0, 1fr))
  }

  .xxl\:grid-cols-6{
    grid-template-columns:repeat(6, minmax(0, 1fr))
  }

  .xxl\:grid-cols-7{
    grid-template-columns:repeat(7, minmax(0, 1fr))
  }

  .xxl\:grid-cols-8{
    grid-template-columns:repeat(8, minmax(0, 1fr))
  }

  .xxl\:grid-cols-9{
    grid-template-columns:repeat(9, minmax(0, 1fr))
  }

  .xxl\:grid-cols-10{
    grid-template-columns:repeat(10, minmax(0, 1fr))
  }

  .xxl\:grid-cols-11{
    grid-template-columns:repeat(11, minmax(0, 1fr))
  }

  .xxl\:grid-cols-12{
    grid-template-columns:repeat(12, minmax(0, 1fr))
  }

  .xxl\:grid-cols-none{
    grid-template-columns:none
  }

  .xxl\:grid-rows-1{
    grid-template-rows:repeat(1, minmax(0, 1fr))
  }

  .xxl\:grid-rows-2{
    grid-template-rows:repeat(2, minmax(0, 1fr))
  }

  .xxl\:grid-rows-3{
    grid-template-rows:repeat(3, minmax(0, 1fr))
  }

  .xxl\:grid-rows-4{
    grid-template-rows:repeat(4, minmax(0, 1fr))
  }

  .xxl\:grid-rows-5{
    grid-template-rows:repeat(5, minmax(0, 1fr))
  }

  .xxl\:grid-rows-6{
    grid-template-rows:repeat(6, minmax(0, 1fr))
  }

  .xxl\:grid-rows-none{
    grid-template-rows:none
  }

  .xxl\:flex-row{
    flex-direction:row
  }

  .xxl\:flex-row-reverse{
    flex-direction:row-reverse
  }

  .xxl\:flex-col{
    flex-direction:column
  }

  .xxl\:flex-col-reverse{
    flex-direction:column-reverse
  }

  .xxl\:flex-wrap{
    flex-wrap:wrap
  }

  .xxl\:flex-wrap-reverse{
    flex-wrap:wrap-reverse
  }

  .xxl\:flex-nowrap{
    flex-wrap:nowrap
  }

  .xxl\:place-content-center{
    place-content:center
  }

  .xxl\:place-content-start{
    place-content:start
  }

  .xxl\:place-content-end{
    place-content:end
  }

  .xxl\:place-content-between{
    place-content:space-between
  }

  .xxl\:place-content-around{
    place-content:space-around
  }

  .xxl\:place-content-evenly{
    place-content:space-evenly
  }

  .xxl\:place-content-stretch{
    place-content:stretch
  }

  .xxl\:place-items-start{
    place-items:start
  }

  .xxl\:place-items-end{
    place-items:end
  }

  .xxl\:place-items-center{
    place-items:center
  }

  .xxl\:place-items-stretch{
    place-items:stretch
  }

  .xxl\:content-center{
    align-content:center
  }

  .xxl\:content-start{
    align-content:flex-start
  }

  .xxl\:content-end{
    align-content:flex-end
  }

  .xxl\:content-between{
    align-content:space-between
  }

  .xxl\:content-around{
    align-content:space-around
  }

  .xxl\:content-evenly{
    align-content:space-evenly
  }

  .xxl\:items-start{
    align-items:flex-start
  }

  .xxl\:items-end{
    align-items:flex-end
  }

  .xxl\:items-center{
    align-items:center
  }

  .xxl\:items-baseline{
    align-items:baseline
  }

  .xxl\:items-stretch{
    align-items:stretch
  }

  .xxl\:justify-start{
    justify-content:flex-start
  }

  .xxl\:justify-end{
    justify-content:flex-end
  }

  .xxl\:justify-center{
    justify-content:center
  }

  .xxl\:justify-between{
    justify-content:space-between
  }

  .xxl\:justify-around{
    justify-content:space-around
  }

  .xxl\:justify-evenly{
    justify-content:space-evenly
  }

  .xxl\:justify-items-start{
    justify-items:start
  }

  .xxl\:justify-items-end{
    justify-items:end
  }

  .xxl\:justify-items-center{
    justify-items:center
  }

  .xxl\:justify-items-stretch{
    justify-items:stretch
  }

  .xxl\:space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(0px * var(--tw-space-x-reverse));
    margin-left:calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(4px * var(--tw-space-x-reverse));
    margin-left:calc(4px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(8px * var(--tw-space-x-reverse));
    margin-left:calc(8px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(12px * var(--tw-space-x-reverse));
    margin-left:calc(12px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(16px * var(--tw-space-x-reverse));
    margin-left:calc(16px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(20px * var(--tw-space-x-reverse));
    margin-left:calc(20px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(24px * var(--tw-space-x-reverse));
    margin-left:calc(24px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(32px * var(--tw-space-x-reverse));
    margin-left:calc(32px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(40px * var(--tw-space-x-reverse));
    margin-left:calc(40px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(48px * var(--tw-space-x-reverse));
    margin-left:calc(48px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(64px * var(--tw-space-x-reverse));
    margin-left:calc(64px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(72px * var(--tw-space-x-reverse));
    margin-left:calc(72px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(96px * var(--tw-space-x-reverse));
    margin-left:calc(96px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(22px * var(--tw-space-x-reverse));
    margin-left:calc(22px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-x-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(100% * var(--tw-space-x-reverse));
    margin-left:calc(100% * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-4px * var(--tw-space-x-reverse));
    margin-left:calc(-4px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-8px * var(--tw-space-x-reverse));
    margin-left:calc(-8px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-12px * var(--tw-space-x-reverse));
    margin-left:calc(-12px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-16px * var(--tw-space-x-reverse));
    margin-left:calc(-16px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-20px * var(--tw-space-x-reverse));
    margin-left:calc(-20px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-24px * var(--tw-space-x-reverse));
    margin-left:calc(-24px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-32px * var(--tw-space-x-reverse));
    margin-left:calc(-32px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-40px * var(--tw-space-x-reverse));
    margin-left:calc(-40px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-48px * var(--tw-space-x-reverse));
    margin-left:calc(-48px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-64px * var(--tw-space-x-reverse));
    margin-left:calc(-64px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-72px * var(--tw-space-x-reverse));
    margin-left:calc(-72px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-96px * var(--tw-space-x-reverse));
    margin-left:calc(-96px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-22px * var(--tw-space-x-reverse));
    margin-left:calc(-22px * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:-space-x-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(-100% * var(--tw-space-x-reverse));
    margin-left:calc(-100% * calc(1 - var(--tw-space-x-reverse)))
  }

  .xxl\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(4px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(4px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(8px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(12px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(16px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(20px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(24px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(32px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(40px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(48px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(48px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(64px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(64px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(72px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(72px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(96px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(96px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(22px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(22px * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(100% * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(100% * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-4px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-4px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-8px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-12px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-16px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-20px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-24px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-32px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-40px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-48px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-48px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-64px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-64px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-18 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-72px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-72px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-96px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-96px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-5dot5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-22px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-22px * var(--tw-space-y-reverse))
  }

  .xxl\:-space-y-full > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(-100% * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(-100% * var(--tw-space-y-reverse))
  }

  .xxl\:space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:1
  }

  .xxl\:space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:1
  }

  .xxl\:divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style:solid
  }

  .xxl\:divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style:dashed
  }

  .xxl\:divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style:dotted
  }

  .xxl\:divide-double > :not([hidden]) ~ :not([hidden]){
    border-style:double
  }

  .xxl\:divide-none > :not([hidden]) ~ :not([hidden]){
    border-style:none
  }

  .xxl\:place-self-auto{
    place-self:auto
  }

  .xxl\:place-self-start{
    place-self:start
  }

  .xxl\:place-self-end{
    place-self:end
  }

  .xxl\:place-self-center{
    place-self:center
  }

  .xxl\:place-self-stretch{
    place-self:stretch
  }

  .xxl\:self-auto{
    align-self:auto
  }

  .xxl\:self-start{
    align-self:flex-start
  }

  .xxl\:self-end{
    align-self:flex-end
  }

  .xxl\:self-center{
    align-self:center
  }

  .xxl\:self-stretch{
    align-self:stretch
  }

  .xxl\:self-baseline{
    align-self:baseline
  }

  .xxl\:justify-self-auto{
    justify-self:auto
  }

  .xxl\:justify-self-start{
    justify-self:start
  }

  .xxl\:justify-self-end{
    justify-self:end
  }

  .xxl\:justify-self-center{
    justify-self:center
  }

  .xxl\:justify-self-stretch{
    justify-self:stretch
  }

  .xxl\:overflow-auto{
    overflow:auto
  }

  .xxl\:overflow-hidden{
    overflow:hidden
  }

  .xxl\:overflow-visible{
    overflow:visible
  }

  .xxl\:overflow-scroll{
    overflow:scroll
  }

  .xxl\:overflow-x-auto{
    overflow-x:auto
  }

  .xxl\:overflow-y-auto{
    overflow-y:auto
  }

  .xxl\:overflow-x-hidden{
    overflow-x:hidden
  }

  .xxl\:overflow-y-hidden{
    overflow-y:hidden
  }

  .xxl\:overflow-x-visible{
    overflow-x:visible
  }

  .xxl\:overflow-y-visible{
    overflow-y:visible
  }

  .xxl\:overflow-x-scroll{
    overflow-x:scroll
  }

  .xxl\:overflow-y-scroll{
    overflow-y:scroll
  }

  .xxl\:overscroll-auto{
    overscroll-behavior:auto
  }

  .xxl\:overscroll-contain{
    overscroll-behavior:contain
  }

  .xxl\:overscroll-none{
    overscroll-behavior:none
  }

  .xxl\:overscroll-y-auto{
    overscroll-behavior-y:auto
  }

  .xxl\:overscroll-y-contain{
    overscroll-behavior-y:contain
  }

  .xxl\:overscroll-y-none{
    overscroll-behavior-y:none
  }

  .xxl\:overscroll-x-auto{
    overscroll-behavior-x:auto
  }

  .xxl\:overscroll-x-contain{
    overscroll-behavior-x:contain
  }

  .xxl\:overscroll-x-none{
    overscroll-behavior-x:none
  }

  .xxl\:truncate{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
  }

  .xxl\:overflow-ellipsis{
    text-overflow:ellipsis
  }

  .xxl\:overflow-clip{
    text-overflow:clip
  }

  .xxl\:whitespace-normal{
    white-space:normal
  }

  .xxl\:whitespace-nowrap{
    white-space:nowrap
  }

  .xxl\:whitespace-pre{
    white-space:pre
  }

  .xxl\:whitespace-pre-line{
    white-space:pre-line
  }

  .xxl\:whitespace-pre-wrap{
    white-space:pre-wrap
  }

  .xxl\:break-normal{
    overflow-wrap:normal;
    word-break:normal
  }

  .xxl\:break-words{
    overflow-wrap:break-word
  }

  .xxl\:break-all{
    word-break:break-all
  }

  .xxl\:rounded-1{
    border-radius:4px
  }

  .xxl\:rounded-2{
    border-radius:8px
  }

  .xxl\:rounded-3{
    border-radius:12px
  }

  .xxl\:rounded-4{
    border-radius:16px
  }

  .xxl\:rounded-none{
    border-radius:0px
  }

  .xxl\:rounded-sm{
    border-radius:0.125rem
  }

  .xxl\:rounded{
    border-radius:0.25rem
  }

  .xxl\:rounded-md{
    border-radius:0.375rem
  }

  .xxl\:rounded-lg{
    border-radius:0.5rem
  }

  .xxl\:rounded-xl{
    border-radius:0.75rem
  }

  .xxl\:rounded-2xl{
    border-radius:1rem
  }

  .xxl\:rounded-3xl{
    border-radius:1.5rem
  }

  .xxl\:rounded-full{
    border-radius:9999px
  }

  .xxl\:rounded-default{
    border-radius:4px
  }

  .xxl\:rounded-t-1{
    border-top-left-radius:4px;
    border-top-right-radius:4px
  }

  .xxl\:rounded-t-2{
    border-top-left-radius:8px;
    border-top-right-radius:8px
  }

  .xxl\:rounded-t-3{
    border-top-left-radius:12px;
    border-top-right-radius:12px
  }

  .xxl\:rounded-t-4{
    border-top-left-radius:16px;
    border-top-right-radius:16px
  }

  .xxl\:rounded-t-none{
    border-top-left-radius:0px;
    border-top-right-radius:0px
  }

  .xxl\:rounded-t-sm{
    border-top-left-radius:0.125rem;
    border-top-right-radius:0.125rem
  }

  .xxl\:rounded-t{
    border-top-left-radius:0.25rem;
    border-top-right-radius:0.25rem
  }

  .xxl\:rounded-t-md{
    border-top-left-radius:0.375rem;
    border-top-right-radius:0.375rem
  }

  .xxl\:rounded-t-lg{
    border-top-left-radius:0.5rem;
    border-top-right-radius:0.5rem
  }

  .xxl\:rounded-t-xl{
    border-top-left-radius:0.75rem;
    border-top-right-radius:0.75rem
  }

  .xxl\:rounded-t-2xl{
    border-top-left-radius:1rem;
    border-top-right-radius:1rem
  }

  .xxl\:rounded-t-3xl{
    border-top-left-radius:1.5rem;
    border-top-right-radius:1.5rem
  }

  .xxl\:rounded-t-full{
    border-top-left-radius:9999px;
    border-top-right-radius:9999px
  }

  .xxl\:rounded-t-default{
    border-top-left-radius:4px;
    border-top-right-radius:4px
  }

  .xxl\:rounded-r-1{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
  }

  .xxl\:rounded-r-2{
    border-top-right-radius:8px;
    border-bottom-right-radius:8px
  }

  .xxl\:rounded-r-3{
    border-top-right-radius:12px;
    border-bottom-right-radius:12px
  }

  .xxl\:rounded-r-4{
    border-top-right-radius:16px;
    border-bottom-right-radius:16px
  }

  .xxl\:rounded-r-none{
    border-top-right-radius:0px;
    border-bottom-right-radius:0px
  }

  .xxl\:rounded-r-sm{
    border-top-right-radius:0.125rem;
    border-bottom-right-radius:0.125rem
  }

  .xxl\:rounded-r{
    border-top-right-radius:0.25rem;
    border-bottom-right-radius:0.25rem
  }

  .xxl\:rounded-r-md{
    border-top-right-radius:0.375rem;
    border-bottom-right-radius:0.375rem
  }

  .xxl\:rounded-r-lg{
    border-top-right-radius:0.5rem;
    border-bottom-right-radius:0.5rem
  }

  .xxl\:rounded-r-xl{
    border-top-right-radius:0.75rem;
    border-bottom-right-radius:0.75rem
  }

  .xxl\:rounded-r-2xl{
    border-top-right-radius:1rem;
    border-bottom-right-radius:1rem
  }

  .xxl\:rounded-r-3xl{
    border-top-right-radius:1.5rem;
    border-bottom-right-radius:1.5rem
  }

  .xxl\:rounded-r-full{
    border-top-right-radius:9999px;
    border-bottom-right-radius:9999px
  }

  .xxl\:rounded-r-default{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
  }

  .xxl\:rounded-b-1{
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px
  }

  .xxl\:rounded-b-2{
    border-bottom-right-radius:8px;
    border-bottom-left-radius:8px
  }

  .xxl\:rounded-b-3{
    border-bottom-right-radius:12px;
    border-bottom-left-radius:12px
  }

  .xxl\:rounded-b-4{
    border-bottom-right-radius:16px;
    border-bottom-left-radius:16px
  }

  .xxl\:rounded-b-none{
    border-bottom-right-radius:0px;
    border-bottom-left-radius:0px
  }

  .xxl\:rounded-b-sm{
    border-bottom-right-radius:0.125rem;
    border-bottom-left-radius:0.125rem
  }

  .xxl\:rounded-b{
    border-bottom-right-radius:0.25rem;
    border-bottom-left-radius:0.25rem
  }

  .xxl\:rounded-b-md{
    border-bottom-right-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }

  .xxl\:rounded-b-lg{
    border-bottom-right-radius:0.5rem;
    border-bottom-left-radius:0.5rem
  }

  .xxl\:rounded-b-xl{
    border-bottom-right-radius:0.75rem;
    border-bottom-left-radius:0.75rem
  }

  .xxl\:rounded-b-2xl{
    border-bottom-right-radius:1rem;
    border-bottom-left-radius:1rem
  }

  .xxl\:rounded-b-3xl{
    border-bottom-right-radius:1.5rem;
    border-bottom-left-radius:1.5rem
  }

  .xxl\:rounded-b-full{
    border-bottom-right-radius:9999px;
    border-bottom-left-radius:9999px
  }

  .xxl\:rounded-b-default{
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px
  }

  .xxl\:rounded-l-1{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
  }

  .xxl\:rounded-l-2{
    border-top-left-radius:8px;
    border-bottom-left-radius:8px
  }

  .xxl\:rounded-l-3{
    border-top-left-radius:12px;
    border-bottom-left-radius:12px
  }

  .xxl\:rounded-l-4{
    border-top-left-radius:16px;
    border-bottom-left-radius:16px
  }

  .xxl\:rounded-l-none{
    border-top-left-radius:0px;
    border-bottom-left-radius:0px
  }

  .xxl\:rounded-l-sm{
    border-top-left-radius:0.125rem;
    border-bottom-left-radius:0.125rem
  }

  .xxl\:rounded-l{
    border-top-left-radius:0.25rem;
    border-bottom-left-radius:0.25rem
  }

  .xxl\:rounded-l-md{
    border-top-left-radius:0.375rem;
    border-bottom-left-radius:0.375rem
  }

  .xxl\:rounded-l-lg{
    border-top-left-radius:0.5rem;
    border-bottom-left-radius:0.5rem
  }

  .xxl\:rounded-l-xl{
    border-top-left-radius:0.75rem;
    border-bottom-left-radius:0.75rem
  }

  .xxl\:rounded-l-2xl{
    border-top-left-radius:1rem;
    border-bottom-left-radius:1rem
  }

  .xxl\:rounded-l-3xl{
    border-top-left-radius:1.5rem;
    border-bottom-left-radius:1.5rem
  }

  .xxl\:rounded-l-full{
    border-top-left-radius:9999px;
    border-bottom-left-radius:9999px
  }

  .xxl\:rounded-l-default{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
  }

  .xxl\:rounded-tl-1{
    border-top-left-radius:4px
  }

  .xxl\:rounded-tl-2{
    border-top-left-radius:8px
  }

  .xxl\:rounded-tl-3{
    border-top-left-radius:12px
  }

  .xxl\:rounded-tl-4{
    border-top-left-radius:16px
  }

  .xxl\:rounded-tl-none{
    border-top-left-radius:0px
  }

  .xxl\:rounded-tl-sm{
    border-top-left-radius:0.125rem
  }

  .xxl\:rounded-tl{
    border-top-left-radius:0.25rem
  }

  .xxl\:rounded-tl-md{
    border-top-left-radius:0.375rem
  }

  .xxl\:rounded-tl-lg{
    border-top-left-radius:0.5rem
  }

  .xxl\:rounded-tl-xl{
    border-top-left-radius:0.75rem
  }

  .xxl\:rounded-tl-2xl{
    border-top-left-radius:1rem
  }

  .xxl\:rounded-tl-3xl{
    border-top-left-radius:1.5rem
  }

  .xxl\:rounded-tl-full{
    border-top-left-radius:9999px
  }

  .xxl\:rounded-tl-default{
    border-top-left-radius:4px
  }

  .xxl\:rounded-tr-1{
    border-top-right-radius:4px
  }

  .xxl\:rounded-tr-2{
    border-top-right-radius:8px
  }

  .xxl\:rounded-tr-3{
    border-top-right-radius:12px
  }

  .xxl\:rounded-tr-4{
    border-top-right-radius:16px
  }

  .xxl\:rounded-tr-none{
    border-top-right-radius:0px
  }

  .xxl\:rounded-tr-sm{
    border-top-right-radius:0.125rem
  }

  .xxl\:rounded-tr{
    border-top-right-radius:0.25rem
  }

  .xxl\:rounded-tr-md{
    border-top-right-radius:0.375rem
  }

  .xxl\:rounded-tr-lg{
    border-top-right-radius:0.5rem
  }

  .xxl\:rounded-tr-xl{
    border-top-right-radius:0.75rem
  }

  .xxl\:rounded-tr-2xl{
    border-top-right-radius:1rem
  }

  .xxl\:rounded-tr-3xl{
    border-top-right-radius:1.5rem
  }

  .xxl\:rounded-tr-full{
    border-top-right-radius:9999px
  }

  .xxl\:rounded-tr-default{
    border-top-right-radius:4px
  }

  .xxl\:rounded-br-1{
    border-bottom-right-radius:4px
  }

  .xxl\:rounded-br-2{
    border-bottom-right-radius:8px
  }

  .xxl\:rounded-br-3{
    border-bottom-right-radius:12px
  }

  .xxl\:rounded-br-4{
    border-bottom-right-radius:16px
  }

  .xxl\:rounded-br-none{
    border-bottom-right-radius:0px
  }

  .xxl\:rounded-br-sm{
    border-bottom-right-radius:0.125rem
  }

  .xxl\:rounded-br{
    border-bottom-right-radius:0.25rem
  }

  .xxl\:rounded-br-md{
    border-bottom-right-radius:0.375rem
  }

  .xxl\:rounded-br-lg{
    border-bottom-right-radius:0.5rem
  }

  .xxl\:rounded-br-xl{
    border-bottom-right-radius:0.75rem
  }

  .xxl\:rounded-br-2xl{
    border-bottom-right-radius:1rem
  }

  .xxl\:rounded-br-3xl{
    border-bottom-right-radius:1.5rem
  }

  .xxl\:rounded-br-full{
    border-bottom-right-radius:9999px
  }

  .xxl\:rounded-br-default{
    border-bottom-right-radius:4px
  }

  .xxl\:rounded-bl-1{
    border-bottom-left-radius:4px
  }

  .xxl\:rounded-bl-2{
    border-bottom-left-radius:8px
  }

  .xxl\:rounded-bl-3{
    border-bottom-left-radius:12px
  }

  .xxl\:rounded-bl-4{
    border-bottom-left-radius:16px
  }

  .xxl\:rounded-bl-none{
    border-bottom-left-radius:0px
  }

  .xxl\:rounded-bl-sm{
    border-bottom-left-radius:0.125rem
  }

  .xxl\:rounded-bl{
    border-bottom-left-radius:0.25rem
  }

  .xxl\:rounded-bl-md{
    border-bottom-left-radius:0.375rem
  }

  .xxl\:rounded-bl-lg{
    border-bottom-left-radius:0.5rem
  }

  .xxl\:rounded-bl-xl{
    border-bottom-left-radius:0.75rem
  }

  .xxl\:rounded-bl-2xl{
    border-bottom-left-radius:1rem
  }

  .xxl\:rounded-bl-3xl{
    border-bottom-left-radius:1.5rem
  }

  .xxl\:rounded-bl-full{
    border-bottom-left-radius:9999px
  }

  .xxl\:rounded-bl-default{
    border-bottom-left-radius:4px
  }

  .xxl\:border-0{
    border-width:0px
  }

  .xxl\:border-2{
    border-width:2px
  }

  .xxl\:border-4{
    border-width:4px
  }

  .xxl\:border-8{
    border-width:8px
  }

  .xxl\:border{
    border-width:1px
  }

  .xxl\:border-t-0{
    border-top-width:0px
  }

  .xxl\:border-t-2{
    border-top-width:2px
  }

  .xxl\:border-t-4{
    border-top-width:4px
  }

  .xxl\:border-t-8{
    border-top-width:8px
  }

  .xxl\:border-t{
    border-top-width:1px
  }

  .xxl\:border-r-0{
    border-right-width:0px
  }

  .xxl\:border-r-2{
    border-right-width:2px
  }

  .xxl\:border-r-4{
    border-right-width:4px
  }

  .xxl\:border-r-8{
    border-right-width:8px
  }

  .xxl\:border-r{
    border-right-width:1px
  }

  .xxl\:border-b-0{
    border-bottom-width:0px
  }

  .xxl\:border-b-2{
    border-bottom-width:2px
  }

  .xxl\:border-b-4{
    border-bottom-width:4px
  }

  .xxl\:border-b-8{
    border-bottom-width:8px
  }

  .xxl\:border-b{
    border-bottom-width:1px
  }

  .xxl\:border-l-0{
    border-left-width:0px
  }

  .xxl\:border-l-2{
    border-left-width:2px
  }

  .xxl\:border-l-4{
    border-left-width:4px
  }

  .xxl\:border-l-8{
    border-left-width:8px
  }

  .xxl\:border-l{
    border-left-width:1px
  }

  .xxl\:border-solid{
    border-style:solid
  }

  .xxl\:border-dashed{
    border-style:dashed
  }

  .xxl\:border-dotted{
    border-style:dotted
  }

  .xxl\:border-double{
    border-style:double
  }

  .xxl\:border-none{
    border-style:none
  }

  .xxl\:border-red1{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .xxl\:border-red2{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .xxl\:border-red3{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .xxl\:border-red4{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .xxl\:border-red5{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .xxl\:border-cyan1{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .xxl\:border-cyan2{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .xxl\:border-cyan3{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .xxl\:border-cyan4{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .xxl\:border-cyan5{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .xxl\:border-bg1{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .xxl\:border-bg2{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xxl\:border-bg3{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .xxl\:border-gray1{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .xxl\:border-gray2{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .xxl\:border-gray3{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .xxl\:border-gray4{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .xxl\:border-gray5{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .xxl\:border-gray6{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .xxl\:border-gray7{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xxl\:border-white{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xxl\:border-warning{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .xxl\:border-error{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .xxl\:border-info{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .xxl\:border-success{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .xxl\:border-special4{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .xxl\:border-dark{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .xxl\:border-transparent{
    border-color:transparent
  }

  .xxl\:border-secondary1{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .xxl\:border-secondary2{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xxl\:border-secondary3{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .xxl\:border-secondary4{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .xxl\:border-secondary5{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .xxl\:border-green1{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-red1:hover{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-red2:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-red3:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-red4:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-red5:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-cyan1:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-cyan2:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-cyan3:hover{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-cyan4:hover{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-cyan5:hover{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-bg1:hover{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-bg2:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-bg3:hover{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-gray1:hover{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-gray2:hover{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-gray3:hover{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-gray4:hover{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-gray5:hover{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-gray6:hover{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-gray7:hover{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-white:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-warning:hover{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-error:hover{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-info:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-success:hover{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-special4:hover{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-dark:hover{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-transparent:hover{
    border-color:transparent
  }

  .xxl\:hover\:border-secondary1:hover{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-secondary2:hover{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-secondary3:hover{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-secondary4:hover{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-secondary5:hover{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .xxl\:hover\:border-green1:hover{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-red1:focus{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-red2:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-red3:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-red4:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-red5:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-cyan1:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-cyan2:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-cyan3:focus{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-cyan4:focus{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-cyan5:focus{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-bg1:focus{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-bg2:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-bg3:focus{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-gray1:focus{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-gray2:focus{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-gray3:focus{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-gray4:focus{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-gray5:focus{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-gray6:focus{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-gray7:focus{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-white:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-warning:focus{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-error:focus{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-info:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-success:focus{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-special4:focus{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-dark:focus{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-transparent:focus{
    border-color:transparent
  }

  .xxl\:focus\:border-secondary1:focus{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-secondary2:focus{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-secondary3:focus{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-secondary4:focus{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-secondary5:focus{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .xxl\:focus\:border-green1:focus{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .xxl\:active\:border-red1:active{
    --tw-border-opacity:1;
    border-color:rgba(204, 53, 44, var(--tw-border-opacity))
  }

  .xxl\:active\:border-red2:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 85, 75, var(--tw-border-opacity))
  }

  .xxl\:active\:border-red3:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 123, 115, var(--tw-border-opacity))
  }

  .xxl\:active\:border-red4:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 241, 241, var(--tw-border-opacity))
  }

  .xxl\:active\:border-red5:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 246, 246, var(--tw-border-opacity))
  }

  .xxl\:active\:border-cyan1:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 152, 151, var(--tw-border-opacity))
  }

  .xxl\:active\:border-cyan2:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 191, 189, var(--tw-border-opacity))
  }

  .xxl\:active\:border-cyan3:active{
    --tw-border-opacity:1;
    border-color:rgba(76, 209, 208, var(--tw-border-opacity))
  }

  .xxl\:active\:border-cyan4:active{
    --tw-border-opacity:1;
    border-color:rgba(178, 236, 235, var(--tw-border-opacity))
  }

  .xxl\:active\:border-cyan5:active{
    --tw-border-opacity:1;
    border-color:rgba(237, 250, 250, var(--tw-border-opacity))
  }

  .xxl\:active\:border-bg1:active{
    --tw-border-opacity:1;
    border-color:rgba(250, 250, 252, var(--tw-border-opacity))
  }

  .xxl\:active\:border-bg2:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xxl\:active\:border-bg3:active{
    --tw-border-opacity:1;
    border-color:rgba(247, 247, 250, var(--tw-border-opacity))
  }

  .xxl\:active\:border-gray1:active{
    --tw-border-opacity:1;
    border-color:rgba(51, 51, 51, var(--tw-border-opacity))
  }

  .xxl\:active\:border-gray2:active{
    --tw-border-opacity:1;
    border-color:rgba(77, 77, 77, var(--tw-border-opacity))
  }

  .xxl\:active\:border-gray3:active{
    --tw-border-opacity:1;
    border-color:rgba(140, 140, 140, var(--tw-border-opacity))
  }

  .xxl\:active\:border-gray4:active{
    --tw-border-opacity:1;
    border-color:rgba(191, 191, 191, var(--tw-border-opacity))
  }

  .xxl\:active\:border-gray5:active{
    --tw-border-opacity:1;
    border-color:rgba(217, 217, 217, var(--tw-border-opacity))
  }

  .xxl\:active\:border-gray6:active{
    --tw-border-opacity:1;
    border-color:rgba(233, 233, 235, var(--tw-border-opacity))
  }

  .xxl\:active\:border-gray7:active{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xxl\:active\:border-white:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 255, 255, var(--tw-border-opacity))
  }

  .xxl\:active\:border-warning:active{
    --tw-border-opacity:1;
    border-color:rgba(255, 195, 25, var(--tw-border-opacity))
  }

  .xxl\:active\:border-error:active{
    --tw-border-opacity:1;
    border-color:rgba(234, 105, 111, var(--tw-border-opacity))
  }

  .xxl\:active\:border-info:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 113, 185, var(--tw-border-opacity))
  }

  .xxl\:active\:border-success:active{
    --tw-border-opacity:1;
    border-color:rgba(152, 212, 95, var(--tw-border-opacity))
  }

  .xxl\:active\:border-special4:active{
    --tw-border-opacity:1;
    border-color:rgba(89, 119, 154, var(--tw-border-opacity))
  }

  .xxl\:active\:border-dark:active{
    --tw-border-opacity:1;
    border-color:rgba(0, 0, 0, var(--tw-border-opacity))
  }

  .xxl\:active\:border-transparent:active{
    border-color:transparent
  }

  .xxl\:active\:border-secondary1:active{
    --tw-border-opacity:1;
    border-color:rgba(49, 49, 64, var(--tw-border-opacity))
  }

  .xxl\:active\:border-secondary2:active{
    --tw-border-opacity:1;
    border-color:rgba(81, 81, 100, var(--tw-border-opacity))
  }

  .xxl\:active\:border-secondary3:active{
    --tw-border-opacity:1;
    border-color:rgba(156, 156, 172, var(--tw-border-opacity))
  }

  .xxl\:active\:border-secondary4:active{
    --tw-border-opacity:1;
    border-color:rgba(229, 232, 237, var(--tw-border-opacity))
  }

  .xxl\:active\:border-secondary5:active{
    --tw-border-opacity:1;
    border-color:rgba(245, 246, 249, var(--tw-border-opacity))
  }

  .xxl\:active\:border-green1:active{
    --tw-border-opacity:1;
    border-color:rgba(13, 226, 152, var(--tw-border-opacity))
  }

  .xxl\:border-opacity-0{
    --tw-border-opacity:0
  }

  .xxl\:border-opacity-5{
    --tw-border-opacity:0.05
  }

  .xxl\:border-opacity-10{
    --tw-border-opacity:0.1
  }

  .xxl\:border-opacity-20{
    --tw-border-opacity:0.2
  }

  .xxl\:border-opacity-25{
    --tw-border-opacity:0.25
  }

  .xxl\:border-opacity-30{
    --tw-border-opacity:0.3
  }

  .xxl\:border-opacity-40{
    --tw-border-opacity:0.4
  }

  .xxl\:border-opacity-50{
    --tw-border-opacity:0.5
  }

  .xxl\:border-opacity-60{
    --tw-border-opacity:0.6
  }

  .xxl\:border-opacity-70{
    --tw-border-opacity:0.7
  }

  .xxl\:border-opacity-75{
    --tw-border-opacity:0.75
  }

  .xxl\:border-opacity-80{
    --tw-border-opacity:0.8
  }

  .xxl\:border-opacity-90{
    --tw-border-opacity:0.9
  }

  .xxl\:border-opacity-95{
    --tw-border-opacity:0.95
  }

  .xxl\:border-opacity-100{
    --tw-border-opacity:1
  }

  .group:hover .xxl\:group-hover\:border-opacity-0{
    --tw-border-opacity:0
  }

  .group:hover .xxl\:group-hover\:border-opacity-5{
    --tw-border-opacity:0.05
  }

  .group:hover .xxl\:group-hover\:border-opacity-10{
    --tw-border-opacity:0.1
  }

  .group:hover .xxl\:group-hover\:border-opacity-20{
    --tw-border-opacity:0.2
  }

  .group:hover .xxl\:group-hover\:border-opacity-25{
    --tw-border-opacity:0.25
  }

  .group:hover .xxl\:group-hover\:border-opacity-30{
    --tw-border-opacity:0.3
  }

  .group:hover .xxl\:group-hover\:border-opacity-40{
    --tw-border-opacity:0.4
  }

  .group:hover .xxl\:group-hover\:border-opacity-50{
    --tw-border-opacity:0.5
  }

  .group:hover .xxl\:group-hover\:border-opacity-60{
    --tw-border-opacity:0.6
  }

  .group:hover .xxl\:group-hover\:border-opacity-70{
    --tw-border-opacity:0.7
  }

  .group:hover .xxl\:group-hover\:border-opacity-75{
    --tw-border-opacity:0.75
  }

  .group:hover .xxl\:group-hover\:border-opacity-80{
    --tw-border-opacity:0.8
  }

  .group:hover .xxl\:group-hover\:border-opacity-90{
    --tw-border-opacity:0.9
  }

  .group:hover .xxl\:group-hover\:border-opacity-95{
    --tw-border-opacity:0.95
  }

  .group:hover .xxl\:group-hover\:border-opacity-100{
    --tw-border-opacity:1
  }

  .xxl\:focus-within\:border-opacity-0:focus-within{
    --tw-border-opacity:0
  }

  .xxl\:focus-within\:border-opacity-5:focus-within{
    --tw-border-opacity:0.05
  }

  .xxl\:focus-within\:border-opacity-10:focus-within{
    --tw-border-opacity:0.1
  }

  .xxl\:focus-within\:border-opacity-20:focus-within{
    --tw-border-opacity:0.2
  }

  .xxl\:focus-within\:border-opacity-25:focus-within{
    --tw-border-opacity:0.25
  }

  .xxl\:focus-within\:border-opacity-30:focus-within{
    --tw-border-opacity:0.3
  }

  .xxl\:focus-within\:border-opacity-40:focus-within{
    --tw-border-opacity:0.4
  }

  .xxl\:focus-within\:border-opacity-50:focus-within{
    --tw-border-opacity:0.5
  }

  .xxl\:focus-within\:border-opacity-60:focus-within{
    --tw-border-opacity:0.6
  }

  .xxl\:focus-within\:border-opacity-70:focus-within{
    --tw-border-opacity:0.7
  }

  .xxl\:focus-within\:border-opacity-75:focus-within{
    --tw-border-opacity:0.75
  }

  .xxl\:focus-within\:border-opacity-80:focus-within{
    --tw-border-opacity:0.8
  }

  .xxl\:focus-within\:border-opacity-90:focus-within{
    --tw-border-opacity:0.9
  }

  .xxl\:focus-within\:border-opacity-95:focus-within{
    --tw-border-opacity:0.95
  }

  .xxl\:focus-within\:border-opacity-100:focus-within{
    --tw-border-opacity:1
  }

  .xxl\:hover\:border-opacity-0:hover{
    --tw-border-opacity:0
  }

  .xxl\:hover\:border-opacity-5:hover{
    --tw-border-opacity:0.05
  }

  .xxl\:hover\:border-opacity-10:hover{
    --tw-border-opacity:0.1
  }

  .xxl\:hover\:border-opacity-20:hover{
    --tw-border-opacity:0.2
  }

  .xxl\:hover\:border-opacity-25:hover{
    --tw-border-opacity:0.25
  }

  .xxl\:hover\:border-opacity-30:hover{
    --tw-border-opacity:0.3
  }

  .xxl\:hover\:border-opacity-40:hover{
    --tw-border-opacity:0.4
  }

  .xxl\:hover\:border-opacity-50:hover{
    --tw-border-opacity:0.5
  }

  .xxl\:hover\:border-opacity-60:hover{
    --tw-border-opacity:0.6
  }

  .xxl\:hover\:border-opacity-70:hover{
    --tw-border-opacity:0.7
  }

  .xxl\:hover\:border-opacity-75:hover{
    --tw-border-opacity:0.75
  }

  .xxl\:hover\:border-opacity-80:hover{
    --tw-border-opacity:0.8
  }

  .xxl\:hover\:border-opacity-90:hover{
    --tw-border-opacity:0.9
  }

  .xxl\:hover\:border-opacity-95:hover{
    --tw-border-opacity:0.95
  }

  .xxl\:hover\:border-opacity-100:hover{
    --tw-border-opacity:1
  }

  .xxl\:focus\:border-opacity-0:focus{
    --tw-border-opacity:0
  }

  .xxl\:focus\:border-opacity-5:focus{
    --tw-border-opacity:0.05
  }

  .xxl\:focus\:border-opacity-10:focus{
    --tw-border-opacity:0.1
  }

  .xxl\:focus\:border-opacity-20:focus{
    --tw-border-opacity:0.2
  }

  .xxl\:focus\:border-opacity-25:focus{
    --tw-border-opacity:0.25
  }

  .xxl\:focus\:border-opacity-30:focus{
    --tw-border-opacity:0.3
  }

  .xxl\:focus\:border-opacity-40:focus{
    --tw-border-opacity:0.4
  }

  .xxl\:focus\:border-opacity-50:focus{
    --tw-border-opacity:0.5
  }

  .xxl\:focus\:border-opacity-60:focus{
    --tw-border-opacity:0.6
  }

  .xxl\:focus\:border-opacity-70:focus{
    --tw-border-opacity:0.7
  }

  .xxl\:focus\:border-opacity-75:focus{
    --tw-border-opacity:0.75
  }

  .xxl\:focus\:border-opacity-80:focus{
    --tw-border-opacity:0.8
  }

  .xxl\:focus\:border-opacity-90:focus{
    --tw-border-opacity:0.9
  }

  .xxl\:focus\:border-opacity-95:focus{
    --tw-border-opacity:0.95
  }

  .xxl\:focus\:border-opacity-100:focus{
    --tw-border-opacity:1
  }

  .xxl\:bg-red1{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .xxl\:bg-red2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .xxl\:bg-red3{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .xxl\:bg-red4{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .xxl\:bg-red5{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .xxl\:bg-cyan1{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .xxl\:bg-cyan2{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .xxl\:bg-cyan3{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .xxl\:bg-cyan4{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .xxl\:bg-cyan5{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .xxl\:bg-bg1{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .xxl\:bg-bg2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xxl\:bg-bg3{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .xxl\:bg-gray1{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .xxl\:bg-gray2{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .xxl\:bg-gray3{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .xxl\:bg-gray4{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .xxl\:bg-gray5{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .xxl\:bg-gray6{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .xxl\:bg-gray7{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xxl\:bg-white{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xxl\:bg-warning{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .xxl\:bg-error{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .xxl\:bg-info{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .xxl\:bg-success{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .xxl\:bg-special4{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .xxl\:bg-dark{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .xxl\:bg-transparent{
    background-color:transparent
  }

  .xxl\:bg-secondary1{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .xxl\:bg-secondary2{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xxl\:bg-secondary3{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .xxl\:bg-secondary4{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .xxl\:bg-secondary5{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .xxl\:bg-green1{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-red1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-red2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-red3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-red4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-red5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-cyan1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-cyan2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-cyan3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-cyan4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-cyan5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-bg1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-bg2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-bg3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-gray1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-gray2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-gray3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-gray4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-gray5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-gray6:hover{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-gray7:hover{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-white:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-warning:hover{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-error:hover{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-info:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-success:hover{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-special4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-dark:hover{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-transparent:hover{
    background-color:transparent
  }

  .xxl\:hover\:bg-secondary1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-secondary2:hover{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-secondary3:hover{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-secondary4:hover{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-secondary5:hover{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .xxl\:hover\:bg-green1:hover{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-red1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-red2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-red3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-red4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-red5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-cyan1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-cyan2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-cyan3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-cyan4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-cyan5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-bg1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-bg2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-bg3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-gray1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-gray2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-gray3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-gray4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-gray5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-gray6:focus{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-gray7:focus{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-white:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-warning:focus{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-error:focus{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-info:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-success:focus{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-special4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-dark:focus{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-transparent:focus{
    background-color:transparent
  }

  .xxl\:focus\:bg-secondary1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-secondary2:focus{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-secondary3:focus{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-secondary4:focus{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-secondary5:focus{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .xxl\:focus\:bg-green1:focus{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-red1:active{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-red2:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-red3:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-red4:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-red5:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-cyan1:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-cyan2:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-cyan3:active{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-cyan4:active{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-cyan5:active{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-bg1:active{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-bg2:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-bg3:active{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-gray1:active{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-gray2:active{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-gray3:active{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-gray4:active{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-gray5:active{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-gray6:active{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-gray7:active{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-white:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-warning:active{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-error:active{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-info:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-success:active{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-special4:active{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-dark:active{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-transparent:active{
    background-color:transparent
  }

  .xxl\:active\:bg-secondary1:active{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-secondary2:active{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-secondary3:active{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-secondary4:active{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-secondary5:active{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .xxl\:active\:bg-green1:active{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-red1{
    --tw-bg-opacity:1;
    background-color:rgba(204, 53, 44, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-red2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 85, 75, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-red3{
    --tw-bg-opacity:1;
    background-color:rgba(255, 123, 115, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-red4{
    --tw-bg-opacity:1;
    background-color:rgba(255, 241, 241, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-red5{
    --tw-bg-opacity:1;
    background-color:rgba(255, 246, 246, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-cyan1{
    --tw-bg-opacity:1;
    background-color:rgba(0, 152, 151, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-cyan2{
    --tw-bg-opacity:1;
    background-color:rgba(0, 191, 189, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-cyan3{
    --tw-bg-opacity:1;
    background-color:rgba(76, 209, 208, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-cyan4{
    --tw-bg-opacity:1;
    background-color:rgba(178, 236, 235, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-cyan5{
    --tw-bg-opacity:1;
    background-color:rgba(237, 250, 250, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-bg1{
    --tw-bg-opacity:1;
    background-color:rgba(250, 250, 252, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-bg2{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-bg3{
    --tw-bg-opacity:1;
    background-color:rgba(247, 247, 250, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-gray1{
    --tw-bg-opacity:1;
    background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-gray2{
    --tw-bg-opacity:1;
    background-color:rgba(77, 77, 77, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-gray3{
    --tw-bg-opacity:1;
    background-color:rgba(140, 140, 140, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-gray4{
    --tw-bg-opacity:1;
    background-color:rgba(191, 191, 191, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-gray5{
    --tw-bg-opacity:1;
    background-color:rgba(217, 217, 217, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-gray6{
    --tw-bg-opacity:1;
    background-color:rgba(233, 233, 235, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-gray7{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-white{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-warning{
    --tw-bg-opacity:1;
    background-color:rgba(255, 195, 25, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-error{
    --tw-bg-opacity:1;
    background-color:rgba(234, 105, 111, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-info{
    --tw-bg-opacity:1;
    background-color:rgba(0, 113, 185, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-success{
    --tw-bg-opacity:1;
    background-color:rgba(152, 212, 95, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-special4{
    --tw-bg-opacity:1;
    background-color:rgba(89, 119, 154, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-dark{
    --tw-bg-opacity:1;
    background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-transparent{
    background-color:transparent
  }

  .group:hover .xxl\:group-hover\:bg-secondary1{
    --tw-bg-opacity:1;
    background-color:rgba(49, 49, 64, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-secondary2{
    --tw-bg-opacity:1;
    background-color:rgba(81, 81, 100, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-secondary3{
    --tw-bg-opacity:1;
    background-color:rgba(156, 156, 172, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-secondary4{
    --tw-bg-opacity:1;
    background-color:rgba(229, 232, 237, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-secondary5{
    --tw-bg-opacity:1;
    background-color:rgba(245, 246, 249, var(--tw-bg-opacity))
  }

  .group:hover .xxl\:group-hover\:bg-green1{
    --tw-bg-opacity:1;
    background-color:rgba(13, 226, 152, var(--tw-bg-opacity))
  }

  .xxl\:bg-opacity-0{
    --tw-bg-opacity:0
  }

  .xxl\:bg-opacity-5{
    --tw-bg-opacity:0.05
  }

  .xxl\:bg-opacity-10{
    --tw-bg-opacity:0.1
  }

  .xxl\:bg-opacity-20{
    --tw-bg-opacity:0.2
  }

  .xxl\:bg-opacity-25{
    --tw-bg-opacity:0.25
  }

  .xxl\:bg-opacity-30{
    --tw-bg-opacity:0.3
  }

  .xxl\:bg-opacity-40{
    --tw-bg-opacity:0.4
  }

  .xxl\:bg-opacity-50{
    --tw-bg-opacity:0.5
  }

  .xxl\:bg-opacity-60{
    --tw-bg-opacity:0.6
  }

  .xxl\:bg-opacity-70{
    --tw-bg-opacity:0.7
  }

  .xxl\:bg-opacity-75{
    --tw-bg-opacity:0.75
  }

  .xxl\:bg-opacity-80{
    --tw-bg-opacity:0.8
  }

  .xxl\:bg-opacity-90{
    --tw-bg-opacity:0.9
  }

  .xxl\:bg-opacity-95{
    --tw-bg-opacity:0.95
  }

  .xxl\:bg-opacity-100{
    --tw-bg-opacity:1
  }

  .group:hover .xxl\:group-hover\:bg-opacity-0{
    --tw-bg-opacity:0
  }

  .group:hover .xxl\:group-hover\:bg-opacity-5{
    --tw-bg-opacity:0.05
  }

  .group:hover .xxl\:group-hover\:bg-opacity-10{
    --tw-bg-opacity:0.1
  }

  .group:hover .xxl\:group-hover\:bg-opacity-20{
    --tw-bg-opacity:0.2
  }

  .group:hover .xxl\:group-hover\:bg-opacity-25{
    --tw-bg-opacity:0.25
  }

  .group:hover .xxl\:group-hover\:bg-opacity-30{
    --tw-bg-opacity:0.3
  }

  .group:hover .xxl\:group-hover\:bg-opacity-40{
    --tw-bg-opacity:0.4
  }

  .group:hover .xxl\:group-hover\:bg-opacity-50{
    --tw-bg-opacity:0.5
  }

  .group:hover .xxl\:group-hover\:bg-opacity-60{
    --tw-bg-opacity:0.6
  }

  .group:hover .xxl\:group-hover\:bg-opacity-70{
    --tw-bg-opacity:0.7
  }

  .group:hover .xxl\:group-hover\:bg-opacity-75{
    --tw-bg-opacity:0.75
  }

  .group:hover .xxl\:group-hover\:bg-opacity-80{
    --tw-bg-opacity:0.8
  }

  .group:hover .xxl\:group-hover\:bg-opacity-90{
    --tw-bg-opacity:0.9
  }

  .group:hover .xxl\:group-hover\:bg-opacity-95{
    --tw-bg-opacity:0.95
  }

  .group:hover .xxl\:group-hover\:bg-opacity-100{
    --tw-bg-opacity:1
  }

  .xxl\:focus-within\:bg-opacity-0:focus-within{
    --tw-bg-opacity:0
  }

  .xxl\:focus-within\:bg-opacity-5:focus-within{
    --tw-bg-opacity:0.05
  }

  .xxl\:focus-within\:bg-opacity-10:focus-within{
    --tw-bg-opacity:0.1
  }

  .xxl\:focus-within\:bg-opacity-20:focus-within{
    --tw-bg-opacity:0.2
  }

  .xxl\:focus-within\:bg-opacity-25:focus-within{
    --tw-bg-opacity:0.25
  }

  .xxl\:focus-within\:bg-opacity-30:focus-within{
    --tw-bg-opacity:0.3
  }

  .xxl\:focus-within\:bg-opacity-40:focus-within{
    --tw-bg-opacity:0.4
  }

  .xxl\:focus-within\:bg-opacity-50:focus-within{
    --tw-bg-opacity:0.5
  }

  .xxl\:focus-within\:bg-opacity-60:focus-within{
    --tw-bg-opacity:0.6
  }

  .xxl\:focus-within\:bg-opacity-70:focus-within{
    --tw-bg-opacity:0.7
  }

  .xxl\:focus-within\:bg-opacity-75:focus-within{
    --tw-bg-opacity:0.75
  }

  .xxl\:focus-within\:bg-opacity-80:focus-within{
    --tw-bg-opacity:0.8
  }

  .xxl\:focus-within\:bg-opacity-90:focus-within{
    --tw-bg-opacity:0.9
  }

  .xxl\:focus-within\:bg-opacity-95:focus-within{
    --tw-bg-opacity:0.95
  }

  .xxl\:focus-within\:bg-opacity-100:focus-within{
    --tw-bg-opacity:1
  }

  .xxl\:hover\:bg-opacity-0:hover{
    --tw-bg-opacity:0
  }

  .xxl\:hover\:bg-opacity-5:hover{
    --tw-bg-opacity:0.05
  }

  .xxl\:hover\:bg-opacity-10:hover{
    --tw-bg-opacity:0.1
  }

  .xxl\:hover\:bg-opacity-20:hover{
    --tw-bg-opacity:0.2
  }

  .xxl\:hover\:bg-opacity-25:hover{
    --tw-bg-opacity:0.25
  }

  .xxl\:hover\:bg-opacity-30:hover{
    --tw-bg-opacity:0.3
  }

  .xxl\:hover\:bg-opacity-40:hover{
    --tw-bg-opacity:0.4
  }

  .xxl\:hover\:bg-opacity-50:hover{
    --tw-bg-opacity:0.5
  }

  .xxl\:hover\:bg-opacity-60:hover{
    --tw-bg-opacity:0.6
  }

  .xxl\:hover\:bg-opacity-70:hover{
    --tw-bg-opacity:0.7
  }

  .xxl\:hover\:bg-opacity-75:hover{
    --tw-bg-opacity:0.75
  }

  .xxl\:hover\:bg-opacity-80:hover{
    --tw-bg-opacity:0.8
  }

  .xxl\:hover\:bg-opacity-90:hover{
    --tw-bg-opacity:0.9
  }

  .xxl\:hover\:bg-opacity-95:hover{
    --tw-bg-opacity:0.95
  }

  .xxl\:hover\:bg-opacity-100:hover{
    --tw-bg-opacity:1
  }

  .xxl\:focus\:bg-opacity-0:focus{
    --tw-bg-opacity:0
  }

  .xxl\:focus\:bg-opacity-5:focus{
    --tw-bg-opacity:0.05
  }

  .xxl\:focus\:bg-opacity-10:focus{
    --tw-bg-opacity:0.1
  }

  .xxl\:focus\:bg-opacity-20:focus{
    --tw-bg-opacity:0.2
  }

  .xxl\:focus\:bg-opacity-25:focus{
    --tw-bg-opacity:0.25
  }

  .xxl\:focus\:bg-opacity-30:focus{
    --tw-bg-opacity:0.3
  }

  .xxl\:focus\:bg-opacity-40:focus{
    --tw-bg-opacity:0.4
  }

  .xxl\:focus\:bg-opacity-50:focus{
    --tw-bg-opacity:0.5
  }

  .xxl\:focus\:bg-opacity-60:focus{
    --tw-bg-opacity:0.6
  }

  .xxl\:focus\:bg-opacity-70:focus{
    --tw-bg-opacity:0.7
  }

  .xxl\:focus\:bg-opacity-75:focus{
    --tw-bg-opacity:0.75
  }

  .xxl\:focus\:bg-opacity-80:focus{
    --tw-bg-opacity:0.8
  }

  .xxl\:focus\:bg-opacity-90:focus{
    --tw-bg-opacity:0.9
  }

  .xxl\:focus\:bg-opacity-95:focus{
    --tw-bg-opacity:0.95
  }

  .xxl\:focus\:bg-opacity-100:focus{
    --tw-bg-opacity:1
  }

  .xxl\:bg-none{
    background-image:none
  }

  .xxl\:bg-gradient-to-t{
    background-image:linear-gradient(to top, var(--tw-gradient-stops))
  }

  .xxl\:bg-gradient-to-tr{
    background-image:linear-gradient(to top right, var(--tw-gradient-stops))
  }

  .xxl\:bg-gradient-to-r{
    background-image:linear-gradient(to right, var(--tw-gradient-stops))
  }

  .xxl\:bg-gradient-to-br{
    background-image:linear-gradient(to bottom right, var(--tw-gradient-stops))
  }

  .xxl\:bg-gradient-to-b{
    background-image:linear-gradient(to bottom, var(--tw-gradient-stops))
  }

  .xxl\:bg-gradient-to-bl{
    background-image:linear-gradient(to bottom left, var(--tw-gradient-stops))
  }

  .xxl\:bg-gradient-to-l{
    background-image:linear-gradient(to left, var(--tw-gradient-stops))
  }

  .xxl\:bg-gradient-to-tl{
    background-image:linear-gradient(to top left, var(--tw-gradient-stops))
  }

  .xxl\:from-red1{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xxl\:from-red2{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xxl\:from-red3{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xxl\:from-red4{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xxl\:from-red5{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xxl\:from-cyan1{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xxl\:from-cyan2{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xxl\:from-cyan3{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xxl\:from-cyan4{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xxl\:from-cyan5{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xxl\:from-bg1{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xxl\:from-bg2{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:from-bg3{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xxl\:from-gray1{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xxl\:from-gray2{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xxl\:from-gray3{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xxl\:from-gray4{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xxl\:from-gray5{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xxl\:from-gray6{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xxl\:from-gray7{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:from-white{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:from-warning{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xxl\:from-error{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xxl\:from-info{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xxl\:from-success{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xxl\:from-special4{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xxl\:from-dark{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:from-transparent{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:from-secondary1{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xxl\:from-secondary2{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:from-secondary3{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xxl\:from-secondary4{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xxl\:from-secondary5{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xxl\:from-green1{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xxl\:hover\:from-red1:hover{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xxl\:hover\:from-red2:hover{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xxl\:hover\:from-red3:hover{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xxl\:hover\:from-red4:hover{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xxl\:hover\:from-red5:hover{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xxl\:hover\:from-cyan1:hover{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xxl\:hover\:from-cyan2:hover{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xxl\:hover\:from-cyan3:hover{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xxl\:hover\:from-cyan4:hover{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xxl\:hover\:from-cyan5:hover{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xxl\:hover\:from-bg1:hover{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xxl\:hover\:from-bg2:hover{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:hover\:from-bg3:hover{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xxl\:hover\:from-gray1:hover{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xxl\:hover\:from-gray2:hover{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xxl\:hover\:from-gray3:hover{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xxl\:hover\:from-gray4:hover{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xxl\:hover\:from-gray5:hover{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xxl\:hover\:from-gray6:hover{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xxl\:hover\:from-gray7:hover{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:hover\:from-white:hover{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:hover\:from-warning:hover{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xxl\:hover\:from-error:hover{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xxl\:hover\:from-info:hover{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xxl\:hover\:from-success:hover{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xxl\:hover\:from-special4:hover{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xxl\:hover\:from-dark:hover{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:hover\:from-transparent:hover{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:hover\:from-secondary1:hover{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xxl\:hover\:from-secondary2:hover{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:hover\:from-secondary3:hover{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xxl\:hover\:from-secondary4:hover{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xxl\:hover\:from-secondary5:hover{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xxl\:hover\:from-green1:hover{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xxl\:focus\:from-red1:focus{
    --tw-gradient-from:#CC352C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xxl\:focus\:from-red2:focus{
    --tw-gradient-from:#FF554B;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xxl\:focus\:from-red3:focus{
    --tw-gradient-from:#FF7B73;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xxl\:focus\:from-red4:focus{
    --tw-gradient-from:#FFF1F1;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xxl\:focus\:from-red5:focus{
    --tw-gradient-from:#FFF6F6;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xxl\:focus\:from-cyan1:focus{
    --tw-gradient-from:#009897;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xxl\:focus\:from-cyan2:focus{
    --tw-gradient-from:#00BFBD;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xxl\:focus\:from-cyan3:focus{
    --tw-gradient-from:#4CD1D0;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xxl\:focus\:from-cyan4:focus{
    --tw-gradient-from:#B2ECEB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xxl\:focus\:from-cyan5:focus{
    --tw-gradient-from:#EDFAFA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xxl\:focus\:from-bg1:focus{
    --tw-gradient-from:#FAFAFC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xxl\:focus\:from-bg2:focus{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:focus\:from-bg3:focus{
    --tw-gradient-from:#F7F7FA;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xxl\:focus\:from-gray1:focus{
    --tw-gradient-from:#333333;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xxl\:focus\:from-gray2:focus{
    --tw-gradient-from:#4D4D4D;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xxl\:focus\:from-gray3:focus{
    --tw-gradient-from:#8C8C8C;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xxl\:focus\:from-gray4:focus{
    --tw-gradient-from:#BFBFBF;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xxl\:focus\:from-gray5:focus{
    --tw-gradient-from:#D9D9D9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xxl\:focus\:from-gray6:focus{
    --tw-gradient-from:#E9E9EB;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xxl\:focus\:from-gray7:focus{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:focus\:from-white:focus{
    --tw-gradient-from:#ffffff;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:focus\:from-warning:focus{
    --tw-gradient-from:#FFC319;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xxl\:focus\:from-error:focus{
    --tw-gradient-from:#EA696F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xxl\:focus\:from-info:focus{
    --tw-gradient-from:#0071B9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xxl\:focus\:from-success:focus{
    --tw-gradient-from:#98D45F;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xxl\:focus\:from-special4:focus{
    --tw-gradient-from:#59779A;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xxl\:focus\:from-dark:focus{
    --tw-gradient-from:#000000;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:focus\:from-transparent:focus{
    --tw-gradient-from:transparent;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:focus\:from-secondary1:focus{
    --tw-gradient-from:#313140;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xxl\:focus\:from-secondary2:focus{
    --tw-gradient-from:#515164;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:focus\:from-secondary3:focus{
    --tw-gradient-from:#9C9CAC;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xxl\:focus\:from-secondary4:focus{
    --tw-gradient-from:#E5E8ED;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xxl\:focus\:from-secondary5:focus{
    --tw-gradient-from:#F5F6F9;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xxl\:focus\:from-green1:focus{
    --tw-gradient-from:#0DE298;
    --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xxl\:via-red1{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xxl\:via-red2{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xxl\:via-red3{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xxl\:via-red4{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xxl\:via-red5{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xxl\:via-cyan1{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xxl\:via-cyan2{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xxl\:via-cyan3{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xxl\:via-cyan4{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xxl\:via-cyan5{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xxl\:via-bg1{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xxl\:via-bg2{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:via-bg3{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xxl\:via-gray1{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xxl\:via-gray2{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xxl\:via-gray3{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xxl\:via-gray4{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xxl\:via-gray5{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xxl\:via-gray6{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xxl\:via-gray7{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:via-white{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:via-warning{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xxl\:via-error{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xxl\:via-info{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xxl\:via-success{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xxl\:via-special4{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xxl\:via-dark{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:via-transparent{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:via-secondary1{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xxl\:via-secondary2{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:via-secondary3{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xxl\:via-secondary4{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xxl\:via-secondary5{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xxl\:via-green1{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xxl\:hover\:via-red1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xxl\:hover\:via-red2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xxl\:hover\:via-red3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xxl\:hover\:via-red4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xxl\:hover\:via-red5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xxl\:hover\:via-cyan1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xxl\:hover\:via-cyan2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xxl\:hover\:via-cyan3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xxl\:hover\:via-cyan4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xxl\:hover\:via-cyan5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xxl\:hover\:via-bg1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xxl\:hover\:via-bg2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:hover\:via-bg3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xxl\:hover\:via-gray1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xxl\:hover\:via-gray2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xxl\:hover\:via-gray3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xxl\:hover\:via-gray4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xxl\:hover\:via-gray5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xxl\:hover\:via-gray6:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xxl\:hover\:via-gray7:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:hover\:via-white:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:hover\:via-warning:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xxl\:hover\:via-error:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xxl\:hover\:via-info:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xxl\:hover\:via-success:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xxl\:hover\:via-special4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xxl\:hover\:via-dark:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:hover\:via-transparent:hover{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:hover\:via-secondary1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xxl\:hover\:via-secondary2:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:hover\:via-secondary3:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xxl\:hover\:via-secondary4:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xxl\:hover\:via-secondary5:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xxl\:hover\:via-green1:hover{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xxl\:focus\:via-red1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #CC352C, var(--tw-gradient-to, rgba(204, 53, 44, 0))
  }

  .xxl\:focus\:via-red2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FF554B, var(--tw-gradient-to, rgba(255, 85, 75, 0))
  }

  .xxl\:focus\:via-red3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FF7B73, var(--tw-gradient-to, rgba(255, 123, 115, 0))
  }

  .xxl\:focus\:via-red4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF1F1, var(--tw-gradient-to, rgba(255, 241, 241, 0))
  }

  .xxl\:focus\:via-red5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFF6F6, var(--tw-gradient-to, rgba(255, 246, 246, 0))
  }

  .xxl\:focus\:via-cyan1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #009897, var(--tw-gradient-to, rgba(0, 152, 151, 0))
  }

  .xxl\:focus\:via-cyan2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #00BFBD, var(--tw-gradient-to, rgba(0, 191, 189, 0))
  }

  .xxl\:focus\:via-cyan3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #4CD1D0, var(--tw-gradient-to, rgba(76, 209, 208, 0))
  }

  .xxl\:focus\:via-cyan4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #B2ECEB, var(--tw-gradient-to, rgba(178, 236, 235, 0))
  }

  .xxl\:focus\:via-cyan5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #EDFAFA, var(--tw-gradient-to, rgba(237, 250, 250, 0))
  }

  .xxl\:focus\:via-bg1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FAFAFC, var(--tw-gradient-to, rgba(250, 250, 252, 0))
  }

  .xxl\:focus\:via-bg2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:focus\:via-bg3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #F7F7FA, var(--tw-gradient-to, rgba(247, 247, 250, 0))
  }

  .xxl\:focus\:via-gray1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #333333, var(--tw-gradient-to, rgba(51, 51, 51, 0))
  }

  .xxl\:focus\:via-gray2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #4D4D4D, var(--tw-gradient-to, rgba(77, 77, 77, 0))
  }

  .xxl\:focus\:via-gray3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #8C8C8C, var(--tw-gradient-to, rgba(140, 140, 140, 0))
  }

  .xxl\:focus\:via-gray4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #BFBFBF, var(--tw-gradient-to, rgba(191, 191, 191, 0))
  }

  .xxl\:focus\:via-gray5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #D9D9D9, var(--tw-gradient-to, rgba(217, 217, 217, 0))
  }

  .xxl\:focus\:via-gray6:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #E9E9EB, var(--tw-gradient-to, rgba(233, 233, 235, 0))
  }

  .xxl\:focus\:via-gray7:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:focus\:via-white:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #ffffff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  .xxl\:focus\:via-warning:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #FFC319, var(--tw-gradient-to, rgba(255, 195, 25, 0))
  }

  .xxl\:focus\:via-error:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #EA696F, var(--tw-gradient-to, rgba(234, 105, 111, 0))
  }

  .xxl\:focus\:via-info:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #0071B9, var(--tw-gradient-to, rgba(0, 113, 185, 0))
  }

  .xxl\:focus\:via-success:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #98D45F, var(--tw-gradient-to, rgba(152, 212, 95, 0))
  }

  .xxl\:focus\:via-special4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #59779A, var(--tw-gradient-to, rgba(89, 119, 154, 0))
  }

  .xxl\:focus\:via-dark:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #000000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:focus\:via-transparent:focus{
    --tw-gradient-stops:var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  .xxl\:focus\:via-secondary1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #313140, var(--tw-gradient-to, rgba(49, 49, 64, 0))
  }

  .xxl\:focus\:via-secondary2:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #515164, var(--tw-gradient-to, rgba(81, 81, 100, 0))
  }

  .xxl\:focus\:via-secondary3:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #9C9CAC, var(--tw-gradient-to, rgba(156, 156, 172, 0))
  }

  .xxl\:focus\:via-secondary4:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #E5E8ED, var(--tw-gradient-to, rgba(229, 232, 237, 0))
  }

  .xxl\:focus\:via-secondary5:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #F5F6F9, var(--tw-gradient-to, rgba(245, 246, 249, 0))
  }

  .xxl\:focus\:via-green1:focus{
    --tw-gradient-stops:var(--tw-gradient-from), #0DE298, var(--tw-gradient-to, rgba(13, 226, 152, 0))
  }

  .xxl\:to-red1{
    --tw-gradient-to:#CC352C
  }

  .xxl\:to-red2{
    --tw-gradient-to:#FF554B
  }

  .xxl\:to-red3{
    --tw-gradient-to:#FF7B73
  }

  .xxl\:to-red4{
    --tw-gradient-to:#FFF1F1
  }

  .xxl\:to-red5{
    --tw-gradient-to:#FFF6F6
  }

  .xxl\:to-cyan1{
    --tw-gradient-to:#009897
  }

  .xxl\:to-cyan2{
    --tw-gradient-to:#00BFBD
  }

  .xxl\:to-cyan3{
    --tw-gradient-to:#4CD1D0
  }

  .xxl\:to-cyan4{
    --tw-gradient-to:#B2ECEB
  }

  .xxl\:to-cyan5{
    --tw-gradient-to:#EDFAFA
  }

  .xxl\:to-bg1{
    --tw-gradient-to:#FAFAFC
  }

  .xxl\:to-bg2{
    --tw-gradient-to:#ffffff
  }

  .xxl\:to-bg3{
    --tw-gradient-to:#F7F7FA
  }

  .xxl\:to-gray1{
    --tw-gradient-to:#333333
  }

  .xxl\:to-gray2{
    --tw-gradient-to:#4D4D4D
  }

  .xxl\:to-gray3{
    --tw-gradient-to:#8C8C8C
  }

  .xxl\:to-gray4{
    --tw-gradient-to:#BFBFBF
  }

  .xxl\:to-gray5{
    --tw-gradient-to:#D9D9D9
  }

  .xxl\:to-gray6{
    --tw-gradient-to:#E9E9EB
  }

  .xxl\:to-gray7{
    --tw-gradient-to:#515164
  }

  .xxl\:to-white{
    --tw-gradient-to:#ffffff
  }

  .xxl\:to-warning{
    --tw-gradient-to:#FFC319
  }

  .xxl\:to-error{
    --tw-gradient-to:#EA696F
  }

  .xxl\:to-info{
    --tw-gradient-to:#0071B9
  }

  .xxl\:to-success{
    --tw-gradient-to:#98D45F
  }

  .xxl\:to-special4{
    --tw-gradient-to:#59779A
  }

  .xxl\:to-dark{
    --tw-gradient-to:#000000
  }

  .xxl\:to-transparent{
    --tw-gradient-to:transparent
  }

  .xxl\:to-secondary1{
    --tw-gradient-to:#313140
  }

  .xxl\:to-secondary2{
    --tw-gradient-to:#515164
  }

  .xxl\:to-secondary3{
    --tw-gradient-to:#9C9CAC
  }

  .xxl\:to-secondary4{
    --tw-gradient-to:#E5E8ED
  }

  .xxl\:to-secondary5{
    --tw-gradient-to:#F5F6F9
  }

  .xxl\:to-green1{
    --tw-gradient-to:#0DE298
  }

  .xxl\:hover\:to-red1:hover{
    --tw-gradient-to:#CC352C
  }

  .xxl\:hover\:to-red2:hover{
    --tw-gradient-to:#FF554B
  }

  .xxl\:hover\:to-red3:hover{
    --tw-gradient-to:#FF7B73
  }

  .xxl\:hover\:to-red4:hover{
    --tw-gradient-to:#FFF1F1
  }

  .xxl\:hover\:to-red5:hover{
    --tw-gradient-to:#FFF6F6
  }

  .xxl\:hover\:to-cyan1:hover{
    --tw-gradient-to:#009897
  }

  .xxl\:hover\:to-cyan2:hover{
    --tw-gradient-to:#00BFBD
  }

  .xxl\:hover\:to-cyan3:hover{
    --tw-gradient-to:#4CD1D0
  }

  .xxl\:hover\:to-cyan4:hover{
    --tw-gradient-to:#B2ECEB
  }

  .xxl\:hover\:to-cyan5:hover{
    --tw-gradient-to:#EDFAFA
  }

  .xxl\:hover\:to-bg1:hover{
    --tw-gradient-to:#FAFAFC
  }

  .xxl\:hover\:to-bg2:hover{
    --tw-gradient-to:#ffffff
  }

  .xxl\:hover\:to-bg3:hover{
    --tw-gradient-to:#F7F7FA
  }

  .xxl\:hover\:to-gray1:hover{
    --tw-gradient-to:#333333
  }

  .xxl\:hover\:to-gray2:hover{
    --tw-gradient-to:#4D4D4D
  }

  .xxl\:hover\:to-gray3:hover{
    --tw-gradient-to:#8C8C8C
  }

  .xxl\:hover\:to-gray4:hover{
    --tw-gradient-to:#BFBFBF
  }

  .xxl\:hover\:to-gray5:hover{
    --tw-gradient-to:#D9D9D9
  }

  .xxl\:hover\:to-gray6:hover{
    --tw-gradient-to:#E9E9EB
  }

  .xxl\:hover\:to-gray7:hover{
    --tw-gradient-to:#515164
  }

  .xxl\:hover\:to-white:hover{
    --tw-gradient-to:#ffffff
  }

  .xxl\:hover\:to-warning:hover{
    --tw-gradient-to:#FFC319
  }

  .xxl\:hover\:to-error:hover{
    --tw-gradient-to:#EA696F
  }

  .xxl\:hover\:to-info:hover{
    --tw-gradient-to:#0071B9
  }

  .xxl\:hover\:to-success:hover{
    --tw-gradient-to:#98D45F
  }

  .xxl\:hover\:to-special4:hover{
    --tw-gradient-to:#59779A
  }

  .xxl\:hover\:to-dark:hover{
    --tw-gradient-to:#000000
  }

  .xxl\:hover\:to-transparent:hover{
    --tw-gradient-to:transparent
  }

  .xxl\:hover\:to-secondary1:hover{
    --tw-gradient-to:#313140
  }

  .xxl\:hover\:to-secondary2:hover{
    --tw-gradient-to:#515164
  }

  .xxl\:hover\:to-secondary3:hover{
    --tw-gradient-to:#9C9CAC
  }

  .xxl\:hover\:to-secondary4:hover{
    --tw-gradient-to:#E5E8ED
  }

  .xxl\:hover\:to-secondary5:hover{
    --tw-gradient-to:#F5F6F9
  }

  .xxl\:hover\:to-green1:hover{
    --tw-gradient-to:#0DE298
  }

  .xxl\:focus\:to-red1:focus{
    --tw-gradient-to:#CC352C
  }

  .xxl\:focus\:to-red2:focus{
    --tw-gradient-to:#FF554B
  }

  .xxl\:focus\:to-red3:focus{
    --tw-gradient-to:#FF7B73
  }

  .xxl\:focus\:to-red4:focus{
    --tw-gradient-to:#FFF1F1
  }

  .xxl\:focus\:to-red5:focus{
    --tw-gradient-to:#FFF6F6
  }

  .xxl\:focus\:to-cyan1:focus{
    --tw-gradient-to:#009897
  }

  .xxl\:focus\:to-cyan2:focus{
    --tw-gradient-to:#00BFBD
  }

  .xxl\:focus\:to-cyan3:focus{
    --tw-gradient-to:#4CD1D0
  }

  .xxl\:focus\:to-cyan4:focus{
    --tw-gradient-to:#B2ECEB
  }

  .xxl\:focus\:to-cyan5:focus{
    --tw-gradient-to:#EDFAFA
  }

  .xxl\:focus\:to-bg1:focus{
    --tw-gradient-to:#FAFAFC
  }

  .xxl\:focus\:to-bg2:focus{
    --tw-gradient-to:#ffffff
  }

  .xxl\:focus\:to-bg3:focus{
    --tw-gradient-to:#F7F7FA
  }

  .xxl\:focus\:to-gray1:focus{
    --tw-gradient-to:#333333
  }

  .xxl\:focus\:to-gray2:focus{
    --tw-gradient-to:#4D4D4D
  }

  .xxl\:focus\:to-gray3:focus{
    --tw-gradient-to:#8C8C8C
  }

  .xxl\:focus\:to-gray4:focus{
    --tw-gradient-to:#BFBFBF
  }

  .xxl\:focus\:to-gray5:focus{
    --tw-gradient-to:#D9D9D9
  }

  .xxl\:focus\:to-gray6:focus{
    --tw-gradient-to:#E9E9EB
  }

  .xxl\:focus\:to-gray7:focus{
    --tw-gradient-to:#515164
  }

  .xxl\:focus\:to-white:focus{
    --tw-gradient-to:#ffffff
  }

  .xxl\:focus\:to-warning:focus{
    --tw-gradient-to:#FFC319
  }

  .xxl\:focus\:to-error:focus{
    --tw-gradient-to:#EA696F
  }

  .xxl\:focus\:to-info:focus{
    --tw-gradient-to:#0071B9
  }

  .xxl\:focus\:to-success:focus{
    --tw-gradient-to:#98D45F
  }

  .xxl\:focus\:to-special4:focus{
    --tw-gradient-to:#59779A
  }

  .xxl\:focus\:to-dark:focus{
    --tw-gradient-to:#000000
  }

  .xxl\:focus\:to-transparent:focus{
    --tw-gradient-to:transparent
  }

  .xxl\:focus\:to-secondary1:focus{
    --tw-gradient-to:#313140
  }

  .xxl\:focus\:to-secondary2:focus{
    --tw-gradient-to:#515164
  }

  .xxl\:focus\:to-secondary3:focus{
    --tw-gradient-to:#9C9CAC
  }

  .xxl\:focus\:to-secondary4:focus{
    --tw-gradient-to:#E5E8ED
  }

  .xxl\:focus\:to-secondary5:focus{
    --tw-gradient-to:#F5F6F9
  }

  .xxl\:focus\:to-green1:focus{
    --tw-gradient-to:#0DE298
  }

  .xxl\:decoration-slice{
    -webkit-box-decoration-break:slice;
            box-decoration-break:slice
  }

  .xxl\:decoration-clone{
    -webkit-box-decoration-break:clone;
            box-decoration-break:clone
  }

  .xxl\:bg-auto{
    background-size:auto
  }

  .xxl\:bg-cover{
    background-size:cover
  }

  .xxl\:bg-contain{
    background-size:contain
  }

  .xxl\:bg-clip-border{
    background-clip:border-box
  }

  .xxl\:bg-clip-padding{
    background-clip:padding-box
  }

  .xxl\:bg-clip-content{
    background-clip:content-box
  }

  .xxl\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .xxl\:bg-bottom{
    background-position:bottom
  }

  .xxl\:bg-center{
    background-position:center
  }

  .xxl\:bg-left{
    background-position:left
  }

  .xxl\:bg-left-bottom{
    background-position:left bottom
  }

  .xxl\:bg-left-top{
    background-position:left top
  }

  .xxl\:bg-right{
    background-position:right
  }

  .xxl\:bg-right-bottom{
    background-position:right bottom
  }

  .xxl\:bg-right-top{
    background-position:right top
  }

  .xxl\:bg-top{
    background-position:top
  }

  .xxl\:bg-repeat{
    background-repeat:repeat
  }

  .xxl\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .xxl\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .xxl\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .xxl\:bg-repeat-round{
    background-repeat:round
  }

  .xxl\:bg-repeat-space{
    background-repeat:space
  }

  .xxl\:bg-origin-border{
    background-origin:border-box
  }

  .xxl\:bg-origin-padding{
    background-origin:padding-box
  }

  .xxl\:bg-origin-content{
    background-origin:content-box
  }

  .xxl\:fill-current{
    fill:currentColor
  }

  .xxl\:object-contain{
    object-fit:contain
  }

  .xxl\:object-cover{
    object-fit:cover
  }

  .xxl\:object-fill{
    object-fit:fill
  }

  .xxl\:object-none{
    object-fit:none
  }

  .xxl\:object-scale-down{
    object-fit:scale-down
  }

  .xxl\:p-0{
    padding:0
  }

  .xxl\:p-1{
    padding:4px
  }

  .xxl\:p-2{
    padding:8px
  }

  .xxl\:p-3{
    padding:12px
  }

  .xxl\:p-4{
    padding:16px
  }

  .xxl\:p-5{
    padding:20px
  }

  .xxl\:p-6{
    padding:24px
  }

  .xxl\:p-8{
    padding:32px
  }

  .xxl\:p-10{
    padding:40px
  }

  .xxl\:p-12{
    padding:48px
  }

  .xxl\:p-16{
    padding:64px
  }

  .xxl\:p-18{
    padding:72px
  }

  .xxl\:p-24{
    padding:96px
  }

  .xxl\:p-5dot5{
    padding:22px
  }

  .xxl\:p-full{
    padding:100%
  }

  .xxl\:first\:p-0:first-child{
    padding:0
  }

  .xxl\:first\:p-1:first-child{
    padding:4px
  }

  .xxl\:first\:p-2:first-child{
    padding:8px
  }

  .xxl\:first\:p-3:first-child{
    padding:12px
  }

  .xxl\:first\:p-4:first-child{
    padding:16px
  }

  .xxl\:first\:p-5:first-child{
    padding:20px
  }

  .xxl\:first\:p-6:first-child{
    padding:24px
  }

  .xxl\:first\:p-8:first-child{
    padding:32px
  }

  .xxl\:first\:p-10:first-child{
    padding:40px
  }

  .xxl\:first\:p-12:first-child{
    padding:48px
  }

  .xxl\:first\:p-16:first-child{
    padding:64px
  }

  .xxl\:first\:p-18:first-child{
    padding:72px
  }

  .xxl\:first\:p-24:first-child{
    padding:96px
  }

  .xxl\:first\:p-5dot5:first-child{
    padding:22px
  }

  .xxl\:first\:p-full:first-child{
    padding:100%
  }

  .xxl\:last\:p-0:last-child{
    padding:0
  }

  .xxl\:last\:p-1:last-child{
    padding:4px
  }

  .xxl\:last\:p-2:last-child{
    padding:8px
  }

  .xxl\:last\:p-3:last-child{
    padding:12px
  }

  .xxl\:last\:p-4:last-child{
    padding:16px
  }

  .xxl\:last\:p-5:last-child{
    padding:20px
  }

  .xxl\:last\:p-6:last-child{
    padding:24px
  }

  .xxl\:last\:p-8:last-child{
    padding:32px
  }

  .xxl\:last\:p-10:last-child{
    padding:40px
  }

  .xxl\:last\:p-12:last-child{
    padding:48px
  }

  .xxl\:last\:p-16:last-child{
    padding:64px
  }

  .xxl\:last\:p-18:last-child{
    padding:72px
  }

  .xxl\:last\:p-24:last-child{
    padding:96px
  }

  .xxl\:last\:p-5dot5:last-child{
    padding:22px
  }

  .xxl\:last\:p-full:last-child{
    padding:100%
  }

  .xxl\:px-0{
    padding-left:0;
    padding-right:0
  }

  .xxl\:px-1{
    padding-left:4px;
    padding-right:4px
  }

  .xxl\:px-2{
    padding-left:8px;
    padding-right:8px
  }

  .xxl\:px-3{
    padding-left:12px;
    padding-right:12px
  }

  .xxl\:px-4{
    padding-left:16px;
    padding-right:16px
  }

  .xxl\:px-5{
    padding-left:20px;
    padding-right:20px
  }

  .xxl\:px-6{
    padding-left:24px;
    padding-right:24px
  }

  .xxl\:px-8{
    padding-left:32px;
    padding-right:32px
  }

  .xxl\:px-10{
    padding-left:40px;
    padding-right:40px
  }

  .xxl\:px-12{
    padding-left:48px;
    padding-right:48px
  }

  .xxl\:px-16{
    padding-left:64px;
    padding-right:64px
  }

  .xxl\:px-18{
    padding-left:72px;
    padding-right:72px
  }

  .xxl\:px-24{
    padding-left:96px;
    padding-right:96px
  }

  .xxl\:px-5dot5{
    padding-left:22px;
    padding-right:22px
  }

  .xxl\:px-full{
    padding-left:100%;
    padding-right:100%
  }

  .xxl\:py-0{
    padding-top:0;
    padding-bottom:0
  }

  .xxl\:py-1{
    padding-top:4px;
    padding-bottom:4px
  }

  .xxl\:py-2{
    padding-top:8px;
    padding-bottom:8px
  }

  .xxl\:py-3{
    padding-top:12px;
    padding-bottom:12px
  }

  .xxl\:py-4{
    padding-top:16px;
    padding-bottom:16px
  }

  .xxl\:py-5{
    padding-top:20px;
    padding-bottom:20px
  }

  .xxl\:py-6{
    padding-top:24px;
    padding-bottom:24px
  }

  .xxl\:py-8{
    padding-top:32px;
    padding-bottom:32px
  }

  .xxl\:py-10{
    padding-top:40px;
    padding-bottom:40px
  }

  .xxl\:py-12{
    padding-top:48px;
    padding-bottom:48px
  }

  .xxl\:py-16{
    padding-top:64px;
    padding-bottom:64px
  }

  .xxl\:py-18{
    padding-top:72px;
    padding-bottom:72px
  }

  .xxl\:py-24{
    padding-top:96px;
    padding-bottom:96px
  }

  .xxl\:py-5dot5{
    padding-top:22px;
    padding-bottom:22px
  }

  .xxl\:py-full{
    padding-top:100%;
    padding-bottom:100%
  }

  .xxl\:first\:px-0:first-child{
    padding-left:0;
    padding-right:0
  }

  .xxl\:first\:px-1:first-child{
    padding-left:4px;
    padding-right:4px
  }

  .xxl\:first\:px-2:first-child{
    padding-left:8px;
    padding-right:8px
  }

  .xxl\:first\:px-3:first-child{
    padding-left:12px;
    padding-right:12px
  }

  .xxl\:first\:px-4:first-child{
    padding-left:16px;
    padding-right:16px
  }

  .xxl\:first\:px-5:first-child{
    padding-left:20px;
    padding-right:20px
  }

  .xxl\:first\:px-6:first-child{
    padding-left:24px;
    padding-right:24px
  }

  .xxl\:first\:px-8:first-child{
    padding-left:32px;
    padding-right:32px
  }

  .xxl\:first\:px-10:first-child{
    padding-left:40px;
    padding-right:40px
  }

  .xxl\:first\:px-12:first-child{
    padding-left:48px;
    padding-right:48px
  }

  .xxl\:first\:px-16:first-child{
    padding-left:64px;
    padding-right:64px
  }

  .xxl\:first\:px-18:first-child{
    padding-left:72px;
    padding-right:72px
  }

  .xxl\:first\:px-24:first-child{
    padding-left:96px;
    padding-right:96px
  }

  .xxl\:first\:px-5dot5:first-child{
    padding-left:22px;
    padding-right:22px
  }

  .xxl\:first\:px-full:first-child{
    padding-left:100%;
    padding-right:100%
  }

  .xxl\:first\:py-0:first-child{
    padding-top:0;
    padding-bottom:0
  }

  .xxl\:first\:py-1:first-child{
    padding-top:4px;
    padding-bottom:4px
  }

  .xxl\:first\:py-2:first-child{
    padding-top:8px;
    padding-bottom:8px
  }

  .xxl\:first\:py-3:first-child{
    padding-top:12px;
    padding-bottom:12px
  }

  .xxl\:first\:py-4:first-child{
    padding-top:16px;
    padding-bottom:16px
  }

  .xxl\:first\:py-5:first-child{
    padding-top:20px;
    padding-bottom:20px
  }

  .xxl\:first\:py-6:first-child{
    padding-top:24px;
    padding-bottom:24px
  }

  .xxl\:first\:py-8:first-child{
    padding-top:32px;
    padding-bottom:32px
  }

  .xxl\:first\:py-10:first-child{
    padding-top:40px;
    padding-bottom:40px
  }

  .xxl\:first\:py-12:first-child{
    padding-top:48px;
    padding-bottom:48px
  }

  .xxl\:first\:py-16:first-child{
    padding-top:64px;
    padding-bottom:64px
  }

  .xxl\:first\:py-18:first-child{
    padding-top:72px;
    padding-bottom:72px
  }

  .xxl\:first\:py-24:first-child{
    padding-top:96px;
    padding-bottom:96px
  }

  .xxl\:first\:py-5dot5:first-child{
    padding-top:22px;
    padding-bottom:22px
  }

  .xxl\:first\:py-full:first-child{
    padding-top:100%;
    padding-bottom:100%
  }

  .xxl\:last\:px-0:last-child{
    padding-left:0;
    padding-right:0
  }

  .xxl\:last\:px-1:last-child{
    padding-left:4px;
    padding-right:4px
  }

  .xxl\:last\:px-2:last-child{
    padding-left:8px;
    padding-right:8px
  }

  .xxl\:last\:px-3:last-child{
    padding-left:12px;
    padding-right:12px
  }

  .xxl\:last\:px-4:last-child{
    padding-left:16px;
    padding-right:16px
  }

  .xxl\:last\:px-5:last-child{
    padding-left:20px;
    padding-right:20px
  }

  .xxl\:last\:px-6:last-child{
    padding-left:24px;
    padding-right:24px
  }

  .xxl\:last\:px-8:last-child{
    padding-left:32px;
    padding-right:32px
  }

  .xxl\:last\:px-10:last-child{
    padding-left:40px;
    padding-right:40px
  }

  .xxl\:last\:px-12:last-child{
    padding-left:48px;
    padding-right:48px
  }

  .xxl\:last\:px-16:last-child{
    padding-left:64px;
    padding-right:64px
  }

  .xxl\:last\:px-18:last-child{
    padding-left:72px;
    padding-right:72px
  }

  .xxl\:last\:px-24:last-child{
    padding-left:96px;
    padding-right:96px
  }

  .xxl\:last\:px-5dot5:last-child{
    padding-left:22px;
    padding-right:22px
  }

  .xxl\:last\:px-full:last-child{
    padding-left:100%;
    padding-right:100%
  }

  .xxl\:last\:py-0:last-child{
    padding-top:0;
    padding-bottom:0
  }

  .xxl\:last\:py-1:last-child{
    padding-top:4px;
    padding-bottom:4px
  }

  .xxl\:last\:py-2:last-child{
    padding-top:8px;
    padding-bottom:8px
  }

  .xxl\:last\:py-3:last-child{
    padding-top:12px;
    padding-bottom:12px
  }

  .xxl\:last\:py-4:last-child{
    padding-top:16px;
    padding-bottom:16px
  }

  .xxl\:last\:py-5:last-child{
    padding-top:20px;
    padding-bottom:20px
  }

  .xxl\:last\:py-6:last-child{
    padding-top:24px;
    padding-bottom:24px
  }

  .xxl\:last\:py-8:last-child{
    padding-top:32px;
    padding-bottom:32px
  }

  .xxl\:last\:py-10:last-child{
    padding-top:40px;
    padding-bottom:40px
  }

  .xxl\:last\:py-12:last-child{
    padding-top:48px;
    padding-bottom:48px
  }

  .xxl\:last\:py-16:last-child{
    padding-top:64px;
    padding-bottom:64px
  }

  .xxl\:last\:py-18:last-child{
    padding-top:72px;
    padding-bottom:72px
  }

  .xxl\:last\:py-24:last-child{
    padding-top:96px;
    padding-bottom:96px
  }

  .xxl\:last\:py-5dot5:last-child{
    padding-top:22px;
    padding-bottom:22px
  }

  .xxl\:last\:py-full:last-child{
    padding-top:100%;
    padding-bottom:100%
  }

  .xxl\:pt-0{
    padding-top:0
  }

  .xxl\:pt-1{
    padding-top:4px
  }

  .xxl\:pt-2{
    padding-top:8px
  }

  .xxl\:pt-3{
    padding-top:12px
  }

  .xxl\:pt-4{
    padding-top:16px
  }

  .xxl\:pt-5{
    padding-top:20px
  }

  .xxl\:pt-6{
    padding-top:24px
  }

  .xxl\:pt-8{
    padding-top:32px
  }

  .xxl\:pt-10{
    padding-top:40px
  }

  .xxl\:pt-12{
    padding-top:48px
  }

  .xxl\:pt-16{
    padding-top:64px
  }

  .xxl\:pt-18{
    padding-top:72px
  }

  .xxl\:pt-24{
    padding-top:96px
  }

  .xxl\:pt-5dot5{
    padding-top:22px
  }

  .xxl\:pt-full{
    padding-top:100%
  }

  .xxl\:pr-0{
    padding-right:0
  }

  .xxl\:pr-1{
    padding-right:4px
  }

  .xxl\:pr-2{
    padding-right:8px
  }

  .xxl\:pr-3{
    padding-right:12px
  }

  .xxl\:pr-4{
    padding-right:16px
  }

  .xxl\:pr-5{
    padding-right:20px
  }

  .xxl\:pr-6{
    padding-right:24px
  }

  .xxl\:pr-8{
    padding-right:32px
  }

  .xxl\:pr-10{
    padding-right:40px
  }

  .xxl\:pr-12{
    padding-right:48px
  }

  .xxl\:pr-16{
    padding-right:64px
  }

  .xxl\:pr-18{
    padding-right:72px
  }

  .xxl\:pr-24{
    padding-right:96px
  }

  .xxl\:pr-5dot5{
    padding-right:22px
  }

  .xxl\:pr-full{
    padding-right:100%
  }

  .xxl\:pb-0{
    padding-bottom:0
  }

  .xxl\:pb-1{
    padding-bottom:4px
  }

  .xxl\:pb-2{
    padding-bottom:8px
  }

  .xxl\:pb-3{
    padding-bottom:12px
  }

  .xxl\:pb-4{
    padding-bottom:16px
  }

  .xxl\:pb-5{
    padding-bottom:20px
  }

  .xxl\:pb-6{
    padding-bottom:24px
  }

  .xxl\:pb-8{
    padding-bottom:32px
  }

  .xxl\:pb-10{
    padding-bottom:40px
  }

  .xxl\:pb-12{
    padding-bottom:48px
  }

  .xxl\:pb-16{
    padding-bottom:64px
  }

  .xxl\:pb-18{
    padding-bottom:72px
  }

  .xxl\:pb-24{
    padding-bottom:96px
  }

  .xxl\:pb-5dot5{
    padding-bottom:22px
  }

  .xxl\:pb-full{
    padding-bottom:100%
  }

  .xxl\:pl-0{
    padding-left:0
  }

  .xxl\:pl-1{
    padding-left:4px
  }

  .xxl\:pl-2{
    padding-left:8px
  }

  .xxl\:pl-3{
    padding-left:12px
  }

  .xxl\:pl-4{
    padding-left:16px
  }

  .xxl\:pl-5{
    padding-left:20px
  }

  .xxl\:pl-6{
    padding-left:24px
  }

  .xxl\:pl-8{
    padding-left:32px
  }

  .xxl\:pl-10{
    padding-left:40px
  }

  .xxl\:pl-12{
    padding-left:48px
  }

  .xxl\:pl-16{
    padding-left:64px
  }

  .xxl\:pl-18{
    padding-left:72px
  }

  .xxl\:pl-24{
    padding-left:96px
  }

  .xxl\:pl-5dot5{
    padding-left:22px
  }

  .xxl\:pl-full{
    padding-left:100%
  }

  .xxl\:first\:pt-0:first-child{
    padding-top:0
  }

  .xxl\:first\:pt-1:first-child{
    padding-top:4px
  }

  .xxl\:first\:pt-2:first-child{
    padding-top:8px
  }

  .xxl\:first\:pt-3:first-child{
    padding-top:12px
  }

  .xxl\:first\:pt-4:first-child{
    padding-top:16px
  }

  .xxl\:first\:pt-5:first-child{
    padding-top:20px
  }

  .xxl\:first\:pt-6:first-child{
    padding-top:24px
  }

  .xxl\:first\:pt-8:first-child{
    padding-top:32px
  }

  .xxl\:first\:pt-10:first-child{
    padding-top:40px
  }

  .xxl\:first\:pt-12:first-child{
    padding-top:48px
  }

  .xxl\:first\:pt-16:first-child{
    padding-top:64px
  }

  .xxl\:first\:pt-18:first-child{
    padding-top:72px
  }

  .xxl\:first\:pt-24:first-child{
    padding-top:96px
  }

  .xxl\:first\:pt-5dot5:first-child{
    padding-top:22px
  }

  .xxl\:first\:pt-full:first-child{
    padding-top:100%
  }

  .xxl\:first\:pr-0:first-child{
    padding-right:0
  }

  .xxl\:first\:pr-1:first-child{
    padding-right:4px
  }

  .xxl\:first\:pr-2:first-child{
    padding-right:8px
  }

  .xxl\:first\:pr-3:first-child{
    padding-right:12px
  }

  .xxl\:first\:pr-4:first-child{
    padding-right:16px
  }

  .xxl\:first\:pr-5:first-child{
    padding-right:20px
  }

  .xxl\:first\:pr-6:first-child{
    padding-right:24px
  }

  .xxl\:first\:pr-8:first-child{
    padding-right:32px
  }

  .xxl\:first\:pr-10:first-child{
    padding-right:40px
  }

  .xxl\:first\:pr-12:first-child{
    padding-right:48px
  }

  .xxl\:first\:pr-16:first-child{
    padding-right:64px
  }

  .xxl\:first\:pr-18:first-child{
    padding-right:72px
  }

  .xxl\:first\:pr-24:first-child{
    padding-right:96px
  }

  .xxl\:first\:pr-5dot5:first-child{
    padding-right:22px
  }

  .xxl\:first\:pr-full:first-child{
    padding-right:100%
  }

  .xxl\:first\:pb-0:first-child{
    padding-bottom:0
  }

  .xxl\:first\:pb-1:first-child{
    padding-bottom:4px
  }

  .xxl\:first\:pb-2:first-child{
    padding-bottom:8px
  }

  .xxl\:first\:pb-3:first-child{
    padding-bottom:12px
  }

  .xxl\:first\:pb-4:first-child{
    padding-bottom:16px
  }

  .xxl\:first\:pb-5:first-child{
    padding-bottom:20px
  }

  .xxl\:first\:pb-6:first-child{
    padding-bottom:24px
  }

  .xxl\:first\:pb-8:first-child{
    padding-bottom:32px
  }

  .xxl\:first\:pb-10:first-child{
    padding-bottom:40px
  }

  .xxl\:first\:pb-12:first-child{
    padding-bottom:48px
  }

  .xxl\:first\:pb-16:first-child{
    padding-bottom:64px
  }

  .xxl\:first\:pb-18:first-child{
    padding-bottom:72px
  }

  .xxl\:first\:pb-24:first-child{
    padding-bottom:96px
  }

  .xxl\:first\:pb-5dot5:first-child{
    padding-bottom:22px
  }

  .xxl\:first\:pb-full:first-child{
    padding-bottom:100%
  }

  .xxl\:first\:pl-0:first-child{
    padding-left:0
  }

  .xxl\:first\:pl-1:first-child{
    padding-left:4px
  }

  .xxl\:first\:pl-2:first-child{
    padding-left:8px
  }

  .xxl\:first\:pl-3:first-child{
    padding-left:12px
  }

  .xxl\:first\:pl-4:first-child{
    padding-left:16px
  }

  .xxl\:first\:pl-5:first-child{
    padding-left:20px
  }

  .xxl\:first\:pl-6:first-child{
    padding-left:24px
  }

  .xxl\:first\:pl-8:first-child{
    padding-left:32px
  }

  .xxl\:first\:pl-10:first-child{
    padding-left:40px
  }

  .xxl\:first\:pl-12:first-child{
    padding-left:48px
  }

  .xxl\:first\:pl-16:first-child{
    padding-left:64px
  }

  .xxl\:first\:pl-18:first-child{
    padding-left:72px
  }

  .xxl\:first\:pl-24:first-child{
    padding-left:96px
  }

  .xxl\:first\:pl-5dot5:first-child{
    padding-left:22px
  }

  .xxl\:first\:pl-full:first-child{
    padding-left:100%
  }

  .xxl\:last\:pt-0:last-child{
    padding-top:0
  }

  .xxl\:last\:pt-1:last-child{
    padding-top:4px
  }

  .xxl\:last\:pt-2:last-child{
    padding-top:8px
  }

  .xxl\:last\:pt-3:last-child{
    padding-top:12px
  }

  .xxl\:last\:pt-4:last-child{
    padding-top:16px
  }

  .xxl\:last\:pt-5:last-child{
    padding-top:20px
  }

  .xxl\:last\:pt-6:last-child{
    padding-top:24px
  }

  .xxl\:last\:pt-8:last-child{
    padding-top:32px
  }

  .xxl\:last\:pt-10:last-child{
    padding-top:40px
  }

  .xxl\:last\:pt-12:last-child{
    padding-top:48px
  }

  .xxl\:last\:pt-16:last-child{
    padding-top:64px
  }

  .xxl\:last\:pt-18:last-child{
    padding-top:72px
  }

  .xxl\:last\:pt-24:last-child{
    padding-top:96px
  }

  .xxl\:last\:pt-5dot5:last-child{
    padding-top:22px
  }

  .xxl\:last\:pt-full:last-child{
    padding-top:100%
  }

  .xxl\:last\:pr-0:last-child{
    padding-right:0
  }

  .xxl\:last\:pr-1:last-child{
    padding-right:4px
  }

  .xxl\:last\:pr-2:last-child{
    padding-right:8px
  }

  .xxl\:last\:pr-3:last-child{
    padding-right:12px
  }

  .xxl\:last\:pr-4:last-child{
    padding-right:16px
  }

  .xxl\:last\:pr-5:last-child{
    padding-right:20px
  }

  .xxl\:last\:pr-6:last-child{
    padding-right:24px
  }

  .xxl\:last\:pr-8:last-child{
    padding-right:32px
  }

  .xxl\:last\:pr-10:last-child{
    padding-right:40px
  }

  .xxl\:last\:pr-12:last-child{
    padding-right:48px
  }

  .xxl\:last\:pr-16:last-child{
    padding-right:64px
  }

  .xxl\:last\:pr-18:last-child{
    padding-right:72px
  }

  .xxl\:last\:pr-24:last-child{
    padding-right:96px
  }

  .xxl\:last\:pr-5dot5:last-child{
    padding-right:22px
  }

  .xxl\:last\:pr-full:last-child{
    padding-right:100%
  }

  .xxl\:last\:pb-0:last-child{
    padding-bottom:0
  }

  .xxl\:last\:pb-1:last-child{
    padding-bottom:4px
  }

  .xxl\:last\:pb-2:last-child{
    padding-bottom:8px
  }

  .xxl\:last\:pb-3:last-child{
    padding-bottom:12px
  }

  .xxl\:last\:pb-4:last-child{
    padding-bottom:16px
  }

  .xxl\:last\:pb-5:last-child{
    padding-bottom:20px
  }

  .xxl\:last\:pb-6:last-child{
    padding-bottom:24px
  }

  .xxl\:last\:pb-8:last-child{
    padding-bottom:32px
  }

  .xxl\:last\:pb-10:last-child{
    padding-bottom:40px
  }

  .xxl\:last\:pb-12:last-child{
    padding-bottom:48px
  }

  .xxl\:last\:pb-16:last-child{
    padding-bottom:64px
  }

  .xxl\:last\:pb-18:last-child{
    padding-bottom:72px
  }

  .xxl\:last\:pb-24:last-child{
    padding-bottom:96px
  }

  .xxl\:last\:pb-5dot5:last-child{
    padding-bottom:22px
  }

  .xxl\:last\:pb-full:last-child{
    padding-bottom:100%
  }

  .xxl\:last\:pl-0:last-child{
    padding-left:0
  }

  .xxl\:last\:pl-1:last-child{
    padding-left:4px
  }

  .xxl\:last\:pl-2:last-child{
    padding-left:8px
  }

  .xxl\:last\:pl-3:last-child{
    padding-left:12px
  }

  .xxl\:last\:pl-4:last-child{
    padding-left:16px
  }

  .xxl\:last\:pl-5:last-child{
    padding-left:20px
  }

  .xxl\:last\:pl-6:last-child{
    padding-left:24px
  }

  .xxl\:last\:pl-8:last-child{
    padding-left:32px
  }

  .xxl\:last\:pl-10:last-child{
    padding-left:40px
  }

  .xxl\:last\:pl-12:last-child{
    padding-left:48px
  }

  .xxl\:last\:pl-16:last-child{
    padding-left:64px
  }

  .xxl\:last\:pl-18:last-child{
    padding-left:72px
  }

  .xxl\:last\:pl-24:last-child{
    padding-left:96px
  }

  .xxl\:last\:pl-5dot5:last-child{
    padding-left:22px
  }

  .xxl\:last\:pl-full:last-child{
    padding-left:100%
  }

  .xxl\:text-left{
    text-align:left
  }

  .xxl\:text-center{
    text-align:center
  }

  .xxl\:text-right{
    text-align:right
  }

  .xxl\:text-justify{
    text-align:justify
  }

  .xxl\:align-baseline{
    vertical-align:baseline
  }

  .xxl\:align-top{
    vertical-align:top
  }

  .xxl\:align-middle{
    vertical-align:middle
  }

  .xxl\:align-bottom{
    vertical-align:bottom
  }

  .xxl\:align-text-top{
    vertical-align:text-top
  }

  .xxl\:align-text-bottom{
    vertical-align:text-bottom
  }

  .xxl\:text-xs{
    font-size:10px
  }

  .xxl\:text-s{
    font-size:12px
  }

  .xxl\:text-sm{
    font-size:14px
  }

  .xxl\:text-tiny{
    font-size:12px
  }

  .xxl\:text-base{
    font-size:16px
  }

  .xxl\:text-lg{
    font-size:20px
  }

  .xxl\:text-xl{
    font-size:24px
  }

  .xxl\:text-2xl{
    font-size:32px
  }

  .xxl\:text-3xl{
    font-size:40px
  }

  .xxl\:text-4xl{
    font-size:48px
  }

  .xxl\:text-5xl{
    font-size:64px
  }

  .xxl\:font-thin{
    font-weight:100
  }

  .xxl\:font-extralight{
    font-weight:200
  }

  .xxl\:font-light{
    font-weight:300
  }

  .xxl\:font-normal{
    font-weight:400
  }

  .xxl\:font-medium{
    font-weight:500
  }

  .xxl\:font-semibold{
    font-weight:600
  }

  .xxl\:font-bold{
    font-weight:700
  }

  .xxl\:font-extrabold{
    font-weight:800
  }

  .xxl\:font-black{
    font-weight:900
  }

  .xxl\:uppercase{
    text-transform:uppercase
  }

  .xxl\:lowercase{
    text-transform:lowercase
  }

  .xxl\:capitalize{
    text-transform:capitalize
  }

  .xxl\:normal-case{
    text-transform:none
  }

  .xxl\:italic{
    font-style:italic
  }

  .xxl\:not-italic{
    font-style:normal
  }

  .xxl\:ordinal, .xxl\:slashed-zero, .xxl\:lining-nums, .xxl\:oldstyle-nums, .xxl\:proportional-nums, .xxl\:tabular-nums, .xxl\:diagonal-fractions, .xxl\:stacked-fractions{
    --tw-ordinal:var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing:var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction:var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric:var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  .xxl\:normal-nums{
    font-variant-numeric:normal
  }

  .xxl\:ordinal{
    --tw-ordinal:ordinal
  }

  .xxl\:slashed-zero{
    --tw-slashed-zero:slashed-zero
  }

  .xxl\:lining-nums{
    --tw-numeric-figure:lining-nums
  }

  .xxl\:oldstyle-nums{
    --tw-numeric-figure:oldstyle-nums
  }

  .xxl\:proportional-nums{
    --tw-numeric-spacing:proportional-nums
  }

  .xxl\:tabular-nums{
    --tw-numeric-spacing:tabular-nums
  }

  .xxl\:diagonal-fractions{
    --tw-numeric-fraction:diagonal-fractions
  }

  .xxl\:stacked-fractions{
    --tw-numeric-fraction:stacked-fractions
  }

  .xxl\:leading-3{
    line-height:.75rem
  }

  .xxl\:leading-4{
    line-height:1rem
  }

  .xxl\:leading-5{
    line-height:1.25rem
  }

  .xxl\:leading-6{
    line-height:1.5rem
  }

  .xxl\:leading-7{
    line-height:1.75rem
  }

  .xxl\:leading-8{
    line-height:2rem
  }

  .xxl\:leading-9{
    line-height:2.25rem
  }

  .xxl\:leading-10{
    line-height:2.5rem
  }

  .xxl\:leading-none{
    line-height:1
  }

  .xxl\:leading-tight{
    line-height:1.25
  }

  .xxl\:leading-snug{
    line-height:1.375
  }

  .xxl\:leading-normal{
    line-height:1.5
  }

  .xxl\:leading-relaxed{
    line-height:1.625
  }

  .xxl\:leading-loose{
    line-height:2
  }

  .xxl\:text-red1{
    color:#CC352C
  }

  .xxl\:text-red2{
    color:#FF554B
  }

  .xxl\:text-red3{
    color:#FF7B73
  }

  .xxl\:text-red4{
    color:#FFF1F1
  }

  .xxl\:text-red5{
    color:#FFF6F6
  }

  .xxl\:text-cyan1{
    color:#009897
  }

  .xxl\:text-cyan2{
    color:#00BFBD
  }

  .xxl\:text-cyan3{
    color:#4CD1D0
  }

  .xxl\:text-cyan4{
    color:#B2ECEB
  }

  .xxl\:text-cyan5{
    color:#EDFAFA
  }

  .xxl\:text-bg1{
    color:#FAFAFC
  }

  .xxl\:text-bg2{
    color:#ffffff
  }

  .xxl\:text-bg3{
    color:#F7F7FA
  }

  .xxl\:text-gray1{
    color:#333333
  }

  .xxl\:text-gray2{
    color:#4D4D4D
  }

  .xxl\:text-gray3{
    color:#8C8C8C
  }

  .xxl\:text-gray4{
    color:#BFBFBF
  }

  .xxl\:text-gray5{
    color:#D9D9D9
  }

  .xxl\:text-gray6{
    color:#E9E9EB
  }

  .xxl\:text-gray7{
    color:#515164
  }

  .xxl\:text-white{
    color:#ffffff
  }

  .xxl\:text-warning{
    color:#FFC319
  }

  .xxl\:text-error{
    color:#EA696F
  }

  .xxl\:text-info{
    color:#0071B9
  }

  .xxl\:text-success{
    color:#98D45F
  }

  .xxl\:text-special4{
    color:#59779A
  }

  .xxl\:text-dark{
    color:#000000
  }

  .xxl\:text-transparent{
    color:transparent
  }

  .xxl\:text-secondary1{
    color:#313140
  }

  .xxl\:text-secondary2{
    color:#515164
  }

  .xxl\:text-secondary3{
    color:#9C9CAC
  }

  .xxl\:text-secondary4{
    color:#E5E8ED
  }

  .xxl\:text-secondary5{
    color:#F5F6F9
  }

  .xxl\:text-green1{
    color:#0DE298
  }

  .group:hover .xxl\:group-hover\:text-red1{
    color:#CC352C
  }

  .group:hover .xxl\:group-hover\:text-red2{
    color:#FF554B
  }

  .group:hover .xxl\:group-hover\:text-red3{
    color:#FF7B73
  }

  .group:hover .xxl\:group-hover\:text-red4{
    color:#FFF1F1
  }

  .group:hover .xxl\:group-hover\:text-red5{
    color:#FFF6F6
  }

  .group:hover .xxl\:group-hover\:text-cyan1{
    color:#009897
  }

  .group:hover .xxl\:group-hover\:text-cyan2{
    color:#00BFBD
  }

  .group:hover .xxl\:group-hover\:text-cyan3{
    color:#4CD1D0
  }

  .group:hover .xxl\:group-hover\:text-cyan4{
    color:#B2ECEB
  }

  .group:hover .xxl\:group-hover\:text-cyan5{
    color:#EDFAFA
  }

  .group:hover .xxl\:group-hover\:text-bg1{
    color:#FAFAFC
  }

  .group:hover .xxl\:group-hover\:text-bg2{
    color:#ffffff
  }

  .group:hover .xxl\:group-hover\:text-bg3{
    color:#F7F7FA
  }

  .group:hover .xxl\:group-hover\:text-gray1{
    color:#333333
  }

  .group:hover .xxl\:group-hover\:text-gray2{
    color:#4D4D4D
  }

  .group:hover .xxl\:group-hover\:text-gray3{
    color:#8C8C8C
  }

  .group:hover .xxl\:group-hover\:text-gray4{
    color:#BFBFBF
  }

  .group:hover .xxl\:group-hover\:text-gray5{
    color:#D9D9D9
  }

  .group:hover .xxl\:group-hover\:text-gray6{
    color:#E9E9EB
  }

  .group:hover .xxl\:group-hover\:text-gray7{
    color:#515164
  }

  .group:hover .xxl\:group-hover\:text-white{
    color:#ffffff
  }

  .group:hover .xxl\:group-hover\:text-warning{
    color:#FFC319
  }

  .group:hover .xxl\:group-hover\:text-error{
    color:#EA696F
  }

  .group:hover .xxl\:group-hover\:text-info{
    color:#0071B9
  }

  .group:hover .xxl\:group-hover\:text-success{
    color:#98D45F
  }

  .group:hover .xxl\:group-hover\:text-special4{
    color:#59779A
  }

  .group:hover .xxl\:group-hover\:text-dark{
    color:#000000
  }

  .group:hover .xxl\:group-hover\:text-transparent{
    color:transparent
  }

  .group:hover .xxl\:group-hover\:text-secondary1{
    color:#313140
  }

  .group:hover .xxl\:group-hover\:text-secondary2{
    color:#515164
  }

  .group:hover .xxl\:group-hover\:text-secondary3{
    color:#9C9CAC
  }

  .group:hover .xxl\:group-hover\:text-secondary4{
    color:#E5E8ED
  }

  .group:hover .xxl\:group-hover\:text-secondary5{
    color:#F5F6F9
  }

  .group:hover .xxl\:group-hover\:text-green1{
    color:#0DE298
  }

  .xxl\:focus-within\:text-red1:focus-within{
    color:#CC352C
  }

  .xxl\:focus-within\:text-red2:focus-within{
    color:#FF554B
  }

  .xxl\:focus-within\:text-red3:focus-within{
    color:#FF7B73
  }

  .xxl\:focus-within\:text-red4:focus-within{
    color:#FFF1F1
  }

  .xxl\:focus-within\:text-red5:focus-within{
    color:#FFF6F6
  }

  .xxl\:focus-within\:text-cyan1:focus-within{
    color:#009897
  }

  .xxl\:focus-within\:text-cyan2:focus-within{
    color:#00BFBD
  }

  .xxl\:focus-within\:text-cyan3:focus-within{
    color:#4CD1D0
  }

  .xxl\:focus-within\:text-cyan4:focus-within{
    color:#B2ECEB
  }

  .xxl\:focus-within\:text-cyan5:focus-within{
    color:#EDFAFA
  }

  .xxl\:focus-within\:text-bg1:focus-within{
    color:#FAFAFC
  }

  .xxl\:focus-within\:text-bg2:focus-within{
    color:#ffffff
  }

  .xxl\:focus-within\:text-bg3:focus-within{
    color:#F7F7FA
  }

  .xxl\:focus-within\:text-gray1:focus-within{
    color:#333333
  }

  .xxl\:focus-within\:text-gray2:focus-within{
    color:#4D4D4D
  }

  .xxl\:focus-within\:text-gray3:focus-within{
    color:#8C8C8C
  }

  .xxl\:focus-within\:text-gray4:focus-within{
    color:#BFBFBF
  }

  .xxl\:focus-within\:text-gray5:focus-within{
    color:#D9D9D9
  }

  .xxl\:focus-within\:text-gray6:focus-within{
    color:#E9E9EB
  }

  .xxl\:focus-within\:text-gray7:focus-within{
    color:#515164
  }

  .xxl\:focus-within\:text-white:focus-within{
    color:#ffffff
  }

  .xxl\:focus-within\:text-warning:focus-within{
    color:#FFC319
  }

  .xxl\:focus-within\:text-error:focus-within{
    color:#EA696F
  }

  .xxl\:focus-within\:text-info:focus-within{
    color:#0071B9
  }

  .xxl\:focus-within\:text-success:focus-within{
    color:#98D45F
  }

  .xxl\:focus-within\:text-special4:focus-within{
    color:#59779A
  }

  .xxl\:focus-within\:text-dark:focus-within{
    color:#000000
  }

  .xxl\:focus-within\:text-transparent:focus-within{
    color:transparent
  }

  .xxl\:focus-within\:text-secondary1:focus-within{
    color:#313140
  }

  .xxl\:focus-within\:text-secondary2:focus-within{
    color:#515164
  }

  .xxl\:focus-within\:text-secondary3:focus-within{
    color:#9C9CAC
  }

  .xxl\:focus-within\:text-secondary4:focus-within{
    color:#E5E8ED
  }

  .xxl\:focus-within\:text-secondary5:focus-within{
    color:#F5F6F9
  }

  .xxl\:focus-within\:text-green1:focus-within{
    color:#0DE298
  }

  .xxl\:hover\:text-red1:hover{
    color:#CC352C
  }

  .xxl\:hover\:text-red2:hover{
    color:#FF554B
  }

  .xxl\:hover\:text-red3:hover{
    color:#FF7B73
  }

  .xxl\:hover\:text-red4:hover{
    color:#FFF1F1
  }

  .xxl\:hover\:text-red5:hover{
    color:#FFF6F6
  }

  .xxl\:hover\:text-cyan1:hover{
    color:#009897
  }

  .xxl\:hover\:text-cyan2:hover{
    color:#00BFBD
  }

  .xxl\:hover\:text-cyan3:hover{
    color:#4CD1D0
  }

  .xxl\:hover\:text-cyan4:hover{
    color:#B2ECEB
  }

  .xxl\:hover\:text-cyan5:hover{
    color:#EDFAFA
  }

  .xxl\:hover\:text-bg1:hover{
    color:#FAFAFC
  }

  .xxl\:hover\:text-bg2:hover{
    color:#ffffff
  }

  .xxl\:hover\:text-bg3:hover{
    color:#F7F7FA
  }

  .xxl\:hover\:text-gray1:hover{
    color:#333333
  }

  .xxl\:hover\:text-gray2:hover{
    color:#4D4D4D
  }

  .xxl\:hover\:text-gray3:hover{
    color:#8C8C8C
  }

  .xxl\:hover\:text-gray4:hover{
    color:#BFBFBF
  }

  .xxl\:hover\:text-gray5:hover{
    color:#D9D9D9
  }

  .xxl\:hover\:text-gray6:hover{
    color:#E9E9EB
  }

  .xxl\:hover\:text-gray7:hover{
    color:#515164
  }

  .xxl\:hover\:text-white:hover{
    color:#ffffff
  }

  .xxl\:hover\:text-warning:hover{
    color:#FFC319
  }

  .xxl\:hover\:text-error:hover{
    color:#EA696F
  }

  .xxl\:hover\:text-info:hover{
    color:#0071B9
  }

  .xxl\:hover\:text-success:hover{
    color:#98D45F
  }

  .xxl\:hover\:text-special4:hover{
    color:#59779A
  }

  .xxl\:hover\:text-dark:hover{
    color:#000000
  }

  .xxl\:hover\:text-transparent:hover{
    color:transparent
  }

  .xxl\:hover\:text-secondary1:hover{
    color:#313140
  }

  .xxl\:hover\:text-secondary2:hover{
    color:#515164
  }

  .xxl\:hover\:text-secondary3:hover{
    color:#9C9CAC
  }

  .xxl\:hover\:text-secondary4:hover{
    color:#E5E8ED
  }

  .xxl\:hover\:text-secondary5:hover{
    color:#F5F6F9
  }

  .xxl\:hover\:text-green1:hover{
    color:#0DE298
  }

  .xxl\:focus\:text-red1:focus{
    color:#CC352C
  }

  .xxl\:focus\:text-red2:focus{
    color:#FF554B
  }

  .xxl\:focus\:text-red3:focus{
    color:#FF7B73
  }

  .xxl\:focus\:text-red4:focus{
    color:#FFF1F1
  }

  .xxl\:focus\:text-red5:focus{
    color:#FFF6F6
  }

  .xxl\:focus\:text-cyan1:focus{
    color:#009897
  }

  .xxl\:focus\:text-cyan2:focus{
    color:#00BFBD
  }

  .xxl\:focus\:text-cyan3:focus{
    color:#4CD1D0
  }

  .xxl\:focus\:text-cyan4:focus{
    color:#B2ECEB
  }

  .xxl\:focus\:text-cyan5:focus{
    color:#EDFAFA
  }

  .xxl\:focus\:text-bg1:focus{
    color:#FAFAFC
  }

  .xxl\:focus\:text-bg2:focus{
    color:#ffffff
  }

  .xxl\:focus\:text-bg3:focus{
    color:#F7F7FA
  }

  .xxl\:focus\:text-gray1:focus{
    color:#333333
  }

  .xxl\:focus\:text-gray2:focus{
    color:#4D4D4D
  }

  .xxl\:focus\:text-gray3:focus{
    color:#8C8C8C
  }

  .xxl\:focus\:text-gray4:focus{
    color:#BFBFBF
  }

  .xxl\:focus\:text-gray5:focus{
    color:#D9D9D9
  }

  .xxl\:focus\:text-gray6:focus{
    color:#E9E9EB
  }

  .xxl\:focus\:text-gray7:focus{
    color:#515164
  }

  .xxl\:focus\:text-white:focus{
    color:#ffffff
  }

  .xxl\:focus\:text-warning:focus{
    color:#FFC319
  }

  .xxl\:focus\:text-error:focus{
    color:#EA696F
  }

  .xxl\:focus\:text-info:focus{
    color:#0071B9
  }

  .xxl\:focus\:text-success:focus{
    color:#98D45F
  }

  .xxl\:focus\:text-special4:focus{
    color:#59779A
  }

  .xxl\:focus\:text-dark:focus{
    color:#000000
  }

  .xxl\:focus\:text-transparent:focus{
    color:transparent
  }

  .xxl\:focus\:text-secondary1:focus{
    color:#313140
  }

  .xxl\:focus\:text-secondary2:focus{
    color:#515164
  }

  .xxl\:focus\:text-secondary3:focus{
    color:#9C9CAC
  }

  .xxl\:focus\:text-secondary4:focus{
    color:#E5E8ED
  }

  .xxl\:focus\:text-secondary5:focus{
    color:#F5F6F9
  }

  .xxl\:focus\:text-green1:focus{
    color:#0DE298
  }

  .xxl\:underline{
    text-decoration:underline
  }

  .xxl\:line-through{
    text-decoration:line-through
  }

  .xxl\:no-underline{
    text-decoration:none
  }

  .group:hover .xxl\:group-hover\:underline{
    text-decoration:underline
  }

  .group:hover .xxl\:group-hover\:line-through{
    text-decoration:line-through
  }

  .group:hover .xxl\:group-hover\:no-underline{
    text-decoration:none
  }

  .xxl\:focus-within\:underline:focus-within{
    text-decoration:underline
  }

  .xxl\:focus-within\:line-through:focus-within{
    text-decoration:line-through
  }

  .xxl\:focus-within\:no-underline:focus-within{
    text-decoration:none
  }

  .xxl\:hover\:underline:hover{
    text-decoration:underline
  }

  .xxl\:hover\:line-through:hover{
    text-decoration:line-through
  }

  .xxl\:hover\:no-underline:hover{
    text-decoration:none
  }

  .xxl\:focus\:underline:focus{
    text-decoration:underline
  }

  .xxl\:focus\:line-through:focus{
    text-decoration:line-through
  }

  .xxl\:focus\:no-underline:focus{
    text-decoration:none
  }

  .xxl\:placeholder-red1::placeholder{
    color:#CC352C
  }

  .xxl\:placeholder-red2::placeholder{
    color:#FF554B
  }

  .xxl\:placeholder-red3::placeholder{
    color:#FF7B73
  }

  .xxl\:placeholder-red4::placeholder{
    color:#FFF1F1
  }

  .xxl\:placeholder-red5::placeholder{
    color:#FFF6F6
  }

  .xxl\:placeholder-cyan1::placeholder{
    color:#009897
  }

  .xxl\:placeholder-cyan2::placeholder{
    color:#00BFBD
  }

  .xxl\:placeholder-cyan3::placeholder{
    color:#4CD1D0
  }

  .xxl\:placeholder-cyan4::placeholder{
    color:#B2ECEB
  }

  .xxl\:placeholder-cyan5::placeholder{
    color:#EDFAFA
  }

  .xxl\:placeholder-bg1::placeholder{
    color:#FAFAFC
  }

  .xxl\:placeholder-bg2::placeholder{
    color:#ffffff
  }

  .xxl\:placeholder-bg3::placeholder{
    color:#F7F7FA
  }

  .xxl\:placeholder-gray1::placeholder{
    color:#333333
  }

  .xxl\:placeholder-gray2::placeholder{
    color:#4D4D4D
  }

  .xxl\:placeholder-gray3::placeholder{
    color:#8C8C8C
  }

  .xxl\:placeholder-gray4::placeholder{
    color:#BFBFBF
  }

  .xxl\:placeholder-gray5::placeholder{
    color:#D9D9D9
  }

  .xxl\:placeholder-gray6::placeholder{
    color:#E9E9EB
  }

  .xxl\:placeholder-gray7::placeholder{
    color:#515164
  }

  .xxl\:placeholder-white::placeholder{
    color:#ffffff
  }

  .xxl\:placeholder-warning::placeholder{
    color:#FFC319
  }

  .xxl\:placeholder-error::placeholder{
    color:#EA696F
  }

  .xxl\:placeholder-info::placeholder{
    color:#0071B9
  }

  .xxl\:placeholder-success::placeholder{
    color:#98D45F
  }

  .xxl\:placeholder-special4::placeholder{
    color:#59779A
  }

  .xxl\:placeholder-dark::placeholder{
    color:#000000
  }

  .xxl\:placeholder-transparent::placeholder{
    color:transparent
  }

  .xxl\:placeholder-secondary1::placeholder{
    color:#313140
  }

  .xxl\:placeholder-secondary2::placeholder{
    color:#515164
  }

  .xxl\:placeholder-secondary3::placeholder{
    color:#9C9CAC
  }

  .xxl\:placeholder-secondary4::placeholder{
    color:#E5E8ED
  }

  .xxl\:placeholder-secondary5::placeholder{
    color:#F5F6F9
  }

  .xxl\:placeholder-green1::placeholder{
    color:#0DE298
  }

  .xxl\:focus\:placeholder-red1:focus::placeholder{
    color:#CC352C
  }

  .xxl\:focus\:placeholder-red2:focus::placeholder{
    color:#FF554B
  }

  .xxl\:focus\:placeholder-red3:focus::placeholder{
    color:#FF7B73
  }

  .xxl\:focus\:placeholder-red4:focus::placeholder{
    color:#FFF1F1
  }

  .xxl\:focus\:placeholder-red5:focus::placeholder{
    color:#FFF6F6
  }

  .xxl\:focus\:placeholder-cyan1:focus::placeholder{
    color:#009897
  }

  .xxl\:focus\:placeholder-cyan2:focus::placeholder{
    color:#00BFBD
  }

  .xxl\:focus\:placeholder-cyan3:focus::placeholder{
    color:#4CD1D0
  }

  .xxl\:focus\:placeholder-cyan4:focus::placeholder{
    color:#B2ECEB
  }

  .xxl\:focus\:placeholder-cyan5:focus::placeholder{
    color:#EDFAFA
  }

  .xxl\:focus\:placeholder-bg1:focus::placeholder{
    color:#FAFAFC
  }

  .xxl\:focus\:placeholder-bg2:focus::placeholder{
    color:#ffffff
  }

  .xxl\:focus\:placeholder-bg3:focus::placeholder{
    color:#F7F7FA
  }

  .xxl\:focus\:placeholder-gray1:focus::placeholder{
    color:#333333
  }

  .xxl\:focus\:placeholder-gray2:focus::placeholder{
    color:#4D4D4D
  }

  .xxl\:focus\:placeholder-gray3:focus::placeholder{
    color:#8C8C8C
  }

  .xxl\:focus\:placeholder-gray4:focus::placeholder{
    color:#BFBFBF
  }

  .xxl\:focus\:placeholder-gray5:focus::placeholder{
    color:#D9D9D9
  }

  .xxl\:focus\:placeholder-gray6:focus::placeholder{
    color:#E9E9EB
  }

  .xxl\:focus\:placeholder-gray7:focus::placeholder{
    color:#515164
  }

  .xxl\:focus\:placeholder-white:focus::placeholder{
    color:#ffffff
  }

  .xxl\:focus\:placeholder-warning:focus::placeholder{
    color:#FFC319
  }

  .xxl\:focus\:placeholder-error:focus::placeholder{
    color:#EA696F
  }

  .xxl\:focus\:placeholder-info:focus::placeholder{
    color:#0071B9
  }

  .xxl\:focus\:placeholder-success:focus::placeholder{
    color:#98D45F
  }

  .xxl\:focus\:placeholder-special4:focus::placeholder{
    color:#59779A
  }

  .xxl\:focus\:placeholder-dark:focus::placeholder{
    color:#000000
  }

  .xxl\:focus\:placeholder-transparent:focus::placeholder{
    color:transparent
  }

  .xxl\:focus\:placeholder-secondary1:focus::placeholder{
    color:#313140
  }

  .xxl\:focus\:placeholder-secondary2:focus::placeholder{
    color:#515164
  }

  .xxl\:focus\:placeholder-secondary3:focus::placeholder{
    color:#9C9CAC
  }

  .xxl\:focus\:placeholder-secondary4:focus::placeholder{
    color:#E5E8ED
  }

  .xxl\:focus\:placeholder-secondary5:focus::placeholder{
    color:#F5F6F9
  }

  .xxl\:focus\:placeholder-green1:focus::placeholder{
    color:#0DE298
  }

  .xxl\:opacity-0{
    opacity:0
  }

  .xxl\:opacity-5{
    opacity:0.05
  }

  .xxl\:opacity-10{
    opacity:0.1
  }

  .xxl\:opacity-20{
    opacity:0.2
  }

  .xxl\:opacity-25{
    opacity:0.25
  }

  .xxl\:opacity-30{
    opacity:0.3
  }

  .xxl\:opacity-40{
    opacity:0.4
  }

  .xxl\:opacity-50{
    opacity:0.5
  }

  .xxl\:opacity-60{
    opacity:0.6
  }

  .xxl\:opacity-70{
    opacity:0.7
  }

  .xxl\:opacity-75{
    opacity:0.75
  }

  .xxl\:opacity-80{
    opacity:0.8
  }

  .xxl\:opacity-90{
    opacity:0.9
  }

  .xxl\:opacity-95{
    opacity:0.95
  }

  .xxl\:opacity-100{
    opacity:1
  }

  .group:hover .xxl\:group-hover\:opacity-0{
    opacity:0
  }

  .group:hover .xxl\:group-hover\:opacity-5{
    opacity:0.05
  }

  .group:hover .xxl\:group-hover\:opacity-10{
    opacity:0.1
  }

  .group:hover .xxl\:group-hover\:opacity-20{
    opacity:0.2
  }

  .group:hover .xxl\:group-hover\:opacity-25{
    opacity:0.25
  }

  .group:hover .xxl\:group-hover\:opacity-30{
    opacity:0.3
  }

  .group:hover .xxl\:group-hover\:opacity-40{
    opacity:0.4
  }

  .group:hover .xxl\:group-hover\:opacity-50{
    opacity:0.5
  }

  .group:hover .xxl\:group-hover\:opacity-60{
    opacity:0.6
  }

  .group:hover .xxl\:group-hover\:opacity-70{
    opacity:0.7
  }

  .group:hover .xxl\:group-hover\:opacity-75{
    opacity:0.75
  }

  .group:hover .xxl\:group-hover\:opacity-80{
    opacity:0.8
  }

  .group:hover .xxl\:group-hover\:opacity-90{
    opacity:0.9
  }

  .group:hover .xxl\:group-hover\:opacity-95{
    opacity:0.95
  }

  .group:hover .xxl\:group-hover\:opacity-100{
    opacity:1
  }

  .xxl\:focus-within\:opacity-0:focus-within{
    opacity:0
  }

  .xxl\:focus-within\:opacity-5:focus-within{
    opacity:0.05
  }

  .xxl\:focus-within\:opacity-10:focus-within{
    opacity:0.1
  }

  .xxl\:focus-within\:opacity-20:focus-within{
    opacity:0.2
  }

  .xxl\:focus-within\:opacity-25:focus-within{
    opacity:0.25
  }

  .xxl\:focus-within\:opacity-30:focus-within{
    opacity:0.3
  }

  .xxl\:focus-within\:opacity-40:focus-within{
    opacity:0.4
  }

  .xxl\:focus-within\:opacity-50:focus-within{
    opacity:0.5
  }

  .xxl\:focus-within\:opacity-60:focus-within{
    opacity:0.6
  }

  .xxl\:focus-within\:opacity-70:focus-within{
    opacity:0.7
  }

  .xxl\:focus-within\:opacity-75:focus-within{
    opacity:0.75
  }

  .xxl\:focus-within\:opacity-80:focus-within{
    opacity:0.8
  }

  .xxl\:focus-within\:opacity-90:focus-within{
    opacity:0.9
  }

  .xxl\:focus-within\:opacity-95:focus-within{
    opacity:0.95
  }

  .xxl\:focus-within\:opacity-100:focus-within{
    opacity:1
  }

  .xxl\:hover\:opacity-0:hover{
    opacity:0
  }

  .xxl\:hover\:opacity-5:hover{
    opacity:0.05
  }

  .xxl\:hover\:opacity-10:hover{
    opacity:0.1
  }

  .xxl\:hover\:opacity-20:hover{
    opacity:0.2
  }

  .xxl\:hover\:opacity-25:hover{
    opacity:0.25
  }

  .xxl\:hover\:opacity-30:hover{
    opacity:0.3
  }

  .xxl\:hover\:opacity-40:hover{
    opacity:0.4
  }

  .xxl\:hover\:opacity-50:hover{
    opacity:0.5
  }

  .xxl\:hover\:opacity-60:hover{
    opacity:0.6
  }

  .xxl\:hover\:opacity-70:hover{
    opacity:0.7
  }

  .xxl\:hover\:opacity-75:hover{
    opacity:0.75
  }

  .xxl\:hover\:opacity-80:hover{
    opacity:0.8
  }

  .xxl\:hover\:opacity-90:hover{
    opacity:0.9
  }

  .xxl\:hover\:opacity-95:hover{
    opacity:0.95
  }

  .xxl\:hover\:opacity-100:hover{
    opacity:1
  }

  .xxl\:focus\:opacity-0:focus{
    opacity:0
  }

  .xxl\:focus\:opacity-5:focus{
    opacity:0.05
  }

  .xxl\:focus\:opacity-10:focus{
    opacity:0.1
  }

  .xxl\:focus\:opacity-20:focus{
    opacity:0.2
  }

  .xxl\:focus\:opacity-25:focus{
    opacity:0.25
  }

  .xxl\:focus\:opacity-30:focus{
    opacity:0.3
  }

  .xxl\:focus\:opacity-40:focus{
    opacity:0.4
  }

  .xxl\:focus\:opacity-50:focus{
    opacity:0.5
  }

  .xxl\:focus\:opacity-60:focus{
    opacity:0.6
  }

  .xxl\:focus\:opacity-70:focus{
    opacity:0.7
  }

  .xxl\:focus\:opacity-75:focus{
    opacity:0.75
  }

  .xxl\:focus\:opacity-80:focus{
    opacity:0.8
  }

  .xxl\:focus\:opacity-90:focus{
    opacity:0.9
  }

  .xxl\:focus\:opacity-95:focus{
    opacity:0.95
  }

  .xxl\:focus\:opacity-100:focus{
    opacity:1
  }

  .xxl\:bg-blend-normal{
    background-blend-mode:normal
  }

  .xxl\:bg-blend-multiply{
    background-blend-mode:multiply
  }

  .xxl\:bg-blend-screen{
    background-blend-mode:screen
  }

  .xxl\:bg-blend-overlay{
    background-blend-mode:overlay
  }

  .xxl\:bg-blend-darken{
    background-blend-mode:darken
  }

  .xxl\:bg-blend-lighten{
    background-blend-mode:lighten
  }

  .xxl\:bg-blend-color-dodge{
    background-blend-mode:color-dodge
  }

  .xxl\:bg-blend-color-burn{
    background-blend-mode:color-burn
  }

  .xxl\:bg-blend-hard-light{
    background-blend-mode:hard-light
  }

  .xxl\:bg-blend-soft-light{
    background-blend-mode:soft-light
  }

  .xxl\:bg-blend-difference{
    background-blend-mode:difference
  }

  .xxl\:bg-blend-exclusion{
    background-blend-mode:exclusion
  }

  .xxl\:bg-blend-hue{
    background-blend-mode:hue
  }

  .xxl\:bg-blend-saturation{
    background-blend-mode:saturation
  }

  .xxl\:bg-blend-color{
    background-blend-mode:color
  }

  .xxl\:bg-blend-luminosity{
    background-blend-mode:luminosity
  }

  .xxl\:mix-blend-normal{
    mix-blend-mode:normal
  }

  .xxl\:mix-blend-multiply{
    mix-blend-mode:multiply
  }

  .xxl\:mix-blend-screen{
    mix-blend-mode:screen
  }

  .xxl\:mix-blend-overlay{
    mix-blend-mode:overlay
  }

  .xxl\:mix-blend-darken{
    mix-blend-mode:darken
  }

  .xxl\:mix-blend-lighten{
    mix-blend-mode:lighten
  }

  .xxl\:mix-blend-color-dodge{
    mix-blend-mode:color-dodge
  }

  .xxl\:mix-blend-color-burn{
    mix-blend-mode:color-burn
  }

  .xxl\:mix-blend-hard-light{
    mix-blend-mode:hard-light
  }

  .xxl\:mix-blend-soft-light{
    mix-blend-mode:soft-light
  }

  .xxl\:mix-blend-difference{
    mix-blend-mode:difference
  }

  .xxl\:mix-blend-exclusion{
    mix-blend-mode:exclusion
  }

  .xxl\:mix-blend-hue{
    mix-blend-mode:hue
  }

  .xxl\:mix-blend-saturation{
    mix-blend-mode:saturation
  }

  .xxl\:mix-blend-color{
    mix-blend-mode:color
  }

  .xxl\:mix-blend-luminosity{
    mix-blend-mode:luminosity
  }

  .xxl\:shadow-panel{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:shadow-hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:shadow-popup{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xxl\:group-hover\:shadow-panel{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xxl\:group-hover\:shadow-hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .group:hover .xxl\:group-hover\:shadow-popup{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:focus-within\:shadow-panel:focus-within{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:focus-within\:shadow-hover:focus-within{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:focus-within\:shadow-popup:focus-within{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:hover\:shadow-panel:hover{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:hover\:shadow-hover:hover{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:hover\:shadow-popup:hover{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:focus\:shadow-panel:focus{
    --tw-shadow:0px 2px 12px rgba(0, 40, 117, 0.06);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:focus\:shadow-hover:focus{
    --tw-shadow:0px 2px 8px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:focus\:shadow-popup:focus{
    --tw-shadow:0px 5px 25px rgba(0, 40, 117, 0.1);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .xxl\:outline-none{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .xxl\:outline-white{
    outline:2px dotted white;
    outline-offset:2px
  }

  .xxl\:outline-black{
    outline:2px dotted black;
    outline-offset:2px
  }

  .xxl\:focus-within\:outline-none:focus-within{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .xxl\:focus-within\:outline-white:focus-within{
    outline:2px dotted white;
    outline-offset:2px
  }

  .xxl\:focus-within\:outline-black:focus-within{
    outline:2px dotted black;
    outline-offset:2px
  }

  .xxl\:focus\:outline-none:focus{
    outline:2px solid transparent;
    outline-offset:2px
  }

  .xxl\:focus\:outline-white:focus{
    outline:2px dotted white;
    outline-offset:2px
  }

  .xxl\:focus\:outline-black:focus{
    outline:2px dotted black;
    outline-offset:2px
  }

  .xxl\:ring-0{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:ring-1{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:ring-2{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:ring-4{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:ring-8{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:ring{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus-within\:ring-0:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus-within\:ring-1:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus-within\:ring-4:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus-within\:ring-8:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus-within\:ring:focus-within{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus\:ring-0:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus\:ring-1:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus\:ring-2:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus\:ring-4:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus\:ring-8:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:focus\:ring:focus{
    --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  .xxl\:ring-inset{
    --tw-ring-inset:inset
  }

  .xxl\:focus-within\:ring-inset:focus-within{
    --tw-ring-inset:inset
  }

  .xxl\:focus\:ring-inset:focus{
    --tw-ring-inset:inset
  }

  .xxl\:ring-red1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .xxl\:ring-red2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .xxl\:ring-red3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .xxl\:ring-red4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .xxl\:ring-red5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .xxl\:ring-cyan1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .xxl\:ring-cyan2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .xxl\:ring-cyan3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .xxl\:ring-cyan4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .xxl\:ring-cyan5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .xxl\:ring-bg1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .xxl\:ring-bg2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xxl\:ring-bg3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .xxl\:ring-gray1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .xxl\:ring-gray2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .xxl\:ring-gray3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .xxl\:ring-gray4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .xxl\:ring-gray5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .xxl\:ring-gray6{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .xxl\:ring-gray7{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xxl\:ring-white{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xxl\:ring-warning{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .xxl\:ring-error{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .xxl\:ring-info{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .xxl\:ring-success{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .xxl\:ring-special4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .xxl\:ring-dark{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .xxl\:ring-transparent{
    --tw-ring-color:transparent
  }

  .xxl\:ring-secondary1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .xxl\:ring-secondary2{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xxl\:ring-secondary3{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .xxl\:ring-secondary4{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .xxl\:ring-secondary5{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .xxl\:ring-green1{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-red1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-red2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-red3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-red4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-red5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-cyan1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-cyan2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-cyan3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-cyan4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-cyan5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-bg1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-bg2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-bg3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-gray1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-gray2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-gray3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-gray4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-gray5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-gray6:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-gray7:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-white:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-warning:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-error:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-info:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-success:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-special4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-dark:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-transparent:focus-within{
    --tw-ring-color:transparent
  }

  .xxl\:focus-within\:ring-secondary1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-secondary2:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-secondary3:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-secondary4:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-secondary5:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .xxl\:focus-within\:ring-green1:focus-within{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-red1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(204, 53, 44, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-red2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 85, 75, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-red3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 123, 115, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-red4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 241, 241, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-red5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 246, 246, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-cyan1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 152, 151, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-cyan2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 191, 189, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-cyan3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(76, 209, 208, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-cyan4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(178, 236, 235, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-cyan5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(237, 250, 250, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-bg1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(250, 250, 252, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-bg2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-bg3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(247, 247, 250, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-gray1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(51, 51, 51, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-gray2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(77, 77, 77, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-gray3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(140, 140, 140, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-gray4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(191, 191, 191, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-gray5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(217, 217, 217, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-gray6:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(233, 233, 235, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-gray7:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-white:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-warning:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(255, 195, 25, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-error:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(234, 105, 111, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-info:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 113, 185, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-success:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(152, 212, 95, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-special4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(89, 119, 154, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-dark:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-transparent:focus{
    --tw-ring-color:transparent
  }

  .xxl\:focus\:ring-secondary1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(49, 49, 64, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-secondary2:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(81, 81, 100, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-secondary3:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(156, 156, 172, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-secondary4:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(229, 232, 237, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-secondary5:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(245, 246, 249, var(--tw-ring-opacity))
  }

  .xxl\:focus\:ring-green1:focus{
    --tw-ring-opacity:1;
    --tw-ring-color:rgba(13, 226, 152, var(--tw-ring-opacity))
  }

  .xxl\:ring-opacity-0{
    --tw-ring-opacity:0
  }

  .xxl\:ring-opacity-5{
    --tw-ring-opacity:0.05
  }

  .xxl\:ring-opacity-10{
    --tw-ring-opacity:0.1
  }

  .xxl\:ring-opacity-20{
    --tw-ring-opacity:0.2
  }

  .xxl\:ring-opacity-25{
    --tw-ring-opacity:0.25
  }

  .xxl\:ring-opacity-30{
    --tw-ring-opacity:0.3
  }

  .xxl\:ring-opacity-40{
    --tw-ring-opacity:0.4
  }

  .xxl\:ring-opacity-50{
    --tw-ring-opacity:0.5
  }

  .xxl\:ring-opacity-60{
    --tw-ring-opacity:0.6
  }

  .xxl\:ring-opacity-70{
    --tw-ring-opacity:0.7
  }

  .xxl\:ring-opacity-75{
    --tw-ring-opacity:0.75
  }

  .xxl\:ring-opacity-80{
    --tw-ring-opacity:0.8
  }

  .xxl\:ring-opacity-90{
    --tw-ring-opacity:0.9
  }

  .xxl\:ring-opacity-95{
    --tw-ring-opacity:0.95
  }

  .xxl\:ring-opacity-100{
    --tw-ring-opacity:1
  }

  .xxl\:focus-within\:ring-opacity-0:focus-within{
    --tw-ring-opacity:0
  }

  .xxl\:focus-within\:ring-opacity-5:focus-within{
    --tw-ring-opacity:0.05
  }

  .xxl\:focus-within\:ring-opacity-10:focus-within{
    --tw-ring-opacity:0.1
  }

  .xxl\:focus-within\:ring-opacity-20:focus-within{
    --tw-ring-opacity:0.2
  }

  .xxl\:focus-within\:ring-opacity-25:focus-within{
    --tw-ring-opacity:0.25
  }

  .xxl\:focus-within\:ring-opacity-30:focus-within{
    --tw-ring-opacity:0.3
  }

  .xxl\:focus-within\:ring-opacity-40:focus-within{
    --tw-ring-opacity:0.4
  }

  .xxl\:focus-within\:ring-opacity-50:focus-within{
    --tw-ring-opacity:0.5
  }

  .xxl\:focus-within\:ring-opacity-60:focus-within{
    --tw-ring-opacity:0.6
  }

  .xxl\:focus-within\:ring-opacity-70:focus-within{
    --tw-ring-opacity:0.7
  }

  .xxl\:focus-within\:ring-opacity-75:focus-within{
    --tw-ring-opacity:0.75
  }

  .xxl\:focus-within\:ring-opacity-80:focus-within{
    --tw-ring-opacity:0.8
  }

  .xxl\:focus-within\:ring-opacity-90:focus-within{
    --tw-ring-opacity:0.9
  }

  .xxl\:focus-within\:ring-opacity-95:focus-within{
    --tw-ring-opacity:0.95
  }

  .xxl\:focus-within\:ring-opacity-100:focus-within{
    --tw-ring-opacity:1
  }

  .xxl\:focus\:ring-opacity-0:focus{
    --tw-ring-opacity:0
  }

  .xxl\:focus\:ring-opacity-5:focus{
    --tw-ring-opacity:0.05
  }

  .xxl\:focus\:ring-opacity-10:focus{
    --tw-ring-opacity:0.1
  }

  .xxl\:focus\:ring-opacity-20:focus{
    --tw-ring-opacity:0.2
  }

  .xxl\:focus\:ring-opacity-25:focus{
    --tw-ring-opacity:0.25
  }

  .xxl\:focus\:ring-opacity-30:focus{
    --tw-ring-opacity:0.3
  }

  .xxl\:focus\:ring-opacity-40:focus{
    --tw-ring-opacity:0.4
  }

  .xxl\:focus\:ring-opacity-50:focus{
    --tw-ring-opacity:0.5
  }

  .xxl\:focus\:ring-opacity-60:focus{
    --tw-ring-opacity:0.6
  }

  .xxl\:focus\:ring-opacity-70:focus{
    --tw-ring-opacity:0.7
  }

  .xxl\:focus\:ring-opacity-75:focus{
    --tw-ring-opacity:0.75
  }

  .xxl\:focus\:ring-opacity-80:focus{
    --tw-ring-opacity:0.8
  }

  .xxl\:focus\:ring-opacity-90:focus{
    --tw-ring-opacity:0.9
  }

  .xxl\:focus\:ring-opacity-95:focus{
    --tw-ring-opacity:0.95
  }

  .xxl\:focus\:ring-opacity-100:focus{
    --tw-ring-opacity:1
  }

  .xxl\:ring-offset-0{
    --tw-ring-offset-width:0px
  }

  .xxl\:ring-offset-1{
    --tw-ring-offset-width:1px
  }

  .xxl\:ring-offset-2{
    --tw-ring-offset-width:2px
  }

  .xxl\:ring-offset-4{
    --tw-ring-offset-width:4px
  }

  .xxl\:ring-offset-8{
    --tw-ring-offset-width:8px
  }

  .xxl\:focus-within\:ring-offset-0:focus-within{
    --tw-ring-offset-width:0px
  }

  .xxl\:focus-within\:ring-offset-1:focus-within{
    --tw-ring-offset-width:1px
  }

  .xxl\:focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width:2px
  }

  .xxl\:focus-within\:ring-offset-4:focus-within{
    --tw-ring-offset-width:4px
  }

  .xxl\:focus-within\:ring-offset-8:focus-within{
    --tw-ring-offset-width:8px
  }

  .xxl\:focus\:ring-offset-0:focus{
    --tw-ring-offset-width:0px
  }

  .xxl\:focus\:ring-offset-1:focus{
    --tw-ring-offset-width:1px
  }

  .xxl\:focus\:ring-offset-2:focus{
    --tw-ring-offset-width:2px
  }

  .xxl\:focus\:ring-offset-4:focus{
    --tw-ring-offset-width:4px
  }

  .xxl\:focus\:ring-offset-8:focus{
    --tw-ring-offset-width:8px
  }

  .xxl\:ring-offset-red1{
    --tw-ring-offset-color:#CC352C
  }

  .xxl\:ring-offset-red2{
    --tw-ring-offset-color:#FF554B
  }

  .xxl\:ring-offset-red3{
    --tw-ring-offset-color:#FF7B73
  }

  .xxl\:ring-offset-red4{
    --tw-ring-offset-color:#FFF1F1
  }

  .xxl\:ring-offset-red5{
    --tw-ring-offset-color:#FFF6F6
  }

  .xxl\:ring-offset-cyan1{
    --tw-ring-offset-color:#009897
  }

  .xxl\:ring-offset-cyan2{
    --tw-ring-offset-color:#00BFBD
  }

  .xxl\:ring-offset-cyan3{
    --tw-ring-offset-color:#4CD1D0
  }

  .xxl\:ring-offset-cyan4{
    --tw-ring-offset-color:#B2ECEB
  }

  .xxl\:ring-offset-cyan5{
    --tw-ring-offset-color:#EDFAFA
  }

  .xxl\:ring-offset-bg1{
    --tw-ring-offset-color:#FAFAFC
  }

  .xxl\:ring-offset-bg2{
    --tw-ring-offset-color:#ffffff
  }

  .xxl\:ring-offset-bg3{
    --tw-ring-offset-color:#F7F7FA
  }

  .xxl\:ring-offset-gray1{
    --tw-ring-offset-color:#333333
  }

  .xxl\:ring-offset-gray2{
    --tw-ring-offset-color:#4D4D4D
  }

  .xxl\:ring-offset-gray3{
    --tw-ring-offset-color:#8C8C8C
  }

  .xxl\:ring-offset-gray4{
    --tw-ring-offset-color:#BFBFBF
  }

  .xxl\:ring-offset-gray5{
    --tw-ring-offset-color:#D9D9D9
  }

  .xxl\:ring-offset-gray6{
    --tw-ring-offset-color:#E9E9EB
  }

  .xxl\:ring-offset-gray7{
    --tw-ring-offset-color:#515164
  }

  .xxl\:ring-offset-white{
    --tw-ring-offset-color:#ffffff
  }

  .xxl\:ring-offset-warning{
    --tw-ring-offset-color:#FFC319
  }

  .xxl\:ring-offset-error{
    --tw-ring-offset-color:#EA696F
  }

  .xxl\:ring-offset-info{
    --tw-ring-offset-color:#0071B9
  }

  .xxl\:ring-offset-success{
    --tw-ring-offset-color:#98D45F
  }

  .xxl\:ring-offset-special4{
    --tw-ring-offset-color:#59779A
  }

  .xxl\:ring-offset-dark{
    --tw-ring-offset-color:#000000
  }

  .xxl\:ring-offset-transparent{
    --tw-ring-offset-color:transparent
  }

  .xxl\:ring-offset-secondary1{
    --tw-ring-offset-color:#313140
  }

  .xxl\:ring-offset-secondary2{
    --tw-ring-offset-color:#515164
  }

  .xxl\:ring-offset-secondary3{
    --tw-ring-offset-color:#9C9CAC
  }

  .xxl\:ring-offset-secondary4{
    --tw-ring-offset-color:#E5E8ED
  }

  .xxl\:ring-offset-secondary5{
    --tw-ring-offset-color:#F5F6F9
  }

  .xxl\:ring-offset-green1{
    --tw-ring-offset-color:#0DE298
  }

  .xxl\:focus-within\:ring-offset-red1:focus-within{
    --tw-ring-offset-color:#CC352C
  }

  .xxl\:focus-within\:ring-offset-red2:focus-within{
    --tw-ring-offset-color:#FF554B
  }

  .xxl\:focus-within\:ring-offset-red3:focus-within{
    --tw-ring-offset-color:#FF7B73
  }

  .xxl\:focus-within\:ring-offset-red4:focus-within{
    --tw-ring-offset-color:#FFF1F1
  }

  .xxl\:focus-within\:ring-offset-red5:focus-within{
    --tw-ring-offset-color:#FFF6F6
  }

  .xxl\:focus-within\:ring-offset-cyan1:focus-within{
    --tw-ring-offset-color:#009897
  }

  .xxl\:focus-within\:ring-offset-cyan2:focus-within{
    --tw-ring-offset-color:#00BFBD
  }

  .xxl\:focus-within\:ring-offset-cyan3:focus-within{
    --tw-ring-offset-color:#4CD1D0
  }

  .xxl\:focus-within\:ring-offset-cyan4:focus-within{
    --tw-ring-offset-color:#B2ECEB
  }

  .xxl\:focus-within\:ring-offset-cyan5:focus-within{
    --tw-ring-offset-color:#EDFAFA
  }

  .xxl\:focus-within\:ring-offset-bg1:focus-within{
    --tw-ring-offset-color:#FAFAFC
  }

  .xxl\:focus-within\:ring-offset-bg2:focus-within{
    --tw-ring-offset-color:#ffffff
  }

  .xxl\:focus-within\:ring-offset-bg3:focus-within{
    --tw-ring-offset-color:#F7F7FA
  }

  .xxl\:focus-within\:ring-offset-gray1:focus-within{
    --tw-ring-offset-color:#333333
  }

  .xxl\:focus-within\:ring-offset-gray2:focus-within{
    --tw-ring-offset-color:#4D4D4D
  }

  .xxl\:focus-within\:ring-offset-gray3:focus-within{
    --tw-ring-offset-color:#8C8C8C
  }

  .xxl\:focus-within\:ring-offset-gray4:focus-within{
    --tw-ring-offset-color:#BFBFBF
  }

  .xxl\:focus-within\:ring-offset-gray5:focus-within{
    --tw-ring-offset-color:#D9D9D9
  }

  .xxl\:focus-within\:ring-offset-gray6:focus-within{
    --tw-ring-offset-color:#E9E9EB
  }

  .xxl\:focus-within\:ring-offset-gray7:focus-within{
    --tw-ring-offset-color:#515164
  }

  .xxl\:focus-within\:ring-offset-white:focus-within{
    --tw-ring-offset-color:#ffffff
  }

  .xxl\:focus-within\:ring-offset-warning:focus-within{
    --tw-ring-offset-color:#FFC319
  }

  .xxl\:focus-within\:ring-offset-error:focus-within{
    --tw-ring-offset-color:#EA696F
  }

  .xxl\:focus-within\:ring-offset-info:focus-within{
    --tw-ring-offset-color:#0071B9
  }

  .xxl\:focus-within\:ring-offset-success:focus-within{
    --tw-ring-offset-color:#98D45F
  }

  .xxl\:focus-within\:ring-offset-special4:focus-within{
    --tw-ring-offset-color:#59779A
  }

  .xxl\:focus-within\:ring-offset-dark:focus-within{
    --tw-ring-offset-color:#000000
  }

  .xxl\:focus-within\:ring-offset-transparent:focus-within{
    --tw-ring-offset-color:transparent
  }

  .xxl\:focus-within\:ring-offset-secondary1:focus-within{
    --tw-ring-offset-color:#313140
  }

  .xxl\:focus-within\:ring-offset-secondary2:focus-within{
    --tw-ring-offset-color:#515164
  }

  .xxl\:focus-within\:ring-offset-secondary3:focus-within{
    --tw-ring-offset-color:#9C9CAC
  }

  .xxl\:focus-within\:ring-offset-secondary4:focus-within{
    --tw-ring-offset-color:#E5E8ED
  }

  .xxl\:focus-within\:ring-offset-secondary5:focus-within{
    --tw-ring-offset-color:#F5F6F9
  }

  .xxl\:focus-within\:ring-offset-green1:focus-within{
    --tw-ring-offset-color:#0DE298
  }

  .xxl\:focus\:ring-offset-red1:focus{
    --tw-ring-offset-color:#CC352C
  }

  .xxl\:focus\:ring-offset-red2:focus{
    --tw-ring-offset-color:#FF554B
  }

  .xxl\:focus\:ring-offset-red3:focus{
    --tw-ring-offset-color:#FF7B73
  }

  .xxl\:focus\:ring-offset-red4:focus{
    --tw-ring-offset-color:#FFF1F1
  }

  .xxl\:focus\:ring-offset-red5:focus{
    --tw-ring-offset-color:#FFF6F6
  }

  .xxl\:focus\:ring-offset-cyan1:focus{
    --tw-ring-offset-color:#009897
  }

  .xxl\:focus\:ring-offset-cyan2:focus{
    --tw-ring-offset-color:#00BFBD
  }

  .xxl\:focus\:ring-offset-cyan3:focus{
    --tw-ring-offset-color:#4CD1D0
  }

  .xxl\:focus\:ring-offset-cyan4:focus{
    --tw-ring-offset-color:#B2ECEB
  }

  .xxl\:focus\:ring-offset-cyan5:focus{
    --tw-ring-offset-color:#EDFAFA
  }

  .xxl\:focus\:ring-offset-bg1:focus{
    --tw-ring-offset-color:#FAFAFC
  }

  .xxl\:focus\:ring-offset-bg2:focus{
    --tw-ring-offset-color:#ffffff
  }

  .xxl\:focus\:ring-offset-bg3:focus{
    --tw-ring-offset-color:#F7F7FA
  }

  .xxl\:focus\:ring-offset-gray1:focus{
    --tw-ring-offset-color:#333333
  }

  .xxl\:focus\:ring-offset-gray2:focus{
    --tw-ring-offset-color:#4D4D4D
  }

  .xxl\:focus\:ring-offset-gray3:focus{
    --tw-ring-offset-color:#8C8C8C
  }

  .xxl\:focus\:ring-offset-gray4:focus{
    --tw-ring-offset-color:#BFBFBF
  }

  .xxl\:focus\:ring-offset-gray5:focus{
    --tw-ring-offset-color:#D9D9D9
  }

  .xxl\:focus\:ring-offset-gray6:focus{
    --tw-ring-offset-color:#E9E9EB
  }

  .xxl\:focus\:ring-offset-gray7:focus{
    --tw-ring-offset-color:#515164
  }

  .xxl\:focus\:ring-offset-white:focus{
    --tw-ring-offset-color:#ffffff
  }

  .xxl\:focus\:ring-offset-warning:focus{
    --tw-ring-offset-color:#FFC319
  }

  .xxl\:focus\:ring-offset-error:focus{
    --tw-ring-offset-color:#EA696F
  }

  .xxl\:focus\:ring-offset-info:focus{
    --tw-ring-offset-color:#0071B9
  }

  .xxl\:focus\:ring-offset-success:focus{
    --tw-ring-offset-color:#98D45F
  }

  .xxl\:focus\:ring-offset-special4:focus{
    --tw-ring-offset-color:#59779A
  }

  .xxl\:focus\:ring-offset-dark:focus{
    --tw-ring-offset-color:#000000
  }

  .xxl\:focus\:ring-offset-transparent:focus{
    --tw-ring-offset-color:transparent
  }

  .xxl\:focus\:ring-offset-secondary1:focus{
    --tw-ring-offset-color:#313140
  }

  .xxl\:focus\:ring-offset-secondary2:focus{
    --tw-ring-offset-color:#515164
  }

  .xxl\:focus\:ring-offset-secondary3:focus{
    --tw-ring-offset-color:#9C9CAC
  }

  .xxl\:focus\:ring-offset-secondary4:focus{
    --tw-ring-offset-color:#E5E8ED
  }

  .xxl\:focus\:ring-offset-secondary5:focus{
    --tw-ring-offset-color:#F5F6F9
  }

  .xxl\:focus\:ring-offset-green1:focus{
    --tw-ring-offset-color:#0DE298
  }

  .xxl\:filter{
    --tw-blur:var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness:var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast:var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale:var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
    --tw-invert:var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate:var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia:var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow:var(--tw-empty,/*!*/ /*!*/);
    filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }

  .xxl\:filter-none{
    filter:none
  }

  .xxl\:blur-0{
    --tw-blur:blur(0)
  }

  .xxl\:blur-none{
    --tw-blur:blur(0)
  }

  .xxl\:blur-sm{
    --tw-blur:blur(4px)
  }

  .xxl\:blur{
    --tw-blur:blur(8px)
  }

  .xxl\:blur-md{
    --tw-blur:blur(12px)
  }

  .xxl\:blur-lg{
    --tw-blur:blur(16px)
  }

  .xxl\:blur-xl{
    --tw-blur:blur(24px)
  }

  .xxl\:blur-2xl{
    --tw-blur:blur(40px)
  }

  .xxl\:blur-3xl{
    --tw-blur:blur(64px)
  }

  .xxl\:brightness-0{
    --tw-brightness:brightness(0)
  }

  .xxl\:brightness-50{
    --tw-brightness:brightness(.5)
  }

  .xxl\:brightness-75{
    --tw-brightness:brightness(.75)
  }

  .xxl\:brightness-90{
    --tw-brightness:brightness(.9)
  }

  .xxl\:brightness-95{
    --tw-brightness:brightness(.95)
  }

  .xxl\:brightness-100{
    --tw-brightness:brightness(1)
  }

  .xxl\:brightness-105{
    --tw-brightness:brightness(1.05)
  }

  .xxl\:brightness-110{
    --tw-brightness:brightness(1.1)
  }

  .xxl\:brightness-125{
    --tw-brightness:brightness(1.25)
  }

  .xxl\:brightness-150{
    --tw-brightness:brightness(1.5)
  }

  .xxl\:brightness-200{
    --tw-brightness:brightness(2)
  }

  .xxl\:contrast-0{
    --tw-contrast:contrast(0)
  }

  .xxl\:contrast-50{
    --tw-contrast:contrast(.5)
  }

  .xxl\:contrast-75{
    --tw-contrast:contrast(.75)
  }

  .xxl\:contrast-100{
    --tw-contrast:contrast(1)
  }

  .xxl\:contrast-125{
    --tw-contrast:contrast(1.25)
  }

  .xxl\:contrast-150{
    --tw-contrast:contrast(1.5)
  }

  .xxl\:contrast-200{
    --tw-contrast:contrast(2)
  }

  .xxl\:drop-shadow-sm{
    --tw-drop-shadow:drop-shadow(0 1px 1px rgba(0,0,0,0.05))
  }

  .xxl\:drop-shadow{
    --tw-drop-shadow:drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
  }

  .xxl\:drop-shadow-md{
    --tw-drop-shadow:drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
  }

  .xxl\:drop-shadow-lg{
    --tw-drop-shadow:drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
  }

  .xxl\:drop-shadow-xl{
    --tw-drop-shadow:drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
  }

  .xxl\:drop-shadow-2xl{
    --tw-drop-shadow:drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
  }

  .xxl\:drop-shadow-none{
    --tw-drop-shadow:drop-shadow(0 0 #0000)
  }

  .xxl\:grayscale-0{
    --tw-grayscale:grayscale(0)
  }

  .xxl\:grayscale{
    --tw-grayscale:grayscale(100%)
  }

  .xxl\:hue-rotate-0{
    --tw-hue-rotate:hue-rotate(0deg)
  }

  .xxl\:hue-rotate-15{
    --tw-hue-rotate:hue-rotate(15deg)
  }

  .xxl\:hue-rotate-30{
    --tw-hue-rotate:hue-rotate(30deg)
  }

  .xxl\:hue-rotate-60{
    --tw-hue-rotate:hue-rotate(60deg)
  }

  .xxl\:hue-rotate-90{
    --tw-hue-rotate:hue-rotate(90deg)
  }

  .xxl\:hue-rotate-180{
    --tw-hue-rotate:hue-rotate(180deg)
  }

  .xxl\:-hue-rotate-180{
    --tw-hue-rotate:hue-rotate(-180deg)
  }

  .xxl\:-hue-rotate-90{
    --tw-hue-rotate:hue-rotate(-90deg)
  }

  .xxl\:-hue-rotate-60{
    --tw-hue-rotate:hue-rotate(-60deg)
  }

  .xxl\:-hue-rotate-30{
    --tw-hue-rotate:hue-rotate(-30deg)
  }

  .xxl\:-hue-rotate-15{
    --tw-hue-rotate:hue-rotate(-15deg)
  }

  .xxl\:invert-0{
    --tw-invert:invert(0)
  }

  .xxl\:invert{
    --tw-invert:invert(100%)
  }

  .xxl\:saturate-0{
    --tw-saturate:saturate(0)
  }

  .xxl\:saturate-50{
    --tw-saturate:saturate(.5)
  }

  .xxl\:saturate-100{
    --tw-saturate:saturate(1)
  }

  .xxl\:saturate-150{
    --tw-saturate:saturate(1.5)
  }

  .xxl\:saturate-200{
    --tw-saturate:saturate(2)
  }

  .xxl\:sepia-0{
    --tw-sepia:sepia(0)
  }

  .xxl\:sepia{
    --tw-sepia:sepia(100%)
  }

  .xxl\:backdrop-filter{
    --tw-backdrop-blur:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate:var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia:var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  .xxl\:backdrop-filter-none{
    -webkit-backdrop-filter:none;
            backdrop-filter:none
  }

  .xxl\:backdrop-blur-0{
    --tw-backdrop-blur:blur(0)
  }

  .xxl\:backdrop-blur-none{
    --tw-backdrop-blur:blur(0)
  }

  .xxl\:backdrop-blur-sm{
    --tw-backdrop-blur:blur(4px)
  }

  .xxl\:backdrop-blur{
    --tw-backdrop-blur:blur(8px)
  }

  .xxl\:backdrop-blur-md{
    --tw-backdrop-blur:blur(12px)
  }

  .xxl\:backdrop-blur-lg{
    --tw-backdrop-blur:blur(16px)
  }

  .xxl\:backdrop-blur-xl{
    --tw-backdrop-blur:blur(24px)
  }

  .xxl\:backdrop-blur-2xl{
    --tw-backdrop-blur:blur(40px)
  }

  .xxl\:backdrop-blur-3xl{
    --tw-backdrop-blur:blur(64px)
  }

  .xxl\:backdrop-brightness-0{
    --tw-backdrop-brightness:brightness(0)
  }

  .xxl\:backdrop-brightness-50{
    --tw-backdrop-brightness:brightness(.5)
  }

  .xxl\:backdrop-brightness-75{
    --tw-backdrop-brightness:brightness(.75)
  }

  .xxl\:backdrop-brightness-90{
    --tw-backdrop-brightness:brightness(.9)
  }

  .xxl\:backdrop-brightness-95{
    --tw-backdrop-brightness:brightness(.95)
  }

  .xxl\:backdrop-brightness-100{
    --tw-backdrop-brightness:brightness(1)
  }

  .xxl\:backdrop-brightness-105{
    --tw-backdrop-brightness:brightness(1.05)
  }

  .xxl\:backdrop-brightness-110{
    --tw-backdrop-brightness:brightness(1.1)
  }

  .xxl\:backdrop-brightness-125{
    --tw-backdrop-brightness:brightness(1.25)
  }

  .xxl\:backdrop-brightness-150{
    --tw-backdrop-brightness:brightness(1.5)
  }

  .xxl\:backdrop-brightness-200{
    --tw-backdrop-brightness:brightness(2)
  }

  .xxl\:backdrop-contrast-0{
    --tw-backdrop-contrast:contrast(0)
  }

  .xxl\:backdrop-contrast-50{
    --tw-backdrop-contrast:contrast(.5)
  }

  .xxl\:backdrop-contrast-75{
    --tw-backdrop-contrast:contrast(.75)
  }

  .xxl\:backdrop-contrast-100{
    --tw-backdrop-contrast:contrast(1)
  }

  .xxl\:backdrop-contrast-125{
    --tw-backdrop-contrast:contrast(1.25)
  }

  .xxl\:backdrop-contrast-150{
    --tw-backdrop-contrast:contrast(1.5)
  }

  .xxl\:backdrop-contrast-200{
    --tw-backdrop-contrast:contrast(2)
  }

  .xxl\:backdrop-grayscale-0{
    --tw-backdrop-grayscale:grayscale(0)
  }

  .xxl\:backdrop-grayscale{
    --tw-backdrop-grayscale:grayscale(100%)
  }

  .xxl\:backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate:hue-rotate(0deg)
  }

  .xxl\:backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate:hue-rotate(15deg)
  }

  .xxl\:backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate:hue-rotate(30deg)
  }

  .xxl\:backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate:hue-rotate(60deg)
  }

  .xxl\:backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate:hue-rotate(90deg)
  }

  .xxl\:backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate:hue-rotate(180deg)
  }

  .xxl\:-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate:hue-rotate(-180deg)
  }

  .xxl\:-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate:hue-rotate(-90deg)
  }

  .xxl\:-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate:hue-rotate(-60deg)
  }

  .xxl\:-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate:hue-rotate(-30deg)
  }

  .xxl\:-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate:hue-rotate(-15deg)
  }

  .xxl\:backdrop-invert-0{
    --tw-backdrop-invert:invert(0)
  }

  .xxl\:backdrop-invert{
    --tw-backdrop-invert:invert(100%)
  }

  .xxl\:backdrop-opacity-0{
    --tw-backdrop-opacity:opacity(0)
  }

  .xxl\:backdrop-opacity-5{
    --tw-backdrop-opacity:opacity(0.05)
  }

  .xxl\:backdrop-opacity-10{
    --tw-backdrop-opacity:opacity(0.1)
  }

  .xxl\:backdrop-opacity-20{
    --tw-backdrop-opacity:opacity(0.2)
  }

  .xxl\:backdrop-opacity-25{
    --tw-backdrop-opacity:opacity(0.25)
  }

  .xxl\:backdrop-opacity-30{
    --tw-backdrop-opacity:opacity(0.3)
  }

  .xxl\:backdrop-opacity-40{
    --tw-backdrop-opacity:opacity(0.4)
  }

  .xxl\:backdrop-opacity-50{
    --tw-backdrop-opacity:opacity(0.5)
  }

  .xxl\:backdrop-opacity-60{
    --tw-backdrop-opacity:opacity(0.6)
  }

  .xxl\:backdrop-opacity-70{
    --tw-backdrop-opacity:opacity(0.7)
  }

  .xxl\:backdrop-opacity-75{
    --tw-backdrop-opacity:opacity(0.75)
  }

  .xxl\:backdrop-opacity-80{
    --tw-backdrop-opacity:opacity(0.8)
  }

  .xxl\:backdrop-opacity-90{
    --tw-backdrop-opacity:opacity(0.9)
  }

  .xxl\:backdrop-opacity-95{
    --tw-backdrop-opacity:opacity(0.95)
  }

  .xxl\:backdrop-opacity-100{
    --tw-backdrop-opacity:opacity(1)
  }

  .xxl\:backdrop-saturate-0{
    --tw-backdrop-saturate:saturate(0)
  }

  .xxl\:backdrop-saturate-50{
    --tw-backdrop-saturate:saturate(.5)
  }

  .xxl\:backdrop-saturate-100{
    --tw-backdrop-saturate:saturate(1)
  }

  .xxl\:backdrop-saturate-150{
    --tw-backdrop-saturate:saturate(1.5)
  }

  .xxl\:backdrop-saturate-200{
    --tw-backdrop-saturate:saturate(2)
  }

  .xxl\:backdrop-sepia-0{
    --tw-backdrop-sepia:sepia(0)
  }

  .xxl\:backdrop-sepia{
    --tw-backdrop-sepia:sepia(100%)
  }

  .xxl\:transition-none{
    transition-property:none
  }

  .xxl\:transition-all{
    transition-property:all;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xxl\:transition{
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xxl\:transition-colors{
    transition-property:background-color, border-color, color, fill, stroke;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xxl\:transition-opacity{
    transition-property:opacity;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xxl\:transition-shadow{
    transition-property:box-shadow;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xxl\:transition-transform{
    transition-property:transform;
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration:150ms
  }

  .xxl\:duration-75{
    transition-duration:75ms
  }

  .xxl\:duration-100{
    transition-duration:100ms
  }

  .xxl\:duration-150{
    transition-duration:150ms
  }

  .xxl\:duration-200{
    transition-duration:200ms
  }

  .xxl\:duration-300{
    transition-duration:300ms
  }

  .xxl\:duration-500{
    transition-duration:500ms
  }

  .xxl\:duration-700{
    transition-duration:700ms
  }

  .xxl\:duration-1000{
    transition-duration:1000ms
  }

  .xxl\:ease-linear{
    transition-timing-function:linear
  }

  .xxl\:ease-in{
    transition-timing-function:cubic-bezier(0.4, 0, 1, 1)
  }

  .xxl\:ease-out{
    transition-timing-function:cubic-bezier(0, 0, 0.2, 1)
  }

  .xxl\:ease-in-out{
    transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1)
  }
}

